import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import CRRAFirstRow from "./rows/firstRow";
import CRRASecondRow from "./rows/secondRow";

const CRResidentialAddress = () => {
  return (
    <WrapperWhiteBG title="Residential Address">
      <CRRAFirstRow />
      <CRRASecondRow />
    </WrapperWhiteBG>
  );
};

export default CRResidentialAddress;
