import { ITable, ITableDetails } from "../../component/table/types";
import { IItemTableDashboard, IRowTable } from "../../services/types/table";
import { getTableDetailsPicker } from "../../utils/getTableDetailsPicker";

export const getDataTable = (
  dataRow: IItemTableDashboard[],
  width: number
): ITable[] => [
  {
    title: "Created",
    width: "150px",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.date),
  },
  {
    title: "Account",
    width: "calc((100% - 300px)/2)",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.account),
  },
  {
    title: "Beneficiary / Remitter",
    width: "calc((100% - 300px)/2)",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.counterparty),
  },
  {
    title: "Amount",
    width: "150px",
    positionText: width > 1024 ? "right" : "left",
    dataColumn: dataRow.map((item) => item.amount),
  },
];

export const getDetailsTable = (data: IRowTable<any>[]): ITableDetails[][] => {
  return data.map(({ details = [] }) =>
    details.map((item) => {
      if (item.id === "documents") {
        return getTableDetailsPicker(item);
      }
      return item;
    })
  );
};

export enum DashboardExpandedByDefaultKey {
  DASHBOARD_BALANCES_EXPANDED_BY_DEFAULT = "DASHBOARD_BALANCES_EXPANDED_BY_DEFAULT",
  DASHBOARD_RECENT_TRANSACTIONS_EXPANDED_BY_DEFAULT = "DASHBOARD_RECENT_TRANSACTIONS_EXPANDED_BY_DEFAULT",
  DASHBOARD_DRAFTS_EXPANDED_BY_DEFAULT = "DASHBOARD_DRAFTS_EXPANDED_BY_DEFAULT",
}

export const InitialIsExpanded: {
  [key in DashboardExpandedByDefaultKey]: boolean;
} = {
  [DashboardExpandedByDefaultKey.DASHBOARD_BALANCES_EXPANDED_BY_DEFAULT]: true,
  [DashboardExpandedByDefaultKey.DASHBOARD_RECENT_TRANSACTIONS_EXPANDED_BY_DEFAULT]:
    false,
  [DashboardExpandedByDefaultKey.DASHBOARD_DRAFTS_EXPANDED_BY_DEFAULT]: false,
};

export const isExpandedByDefault = (
  key: DashboardExpandedByDefaultKey,
  initialIsExpanded: boolean
): boolean => {
  const value = sessionStorage.getItem(key);
  if (value === null) {
    return initialIsExpanded;
  }
  return value !== "0";
};

export const setShouldExpandByDefault = (
  key: DashboardExpandedByDefaultKey,
  shouldExpand: boolean
) => {
  sessionStorage.setItem(key, shouldExpand ? "1" : "0");
};
