import { useEffect, useState } from "react";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import WrapperBodyTitle from "../../../component/Wrappers/WrapperBodyTitle";
import { DownloadsApi } from "../../../services/downloads";
import DocList from "./docList";

const DownloadDocs = () => {
  const [siteId, setSiteId] = useState('');
  const [queryInit, infoInitQuery] = useMutationAPI(DownloadsApi.init, {
    changeIsLoading: true,
  });

  const navigate = useNavigate();

  const docsData = infoInitQuery.data?.data?.data;

  const dropdownOptions = infoInitQuery.data?.data?.docs_filter?.dropdown_options?.data || [];

  const handleFilter = (value: string) => {
    setSiteId(value);
    queryInit({siteId: value})
  }

  useEffect(() => {
    queryInit({siteId: ''})
  }, [])

  return (
    <WrapperBodyTitle
      {...infoInitQuery.data?.data?.page?.header}
      onClickButton={({action}) => {
        navigate("/recurring-payments/builder", {
          state: {type: action},
        });
      }}>

      <Select
        defaultValue={siteId}
        style={{ width: 280 }}
        onChange={handleFilter}
        options={[
          { value: '', label: 'All' },
          ...(dropdownOptions && dropdownOptions.map(({text, value}) => ({
            value, label: text,
          }))),
        ]}
      />

      {docsData && <DocList data={docsData} />}
    </WrapperBodyTitle>
  );
};

export default DownloadDocs;
