import { Button } from "antd";
import { useContext, useEffect, useMemo } from "react";
import { ContextInvoiceUse } from "../../..";
import Table from "../../../../../component/table";
import useSizePage from "../../../../../utils/hookSizePage";
import { initDataCommon } from "../../../data";
import { getDataTable } from "./data";
import { TOnChangeDataTable, TOnErrorDataTable } from "./types";

const IUFSTable = () => {
  const { dataFS, dataCommon, setDataCommon } = useContext(ContextInvoiceUse);
  const { dataTable, currency, taxType } = dataCommon;
  const { width } = useSizePage();

  const onChangeTable: TOnChangeDataTable = (key, index, value) => {
    setDataCommon((prev) => {
      const dataTable = [...prev.dataTable];
      dataTable[index] = { ...dataTable[index], [key]: { error: "", value } };
      return { ...prev, dataTable };
    });
  };
  const onErrorTable: TOnErrorDataTable = (key, index, error) => {
    setDataCommon((prev) => {
      const dataTable = [...prev.dataTable];
      dataTable[index] = {
        ...dataTable[index],
        [key]: { ...dataTable[index][key], error },
      };
      return { ...prev, dataTable };
    });
  };

  const onDeleteTable = (indexProps: number) => {
    setDataCommon((prev) => {
      const dataTable = prev.dataTable.filter(
        (_, index) => index !== indexProps
      );

      if (!dataTable.length) dataTable.push({ ...initDataCommon.dataTable[0] });
      return { ...prev, dataTable };
    });
  };

  const onDuplicateTable = (indexProps: number) => {
    setDataCommon((prev) => {
      const dataTable = [...prev.dataTable, prev.dataTable[indexProps]];
      return { ...prev, dataTable };
    });
  };

  const table = useMemo(
    () =>
      getDataTable(
        dataTable,
        currency,
        taxType,
        onChangeTable,
        onErrorTable,
        width
      ),
    [dataTable, currency, onChangeTable, onErrorTable]
  );

  // useEffect(() => {
  //   if (dataFS.GTypeTax.value.id === "none") {
  //     for (let i = 0; i < dataTable.length; i++) {
  //       onChangeTable("tax", i, "0");
  //     }
  //   }
  // }, [dataFS.GTypeTax.value]);

  return (
    <div>
      <Table
        table={table}
        onClickDuplicate={onDuplicateTable}
        onClickDelete={
          table[0]?.dataColumn.length > 1 ? onDeleteTable : undefined
        }
      />
    </div>
  );
};

export default IUFSTable;
