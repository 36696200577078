import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import { PendingPaymnetsAPI } from "../../../../services/pendingPayments";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import useSizePage from "../../../../utils/hookSizePage";
import ModalAuth from "../../../shared/components/ModalAuth";

interface IProps {
  transfers: string[];
  onRealod: () => void;
}

const PPButtonAuth: FC<IProps> = ({ transfers, onRealod }) => {
  const [queryAuth, infoAuthQuery] = useMutationAPI(
    PendingPaymnetsAPI.authorize
  );

  const { width } = useSizePage();

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const response = infoAuthQuery.data;
    if (response) {
      setIsOpenModal(true);
    }
  }, [infoAuthQuery]);

  return (
    <>
      <Button
        shape="round"
        size="large"
        onClick={() => queryAuth({ transfers })}
        disabled={!transfers.length}
        loading={infoAuthQuery.isLoading}
      >
        {width > 1024 ? "Execute selected" : "Execute"}
        {!transfers.length ? "" : ` (${transfers.length})`}
      </Button>
      {isOpenModal && (
        <ModalAuth
          onCancel={() => setIsOpenModal(false)}
          transfers={transfers}
          onResponse={onRealod}
          execute={PendingPaymnetsAPI.execute}
        />
      )}
    </>
  );
};

export default PPButtonAuth;
