import { Button, InputRef } from "antd";
import { createRef, useContext, useEffect } from "react";
import { CryptoSellContext } from "..";
import AuthTransfer from "../../../../component/authTransfer";
import { IValueError } from "../../../../component/payment/types";
import { CryptoAPI } from "../../../../services/crypto";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";

export interface IDataThirdStepPayment {
  amount: string;
  name: string;
}

const CryptoSellSS = () => {
  const valueContext = useContext(CryptoSellContext);

  const { dataSecond, dataCommon, step, setStep, setDataThird } = valueContext;
  const { checksum, amount, name } = dataSecond;
  const { paymentId } = dataCommon;

  const refInput = createRef<InputRef>();
  const [executeQuery, infoExecuteQuery] = useMutationAPI(
    CryptoAPI.sell.execute
  );

  useEffect(() => {
    const response = infoExecuteQuery.data;
    if (response) {
      setDataThird({ table: response.data.data, accountId: "" });
      setStep(3);
    }
  }, [infoExecuteQuery]);

  useEffect(() => {
    if (step === 2) {
      setTimeout(() => {
        if (refInput.current) refInput.current.focus();
      }, 1000);
    }
  }, [step]);

  const onClickExecute = (auth: IValueError<string>) => {
    if (!!auth.error.length) return;
    executeQuery({
      paymentId,
      checksum,
      auth_code: auth.value,
    });
  };

  return (
    <AuthTransfer
      amount={amount}
      name={name}
      onClick={onClickExecute}
      isLoadingAuth={infoExecuteQuery.isLoading}
      ref={refInput}
    >
      <Button
        shape="round"
        size="large"
        onClick={() => setStep(1)}
        disabled={infoExecuteQuery.isLoading || step !== 2}
      >
        Edit payment details
      </Button>
    </AuthTransfer>
  );
};

export default CryptoSellSS;
