import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px 0;
  align-items: center;
`;
export const WrapperItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px 0;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
