import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperSwitch = styled.div`
  margin: 15px 0;
  text-align: center;
`;

export const Title = styled.div`
  margin-bottom: 4px;
`;

export const WrappperStep = styled.div`
  overflow: hidden;
`;
