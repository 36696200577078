import { useContext, useEffect, useMemo } from "react";
import { ContextMassPayment } from "../../..";
import PickerComponent, {
  IItemPicker,
} from "../../../../../component/Global/Picker";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";

const MassPaymentDestination = () => {
  const { data, dataCommon, setData } = useContext(ContextMassPayment);
  const { paymentDestination } = data;
  const { dataDestination } = dataCommon;

  const setPicker = (value: IItemPicker) => {
    setData((prev) => ({
      ...prev,
      paymentDestination: { error: "", value },
      account: { error: "", value: { id: "", text: "" } },
    }));
  };

  useEffect(() => {
    if (dataDestination.length === 1) {
      setPicker(dataDestination[0]);
    }
  }, [dataDestination]);

  if (dataDestination.length === 1) {
    return (
      <WrapperWhiteBG title="Payment Destination">
        {dataDestination[0].component}
      </WrapperWhiteBG>
    );
  }

  return (
    <WrapperWhiteBG title="Payment Destination">
      <PickerComponent
        {...paymentDestination}
        data={dataDestination}
        setValue={setPicker}
        placeholder="Select Destination"
      />
    </WrapperWhiteBG>
  );
};

export default MassPaymentDestination;
