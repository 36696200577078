import instance from "..";
import { IFileAPI } from "../types";
import {
  IPRecurringBuild,
  IPRecurringDelete,
  IPRecurringFileDelete,
  IPRecurringFileDownload,
  IPRecurringFileUpload,
  IPRecurringSave,
  IPRecurringValidate,
} from "./types/payload";
import {
  IResInitBuildRP,
  IResInitRP,
  IRRecurringFileDelete,
  IRRecurringFileUpload,
  IRRecurringValidate,
} from "./types/response";

export const RecurringPaymentAPI = {
  init: () => instance.get<IResInitRP>("app/init-recurring-payments-list"),
  build: ({ isEdit, recurringId, type }: IPRecurringBuild) =>
    instance.get<IResInitBuildRP>(
      `/recurring-payments/${isEdit ? "edit" : "init"}/${type}${
        !recurringId.length ? "" : "/" + recurringId
      }`
    ),
  validate: ({ id, payload }: IPRecurringValidate) =>
    instance.post<IRRecurringValidate>(
      "/recurring-payments/validate/" + id,
      payload
    ),
  save: ({ id }: IPRecurringSave) =>
    instance.post("/recurring-payments/store/" + id),
  delete: (payload: IPRecurringDelete) =>
    instance.post("/recurring-payments/delete", payload),
  file: {
    upload: ({ recurringPaymentId, formData }: IPRecurringFileUpload) =>
      instance.post<IRRecurringFileUpload>(
        "/recurring-payments/documents/upload/" + recurringPaymentId,
        formData
      ),
    delete: ({ id }: IPRecurringFileDelete) =>
      instance.get<IRRecurringFileDelete>(
        "/recurring-payments/documents/delete/" + id
      ),
    download: ({ id }: IPRecurringFileDownload) =>
      instance.get<IFileAPI>("/documents/" + id),
  },
};
