import { CheckOutlined, CopyOutlined, DownloadOutlined, EditOutlined, FileProtectOutlined, MailOutlined, UsbOutlined } from "@ant-design/icons";

export const getIconDropDown = (text: string) => {
  switch (text) {
    case "clone": {
      return <CopyOutlined />;
    }
    case "edit": {
      return <EditOutlined />;
    }
    case "download": {
      return <DownloadOutlined />;
    }
    case "email": {
      return <MailOutlined />;
    }
    case "use": {
      return <FileProtectOutlined />;
    }
    case "execute":{
      return <CheckOutlined/> 
    }
    default: {
      return <UsbOutlined />;
    }
  }
};
