import * as Styled from "./style";
import { useContext, useEffect } from "react";
import AmountComponent from "../../../../../component/Global/Amount";
import { Text } from "../../../../../commonStyle";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CryptoSellContext } from "../..";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { CryptoAPI } from "../../../../../services/crypto";
import validateNumber from "../../../../../utils/validate/validateNumber";
import { getDecimal, getMin } from "../data";

const CryptoSellAmount = () => {
  const { dataFirst, dataCommon, setDataCommon, onChangeData, onErrorData } =
    useContext(CryptoSellContext);

  const { accountId, currencySymbol, balance, currencyCode, rate } = dataCommon;
  const { amount } = dataFirst;

  const [rateQuery, infoRateQuery] = useMutationAPI(CryptoAPI.sell.rate);

  useEffect(() => {
    if (!amount.value.length || !accountId.length) return;
    rateQuery({ accountId, fxPaymentAmount: amount.value });
  }, [amount.value, accountId]);

  useEffect(() => {
    const { paymentAmountFormatted = "", paymentAmount = 0 } =
      infoRateQuery.data?.data || {};

    setDataCommon((prev) => ({
      ...prev,
      rate: paymentAmountFormatted,
      rateNumber: paymentAmount,
      isLoadingRate: infoRateQuery.isLoading,
    }));
  }, [infoRateQuery]);

  const onBlurAmount = () => {
    const value = parseFloat(amount.value) || 0;
    const error = validateNumber(
      value,
      {
        max: balance,
        min: getMin(currencyCode),
      },
      {
        symbol: currencySymbol,
        decimalScale: getDecimal(currencyCode),
      }
    );
    onChangeData("amount", value.toFixed(getDecimal(currencyCode)));
    onErrorData("amount", error);
  };

  return (
    <Styled.Wrapper>
      <Styled.WrapperAmount>
        <AmountComponent
          {...amount}
          onChangeValue={(value) => onChangeData("amount", value)}
          prefix={`${currencySymbol} `}
          title="Amount to sell:"
          placeholder={currencySymbol + (0).toFixed(getDecimal(currencyCode))}
          decimalsLimit={getDecimal(currencyCode)}
          onBlur={onBlurAmount}
          charLimit={16}
          width="100%"
        />
      </Styled.WrapperAmount>
      {!rate.length ? (
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 24, color: "black" }} spin />
          }
        />
      ) : (
        <Text fontSize={16}>&asymp;{rate}</Text>
      )}
    </Styled.Wrapper>
  );
};

export default CryptoSellAmount;
