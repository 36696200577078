import { AlertProps } from "antd";
import React, { FC, useId } from "react";
import { Text } from "../../../commonStyle";
import WrapperTitleError from "../../Wrappers/wrapperTitleError";
import * as Styled from "./style";
import {
  PreparedFieldActions,
  usePreparedFiled,
} from "../../../pages/payments/shared/firstStep/form";

export interface IPropsInputComponent {
  value: string;
  onChangeValue: (text: string) => void;
  error?: string;
  title?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  prefix?: string;
  postfix?: string;
  isPassword?: boolean;
  onKeyEnter?: () => void;
  decimalsLimit?: number;
  charLimit?: number;
  width?: string;
  info?: AlertProps[];
  disabledError?: boolean;
  id?: string;
  ableNegative?: boolean;
  idKey?: string;
  fieldActions?: PreparedFieldActions;
}

const AmountComponent: FC<IPropsInputComponent> = ({
  error = "",
  title,
  prefix = "",
  postfix = "",
  value,
  onChangeValue,
  onBlur,
  onFocus,
  placeholder,
  onKeyEnter,
  isPassword = false,
  decimalsLimit = 2,
  charLimit,
  width,
  info,
  disabledError,
  id,
  ableNegative = false,
  idKey,
  fieldActions,
}) => {
  const idComponent = useId();
  const isIntegerNumber = decimalsLimit === 0;

  if (fieldActions) {
    usePreparedFiled(fieldActions);
  }

  const focusOnDot = (move = 0) => {
    const value1 = parseInt(value).toString();
    let position = value.indexOf(".");

    const element = document.getElementById(
      idComponent
    ) as HTMLInputElement | null;

    if (position === -1 || !element) return;

    position += Math.ceil(value1.length / 3 - 1) + move;

    element.setSelectionRange(position, position);
  };

  const onFocusInput = () => {
    if (onFocus) onFocus();
    setTimeout(() => focusOnDot(), 0);
  };

  const onBlurInput = () => {
    const valueRes = parseFloat(value) || 0;
    onChangeValue(valueRes.toFixed(decimalsLimit));

    if (onBlur) onBlur();
    fieldActions?.onBlur?.();
  };

  const onChange = (valueProps = "0.00") => {
    const valueChange = parseFloat(valueProps).toFixed(decimalsLimit);

    if (!isIntegerNumber) {
      const floatStr = valueChange.split(".")[1];
      const float = parseInt(floatStr) || 0;
      const countZero = floatStr
        .split("")
        .filter((item) => item === "0").length;

      if (
        !(float % 10) &&
        !!float &&
        parseInt(valueChange) === parseInt(value)
      ) {
        setTimeout(() => focusOnDot(decimalsLimit - countZero + 1), 10);
      }
    }

    if (charLimit) {
      if (valueChange.length > charLimit) return;
    }

    onChangeValue(valueChange);
    // if (parseFloat(value) === 0) setTimeout(focusOnDot, 10);
  };

  const onChangeArrowUp = () => {
    onChange((parseInt(value) + 1).toString());
  };

  const onChangeArrowDown = () => {
    onChange(value !== "1" ? (parseInt(value) - 1).toString() : "1");
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && onKeyEnter) {
      onKeyEnter();
    }
    if (e.key === "-" && !ableNegative) {
      e.preventDefault();
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ".") {
      focusOnDot(1);
      return;
    }
  };

  return (
    <WrapperTitleError
      title={title}
      error={error}
      width={width}
      info={info}
      disabledError={disabledError}
      id={id}
    >
      <Styled.Wrapper>
        <Styled.WrapperValue
          isError={!!error.length}
          borderColor={error.length ? "#eb5757" : "#D9D9D9"}
        >
          <Text fontSize={16} noWrap>
            {prefix}
          </Text>
          <Styled.InputNumber id={idKey}>
            <Styled.Input
              value={value}
              onFocus={onFocusInput}
              onBlur={onBlurInput}
              onValueChange={onChange}
              placeholder={placeholder}
              type={isPassword ? "password" : undefined}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              decimalsLimit={decimalsLimit}
              decimalSeparator="."
              groupSeparator=","
              id={idComponent}
            />
            <Styled.Postfix fontSize={16} noWrap>
              {postfix}
            </Styled.Postfix>

            {isIntegerNumber && (
              <>
                <Styled.ArrowUp onClick={onChangeArrowUp}>^</Styled.ArrowUp>
                <Styled.ArrowDown onClick={onChangeArrowDown}>
                  ^
                </Styled.ArrowDown>
              </>
            )}
          </Styled.InputNumber>
        </Styled.WrapperValue>
      </Styled.Wrapper>
    </WrapperTitleError>
  );
};

export default AmountComponent;
