import { useContext } from "react";
import { ContextInvoiceBuilder } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import * as Styled from "./style";

const IBRemFourthRow = () => {
  const { dataFS, onChangeData, validateOnBlur } = useContext(
    ContextInvoiceBuilder
  );
  const { RemPhone, RemEmail } = dataFS;

  return (
    <Styled.Wrapper>
      <InputComponent
        {...RemEmail}
        title="Email:"
        onChangeValue={(value) => onChangeData("RemEmail", value)}
        onBlur={() => validateOnBlur("RemEmail")}
        width="50%"
        maxLength={100}
        id="RemEmail"
      />
      <InputComponent
        {...RemPhone}
        title="Phone Number:"
        onChangeValue={(value) => onChangeData("RemPhone", value)}
        onBlur={() => validateOnBlur("RemPhone")}
        width="50%"
        maxLength={20}
        id="RemPhone"
      />
    </Styled.Wrapper>
  );
};

export default IBRemFourthRow;
