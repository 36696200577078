import { useContext } from "react";
import { ContextRecurringPayment } from "../../../..";
import PickerComponent from "../../../../../../component/Global/Picker";
import ScheduleOptions from "./options";
import ScheduleDailyPicker from "./options/dailyPicker";
import * as Styled from "./style";

const RPBFSGSchedule = () => {
  const { dataFS, dataCommon, onChangeData } = useContext(
    ContextRecurringPayment
  );
  const { schedulePicker } = dataFS;
  const { dataSchedule } = dataCommon;

  return (
    <Styled.Wrapper>
      <PickerComponent
        {...schedulePicker}
        title="Schedule as:"
        id="schedulePicker"
        setValue={(value) => {
          onChangeData("schedulePicker", value);
          onChangeData("scheduleOptions", []);
        }}
        data={dataSchedule}
        width="20%"
        placeholder="Select Period"
      />
      <ScheduleOptions />
    </Styled.Wrapper>
  );
};

export default RPBFSGSchedule;
