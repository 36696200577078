import { IItemPicker } from "../../component/Global/Picker";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import { IDataDropdonwInitUpload } from "../../services/creditDoc/types";
import { getValueError } from "../payments/data";
import { IDataContext } from "./types";

export const dataContext: IDataContext = {
  benefAccount: getValueError({ text: "", id: "" }),
  remitterName: getValueError(""),
  paymentDescription: getValueError(""),
  amount: getValueError("0.00"),
  dataBenefAccount: [],
  requestId: "",
  files: getValueError([]),
  dataSecondStep: [],
  dataBenefCurrency: [],
};

export const getDataBenefAccount = (
  data: IDataDropdonwInitUpload[]
): IItemPicker[] => {
  return data.map(({ data, currencySymbol, value }) => {
    return {
      text: data.map(({ text }) => text).join(""),
      id: value,
      component: <TypeToTextMap data={[data]} />,
    };
  });
};

export const getDataBenefCurrency = (
  data: IDataDropdonwInitUpload[]
): IItemPicker[] => {
  return data.map(({ currencySymbol, value }) => {
    return {
      text: currencySymbol,
      id: value,
    };
  });
};
