import PaymentAPI from "../../../services/paymentAPI";
import { IResponseInitIternal } from "../../../services/paymentAPI/types/response";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import { initDataSS, initDataTS } from "../data";
import PaymentSystem, { TLocationState } from "../shared";
import { requireFiles } from "../shared/validations";
import {
  getDataCompany,
  getDataFromForm,
  initDataCommon,
  initDataFS,
} from "./data";
import FirstStepInternal from "./firstStep";
import { IDataCommon, IDataFS } from "./types";

const InternalComponent = () => {
  return (
    <PaymentSystem<
      IDataFS,
      IDataCommon,
      IResponseInitIternal,
      any,
      TLocationState
    >
      isSavePending={false}
      initDataFS={initDataFS}
      initDataSS={initDataSS}
      initDataTS={initDataTS}
      initDataCommon={initDataCommon}
      updateInput={(input) => input}
      infoInitQueryProvider={({ accountId, paymentId, mode }) => {
        return useQueryAPI(PaymentAPI.internal.getInit, {
          payload: { id: accountId || paymentId || "", mode },
          changeIsLoading: true,
        });
      }}
      firstStepComponet={(context, setStep) => {
        return <FirstStepInternal input={context} setStep={setStep} />;
      }}
      onInitQueryLoaded={(
        response,
        input,
        onChangeDataFS,
        updateDataCommon
      ) => {
        if (!response.data) {
          return;
        }
        const {
          header,
          beneficiaries_list,
          account,
          transfer,
          form = {},
          user,
        } = response.data.data;

        const dataCompany = getDataCompany(beneficiaries_list.dropdown_options);

        const dataForm = getDataFromForm(
          form,
          dataCompany,
          account.accountName
        );

        dataForm.map(([key, value]) => onChangeDataFS(key, value));

        updateDataCommon((prev) => ({
          ...prev,
          dataHeader: header,
          amountSymbol: account.currencySymbol,
          balance: account.balance.available,
          paymentID: transfer.paymentId,
          dataCompany,
          accountId: account.accountId,
          isRequireFiles: transfer.options.requireFilesUpload,
          isDisableSaveAsDraft: transfer.options.disableSaveAsDraft,
          useCompanyNameInDescriptionControl:
            transfer.options.useCompanyNameInDescriptionControl,
          isAbleAuth: user?.userType !== "simple-user",
          companyName: account.accountName,
          isUsingTemplate: input.mode === "edit",
        }));
      }}
      validateAll={(data, dataCommon) => {
        const isRequireFiles =
          dataCommon.isRequireFiles && !data.DIsInterTransfer.value;

        if (isRequireFiles) {
          requireFiles(data.Tfiles);
        }
      }}
    />
  );
};

export default InternalComponent;
