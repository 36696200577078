import { Empty } from "antd";
import { createRef, FC } from "react";
import { IItemPicker } from "..";
import * as Styled from "../style";

interface IProps {
  data: IItemPicker[];
  onSelectItem: (item: IItemPicker) => void;
}

const PickerOpen: FC<IProps> = ({ data, onSelectItem }) => {

  if (!data.length) {
    return (
      <Styled.WrapperData>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Styled.WrapperData>
    );
  }
  return (
    <Styled.WrapperData>
      {data.map((item) => (
        <Styled.SelectText
          key={item.text + ":" + item.id}
          padding="10px 5px"
          onMouseDown={() => {
            onSelectItem(item);
          }}
        >
          {item.component || item.text}
        </Styled.SelectText>
      ))}
    </Styled.WrapperData>
  );
};

export default PickerOpen;
