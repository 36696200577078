import { FC, useId } from "react";
import { Text } from "../../../../commonStyle";
import TypeToTextMap from "../../../../component/Global/TypeToTextMap";
import { IDataInit } from "../../../../services/cards/types";
import { IResponseCardInit } from "../../../../services/cards/types/response";
import CTFCPicker from "./picker";

import * as Styled from "./style";

interface IProps {
  title: string;
  data: IDataInit["data"];
  operations: IDataInit["operations_list"];
  templates: IResponseCardInit["templates"];
  refetchData: () => void;
}

const CTFirstColumn: FC<IProps> = ({
  title,
  data,
  operations,
  templates,
  refetchData,
}) => {
  return (
    <Styled.Wrapper>
      <Text fontSize={18}>{title}</Text>
      <TypeToTextMap data={data} styleData={{ fontSize: 15 }} />
      {operations && (
        <CTFCPicker
          operations={operations}
          data={data}
          templates={templates}
          refetchData={refetchData}
        />
      )}
    </Styled.Wrapper>
  );
};

export default CTFirstColumn;
