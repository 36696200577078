import { Button } from "antd";
import { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CryptoSendContext } from "..";
import { TextBold } from "../../../../commonStyle";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { CryptoAPI } from "../../../../services/crypto";
import PaymentAPI from "../../../../services/paymentAPI";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import ItemSecondStepSwift from "./item";
import * as Styled from "./style";

const CryptoSendSS = () => {
  const valueContext = useContext(CryptoSendContext);

  const { dataCommon, step, setStep, setDataCommon, dataSS, setDataTS } =
    valueContext;

  const { paymentId } = dataCommon;

  const [authorizeQuery, infoAuthorizeQuery] = useMutationAPI(
    CryptoAPI.send.authorize
  );

  useEffect(() => {
    const response = infoAuthorizeQuery.data;
    if (response) {
      const typeAuth = response.data.type;
      const { paymentAmountFormatted: amount, paymentName: name } =
        response.data.payment;
      setDataTS({ amount, name });
      setDataCommon((prev) => ({ ...prev, typeAuth }));
      setStep(3);
    }
  }, [infoAuthorizeQuery]);

  return (
    <Styled.Wrapper>
      <WrapperWhiteBG>
        <Styled.WrapperItem>
          {dataSS.map(({ title, data }) => {
            return (
              <div>
                <TextBold fontSize={18}>{title}</TextBold>
                <ItemSecondStepSwift data={data} />
              </div>
            );
          })}
        </Styled.WrapperItem>
        <Styled.WrapperButtons>
          <Button
            shape="round"
            size="large"
            onClick={() => authorizeQuery({ paymentId })}
            loading={infoAuthorizeQuery.isLoading}
            disabled={step !== 2}
          >
            Authorize
          </Button>
        </Styled.WrapperButtons>
      </WrapperWhiteBG>
      <Button
        shape="round"
        size="large"
        onClick={() => setStep(1)}
        disabled={infoAuthorizeQuery.isLoading}
      >
        Edit payment details
      </Button>
    </Styled.Wrapper>
  );
};

export default CryptoSendSS;
