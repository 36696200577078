import { AxiosResponse } from "axios";
import { ReactNode, useEffect } from "react";
import { ISecondStepDataAPI } from "../../../../services/paymentAPI/types";
import { IResponsePaymentValidate } from "../../../../services/paymentAPI/types/response";
import { IErrorPayment, IResponseError } from "../../../../services/types";
import useMutationAPI, {
  IInfoMutaion,
} from "../../../../utils/usePromise/hookMutationAPI";
import {
  IDataCommon,
  IDataTS,
  TMyDispatch,
  TOnChangeDataFS,
  TOnUpdateDataFS,
  TSetErrorDataFS,
} from "../../types";

interface Input<DataFS extends {}, DataCommon extends IDataCommon> {
  dataFS: DataFS;
  dataCommon: DataCommon;
  onChangeDataFS: TOnChangeDataFS<DataFS>;
  setErrorDataFS: TSetErrorDataFS<DataFS>;
  setDataSS: TMyDispatch<ISecondStepDataAPI[]>;
  setDataTS: TMyDispatch<IDataTS>;
  setDataCommon: TMyDispatch<DataCommon>;
  updateDataFS: TOnUpdateDataFS<DataFS>;
}

type BaseProps<
  DataFS extends {},
  DataCommon extends IDataCommon,
  IPayloadValidate
> = {
  input: Input<DataFS, DataCommon>;
  setStep: (step: number) => void;
  validateApi: (
    payload: IPayloadValidate
  ) => Promise<AxiosResponse<IResponsePaymentValidate, any>>;
  draftApi?: undefined;
  getErrorResponse: (errorProps: IErrorPayment<string>[]) => {
    key: keyof DataFS;
    error: string;
  }[];
  content: (
    queryValidate: (
      props: IPayloadValidate
    ) => Promise<IInfoMutaion<IResponseError, IResponsePaymentValidate>>,
    infoValidateQuery: IInfoMutaion<IResponseError, IResponsePaymentValidate>
  ) => ReactNode;
};

type ExpandedProps<
  DataFS extends {},
  DataCommon extends IDataCommon,
  IPayloadValidate,
  IPayloadDraft
> = Omit<
  BaseProps<DataFS, DataCommon, IPayloadValidate>,
  "content" | "draftApi"
> & {
  draftApi: (payload: IPayloadDraft) => Promise<AxiosResponse<any, any>>;
  content: (
    queryValidate: (
      props: IPayloadValidate
    ) => Promise<IInfoMutaion<IResponseError, IResponsePaymentValidate>>,
    infoValidateQuery: IInfoMutaion<IResponseError, IResponsePaymentValidate>,
    queryDraft: (
      props: IPayloadDraft
    ) => Promise<IInfoMutaion<IResponseError, any>>,
    infoDraftQuery: IInfoMutaion<IResponseError, any>
  ) => ReactNode;
};

type Props<
  DataFS extends {},
  DataCommon extends IDataCommon,
  IPayloadValidate,
  IPayloadDraft
> =
  | BaseProps<DataFS, DataCommon, IPayloadValidate>
  | ExpandedProps<DataFS, DataCommon, IPayloadValidate, IPayloadDraft>;

const FirstStepCommon = <
  DataFS extends {},
  DataCommon extends IDataCommon,
  IPayloadValidate,
  IPayloadDraft
>({
  input,
  setStep,
  validateApi,
  draftApi,
  getErrorResponse,
  content,
}: Props<DataFS, DataCommon, IPayloadValidate, IPayloadDraft>) => {
  const { setDataSS, setDataTS, setDataCommon, setErrorDataFS } = input;

  const [queryValidate, infoValidateQuery] = useMutationAPI(validateApi);
  let queryDraft:
    | ((props: IPayloadDraft) => Promise<IInfoMutaion<IResponseError, any>>)
    | undefined;

  let infoDraftQuery: IInfoMutaion<IResponseError, any> | undefined;

  if (draftApi) {
    [queryDraft, infoDraftQuery] = useMutationAPI(draftApi);
  } else {
    [queryDraft, infoDraftQuery] = [undefined, undefined];
  }

  useEffect(() => {
    const response = infoValidateQuery.data;
    const responseError = infoValidateQuery.error;

    if (response) {
      const { payment, data } = response.data;
      const {
        paymentName: name,
        paymentChecksum: checksum,
        paymentAmountFormatted: amount,
      } = payment;

      setStep(2);
      setDataSS(data);
      setDataTS({ name, amount });
      setDataCommon((prev) => ({
        ...prev,
        checksum,
      }));
    }

    if (responseError && responseError.response) {
      const errorResponse = responseError.response.data.errors as
        | IErrorPayment<string>[]
        | undefined;
      if (!errorResponse) return;

      const errorData = getErrorResponse(errorResponse);
      errorData.forEach(({ key, error }) => setErrorDataFS(key, error));
    }
  }, [infoValidateQuery]);

  return (
    <div>
      {draftApi
        ? content(
            queryValidate,
            infoValidateQuery,
            queryDraft!,
            infoDraftQuery!
          )
        : content(queryValidate, infoValidateQuery)}
    </div>
  );
};

export default FirstStepCommon;
