import { useContext, useId } from "react";
import { ContextImportBulkTransfer } from "../../..";
import { TextBold } from "../../../../../commonStyle";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";

const SSMPHeader = () => {
  const dataContext = useContext(ContextImportBulkTransfer);
  const idComponent = useId();

  const { secondStepHeader } = dataContext.dataCommon;

  return (
    <div>
      <TextBold fontSize={18}>BENEFICIARY</TextBold>
      <TypeToTextMap data={secondStepHeader} />
    </div>
  );
};

export default SSMPHeader;
