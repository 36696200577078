import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import CompanyAtBank from "../../component/companyAtBank";
import { IItemPicker } from "../../component/Global/Picker";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import { CryptoAPI } from "../../services/crypto";
import { IItemDataInit, IWallets } from "../../services/crypto/types";
import { changeOpenType } from "../../store/user";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import CryptoTable from "./table";

const initCompany: IItemPicker = {
  text: "All Companies",
  id: "all-companies",
};

const CryptoComponent = () => {
  const [queryInit, infoInitQuery] = useMutationAPI(CryptoAPI.init, {
    changeIsLoading: true,
  });

  const [dataCompany, setDataCompany] = useState<IItemPicker[]>([]);
  const [valueCompany, setValueCompany] = useState(initCompany);
  const [valueRespTable, setValueRespTable] = useState<IItemDataInit[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeOpenType({ openType: "crypto" }));
    queryInit({});
  }, []);

  useEffect(() => {
    const response = infoInitQuery.data;
    if (response) {
      const companyResp =
        response.data.filters.companies_filter.dropdown_options.data;
      const tableResp = response.data.data;
      setDataCompany(
        companyResp.map(({ text, value }) => ({ text, id: value }))
      );
      setValueRespTable(tableResp.map(({ item }) => item));
    }
  }, [infoInitQuery]);

  const valueTable = useMemo(
    () =>
      valueRespTable.filter(
        ({ params }) =>
          params.companyId === valueCompany.id ||
          valueCompany.id === "all-companies"
      ),
    [valueRespTable, valueCompany]
  );

  return (
    <WrapperBodyTitle {...infoInitQuery.data?.data.page?.header}>
      <CompanyAtBank
        pickers={[
          {
            value: valueCompany,
            data: dataCompany,
            setValue: setValueCompany,
            width: "250px",
          },
        ]}
      />
      <CryptoTable data={valueTable} realoadPage={() => queryInit({})} />
    </WrapperBodyTitle>
  );
};

export default CryptoComponent;
