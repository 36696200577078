import { ArrowLeftOutlined, MailOutlined } from "@ant-design/icons";
import { Button, InputRef } from "antd";
import { createRef, FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextBold } from "../../commonStyle";
import InputComponent from "../../component/Global/Input";
import * as Styled from "./style";

interface IProps {
  email: string;
  onChangeEmail: (email: string) => void;
  errors: string;
  onBlurInput: () => void;
  textButton: string;
  onClickButton: () => void;
  isLoading: boolean;
}

const BodyReset: FC<IProps> = ({
  email,
  onChangeEmail,
  errors,
  onBlurInput,
  textButton,
  onClickButton,
  isLoading,
}) => {
  const refInput = createRef<InputRef>();
  const navigate = useNavigate();

  useEffect(() => {
    if (refInput.current) {
      refInput.current.focus();
    }
  }, []);

  return (
    <div>
      <InputComponent
        value={email}
        onChangeValue={onChangeEmail}
        error={errors}
        onBlur={onBlurInput}
        title="Please enter your Email address"
        prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        onKeyEnter={onClickButton}
        ref={refInput}
      />
      <Styled.WrapperButton>
        <Button
          shape="round"
          size="large"
          onClick={onClickButton}
          loading={isLoading}
        >
          {textButton}
        </Button>
      </Styled.WrapperButton>
      <Styled.Info>
        Reset instructions will be sent to your email address
      </Styled.Info>

      <Styled.WrapperButton>
        <ArrowLeftOutlined style={{ color: "#6c64f4" }} />
        <TextBold
          fontSize={16}
          color="#6c64f4"
          onClick={() => navigate(-1)}
          isCursor
        >
          Back
        </TextBold>
        {/* <Button
          style={{ width: "70%", marginTop: "16px" }}
          shape="round"
          size="large"
          onClick={() => navigate(-1)}
        >
          Back
        </Button> */}
      </Styled.WrapperButton>
    </div>
  );
};

export default BodyReset;
