import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
  & > * {
    width: 50%;
  }

  @media (max-width: 1024px) {
    &:first-child {
      flex-direction: column-reverse;
    }
    flex-direction: column;
    & > * {
      width: 100%;
    }
  }
`;

export const Logo = styled.img`
  position: relative;
  left: 0;
  width: 320px;
  height: 120px;
  object-fit: contain;
`;
