import { Alert } from "antd";
import * as Styled from "./style";

const OptionalErrorAlert = ({ message }: { message: string }) => {
  return message.length ? (
    <Styled.Error>
      <Alert message={message} type="error" />
    </Styled.Error>
  ) : (
    <></>
  );
};

export default OptionalErrorAlert;
