import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, InputRef } from "antd";
import React, {
  ChangeEvent,
  createRef,
  MouseEvent,
  useContext,
  useEffect,
} from "react";
import { ContextInvoiceBuilder } from "../../../..";
import { fileToBinaryFormData } from "../../../../../../component/Global/transferDocument/data";
import WrapperTitleError from "../../../../../../component/Wrappers/wrapperTitleError";
import { InvoiceTBAPI } from "../../../../../../services/invoiceTemplateBuilder";
import useMutationAPI from "../../../../../../utils/usePromise/hookMutationAPI";
import * as Styled from "./style";

const IBDImgUpload = () => {
  const { dataFS, dataCommon, onChangeData } = useContext(
    ContextInvoiceBuilder
  );
  const { GLogo } = dataFS;
  const { invoiceID } = dataCommon;

  const [queryUpload, infoUploadQuery] = useMutationAPI(
    InvoiceTBAPI.logo.upload
  );

  const [queryDelete, infoDeleteQuery] = useMutationAPI(
    InvoiceTBAPI.logo.delete
  );

  const refInput = createRef<HTMLInputElement>();

  useEffect(() => {
    const response = infoUploadQuery.data?.data;

    if (response) onChangeData("GLogo", response.image);
  }, [infoUploadQuery]);

  useEffect(() => {
    const response = infoDeleteQuery.data?.data;

    if (response) onChangeData("GLogo", "");
  }, [infoDeleteQuery]);

  const onClickWrapper = () => {
    if (!refInput.current) return;
    refInput.current.click();
  };

  const setImg = async (files: FileList | null) => {
    if (!files) return;
    const typeSplit = files[0].name.split(".");
    const type = typeSplit[typeSplit.length - 1];
    if (type !== "jpg" && type !== "png") return;

    const { formData } = await fileToBinaryFormData(files[0]);

    queryUpload({ invoiceID, formData });
  };

  const onChangeInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    await setImg(files);
    e.target.value = "";
  };

  const onClickDelete = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    queryDelete({ invoiceID });
  };

  const onDropFile = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;

    await setImg(files);
  };

  return (
    <WrapperTitleError
      title="Company logo image(.png or .jpg):"
      error={GLogo.error}
    >
      <input
        style={{ display: "none" }}
        ref={refInput}
        type="file"
        onChange={onChangeInput}
        accept=".png, .jpg"
      />

      <Styled.Wrapper
        onClick={onClickWrapper}
        isImg={!!GLogo.value.length}
        isError={!!GLogo.error.length}
        onDragStart={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        onDrop={onDropFile}
        id="GLogo"
      >
        {!!GLogo.value.length && (
          <Styled.DeleteImg onClick={onClickDelete}>
            <CloseCircleOutlined
              style={{
                backgroundColor: "#eb5757",
                color: "white",
                borderRadius: "50%",
              }}
            />
          </Styled.DeleteImg>
        )}
        {!GLogo.value.length ? (
          <span>320x120</span>
        ) : (
          <img src={"data:image/png;base64, " + GLogo.value} alt="120x120" />
        )}
      </Styled.Wrapper>
      <Button
        shape="round"
        size="large"
        onClick={onClickWrapper}
        loading={infoUploadQuery.isLoading}
      >
        Upload Logo
      </Button>
    </WrapperTitleError>
  );
};

export default IBDImgUpload;
