import { useContext, useState } from "react";
import { ContextCardRequest } from "../../..";
import InputComponent from "../../../../../component/Global/Input";
import SwitchComponent from "../../../../../component/Global/Switch";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import useSizePage from "../../../../../utils/hookSizePage";
import validateString from "../../../../../utils/validate/validateString";
import CRSDRows from "./rows";
import * as Styled from "./style";

const CRShippingDetails = () => {
  const valueContext = useContext(ContextCardRequest);

  const { width } = useSizePage();

  const { dataFS, onChangeDataFS, setErrorDataFS } = valueContext;

  const { SDFullName, SDSwitch } = dataFS;

  const onBlurName = () => {
    const error = validateString(SDFullName.value, {
      isReq: true,
      min: 3,
      max: 100,
    });

    setErrorDataFS("SDFullName", error);
  };

  return (
    <WrapperWhiteBG title="Shipping Details">
      <InputComponent
        {...SDFullName}
        title="Full Name:"
        onChangeValue={(value) => onChangeDataFS("SDFullName", value)}
        width={width > 1024 ? "calc((100% / 3) - 5px)" : "100%"}
        onBlur={onBlurName}
        maxLength={100}
        id="SDFullName"
      />
      <Styled.WrapperSwitch>
        <SwitchComponent
          checked={SDSwitch.value}
          onChange={(value) => onChangeDataFS("SDSwitch", value)}
          text="Ship to Residential Address"
          id="SDSwitch"
          textChange
        />
      </Styled.WrapperSwitch>

      {!SDSwitch.value && <CRSDRows />}
    </WrapperWhiteBG>
  );
};

export default CRShippingDetails;
