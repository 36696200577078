import { IItemPicker } from "../../component/Global/Picker";
import { IDropdownAPI } from "../../services/types";
import { IData } from "./type";
import { ITable, ITableDetails } from "../../component/table/types";
import { getTableDetailsPicker } from "../../utils/getTableDetailsPicker";
import { InvoiceBuilderAPI } from "../../services/invoiceBuilder";
import { dowloadBase64 } from "../../utils/dowloadBase64";
import ActionsTableComponent from "../../component/tableActionsPicker";

export const initData: IData = {
  table: [],
  dataAccount: [],
  dataCompany: [],
};

export const getDataPicker = (data?: IDropdownAPI): IItemPicker[] =>
  data?.dropdown_options?.data?.map(({ text, value }) => ({
    text,
    id: value,
  })) ?? [];

export const getDataTable = (
  data: IData["table"],
  onSelectDropDownTable: (item: IItemPicker) => void,
  width: number
): ITable[] => [
  {
    title: "Created",
    positionText: "left",
    width: "150px",
    dataColumn: data.map(({ item }) => item.date),
  },
  {
    title: "Template Name",
    positionText: "left",
    width: "calc(100% - 60% - 150px)",
    dataColumn: data.map(({ item }) => item.name),
  },
  {
    title: "Details",
    positionText: "left",
    width: "40%",
    dataColumn: data.map(({ item }) => item.invoice_details),
  },
  {
    title: "Actions",
    positionText: width < 1024 ? "left" : "right",
    width: "20%",
    dataColumn: data.map(({ item, invoiceTemplateId }) =>
      item.actions.map((line) =>
        line.map((item) => (
          <ActionsTableComponent
            {...item}
            setValue={({ id }) =>
              onSelectDropDownTable({ text: id, id: invoiceTemplateId })
            }
          />
        ))
      )
    ),
  },
];

export const getDetailsTable = (data: IData["table"]): ITableDetails[][] => {
  return data.map(({ details }) =>
    details.map((item) => {
      if (item.id === "invoices") {
        return getTableDetailsPicker(item, ({ id }) => {
          InvoiceBuilderAPI.buttonSS
            .download({ invoiceID: id })
            .then(({ data }) => {
              const { fileContents, fileName } = data;
              dowloadBase64(fileContents, fileName);
            });
        });
      }
      return item;
    })
  );
};
