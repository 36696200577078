import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  top: -30px;
  left: -30px;
  width: calc(100% + 60px);
  height: calc(100% + 60px);
  padding: 30px;
  /* overflow: hidden; */
`;

interface IWrapperStep {
  step: number;
  gap: string;
  speedHide: number;
  speedSlide: number;
}

export const WrapperChildren = styled.div<IWrapperStep>`
  display: flex;
  width: 100%;
  transition: ${({ speedSlide }) => `${speedSlide}s`};
  transform: ${({ step }) => `translateX(calc(-100% * ${step - 1}))`};
  & > div {
    width: 100%;
    height: 0px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap};
    transition: ${({ speedHide }) => `${speedHide}s`};
    &:not(:nth-child(${({ step }) => step})) > * {
      display: none;
    }
  }

  & > div {
    opacity: 0;
    z-index: -2;
  }

  & > div:nth-child(${({ step }) => step}) {
    height: fit-content;
    opacity: 1;
    z-index: 1;
  }
`;
