import { Button } from "antd";
import { FC } from "react";
import * as Styled from "./style";

interface IProps {
  isOpen: boolean;
  changeStatus: () => void;
}

const TIShowDetails: FC<IProps> = ({ isOpen, changeStatus }) => {
  return (
    <Styled.Wrapper>
      <Button shape="round" size="large" onClick={changeStatus}>
        {`${isOpen ? "Hide" : "Show"} details`}
      </Button>
      <Styled.Line />
    </Styled.Wrapper>
  );
};

export default TIShowDetails;
