import { useEffect, useId, useState } from "react";
import { responseToData } from "./data";
import { Empty } from "antd";
import WrapperWhiteBG from "../../../component/Wrappers/wrapperWhiteBG";
import ItemBalances, { IPropsItemBalances } from "./Item";
import * as Styled from "./style";
import { IResponseDashboard } from "../../../services/pagesAPI/types/response";

interface IDataItem extends IPropsItemBalances {
  params: {
    bankId: string;
    companyId: string;
  };
}

interface IBalances {
  dashboardResponse?: IResponseDashboard;
}

const Balances = ({ dashboardResponse }: IBalances) => {
  const idComponent = useId();
  const [data, setData] = useState<IDataItem[]>([]);

  useEffect(() => {
    if (dashboardResponse) {
      const { accounts } = dashboardResponse;

      setData(responseToData(accounts));
    }
  }, [dashboardResponse]);

  return (
    <Styled.Wrapper>
      <WrapperWhiteBG isBorder>
        {!data.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          data.map((props, index) => (
            <ItemBalances {...props} key={idComponent + index} />
          ))
        )}
      </WrapperWhiteBG>
    </Styled.Wrapper>
  );
};

export default Balances;
