import { InputUk } from "../../../types";
import SortRowBeneficiaryUk from "./SortRow";
import FirstRowBeneficiaryUk from "./firstRow";
import SecondRowBeneficiaryUk from "./secondRow";
import ThirdRowBeneficiaryUk from "./thirdRow";

const BeneficiaryRowsUk = ({ input }: { input: InputUk }) => {
  const { dataFS, dataCommon } = input;
  const { canadaID, usID, isRequireAddress, isRequireIBAN } = dataCommon;
  const countryID = dataFS.BCountry.value.id;

  return (
    <>
      {isRequireAddress && (
        <>
          <FirstRowBeneficiaryUk
            input={input}
            isUS={countryID === usID}
            isCanada={countryID === canadaID}
          />
          <SecondRowBeneficiaryUk
            input={input}
            isUS={countryID === usID}
            isCanada={countryID === canadaID}
          />
        </>
      )}
      {isRequireIBAN && <ThirdRowBeneficiaryUk input={input} />}
      <SortRowBeneficiaryUk input={input} />
    </>
  );
};

export default BeneficiaryRowsUk;
