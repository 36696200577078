import { useContext } from "react";
import { ContextRecurringPayment } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import validateString, {
  IRulesString,
} from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

const rulePostalCode: IRulesString = { isReq: true, min: 3, max: 10 };
const ruleStreet: IRulesString = { isReq: true, min: 3, max: 50 };
const ruleHouse: IRulesString = { isReq: true, max: 10 };

const RPBFPayToTR = () => {
  const { dataFS, onChangeData, onErrorData } = useContext(
    ContextRecurringPayment
  );
  const { postalCode, street, house } = dataFS;

  const onBlur = (
    key: "postalCode" | "street" | "house",
    rule: IRulesString
  ) => {
    const error = validateString(dataFS[key].value, rule);
    onErrorData(key, error);
  };

  return (
    <Styled.Wrapper>
      <InputComponent
        {...postalCode}
        id="postalCode"
        title="Postal Code:"
        onChangeValue={(value) => onChangeData("postalCode", value)}
        width="25%"
        maxLength={10}
        onBlur={() => onBlur("postalCode", rulePostalCode)}
      />
      <InputComponent
        {...street}
        id="street"
        title="Street address:"
        onChangeValue={(value) => onChangeData("street", value)}
        width="50%"
        maxLength={50}
        onBlur={() => onBlur("street", ruleStreet)}
      />
      <InputComponent
        {...house}
        id="house"
        title="House number:"
        onChangeValue={(value) => onChangeData("house", value)}
        width="25%"
        maxLength={10}
        onBlur={() => onBlur("house", ruleHouse)}
      />
    </Styled.Wrapper>
  );
};

export default RPBFPayToTR;
