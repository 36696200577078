import { Button, Modal } from "antd";
import { FC, ReactNode, useId, useMemo, useState } from "react";
import { Text, TextBold } from "../../../../../commonStyle";
import CommonModalComponent from "../../../../commonModal";
import * as Styled from "./style";

export type TGetLeaveButton = (
  onClickStay: () => void,
  onClickLeave: () => void
) => [ReactNode, ReactNode];
interface IProps {
  onClickStay: () => void;
  onClickLeave: () => void;
}

const dataTitle = {
  init: {
    title: "Leave page?",
    subTitle: "On leaving the page all filled-in data will be discarded.",
  },
  payment: {
    title: "Discard payment?",
    subTitle:
      "Save as draft to get back when ready. Discard to cancel payment and go back.",
  },
};

const NBModalLeave: FC<IProps> = ({ onClickStay, onClickLeave }) => {
  const idComponent = useId();

  const [data, setData] = useState({ ...dataTitle.init });

  const buttons = useMemo((): [ReactNode, ReactNode] => {
    const getLeaveButtons = (window as any).getLeaveButtons as
      | TGetLeaveButton
      | null
      | undefined;

    const mode: keyof typeof dataTitle = getLeaveButtons ? "payment" : "init";

    setData({ ...dataTitle[mode] });

    if (!getLeaveButtons) {
      return [
        <Button
          key={idComponent + "leave"}
          shape="round"
          size="large"
          style={{ padding: "6.4px 40px" }}
          onClick={onClickLeave}
          danger
        >
          Leave
        </Button>,
        <Button
          key={idComponent + "stay"}
          shape="round"
          size="large"
          style={{ padding: "6.4px 40px" }}
          onClick={onClickStay}
        >
          Stay
        </Button>,
      ];
    }

    return getLeaveButtons(onClickStay, onClickLeave);
  }, []);

  return (
    <CommonModalComponent
      onClose={onClickStay}
      title={data.title}
      message={data.subTitle}
      buttons={buttons}
    />
  );
};

export default NBModalLeave;
