import { useEffect, useState } from "react";
import { Button, Divider } from "antd";

import InputComponent from "../../../component/Global/Input";
import TextareaComponent from "../../../component/Global/Textarea";

import validateString, {
  IRulesString,
} from "../../../utils/validate/validateString";
import UserAPI from "../../../services/userAPI";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";

import { IContactFormAPI } from "../../../services/types";

import * as Styled from "./style";

const subjectRules: IRulesString = {
  isReq: true,
  min: 3,
  max: 100,
};

const textRules: IRulesString = {
  isReq: true,
  min: 3,
  max: 100,
};

interface IProps {
  dataForm: IContactFormAPI;
  setDataFormEdited: (edited: boolean) => void;
}

const ContactForm = ({ dataForm, setDataFormEdited }: IProps) => {
  const [queryInit] = useMutationAPI(UserAPI.sendContactMessage, {
    changeIsLoading: true,
  });
  const [subject, setSubject] = useState({ value: "", error: "" });
  const [text, setText] = useState({ value: "", error: "" });

  useEffect(() => {
    const isDefaultContent = subject.value === "" && text.value === "";
    setDataFormEdited(!isDefaultContent);
  }, [subject, text]);

  const onBlurSubject = () => {
    const error = validateString(subject.value, subjectRules);
    setSubject((prev) => ({ ...prev, error }));
    return error;
  };

  const onBlurText = () => {
    const error = validateString(text.value, textRules);
    setText((prev) => ({ ...prev, error }));
    return error;
  };

  const onReset = () => {
    setSubject({ value: "", error: "" });
    setText({ value: "", error: "" });
  };

  const IsErrorValue = () => {
    const subjectError = onBlurSubject();
    const textError = onBlurText();
    return subjectError || textError;
  };

  const onClickSend = () => {
    if (IsErrorValue()) return;
    onReset();
    return queryInit({
      contactSubject: subject.value,
      contactText: text.value,
    });
  };

  return (
    <Styled.Wrapper>
      <Divider />
      <InputComponent
        {...subject}
        placeholder={dataForm.fields.contactSubject}
        onChangeValue={(value) => setSubject({ error: "", value })}
        onBlur={onBlurSubject}
      />
      <TextareaComponent
        {...text}
        placeholder={dataForm.fields.contactText}
        onChangeValue={(value) => setText({ error: "", value })}
        disabledError={false}
        onBlur={onBlurText}
      />
      <Styled.ButtonWrapper>
        <Button shape="round" size="large" type="primary" onClick={onClickSend}>
          {dataForm.button}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

export default ContactForm;
