import { ColumnType } from "antd/es/table";
import { ReactNode } from "react";
import {
  IRowTable,
  TRTDetailsTableItemBodyAPI,
} from "../../services/types/table";
import { alignToPosition } from "../../utils/alingToPosition";
import TypeToTextMap from "../Global/TypeToTextMap";
import {
  IDataRowTable,
  ITable,
  ITableDetails,
  ITableDetailsTable,
} from "./types";

const getDataBodyTable = (
  dataProps: TRTDetailsTableItemBodyAPI[],
  prefixKey: string
) =>
  dataProps.map((row, index) => {
    const rowArray = Object.entries(row);

    const response = Object.fromEntries(
      rowArray.map(([key, { data, options }]) => {
        if (data[0][0].type === "empty") {
          return [key, "#empty"];
        }

        return [
          key,
          <TypeToTextMap
            data={data}
            position={alignToPosition[options?.align || "left"]}
          />,
        ];
      })
    );

    return {
      key: prefixKey + index,
      ...response,
    };
  });

export const getDataDetailsTable = (
  dataProps: IRowTable["details_table"]
): ITableDetailsTable[] =>
  (dataProps || []).map((props) => {
    if (!props)
      return {
        dataSource: [],
        columns: [],
      };

    const dataSourceBody = getDataBodyTable(props.body.rows, "body_");
    const dataSourceFooter = getDataBodyTable(props.footer.rows, "footer_");
    const dataSourceSpan = [...props.body.rows, ...props.footer.rows];

    const columns = (props.header.rows[0] || []).map(
      ({
        title,
        dataIndex,
        options,
      }): ColumnType<{ [key: string]: ReactNode }> => {
        const titleComp =
          title[0][0].type === "empty" ? (
            "#empty"
          ) : (
            <TypeToTextMap
              data={title}
              position={alignToPosition[options?.align || "left"]}
            />
          );

        return {
          title: titleComp,
          dataIndex,
          key: dataIndex,
          ...options,
          onCell: (_, index = 0) =>
            dataSourceSpan[index][dataIndex]?.options || {},
        };
      }
    );

    return {
      dataSource: [...dataSourceBody, ...dataSourceFooter],
      columns,
      options: {
        showHeader: !props.header?.options?.hidden,
        size: props.header?.options?.size,
      },
    };
  });

export const getDataRow = (
  table: ITable[],
  details: ITableDetails[][],
  details_table: IRowTable["details_table"][],
  idRows?: string[]
) => {
  const countRows = table[0]?.dataColumn.length || 0;

  return new Array(countRows).fill(1).map((_, index) => {
    const isEmptyDetails = !(details[index] || [])
      .map(({ data }) => !!data.length)
      .filter((item) => item).length;

    return {
      data: table.map(
        ({ dataColumn, width, positionText, title }): IDataRowTable => {
          return { lines: dataColumn[index], width, positionText, title };
        }
      ),
      details: !isEmptyDetails && details[index] ? details[index] : [],
      details_table: getDataDetailsTable(details_table[index]),
      idRow: idRows && idRows[index],
    };
  });
};
