import { IPayloadValidateSwift } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { IDataFS } from "../types";

const keyResponse: {
  [key in keyof Required<IPayloadValidateSwift["payload"]>]: keyof IDataFS;
} = {
  paymentAccountNumberTo: "BAccountNumber",
  paymentAmount: "DAmount",
  paymentBankBic: "BBic",
  paymentDescription: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
  paymentIsSameCompanyTransfer: "DIsInterTransfer",
  paymentName: "BCompany",
  paymentPriorityType: "DIsUrgent",
  paymentNameAddressCity: "BCity",
  paymentNameAddressNumber: "BHouseNumber",
  paymentNameAddressPostalCode: "BPostalCode",
  paymentNameAddressStreet: "BStreetAddress",
  paymentNameCountryId: "BCountry",
  paymentBankAba: "BAba",
  paymentCorrespondentAccount: "BCorrespAccount",
  paymentCorrespondentBankBic: "BCorrespBIC",
  paymentCorrespondentBankName: "BCorrespBankName",
  paymentNameAddressState: "BState",
  paymentSwiftFeeType: "DFee",
  paymentBankInstitutionNumber: "BInstitutionNumber",
  paymentBankTransitNumber: "BTransitNumber",
  accountId: "DAmount",
};

export const getErrorResponse = (
  errorProps: IErrorPayment<keyof IPayloadValidateSwift["payload"]>[]
): { key: keyof IDataFS; error: string }[] =>
  errorProps.flatMap(({ field, message }) => {
    if (field === "paymentNameAddressState") {
      return [
        {
          key: "BState",
          error: message,
        },
        {
          key: "BCaState",
          error: message,
        },
      ];
    } else {
      return [
        {
          key: keyResponse[field],
          error: message,
        },
      ];
    }
  });
