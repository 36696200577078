import styled from "styled-components";

export const Wrapper = styled.div`
  /* padding: 16px; */
  border-radius: 14px;
  /* box-shadow: 0 0 50px rgb(27, 41, 68, 10%); */
  transition: height 0.8s;
  background-color: white;
  &:hover {
    background-color: #f8f7fe;

    & > div:first-child > div:first-child > span:nth-child(3) {
      display: block;
    }
    & > div:first-child > div:last-child span {
      display: block;
    }
  }

  @media (max-width: 1024px) {
    & > div:first-child > div:first-child > span:nth-child(3) {
      display: block;
    }
    & > div:first-child > div:last-child span {
      display: block;
    }
  }
`;

interface IWrapperMainInfo {
  isCurson: boolean;
}

export const WrapperMainInfo = styled.div<IWrapperMainInfo>`
  display: flex;
  align-items: center;
  padding: 16px;

  cursor: ${({ isCurson }) => (isCurson ? "pointer" : "initial")};

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: start;

    & > div {
      width: 100%;
    }

    & > div:nth-child(1) {
      margin-bottom: 8px;
    }
  }
`;

interface IWrapperBody {
  isFirst: boolean;
  isLast: boolean;
}

const getWidthIcon = (isFirst: boolean, isLast: boolean) => {
  let width = 0;
  if (isFirst) width = +24;
  if (isLast) width = +24;
  return width;
};

export const WrapperBody = styled.div<IWrapperBody>`
  display: flex;
  align-items: center;
  width: ${({ isFirst, isLast }) =>
    `calc(100% - ${getWidthIcon(isFirst, isLast)}px)`};
  & > div:last-child > div:last-child {
    padding-right: 0px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: start;
    gap: 10px;

    & > div {
      width: 100%;
    }
  }
`;
