import { Route } from "react-router-dom";
import RecurringPaymentComponent from "../../pages/recurringPayment";
import RPBuilderComponent from "../../pages/recurringPaymentBuilder";

const RecurringRoute = () => {
  return (
    <Route path="recurring-payments">
      <Route path="" element={<RecurringPaymentComponent />} />
      <Route path="builder" element={<RPBuilderComponent />} />
    </Route>
  );
};

export default RecurringRoute;
