import { Alert } from "antd";
import { useEffect, useState } from "react";
import AmountComponent from "../../../../../../component/Global/Amount";
import SwitchComponent from "../../../../../../component/Global/Switch";
import validateNumber from "../../../../../../utils/validate/validateNumber";
import { InputSepa } from "../../../types";
import * as Styled from "./style";

const getIsAbleUrgent = () => {
  const date = new Date();
  const hours = date.getUTCHours();
  const day = date.getUTCDay();
  if (hours < 8 || hours > 12) return false;
  if (day === 0 || day === 6) return false;
  return true;
};

const PaymentDetaisDateFirst = ({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    formActions: { prepareMoney },
  },
}: {
  input: InputSepa;
}) => {
  const { DAmount, DIsUrgent } = dataFS;
  const { amountSymbol, balance } = dataCommon;

  const [isAbleUrgent, setIsAbleUrgent] = useState(false);

  useEffect(() => {
    setIsAbleUrgent(getIsAbleUrgent());
    const interval = setInterval(() => {
      setIsAbleUrgent(getIsAbleUrgent());
    }, 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!isAbleUrgent) {
      onChangeDataFS("DIsUrgent", false);
    }
  }, [isAbleUrgent]);

  return (
    <Styled.WrapperRow>
      <Styled.WrapperDate>
        <AmountComponent
          {...DAmount}
          onChangeValue={(value) => onChangeDataFS("DAmount", value)}
          title="Amount:"
          prefix={amountSymbol + " "}
          placeholder={amountSymbol + " 0.00"}
          charLimit={16}
          width="100%"
          id="DAmount"
          fieldActions={prepareMoney("DAmount", (value) =>
            validateNumber(
              value,
              {
                min: 0.01,
                max: balance,
              },
              {
                symbol: amountSymbol,
              }
            )
          )}
        />
      </Styled.WrapperDate>
      <Styled.WrapperTextInfo>
        <SwitchComponent
          checked={DIsUrgent.value}
          text="Urgent"
          onChange={(value) => {
            onChangeDataFS("DIsUrgent", value);
          }}
          disabled={!isAbleUrgent}
          id="DIsUrgent"
          textChange
        />

        <Alert message=" Please note that urgent transfers are available Monday till Friday from 08:00 till 13:00 CET" />
      </Styled.WrapperTextInfo>
    </Styled.WrapperRow>
  );
};

export default PaymentDetaisDateFirst;
