import { useEffect, useMemo, useState } from "react";
import AmountComponent from "../../../../../../component/Global/Amount";
import PickerComponent from "../../../../../../component/Global/Picker";
import validateNumber from "../../../../../../utils/validate/validateNumber";
import { InputFx } from "../../../types";
import PaymentRateComponent from "./Rate";
import * as Styled from "./style";
import { formatValue } from "react-currency-input-field";
import Big from "big.js";

interface IDataRate {
  paymentAmount: number;
  paymentAmountFormatted: string;
}

const initDataRate: IDataRate = {
  paymentAmount: 0,
  paymentAmountFormatted: "0",
};

const PaymentDetaisCurrencyFirst = ({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    formActions: { preparePickerRequired, prepareMoney },
    amountSymbol,
  },
}: {
  input: InputFx;
}) => {
  const { DAmount, DCurrency } = dataFS;
  const {
    dataCurrency,
    balance,
    dataRates,
    dataCurrencyDictionary,
    balanceCurrencySymbol,
  } = dataCommon;

  const [dataRate, setDataRate] = useState(initDataRate);

  const isErrorAmountCurrency = useMemo(
    () => !!DAmount.value.length && !!DCurrency.value.id.length,
    [DAmount, DCurrency]
  );

  useEffect(() => {
    if (dataRates) {
      const currency = dataCurrencyDictionary.find(
        (el) => el.currencyId == DCurrency.value.id
      );
      if (!currency) {
        setDataRate({
          paymentAmount: 0,
          paymentAmountFormatted: "",
        });
        return;
      }

      const rate = dataRates[currency.currencyCode];
      if (!rate) {
        setDataRate({
          paymentAmount: 0,
          paymentAmountFormatted: "",
        });
        return;
      }

      const resultAmount = new Big(DAmount.value)
        .times(rate)
        .round(2, Big.roundHalfUp)
        .toNumber();

      setDataRate({
        paymentAmount: resultAmount,
        paymentAmountFormatted: `${balanceCurrencySymbol} ${formatValue({
          value: resultAmount.toString(),
          decimalSeparator: ".",
          groupSeparator: ",",
          decimalScale: 2,
        })}`,
      });
    }
  }, [DCurrency, dataRates, DAmount]);

  return (
    <Styled.WrapperRow>
      <PickerComponent
        {...DCurrency}
        setValue={(value) => onChangeDataFS("DCurrency", value)}
        data={dataCurrency}
        title="Currency:"
        placeholder="Select Currency"
        id="DCurrency"
        fieldActions={preparePickerRequired("DCurrency", {
          revalidateDeps: ["DAmount"],
        })}
      />

      <AmountComponent
        value={!DCurrency.value.id.length ? "" : DAmount.value}
        onChangeValue={(value) => onChangeDataFS("DAmount", value)}
        title="Amount:"
        placeholder={
          !DCurrency.value.id.length
            ? "Select currency"
            : amountSymbol() + " 0.00"
        }
        prefix={amountSymbol() + " "}
        error={DAmount.error}
        charLimit={16}
        id="DAmount"
        fieldActions={prepareMoney("DAmount", (value, allValues) => {
          const errorRules =
            dataRate.paymentAmount > balance
              ? { min: 0.01, max: balance }
              : { min: 0.01 };

          return validateNumber(dataRate.paymentAmount, errorRules, {
            symbol: balanceCurrencySymbol,
          });
        })}
      />

      <PaymentRateComponent
        isAble={isErrorAmountCurrency}
        text={dataRate.paymentAmountFormatted}
      />
    </Styled.WrapperRow>
  );
};

export default PaymentDetaisCurrencyFirst;
