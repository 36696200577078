import { Button, InputRef } from "antd";
import { forwardRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContextMassPayment } from "../..";
import AuthTransfer from "../../../../component/authTransfer";
import { MassPaymentAPI } from "../../../../services/massPaymentAPI";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";

export interface IDataThirdStepPayment {
  amount: string;
  name: string;
}

const ThirdStepPayment = forwardRef<InputRef>((props, ref) => {
  const dataContext = useContext(ContextMassPayment);
  if (!dataContext) return <div />;

  const { data, setData, setStep, typeQuery } = dataContext;
  const { importId, dataThirdStep } = data;

  const [executeQuery, infoExecuteQuery] = useMutationAPI(
    MassPaymentAPI.execute
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (infoExecuteQuery.error || infoExecuteQuery.data) {
      navigate("/dashboard");
    }
  }, [infoExecuteQuery]);

  const onClickRestart = () => {
    setData((prev) => ({ ...prev, files: { value: [], error: "" } }));
    setStep(1);
  };

  const onClickExecute = (auth_code: string) => {
    executeQuery({ importId, payload: { auth_code }, typeQuery });
  };

  return (
    <AuthTransfer
      {...dataThirdStep}
      onClick={({ value }) => onClickExecute(value)}
      isLoadingAuth={infoExecuteQuery.isLoading}
      ref={ref}
    >
      <Button
        shape="round"
        size="large"
        onClick={onClickRestart}
        disabled={infoExecuteQuery.isLoading}
      >
        Restart Import
      </Button>
    </AuthTransfer>
  );
});

export default ThirdStepPayment;
