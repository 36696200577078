import { useContext } from "react";
import { ContextCardRequest } from "../../..";
import DatePickerComponent from "../../../../../component/Global/DatePicker";
import InputComponent from "../../../../../component/Global/Input";
import PickerComponent from "../../../../../component/Global/Picker";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import validateString from "../../../../../utils/validate/validateString";
import * as Styled from "./style";

const getDate = (time: number) => new Date(new Date().getTime() + time);

const CRPassportID = () => {
  const valueContext = useContext(ContextCardRequest);

  const { dataFS, dataCommon, onChangeDataFS, setErrorDataFS } = valueContext;

  const { PIDCountry, PIDDocNumber, PIDExpireDate, PIDIssueDate } = dataFS;

  const onBlurDoc = () => {
    const error = validateString(PIDDocNumber.value, {
      isReq: true,
      min: 3,
      max: 20,
    });

    setErrorDataFS("PIDDocNumber", error);
  };

  return (
    <WrapperWhiteBG title="Passport / ID">
      <Styled.Wrapper>
        <InputComponent
          {...PIDDocNumber}
          title="Document number:"
          onChangeValue={(value) => onChangeDataFS("PIDDocNumber", value)}
          onBlur={onBlurDoc}
          maxLength={20}
          id="PIDDocNumber"
        />
        <PickerComponent
          {...PIDCountry}
          title="Country of Issuing:"
          data={dataCommon.PIDCountryData}
          setValue={(value) => onChangeDataFS("PIDCountry", value)}
          placeholder="Select Country"
          id="PIDCountry"
        />

        <DatePickerComponent
          {...PIDIssueDate}
          title="Issue Date:"
          setDate={(value) => onChangeDataFS("PIDIssueDate", value)}
          maxDate={getDate(-24 * 60 * 60 * 1000)}
          id="PIDIssueDate"
        />
        <DatePickerComponent
          {...PIDExpireDate}
          title="Expire Date:"
          setDate={(value) => onChangeDataFS("PIDExpireDate", value)}
          minDate={getDate(24 * 60 * 60 * 1000)}
          id="PIDExpireDate"
        />
      </Styled.Wrapper>
    </WrapperWhiteBG>
  );
};

export default CRPassportID;
