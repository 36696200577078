import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalDeleteComponent from "../../../component/commonModal";
import WrapperBodyTitle from "../../../component/Wrappers/WrapperBodyTitle";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import { CryptoFiatPayInApi } from "../../../services/cryptoFiatPayIn";
import { IDataInitCryptoFiatPayIn } from "../../../services/cryptoFiatPayIn/types";
import CDTable from "./table";

const MainPageCryptoFiatPayIn = () => {
  const infoInitQuery = useQueryAPI(CryptoFiatPayInApi.init, {
    changeIsLoading: true,
  });

  const [queryDelete, infoQueryDelete] = useMutationAPI(
    CryptoFiatPayInApi.delete
  );
  const navigate = useNavigate();

  const [tableResp, setTableResp] = useState<IDataInitCryptoFiatPayIn[]>([]);
  const [selectIdTable, setSelectIdTable] = useState<string[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const response = infoInitQuery.data;
    if (response) {
      setTableResp(response.data.data);
    }
  }, [infoInitQuery]);

  useEffect(() => {
    const response = infoQueryDelete.data;
    if (response && response.data.result) {
      setIsOpenModal(false);
      setTableResp((prev) =>
        prev.filter(({ requestId }) => !selectIdTable.includes(requestId))
      );
      setSelectIdTable([]);
    }
  }, [infoQueryDelete]);

  const onClickNo = () => setIsOpenModal(false);

  const onClickYes = () => {
    queryDelete({ requestIds: selectIdTable });
    onClickNo;
  };

  return (
    <WrapperBodyTitle
      {...infoInitQuery.data?.data.page?.header}
      onClickButton={() => navigate("/crypto-fiat-pay-in-request/new-request")}
    >
      <CDTable
        tableResp={tableResp}
        selectIdTable={selectIdTable}
        setIsOpenModal={setIsOpenModal}
        setSelectIdTable={setSelectIdTable}
      />
      {isOpenModal && (
        <ModalDeleteComponent
          onClose={onClickNo}
          onClickYes={onClickYes}
          onClickNo={onClickNo}
          loadingYes={infoQueryDelete.isLoading}
          title="Delete request?"
        />
      )}
    </WrapperBodyTitle>
  );
};

export default MainPageCryptoFiatPayIn;
