import styled from "styled-components";

export const Wrapper = styled.div`
  box-shadow: 0 0 20px #1b29441c;
  margin-bottom: 24px;
  border-radius: 4px;
  background-color: white;
  & [data-row-key="footer"] {
    background-color: #fafafa;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
export const WrapperItem = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  padding: 12px;
  flex-wrap: wrap;
  &:last-child {
    border-bottom: unset;
  }
`;
