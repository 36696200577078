import { Button, Modal } from "antd";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthError } from "../../store/user";
import * as Styled from "./style";

interface IProps {
  info: string;
  title: string;
}

const Page403: FC<IProps> = ({ info, title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickOK = () => {
    dispatch(setAuthError({ status: false }));
    navigate("/login");
  };

  return (
    <div>
      <Modal open={true} closable={false} width={450}>
        <Styled.WrapperModal>
          <Styled.Title fontSize={22}>{title}</Styled.Title>
          <Styled.Info fontSize={16}>{info}</Styled.Info>
          <Styled.WrapperButton>
            <Button onClick={onClickOK} shape="round" size="large" block>
              Ok
            </Button>
          </Styled.WrapperButton>
        </Styled.WrapperModal>
      </Modal>
    </div>
  );
};

export default Page403;
