import { notification, Switch } from "antd";
import { FC, ReactNode } from "react";
import { Text } from "../../../commonStyle";
import * as Styled from "./style";

interface IProps {
  onChange: (value: boolean) => void;
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  text?: ReactNode;
  id?: string;
  textChange?: boolean;
  iniline?: boolean;
}

const SwitchComponent: FC<IProps> = ({
  text,
  id,
  textChange = false,
  iniline = false,
  ...propsSwitch
}) => {
  const onClickText = () => {
    if (
      !textChange ||
      propsSwitch.checked === undefined ||
      propsSwitch.disabled
    )
      return;
    propsSwitch.onChange(!propsSwitch.checked);
  };

  return (
    <Styled.Wrapper id={id} isInline={iniline}>
      <Switch {...propsSwitch} />
      {text && (
        <Text
          fontSize={16}
          onClick={onClickText}
          isCursor={
            textChange &&
            propsSwitch.checked !== undefined &&
            !propsSwitch.disabled
          }
        >
          {text}
        </Text>
      )}
    </Styled.Wrapper>
  );
};

export default SwitchComponent;
