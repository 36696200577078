import instance from "../../services";

/**
 * Handles file download by making a fetch request to the provided file URL.
 * @param {string} fileUrl - The URL of the file to be downloaded.
 * @param {string} filename - The desired filename for the downloaded file (optional).
 */
const handleDownload = async (fileUrl: string, filename: string) => {
  try {
    // Make a fetch request to the server with the file URL and include the authorization token.
    const response = await instance.get(fileUrl, {
      responseType: 'arraybuffer', // Set the responseType to 'arraybuffer' for binary data.
    });

    // Retrieve the file content as a blob.
    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    // Create a URL for the blob, making it accessible for download.
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element for triggering the download.
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "downloaded_file"; // Set the filename or use a default name.

    // Append the anchor element to the document body.
    document.body.appendChild(a);

    // Simulate a click on the anchor element to initiate the file download.
    a.click();

    // Remove the anchor element from the document body.
    document.body.removeChild(a);

    // Revoke the URL to release resources.
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // Handle any errors that occur during the file download process.
    console.error("Error downloading file:", error);
    // You can add additional error handling logic or display an error message to the user.
  }
};

export default handleDownload;
