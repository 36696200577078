import RPBFSDocuments from "./documents";
import RPBFSFooter from "./footer";
import RPBFSGeneral from "./general";
import RPBFSPayTo from "./payTo";

const RPBuilderFS = () => {
  return (
    <div>
      <RPBFSGeneral />
      <RPBFSPayTo />
      <RPBFSDocuments />
      <RPBFSFooter />
    </div>
  );
};

export default RPBuilderFS;
