import { useContext } from "react";
import { ContextInvoiceBuilder } from "../../..";
import InputComponent from "../../../../../component/Global/Input";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import validateString from "../../../../../utils/validate/validateString";
import * as Styled from "./style";

const IBFSDetails = () => {
  const { dataFS, dataCommon, onChangeData, onErrorData } = useContext(
    ContextInvoiceBuilder
  );
  const { paymentDetails, paymentPrefix } = dataCommon;
  const { GAccount, DReference } = dataFS;
  const DAccountID = GAccount.value.id;

  const onBlur = () => {
    if (!!paymentPrefix.length) return;
    const error = validateString(DReference.value, {
      min: 3,
      max: 100,
    });

    onErrorData("DReference", error);
  };

  return (
    <WrapperWhiteBG title="Payment details">
      {!paymentDetails.length || !DAccountID.length ? (
        <div> Please select the Account to Receive funds to above </div>
      ) : (
        <TypeToTextMap
          data={
            paymentDetails.find(({ id }) => id === DAccountID)?.data || [
              [{ text: "This account does not exist", type: "normal" }],
            ]
          }
        />
      )}

      <Styled.WrapperInput>
        <InputComponent
          {...DReference}
          title="Payment description:"
          addonBefore={paymentPrefix}
          onChangeValue={(value) => onChangeData("DReference", value)}
          onBlur={onBlur}
          id="DReference"
          maxLength={100}
        />
      </Styled.WrapperInput>
    </WrapperWhiteBG>
  );
};

export default IBFSDetails;
