import { useContext, useId, useMemo } from "react";
import { ContextCardsStatement } from "../..";
import { TextBold } from "../../../../commonStyle";
import useSizePage from "../../../../utils/hookSizePage";
import { useAppSelector } from "../../../../utils/useState";
import { TypeToText } from "../../../../utils/typeToText";
import * as Styled from "./style";

const InfoBalencesViewStatement = () => {
  const idComponent = useId();
  const valueContext = useContext(ContextCardsStatement);

  const balances = valueContext.data.infoBalances;

  const { width } = useSizePage();

  const data = useMemo(
    () => [
      balances.starting_balance,
      balances.credit_turnover,
      balances.debit_turnover,
      balances.ending_balance,
    ],
    [balances]
  );

  return (
    <Styled.Wrapper>
      {data.map(({ title, ...item }, index) => {
        return (
          <Styled.WrapperItem key={idComponent + "." + index}>
            <TextBold fontSize={width > 1024 ? 18 : 14}>{title}</TextBold>
            <TypeToText
              {...item}
              styleData={{ fontSize: width > 1024 ? 16 : 14 }}
            />
          </Styled.WrapperItem>
        );
      })}
    </Styled.Wrapper>
  );
};

export default InfoBalencesViewStatement;
