import { useContext } from "react";
import { ContextCardRequest } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import PickerComponent from "../../../../../../../component/Global/Picker";
import validateString from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

const CRSDFirstRow = () => {
  const valueContext = useContext(ContextCardRequest);

  const { dataFS, dataCommon, onChangeDataFS, setErrorDataFS } = valueContext;

  const { SDCountry, SDCity, SDRegion } = dataFS;

  const onBlur = (key: "SDCity" | "SDRegion") => {
    const error = validateString(dataFS[key].value, {
      isReq: true,
      min: 3,
      max: 50,
    });

    setErrorDataFS(key, error);
  };

  return (
    <Styled.Wrapper>
      <PickerComponent
        {...SDCountry}
        title="Country:"
        data={dataCommon.RACountry}
        setValue={(value) => onChangeDataFS("SDCountry", value)}
        placeholder="Select Country"
      />
      <InputComponent
        {...SDCity}
        title="City:"
        maxLength={50}
        onChangeValue={(value) => onChangeDataFS("SDCity", value)}
        onBlur={() => onBlur("SDCity")}
      />
      <InputComponent
        {...SDRegion}
        title="Region/District:"
        onChangeValue={(value) => onChangeDataFS("SDRegion", value)}
        onBlur={() => onBlur("SDRegion")}
      />
    </Styled.Wrapper>
  );
};

export default CRSDFirstRow;
