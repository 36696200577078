import { Button } from "antd";
import { FC } from "react";
import * as Styled from "./style";

interface IData {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

interface IProps {
  login: IData;
  forgot: IData;
}

const LFSButtons: FC<IProps> = ({ login, forgot }) => {
  return (
    <Styled.Wrapper>
      <Button shape="round" size="large" {...login} >
        Login
      </Button>
      <Button shape="round" size="large" type="link" {...forgot}>
        Forgot your password?
      </Button>
    </Styled.Wrapper>
  );
};

export default LFSButtons;
