import { Button } from "antd";
import { FC } from "react";
import { Text } from "../../../../commonStyle";
import * as Styled from "./style";

interface IProps {
  onCkick: () => void;
  isLoading: boolean;
  isDisabled: boolean;
}

const EPFooter: FC<IProps> = ({ onCkick, isLoading, isDisabled }) => {
  return (
    <Styled.Wrapper>
      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onCkick}
        loading={isLoading}
        disabled={isDisabled}
      >
        UPDATE PROFILE
      </Button>
      <Text textAling="center">
        You will be asked to re-login after updating credentials
      </Text>
    </Styled.Wrapper>
  );
};

export default EPFooter;
