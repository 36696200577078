import { IDataHintsInput } from "../../component/Global/Input/hints";
import { IItemPicker } from "../../component/Global/Picker";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import { IPayloadInvoiceValid } from "../../services/invoiceTemplateBuilder/types/payload";
import { IResInitBuilder } from "../../services/invoiceTemplateBuilder/types/response";
import { getValueError } from "../payments/data";
import { IContext, IDataFS, IDataCommon, IDataSS } from "./types";

export const initDataFS: IDataFS = {
  GLogo: getValueError(""),
  GAccount: getValueError({ text: "", id: "" }),
  GTypeTax: getValueError({ text: "", id: "" }),

  DReference: getValueError(""),

  RemState: getValueError({ text: "", id: "" }),
  RemCity: getValueError(""),
  RemCompanyName: getValueError(""),
  RemCountry: getValueError({ text: "", id: "" }),
  RemEmail: getValueError(""),
  RemHouseNumber: getValueError(""),
  RemPhone: getValueError(""),
  RemPostalCode: getValueError(""),
  RemStreetAddress: getValueError(""),
};

export const initDataSS: IDataSS = {
  logo: "",
  templateName: getValueError(""),
  beneficiary: {
    title: "",
    data: [],
  },
  remmiter: {
    title: "",
    data: [],
  },
  details: {
    title: "",
    data: [],
  },
  table: [],
  footer: [],
};

export const initDataCommon: IDataCommon = {
  dataRemHints: [],
  dataGAccount: [],
  dataCurrencyAccount: [],
  currency: "",
  dataGTypeTax: [],
  dataPaymentPrefix: [],
  paymentPrefix: "",
  dataRemCountry: [],
  dataRemState: [],
  beneficiary: [],
  paymentDetails: [],
  dataTable: [
    {
      title: { value: "", error: "" },
      qty: { value: "1", error: "" },
      rate: { value: "", error: "" },
      tax: { value: "0", error: "" },
    },
  ],
  USID: "",
  isUS: false,
  invoiceID: "",
};

const initSet = () => console.log("Init Context Invoice Builder");

export const initContext: IContext = {
  step: 1,
  setStep: initSet,
  dataFS: initDataFS,
  setDataFS: initSet,
  dataSS: initDataSS,
  setDataSS: initSet,
  dataCommon: initDataCommon,
  setDataCommon: initSet,
  onChangeData: initSet,
  onErrorData: initSet,
  validateOnBlur: initSet,
};

export const getBeneficiaryDetails = (
  data: IResInitBuilder["beneficiaries_list"]["dropdown_options"]["data"],
  key: "beneficiary_data" | "payment_data"
): IDataCommon["beneficiary"] => {
  return data.map((item) => ({
    id: item.value,
    data: item[key],
  }));
};

export const getAccountData = (
  data: IResInitBuilder["beneficiaries_list"]["dropdown_options"]["data"]
): IDataCommon["dataGAccount"] => {
  return data.map(({ data, value }) => ({
    text: data.map(({ text }) => text).join(""),
    id: value,
    component: <TypeToTextMap data={[data]} />,
  }));
};

export const getCurrencyAccountData = (
  data: IResInitBuilder["beneficiaries_list"]["dropdown_options"]["data"]
): IDataCommon["dataCurrencyAccount"] => {
  return data.map(({ currencySymbol, value }) => ({
    text: currencySymbol,
    id: value,
  }));
};

export const getCountryData = (
  data: IResInitBuilder["dictionaries"]["countries"]
): IDataCommon["dataRemCountry"] => {
  return data.map(({ countryId, countryName }) => ({
    text: countryName,
    id: countryId,
  }));
};

export const getStateData = (
  data: IResInitBuilder["dictionaries"]["us_states"]
): IDataCommon["dataRemState"] => {
  return data.map(({ stateCode, stateName }) => ({
    text: stateName,
    id: stateCode,
  }));
};

export const getHintsData = (
  data: IResInitBuilder["remitters"],
  dataCountry: IDataCommon["dataRemCountry"]
): IDataHintsInput<Partial<IDataFS>>[] => {
  return data.map((item) => {
    const country = dataCountry.find(
      ({ id }) => item.remitterCountryId === id
    ) || { text: "", id: "" };

    return {
      title: { text: item.remitterName || "", type: "em" },
      subTitle: item.details || [],
      data: {
        RemCity: getValueError(item.remitterCity || ""),
        RemPostalCode: getValueError(item.remitterPostalCode || ""),
        RemCountry: getValueError(country),
        RemHouseNumber: getValueError(item.remitterHouseNumber || ""),
        RemStreetAddress: getValueError(item.remitterStreetAddress || ""),
      },
    };
  });
};

export const getDataTax = (
  data: IResInitBuilder["dictionaries"]["taxationTypes"]
) =>
  data.map(({ taxationName, taxationType }) => ({
    text: taxationName,
    id: taxationType,
  }));

export const getDataPrefix = (
  data: IResInitBuilder["beneficiaries_list"]["dropdown_options"]["data"]
): IItemPicker[] => {
  return data.map(({ value, referencePrefix }) => {
    return {
      text: referencePrefix || "",
      id: value,
    };
  });
};

const resToData: {
  [key in keyof IPayloadInvoiceValid["payload"]]: keyof IDataFS;
} = {
  accountId: "GAccount",
  invoiceData: "GTypeTax",
  invoiceReference: "DReference",
  remitterCity: "RemCity",
  remitterCountryId: "RemCountry",
  remitterEmail: "RemEmail",
  remitterHouseNumber: "RemHouseNumber",
  remitterName: "RemCompanyName",
  remitterPhone: "RemPhone",
  remitterPostalCode: "RemPostalCode",
  remitterStreetAddress: "RemStreetAddress",
  taxationType: "GTypeTax",
  remitterState: "RemState",
};

export const getResFormToData = (
  form: IResInitBuilder["form"],
  logo: IResInitBuilder["logo"],
  dataAccount: IItemPicker[],
  dataCountry: IItemPicker[],
  dataState: IItemPicker[],
  dataTax: IItemPicker[]
) => {
  let responseData: Partial<{ [key in keyof IDataFS]: string | IItemPicker }> =
    {};
  const dataTable: IDataCommon["dataTable"] = [];
  if (!form) return { dataForm: [], dataTable: initDataCommon.dataTable };

  const keysForm = Object.keys(form) as (keyof typeof form)[];

  keysForm.forEach((key) => {
    if (key === "invoiceData") {
      const data = form[key];
      if (!data) return;

      data.forEach((item) => {
        dataTable.push({
          title: getValueError(item.invoiceItemDescription),
          qty: getValueError(item.invoiceItemQty.toString()),
          rate: getValueError(item.invoiceItemRate.toString()),
          tax: getValueError(item.invoiceItemTax.toString()),
        });
      });
      return;
    }

    responseData[resToData[key]] = form[key];
  });

  responseData = {
    ...responseData,
    GLogo: logo?.fileContents || "",
    GAccount:
      dataAccount.find(({ id }) => id === form.accountId) ||
      initDataFS.GAccount.value,
    GTypeTax:
      dataTax.find(({ id }) => id === form.taxationType) ||
      initDataFS.GTypeTax.value,
    RemCountry:
      dataCountry.find(({ id }) => id === form.remitterCountryId) ||
      initDataFS.RemCountry.value,
    RemState:
      dataState.find(({ id }) => id === form.remitterState) ||
      initDataFS.RemCountry.value,
  };

  const response = Object.entries(responseData) as [
    keyof IDataFS,
    string | IItemPicker
  ][];

  return {
    dataForm: response.map(([key, value]) => ({ key, value })),
    dataTable: !dataTable.length ? initDataCommon.dataTable : dataTable,
  };
};
