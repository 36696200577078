import * as Styled from "./style";
import { FC, useEffect, useState } from "react";
import BodyReset from "./body";
import SuccessResReset from "./successRes";
import { AxiosResponse } from "axios";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import { IResponseReset } from "../../services/userAPI/types/response";
import OptionalErrorAlert from "../../component/OptionalErrorAlert";

interface IProps {
  title: string;
  textButton: string;
  promiseReset: (email: string) => Promise<AxiosResponse<IResponseReset>>;
}

const Reset: FC<IProps> = ({ title, textButton, promiseReset }) => {
  const [query, infoQuery] = useMutationAPI(promiseReset, { reCaptcha: true });
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [isCorrectEmail, setIsCorrectEmail] = useState(true);

  useEffect(() => {
    const responseError = infoQuery.error?.response?.data;

    if (responseError) {
      setErrors(
        responseError.error ||
          responseError.notification?.data[0].text ||
          process.env.REACT_APP_UNKNOWN_ERROR ||
          "Unknown Error"
      );
    }
  }, [infoQuery]);

  const onChangeEmail = (email: string) => {
    setEmail(email);
    setIsCorrectEmail(true);
    setErrors("");
  };

  const validateEmail = (email: string) => {
    const reg = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return reg.test(email);
  };

  const onClickButton = async () => {
    if (validateEmail(email)) {
      query(email);
    } else {
      setIsCorrectEmail(false);
      setErrors("Please enter a correct email address");
    }
  };

  const onBlurInput = () => {
    if (!validateEmail(email)) {
      setErrors("Please enter a correct email address");
    }
    if (!email.length) {
      setErrors("Email is required");
    }
  };

  return (
    <>
      <OptionalErrorAlert
        message={isCorrectEmail ? "" : "Please enter a correct email address"}
      />
      <Styled.Title>{title}</Styled.Title>
      {infoQuery.data ? (
        <SuccessResReset />
      ) : (
        <BodyReset
          email={email}
          onChangeEmail={onChangeEmail}
          errors={errors}
          onBlurInput={onBlurInput}
          onClickButton={onClickButton}
          textButton={textButton}
          isLoading={infoQuery.isLoading}
        />
      )}
    </>
  );
};

export default Reset;
