import { IItemPicker } from "../../component/Global/Picker";
import { ITable } from "../../component/table/types";
import { IPayloadViewStatement } from "../../services/pagesAPI/types/payload";
import { IDataDropdown } from "../../services/types";
import { IItemTableStatement } from "../../services/types/table";
import { getDateWithTimezone } from "../../utils/getDateWithTimezone";
import { IContext, IData, TOnChangeData } from "./types";

const initialDate = new Date();
const initialDateEnd = getDateWithTimezone({
  year: initialDate.getFullYear(),
  month: initialDate.getMonth(),
  date: initialDate.getDate(),
});
const initialDateStart = getDateWithTimezone({
  year: initialDate.getFullYear(),
  month: initialDate.getMonth() - 1,
  date: initialDate.getDate(),
});

export const initData: IData = {
  startDate: initialDateStart,
  endDate: initialDateEnd,
  accountId: { text: "", id: "" },
  accountIdData: [],
  interval: { text: "", id: "" },
  intervalData: [],
  paymentType: { text: "", id: "" },
  paymentTypeData: [],
  infoHeader: {
    statement_heading: [],
    statement_dates: [],
    statement_subtitle: [],
    statement_title: [],
  },
  infoBalances: {
    starting_balance: { text: "initial", title: "Title", type: "normal" },
    debit_turnover: { text: "initial", title: "Title", type: "normal" },
    credit_turnover: { text: "initial", title: "Title", type: "normal" },
    ending_balance: { text: "initial", title: "Title", type: "normal" },
  },
  downloadData: [],
};

const initSet = () => console.log("Init Card Statement");

export const initContext: IContext = {
  data: initData,
  setData: initSet,
  onChangeData: initSet,
};

export const getPayload = (
  data: IData,
  isInit: boolean
): IPayloadViewStatement => {
  const { accountId, paymentType, startDate, endDate, interval } = data;

  const response = {
    accountId: accountId.id,
    paymentType: paymentType.id,
    interval: interval.id,
  };

  if (isInit || !!interval.id.length) return response;

  return {
    ...response,
    paymentDateFrom_submit: startDate.toISOString().split("T")[0],
    paymentDateTo_submit: endDate.toISOString().split("T")[0],
  };
};

export const getDataTable = (
  dataRow: IItemTableStatement[],
  size: number
): ITable[] => [
  {
    title: "Date",
    width: "150px",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.date),
  },
  {
    title: "Beneficiary / Remitter",
    width: "calc((100% - 450px)/2)",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.counterparty),
  },
  {
    title: "Description",
    width: "calc((100% - 450px)/2)",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.description),
  },
  {
    title: "Amount",
    width: "150px",
    positionText: size <= 1024 ? "left" : "right",
    dataColumn: dataRow.map((item) => item.amount),
  },
  {
    title: "Balance",
    width: "150px",
    positionText: size <= 1024 ? "left" : "right",
    dataColumn: dataRow.map((item) => item.balance),
  },
];

export const getValueToID = (dataProps: IDataDropdown[]): IItemPicker[] => {
  return dataProps.map(({ text, value }) => ({
    text,
    id: value,
  }));
};
