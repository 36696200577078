import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  & > div {
    min-width: 300px;
    width: 40%;
  }
`;
