import instance from "..";
import {
  IPayloadDelete,
  IPayloadDeleteFile,
  IPayloadExecute,
  IPayloadUploadFile,
  IPayloadValidate,
} from "./types/payload";
import {
  IResponseDelete,
  IResponseDelte,
  IResponseInit,
  IResponseInitUpload,
  IResponseUpload,
  IResponseValidate,
} from "./types/response";

export const CreditDocApi = {
  init: () => instance.get<IResponseInit>("/app/init-invoices"),
  delete: (payload: IPayloadDelete) =>
    instance.post<IResponseDelete>(
      "/transfers/payment-request/delete",
      payload
    ),
  initUplaod: () =>
    instance.get<IResponseInitUpload>("/transfers/init/payment-request"),
  validate: ({ requestId, payload }: IPayloadValidate) =>
    instance.post<IResponseValidate>(
      "/transfers/validate/payment-request/" + requestId,
      payload
    ),
  execute: ({requestId}:IPayloadExecute) => instance.post("/transfers/payment-request/execute/"+requestId),
  files: {
    upload: ({ requestId, formData }: IPayloadUploadFile) =>
      instance.post<IResponseUpload>(
        "/transfers/payment-request-documents/upload/" + requestId,
        formData
      ),
    delete: ({ id }: IPayloadDeleteFile) =>
      instance.get<IResponseDelte>(
        "/transfers/payment-request-documents/delete/" + id
      ),
  },
};
