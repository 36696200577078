import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import { BIC_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions, PreparedFieldActions } from "../form";

const rulesCorrespondentBIC: IRulesString = { min: 8, max: 11 };
const rulesBIC: IRulesString = { ...rulesCorrespondentBIC, isReq: true };

type IBICDataFS = {
  BBic: IValueError<string>;
};

type IBICCorrespondentDataFS = {
  BCorrespBIC: IValueError<string>;
};

interface BICInput {
  dataFS: IBICDataFS;
  onChangeDataFS: TOnChangeDataFS<IBICDataFS>;
  formActions: FormActions<IBICDataFS>;
}

interface BICCorrespondentInput {
  dataFS: IBICCorrespondentDataFS;
  onChangeDataFS: TOnChangeDataFS<IBICCorrespondentDataFS>;
  formActions: FormActions<IBICCorrespondentDataFS>;
}

interface IBICInputTemplate {
  data: IValueError<string>;
  title: string;
  onChangeValue: (value: string) => void;
  id: string;
  fieldActions: PreparedFieldActions;
}

const BICInputTemplate = ({
  data,
  title,
  onChangeValue,
  id,
  fieldActions,
}: IBICInputTemplate) => {
  return (
    <InputComponent
      {...data}
      onChangeValue={onChangeValue}
      title={title}
      maxLength={rulesBIC.max}
      regex={BIC_REGEX}
      onLostFocusActions={[
        LostFocusActions.REMOVE_WHITESPACES,
        LostFocusActions.UPPERCASE,
      ]}
      fieldActions={fieldActions}
      id={id}
    />
  );
};

export const BICInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
  isReq,
}: {
  input: BICInput;
  isReq?: boolean;
}) => {
  return (
    <BICInputTemplate
      data={dataFS.BBic}
      title="BIC:"
      onChangeValue={(value) => onChangeDataFS("BBic", value)}
      fieldActions={prepareStringRulesField("BBic", {
        ...rulesBIC,
        isReq: isReq ?? rulesBIC.isReq,
      })}
      id="BBic"
    />
  );
};

export const BICCorrespondentInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: BICCorrespondentInput;
}) => {
  return (
    <BICInputTemplate
      data={dataFS.BCorrespBIC}
      title="Correspondent BIC:"
      onChangeValue={(value) => onChangeDataFS("BCorrespBIC", value)}
      fieldActions={prepareStringRulesField(
        "BCorrespBIC",
        rulesCorrespondentBIC
      )}
      id="BCorrespBIC"
    />
  );
};
