import { FC, useEffect, useId, useMemo, useState } from "react";
import { IItemPicker, IPropsPicker } from "../../component/Global/Picker";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import CompanyAtBank from "../../component/companyAtBank";
import ItemStatments, { IPropsItemStatments } from "./Item";
import WrapperWhiteBG from "../../component/Wrappers/wrapperWhiteBG";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import { AxiosResponse } from "axios";
import { IResponseStatements } from "../../services/pagesAPI/types/response";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeOpenType } from "../../store/user";
import { responseToData } from "./data";
import { Empty } from "antd";

interface IProps {
  openType: string;
  promise: () => Promise<AxiosResponse<IResponseStatements, any>>;
}

interface IDataItem extends IPropsItemStatments {
  params: {
    bankId: string;
    companyId: string;
  };
}

const initialCompany = {
  text: "All Companies",
  id: "all-companies",
};

const initialBank = {
  text: "All Banks",
  id: "all-banks",
};

const StatementsNewPayment: FC<IProps> = ({ openType, promise }) => {
  const idComponent = useId();
  const infoQuery = useQueryAPI(promise, { changeIsLoading: true });
  const [data, setData] = useState<IDataItem[]>([]);
  const [company, setCompany] = useState<IItemPicker>(initialCompany);
  const [dataCompanies, setDataCompanies] = useState<IItemPicker[]>([]);
  const [bank, setBank] = useState<IItemPicker>(initialBank);
  const [dataBanks, setDataBanks] = useState<IItemPicker[]>([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeOpenType({
        openType,
      })
    );
  }, [openType]);

  const getIsValidItem = ({ params }: IDataItem) => {
    const isBank = params.bankId === bank.id || bank.id === "all-banks";
    const isCompany =
      params.companyId === company.id || company.id === "all-companies";
    return { isBank, isCompany };
  };

  const pickersData = useMemo(
    (): IPropsPicker[] => [
      {
        value: company,
        setValue: setCompany,
        data: dataCompanies.filter(({ id }) => {
          const item = data
            .filter((itemF) => getIsValidItem(itemF).isBank)
            .find(({ params }) => params.companyId === id);
          if (id === "all-companies") return true;
          return !!item;
        }),
        disabledError: true,
      },
      {
        value: bank,
        setValue: setBank,
        data: dataBanks.filter(({ id }) => {
          const item = data
            .filter((itemF) => getIsValidItem(itemF).isCompany)
            .find(({ params }) => params.bankId === id);
          if (id === "all-banks") return true;
          return !!item;
        }),
        disabledError: true,
      },
    ],
    [company, setCompany, bank, setBank, dataCompanies, dataBanks]
  );

  const dataItemFiltered = useMemo(
    () =>
      data.filter((item) => {
        const { isBank, isCompany } = getIsValidItem(item);
        return isBank && isCompany;
      }),
    [bank, company, data]
  );

  useEffect(() => {
    if (infoQuery.data) {
      const { data, filters } = infoQuery.data.data;
      const { companies_filter, banks_filter } = filters;

      setData(responseToData(data, navigate, idComponent + ".Button"));
      setCompany(initialCompany);
      setBank(initialBank);
      setDataCompanies(
        companies_filter?.dropdown_options?.data?.map(({ text, value }) => ({
          text,
          id: value,
        })) ?? []
      );
      setDataBanks(
        banks_filter?.dropdown_options?.data?.map(({ text, value }) => ({
          text,
          id: value,
        })) ?? []
      );
    }
  }, [infoQuery.data]);

  return (
    <WrapperBodyTitle {...infoQuery.data?.data.page?.header}>
      <CompanyAtBank pickers={pickersData} />
      <WrapperWhiteBG isBorder>
        {!dataItemFiltered.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          dataItemFiltered.map((props, index) => (
            <ItemStatments {...props} key={idComponent + index} />
          ))
        )}
      </WrapperWhiteBG>
    </WrapperBodyTitle>
  );
};

export default StatementsNewPayment;
