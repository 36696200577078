import { IPayloadValidateBulk } from "../../../../../services/paymentAPI/types/payload";
import validateNumber from "../../../../../utils/validate/validateNumber";
import validateString from "../../../../../utils/validate/validateString";
import { IDataFS } from "../../types";

export const validateData = (
  data: IDataFS,
  amountSymbol: string,
  isRequireFiles: boolean
) => {
  const keys = Object.keys(data) as Extract<keyof IDataFS, string>[];

  const getError = (key: Extract<keyof IDataFS, string>, error?: string) => ({
    key,
    error: error || "",
  });

  const error = keys.map((key) => {
    if (key === "DAmount") {
      return getError(
        key,
        validateNumber(
          parseFloat(data[key].value),
          { min: 0.01 },
          {
            symbol: amountSymbol,
          }
        )
      );
    }

    const value = data[key].value;

    if (value instanceof Array && isRequireFiles && !value.length) {
      return getError(key, "Document(s) required");
    }

    if (value instanceof Date || value instanceof Array || !isRequireFiles) {
      return getError(key);
    }

    if (typeof value === "boolean") {
      return getError(key);
    }

    if (typeof value === "string") {
      const error = validateString(value, { isReq: true });
      return getError(key, error);
    }

    const error = validateString(value.id, { isReq: true });
    return getError(key, error);
  });

  return error;
};

export const getPayload = (
  data: IDataFS,
  accountId: string,
  useCompanyName: boolean,
  currencyId: string
): IPayloadValidateBulk["payload"] => {
  let payload: IPayloadValidateBulk["payload"] = {
    accountId,
    accountIdTo: data.BCompany.value.id,
    fxPaymentAmount: parseFloat(data.DAmount.value) || 0,
    paymentDescription: data.DPaymentDescription.value,
    paymentDescriptionCompanyName: useCompanyName && data.DIsCompanyName.value,
    fxPaymentCurrencyId: currencyId,
  };

  return payload;
};
