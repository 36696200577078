import IBFSBeneficiary from "./beneficiary";
import IBFSContinue from "./continue";
import IBFSGeneral from "./general";
import IBFSFooter from "./footer";
import IBFSRemitter from "./remitter";
import * as Styled from "./style";
import IBFSTable from "./table";
import IBFSDetails from "./details";

const IBFirstStep = () => {
  return (
    <Styled.Wrapper>
      <IBFSGeneral />
      <IBFSBeneficiary />
      <IBFSDetails />
      <IBFSRemitter />
      <IBFSTable />
      <IBFSFooter />
      <IBFSContinue />
    </Styled.Wrapper>
  );
};

export default IBFirstStep;
