import { FC, useContext } from "react";
import { ContextCredDoc } from "../../..";
import TransferDocument from "../../../../../component/Global/transferDocument";
import {
  IFileToFormData,
  IFileTransferDocument,
} from "../../../../../component/Global/transferDocument/types";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { CreditDocApi } from "../../../../../services/creditDoc";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";

interface IProps {
  setValue: (value: IFileTransferDocument[]) => void;
  setError: (value: string) => void;
}

const CDUTransferDocument: FC<IProps> = ({ setError, setValue }) => {
  const valueContext = useContext(ContextCredDoc);

  const { data, step } = valueContext;
  const { requestId, files } = data;

  const [queryUpload] = useMutationAPI(CreditDocApi.files.upload);
  const [queryDelete] = useMutationAPI(CreditDocApi.files.delete);

  const onUpload = async (data: IFileToFormData[]) => {
    const dataResponse = await Promise.all(
      data.map(({ formData }) => queryUpload({ requestId, formData }))
    );
    return dataResponse.map(({ data: response, error: responseError }) => {
      if (response && response.data.status === "done") {
        const { name, documentId: id } = response.data;
        return { name, id, isLoading: false };
      }
      if (responseError && responseError.response) {
        return "";
      }
      if (response && response.data.status === "file_exists") {
        return "";
      }
      return "Unknow error";
    });
  };

  const onDelete = async (id: string) => {
    const response = await queryDelete({ id });
    if (!response.data) return false;
    return response.data.data.result;
  };
  return (
    <WrapperWhiteBG title="Payment Documents">
      <TransferDocument
        {...files}
        id="files"
        setValue={setValue}
        setError={setError}
        onDelete={onDelete}
        onUpload={onUpload}
        isAble={step === 1}
        accept=".jpeg, .jpg, .png, .pdf, .doc, .docx, .xls, .xlsx"
        multiple
        isRequired
      />
    </WrapperWhiteBG>
  );
};

export default CDUTransferDocument;
