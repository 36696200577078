import styled from "styled-components";

export const Title = styled.div`
  color: #041e42;
  margin-bottom: 20px;
  text-align: center;
`;

export const WrapperButton = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 4px;
`;

export const Info = styled.div`
  margin-top: 15px;
  text-align: start;
  font-size: 14px;
  color: #041e42;
  font-family: Poppins-light;
`;
