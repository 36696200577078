import { FC } from "react";
import { Text, TextBold } from "../../../../../commonStyle";
import * as Styled from "./style";

interface IProps {
  totalAmount: string;
}

const BTTTotal: FC<IProps> = ({ totalAmount }) => {
  return (
    <Styled.WrappperTotal>
      <Text fontSize={18}>Total:</Text>
      <TextBold fontSize={18}>{totalAmount}</TextBold>
    </Styled.WrappperTotal>
  );
};

export default BTTTotal;
