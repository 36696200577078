import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import IBDImgUpload from "./imgUpload";
import IBGFirstRow from "./rows/firstRow";


const IBFSGeneral = () => {
  return (
    <WrapperWhiteBG title="General">
      <IBDImgUpload />
      <IBGFirstRow />
    </WrapperWhiteBG>
  );
};

export default IBFSGeneral;
