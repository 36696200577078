import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import WrapperPayment from "../../component/Wrappers/wrapperPayment";
import { InvoiceBuilderAPI } from "../../services/invoiceBuilder";
import { setIsQuestionLeave } from "../../store/user";
import { getChangeError } from "../../utils/getChangeError";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import { useAppSelector } from "../../utils/useState";
import {
  getResFormToData,
  initContext,
  initDataCommon,
  initDataFS,
  initDataSS,
  initStaticDataFS,
} from "./data";
import InvoiceUseFS from "./step/firstStep";
import InvoiceUseSS from "./step/secondStep";
import { useOpenBrowserModalAfterPageReload } from "../../utils/useOpenBrowserModalAfterPageReload/useOpenBrowserModalAfterPageReload";

interface ILocation {
  state?: {
    invoiceId?: string;
  };
}

export const ContextInvoiceUse = createContext(initContext);

const InvoiceUseComponent = () => {
  const invoiceID = (useLocation() as ILocation).state?.invoiceId;

  const infoInitQuery = useQueryAPI(InvoiceBuilderAPI.initBuilder, {
    payload: { invoiceID },
    changeIsLoading: true,
  });
  const dispatch = useDispatch();
  const { isQuestionLeave } = useAppSelector((store) => store.userReducer);

  const [isInit, setIsInit] = useState(true);
  const [step, setStep] = useState(1);
  const [staticDataFS, setStaticDataFS] = useState(initStaticDataFS);
  const [dataFS, setDataFS] = useState(initDataFS);
  const [dataSS, setDataSS] = useState(initDataSS);
  const [dataCommon, setDataCommon] = useState(initDataCommon);

  const { onChangeData, onErrorData } = getChangeError(setDataFS);

  useOpenBrowserModalAfterPageReload(!isInit, [dataFS, dataSS, dataCommon]);

  useEffect(() => {
    return () => {
      dispatch(setIsQuestionLeave(false));
    };
  }, []);

  useEffect(() => {
    if (!isInit) {
      dispatch(setIsQuestionLeave(true));
    }
  }, [dataFS, dataSS, dataCommon]);

  useEffect(() => {
    const response = infoInitQuery.data;

    if (response) {
      const { invoice, form, header, beneficiary, payment_details, remitter } =
        response.data;
      const {
        invoiceId,
        currencySymbol,
        taxationName,
        taxationType,
        invoiceTemplateName,
        referencePrefix = "",
      } = invoice;

      setStaticDataFS({
        beneficiary,
        details: payment_details,
        remitter,
        logo: header.headerLogoContents,
      });

      const { dataForm, dataTable } = getResFormToData(form);

      let descStringMode = "";

      dataForm.forEach(({ key, value }) => {
        if (key === "description") {
          let refLenght = referencePrefix.length;
          refLenght =
            refLenght < value.toString().length ? refLenght + 1 : refLenght;
          descStringMode = dataCommon.descriptionMode
            ? value.toString().slice(refLenght)
            : value.toString();

          onChangeData(key, descStringMode);
          return;
        }
        onChangeData(key, value);
      });

      setDataCommon((prev) => ({
        ...prev,
        invoiceID: invoiceId,
        currency: currencySymbol,
        templateName: invoiceTemplateName,
        dataTable,
        prefixRef: "",
        taxType: { text: taxationName, id: taxationType },
        descriptionMode: !descStringMode?.length,
        referencePrefix,
      }));
      setTimeout(() => {
        setIsInit(false);
      }, 1000);
    }
  }, [infoInitQuery]);

  return (
    <ContextInvoiceUse.Provider
      value={{
        step,
        setStep,
        staticDataFS,
        setStaticDataFS,
        dataFS,
        setDataFS,
        dataSS,
        setDataSS,
        dataCommon,
        setDataCommon,
        onChangeData,
        onErrorData,
      }}
    >
      <WrapperPayment
        title={`Using Template "${dataCommon.templateName}"`}
        step={step}
        viewStep={["Fill out", "Preview & share"]}
      >
        <InvoiceUseFS />
        <InvoiceUseSS />
      </WrapperPayment>
    </ContextInvoiceUse.Provider>
  );
};

export default InvoiceUseComponent;
