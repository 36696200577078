import styled from "styled-components";

export const Wrapper = styled.div`
  width: 272px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 12px;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
`;

interface IWrapperDate {
  isSelect: boolean;
}

export const WrapperDate = styled.div<IWrapperDate>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  font-size: 14px;
  background-color: ${({ isSelect }) => (isSelect ? "#5536ef" : "transparent")};
  color: ${({ isSelect }) => (isSelect ? "white" : "black")};
  cursor: pointer;
`;
