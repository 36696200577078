import { FC, useContext, useEffect, useMemo } from "react";
import * as Styled from "./style";
import { ContextImportBulkTransfer } from "../..";
import { BulkTransferAPI } from "../../../../services/bulkTransfer";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import PickerComponent from "../../../../component/Global/Picker";
import validateString from "../../../../utils/validate/validateString";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { Button } from "antd";
import TransferDocument from "../../../../component/Global/transferDocument";
import BTFSTransferDocument from "./transferDocument";

const FirstStepImportBP = () => {
  const dataContext = useContext(ContextImportBulkTransfer);

  const [validateQuery, infoValidateQuery] = useMutationAPI(
    BulkTransferAPI.valadate
  );

  const {
    dataFS,
    dataCommon,
    setDataCommon,
    setStep,
    step,
    onChangeDataFS,
    setErrorFS,
  } = dataContext;
  const { account, files } = dataFS;
  const { dataAccount, importId } = dataCommon;

  const disabledContinue = useMemo(() => {
    const errors = [account, files]
      .map(({ error }) => error)
      .filter((error) => !!error.length);
    const isLoadingFile = !!files.value.filter(({ isLoading }) => isLoading)
      .length;
    return isLoadingFile;
  }, [account, files]);

  const validate = () => {
    let isValid = true;
    if (!account.value.id.length) {
      const error = validateString(account.value.id, { isReq: true });
      setErrorFS("account", error);
      isValid = false;
    }
    if (!files.value.length) {
      setErrorFS("files", "Document(s) required");
      isValid = false;
    }
    return isValid;
  };

  const onClickContinue = () => {
    if (!validate()) return;
    validateQuery({ accountIdTo: account.value.id, importId });
  };

  useEffect(() => {
    if (infoValidateQuery.data) {
      const secondStepHeader = infoValidateQuery.data.data.account.data;
      const { hasErrors, data: dataTable } = infoValidateQuery.data.data;
      setDataCommon((prev) => ({
        ...prev,
        secondStepHeader,
        dataTable,
        hasErrors,
      }));
      setStep(2);
    }
  }, [infoValidateQuery]);

  return (
    <div>
      <WrapperWhiteBG title="Details">
        <PickerComponent
          value={account.value}
          data={dataAccount}
          setValue={(value) => onChangeDataFS("account", value)}
          error={account.error}
          title="Beneficiary's account:"
          placeholder="Select Account"
        />
      </WrapperWhiteBG>
      <BTFSTransferDocument
        disabled={infoValidateQuery.isLoading || step !== 1}
      />
      <Styled.WrapperButton>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={onClickContinue}
          loading={infoValidateQuery.isLoading}
          disabled={disabledContinue}
        >
          Continue
        </Button>
      </Styled.WrapperButton>
    </div>
  );
};

export default FirstStepImportBP;
