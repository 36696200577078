import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { ICredArr } from "./types"

export const credItem: ICredArr[] = [
  {
    placeholder: "Username",
    img: <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />,
    name: "login",
  },
  {
    placeholder: "Password",
    img: <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />,
    name: "password",
  },
];


export const initDataCred = {
  login: "",
  password: "",
};