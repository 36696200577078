import { Button } from "antd";
import { FC, ReactNode, useId } from "react";
import { TextBold } from "../../../commonStyle";
import { IItemTableAPI } from "../../../services/types/table";
import WrapperWhiteBG from "../../Wrappers/wrapperWhiteBG";
import VPItem from "./item";
import * as Styled from "./style";

export interface IDataVerifyPayment<T> {
  title: string;
  data: T[];
}

interface IProps {
  data: IDataVerifyPayment<IDataVerifyPayment<IItemTableAPI>>[];
}

const VerifyPayments: FC<IProps> = ({ data }) => {
  const idComponent = useId();

  return (
    <Styled.WrapperItem>
      {data.map(({ title, data }, index) => {
        return (
          <div key={idComponent + index}>
            <TextBold fontSize={18}>{title}</TextBold>
            <VPItem data={data} />
          </div>
        );
      })}
    </Styled.WrapperItem>
  );
};

export default VerifyPayments;
