import { useContext } from "react";
import { ContextInvoiceBuilder } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import * as Styled from "./style";
import { addressFields } from "../../../continue/data";

const IBRemThirdRow = () => {
  const { dataFS, onChangeData, validateOnBlur } = useContext(
    ContextInvoiceBuilder
  );
  const { RemStreetAddress, RemHouseNumber, RemPostalCode } = dataFS;

  return (
    <Styled.Wrapper>
      <InputComponent
        {...RemPostalCode}
        title="Postal Code:"
        onChangeValue={(value) => onChangeData("RemPostalCode", value)}
        onBlur={() => validateOnBlur("RemPostalCode", addressFields)}
        width="20%"
        maxLength={10}
        id="RemPostalCode"
      />
      <InputComponent
        {...RemStreetAddress}
        title="Street address:"
        onChangeValue={(value) => onChangeData("RemStreetAddress", value)}
        onBlur={() => validateOnBlur("RemStreetAddress", addressFields)}
        width="60%"
        maxLength={50}
        id="RemStreetAddress"
      />
      <InputComponent
        {...RemHouseNumber}
        title="House number:"
        onChangeValue={(value) => onChangeData("RemHouseNumber", value)}
        onBlur={() => validateOnBlur("RemHouseNumber", addressFields)}
        width="20%"
        maxLength={10}
        id="RemHouseNumber"
      />
    </Styled.Wrapper>
  );
};

export default IBRemThirdRow;
