import { FC } from "react";
import { Text } from "../../../../commonStyle";
import PickerComponent, {
  IItemPicker,
} from "../../../../component/Global/Picker";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { IData, TOnChangeData, TSetErrorData } from "../../types";
import EPChooseType from "./chooseType";
import EPNameLogin from "./nameLogin";
import * as Styled from "./style";

interface IProps {
  onChangeData: TOnChangeData;
  data: IData;
  dataTimezone: IItemPicker[];
  setErrorData: TSetErrorData;
}

const EPDetails: FC<IProps> = ({
  data,
  onChangeData,
  dataTimezone,
  setErrorData,
}) => {
  return (
    <WrapperWhiteBG title="Login details" titleOut="EDIT PROFILE">
      <Styled.Wrapper>
        <EPChooseType
          {...data.authType}
          setType={(value) => onChangeData("authType", value)}
        />
        <PickerComponent
          {...data.timezone}
          title="Timezone:"
          data={dataTimezone}
          setValue={(value) => onChangeData("timezone", value)}
          placeholder="Select Timezone"
        />
        <EPNameLogin
          name={data.name}
          login={data.login}
          onChangeData={onChangeData}
          setErrorData={setErrorData}
        />
      </Styled.Wrapper>
    </WrapperWhiteBG>
  );
};

export default EPDetails;
