import { LoadingOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { FC, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import TypeToTextMap from "../../../../component/Global/TypeToTextMap";
import { IItemDataInit } from "../../../../services/crypto/types";
import { IItemTableAPI } from "../../../../services/types/table";
import useSizePage from "../../../../utils/hookSizePage";
import { TypeToText } from "../../../../utils/typeToText";
import GenerateModal from "../../generate";
import * as Styled from "./style";

interface IProps {
  amountData: IItemTableAPI[][];
  buttonData: IItemDataInit["buttons"];
  rate: IItemTableAPI;
  accountId: string;
  realoadPage: () => void;
}

const SSTCryptoTable: FC<IProps> = ({
  amountData,
  buttonData,
  rate,
  accountId,
  realoadPage,
}) => {
  const idCompoent = useId();
  const { width } = useSizePage();

  const [isViewGen, setViewGen] = useState(false);
  const navigate = useNavigate();

  const onClickButtons = {
    "generate-address": () => {
      setViewGen(true);
    },
    "crypto-to-fiat": () => {
      navigate("/crypto/crypto-to-fiat", { state: { accountId } });
    },
    "crypto-to-crypto": () => {
      navigate("/crypto/crypto-to-crypto", { state: { accountId } });
    },
    statement: () => {
      navigate("/statements/view", { state: { accountId } });
    },
  };

  return (
    <Styled.WrapperColunm>
      <TypeToTextMap
        data={amountData}
        position={width > 768 ? "end" : "start"}
      />

      <Styled.WrapperRow>
        {!rate.text.length ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <TypeToText {...rate} />
        )}
      </Styled.WrapperRow>

      <Styled.WrapperRow>
        {buttonData.map(({ text, type, action }, index) => {
          const key = idCompoent + ".button." + index;
          return (
            <Button
              key={key}
              shape="round"
              size="large"
              disabled={type === "disabled"}
              onClick={action && onClickButtons[action]}
            >
              {text}
            </Button>
          );
        })}
      </Styled.WrapperRow>

      {isViewGen && (
        <GenerateModal
          onCancel={() => setViewGen(false)}
          accountId={accountId}
          realoadPage={realoadPage}
        />
      )}
    </Styled.WrapperColunm>
  );
};

export default SSTCryptoTable;
