import styled from "styled-components";

export const WrapperPickers = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

interface IWrapperPicker {
  width?: string;
}

export const WrapperPicker = styled.div<IWrapperPicker>`
  display: flex;
  align-items: center;
  gap: 0 10px;
  width: ${({ width }) => width || "200px"};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WrapperAt = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
