import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import WrapperPayment from "../../component/Wrappers/wrapperPayment";
import { CreditDocApi } from "../../services/creditDoc";
import { setIsQuestionLeave } from "../../store/user";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import { dataContext, getDataBenefAccount, getDataBenefCurrency } from "./data";
import CredDocFirstStep from "./steps/first";
import CreditDocSecondStep from "./steps/second";
import { IContext } from "./types";

const valueContext: IContext = {
  data: dataContext,
  setData: (value) => console.log(value),
  step: 1,
  setStep: (value) => console.log(value),
};

export const ContextCredDoc = createContext<IContext>(valueContext);

const CDUploadComponent = () => {
  const infoInitQuery = useQueryAPI(CreditDocApi.initUplaod, {
    changeIsLoading: true,
  });
  const dispatch = useDispatch();

  const [isInit, setIsInit] = useState(true);
  const [step, setStep] = useState(1);
  const [data, setData] = useState(dataContext);

  useEffect(() => {
    return () => {
      dispatch(setIsQuestionLeave(false));
    };
  }, []);

  useEffect(() => {
    if (!isInit) {
      dispatch(setIsQuestionLeave(true));
    }
  }, [data]);

  useEffect(() => {
    const response = infoInitQuery.data;
    if (response) {
      const dataBenefAccount = getDataBenefAccount(
        response.data.remitters_list.dropdown_options.data
      );

      const dataBenefCurrency = getDataBenefCurrency(
        response.data.remitters_list.dropdown_options.data
      );

      const { requestId } = response.data.transfer;
      setData((prev) => ({
        ...prev,
        dataBenefAccount,
        requestId,
        dataBenefCurrency,
      }));
      setTimeout(() => setIsInit(false), 1000);
    }
  }, [infoInitQuery]);

  return (
    <ContextCredDoc.Provider value={{ data, setData, step, setStep }}>
      <WrapperPayment
        {...infoInitQuery.data?.data.page?.header}
        viewStep={["Fill out", "Verify"]}
        step={step}
      >
        <CredDocFirstStep />
        <CreditDocSecondStep />
      </WrapperPayment>
    </ContextCredDoc.Provider>
  );
};

export default CDUploadComponent;
