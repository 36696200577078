import { FC, useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ITable } from "../../../component/table/types";
import WrapperBodyTitle from "../../../component/Wrappers/WrapperBodyTitle";
import { BulkTransferAPI } from "../../../services/bulkTransfer";
import { IItemDataInitTable } from "../../../services/bulkTransfer/types";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import useSizePage from "../../../utils/hookSizePage";
import * as Styled from "./style";
import BTPTable from "./table";
import { IDataTable } from "./types";
import { Empty } from "antd";

const getDataTable = (
  dataRow: IItemDataInitTable["item"][],
  width: number
): ITable[] => [
  {
    title: "Date",
    width: "12%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.date),
  },
  {
    title: "Account",
    width: "25%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.account),
  },
  {
    title: "Beneficiary",
    width: "25%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.counterparty),
  },
  {
    title: "Payment",
    width: "28%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.description),
  },
  {
    title: "Amount",
    width: "10%",
    positionText: width > 1024 ? "right" : "left",
    dataColumn: dataRow.map((item) => item.amount),
  },
];

const PendingBulkTransfer = () => {
  const idComponent = useId();
  const { width } = useSizePage();
  const navigate = useNavigate();

  const [queryInit, infoInitQuery] = useMutationAPI(
    BulkTransferAPI.pending.init,
    { changeIsLoading: true }
  );

  const [dataTables, setDataTables] = useState<IDataTable[]>([]);

  useEffect(() => {
    queryInit({});
  }, []);

  useEffect(() => {
    const response = infoInitQuery.data;

    if (response) {
      const { data } = response.data;
      const dataToTables = data.map(
        ({ accountName, data, totalAmount }): IDataTable => {
          return {
            accountName,
            details: data.map(({ details }) => details),
            table: getDataTable(
              data.map(({ item }) => item),
              width
            ),
            idRows: data.map(({ paymentId }) => paymentId),
            totalAmount,
          };
        }
      );

      setDataTables(dataToTables);
    }
  }, [infoInitQuery]);

  return (
    <WrapperBodyTitle title="PENDING BULK TRANSFERS">
      <Styled.WrapperTables>
        {dataTables.length ? (
          dataTables.map((props, index) => (
            <BTPTable
              {...props}
              onReload={() => queryInit({})}
              key={idComponent + index}
            />
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Styled.WrapperTables>
    </WrapperBodyTitle>
  );
};

export default PendingBulkTransfer;
