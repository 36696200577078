import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import { IItemTableAPI } from "../../services/types/table";
import UserAPI from "../../services/userAPI";
import { changeOpenType, setIsQuestionLeave } from "../../store/user";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import { IContactFormAPI } from "../../services/types";
import ContactForm from "./form";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";

const ContactComponent = () => {
  const [queryInit, infoInitQuery] = useMutationAPI(UserAPI.getContact, {
    changeIsLoading: true,
  });
  const dispatch = useDispatch();

  const [dataText, setDataText] = useState<IItemTableAPI[][]>([]);
  const [dataForm, setDataForm] = useState<null | IContactFormAPI>(null);
  const [dataFormEdited, setDataFormEdited] = useState(false);

  useEffect(() => {
    dispatch(changeOpenType({ openType: "contact" }));
    queryInit({});
  }, []);

  useEffect(() => {
    dispatch(setIsQuestionLeave(dataFormEdited));

    return () => {
      dispatch(setIsQuestionLeave(false));
    };
  }, [dataFormEdited]);

  useEffect(() => {
    const response = infoInitQuery.data;

    if (response) {
      setDataText(response.data.contacts);
      setDataForm(response.data.form);
    }
  }, [infoInitQuery.data]);

  return (
    <WrapperBodyTitle {...infoInitQuery.data?.data.page?.header}>
      <TypeToTextMap data={dataText} />
      {dataForm && (
        <ContactForm
          dataForm={dataForm}
          setDataFormEdited={setDataFormEdited}
        />
      )}
    </WrapperBodyTitle>
  );
};

export default ContactComponent;
