import { TDataFS } from "../types";

type Config = {
  errorOverride?: string;
};

export const deepCopyData = <T extends TDataFS<any>>(
  data: T,
  config?: Config
): T => {
  return Object.fromEntries(
    Object.entries({ ...data }).map((entry) => {
      return [
        entry[0],
        { ...entry[1], error: config?.errorOverride ?? entry[1].error },
      ];
    })
  ) as T;
};
