import { FC, ReactElement } from "react";
import { Text, TextBold, Link, ITextCommon } from "../../commonStyle";
import * as Styled from "./style";
import TextIcon from "./icon";
import { Alert } from "antd";
import handleDownload from "../fileDownloadHandler";
import TextCopyable from "../../component/Global/TextCopyable";

export interface ISpecificTypeText {
  type: string;
  handle: (data: IPropsTypeToText) => ReactElement;
}

export interface IPropsTypeToText {
  text: string;
  type: string;
  url?: string;
  icon?: string;
  styleData?: ITextCommon;
  widthIcon?: number;
  withOutJWT?: boolean;
  linkProps?: { download?: boolean };
  action?: string;
  specificType?: ISpecificTypeText[];
}

export const TypeToText: FC<IPropsTypeToText> = (props) => {
  const {
    type,
    styleData,
    url,
    text,
    withOutJWT,
    icon,
    widthIcon,
    specificType,
    linkProps,
  } = props;
  if (url) {
    const href = withOutJWT
      ? url
      : url + `?jwt=${localStorage.getItem("token")}`;

    const propsLink = {
      ...props,
      styleData: {
        ...props.styleData,
        isCursor: true,
        color: props.styleData?.color || "#6c64f4",
      },
    };

    const handleOnClick = () => {
      handleDownload(url, text);
    };

    if (!withOutJWT) {
      return (
        <Link onClick={handleOnClick} target="_blank" {...linkProps}>
          <TypeToText {...propsLink} url={undefined} />
        </Link>
      );
    }

    return (
      <Link href={href} target="_blank" {...linkProps}>
        <TypeToText {...propsLink} url={undefined} />
      </Link>
    );
  }

  if (icon && !text) {
    return (
      <Styled.WrapperIcon widthIcon={widthIcon || 15}>
        <TextIcon icon={icon} type={type} color={styleData?.color} />
      </Styled.WrapperIcon>
    );
  }

  if (icon && text) {
    return (
      <Styled.WrapperTextIcon widthIcon={widthIcon || 15}>
        <TextIcon icon={icon} type={type} color={styleData?.color} />
        <Text fontSize={16} {...styleData} data-type={type}>
          {text}
        </Text>
      </Styled.WrapperTextIcon>
    );
  }

  const specificTypeData = specificType?.find(
    ({ type: typeArr }) => typeArr === type
  );

  if (specificTypeData) {
    return specificTypeData.handle(props);
  }

  switch (type) {
    case "normal": {
      return (
        <Text fontSize={16} {...styleData} data-type={type}>
          {text}
        </Text>
      );
    }
    case "copyable": {
      return (
        <TextCopyable
          text={text}
          messageText={text}
          style={{ marginBottom: "unset", wordBreak: "break-all" }}
        />
      );
    }
    case "new-line": {
      return (
        <Text fontSize={16} height={15} {...styleData}>
          {text}
        </Text>
      );
    }
    case "alert": {
      return (
        <Text fontSize={16} color="#eb5757" {...styleData}>
          {text}
        </Text>
      );
    }
    case "em":
    case "amount-pending": {
      return (
        <TextBold fontSize={16} {...styleData} data-type={type}>
          {text}
        </TextBold>
      );
    }
    case "amount-in":
    case "amount-out": {
      return (
        <TextBold
          color={type === "amount-out" ? "#eb5757" : "#54b9c8"}
          fontSize={16}
          {...styleData}
        >
          {text}
        </TextBold>
      );
    }
    case "amount": {
      return (
        <TextBold fontSize={16} {...styleData}>
          {text}
        </TextBold>
      );
    }
    case "success":
    case "error": {
      return (
        <TextBold
          color={type === "error" ? "#eb5757" : "#54b9c8"}
          fontSize={16}
          {...styleData}
        >
          {text}
        </TextBold>
      );
    }

    case "info":
    case "warning":
    case "success":
    case "error": {
      return (
        <Alert
          message={
            <Text fontSize={16} {...styleData}>
              {text}
            </Text>
          }
          type={type}
        />
      );
    }

    default: {
      return (
        <Text fontSize={16} {...styleData}>
          {text}
        </Text>
      );
    }
  }
};
