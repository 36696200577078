import * as Styled from "../../../../shared/firstStep/beneficiary/style";
import { PaymentAccountNumberInput } from "../../../../shared/firstStep/components/accountNumberInput";
import { PaymentBankInstitutionNumberInput } from "../../../../shared/firstStep/components/institutionNumberInput";
import { PaymentBankTransitNumberInput } from "../../../../shared/firstStep/components/transitNumberInput";
import { InputEft } from "../../../types";

const SecondRowBeneficiaryEft = ({ input }: { input: InputEft }) => {
  return (
    <Styled.Row>
      <Styled.Item flex={1}>
        <PaymentBankTransitNumberInput input={input} />
      </Styled.Item>

      <Styled.Item flex={1}>
        <PaymentBankInstitutionNumberInput input={input} />
      </Styled.Item>

      <Styled.Item flex={1}>
        <PaymentAccountNumberInput input={input} />
      </Styled.Item>
    </Styled.Row>
  );
};

export default SecondRowBeneficiaryEft;
