import styled from "styled-components";
import { TextBold } from "../../../../commonStyle";

export const Wrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px 0;
`;

export const WrapperItem = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Title = styled(TextBold)`
  width: 200px;
  word-wrap: break-word;
`;

export const Link = styled.a`
  text-decoration: none;
`;
