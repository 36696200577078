import { FC, ReactElement, useId } from "react";
import { ITextCommon } from "../../../commonStyle";
import { IItemTableAPI } from "../../../services/types/table";
import { ISpecificTypeText, TypeToText } from "../../../utils/typeToText";
import * as Styled from "./style";

type TStyle = ITextCommon | ((value: IItemTableAPI) => ITextCommon);



interface IProps {
  data: IItemTableAPI[][];
  styleData?: TStyle;
  position?: "start" | "center" | "end";
  gapLines?: number;
  gapText?: number;
  specificType?: ISpecificTypeText[];
}

const TypeToTextMap: FC<IProps> = ({
  data,
  styleData,
  position = "start",
  gapLines = 0,
  gapText = 5,
  specificType,
}) => {
  const idComponent = useId();

  const getStyle = (item: IItemTableAPI) => {
    if (typeof styleData === "function") return styleData(item);
    return styleData;
  };

  return (
    <Styled.Wrapper gap={gapLines}>
      {data.map((line, index) => {
        const key = idComponent + ".TypeToTextMap." + index;
        return (
          <Styled.WrapperDataLine key={key} gap={gapText} position={position}>
            {line.map((item, index) => (
              <TypeToText
                {...item}
                key={key + "." + index}
                styleData={getStyle(item)}
                specificType={specificType}
              />
            ))}
          </Styled.WrapperDataLine>
        );
      })}
    </Styled.Wrapper>
  );
};
 
export default TypeToTextMap;
