import { useContext } from "react";
import { CardTopUpContext } from "../../..";
import PickerComponent from "../../../../../component/Global/Picker";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";

const CTUFSRemitterDetails = () => {
  const valueContext = useContext(CardTopUpContext);

  const { dataFS, dataCommon, onChangeDataFS } = valueContext;

  return (
    <WrapperWhiteBG title="Remitter Details">
      <PickerComponent
        {...dataFS.account}
        data={dataCommon.accountData}
        setValue={(value) => onChangeDataFS("account", value)}
        placeholder="Select Remitter"
      />
    </WrapperWhiteBG>
  );
};

export default CTUFSRemitterDetails;
