import { IDataCommon, ITableInvoiceBuilder } from "../../../types";

const getItemSubtotal = ({ qty, rate }: ITableInvoiceBuilder) => {
  return (parseFloat(qty.value) || 0) * (parseFloat(rate.value) || 0);
};

export const getSubtotal = (data: ITableInvoiceBuilder[]) =>
  data.reduce((prev, current) => prev + getItemSubtotal(current), 0);

const getItemTax = (item: ITableInvoiceBuilder) => {
  return (getItemSubtotal(item) * (parseFloat(item.tax.value) || 0)) / 100;
};

export const getTax = (data: ITableInvoiceBuilder[]) =>
  data.reduce((prev, current) => prev + getItemTax(current), 0);

export const validateRow = (item: ITableInvoiceBuilder) => {
  const keys = Object.keys(item) as (keyof typeof item)[];

  return !keys
    .map(
      (key) =>
        !!item[key].error.length || (!item[key].value.length && key !== "tax")
    )
    .filter((item) => item).length;
};
