import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import { EMAIL_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

const rulesEmail: IRulesString = { isReq: true, max: 255, isEmail: true };

type IDataFS = {
  paymentEmail: IValueError<string>;
};

interface Input {
  dataFS: IDataFS;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const EmailInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: Input;
}) => {
  return (
    <InputComponent
      {...dataFS.paymentEmail}
      onChangeValue={(value) => onChangeDataFS("paymentEmail", value)}
      title="Email:"
      maxLength={rulesEmail.max}
      fieldActions={prepareStringRulesField("paymentEmail", rulesEmail)}
      regex={EMAIL_REGEX}
      onLostFocusActions={[
        LostFocusActions.REMOVE_WHITESPACES,
        LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
      ]}
      id="paymentEmail"
    />
  );
};
