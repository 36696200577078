import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px 0;
`;
