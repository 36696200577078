import instance from "..";
import { IResponseFileUpload } from "../pagesAPI/types/response";
import {
  IPayloadAuthorize,
  IPayloadExecute,
  IPayloadFileDelete,
  IPayloadFileUpload,
  IPayloadInit,
  IPayloadMassRequest,
  IPayloadValidate,
  IResponseAuthorize,
  IResponseDelete,
  IResponseInit,
  IResponseValidate,
} from "./types";

export const MassPaymentAPI = {
  init: ({ typeQuery }: IPayloadInit) =>
    instance.get<IResponseInit>("/transfers/init/" + typeQuery),
  request: ({ payload, url }: IPayloadMassRequest) =>
    instance.post(url, payload),
  validate: (payload: IPayloadValidate) =>
    instance.post<IResponseValidate>(
      `/transfers/validate/${payload.typeQuery}/${payload.importId}`,
      payload
    ),
  authorize: ({ importId, typeQuery }: IPayloadAuthorize) =>
    instance.get<IResponseAuthorize>(
      `/transfers/${typeQuery}/authorize/${importId}`
    ),
  execute: ({ payload, importId, typeQuery }: IPayloadExecute) =>
    instance.post(`/transfers/${typeQuery}/execute/${importId}`, payload),
  file: {
    upload: ({ importId, formData, typeQuery }: IPayloadFileUpload) =>
      instance.post<IResponseFileUpload>(
        `/transfers/${typeQuery}/documents/upload/${importId}`,
        formData
      ),
    delete: ({ importId, fileId, typeQuery }: IPayloadFileDelete) =>
      instance.get<IResponseDelete>(
        `/transfers/${typeQuery}/documents/delete/${importId}/${fileId}`
      ),
  },
};
