import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useContext, useId } from "react";
import { ContextRecurringPayment } from "../../../../../..";
import { IItemPicker } from "../../../../../../../../component/Global/Picker";
import * as Styled from "./style";

const dataCheckBox: IItemPicker[] = [
  { text: "Mon", id: "Monday" },
  { text: "Tue", id: "Tuesday" },
  { text: "Wed", id: "Wednesday" },
  { text: "Thu", id: "Thursday" },
  { text: "Fri", id: "Friday" },
];

const ScheduleDailyPicker = () => {
  const idComponent = useId();
  const { dataFS, onChangeData } = useContext(ContextRecurringPayment);
  const { scheduleOptions } = dataFS;

  const onChangeValue = (e: CheckboxChangeEvent, value: string) => {
    const changeValue = scheduleOptions.value.filter((item) => item !== value);
    if (e.target.checked) {
      changeValue.push(value);
    }
    onChangeData("scheduleOptions", changeValue);
  };

  return (
    <Styled.Wrapper>
      {dataCheckBox.map(({ text, id }, index) => (
        <Checkbox
          checked={scheduleOptions.value.includes(id)}
          onChange={(e) => onChangeValue(e, id)}
          key={idComponent + index}
        >
          {text}
        </Checkbox>
      ))}
    </Styled.Wrapper>
  );
};

export default ScheduleDailyPicker;
