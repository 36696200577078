import styled from "styled-components";

export const WrapperColunm = styled.div`
  width: 50%;
  & > div:first-child {
    margin-top: unset;
  }
  & > div {
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WrapperRow = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: start;
  }
`;
