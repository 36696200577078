import { IRowTableAccounts } from "../../../services/pagesAPI/types";

export const responseToData = (responseTable: IRowTableAccounts[]) => {
  return responseTable.map(({ item }) => {
    return {
      firstColumnData: item.description,
      score: item.balance,
      params: item.params,
    };
  });
};
