import { FC } from "react";
import * as Styled from "../../../../shared/firstStep/beneficiary/style";
import { CanadaStatePicker } from "../../../../shared/firstStep/components/canadaStatePicker";
import { CityInput } from "../../../../shared/firstStep/components/cityInput";
import { CountryPicker } from "../../../../shared/firstStep/components/countryPicker";
import { PostalCodeInput } from "../../../../shared/firstStep/components/postalCodeInput";
import { StatePicker } from "../../../../shared/firstStep/components/statePicker";
import { InputFx } from "../../../types";

interface IProps {
  isUS: boolean;
  isCanada: boolean;
  input: InputFx;
}

const FirstRowBeneficiarySwift: FC<IProps> = ({ input, isUS, isCanada }) => {
  return (
    <Styled.Row>
      <Styled.Item flex={1}>
        <CountryPicker input={input} />
      </Styled.Item>

      {isUS && (
        <Styled.Item flex={1}>
          <StatePicker input={input} />
        </Styled.Item>
      )}

      {isCanada && (
        <Styled.Item flex={1}>
          <CanadaStatePicker input={input} />
        </Styled.Item>
      )}

      <Styled.Item flex={1}>
        <CityInput input={input} />
      </Styled.Item>

      {!isUS && !isCanada && (
        <Styled.Item flex={1}>
          <PostalCodeInput input={input} />
        </Styled.Item>
      )}
    </Styled.Row>
  );
};

export default FirstRowBeneficiarySwift;
