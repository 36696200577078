import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 17px;

  @media (max-width: 1024px) {
    margin-top: 0;
    margin-bottom: 15px;
  }
`;
