import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const FirstColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const WrapperRow = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
`;

export const SecondColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
