import validateNumber from "../../../../utils/validate/validateNumber";
import validateString from "../../../../utils/validate/validateString";
import { IDataFS } from "../types";

export const getMin = (code: string) => {
  switch (code) {
    case "BTC": {
      return 0.000015;
    }
    default: {
      return 0.01;
    }
  }
};

export const getDecimal = (code: string) => {
  switch (code) {
    case "BTC": {
      return 8;
    }
    default: {
      return 2;
    }
  }
};

const keysData: (keyof IDataFS)[] = [
  "amount",
  "company",
  "description",
  "wallet",
];

const getError = (key: keyof IDataFS, error = "") => {
  return {
    key,
    error,
  };
};

export const validate = (
  data: IDataFS,
  amountSymbol: string,
  code: string,
  isValidWallet: boolean
) => {
  const keys = Object.keys(data) as (keyof IDataFS)[];

  const errors = keys.map((key) => {
    const value = data[key].value;

    if (key === "amount") {
      const error = validateNumber(
        parseFloat(data[key].value),
        {
          min: getMin(code),
        },
        {
          symbol: amountSymbol + " ",
        }
      );
      return getError(key, error);
    }

    if (key === "wallet") {
      return getError(key, isValidWallet ? "" : "Wallet Address is incorrect");
    }

    if (typeof value === "boolean") {
      return getError(key);
    }

    if (typeof value === "string") {
      const error = validateString(value, { isReq: true });
      return getError(key, error);
    }

    return getError(key, !value.length ? "Document(s) required" : "");
  });

  if (!data.isConfirm.value)
    errors.push({ key: "isConfirm", error: "Please confirm the sell" });

  return errors;
};

export const getIsDisableCotinue = (data: IDataFS) => {
  const errors = keysData
    .map((key) => data[key].error)
    .filter((item) => !!item.length);

  if (data.files.error.length) {
    errors.push(data.files.error);
  }

  return !!errors.length;
};
