import styled from "styled-components";
import { TPositionTextTable } from "../types";

interface IWrapper {
  isSelect: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 14px;
  background-color: #eff1f4;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    display: ${({ isSelect }) => (isSelect ? "flex" : "none")};
  }
`;

export const WrapperCheckbox = styled.div`
  width: 16px;
  margin-right: 8px;
`;

interface IWrapperText {
  type: TPositionTextTable;
  width: string;
}

const typeToJustify = (type: TPositionTextTable) => {
  if (type === "left") return "start";
  if (type === "right") return "end";
  return "center";
};

export const WrapperText = styled.div<IWrapperText>`
  padding-right: 8px;
  display: flex;
  justify-content: ${({ type }) => typeToJustify(type)};
  width: ${({ width }) => width};

  &:last-child {
    padding-right: 0px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const WrapperTextSelect = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
  }
`;
