import { Route } from "react-router-dom";
import CreditDocComponent from "../../pages/creditDoc";
import CDUploadComponent from "../../pages/creditDocUpload";

const CreditDocRoutes = () => {
  return (
    <Route path="/credit-documents">
      <Route path="" element={<CreditDocComponent />} />
      <Route path="upload" element={<CDUploadComponent />} />
    </Route>
  );
};

export default CreditDocRoutes;
