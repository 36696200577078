import styled, { css } from "styled-components";
import { Text } from "../../../commonStyle";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

interface IWrapperValue {
  borderColor: string;
  isOpen: boolean;
  isButton: boolean;
  isError: boolean;
}

const getBorderColor = (props: IWrapperValue) => {
  const { borderColor, isOpen, isButton, isError } = props;
  if (isError) return "#eb5757";
  if (isOpen || isButton) return borderColor;
  return "#d9d9d9";
};

export const WrapperValue = styled.div<IWrapperValue>`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: ${({ isButton }) => (isButton ? "40px" : "4px")};
  border: ${({ isButton }) => (isButton ? "2px solid" : "1px solid")};
  cursor: pointer;
  border-color: ${(props) => getBorderColor(props)};
  padding: 8px;
  &:hover {
    border-color: ${({ borderColor, isError }) =>
      isError ? "#eb5757" : borderColor};
    box-shadow: 0 0 1px #00000088;
  }
`;

export const WrapperIcon = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const WrapperData = styled.div`
  position: absolute;
  z-index: 40;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  top: 45px;
  left: 0px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 1px #00000088;
  animation: animationPicker 0.3s;
  & > div:first-child {
    border-radius: 4px 4px 0 0;
  }
  & > div:last-child {
    border-radius: 0 0 4px 4px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7a78a9;
    border-radius: 5px;
  }
`;

export const SelectText = styled(Text)`
  cursor: pointer;
  line-height: 1.5;
  background-color: white;
  &:hover {
    background-color: #dfdae5;
    font-weight: 600;
  }
  &:active {
    background-color: #dfdae5;
    font-weight: 600;
  }
`;

interface IWrapperArrow {
  isOpen: boolean;
}

export const WrapperArrow = styled.div<IWrapperArrow>`
  display: flex;
  align-items: center;
  transform: ${({ isOpen }) => (isOpen ? "rotateZ(0deg)" : "rotateZ(180deg)")};
  transition: 0.5s;
  & svg {
    width: 10px;
    height: 10px;
  }
  & span {
    width: 10px;
    height: 10px;
  }
`;
