import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import WrapperPayment from "../../component/Wrappers/wrapperPayment";
import { RecurringPaymentAPI } from "../../services/recurringPayment";
import { getChangeError } from "../../utils/getChangeError";
import { useQuestionBeforeLeave } from "../../utils/questionBeforeLeave";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import {
  getDataAccount,
  getDataCompany,
  getDataCounrty,
  getDataForm,
  getDataState,
  getDataSymbol,
  initContext,
  initDataCommon,
  initDataFS,
  initDataSS,
} from "./data";
import RPBuilderFS from "./steps/firstStep";
import RPBuilderSS from "./steps/secondStep";

interface ILocation {
  state?: {
    recurringId?: string;
    isEdit?: boolean;
    type?: string;
  };
}

export const ContextRecurringPayment = createContext(initContext);

const RPBuilderComponent = () => {
  const {
    isEdit = false,
    recurringId = "",
    type = "",
  } = (useLocation() as ILocation).state || {};

  const infoInitQuery = useQueryAPI(RecurringPaymentAPI.build, {
    changeIsLoading: true,
    payload: { isEdit, recurringId, type },
  });

  const [step, setStep] = useState(1);
  const [dataFS, setDataFS] = useState(initDataFS);
  const [dataSS, setDataSS] = useState(initDataSS);
  const [dataCommon, setDataCommon] = useState(initDataCommon);
  const { onChangeData, onErrorData } = getChangeError(setDataFS);

  const onFinishLoading = useQuestionBeforeLeave({ deps: [dataFS] });

  useEffect(() => {
    const response = infoInitQuery.data?.data;
    if (response) {
      const {
        remitters_list,
        dictionaries,
        beneficiaries,
        recurringPaymentId,
        form,
        documents,
      } = response;

      const dataSymbol = getDataSymbol(remitters_list.dropdown_options.data);

      const dataAccount = getDataAccount(remitters_list.dropdown_options.data);
      const dataCountry = getDataCounrty(dictionaries.countries);
      const dataState = getDataState(dictionaries.us_states);
      const dataCompany = getDataCompany(beneficiaries, dataCountry, dataState);
      const dataForm = getDataForm(
        form,
        documents,
        dataAccount,
        dataCountry,
        dataState
      );

      dataForm.forEach(({ key, value }) => onChangeData(key, value));

      setDataCommon((prev) => ({
        ...prev,
        dataAccount,
        dataCountry,
        dataState,
        dataCompany,
        usId: dictionaries.country_id_us,
        caId: dictionaries.country_id_ca,
        recurringPaymentId,
        dataSymbol,
        type,
        isOpenCorresp: !!dataForm.find(
          ({ key }) =>
            key === "correspAccount" ||
            key === "correspBankName" ||
            key === "correspBIC"
        ),
      }));

      setTimeout(() => {
        onFinishLoading(false);
      }, 1000);
    }
  }, [infoInitQuery]);

  return (
    <ContextRecurringPayment.Provider
      value={{
        step,
        setStep,
        dataFS,
        dataSS,
        setDataSS,
        setDataFS,
        dataCommon,
        setDataCommon,
        onChangeData,
        onErrorData,
      }}
    >
      <WrapperPayment
        {...infoInitQuery.data?.data.page?.header}
        step={step}
        viewStep={["Fill out", "Preview & save"]}
      >
        <RPBuilderFS />
        <RPBuilderSS />
      </WrapperPayment>
    </ContextRecurringPayment.Provider>
  );
};

export default RPBuilderComponent;
