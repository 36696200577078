import { IItemPicker } from "../../component/Global/Picker";
import { ITable, ITableDetails } from "../../component/table/types";
import { IData, IPropsOnSelectTable } from "./types";
import { getTableDetailsPicker } from "../../utils/getTableDetailsPicker";
import { RecurringPaymentAPI } from "../../services/recurringPayment";
import { dowloadBase64 } from "../../utils/dowloadBase64";
import { IResInitRP } from "../../services/recurringPayment/types/response";
import ActionsTableComponent from "../../component/tableActionsPicker";

export const initPicker: IItemPicker = {
  text: "",
  id: "",
};

export const initData: IData = {
  table: [],
  dataAccount: [],
  dataCompany: [],
};

export const getDataFilter = (
  dataProps: IResInitRP["filters"]["accounts_filter"]
) =>
  dataProps.dropdown_options.data.map(
    ({ text, value }): IItemPicker => ({
      text,
      id: value,
    })
  );

export const getDataTable = (
  data: IData["table"],
  onSelectDropDownTable: (item: IPropsOnSelectTable) => void,
  width: number
): ITable[] => [
  {
    title: "Created",
    positionText: "left",
    width: "150px",
    dataColumn: data.map(({ item }) => item.date),
  },
  {
    title: "Remitter",
    positionText: "left",
    width: "calc((100% - 15% - 300px) / 2)",
    dataColumn: data.map(({ item }) => item.remitter_details),
  },
  {
    title: "Beneficiary ",
    positionText: "left",
    width: "calc((100% - 15% - 300px) / 2)",
    dataColumn: data.map(({ item }) => item.beneficiary_details),
  },
  {
    title: "Details",
    positionText: "left",
    width: "15%",
    dataColumn: data.map(({ item }) => item.payment_details),
  },

  {
    title: "Actions",
    positionText: width < 1024 ? "left" : "right",
    width: "150px",
    dataColumn: data.map(({ item, recurringPaymentId }) =>
      item.actions.map((line) =>
        line.map((item) => (
          <ActionsTableComponent
            {...item}
            setValue={({ id }) =>
              onSelectDropDownTable({
                action: id,
                id: recurringPaymentId,
                type: item.data.recurringPaymentTransferType,
              })
            }
          />
        ))
      )
    ),
  },
];

export const getDetailsTable = (data: IData["table"]): ITableDetails[][] => {
  return data.map(({ details }) =>
    details
      .filter((item) => !(item instanceof Array))
      .map((item) => {
        if (item.id === "documents") {
          return getTableDetailsPicker(item, ({ id }) => {
            RecurringPaymentAPI.file.download({ id }).then(({ data }) => {
              const { fileContents, fileName } = data;
              dowloadBase64(fileContents, fileName);
            });
          });
        }
        return item;
      })
  );
};
