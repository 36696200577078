import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 40px;
  left: 0;
  max-height: 200px;
  background-color: white;
  box-shadow: 0 0 50px rgb(27 41 68 / 10%);
  border-radius: 10px;
  animation: animationPicker 0.3s;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7a78a9;
    border-radius: 5px;
  }
`;

export const Hint = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: #eaecf4;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 0 5px;
`;
