import styled from "styled-components";

export const WrapperHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 26px 36px;
  margin-bottom: 15px;
`;

export const WrapperHeaderImg = styled.div`
  width: 180px;
  & > img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 1024px) {
    width: 130px;
  }
`;

export const WrapperHeaderContent = styled.div`
  width: calc(100% - 340px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  & div {
    text-align: start;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const WrapperButton = styled.div`
  width: 120px;
  height: 40px;
  & > * {
    width: 100%;
  }
`;

export const WrapperName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  padding: 4px 0;
`;
