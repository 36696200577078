import { IItemTableAPI } from "../../services/types/table";
import { notification } from "antd";
import BodyNotification from "./body";

export type TTypeNotif = "success" | "warning" | "error";

export const useCreateNotif = () => {
  const create = (title: string, data: IItemTableAPI[], type: TTypeNotif) => {
    notification[type]({
      message: title,
      description: <BodyNotification data={data} />,
    });
  };
  return create;
};
