import { FC, useEffect, useState } from "react";
import { ILimitationDate } from "../../DatePicker/types";
import HeaderDateRange from "./header";
import * as Styled from "./style";
import TableDateRange from "./tableDate";

type TView = "start" | "end";

export interface IDate {
  date: number;
  month: number;
  year: number;
}

interface IProps {
  startDate: Date;
  endDate: Date;
  setDate: (start: Date, end: Date) => void;
  top: number;
  left: number;
  limitationDate:ILimitationDate
}

interface IView {
  date: Date;
  month: number;
  year: number;
}

const monthNumberToText = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];


const OpenDateRange: FC<IProps> = ({
  startDate,
  endDate,
  setDate,
  left,
  top,
  limitationDate,
}) => {
  const [viewStart, setViewStart] = useState<IView>({
    date: new Date(0),
    month: startDate.getMonth(),
    year: startDate.getFullYear(),
  });

  const [viewEnd, setViewEnd] = useState<IView>({
    date: new Date(0),
    month: endDate.getMonth(),
    year: endDate.getFullYear(),
  });

  const [lastClick, setLastClisk] = useState<TView>("start");

  const [hoverDate, setHoverDate] = useState<Date>(new Date(0));

  useEffect(() => {
    const start = viewStart.date;
    const end = viewEnd.date;
    if (!start.getTime() || !end.getTime()) return;
    if (start < end) {
      setDate(start, end);
    } else {
      setDate(end, start);
    }
  }, [viewStart.date, viewEnd.date]);

  const getTextHeader = (type: TView) => {
    const data = type === "start" ? viewStart : viewEnd;
    return monthNumberToText[data.month].slice(0, 3) + " " + data.year;
  };

  const getHandlerHeader = (type: TView, isSetting = false) => {
    const data = type === "start" ? viewStart : viewEnd;
    const setData = type === "start" ? setViewStart : setViewEnd;
    const setLast = isSetting ? () => {} : () => setLastClisk(type);

    const handlers = {
      nextMonthClick: () => {
        setLast();
        if (data.month !== 11) {
          setData((prev) => ({
            ...prev,
            month: prev.month + 1,
          }));
          return;
        }
        handlers.nextYearClick();
      },
      nextYearClick: () => {
        setLast();
        setData((prev) => ({
          ...prev,
          month: 0,
          year: prev.year + 1,
        }));
      },
      backMonthClick: () => {
        setLast();
        if (data.month) {
          setData((prev) => ({
            ...prev,
            month: prev.month - 1,
          }));
          return;
        }
        handlers.backYearClick();
      },
      backYearClick: () => {
        setLast();
        setData((prev) => ({
          ...prev,
          month: 11,
          year: prev.year - 1,
        }));
      },
    };
    return handlers;
  };

  useEffect(() => {
    const isStart = lastClick === "start";
    const handler = getHandlerHeader(isStart ? "end" : "start", true);
    const handlerMounth = isStart
      ? handler.nextMonthClick
      : handler.backMonthClick;
    const handlerYear = isStart ? handler.nextYearClick : handler.backYearClick;
    const errorMounth =
      viewStart.month >= viewEnd.month && viewStart.year == viewEnd.year;
    const errorYear = viewStart.year > viewEnd.year;
    if (errorMounth) {
      handlerMounth();
    }
    if (errorYear) {
      handlerYear();
    }
  }, [viewStart, viewEnd]);

  const setDateTable = (date: Date) => {
    const setData = !viewStart.date.getTime() ? setViewStart : setViewEnd;
    setData((prev) => ({ ...prev, date }));
  };

  return (
    <Styled.Wrapper top={top} left={left}>
      <Styled.WrapperHeader>
        <HeaderDateRange
          text={getTextHeader("start")}
          handlersClick={getHandlerHeader("start")}
        />
        <HeaderDateRange
          text={getTextHeader("end")}
          handlersClick={getHandlerHeader("end")}
        />
      </Styled.WrapperHeader>
      <Styled.WrapperTable>
        <TableDateRange
          start={viewStart.date}
          end={viewEnd.date}
          month={viewStart.month}
          year={viewStart.year}
          setDate={setDateTable}
          hoverDate={hoverDate}
          setHoverDate={setHoverDate}
          limitationDate={limitationDate}
        />
        <TableDateRange
          start={viewStart.date}
          end={viewEnd.date}
          month={viewEnd.month}
          year={viewEnd.year}
          setDate={setDateTable}
          hoverDate={hoverDate}
          setHoverDate={setHoverDate}
          limitationDate={limitationDate}
        />
      </Styled.WrapperTable>
    </Styled.Wrapper>
  );
};

export default OpenDateRange;
