import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px 0;
`;

export const WrapperButtons = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
