import { useContext } from "react";
import { IDataCommon, IDataFS, TOnErrrorData } from "../../../types";
import { validateCommonData, validateData } from "./data";
import { ContextInvoiceBuilder } from "../../..";
import { splitKey } from "../../../../../utils/getIndexedKey";
import { scrollToError } from "../../../../../utils/scrollToError";

interface CheckResult {
  dataFS: { key: keyof IDataFS; error: string }[];
  dataCommon: { key: string; error: string }[];
}

const validateAllData = (
  dataFS: IDataFS,
  dataCommon: IDataCommon
): CheckResult => {
  const { isUS } = dataCommon;
  const errorsFS = validateData(dataFS, isUS);

  const commonErrors = validateCommonData(dataCommon);

  return {
    dataFS: errorsFS,
    dataCommon: commonErrors,
  };
};

export const useIsValidAndHighlightAllErrors = (): (() => boolean) => {
  const { onErrorData, dataCommon, dataFS, setDataCommon } = useContext(
    ContextInvoiceBuilder
  );

  return () => {
    const result = validateAllData(dataFS, dataCommon);

    result.dataFS.forEach(({ key, error }) => onErrorData(key, error));
    updateCommonData(dataCommon, setDataCommon, result.dataCommon);
    const errors = [...result.dataFS, ...result.dataCommon];
    scrollToError(errors);

    return !errors.filter(({ error }) => !!error.length).length;
  };
};

const updateCommonData = (
  dataCommon: IDataCommon,
  setDataCommon: (data: IDataCommon) => void,
  errors: { key: string; error: string }[]
) => {
  let newDataCommon = dataCommon;
  errors.forEach(({ key: errorKeyId, error: errorMessage }) => {
    const { key: errorKey, index: errorKeyIndex } = splitKey(errorKeyId);
    newDataCommon = {
      ...newDataCommon,
      dataTable: newDataCommon.dataTable.map((row, index) => {
        if (index === errorKeyIndex) {
          return {
            ...row,
            [errorKey]: {
              ...row[errorKey as keyof typeof row],
              error: errorMessage,
            },
          };
        } else {
          return row;
        }
      }),
    };
  });
  setDataCommon(newDataCommon);
};

export const useValidateOnBlur = (
  dataFS: IDataFS,
  dataCommon: IDataCommon,
  onErrorData: TOnErrrorData
): ((
  type: keyof IDataFS,
  fieldsToRemoveErrors?: (keyof IDataFS)[]
) => void) => {
  return (type: keyof IDataFS, fieldsToRemoveErrors?: (keyof IDataFS)[]) => {
    const validationRes = validateAllData(dataFS, dataCommon);

    fieldsToRemoveErrors?.forEach((field) => {
      const addField = validationRes.dataFS.find((it) => it.key === field);
      if (addField === undefined || addField.error === "") {
        onErrorData(field, "");
      }
    });
    const dataFSErr = validationRes.dataFS.find((it) => it.key === type);
    if (dataFSErr !== undefined) {
      onErrorData(dataFSErr.key, dataFSErr.error);
    }
  };
};
