import styled from "styled-components";

import { WRAPPER_BG_PATHS } from "../../../assets";

export const WrapperBG = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url("${WRAPPER_BG_PATHS.large}");
  background-size: cover;

  @media (max-width: 1400px) {
    background-image: url("${WRAPPER_BG_PATHS.medium}");
  }
  @media (max-width: 900px) {
    background-image: url("${WRAPPER_BG_PATHS.small}");
  }
`;

export const WrapperBody = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;
