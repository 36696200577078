import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

interface IWrapperText {
  type: string;
}

const getTextAling: { [key: string]: string } = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
  unset: "unset",
};

export const WrapperText = styled.div<IWrapperText>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${({ type }) => getTextAling[type] || "flex-start"};
  gap: 0 5px;
`;
