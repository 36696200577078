import styled, { keyframes } from "styled-components";
import { TextBold } from "../../../../commonStyle";

interface IWrapperAnimation {
  isOpen: boolean;
  height: number;
}

export const WrapperAnimation = styled.div<IWrapperAnimation>`
  height: ${({ isOpen, height }) => (isOpen ? height + "px" : "0px")};
  transition: height 0.2s;
  padding: ${({ isOpen }) => (isOpen ? "0 16px 16px 16px" : "0px")};
  /* & > div {
    height: ${({ isOpen, height }) => (isOpen ? height + "px" : "0px")};
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    transition: opacity 0.2s;
  } */
`;

interface IWrapper {
  isOpen: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  border-top: 1px solid #e5e5e5;
  padding: ${({ isOpen }) => (isOpen ? `22px` : "0")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transition: opacity 0.2s;

  @media (max-width: 1024px) {
    padding: 0;
    border-top: none;
  }

  @media (min-width: 1024px) {
    & > div {
      display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    }
  }
`;
interface IWrapperDetails {
  isOpen: boolean;
  paddingLeft: string;
}
export const WrapperDetails = styled.div<IWrapperDetails>`
  padding-left: ${({ isOpen, paddingLeft }) => (isOpen ? paddingLeft : "0")};
  @media (max-width: 1024px) {
    padding-left: 0;
  }
`;

export const WrapperInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  margin-bottom: 10px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Title = styled(TextBold)`
  width: 180px;
  margin-right: 20px;
  @media (max-width: 1024px) {
    font-size: 16px;
    width: 100%;
  }
`;

export const TextLink = styled.a`
  font-size: 14px;
  color: #6c64f4;
  word-break: break-all;
  text-decoration: none;
`;

export const WrapperTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
