import { createContext, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PickerComponent from "../../component/Global/Picker";
import Table from "../../component/table";
import PaginationTable from "../../component/table/pagination";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import WrapperWhiteBG from "../../component/Wrappers/wrapperWhiteBG";
import { TransactionsAPI } from "../../services/transactions";
import { changeOpenType } from "../../store/user";
import useSizePage from "../../utils/hookSizePage";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import {
  getFilterData,
  getPayload,
  getTable,
  initContext,
  initData,
} from "./data";
import TransactionDownload from "./download";
import TransactionHeader from "./header";
import TransactionsInfoHeader from "./infoComponent/header";
import * as Styled from "./style";

export const ContextTransactions = createContext(initContext);

const TransactionsPage = () => {
  const [queryData, infoDataQuery] = useMutationAPI(TransactionsAPI.getData, {
    changeIsLoading: true,
  });

  const dispatch = useDispatch();

  const [isBlockQuery, setIsBlockQuery] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [data, setData] = useState(initData);
  const { width } = useSizePage();

  const onChangeData: typeof initContext["onChangeData"] = (key, data) =>
    setData((prev) => ({ ...prev, [key]: data }));

  useEffect(() => {
    dispatch(changeOpenType({ openType: "transactions-report" }));
  }, []);

  useEffect(() => {
    if (isBlockQuery) return;
    setIsBlockQuery(true);
    queryData(getPayload(data, isInit));
    setIsInit(false);
  }, [
    data.site,
    data.interval,
    data.siteType,
    data.startDate,
    data.endDate,
    data.view_page.page_number,
    data.view_page.page_size,
  ]);

  useEffect(() => {
    const response = infoDataQuery.data?.data;

    if (response) {
      const { data: dataTable, filters, header, view_page } = response;
      const {
        sites_filter,
        intervals_filter,
        status_filter,
        export_filter,
        dates_filter,
      } = filters;
      const { date_from, date_to } = dates_filter;

      const intervalData = getFilterData(intervals_filter);
      const siteTypeData = getFilterData(status_filter);
      const siteData = getFilterData(sites_filter);
      const downloadData = getFilterData(export_filter);

      const site = siteData.find(
        ({ id }) => id === sites_filter.dropdown_options.value
      ) || { id: "", text: "" };

      const interval = intervalData.find(
        ({ id }) => id === intervals_filter.dropdown_options.value
      ) || { id: "", text: "" };

      const siteType = siteTypeData.find(
        ({ id }) => id === status_filter.dropdown_options.value
      ) || { id: "", text: "" };

      setData((prev) => ({
        ...prev,
        dataTable,
        intervalData,
        infoHeader: header,
        siteTypeData,
        siteData,
        downloadData,
        view_page: { ...prev.view_page, total_items: view_page.total_items },
        site,
        startDate: new Date(date_from.value),
        endDate: new Date(date_to.value),
        interval,
        siteType,
      }));
    
      setTimeout(() => setIsBlockQuery(false), 1000);
    }
  }, [infoDataQuery]);

  return (
    <ContextTransactions.Provider value={{ data, onChangeData }}>
      <WrapperBodyTitle {...infoDataQuery.data?.data.page?.header}>
        <TransactionHeader />
        <WrapperWhiteBG>
          <TransactionsInfoHeader />
        </WrapperWhiteBG>
        {!!data.dataTable.length && <TransactionDownload data={data} />}
        <Styled.WrapperTable>
          <Table
            table={getTable(data.dataTable, width)}
            details={data.dataTable.map(({ details }) => details || [])}
            disablePagination
            pageSize={data.view_page.page_size}
          />
        </Styled.WrapperTable>
        {!!data.dataTable.length && (
          <PaginationTable
            current={data.view_page.page_number}
            pageSize={data.view_page.page_size}
            total={data.view_page.total_items}
            onChange={(page_number, page_size) => {
              onChangeData("view_page", {
                ...data.view_page,
                page_number,
                page_size,
              });
            }}
          />
        )}
      </WrapperBodyTitle>
    </ContextTransactions.Provider>
  );
};

export default TransactionsPage;
