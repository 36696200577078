export interface IRulesString {
  isReq?: boolean;
  min?: number;
  max?: number;
  isEmail?: boolean;
  ownRule?: {
    rule: ((value: string) => boolean) | RegExp;
    error: string;
  };
}

const validateString = (value: string, rules: IRulesString) => {
  const { isReq, min, max, isEmail, ownRule } = rules;
  if (isReq && !value.length) return "Required";
  if (!isReq && !value.length) return "";
  if (isEmail) {
    let re = /\S+@\S+\.\S+/;
    if (!re.test(value)) return "E-mail required";
  }
  if (min && value.length < min) return `Required (min: ${min} chars)`;
  if (max && value.length > max) return `Required (max: ${max} chars)`;
  if (ownRule && typeof ownRule.rule === "function" && !ownRule.rule(value))
    return ownRule.error;
  if (ownRule && typeof ownRule.rule !== "function" && !ownRule.rule.test(value))
    return ownRule.error;

  return "";
};

export default validateString;
