import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
`;

interface IWrapperValue {
  borderColor: string;
  isOpen: boolean;
}

export const WrapperValue = styled.div<IWrapperValue>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid;
  cursor: pointer;
  border-color: ${({ isOpen, borderColor }) =>
    isOpen ? borderColor : "#d9d9d9"};
  padding: 8px 15px 8px 0;
  &:hover {
    border-color: ${({ borderColor }) => borderColor};
    box-shadow: 0 0 1px #00000088;
  }
`;

export const WrapperDate = styled.div`
  width: calc(100% - 14px);
  display: flex;
  justify-content: space-around;
  gap: 0 20px;
  padding: 0 30px;
`;
