import styled from "styled-components";

export const WrapperButton = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1024px) {
    position: sticky;
    bottom: 20px;
  }
`;
