import { FC, ReactNode } from "react";
import { IButtonAPI } from "../../../services/types";
import { IItemTableAPI } from "../../../services/types/table";
import { TOnClickButtonToAntd } from "../../Button";
import ViewSteps from "../../viewSteps";
import WrapperBodyTitle from "../WrapperBodyTitle";
import WrapperSteps from "../wrapperSteps";

interface IProps<DB, AB> {
  children: ReactNode;
  viewStep?: string[];
  step: number;
  title?: string;
  text?: IItemTableAPI[][];
  buttons?: IButtonAPI<DB, AB>[];
  onClickButton?: TOnClickButtonToAntd<DB, AB>;
}

const viewStepDefault = ["Fill out", "Verify", "Authorize"];

const WrapperPayment = <DB, AB>({
  title,
  text,
  buttons,
  onClickButton,
  children,
  viewStep = viewStepDefault,
  step,
}: IProps<DB, AB>) => {
  return (
    <WrapperBodyTitle
      title={title}
      text={text}
      buttons={buttons}
      onClickButton={onClickButton}
    >
      <ViewSteps step={step} viewSteps={viewStep} />
      <WrapperSteps step={step}>{children}</WrapperSteps>
    </WrapperBodyTitle>
  );
};

export default WrapperPayment;
