import { Button } from "antd";
import { useContext, useEffect } from "react";
import { ContextInvoiceBuilder } from "../../..";
import { IPayloadInvoiceValid } from "../../../../../services/invoiceTemplateBuilder/types/payload";
import { InvoiceTBAPI } from "../../../../../services/invoiceTemplateBuilder";
import { IErrorPayment } from "../../../../../services/types";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";

import { getErrorResponse, getPayloadAPI } from "./data";
import * as Styled from "./style";
import { useIsValidAndHighlightAllErrors } from "./validator";

type TErrorsResponse =
  | IErrorPayment<keyof IPayloadInvoiceValid["payload"]>[]
  | undefined;

const IBFSContinue = () => {
  const { dataFS, dataCommon, setStep, onErrorData, setDataSS, setDataCommon } =
    useContext(ContextInvoiceBuilder);
  const { invoiceID, dataTable, paymentPrefix, isUS } = dataCommon;

  const [queryValid, infoValidQuery] = useMutationAPI(InvoiceTBAPI.validate);

  useEffect(() => {
    const response = infoValidQuery.data?.data;
    const responseError = infoValidQuery.error?.response?.data;

    if (response) {
      const {
        header,
        beneficiary,
        remitter,
        payment_details,
        invoice_items,
        footer,
      } = response;

      setDataSS((prev) => ({
        ...prev,
        beneficiary: beneficiary,
        remmiter: remitter,
        details: payment_details,
        table: invoice_items.data,
        footer: footer.data,
        logo: header.headerLogoContents,
      }));
      setStep(2);
    }

    if (responseError) {
      const resError = responseError.errors as TErrorsResponse;

      if (!resError) return;

      const { errors, errorTable } = getErrorResponse(resError);
      errors.forEach(({ key, error }) => onErrorData(key, error));

      setDataCommon((prev) => {
        const dataTable = [...prev.dataTable];

        errorTable.forEach(({ key, index, error }) => {
          if (isNaN(index)) return;

          dataTable[index][key].error = error;
        });

        return { ...prev, dataTable };
      });
    }
  }, [infoValidQuery]);

  const isValidAndHighlightAllErrors = useIsValidAndHighlightAllErrors();

  const onClickContinue = () => {
    if (isValidAndHighlightAllErrors()) {
      queryValid({
        invoiceID,
        payload: getPayloadAPI(dataFS, dataTable, paymentPrefix),
      });
    }
  };

  return (
    <Styled.Wrapper>
      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onClickContinue}
        loading={infoValidQuery.isLoading}
        // disabled={isErrorOnTable || isErrorOnFields}
      >
        Continue to invoice preview
      </Button>
    </Styled.Wrapper>
  );
};

export default IBFSContinue;
