import { Button } from "antd";
import { AxiosError } from "axios";
import { useContext, useEffect, useMemo } from "react";
import { CardTopUpContext } from "../../..";
import { IValueError } from "../../../../../component/payment/types";
import { CardAPI } from "../../../../../services/cards";
import { IPayloadTUValidate } from "../../../../../services/cards/types/payload";
import { IErrorPayment } from "../../../../../services/types";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { getErrorResponse, getPayload, validateData } from "./data";
import * as Styled from "./style";

type TErrorsResponse =
  | IErrorPayment<keyof IPayloadTUValidate["payload"]>[]
  | undefined;

const CTUFSContinueButton = () => {
  const valueContext = useContext(CardTopUpContext);

  const {
    dataFS,
    dataCommon,
    setErrorDataFS,
    setDataSS,
    setDataTS,
    setDataCommon,
    setStep,
  } = valueContext;
  const { paymentId, accountId } = dataCommon;

  const [queryValid, infoValidQuery] = useMutationAPI(CardAPI.topUp.validate);

  const isDisable = useMemo(() => {
    const errors = Object.values(dataFS) as IValueError<any>[];
    return !!errors.filter(({ error }) => !!error.length).length;
  }, [dataFS]);

  useEffect(() => {
    const response = infoValidQuery.data;
    const responseError = infoValidQuery.error;

    if (response) {
      const { data, payment } = response.data;
      const { paymentAmountFormatted, paymentChecksum, paymentName } = payment;

      setDataSS(data);
      setDataTS({ name: paymentName, amount: paymentAmountFormatted });
      setDataCommon((prev) => ({ ...prev, checksum: paymentChecksum }));
      setStep(2);
    }

    if (responseError && responseError.response) {
      const errors = responseError.response.data.errors as TErrorsResponse;
      if (!errors) return;
      const errorsData = getErrorResponse(errors);

      errorsData.forEach(({ key, error }) => setErrorDataFS(key, error));
    }
  }, [infoValidQuery]);

  const validate = () => {
    const errors = validateData(dataFS, dataCommon.currencySymbol);

    errors.forEach(({ key, error }) => setErrorDataFS(key, error));

    return !errors.filter(({ error }) => !!error.length).length;
  };

  const onClickContinue = () => {
    if (validate()) {
      queryValid(getPayload(dataFS, paymentId, accountId));
    }
  };

  return (
    <Styled.Wrapper>
      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onClickContinue}
        loading={infoValidQuery.isLoading}
        // disabled={isDisable}
      >
        Continue
      </Button>
    </Styled.Wrapper>
  );
};

export default CTUFSContinueButton;
