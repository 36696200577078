import styled from "styled-components";
import { Text } from "../../../../commonStyle";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Text)`
  font-size: 22px;
  margin-bottom: 16px;
`;

export const WrapperSend = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;
