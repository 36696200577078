import validatePassword from "../../../../utils/validate/validatePassword";

export const validateNewOldPasswords = (
  oldPassword: string,
  newPassword: string
): [string, string] => {
  const oldPasswordLengthError = validatePassword(oldPassword);
  let newPasswordError = validatePassword(newPassword);

  if (newPasswordError === "") {
    if (oldPassword === newPassword) {
      newPasswordError = "New password should not be equal to old password";
    }
  }

  return [oldPasswordLengthError, newPasswordError];
};
