import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px 0;
`;

export const WrapperImg = styled.div`
  width: 150px;
  height: 150px;
  & > img {
    width: 100%;
    height: 100%;
  }
`;
