import { IDataHintsInput } from "../../../component/Global/Input/hints";
import { IItemPicker } from "../../../component/Global/Picker";
import { IFileTransferDocument } from "../../../component/Global/transferDocument/types";
import {
  IBeneficiaryUkAPI,
  IDictionariesUk,
  IFilesPayment,
} from "../../../services/paymentAPI/types";
import { IPayloadValidateUk } from "../../../services/paymentAPI/types/payload";
import { getValueError } from "../data";
import { IDataCommon, IDataFS } from "./types";

export const initDataFS: IDataFS = {
  DIsInterTransfer: getValueError(false),
  DAmount: getValueError("0.00"),
  DPaymentDescription: getValueError(""),
  DIsCompanyName: getValueError(true),

  BCompany: getValueError(""),
  BCountry: getValueError({ text: "", id: "" }),
  BState: getValueError({ text: "", id: "" }),
  BCaState: getValueError({ text: "", id: "" }),
  BCity: getValueError(""),
  BPostalCode: getValueError(""),
  BStreetAddress: getValueError(""),
  BHouseNumber: getValueError(""),
  BUkAccountNumber: getValueError(""),
  BBic: getValueError(""),
  BSortCode: getValueError(""),

  Tfiles: getValueError([]),
};

export const initDataCommon: IDataCommon = {
  amountSymbol: "",
  balance: 0,
  dataHeader: [],
  canadaID: "",
  usID: "",
  paymentID: "",
  checksum: "",
  typeAuth: "google-authenticator",
  dataState: [],
  dataCaState: [],
  companyHints: [],
  accountId: "",
  isRequireFiles: true,
  isDisableSaveAsDraft: false,
  useCompanyNameInDescriptionControl: true,
  companyName: "",
  dataCountry: [],
  isRequireIBAN: false,
  isRequireAddress: false,
  isLoadingFiles: false,
  prefixDescription: "Test Company",
  isAbleAuth: false,
  isUsingTemplate: false,
};

const getDataHints = (
  data: IBeneficiaryUkAPI,
  dictionaries: IDictionariesUk
): Partial<IDataFS> => {
  const countryName = dictionaries.countries.find(
    (item) => item.countryId === data.paymentNameCountryId
  )?.countryName;
  const stateName = dictionaries.us_states.find(
    (item) => item.stateCode === data.paymentNameAddressState
  )?.stateName;
  const canadaStateName = dictionaries.ca_states.find(
    (item) => item.stateCode === data.paymentNameAddressState
  )?.stateName;

  return {
    BUkAccountNumber: getValueError(data.paymentBankUkAccountNumber || ""),
    BBic: getValueError(data.paymentBankBic || ""),

    BCountry: getValueError({
      text: countryName || "",
      id: data.paymentNameCountryId || "",
    }),
    BCity: getValueError(data.paymentNameAddressCity || ""),
    BState: getValueError({
      text: stateName || "",
      id: data.paymentNameAddressState || "",
    }),
    BCaState: getValueError({
      text: canadaStateName || "",
      id: data.paymentNameAddressState || "",
    }),
    BPostalCode: getValueError(data.paymentNameAddressPostalCode || ""),
    BStreetAddress: getValueError(data.paymentNameAddressStreet || ""),
    BHouseNumber: getValueError(data.paymentNameAddressNumber || ""),
    BSortCode: getValueError(data.paymentBankSortCode || ""),
  };
};

export const getCompanyHints = (
  beneficiaries: IBeneficiaryUkAPI[],
  dictionaries: IDictionariesUk
) => {
  const data = beneficiaries.map(
    (item): IDataHintsInput => ({
      title: { text: item.paymentName, type: "em" },
      subTitle: item.details,
      data: getDataHints(item, dictionaries),
    })
  );
  return data;
};

export const getDataCointry = (dictionaries: IDictionariesUk) => {
  const data = dictionaries.countries.map(
    ({ countryId, countryName }): IItemPicker => ({
      id: countryId,
      text: countryName,
    })
  );
  return data;
};

const resToData: {
  [key in keyof IPayloadValidateUk["payload"]]: keyof IDataFS;
} = {
  accountId: "BBic",
  paymentBankUkAccountNumber: "BUkAccountNumber",
  paymentAmount: "DAmount",
  paymentBankBic: "BBic",
  paymentIsSameCompanyTransfer: "DIsInterTransfer",
  paymentDescriptionCompanyName: "DIsCompanyName",
  paymentName: "BCompany",
  paymentNameAddressCity: "BCity",
  paymentNameAddressNumber: "BHouseNumber",
  paymentNameAddressPostalCode: "BPostalCode",
  paymentNameAddressState: "BState",
  paymentNameAddressStreet: "BStreetAddress",
  paymentNameCountryId: "BCountry",
  paymentReference: "DPaymentDescription",
  paymentBankSortCode: "BSortCode",
};

type TValue = boolean | string | IItemPicker | IFileTransferDocument[];

export const getDataFromForm = (
  form: Partial<IPayloadValidateUk["payload"]>,
  files: IFilesPayment[],
  dataCountry: IItemPicker[],
  dataState: IItemPicker[],
  dataCaState: IItemPicker[],
  paymentDescription: string
) => {
  let responseData: Partial<{
    [key in keyof IDataFS]: TValue;
  }> = {};

  const keysForm = Object.keys(form) as (keyof typeof form)[];

  keysForm.forEach((key) => {
    const value = form[key];
    const keyData = resToData[key];

    if (!keyData) return;

    if (typeof value === "number") {
      responseData[keyData] = value.toFixed(2);
      return;
    }

    if (keyData === "BCountry") {
      responseData[keyData] =
        dataCountry.find(({ id }) => id === form.paymentNameCountryId) ||
        initDataFS.BCountry.value;
      return;
    }

    if (keyData === "BState") {
      responseData["BCaState"] =
        dataCaState.find(({ id }) => id === form.paymentNameAddressState) ||
        initDataFS.BCaState.value;
      responseData["BState"] =
        dataState.find(({ id }) => id === form.paymentNameAddressState) ||
        initDataFS.BState.value;
      return;
    }

    if (
      keyData === "DPaymentDescription" &&
      typeof value === "string" &&
      form.paymentDescriptionCompanyName
    ) {
      responseData[keyData] = value.slice(paymentDescription.length + 1);
      return;
    }

    responseData[keyData] = value;
  });

  responseData["Tfiles"] = files.map(({ documentId, name, url }) => ({
    id: documentId,
    name,
    url,
    isLoading: false,
  }));

  return Object.entries(responseData) as [keyof IDataFS, TValue][];
};
