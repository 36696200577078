import { Button, InputRef } from "antd";
import { FC, forwardRef } from "react";
import AmountComponent from "../../../../../../../../../component/Global/Amount";
import InputComponent from "../../../../../../../../../component/Global/Input";
import SwitchComponent from "../../../../../../../../../component/Global/Switch";
import { TAuthCode } from "../../../../../../../../../services/types";
import { useAppSelector } from "../../../../../../../../../utils/useState";
import { IData } from "../../types";
import * as Styled from "./style";

interface IProp {
  code: string;
  onChangeCode: (code: string) => void;
  onClickButton: () => void;
  isLoading: boolean;
}

const textType = (type: TAuthCode) => {
  if (type === "google-authenticator")
    return "Please enter Google Authenticator code";
  return "Please enter SMS code";
};

const CTFCAutoTopUpSS = forwardRef<InputRef, IProp>(
  ({ code, onChangeCode, onClickButton, isLoading }, ref) => {
    const { userMultiFactorType } = useAppSelector(
      (store) => store.userReducer
    );
    return (
      <Styled.Wrapper>
        <Styled.Title>{textType(userMultiFactorType)}</Styled.Title>
        <InputComponent
          value={code}
          onChangeValue={onChangeCode}
          placeholder="Code"
          width="60%"
          ref={ref}
        />

        <Button
          shape="round"
          size="large"
          style={{ marginTop: "15px" }}
          onClick={onClickButton}
          loading={isLoading}
        >
          Authorize & Save
        </Button>
      </Styled.Wrapper>
    );
  }
);

export default CTFCAutoTopUpSS;
