import CTUFSContinueButton from "./continueButton";
import CTUFSRemitterDetails from "./remitterDetails";
import CTUFSTopUpDetails from "./topUpDetails";

const CTUFirstStep = () => {
  return (
    <div>
      <CTUFSRemitterDetails />
      <CTUFSTopUpDetails />
      <CTUFSContinueButton />
    </div>
  );
};

export default CTUFirstStep;
