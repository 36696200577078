import { FC, useId } from "react";
import { Text, TextBold } from "../../../commonStyle";
import * as Styled from "./style";
import { MAIN_THEME } from "../../../assets";

interface IProps {
  amount?: string;
  name?: string;
  title?: string;
}

const HeaderThirdStepPayment: FC<IProps> = ({
  amount,
  name,
  title = "Authorize payment",
}) => {
  const idComponent = useId();
  const dataInfo = [
    { text: "to", color: undefined },
    { text: name, color: MAIN_THEME.projectColor },
    { text: "for", color: undefined },
    { text: amount, color: MAIN_THEME.projectColor },
  ];

  return (
    <>
      <TextBold
        textAling="center"
        fontSize={22}
        color={MAIN_THEME.projectColor}
      >
        {title}
      </TextBold>
      {amount && name && (
        <Styled.WrapperInfo>
          {dataInfo.map(({ text, color }, index) => (
            <Text key={idComponent + index} fontSize={16} color={color}>
              {text}
            </Text>
          ))}
        </Styled.WrapperInfo>
      )}
    </>
  );
};

export default HeaderThirdStepPayment;
