import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WrapperPayment from "../../../component/Wrappers/wrapperPayment";
import { CryptoAPI } from "../../../services/crypto";
import { IItemTableAPI } from "../../../services/types/table";
import { getChangeError } from "../../../utils/getChangeError";
import { useQuestionBeforeLeave } from "../../../utils/questionBeforeLeave";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import { initDataCommon, initDataFS, initDataSS, initDataTS } from "./data";
import CryptoSendFS from "./firstStep";
import CryptoSendSS from "./secondStep";
import CryptoSendTS from "./thirdStep";
import { IContext } from "./types";

interface ILocation {
  state: {
    accountId?: string;
    paymentId?: string;
  };
}

const initSetData = () => console.log("init Crypto Send Context");

const initContext: IContext = {
  step: 1,
  setStep: initSetData,
  dataFS: initDataFS,
  setDataFS: initSetData,
  dataCommon: initDataCommon,
  setDataCommon: initSetData,
  dataSS: initDataSS,
  setDataSS: initSetData,
  dataTS: initDataTS,
  setDataTS: initSetData,
  onChangeData: initSetData,
  onErrorData: initSetData,
};

export const CryptoSendContext = createContext(initContext);

const CryptoSend = () => {
  const navigate = useNavigate();
  const { accountId, paymentId } = (useLocation() as ILocation).state || {};

  const { data, error } = useQueryAPI(CryptoAPI.send.init, {
    payload: { id: accountId || paymentId || "", isEdit: !!paymentId?.length },
    changeIsLoading: true,
  });

  const [step, setStep] = useState(1);
  const [dataCommon, setDataCommon] = useState(initDataCommon);
  const [dataFS, setDataFS] = useState(initDataFS);
  const [dataSS, setDataSS] = useState(initDataSS);
  const [dataTS, setDataTS] = useState(initDataTS);

  const { onChangeData, onErrorData } = getChangeError(setDataFS);

  const onFinishLoading = useQuestionBeforeLeave({ deps: [dataFS] });

  useEffect(() => {
    if (accountId === undefined && paymentId === undefined)
      navigate("/new-payment");
  }, []);

  useEffect(() => {
    if (data) {
      const { header, account, transfer } = data.data;
      const { currencySymbol, currencyCode, accountId, balance } = account;
      const { paymentId, confirmMessage } = transfer;
      setDataCommon((prev) => ({
        ...prev,
        currencySymbol,
        accountId,
        paymentId,
        balance: balance.available,
        currencyCode,
        confirmMessage,
      }));
      setTimeout(() => onFinishLoading(false), 1000);
    }
  }, [data]);

  return (
    <CryptoSendContext.Provider
      value={{
        step,
        setStep,
        dataCommon,
        setDataCommon,
        dataFS,
        setDataFS,
        dataSS,
        setDataSS,
        dataTS,
        setDataTS,
        onChangeData,
        onErrorData,
      }}
    >
      <WrapperPayment title="Send Crypto" step={step}>
        <CryptoSendFS />
        <CryptoSendSS />
        <CryptoSendTS />
      </WrapperPayment>
    </CryptoSendContext.Provider>
  );
};

export default CryptoSend;
