import { FC, useEffect, useId, useMemo, useState } from "react";
import ItemTable from "./item";
import { IDataRowTable, IPagination, ITable, ITableDetails } from "./types";
import * as Styled from "./style";
import HeaderTable from "./header";
import { Empty } from "antd";
import PaginationTable from "./pagination";
import { IRowTable } from "../../services/types/table";
import { getDataRow } from "./data";

interface IProps {
  table: ITable[];
  details?: ITableDetails[][];
  details_table?: IRowTable["details_table"][];
  idRows?: string[];
  idSeletRows?: string[];
  onSelectRow?: (idRows: string[]) => void;
  onClickDelete?: (index: number) => void;
  onClickEdit?: (data: IDataRowTable[], index: number) => void;
  onClickDuplicate?: (index: number, data: IDataRowTable[]) => void;
  title?: string;
  pageSize?: number;
  disablePagination?: boolean;
  paddingDetails?: string;
}

const Table: FC<IProps> = ({
  table,
  details = [],
  idRows,
  idSeletRows = [],
  onSelectRow,
  onClickDelete,
  onClickEdit,
  onClickDuplicate,
  title,
  pageSize = 10,
  disablePagination = false,
  paddingDetails = "170px",
  details_table = [],
}) => {
  const idComponent = useId();
  const [paginationData, setPaginationData] = useState<IPagination>({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    setPaginationData((prev) => ({ ...prev, pageSize }));
  }, [pageSize]);

  const dataRow = useMemo(
    () => getDataRow(table, details, details_table, idRows),
    [table, details]
  );
  const dataRowPagin = useMemo(() => {
    const { current, pageSize } = paginationData;
    const start = (current - 1) * pageSize;
    const end = current * pageSize;
    return dataRow.slice(start, end);
  }, [paginationData, dataRow]);

  const dataHeader = useMemo(
    () =>
      table.map(({ title, width, positionText }) => ({
        title,
        width,
        positionText,
      })),
    [table]
  );

  useEffect(() => {
    const { current, pageSize } = paginationData;
    if (dataRow.length < current * pageSize)
      setPaginationData((prev) => ({ ...prev, current: 1 }));
  }, [table]);

  if (!dataRow.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const onClickSelect = (id: string) => {
    if (!onSelectRow) return;
    const selectArr = idSeletRows.includes(id)
      ? [...idSeletRows].filter((item) => item !== id)
      : [...idSeletRows, id];

    onSelectRow(selectArr);
  };

  return (
    <Styled.Wrapper>
      {title && <Styled.Title>{title}</Styled.Title>}
      <div>
        <HeaderTable
          data={dataHeader}
          pagination={paginationData}
          idRows={idRows}
          idSelectRows={idSeletRows}
          onSelectRow={onSelectRow}
          isDelete={!!onClickDelete}
        />

        <Styled.WrapperRows>
          {dataRowPagin.map(
            ({ data, details, details_table, idRow }, index) => {
              const isSelected = idSeletRows.includes(idRow || "");
              return (
                <ItemTable
                  key={idComponent + (idRow ?? index)}
                  data={data}
                  details={details}
                  details_table={details_table}
                  id={idRow}
                  isSelected={isSelected}
                  onSelect={onClickSelect}
                  index={index}
                  onClickDelete={onClickDelete}
                  onClickEdit={onClickEdit}
                  onClickDuplicate={onClickDuplicate}
                  paddingDetails={paddingDetails}
                />
              );
            }
          )}
        </Styled.WrapperRows>
        {!disablePagination && (
          <PaginationTable
            {...paginationData}
            total={dataRow.length}
            onChange={(current, pageSize) => {
              if (onSelectRow && !!idSeletRows.length) {
                onSelectRow([]);
              }
              setPaginationData({ current, pageSize });
            }}
          />
        )}
      </div>
    </Styled.Wrapper>
  );
};

export default Table;
