import { PaperClipOutlined } from "@ant-design/icons";
import { FC, useId } from "react";
import { IItemTableAPI } from "../../../../../services/types/table";
import { TypeToText } from "../../../../../utils/typeToText";
import * as Styled from "./style";

interface IData {
  data: IItemTableAPI[];
  title: string;
}

interface IProps {
  data: IData[];
}

const ItemSecondStepSwift: FC<IProps> = ({ data }) => {
  const idCompoent = useId();
  return (
    <Styled.Wrapper>
      {data.map(({ title, data }, index) => {
        const key = idCompoent + "." + index;
        return (
          <Styled.WrapperItem key={key}>
            {!!title.length && (
              <Styled.Title fontSize={16}>{title}</Styled.Title>
            )}
            {data.map((item, index) => (
              <TypeToText {...item} key={key + "." + index} />
            ))}
          </Styled.WrapperItem>
        );
      })}
    </Styled.Wrapper>
  );
};

export default ItemSecondStepSwift;
