import { useContext } from "react";
import { ContextCardRequest } from "../../..";
import { Text } from "../../../../../commonStyle";
import SwitchComponent from "../../../../../component/Global/Switch";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import WrapperTitleError from "../../../../../component/Wrappers/wrapperTitleError";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { IPropsTypeToText } from "../../../../../utils/typeToText";

const CRTermsService = () => {
  const valueContext = useContext(ContextCardRequest);

  const { dataFS, dataCommon, onChangeDataFS } = valueContext;
  const { TSSwitch } = dataFS;

  const handleNormal = ({ text }: IPropsTypeToText) => {
    return (
      <Text
        onClick={() => onChangeDataFS("TSSwitch", !TSSwitch.value)}
        isCursor
      >
        {text}
      </Text>
    );
  };

  return (
    <WrapperWhiteBG title="Terms of Service">
      <WrapperTitleError error={TSSwitch.error}>
        <SwitchComponent
          checked={TSSwitch.value}
          onChange={(value) => onChangeDataFS("TSSwitch", value)}
          text={
            <TypeToTextMap
              data={[dataCommon.dataTerms]}
              specificType={[{ type: "normal", handle: handleNormal }]}
            />
          }
          id="TSSwitch"
        />
      </WrapperTitleError>
    </WrapperWhiteBG>
  );
};

export default CRTermsService;
