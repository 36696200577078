import { InputRef, Modal, Skeleton } from "antd";
import { createRef, FC, useEffect, useState } from "react";
import { Text } from "../../../../../../../commonStyle";
import TypeToTextMap from "../../../../../../../component/Global/TypeToTextMap";
import WrapperSteps from "../../../../../../../component/Wrappers/wrapperSteps";
import { CardAPI } from "../../../../../../../services/cards";
import { IDataInitOperation } from "../../../../../../../services/cards/types";
import { IItemTableAPI } from "../../../../../../../services/types/table";
import useMutationAPI from "../../../../../../../utils/usePromise/hookMutationAPI";
import { initData } from "./data";
import CTFCAutoTopUpFS from "./steps/firstStep";
import CTFCAutoTopUpSS from "./steps/secondStep";
import * as Styled from "./style";
import { IData } from "./types";

interface IProps {
  data: IItemTableAPI[][];
  cardId: string;
  onCancel: () => void;
  refetchData: () => void;
  propsDataField?: IDataInitOperation["data"]["cardAutoTopUpData"];
}

const CTFCAutoTopUp: FC<IProps> = ({
  data,
  cardId,
  onCancel,
  refetchData,
  propsDataField,
}) => {
  const [queryAuth, infoAuthQuery] = useMutationAPI(CardAPI.table.autoTopUp);

  const [dataField, setDataField] = useState(initData);
  const [step, setStep] = useState(1);

  const refCode = createRef<InputRef>();

  useEffect(() => {
    if (propsDataField) {
      setDataField((prev) => ({
        ...prev,
        isEnabled: propsDataField.status,
        amountBelow: propsDataField.minimumBalanceDropAmount.toFixed(2),
        amountTopUp: propsDataField.minimumTopUpAmount.toFixed(2),
      }));
    }
  }, [propsDataField]);

  useEffect(() => {
    const response = infoAuthQuery.data?.data;
    const responseError = infoAuthQuery.error?.response;

    if (response || responseError) {
      onCancel();
      refetchData();
    }
  }, [infoAuthQuery]);

  const setData = <K extends keyof IData, D extends IData[K]>(
    key: K,
    data: D
  ) => {
    setDataField((prev) => ({ ...prev, [key]: data }));
  };

  const onClickAuth = () => {
    queryAuth({
      cardId,
      payload: {
        status: dataField.isEnabled,
        minimumBalanceDropAmount: dataField.amountBelow,
        minimumTopUpAmount: dataField.amountTopUp,
        auth_code: dataField.authCode,
      },
    });
  };

  useEffect(() => {
    if (step === 2) {
      setTimeout(() => {
        if (refCode.current) {
          refCode.current.focus();
        }
      }, 500);
    }
  }, [step]);

  return (
    <Modal open={true} onCancel={onCancel} width={478}>
      {false ? (
        <Skeleton paragraph={{ rows: 7 }} />
      ) : (
        <Styled.Wrapper>
          <Text fontSize={20} margin="0 0 16px">
            Setup Auto Top-up
          </Text>
          <TypeToTextMap data={data} position="center" />
          <Styled.WrappperStep>
            <WrapperSteps
              step={step}
              gapChilden="0px"
              speedHide={0.5}
              speedSlide={0.5}
            >
              <CTFCAutoTopUpFS
                data={dataField}
                setData={setData}
                onClickButton={() => setStep(2)}
              />
              <CTFCAutoTopUpSS
                code={dataField.authCode}
                onChangeCode={(code) => setData("authCode", code)}
                onClickButton={onClickAuth}
                isLoading={infoAuthQuery.isLoading}
                ref={refCode}
              />
            </WrapperSteps>
          </Styled.WrappperStep>
        </Styled.Wrapper>
      )}
    </Modal>
  );
};

export default CTFCAutoTopUp;
