import { FC, useEffect, useId, useState } from "react";
import WrapperWhiteBG from "../../../component/Wrappers/wrapperWhiteBG";
import { CryptoAPI } from "../../../services/crypto";
import { IItemDataInit, IRate, IWallets } from "../../../services/crypto/types";
import { IItemTableAPI } from "../../../services/types/table";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import FSTCryptoTable from "./firstColunm";
import SSTCryptoTable from "./secondColunm";
import * as Styled from "./style";

interface IProps {
  data: IItemDataInit[];
  realoadPage: () => void;
}

const CryptoTable: FC<IProps> = ({ data, realoadPage }) => {
  const idComponent = useId();

  const [queryRates, infoRatesQuery] = useMutationAPI(CryptoAPI.getRates);

  const [rates, setRates] = useState<IRate[]>([]);

  useEffect(() => {
    queryRates({});
    const timerId = setInterval(queryRates, 1000 * 60);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    const response = infoRatesQuery.data;
    if (response) {
      setRates(response.data.rates);
    }
  }, [infoRatesQuery]);

  const getRate = (id: string): IItemTableAPI => {
    const rate = rates.find(({ accountId }) => accountId === id);

    return {
      text: "",
      type: "normal",
      ...rate,
    };
  };

  return (
    <WrapperWhiteBG isBorder>
      {data.map(({ description, balance, buttons, wallets, params }, index) => {
        return (
          <Styled.WrapperItem key={idComponent + ".rowTable." + index}>
            <FSTCryptoTable
              titleData={description}
              wallets={wallets}
              realoadPage={realoadPage}
            />
            <SSTCryptoTable
              amountData={balance}
              buttonData={buttons}
              rate={getRate(params.accountId)}
              accountId={params.accountId}
              realoadPage={realoadPage}
            />
          </Styled.WrapperItem>
        );
      })}
    </WrapperWhiteBG>
  );
};

export default CryptoTable;
