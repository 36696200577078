import {
  DownloadOutlined,
  MailOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContextInvoiceUse } from "../../..";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { InvoiceBuilderAPI } from "../../../../../services/invoiceBuilder";
import { dowloadBase64 } from "../../../../../utils/dowloadBase64";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import IUModalEmail from "./ModalEmail";
import * as Styled from "./style";

const IBSSButtonsGreen = () => {
  const { dataCommon } = useContext(ContextInvoiceUse);
  const { invoiceID } = dataCommon;

  const navigate = useNavigate();

  const [queryDowload, infoDowloadQuery] = useMutationAPI(
    InvoiceBuilderAPI.buttonSS.download
  );
  const [querySave, infoSaveQuery] = useMutationAPI(
    InvoiceBuilderAPI.buttonSS.save
  );

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const response = infoDowloadQuery.data?.data;
    if (response) {
      const { fileContents, fileName } = response;
      dowloadBase64(fileContents, fileName);
    }
  }, [infoDowloadQuery]);

  useEffect(() => {
    const response = infoSaveQuery.data?.data;
    if (response) {
      navigate("/invoices-templates");
    }
  }, [infoSaveQuery]);

  return (
    <WrapperWhiteBG>
      <Styled.Wrapper>
        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={() =>
            queryDowload({
              invoiceID,
            })
          }
          loading={infoDowloadQuery.isLoading}
          disabled={isOpenModal}
          icon={<DownloadOutlined />}
        >
          Download
        </Button>
        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={() => setIsOpenModal(true)}
          disabled={infoDowloadQuery.isLoading}
          icon={<MailOutlined />}
        >
          Share via Email
        </Button>
        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={() =>
            querySave({
              invoiceID,
            })
          }
          loading={infoSaveQuery.isLoading}
          disabled={isOpenModal}
          icon={<SaveOutlined />}
        >
          Save to download later
        </Button>
        {isOpenModal && (
          <IUModalEmail
            onCancel={() => setIsOpenModal(false)}
            invoiceID={invoiceID}
          />
        )}
      </Styled.Wrapper>
    </WrapperWhiteBG>
  );
};

export default IBSSButtonsGreen;
