import { FC, useContext, useId } from "react";
import { ContextRecurringPayment } from "../../../../../..";
import * as Styled from "./style";

interface IProps {
  singleSelected?: boolean;
}

const ScheduleMounthlyPicker: FC<IProps> = ({ singleSelected }) => {
  const idComponent = useId();
  const { dataFS, onChangeData } = useContext(ContextRecurringPayment);
  const { scheduleOptions } = dataFS;

  const onChangeValue = (value: string) => {
    if (singleSelected) {
      onChangeData("scheduleOptions", [value]);
      return;
    }
    const changeValue = scheduleOptions.value.filter((item) => item !== value);
    const isAddMode = !scheduleOptions.value.find((item) => item === value);
    if (isAddMode) {
      changeValue.push(value);
    }

    onChangeData("scheduleOptions", changeValue);
  };

  return (
    <Styled.Wrapper>
      {new Array(30).fill(1).map((_, index) => {
        return (
          <Styled.WrapperDate
            isSelect={scheduleOptions.value.includes(String(index + 1))}
            onClick={() => onChangeValue(String(index + 1))}
            key={idComponent + index}
          >
            {index + 1}
          </Styled.WrapperDate>
        );
      })}
    </Styled.Wrapper>
  );
};

export default ScheduleMounthlyPicker;
