import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { useContext, useMemo } from "react";
import { ContextRecurringPayment } from "../../..";
import { TextBold } from "../../../../../commonStyle";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import RPBFPayToCorrespRow from "./rows/correspRow";
import RPBFSPayToFR from "./rows/firstRow";
import RPBFPayToFR from "./rows/fourthRow";
import RPBFPayToSR from "./rows/secondRow";
import RPBFPayToTR from "./rows/thirdRow";
import * as Styled from "./style";

const RPBFSPayTo = () => {
  const { dataFS, dataCommon, setDataCommon } = useContext(
    ContextRecurringPayment
  );

  const { country } = dataFS;
  const { usId, caId, type, isOpenCorresp } = dataCommon;

  const flagsCountry = useMemo(
    () => ({
      isUS: country.value.id === usId,
      isCA: country.value.id === caId,
    }),
    [country, usId, caId]
  );

  const onClickDetails = () => {
    setDataCommon((prev) => ({ ...prev, isOpenCorresp: !prev.isOpenCorresp }));
  };

  return (
    <WrapperWhiteBG title="Pay To">
      <RPBFSPayToFR />
      {type !== "uk" && (
        <>
          <RPBFPayToSR {...flagsCountry} />
          <RPBFPayToTR />
        </>
      )}
      <RPBFPayToFR {...flagsCountry} />

      {type === "swift" && (
        <>
          <Styled.TitleCorrespDetails onClick={onClickDetails}>
            <Styled.TitleImg>
              {isOpenCorresp ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
            </Styled.TitleImg>
            <TextBold fontSize={18}>Correspondents Details</TextBold>
          </Styled.TitleCorrespDetails>
          {isOpenCorresp && <RPBFPayToCorrespRow />}
        </>
      )}
    </WrapperWhiteBG>
  );
};

export default RPBFSPayTo;
