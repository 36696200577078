import { FC, useState } from "react";
import Table from "../../../../component/table";
import useSizePage from "../../../../utils/hookSizePage";
import { IDataTable } from "../types";
import BTPButtonAuth from "./buttons/Auth";
import BTPButtonDelete from "./buttons/Delete";
import * as Styled from "./style";
import BTTTotal from "./total";

interface IProps extends IDataTable {
  onReload: () => void;
}

const BTPTable: FC<IProps> = ({
  totalAmount,
  accountName,
  onReload,
  ...propsTable
}) => {
  const [selectRow, setSelectRow] = useState<string[]>([]);
  const { width } = useSizePage();

  const onReloadAndClearSelection = () => {
    onReload();
    setSelectRow([]);
  };

  return (
    <div>
      <Styled.Title>{accountName}</Styled.Title>
      <Table
        {...propsTable}
        idSeletRows={selectRow}
        onSelectRow={setSelectRow}
      />
      {width <= 1024 && <BTTTotal totalAmount={totalAmount} />}
      <Styled.WrapperButton>
        <BTPButtonAuth
          transfers={selectRow}
          onReload={onReloadAndClearSelection}
        />
        <BTPButtonDelete
          transfers={selectRow}
          onReload={onReloadAndClearSelection}
        />
        {width > 1024 && <BTTTotal totalAmount={totalAmount} />}
      </Styled.WrapperButton>
    </div>
  );
};

export default BTPTable;
