import instance from "..";
import {
  IPAutoTopCard,
  IPayloadActivateCard,
  IPayloadBlockCard,
  IPayloadFilesDelete,
  IPayloadFilesUpload,
  IPayloadRequestAuth,
  IPayloadRequestExecute,
  IPayloadRequestInit,
  IPayloadRequestValidate,
  IPayloadSendPin,
  IPayloadShowPin,
  IPayloadTUAuth,
  IPayloadTUExecute,
  IPayloadTUInit,
  IPayloadTUValidate,
} from "./types/payload";
import {
  IRAutoTopUp,
  IResponseCardInit,
  IResponseReqAuth,
  IResponseRequestFilesDelete,
  IResponseRequestFilesUpload,
  IResponseRequestInit,
  IResponseReqValidate,
  IResponseShowPin,
  IResponseTUAuthorize,
  IResponseTUInit,
  IResponseTUValidate,
} from "./types/response";

export const CardAPI = {
  init: () => instance.get<IResponseCardInit>("/app/init-cards-list"),
  request: {
    init: ({ idRequest }: IPayloadRequestInit) =>
      instance.get<IResponseRequestInit>("/cards/init/" + idRequest),
    validate: ({ cardRecordId, payload }: IPayloadRequestValidate) =>
      instance.post<IResponseReqValidate>(
        "/cards/validate/request/" + cardRecordId,
        payload
      ),
    authorize: ({ cardRecordId }: IPayloadRequestAuth) =>
      instance.get<IResponseReqAuth>(
        "/cards/authorize/request/" + cardRecordId
      ),
    execute: ({ cardRecordId, auth_code }: IPayloadRequestExecute) =>
      instance.post<IResponseReqAuth>(
        "/cards/execute/request/" + cardRecordId,
        { auth_code }
      ),
    files: {
      upload: ({ cardRecordId, formData, path }: IPayloadFilesUpload) =>
        instance.post<IResponseRequestFilesUpload>(
          `/cards/documents/upload/${cardRecordId}/${path}`,
          formData
        ),
      delete: ({ id }: IPayloadFilesDelete) =>
        instance.get<IResponseRequestFilesDelete>(
          "/cards/documents/delete/" + id
        ),
    },
  },
  table: {
    showPin: (payload: IPayloadShowPin) =>
      instance.post<IResponseShowPin>("/cards/reveal-pin", payload),
    sendPin: (payload: IPayloadSendPin) =>
      instance.post("/cards/send-pin", payload),
    blockCard: ({ auth_code, cardId }: IPayloadBlockCard) =>
      instance.post("/cards/execute/block/" + cardId, { auth_code }),
    unblockCard: ({ auth_code, cardId }: IPayloadBlockCard) =>
      instance.post("/cards/execute/unblock/" + cardId, { auth_code }),
    terminateCard: ({ auth_code, cardId }: IPayloadBlockCard) =>
      instance.post("/cards/execute/terminate/" + cardId, { auth_code }),
    activateCard: ({ cardId }: IPayloadActivateCard) =>
      instance.get("/cards/authorize/request/" + cardId),
    autoTopUp: ({ cardId, payload }: IPAutoTopCard) =>
      instance.post<IRAutoTopUp>(
        "cards/execute/setup-auto-top-up/" + cardId,
        payload
      ),
  },
  topUp: {
    init: ({ cardId }: IPayloadTUInit) =>
      instance.get<IResponseTUInit>("/transfers/init/topup/" + cardId),
    validate: ({ paymentId, payload }: IPayloadTUValidate) =>
      instance.post<IResponseTUValidate>(
        "/transfers/validate/topup/" + paymentId,
        payload
      ),
    authorize: ({ paymentId }: IPayloadTUAuth) =>
      instance.get<IResponseTUAuthorize>("/transfers/authorize/" + paymentId),
    execute: ({ paymentId, checksum, auth_code }: IPayloadTUExecute) =>
      instance.post(`/transfers/execute/${paymentId}/${checksum}`, {
        auth_code,
      }),
  },
};
