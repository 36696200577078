import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Table from "../../component/table";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import PagesAPI from "../../services/pagesAPI";
import { INotifDashboard } from "../../services/pagesAPI/types";
import { IResponseDashboard } from "../../services/pagesAPI/types/response";
import { changeOpenType } from "../../store/user";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import useSizePage from "../../utils/hookSizePage";
import {
  DashboardExpandedByDefaultKey,
  InitialIsExpanded,
  getDataTable,
  getDetailsTable,
  isExpandedByDefault,
  setShouldExpandByDefault,
} from "./data";
import DashboardDrafts from "./drafts";
import DashNotifications from "./notif";
import Balances from "./balances";
import * as Styled from "./style";
import { Button, CollapseProps } from "antd";

const Dashboard = () => {
  const [queryInit, infoInitQuery] = useMutationAPI(PagesAPI.dashboard, {
    changeIsLoading: true,
  });
  const [queryCloseNotif] = useMutationAPI(PagesAPI.closeNotif);
  const dispatch = useDispatch();
  const { width } = useSizePage();

  const [dataTable, setDataTable] = useState<IResponseDashboard["data"]>([]);
  const [dataTableDraft, setDataTableDraft] = useState<
    IResponseDashboard["draft_data"]
  >([]);
  const [dataNotif, setDataNotif] = useState<INotifDashboard[]>([]);
  const [expandedPanels, setExpandedPanels] = useState<string[]>(
    Object.values(DashboardExpandedByDefaultKey).filter((it) =>
      isExpandedByDefault(it, InitialIsExpanded[it])
    )
  );

  const items: CollapseProps["items"] = [
    {
      key: DashboardExpandedByDefaultKey.DASHBOARD_BALANCES_EXPANDED_BY_DEFAULT,
      label: "Balances",
      children: <Balances dashboardResponse={infoInitQuery.data?.data} />,
    },
    {
      key: DashboardExpandedByDefaultKey.DASHBOARD_RECENT_TRANSACTIONS_EXPANDED_BY_DEFAULT,
      label: "Recent Transactions",
      children: (
        <Table
          table={getDataTable(
            dataTable.map(({ item }) => item),
            width
          )}
          details={getDetailsTable(dataTable)}
        />
      ),
    },
    {
      key: DashboardExpandedByDefaultKey.DASHBOARD_DRAFTS_EXPANDED_BY_DEFAULT,
      label: "Saved drafts",
      children: (
        <DashboardDrafts
          data={dataTableDraft}
          reloadDash={() => queryInit({})}
        />
      ),
    },
  ].filter((item) => {
    if (
      item.key ===
        DashboardExpandedByDefaultKey.DASHBOARD_RECENT_TRANSACTIONS_EXPANDED_BY_DEFAULT &&
      dataTable.length === 0
    ) {
      return false;
    } else if (
      item.key ===
        DashboardExpandedByDefaultKey.DASHBOARD_DRAFTS_EXPANDED_BY_DEFAULT &&
      dataTableDraft.length === 0
    ) {
      return false;
    } else return true;
  });

  useEffect(() => {
    dispatch(changeOpenType({ openType: "dashboard" }));
    queryInit({});
  }, []);

  useEffect(() => {
    if (infoInitQuery.data) {
      const { data, notifications, draft_data } = infoInitQuery.data.data;
      setDataTable(data);
      setDataTableDraft(draft_data);
      setDataNotif(notifications);
    }
  }, [infoInitQuery.data]);

  const deleteNotif = async (id: string) => {
    const { data } = await queryCloseNotif({ id });
    if (!data?.data) return;
    setDataNotif((prev) =>
      prev.filter(({ notificationId }) => notificationId !== id)
    );
  };

  const onChange = (keys: string | string[]) => {
    if (!Array.isArray(keys)) {
      keys = [keys];
    }

    setExpandedPanels(keys);
    Object.values(DashboardExpandedByDefaultKey).forEach((key) => {
      setShouldExpandByDefault(key, keys.includes(key));
    });
  };

  return (
    <WrapperBodyTitle {...infoInitQuery.data?.data.page?.header}>
      {!!dataNotif.length && (
        <DashNotifications data={dataNotif} deleteNotif={deleteNotif} />
      )}

      <Styled.CollapseWrapper
        activeKey={expandedPanels}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <Button shape="round" size="middle" style={{ fontSize: "16px" }}>
            {isActive ? "Hide" : "Show"}
          </Button>
        )}
        bordered={false}
        onChange={onChange}
        ghost
        items={items}
      />
    </WrapperBodyTitle>
  );
};

export default Dashboard;
