import styled from "styled-components";

export const WrapperBlock = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 16px;
  & > div {
    width: 50%;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
`;

export const WrapperButtons = styled.div`
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
