import { Route } from "react-router-dom";
import CardComponent from "../../pages/cards";
import CardRequestComponent from "../../pages/cardsRequest";
import CardTUComponent from "../../pages/cardsTopUp";

export const CardsRoutes = () => {
  return (
    <Route path="/cards">
      <Route path="" element={<CardComponent />} />
      <Route path="request" element={<CardRequestComponent />} />
      <Route path="top-up" element={<CardTUComponent />} />
    </Route>
  );
};
