import styled from "styled-components";

interface IWrapper {
  top: number;
  left: number;
}

export const Wrapper = styled.div<IWrapper>`
  position: absolute;
  top: ${({ top }) => 40 + top + "px"};
  left: ${({ left }) => 0 + left + "px"};
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 1px #00000088;
  padding: 10px;
  animation: animationPicker 0.3s;
  z-index: 2;
`;

export const WrapperTable = styled.div`
  display: flex;
  align-items: center;
  gap: 0 20px;
`;

export const WrapperHeader = styled(WrapperTable)`
  border-bottom: 1px solid #bfbfbf;
`;
