import styled from "styled-components";

export const WrapperRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  margin: 15px 0;
  & > div {
    width: calc(100% / 2);
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
`;

export const WrapperUrgent = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const WrapperTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  transform: translateY(-8px);
  & > div {
    width: 100%;
  }
  @media (max-width: 1090px) {
    transform: translateY(4px);
  }
  @media (max-width: 1024px) {
    transform: translateY(4px);
    justify-content: start;
    & > div {
      width: 100%;
    }
  }
`;
