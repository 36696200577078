import { IItemPickerValue } from "../../../component/Global/Picker";
import { IFileTransferDocument } from "../../../component/Global/transferDocument/types";
import validateString from "../../../utils/validate/validateString";
import { IValueError } from "../../contact/form/types";
import { TDataFS } from "../types";

export const requireAllFieldsOrNone = <DataFS extends TDataFS<any>>(
  data: DataFS,
  keys: (keyof DataFS)[]
) => {
  let anyFieldPresent = false;
  for (const key of keys) {
    const value = data[key].value;
    if (typeof value === "string" && value.length !== 0) {
      anyFieldPresent = true;
    }
  }
  if (anyFieldPresent) {
    const emptyStringError = validateString("", { isReq: true });
    for (const key of keys) {
      const value = data[key].value;
      if (typeof value === "string" && value.length === 0) {
        data[key].error = emptyStringError;
      }
    }
  }
};

export const requireValue = (value: IValueError<string>) => {
  value.error = validateString(value.value, {
    isReq: true,
  });
};

export const requireFiles = (value: IValueError<IFileTransferDocument[]>) => {
  if (!value.value.length) {
    value.error = "Document(s) required";
  }
};

export const requireSelector = (value: IValueError<IItemPickerValue>) => {
  value.error = validateString(value.value.id, {
    isReq: true,
  });
};
