import styled from "styled-components";
import { MAIN_THEME } from "../../../../assets";

interface IValue {
  widthIcon: number;
  isButton: boolean;
  isPlaceholder: boolean;
}

const getColor = (isPlaceholder: boolean, isButton: boolean) => {
  if (isPlaceholder) return "#6c7076";
  return isButton ? MAIN_THEME.projectColor : "#041e42";
};

export const Value = styled.div<IValue>`
  display: flex;
  align-items: center;
  width: ${({ widthIcon }) => `calc(100% - ${widthIcon}px - 10px)`};
  padding-right: 10px;
  color: ${({ isPlaceholder, isButton }) => getColor(isPlaceholder, isButton)};
  text-overflow: ellipsis;
  overflow: hidden;
  height: 22px;
  white-space: nowrap;
`;

interface IValueOpen {
  widthIcon: number;
}

export const ValueOpen = styled.input<IValueOpen>`
  width: ${({ widthIcon }) => `calc(100% - ${widthIcon}px - 10px)`};
  height: 22px;
  padding-right: 10px;
  color: #041e42;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  outline: none;
`;
