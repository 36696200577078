import { FC, useContext, useState } from "react";
import { CryptoSendContext } from "../..";
import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { IDataFS } from "../../types";
import CryptoSendConfirm from "./confirm";
import validateString from "../../../../../utils/validate/validateString";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { CryptoAPI } from "../../../../../services/crypto";
import { COMPANY_AND_CORRESPONDENT_BANK_NAME_REGEX } from "../../../../../utils/regex/payments";

interface IProps {
  onChangeValue: <T extends keyof IDataFS, D extends IDataFS[T]["value"]>(
    key: T,
    value: D
  ) => void;
  setError: (key: keyof IDataFS, error: string) => void;
}

const CryptoSendBenef: FC<IProps> = ({ onChangeValue, setError }) => {
  const valueContext = useContext(CryptoSendContext);

  const [queryValidWallet, infoValidWalletQuery] = useMutationAPI(
    CryptoAPI.validateAddress
  );

  const { dataFS, dataCommon } = valueContext;
  const { company, wallet, isConfirm } = dataFS;
  const { currencyCode, confirmMessage } = dataCommon;

  const [isValidWallet, setIsValidWallet] = useState(false);

  const onBlurWallet = async () => {
    if (!wallet.value.length) return;
    const { data } = await queryValidWallet({
      wallet: wallet.value,
      currencyCode,
    });

    const isValid = !!data;

    setIsValidWallet(isValid);

    if (!isValid) {
      setError("wallet", "Wallet Address is incorrect");
    }
  };

  const onBlurCompany = () => {
    const error = validateString(company.value, {
      isReq: true,
      min: 3,
      max: 100,
    });
    setError("company", error);
  };

  return (
    <WrapperWhiteBG title="Beneficiary Details">
      <InputComponent
        {...company}
        onChangeValue={(value) => onChangeValue("company", value)}
        title="Company / Name:"
        maxLength={100}
        regex={COMPANY_AND_CORRESPONDENT_BANK_NAME_REGEX}
        onLostFocusActions={[
          LostFocusActions.REPLACE_WHITESPACES,
          LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
        ]}
        onBlur={onBlurCompany}
      />
      <InputComponent
        {...wallet}
        onChangeValue={(value) => {
          onChangeValue("wallet", value);
          onChangeValue("isConfirm", false);
          setIsValidWallet(false);
        }}
        title="Wallet Address:"
        maxLength={100}
        onBlur={onBlurWallet}
      />
      {isValidWallet && (
        <CryptoSendConfirm
          {...isConfirm}
          onChangeValue={(value) => onChangeValue("isConfirm", value)}
          text={confirmMessage}
          wallet={wallet.value}
        />
      )}
    </WrapperWhiteBG>
  );
};

export default CryptoSendBenef;
