import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const WrapperButton = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export const WrapperInputRow = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    max-width: calc(50% - 15px);
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    & > div {
      max-width: 100%;
    }
  }
`;
