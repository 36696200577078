import { Button } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Table from "../../component/table";
import { ITable, ITableDetails } from "../../component/table/types";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import { ActionReqiredAPI } from "../../services/actionReqired";
import { IDataInit } from "../../services/actionReqired/types";
import { IItemTableAPI } from "../../services/types/table";
import { changeOpenType } from "../../store/user";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import ARButtonAttach from "./buttonAttach";

const getTableRequest = (
  dataProps: IDataInit["item"]["request"],
  counterparty: IItemTableAPI[][],
  amount: IItemTableAPI[][],
  onSuccess: () => void
) => {
  return dataProps.map((lines) => {
    return lines.map((item) => {
      const { type, data, text } = item;
      if (type !== "default") return item;
      return (
        <ARButtonAttach
          text={text}
          riskCaseId={data.riskCaseId}
          counterparty={counterparty}
          amount={amount}
          onSuccess={onSuccess}
        />
      );
    });
  });
};

const getDataTable = (
  dataRow: IDataInit["item"][],
  onSuccessRequest: () => void
): ITable[] => [
  {
    title: "Account",
    width: "25%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.account),
  },
  {
    title: "Beneficiary / Remitter",
    width: "30%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.counterparty),
  },
  {
    title: "Request",
    width: "25%",
    positionText: "left",
    dataColumn: dataRow.map((item) =>
      getTableRequest(
        item.request,
        item.counterparty,
        item.amount,
        onSuccessRequest
      )
    ),
  },
  {
    title: "Amount",
    width: "20%",
    positionText: "right",
    dataColumn: dataRow.map((item) => item.amount),
  },
];

const ActionReqired = () => {
  const [queryInit, infoInitQuery] = useMutationAPI(ActionReqiredAPI.init, {
    changeIsLoading: true,
  });

  const [dataTable, setDataTable] = useState<ITable[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    queryInit({});
    dispatch(changeOpenType({ openType: "action-required" }));
  }, []);

  useEffect(() => {
    const response = infoInitQuery.data;
    if (response) {
      setDataTable(
        getDataTable(
          response.data.data.map(({ item }) => item),
          () => queryInit({})
        )
      );
    }
  }, [infoInitQuery]);

  return (
    <WrapperBodyTitle title="ACTION REQUIRED">
      <Table table={dataTable} />
    </WrapperBodyTitle>
  );
};

export default ActionReqired;
