import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useContext } from "react";
import { ContextRecurringPayment } from "../../../../../..";
import PickerComponent, {
  IItemPicker,
} from "../../../../../../../../component/Global/Picker";
import * as Styled from "./style";

const dataCheckBox: IItemPicker[] = [
  { text: "Monday", id: "Monday" },
  { text: "Tuesday", id: "Tuesday" },
  { text: "Wednesday", id: "Wednesday" },
  { text: "Thursday", id: "Thursday" },
  { text: "Friday", id: "Friday" },
];

const ScheduleWeeklyPicker = () => {
  const { dataFS, onChangeData } = useContext(ContextRecurringPayment);
  const { scheduleOptions } = dataFS;

  return (
    <Styled.Wrapper>
      <PickerComponent
        value={
          dataCheckBox.find(({ id }) => scheduleOptions.value[0] === id) || {
            text: "",
            id: "",
          }
        }
        data={dataCheckBox}
        setValue={(value) => onChangeData("scheduleOptions", [value.id])}
        width="200px"
        placeholder="Select weekday"
      />
    </Styled.Wrapper>
  );
};

export default ScheduleWeeklyPicker;
