import styled from "styled-components";
import { Text } from "../../../../commonStyle";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Text)`
  font-size: 22px;
  margin-bottom: 16px;
`;

export const WrapperButton = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export const WrapperInput = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 60%;
  @media (max-width: 480px) {
    width: 90%;
  }
`;
