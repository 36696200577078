import IBSSButtons from "./buttons";
import IBSSButtonsGreen from "./buttonsGreen";
import IBSSFooter from "./footer";
import IBSSHeader from "./header";
import IBSSMainInfo from "./mainInfo";
import IBSSTable from "./table";
import * as Styled from "./style";

const IBSecondStep = () => {
  return (
    <Styled.Wrapper>
      <IBSSHeader />
      <IBSSMainInfo />
      <IBSSTable />
      <IBSSFooter />
      <IBSSButtonsGreen />
      <IBSSButtons />
    </Styled.Wrapper>
  );
};

export default IBSecondStep;
