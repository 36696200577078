import styled from "styled-components";

interface IWrapper {
  isImg: boolean;
  isError: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  max-width: 100%;
  width: 320px;
  height: 120px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: ${({ isError }) => (isError ? "#eb5757" : "inherit")};
  border: ${({ isImg, isError }) =>
    isImg ? "unset" : `2px dashed ${isError ? "#eb5757" : "#d3d3d3"}`};
  cursor: pointer;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const DeleteImg = styled.div`
  position: absolute;
  left: calc(100% - 16px);
  top: 2px;
`;
