import { FC } from "react";
import PickerComponent, { IPropsPicker } from "../../Global/Picker";
import * as Styled from "./style";

interface IProps {
  pickers: IPropsPicker[];
}

const SelectAt: FC<IProps> = ({ pickers }) => {
  return (
    <Styled.WrapperPickers>
      {pickers.map((propsPicker, index) => {
        return (
          <Styled.WrapperPicker
            key={index + "Picker:" + propsPicker.value}
            width={propsPicker.width}
          >
            {!!index && <Styled.WrapperAt>at</Styled.WrapperAt>}
            <PickerComponent {...propsPicker} />
          </Styled.WrapperPicker>
        );
      })}
    </Styled.WrapperPickers>
  );
};

export default SelectAt;
