import { Route } from "react-router-dom";
import CryptoFiatPayInComponent from "../../pages/cryptoFiatPayIn";
import CryptoFiatPayInNewRequest from "../../pages/cryptoFiatPayInNewRequst";

const CryptoFiatPayInRoutes = () => {
  return (
    <Route path="/crypto-fiat-pay-in-request">
      <Route path="" element={<CryptoFiatPayInComponent />} />
      <Route path="new-request" element={<CryptoFiatPayInNewRequest />} />
    </Route>
  );
};

export default CryptoFiatPayInRoutes;
