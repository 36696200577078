import { Route } from "react-router-dom";
import InvoiceTemplateBuilderComponent from "../../pages/invoiceTemplate";
import InvoiceTemplateListComponent from "../../pages/invoiceTemplateList";
import InvoiceUseComponent from "../../pages/invoiceUse";

const InvoiceTemplateRoutes = () => {
  return (
    <Route path="/invoices-templates">
      <Route path="" element={<InvoiceTemplateListComponent />} />
      <Route path="builder" element={<InvoiceTemplateBuilderComponent />} />
      <Route path="use" element={<InvoiceUseComponent />} />
    </Route>
  );
};

export default InvoiceTemplateRoutes;
