import SwitchComponent from "../../../../../component/Global/Switch";
import PaymentDetailsCommon from "../../../shared/firstStep/payment";
import { InputUk } from "../../types";
import PaymentDetaisDateFirst from "./firstPart";
import PaymentDetaisDateSecond from "./secondPart";

const PaymentDetaisUk = ({ input }: { input: InputUk }) => {
  const { dataFS, onChangeDataFS, setErrorDataFS } = input;

  return (
    <PaymentDetailsCommon>
      <SwitchComponent
        checked={dataFS.DIsInterTransfer.value}
        text="Intercompany payment"
        onChange={(value) => {
          setErrorDataFS("Tfiles", "");
          onChangeDataFS("DIsInterTransfer", value);
        }}
        id="DIsInterTransfer"
        textChange
      />
      <PaymentDetaisDateFirst input={input} />
      <PaymentDetaisDateSecond input={input} />
    </PaymentDetailsCommon>
  );
};

export default PaymentDetaisUk;
