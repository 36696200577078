import { FC } from "react";
import * as Styled from "../../../../shared/firstStep/beneficiary/style";
import { PostalCodeInput } from "../../../../shared/firstStep/components/postalCodeInput";
import { InputSwift } from "../../../types";
import { StreetAddressInput } from "../../../../shared/firstStep/components/streetAddressInput";
import { HouseNumberInput } from "../../../../shared/firstStep/components/houseNumberInput";

interface IProps {
  input: InputSwift;
  isUS: boolean;
  isCanada: boolean;
}

const SecondRowBeneficiarySwift: FC<IProps> = ({ input, isUS, isCanada }) => {
  return (
    <Styled.Row>
      {(isUS || isCanada) && (
        <Styled.Item flex={1}>
          <PostalCodeInput input={input} />
        </Styled.Item>
      )}

      <Styled.Item flex={isUS ? 1 : 2 || isCanada ? 1 : 2}>
        <StreetAddressInput input={input} />
      </Styled.Item>

      <Styled.Item flex={1}>
        <HouseNumberInput input={input} />
      </Styled.Item>
    </Styled.Row>
  );
};

export default SecondRowBeneficiarySwift;
