import InputComponent from "../../../../../component/Global/Input";
import { NUMERIC_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions, PreparedFieldActions } from "../form";

const rulesTransit: IRulesString = { min: 5, max: 5, isReq: true };

type ITransitNumberDataFS = {
  BTransitNumber: IValueError<string>;
};

type IPaymentBankTransitNumberDataFS = {
  paymentBankTransitNumber: IValueError<string>;
};

interface TransitNumberInput {
  dataFS: ITransitNumberDataFS;
  onChangeDataFS: TOnChangeDataFS<ITransitNumberDataFS>;
  formActions: FormActions<ITransitNumberDataFS>;
}

interface PaymentBankTransitNumberInput {
  dataFS: IPaymentBankTransitNumberDataFS;
  onChangeDataFS: TOnChangeDataFS<IPaymentBankTransitNumberDataFS>;
  formActions: FormActions<IPaymentBankTransitNumberDataFS>;
}

interface ITransitNumberInputTemplate {
  data: IValueError<string>;
  onChangeValue: (value: string) => void;
  id: string;
  fieldActions: PreparedFieldActions;
}

const TransitNumberTemplate = ({
  data,
  onChangeValue,
  fieldActions,
  id,
}: ITransitNumberInputTemplate) => {
  return (
    <InputComponent
      {...data}
      onChangeValue={onChangeValue}
      title="Transit Number:"
      maxLength={rulesTransit.max}
      fieldActions={fieldActions}
      regex={NUMERIC_REGEX}
      id={id}
    />
  );
};

export const TransitNumberInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: TransitNumberInput;
}) => {
  return (
    <TransitNumberTemplate
      data={dataFS.BTransitNumber}
      onChangeValue={(value) => onChangeDataFS("BTransitNumber", value)}
      fieldActions={prepareStringRulesField("BTransitNumber", rulesTransit)}
      id="BTransitNumber"
    />
  );
};

export const PaymentBankTransitNumberInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: PaymentBankTransitNumberInput;
}) => {
  return (
    <TransitNumberTemplate
      data={dataFS.paymentBankTransitNumber}
      onChangeValue={(value) =>
        onChangeDataFS("paymentBankTransitNumber", value)
      }
      fieldActions={prepareStringRulesField(
        "paymentBankTransitNumber",
        rulesTransit
      )}
      id="paymentBankTransitNumber"
    />
  );
};
