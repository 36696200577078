import { FC } from "react";
import { IDataInitOperation } from "../../../../../../services/cards/types";
import { IResponseCardInit } from "../../../../../../services/cards/types/response";
import { IItemTableAPI } from "../../../../../../services/types/table";
import CTFCAutoTopUp from "./autoTopUp";
import CTFCBlockCard from "./blockCard";
import CTFCShowPin from "./showPin";
import CTFCTerminateCard from "./terminateCard";
import CTFCUnblockCard from "./unblockCard";

interface IDataAutoTopUp {
  data: IDataInitOperation["data"]["cardAutoTopUpData"];
  id: string;
}

interface IProps {
  type: string;
  id: string;
  data: IItemTableAPI[][];
  onCancel: () => void;
  templates: IResponseCardInit["templates"];
  refetchData: () => void;
  dataAutoTopUp: IDataAutoTopUp[];
}

const CTFCGetModal: FC<IProps> = ({
  type,
  id,
  templates,
  dataAutoTopUp,
  ...propsModal
}) => {
  switch (type) {
    case "show-pin": {
      return (
        <CTFCShowPin
          {...propsModal}
          cardId={id}
          template={templates.show_card_pin_modal}
        />
      );
    }

    case "block-card": {
      return (
        <CTFCBlockCard
          {...propsModal}
          cardId={id}
          template={templates.block_card_modal}
        />
      );
    }

    case "terminate-card": {
      return (
        <CTFCTerminateCard
          {...propsModal}
          cardId={id}
          template={templates.terminate_card_modal}
        />
      );
    }

    case "unblock-card": {
      return (
        <CTFCUnblockCard
          {...propsModal}
          cardId={id}
          template={templates.unblock_card_modal}
        />
      );
    }

    case "setup-auto-top-up": {
      return (
        <CTFCAutoTopUp
          {...propsModal}
          cardId={id}
          propsDataField={dataAutoTopUp.find(({ id: idFind }) => id === idFind)?.data}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default CTFCGetModal;
