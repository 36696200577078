import styled from "styled-components";

export const WrapperInput = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const ErrorCred = styled.div`
  padding: 8px 15px;
  background-color: #fff1f0;
  border-radius: 5px;
  animation: slideDownAlert 0.4s;
`;
