import { FC, useId, useMemo } from "react";
import { IItemTableAPI } from "../../../../services/types/table";
import { TypeToText } from "../../../../utils/typeToText";
import TypeToTextMap from "../../TypeToTextMap";
import * as Styled from "./style";

export interface IDataHintsInput<T = any> {
  title: IItemTableAPI;
  subTitle: IItemTableAPI[][];
  data: T;
}

interface IProps {
  data: IDataHintsInput[];
  onSelect: (title: string, data: any) => void;
  value: string;
}

const HintsInputComponent: FC<IProps> = ({ data, value, onSelect }) => {
  const idComponent = useId();

  const dataFiltered = useMemo(() => {
    return data.filter(({ title }) =>
      title.text.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
  }, [data, value]);

  return (
    <Styled.Wrapper>
      {dataFiltered.map(({ title, subTitle, data }, index) => {
        const keyHint = idComponent + "." + index;
        return (
          <Styled.Hint
            key={keyHint}
            onMouseDown={() => {
              onSelect(title.text, data);
            }}
          >
            <TypeToText {...title} styleData={{ fontSize: 14 }} />
            <TypeToTextMap data={subTitle} styleData={{ fontSize: 13 }} />
          </Styled.Hint>
        );
      })}
    </Styled.Wrapper>
  );
};

export default HintsInputComponent;
