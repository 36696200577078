import { IPayloadSendValidate } from "../../../../../services/crypto/types/payload";
import { IDataFS } from "../../types";

export const getPayload = (accountId:string,dataFS:IDataFS): IPayloadSendValidate["payload"] => {
  return {
    accountId,
    flagWalletConsent: dataFS.isConfirm.value,
    paymentAccountNumberTo: dataFS.wallet.value,
    paymentAmount: parseFloat(dataFS.amount.value),
    paymentDescription: dataFS.description.value,
    paymentName: dataFS.company.value,
  };
};
