import instance from "..";
import {
  IPayloadDelete,
  IPayloadExecute,
  IPayloadValidate,
} from "./types/payload";
import {
  IResponseDelete,
  IResponseInit,
  IResponseInitNewRequest,
  IResponseValidate,
} from "./types/response";

export const CryptoFiatPayInApi = {
  init: () => instance.get<IResponseInit>("/crypto-fiat-pay-in-request/list"),
  delete: (payload: IPayloadDelete) =>
    instance.post<IResponseDelete>(
      "/crypto-fiat-pay-in-request/delete",
      payload
    ),
  initNewRequest: () =>
    instance.get<IResponseInitNewRequest>("/crypto-fiat-pay-in-request/init"),
  validate: ({ requestId, payload }: IPayloadValidate) =>
    instance.post<IResponseValidate>(
      "/crypto-fiat-pay-in-request/validate/" + requestId,
      payload
    ),
  execute: ({ requestId }: IPayloadExecute) =>
    instance.post("/crypto-fiat-pay-in-request/execute/" + requestId),
};
