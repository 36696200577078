import { FC, useContext } from "react";
import { ContextMassPayment } from "..";
import * as Styled from "./style";
import TypeToTextMap from "../../../component/Global/TypeToTextMap";
import WrapperBodyTitle from "../../../component/Wrappers/WrapperBodyTitle";
import DocumentMassPay from "../steps/firstStep/documents";
import { Button } from "antd";
import { IPropsTypeToText } from "../../../utils/typeToText";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import { MassPaymentAPI } from "../../../services/massPaymentAPI";
import { useAppSelector } from "../../../utils/useState";

interface IProps {
  title: string;
}

const MPRequest = () => {
  const { data, typeQuery } = useContext(ContextMassPayment);

  const [queryRequest, infoRequestQuery] = useMutationAPI(
    MassPaymentAPI.request
  );

  const { userId } = useAppSelector((store) => store.userReducer);

  const handleButton = ({ text }: IPropsTypeToText) => {
    return (
      <Button
        shape="round"
        size="large"
        onClick={() =>
          queryRequest({
            url: `/${typeQuery}-request`,
            payload: { userId },
          })
        }
      >
        {text}
      </Button>
    );
  };

  return (
    <WrapperBodyTitle
      title={`IMPORT ${
        typeQuery === "mass-payments" ? "MASS" : "IZZIO"
      } PAYMENTS`}
    >
      <Styled.WrapperInfo>
        <TypeToTextMap
          data={data.dataInfo}
          specificType={[{ type: "default", handle: handleButton }]}
          gapLines={16}
        />
      </Styled.WrapperInfo>
      <DocumentMassPay />
    </WrapperBodyTitle>
  );
};

export default MPRequest;
