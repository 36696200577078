import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeOpenType } from "../../store/user";
import DownloadDocs from "./downloadDocs";

const DownloadsComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeOpenType({ openType: "docs" }));
  }, []);

  return <DownloadDocs />;
};

export default DownloadsComponent;
