import { MoreOutlined } from "@ant-design/icons";
import { FC, useMemo, useState } from "react";
import PickerComponent, {
  IItemPicker,
} from "../../../../../component/Global/Picker";
import { IDataInit } from "../../../../../services/cards/types";
import { IResponseCardInit } from "../../../../../services/cards/types/response";
import { initData, initDataModal } from "./data";
import CTFCGetModal from "./modal";

interface IProps {
  operations: IDataInit["operations_list"];
  data: IDataInit["data"];
  templates: IResponseCardInit["templates"];
  refetchData: () => void;
}

const CTFCPicker: FC<IProps> = ({
  operations = initData,
  data,
  templates,
  refetchData,
}) => {
  const [dataModal, setDataModal] = useState(initDataModal);

  const dataPicker = useMemo(
    () =>
      operations.data.map(({ text, data }) => {
        return {
          text,
          id: data.cardId,
        };
      }),
    [operations]
  );

  const dataAutoTopUp = useMemo(
    () =>
      operations.data.filter(({data})=>!!data.cardAutoTopUpData).map(({ text, data}) => {
        return {
          data:data.cardAutoTopUpData,
          id: data.cardId,
        };
      }),
    [operations]
  );

  const onCancel = () => {
    setDataModal(initDataModal);
  };

  return (
    <>
      <PickerComponent
        value={{ text: "Card actions", id: "init" }}
        data={dataPicker}
        setValue={setDataModal}
        width="250px"
        icon={<MoreOutlined />}
      />
      {dataModal.text !== "" && (
        <CTFCGetModal
          data={data}
          id={dataModal.id}
          type={
            operations.data.find(({ text }) => text === dataModal.text)
              ?.action || ""
          }
          onCancel={onCancel}
          templates={templates}
          refetchData={refetchData}
          dataAutoTopUp={dataAutoTopUp}
        />
      )}
    </>
  );
};

export default CTFCPicker;
