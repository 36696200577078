import { Button, Modal, Skeleton } from "antd";
import { FC, useEffect, useState } from "react";
import { Text, TextBold } from "../../../../../../../commonStyle";
import TypeToTextMap from "../../../../../../../component/Global/TypeToTextMap";
import { CardAPI } from "../../../../../../../services/cards";
import { IItemTableAPI } from "../../../../../../../services/types/table";
import useMutationAPI from "../../../../../../../utils/usePromise/hookMutationAPI";
import useQueryAPI from "../../../../../../../utils/usePromise/hookQueryAPI";
import { useAppSelector } from "../../../../../../../utils/useState";
import * as Styled from "./style";

interface IProps {
  data: IItemTableAPI[][];
  cardId: string;
  onCancel: () => void;
  template: {
    title: string;
    data: IItemTableAPI[][];
  };
  refetchData: () => void;
}

const CTFCShowPin: FC<IProps> = ({
  data,
  cardId,
  template,
  onCancel,
  refetchData,
}) => {
  const [dataPin, setDataPin] = useState({
    card3dsPassword: "",
    cardPin: "",
    cardPhone: "",
  });

  const infoShowQuery = useQueryAPI(CardAPI.table.showPin, {
    payload: { cardId },
  });
  const [querySend, infoSendQuery] = useMutationAPI(CardAPI.table.sendPin);

  useEffect(() => {
    const response = infoShowQuery.data;
    if (response) {
      setDataPin(response.data);
    }
  }, [infoShowQuery]);

  useEffect(() => {
    const response = infoSendQuery.data;

    if (response) {
      onCancel();
    }
  }, [infoSendQuery]);

  return (
    <Modal open={true} onCancel={onCancel} width={478}>
      {infoShowQuery.isLoading ? (
        <Skeleton paragraph={{ rows: 7 }} />
      ) : (
        <Styled.Wrapper>
          <Text fontSize={20} margin="0 0 16px">
            {template.title}
          </Text>
          <TypeToTextMap data={data} position="center" />
          <Styled.WrapperPins>
            <TextBold fontSize={16}>{"PIN: " + dataPin.cardPin}</TextBold>
            <TextBold fontSize={16}>
              {"3DS Password: " + dataPin.card3dsPassword}
            </TextBold>
          </Styled.WrapperPins>
          <TypeToTextMap data={template.data} position="center" />
          <Text fontSize={16}>{dataPin.cardPhone}</Text>
          <Button
            shape="round"
            size="large"
            style={{ marginTop: "15px" }}
            onClick={() => querySend(dataPin)}
          >
            Send via SMS
          </Button>
        </Styled.Wrapper>
      )}
    </Modal>
  );
};

export default CTFCShowPin;
