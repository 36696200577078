import { FC } from "react";
import * as Styled from "./style";
import { IDownloadDocument } from "../../../../services/downloads/types";
import {TypeToText} from "../../../../utils/typeToText";

interface IProps {
  data: IDownloadDocument[];
}

const DocList: FC<IProps> = ({data}) => {
  return (
    <Styled.Wrapper>
      {data.map(({text, url, icon, type}, index) => {
        return <TypeToText key={index} text={text} url={url} icon={icon} type={type} linkProps={{download: true}} />
      })}
    </Styled.Wrapper>
  );
};

export default DocList;
