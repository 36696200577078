import styled, { css } from "styled-components";

interface IWrapper {
  disableHeader: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  & .ant-picker-header {
    display: ${({ disableHeader }) => (disableHeader ? "none" : "flex")};
  }
`;
