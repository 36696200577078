import { useEffect, useState } from "react";
import InputComponent from "../../../../../component/Global/Input";
import { CryptoAPI } from "../../../../../services/crypto";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import validateString from "../../../../../utils/validate/validateString";
import PaymentDetailsCommon from "../../../shared/firstStep/payment";
import { InputCrypto } from "../../types";
import ConfirmRowCrypto from "./ConfirmRow";
import PaymentDetaisCurrencyFirst from "./firstPart";

const PaymentDetaisCrypto = ({ input }: { input: InputCrypto }) => {
  const { dataFS, dataCommon, onChangeDataFS, setErrorDataFS } = input;
  const [queryValidWallet, infoValidWalletQuery] = useMutationAPI(
    CryptoAPI.validateAddress
  );

  const [isValidWallet, setIsValidWallet] = useState(false);
  const { DWallet, DReason } = dataFS;

  useEffect(() => {
    onChangeDataFS("DIsConfirm", false);
  }, []);

  const onBlurReason = () => {
    const error = validateString(DReason.value, {
      isReq: true,
      min: 3,
      max: 50,
    });
    setErrorDataFS("DReason", error);
  };

  const onBlurWallet = async () => {
    const { data } = await queryValidWallet({
      wallet: DWallet.value,
      currencyCode: dataCommon.nameCrypto,
    });

    const isValid = !!data;

    setIsValidWallet(isValid);
    if (!isValid) {
      setErrorDataFS("DWallet", "Wallet Address is incorrect");
    }
  };

  return (
    <PaymentDetailsCommon>
      <PaymentDetaisCurrencyFirst input={input} />

      <InputComponent
        {...DReason}
        onChangeValue={(value) => onChangeDataFS("DReason", value)}
        title="Reason of Purchase:"
        onBlur={onBlurReason}
        id="DReason"
      />
      <InputComponent
        {...DWallet}
        onChangeValue={(value) => {
          setIsValidWallet(false);
          onChangeDataFS("DWallet", value);
        }}
        title="Wallet Address:"
        onBlur={onBlurWallet}
        id="DWallet"
      />
      <ConfirmRowCrypto isValidWallet={isValidWallet} input={input} />
    </PaymentDetailsCommon>
  );
};

export default PaymentDetaisCrypto;
