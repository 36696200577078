import { FC, useContext, useEffect } from "react";
import { CryptoSendContext } from "../..";
import AmountComponent from "../../../../../component/Global/Amount";
import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import useSizePage from "../../../../../utils/hookSizePage";
import validateNumber from "../../../../../utils/validate/validateNumber";
import validateString from "../../../../../utils/validate/validateString";
import { IDataFS } from "../../types";
import { getDecimal, getMin } from "../data";
import { PAYMENT_DESCRIPTION_REGEX } from "../../../../../utils/regex/payments";

interface IProps {
  onChangeValue: <T extends keyof IDataFS, D extends IDataFS[T]["value"]>(
    key: T,
    value: D
  ) => void;
  setError: (key: keyof IDataFS, error: string) => void;
}

const CryptoSendDetails: FC<IProps> = ({ onChangeValue, setError }) => {
  const valueContext = useContext(CryptoSendContext);

  const { dataCommon, dataFS, setDataFS } = valueContext;
  const { amount, description } = dataFS;
  const { currencySymbol, balance, currencyCode } = dataCommon;

  const { width } = useSizePage();

  useEffect(() => {
    const value = parseFloat(amount.value) || 0;

    onChangeValue("amount", value.toFixed(getDecimal(currencyCode)));
  }, [currencyCode]);

  const onBlurAmount = () => {
    const value = parseFloat(amount.value) || 0;

    const error = validateNumber(
      value,
      {
        min: getMin(currencyCode),
        max: balance,
      },
      {
        symbol: currencySymbol,
        decimalScale: getDecimal(currencyCode),
      }
    );

    onChangeValue("amount", value.toFixed(getDecimal(currencyCode)));
    setError("amount", error);
  };

  const onBlurDesc = () => {
    const error = validateString(description.value, {
      isReq: true,
      min: 3,
      max: 50,
    });

    setError("description", error);
  };

  return (
    <WrapperWhiteBG title="Payment Details">
      <AmountComponent
        {...amount}
        title="Amount:"
        onChangeValue={(value) => onChangeValue("amount", value)}
        prefix={currencySymbol + " "}
        onBlur={onBlurAmount}
        decimalsLimit={getDecimal(currencyCode)}
        width={width > 768 ? "33%" : "100%"}
        charLimit={16}
      />

      <InputComponent
        {...description}
        onChangeValue={(value) => onChangeValue("description", value)}
        title="Payment description:"
        maxLength={50}
        regex={PAYMENT_DESCRIPTION_REGEX}
        onLostFocusActions={[
          LostFocusActions.REPLACE_WHITESPACES,
          LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
        ]}
        onBlur={onBlurDesc}
      />
    </WrapperWhiteBG>
  );
};

export default CryptoSendDetails;
