import { IDataHintsInput } from "../../../component/Global/Input/hints";
import { IItemPicker } from "../../../component/Global/Picker";
import { IFileTransferDocument } from "../../../component/Global/transferDocument/types";
import {
  IBeneficiaryEftAPI,
  IFilesPayment,
} from "../../../services/paymentAPI/types";
import { IPayloadValidateEft } from "../../../services/paymentAPI/types/payload";
import { IDropdownAPI } from "../../../services/types";
import { getValueError } from "../data";
import { IDataCommon, IDataFS } from "./types";

export const initDataFS: IDataFS = {
  DIsInterTransfer: getValueError(false),
  DIsUrgent: getValueError(false),
  DIsCompanyName: getValueError(true),
  DAmount: getValueError("0.00"),
  DPaymentDescription: getValueError(""),
  DFee: getValueError({ text: "", id: "sha" }),
  BCompany: getValueError(""),
  paymentAccountNumberTo: getValueError(""),
  paymentBankInstitutionNumber: getValueError(""),
  paymentBankTransitNumber: getValueError(""),
  paymentEmail: getValueError(""),
  paymentFirstName: getValueError(""),
  paymentLastName: getValueError(""),

  Tfiles: getValueError([]),
};

export const initDataCommon: IDataCommon = {
  amountSymbol: "",
  balance: 0,
  dataHeader: [],
  paymentID: "",
  checksum: "",
  typeAuth: "google-authenticator",
  companyHints: [],
  accountId: "",
  isRequireFiles: true,
  isDisableSaveAsDraft: false,
  useCompanyNameInDescriptionControl: true,
  companyName: "",
  dataFee: [],
  isLoadingFiles: false,
  prefixDescription: "Test Company",
  isAbleAuth: false,
  isUsingTemplate: false,
};

const getDataHints = (data: IBeneficiaryEftAPI): Partial<IDataFS> => {
  return {
    paymentAccountNumberTo: getValueError(data.paymentAccountNumberTo || ""),
    paymentBankInstitutionNumber: getValueError(
      data.paymentBankInstitutionNumber || ""
    ),
    paymentBankTransitNumber: getValueError(
      data.paymentBankTransitNumber || ""
    ),
    paymentEmail: getValueError(data.paymentEmail || ""),
    paymentFirstName: getValueError(data.paymentFirstName || ""),
    paymentLastName: getValueError(data.paymentLastName || ""),
  };
};

export const getCompanyHints = (beneficiaries: IBeneficiaryEftAPI[]) => {
  const data = beneficiaries.map(
    (item): IDataHintsInput => ({
      title: { text: item.paymentName, type: "em" },
      subTitle: item.details,
      data: getDataHints(item),
    })
  );
  return data;
};

export const getDataFee = (dataProps?: IDropdownAPI) => {
  if (!dataProps) return [];

  const data = dataProps.dropdown_options.data.map(
    ({ text, value }): IItemPicker => ({
      text,
      id: value,
    })
  );
  return data;
};

export const getInitFee = (dataProps?: IDropdownAPI) => {
  if (!dataProps) return { text: "", id: "sha" };

  const data = getDataFee(dataProps).find(
    (item) => item.id === dataProps.dropdown_options.value
  );
  if (!data) return { text: "", id: "sha" };
  return data;
};

const resToData: {
  [key in keyof IPayloadValidateEft["payload"]]: keyof IDataFS;
} = {
  accountId: "DPaymentDescription",
  paymentAccountNumberTo: "paymentAccountNumberTo",
  paymentAmount: "DAmount",
  paymentDescription: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
  paymentIsSameCompanyTransfer: "DIsInterTransfer",
  paymentName: "BCompany",
  paymentPriorityType: "DIsUrgent",
  paymentEftFeeType: "DFee",
  paymentBankTransitNumber: "paymentBankTransitNumber",
  paymentBankInstitutionNumber: "paymentBankInstitutionNumber",
  paymentEmail: "paymentEmail",
  paymentFirstName: "paymentFirstName",
  paymentLastName: "paymentLastName",
};

type TValue = boolean | string | IItemPicker | IFileTransferDocument[];

export const getDataFromForm = (
  form: Partial<IPayloadValidateEft["payload"]>,
  files: IFilesPayment[],
  dataFee: IItemPicker[],
  paymentDescription: string
) => {
  let responseData: Partial<{
    [key in keyof IDataFS]: TValue;
  }> = {};

  const keysForm = Object.keys(form) as (keyof typeof form)[];

  keysForm.forEach((key) => {
    const value = form[key];
    const keyData = resToData[key];

    if (!keyData) return;

    if (typeof value === "number") {
      responseData[keyData] = value.toFixed(2);
      return;
    }

    if (keyData === "DFee") {
      responseData[keyData] =
        dataFee.find(({ id }) => id === form.paymentEftFeeType) ||
        initDataFS.DFee.value;
      return;
    }

    if (
      keyData === "DPaymentDescription" &&
      typeof value === "string" &&
      form.paymentDescriptionCompanyName
    ) {
      responseData[keyData] = value.slice(paymentDescription.length + 1);
      return;
    }

    responseData[keyData] = value;
  });

  responseData["Tfiles"] = files.map(({ documentId, name, url }) => ({
    id: documentId,
    name,
    url,
    isLoading: false,
  }));

  return Object.entries(responseData) as [keyof IDataFS, TValue][];
};
