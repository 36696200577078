import { FC, useId } from "react";
import { IItemTableAPI } from "../../services/types/table";
import { TypeToText } from "../typeToText";
import * as Styled from "./style";

interface IProps {
  data: IItemTableAPI[];
}

const BodyNotification: FC<IProps> = ({ data }) => {
  const idComponent = useId();

  return (
    <Styled.WrapperBody>
      {data.map((item, index) => (
        <TypeToText {...item} key={idComponent + index} />
      ))}
    </Styled.WrapperBody>
  );
};

export default BodyNotification;
