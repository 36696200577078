import { useContext, useEffect, useMemo } from "react";
import { ContextMassPayment } from "../..";
import PickerComponent, {
  IItemPicker,
} from "../../../../component/Global/Picker";
import MPTransferDocument from "./transferDocument";
import * as Styled from "./style";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import { MassPaymentAPI } from "../../../../services/massPaymentAPI";
import validateString from "../../../../utils/validate/validateString";
import DocumentMassPay from "./documents";
import { Button } from "antd";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { IPayloadValidate } from "../../../../services/massPaymentAPI/types";
import MassPaymentDestination from "./destination";

const FirstStepMassPayment = () => {
  const dataContext = useContext(ContextMassPayment);

  const [validateQuery, infoValidateQuery] = useMutationAPI(
    MassPaymentAPI.validate
  );

  const { data, setData, dataCommon, setStep, step, typeQuery } = dataContext;
  const { account, files, importId, paymentDestination } = data;
  const { dataAccount, accountToDestination } = dataCommon;

  const setAccount = (value: IItemPicker) => {
    setData((prev) => ({
      ...prev,
      account: { error: "", value },
    }));
  };

  const validate = () => {
    let isValid = true;
    if (!account.value.id.length) {
      const error = validateString(data.account.value.id, { isReq: true });
      setData((prev) => ({ ...prev, account: { ...prev.account, error } }));
      isValid = false;
    }
    if (!files.value.length) {
      setData((prev) => ({
        ...prev,
        files: { ...prev.files, error: "Document(s) required" },
      }));
      isValid = false;
    }
    return isValid;
  };

  const onClickContinue = () => {
    if (!validate()) return;
    const payload: IPayloadValidate = {
      accountIdTo: account.value.id,
      importId,
      typeQuery,
    };

    if (typeQuery === "mass-payments")
      payload.massPaymentType = paymentDestination.value.id;

    validateQuery(payload);
  };

  useEffect(() => {
    if (infoValidateQuery.data) {
      const secondStepSummary = infoValidateQuery.data.data.summary.data;
      const secondStepHeader = infoValidateQuery.data.data.account.data;
      const { hasErrors, data: dataTable } = infoValidateQuery.data.data;
      setData((prev) => ({
        ...prev,
        secondStepHeader,
        secondStepSummary,
        dataTable,
        hasErrors,
      }));
      setStep(2);
    }
  }, [infoValidateQuery]);

  const dataAcc = useMemo(
    () =>
      dataAccount.filter(({ id }) => {
        if (typeQuery === "izzio-payouts") return true;
        const accountToDes = accountToDestination.find(
          ({ value }) => value === id
        );
        if (!accountToDes) return false;
        return accountToDes.dataId.includes(paymentDestination.value.id);
      }),
    [paymentDestination]
  );

  return (
    <div>
      {typeQuery === "mass-payments" && <MassPaymentDestination />}
      <WrapperWhiteBG title="From Account">
        {typeQuery === "izzio-payouts" || !!paymentDestination.value.id ? (
          <PickerComponent
            {...account}
            data={dataAcc}
            setValue={setAccount}
            placeholder="Select Account"
            selectOneItem
          />
        ) : (
          <div>Select Destination</div>
        )}
      </WrapperWhiteBG>

      <MPTransferDocument
        isAble={
          !infoValidateQuery.isLoading && step === 1 && !files.value.length
        }
      />
      <Styled.WrapperButton>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={onClickContinue}
          disabled={
            !!files.value.filter(({ isLoading }) => isLoading).length ||
            !account.value.id.length ||
            !paymentDestination.value.id.length
          }
          loading={infoValidateQuery.isLoading}
        >
          Continue
        </Button>
      </Styled.WrapperButton>

      <DocumentMassPay />
    </div>
  );
};

export default FirstStepMassPayment;
