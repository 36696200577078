import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContextInvoiceBuilder } from "../../..";
import InputComponent from "../../../../../component/Global/Input";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { InvoiceTBAPI } from "../../../../../services/invoiceTemplateBuilder";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import validateString from "../../../../../utils/validate/validateString";
import * as Styled from "./style";

type TSave = "later" | "use" | "";

const IBSSButtonsGreen = () => {
  const { dataSS, dataCommon, setDataSS } = useContext(ContextInvoiceBuilder);
  const { invoiceID } = dataCommon;
  const { templateName } = dataSS;
  const navigate = useNavigate();

  const [querySave, infoSaveQuery] = useMutationAPI(InvoiceTBAPI.buttonSS.save);

  const [typeSave, setTypeSave] = useState<TSave>("");

  useEffect(() => {
    const dataSave = infoSaveQuery.data?.data;
    if (dataSave && typeSave === "later") {
      navigate("/invoices-templates");
    }
    if (dataSave && typeSave === "use") {
      navigate("/invoices-templates/use", {
        state: { invoiceId: dataSave.invoiceTemplateId },
      });
    }
  }, [infoSaveQuery]);

  const getDisable = (type: TSave) =>
    (infoSaveQuery.isLoading && typeSave === type) ||
    !!templateName.error.length ||
    !templateName.value.length;

  const onBlur = () => {
    const error = validateString(templateName.value, {
      isReq: true,
      max: 100,
    });
    setDataSS((prev) => ({
      ...prev,
      templateName: { ...prev.templateName, error },
    }));
  };

  return (
    <WrapperWhiteBG>
      <Styled.Wrapper>
        <InputComponent
          {...templateName}
          title="Name your Template:"
          onBlur={onBlur}
          onChangeValue={(value) =>
            setDataSS((prev) => ({
              ...prev,
              templateName: { value, error: "" },
            }))
          }
        />
        <Styled.WrapperButton>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => {
              setTypeSave("later");
              querySave({
                invoiceID,
                payload: { invoiceTemplateName: templateName.value },
              });
            }}
            loading={infoSaveQuery.isLoading && typeSave === "later"}
            disabled={getDisable("use")}
            icon={<SaveOutlined />}
          >
            Save Template
          </Button>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => {
              setTypeSave("use");
              querySave({
                invoiceID,
                payload: { invoiceTemplateName: templateName.value },
              });
            }}
            loading={infoSaveQuery.isLoading && typeSave === "use"}
            disabled={getDisable("later")}
            icon={<DownloadOutlined />}
          >
            Save and use
          </Button>
        </Styled.WrapperButton>
      </Styled.Wrapper>
    </WrapperWhiteBG>
  );
};

export default IBSSButtonsGreen;
