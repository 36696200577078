import {
  AppTheme,
  CassiopayTheme,
  paylogiqTheme,
  smcaTheme,
  smroTheme,
} from "./theme/theme";

const projectName = process.env.REACT_APP_PROJECT_NAME || "paylogiq";

export const STYLE_PATHS = {
  fonts: require(`./${projectName}/css/fonts.css`),
};

export const FAVICON_PATHS = {
  favicon: require(`./${projectName}/favicon/favicon.ico`),
};

export const WRAPPER_BG_PATHS = {
  large: require(`./${projectName}/img/background-lg.jpg`),
  medium: require(`./${projectName}/img/background-md.jpg`),
  small: require(`./${projectName}/img/background-sm.jpg`),
};

export const logoImg = require(`./${projectName}/img/logo.svg`);
export const { BtcIcon } = require(`./${projectName}/img/btc`);

enum Theme {
  CassoiPay,
  Paylogiq,
  Smro,
  Smca,
}

type ThemeData = {
  envName: string;
  appTheme: AppTheme;
};

const findThemeByEnvName = (envName?: string): Theme | undefined => {
  const values = Object.values(Theme).filter(
    (it) => typeof it === "number"
  ) as Theme[];
  for (const theme of values) {
    if (THEME_TO_DATA[theme].envName == envName) {
      return theme;
    }
  }
  return undefined;
};

const THEME_TO_DATA: { [key in Theme]: ThemeData } = {
  [Theme.CassoiPay]: {
    envName: "cassoipaytheme",
    appTheme: CassiopayTheme,
  },
  [Theme.Paylogiq]: {
    envName: "paylogiqtheme",
    appTheme: paylogiqTheme,
  },
  [Theme.Smro]: {
    envName: "smrotheme",
    appTheme: smroTheme,
  },
  [Theme.Smca]: {
    envName: "smcatheme",
    appTheme: smcaTheme,
  },
};

const theme =
  findThemeByEnvName(process.env.REACT_APP_PROJECT_MAIN_THEME) ??
  Theme.CassoiPay; // default error
export const MAIN_THEME: AppTheme = THEME_TO_DATA[theme].appTheme;
