export const isDateEqueality = (date1: Date, date2: Date) => {
  const denominator = 1000 * 60 * 60 * 24;
  const value1 = Math.round(date1.getTime() / denominator);
  const value2 = Math.round(date2.getTime() / denominator);
  return value1 === value2;
};

interface IDataCheckDate {
  date: Date[];
  blockDate?: Date[];
  blockDayWeek?: number[];
  minDate?: Date;
  maxDate?: Date;
}

export const checkDate = ({
  date,
  blockDate = [],
  blockDayWeek = [],
  maxDate = new Date(10000, 0, 0),
  minDate = new Date(0),
}: IDataCheckDate) =>
  date.map((item) => {
    const firstValue =
      (item > minDate || isDateEqueality(item, minDate)) &&
      (item < maxDate || isDateEqueality(item, maxDate));

    const secondValue =
      !blockDayWeek.includes(item.getDay()) &&
      !blockDate.find((item) => isDateEqueality(item, item));

    return !(firstValue && secondValue);
  });
