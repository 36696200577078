import { CalendarOutlined } from "@ant-design/icons";
import { createRef, FC, useEffect, useMemo, useState } from "react";
import { TextBold } from "../../../commonStyle";
import useSizePage from "../../../utils/hookSizePage";
import { ILimitationDate } from "../DatePicker/types";
import OpenDateRange from "./openComponent";
import * as Styled from "./style";

export interface IInfoDateRange {
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
}

interface IProps extends ILimitationDate {
  infoDateRange: IInfoDateRange;
  borderColor?: string;
}

const DateRangePicker: FC<IProps> = ({
  infoDateRange,
  borderColor = "#6C64F4",
  blockDate,
  blockDayWeek,
  maxDate,
  minDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const refSelect = createRef<HTMLDivElement>();
  const [selectOptions, setSelectOptions] = useState({
    top: 0,
    left: 0,
  });

  const { width, height } = useSizePage();

  const { startDate, setStartDate, endDate, setEndDate } = infoDateRange;

  const reSizeOpen = () => {
    if (!refSelect.current) return;
    const { bottom, left } = refSelect.current.getBoundingClientRect();
    const moveLeft = width - 508 - left;
    const moveTop = height - 256 - bottom;
    setSelectOptions({
      top: moveTop < 0 ? moveTop : 0,
      left: moveLeft < 0 ? moveLeft : 0,
    });
  };

  const openPicker = () => {
    if (!refSelect.current) return;
    reSizeOpen();
    setIsOpen((prev) => !prev);
  };

  document.body.addEventListener("click", (e) => {
    if (refSelect.current && !e.composedPath().includes(refSelect.current)) {
      setIsOpen(false);
    }
  });

  const setDate = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
    openPicker();
  };

  const dateElement = useMemo(() => {
    return (
      <>
        <TextBold>{`${startDate.getDate()}/${
          startDate.getMonth() + 1
        }/${startDate.getFullYear()}`}</TextBold>
        <TextBold>~</TextBold>
        <TextBold>{`${endDate.getDate()}/${
          endDate.getMonth() + 1
        }/${endDate.getFullYear()}`}</TextBold>
      </>
    );
  }, [startDate, endDate]);

  useEffect(() => {
    reSizeOpen();
  }, [width, height]);

  return (
    <Styled.Wrapper ref={refSelect}>
      <Styled.WrapperValue
        borderColor={borderColor}
        isOpen={isOpen}
        onClick={openPicker}
      >
        <Styled.WrapperDate>{dateElement}</Styled.WrapperDate>
        <CalendarOutlined />
      </Styled.WrapperValue>
      {isOpen && (
        <OpenDateRange
          startDate={startDate}
          endDate={endDate}
          setDate={setDate}
          limitationDate={{ blockDate, blockDayWeek, maxDate, minDate }}
          {...selectOptions}
        />
      )}
    </Styled.Wrapper>
  );
};

export default DateRangePicker;
