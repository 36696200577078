import { useContext } from "react";
import { ContextCardRequest } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import PickerComponent from "../../../../../../../component/Global/Picker";
import useSizePage from "../../../../../../../utils/hookSizePage";
import validateString from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

const CRRASecondRow = () => {
  const valueContext = useContext(ContextCardRequest);

  const { width } = useSizePage();

  const { dataFS, onChangeDataFS, setErrorDataFS } = valueContext;

  const { RAPostalCode, RAStreet, RAHouseNumber, RAAdditional } = dataFS;

  const onBlur = (
    key: "RAPostalCode" | "RAStreet" | "RAHouseNumber" | "RAAdditional"
  ) => {
    const error = validateString(dataFS[key].value, {
      isReq: true,
      min: 3,
      max: key === "RAPostalCode" || key === "RAHouseNumber" ? 10 : 50,
    });

    setErrorDataFS(key, error);
  };

  return (
    <Styled.Wrapper>
      <InputComponent
        {...RAPostalCode}
        title="Postal Code:"
        onChangeValue={(value) => onChangeDataFS("RAPostalCode", value)}
        width={width > 1024 ? "12.5%" : "100%"}
        onBlur={() => onBlur("RAPostalCode")}
        maxLength={10}
        id="RAPostalCode"
      />
      <InputComponent
        {...RAStreet}
        title="Street address:"
        onChangeValue={(value) => onChangeDataFS("RAStreet", value)}
        width={width > 1024 ? "50%" : "100%"}
        onBlur={() => onBlur("RAStreet")}
        maxLength={50}
        id="RAStreet"
      />
      <InputComponent
        {...RAHouseNumber}
        title="House number:"
        onChangeValue={(value) => onChangeDataFS("RAHouseNumber", value)}
        width={width > 1024 ? "12.5%" : "100%"}
        onBlur={() => onBlur("RAHouseNumber")}
        maxLength={10}
        id="RAHouseNumber"
      />
      <InputComponent
        {...RAAdditional}
        title="Additional:"
        onChangeValue={(value) => onChangeDataFS("RAAdditional", value)}
        width={width > 1024 ? "25%" : "100%"}
        onBlur={() => onBlur("RAAdditional")}
        id="RAAdditional"
      />
    </Styled.Wrapper>
  );
};

export default CRRASecondRow;
