import { Button } from "antd";
import { FC } from "react";
import Table from "../../../../component/table";
import { ITable } from "../../../../component/table/types";
import {
  IDataInitCredit,
  IItemDataInitCredit,
} from "../../../../services/creditDoc/types";
import useSizePage from "../../../../utils/hookSizePage";
import * as Styled from "./style";

interface IProps {
  tableResp: IDataInitCredit[];
  selectIdTable: string[];
  setSelectIdTable: (value: string[]) => void;
  setIsOpenModal: (value: boolean) => void;
}

const getDataTable = (
  dataRow: IItemDataInitCredit[],
  width: number
): ITable[] => [
  {
    title: "Date",
    width: "10%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.date),
  },
  {
    title: "Account",
    width: "30%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.account),
  },
  {
    title: "Remitter",
    width: "50%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.counterparty),
  },
  {
    title: "Amount",
    width: "10%",
    positionText: width > 1024 ? "right" : "left",
    dataColumn: dataRow.map((item) => item.amount),
  },
];

const CDTable: FC<IProps> = ({
  tableResp,
  selectIdTable,
  setSelectIdTable,
  setIsOpenModal,
}) => {
  const { width } = useSizePage();

  return (
    <Styled.Wrapper>
      <Table
        table={getDataTable(
          tableResp.map(({ item }) => item),
          width
        )}
        details={tableResp.map(({ details }) => details)}
        idRows={tableResp.map(({ requestId }) => requestId)}
        idSeletRows={selectIdTable}
        onSelectRow={setSelectIdTable}
      />
      {!!tableResp.length && (
        <Styled.WrapperButton>
          <Button
            shape="round"
            size="large"
            disabled={!selectIdTable.length}
            onClick={() => setIsOpenModal(true)}
            danger
          >
            Delete selected
          </Button>
        </Styled.WrapperButton>
      )}
    </Styled.Wrapper>
  );
};

export default CDTable;
