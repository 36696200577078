import { Button } from "antd";
import React, { FC, ReactNode, useId } from "react";
import { isElement } from "react-dom/test-utils";
import { ITextCommon, TextBold } from "../../../../commonStyle";
import { IItemTableAPI } from "../../../../services/types/table";
import { TypeToText } from "../../../../utils/typeToText";
import { IDataRowTable } from "../../types";
import TIDataToComponent from "../utils";
import * as Styled from "./style";

interface IProps {
  data: IDataRowTable;
}

const getTextAling: { [key: string]: ITextCommon["textAling"] } = {
  left: "start",
  center: "center",
  right: "end",
};

const BodyItemTable: FC<IProps> = ({ data }) => {
  const { positionText, width, lines, title } = data;

  return (
    <Styled.WrapperBody width={width}>
      <TextBold fontSize={16} id="titleOfTableItem">
        {title + ":"}
      </TextBold>
      <Styled.WrapperItem type={positionText}>
        <TIDataToComponent data={lines} positionText={positionText} />
      </Styled.WrapperItem>
    </Styled.WrapperBody>
  );
};

export default BodyItemTable;
