import { FC } from "react";
import { Text } from "../../../../../../../commonStyle";
import * as Styled from "./style";

interface IProps {
  isLoading?: boolean;
  isAble: boolean;
  text: string;
}

const PaymentRateComponent: FC<IProps> = ({
  isLoading = false,
  isAble,
  text,
}) => {
  return (
    <Styled.Wrapper>
      {isLoading && <div> loading...</div>}
      {isAble && !isLoading ? (
        <Text fontSize={16}>&#8780;{" " + text}</Text>
      ) : (
        <div />
      )}
    </Styled.Wrapper>
  );
};

export default PaymentRateComponent;
