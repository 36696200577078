import { IDataInfoFile, IFileToFormData, IFileTransferDocument, TOnUploadFile } from "./types";

export const fileToBinaryFormData = (file: File): Promise<IFileToFormData> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target) return;

      const blob = new Blob([e.target.result as string], {
        type: file.type,
      });
      const formData = new FormData();
      formData.append("file", blob, file.name);

      resolve({ formData });
    };
    reader.readAsArrayBuffer(file);
  });
};

export const filterResponseFiles = (
  dataProps: (string | IFileTransferDocument)[]
) => {
  const error = dataProps.filter((item) => typeof item === "string").join(",");
  const value = dataProps.filter(
    (item) => typeof item !== "string"
  ) as IFileTransferDocument[];

  return { value, error };
};



export const onChangeInputFile = async (
  dataFiles: File[],
  dataInfo: IDataInfoFile,
  onUpload: TOnUploadFile
) => {
  const { value, setValue, setError } = dataInfo;
  const dataBeforeQuery = dataFiles.map(
    ({ name }): IFileTransferDocument => ({ name, isLoading: true, id: "" })
  );

  setValue([...value, ...dataBeforeQuery]);

  const dataFileToBinary = await Promise.all(
    dataFiles.map((file) => fileToBinaryFormData(file))
  );

  const dataResponse = await onUpload(dataFileToBinary);

  const { error, value: dataValue } = filterResponseFiles(dataResponse);

  const prevValue = value.filter(({ isLoading }) => !isLoading);

  setValue([...prevValue, ...dataValue]);
  setError(error);
};
