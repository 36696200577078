import { IDataHintsInput } from "../../component/Global/Input/hints";
import { IItemPicker } from "../../component/Global/Picker";
import { IFileTransferDocument } from "../../component/Global/transferDocument/types";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import { IPRecurringValidate } from "../../services/recurringPayment/types/payload";
import { IResInitBuildRP } from "../../services/recurringPayment/types/response";
import { getValueError } from "../payments/data";
import {
  IArrayItemResToData,
  IContext,
  IDataCommon,
  IDataFS,
  IDataSS,
} from "./types";

const dataSchedule: IItemPicker[] = [
  { text: "Daily", id: "daily" },
  { text: "Weekly", id: "weekly" },
  { text: "Monthly", id: "monthly" },
  { text: "Quarterly", id: "quarterly" },
];

export const initDataFS: IDataFS = {
  account: getValueError({ text: "", id: "" }),
  schedulePicker: getValueError({ text: "", id: "" }),
  scheduleOptions: getValueError([]),
  amount: getValueError("0.00"),
  description: getValueError(""),
  templateName: getValueError(""),

  country: getValueError({ text: "", id: "" }),
  state: getValueError({ text: "", id: "" }),
  city: getValueError(""),
  company: getValueError(""),
  house: getValueError(""),
  postalCode: getValueError(""),
  street: getValueError(""),
  aba: getValueError(""),
  bic: getValueError(""),
  iban: getValueError(""),
  institutionNumber: getValueError(""),
  transitNumber: getValueError(""),
  sortCode: getValueError(""),

  correspAccount: getValueError(""),
  correspBankName: getValueError(""),
  correspBIC: getValueError(""),

  files: getValueError([]),
};

export const initDataSS: IDataSS = {
  beneficiary: {
    title: "",
    data: [],
  },
  remitter: {
    title: "",
    data: [],
  },
};

export const initDataCommon: IDataCommon = {
  dataAccount: [],
  dataCountry: [],
  dataCompany: [],
  dataState: [],
  dataSchedule,
  usId: "",
  caId: "",
  recurringPaymentId: "",
  dataSymbol: [],
  type: "",
  title: "",
  isOpenCorresp: false,
};

const initSet = () => {};

export const initContext: IContext = {
  step: 1,
  setStep: initSet,
  dataFS: initDataFS,
  dataSS: initDataSS,
  setDataSS: initSet,
  setDataFS: initSet,
  dataCommon: initDataCommon,
  setDataCommon: initSet,
  onChangeData: initSet,
  onErrorData: initSet,
};

export const getDataAccount = (
  dataProps: IResInitBuildRP["remitters_list"]["dropdown_options"]["data"]
): IItemPicker[] =>
  dataProps.map(({ data, value }) => ({
    text: data.map(({ text }) => text).join(),
    id: value,
    component: <TypeToTextMap data={[data]} />,
  }));

export const getDataCounrty = (
  dataProps: IResInitBuildRP["dictionaries"]["countries"]
): IItemPicker[] =>
  dataProps.map(({ countryId, countryName }) => ({
    text: countryName,
    id: countryId,
  }));

export const getDataState = (
  dataProps: IResInitBuildRP["dictionaries"]["us_states"]
): IItemPicker[] =>
  dataProps.map(({ stateCode, stateName }) => ({
    text: stateName,
    id: stateCode,
  }));

const getValueById = (valueId: string, data: IItemPicker[]) =>
  data.find(({ id }) => id === valueId) || {
    text: "",
    id: "",
  };

export const getDataCompany = (
  dataProps: IResInitBuildRP["beneficiaries"],
  dataCountry: IItemPicker[],
  dataState: IItemPicker[]
): IDataHintsInput<Partial<IDataFS>>[] =>
  dataProps.map((item) => {
    const valueCountry = getValueById(
      item.beneficiaryCountryId || "",
      dataCountry
    );
    const valueState = getValueById(item.beneficiaryState || "", dataState);

    return {
      title: { text: item.beneficiaryName, type: "em" },
      subTitle: item.details,
      data: {
        city: getValueError(item.beneficiaryCity || ""),
        country: getValueError(valueCountry),
        house: getValueError(item.beneficiaryHouseNumber || ""),
        postalCode: getValueError(item.beneficiaryPostalCode || ""),
        street: getValueError(item.beneficiaryStreetAddress || ""),
        state: getValueError(valueState),
        aba: getValueError(item.beneficiaryBankAba || ""),
        bic: getValueError(item.beneficiaryBankBic || ""),
        institutionNumber: getValueError(
          item.beneficiaryBankInstitutionNumber || ""
        ),
        transitNumber: getValueError(item.beneficiaryBankTransitNumber || ""),
        sortCode: getValueError(item.beneficiaryBankSortCode || ""),
        iban: getValueError(item.beneficiaryAccountNumber || ""),
        correspAccount: getValueError(
          item.beneficiaryCorrespondentAccount || ""
        ),
        correspBankName: getValueError(
          item.beneficiaryCorrespondentBankName || ""
        ),
        correspBIC: getValueError(item.beneficiaryCorrespondentBankBic || ""),
      },
    };
  });

const getKeyDataFromResp: {
  [key in keyof IPRecurringValidate["payload"]]: keyof IDataFS;
} = {
  accountId: "account",
  beneficiaryCity: "city",
  beneficiaryCountryId: "country",
  beneficiaryHouseNumber: "house",
  beneficiaryName: "company",
  beneficiaryPostalCode: "postalCode",
  beneficiaryState: "state",
  beneficiaryStreetAddress: "street",
  recurringPaymentAmount: "amount",
  recurringPaymentDescription: "description",
  recurringPaymentName: "templateName",
  recurringPaymentScheduleData: "scheduleOptions",
  recurringPaymentScheduleType: "schedulePicker",
  beneficiaryAccountNumber: "iban",
  beneficiaryBankAba: "aba",
  beneficiaryBankBic: "bic",
  beneficiaryBankInstitutionNumber: "institutionNumber",
  beneficiaryBankSortCode: "sortCode",
  beneficiaryBankTransitNumber: "transitNumber",
  beneficiaryCorrespondentAccount: "correspAccount",
  beneficiaryCorrespondentBankBic: "correspBIC",
  beneficiaryCorrespondentBankName: "correspBankName",
};

export const getDataForm = (
  formProps: IResInitBuildRP["form"],
  dataFiles: IResInitBuildRP["documents"],
  dataAccount: IItemPicker[],
  dataCountry: IItemPicker[],
  dataState: IItemPicker[]
) => {
  if (!formProps) return [];
  const array = Object.entries(formProps) as [
    keyof IPRecurringValidate["payload"],
    string | string[]
  ][];

  const response = array.map(([keyProps, valueProps]): IArrayItemResToData => {
    const key = getKeyDataFromResp[keyProps];
    let value: IFileTransferDocument[] | string | string[] | IItemPicker =
      valueProps;

    if (key === "account") {
      value = dataAccount.find(({ id }) => valueProps === id) || {
        id: "",
        text: "",
      };
    }
    if (key === "country") {
      value = dataCountry.find(({ id }) => valueProps === id) || {
        id: "",
        text: "",
      };
    }
    if (key === "state") {
      value = dataState.find(({ id }) => valueProps === id) || {
        id: "",
        text: "",
      };
    }
    if (key === "schedulePicker") {
      value = dataSchedule.find(({ id }) => valueProps === id) || {
        id: "",
        text: "",
      };
    }

    return { key, value };
  });

  if (dataFiles) {
    response.push({
      key: "files",
      value: dataFiles.map(
        ({ documentId: id, name, url }): IFileTransferDocument => ({
          url,
          name,
          isLoading: false,
          id,
        })
      ),
    });
  }

  return response;
};

export const getDataSymbol = (
  dataProps: IResInitBuildRP["remitters_list"]["dropdown_options"]["data"]
): IItemPicker[] =>
  dataProps.map(({ value, currencySymbol }) => ({
    id: value,
    text: currencySymbol,
  }));
