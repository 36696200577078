import styled from "styled-components";

interface IWrapper {
  isInline: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  display: ${({ isInline }) => (isInline ? "inline" : "flex")};
  align-items: center;
  gap: 0 10px;

  & > * {
    display: inline;
  }

  & > *:last-child {
    margin-left: ${({ isInline }) => (isInline ? "16px" : "unset")};
  }
`;
