import PickerComponent, {
  IItemPicker,
  IItemPickerValue,
} from "../../../../../../component/Global/Picker";
import WrapperWhiteBG from "../../../../../../component/Wrappers/wrapperWhiteBG";
import { IValueError } from "../../../../../contact/form/types";
import { TDataFS, TOnChangeDataFS } from "../../../../types";
import { FormActions, PrepareOptions } from "../../form";
import * as Styled from "../style";

type IDataFS = {
  BCompany: IValueError<IItemPickerValue>;
};

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

type IDataCommon = RequireAtLeastOne<
  {
    companyHints: IItemPicker[];
    dataCompany: IItemPicker[];
  },
  "companyHints" | "dataCompany"
>;

interface Input {
  dataFS: IDataFS;
  dataCommon: IDataCommon;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

const PaymentBeneficiaryCompanyPickerCommon = <
  DepsDataFS extends TDataFS<any>
>({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    formActions: { preparePickerRequired },
  },
  options,
}: {
  input: Input;
  options?: PrepareOptions<DepsDataFS>;
}) => {
  return (
    <WrapperWhiteBG title="Beneficiary Details">
      <Styled.Wrapper>
        <Styled.Row>
          <PickerComponent
            {...dataFS.BCompany}
            setValue={(value) => {
              onChangeDataFS("BCompany", { text: value.text, id: value.id });
            }}
            title="Company / Name:"
            data={dataCommon?.companyHints ?? dataCommon?.dataCompany ?? []}
            placeholder="Select Beneficiary"
            id="BCompany"
            fieldActions={preparePickerRequired(
              "BCompany",
              options as PrepareOptions<IDataFS>
            )}
          />
        </Styled.Row>
      </Styled.Wrapper>
    </WrapperWhiteBG>
  );
};

export default PaymentBeneficiaryCompanyPickerCommon;
