import styled from "styled-components";

export const WrapperLine = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const WrapperText = styled.div`
  margin-top: 5px;
`;
