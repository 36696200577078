import { useContext } from "react";
import { ContextInvoiceUse } from "../../..";
import Table from "../../../../../component/table";
import { getDataTable } from "./data";

const IBSSTable = () => {
  const { table } = useContext(ContextInvoiceUse).dataSS;

  return (
    <div>
      <Table table={getDataTable(table)} />
    </div>
  );
};

export default IBSSTable;
