import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import ModalDeleteComponent from "../../../component/commonModal";
import { InvoiceTLAPI } from "../../../services/invoiceTemplateList";
import { RecurringPaymentAPI } from "../../../services/recurringPayment";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import * as Styled from "./style";

interface IProps {
  invoices: string[];
  reloadTable: () => void;
}

const RPButtonsTable: FC<IProps> = ({ invoices, reloadTable }) => {
  const [queryDelete, infoDeleteQuery] = useMutationAPI(
    RecurringPaymentAPI.delete
  );

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (infoDeleteQuery.data?.data) {
      reloadTable();
      setIsOpen(false);
    }
  }, [infoDeleteQuery]);

  return (
    <Styled.Wrapper>
      <Button
        shape="round"
        size="large"
        disabled={!invoices.length}
        loading={infoDeleteQuery.isLoading}
        onClick={() => setIsOpen(true)}
        danger
      >
        Delete selected
      </Button>
      {isOpen && (
        <ModalDeleteComponent
          onClickYes={() => queryDelete({ invoices })}
          onClickNo={() => setIsOpen(false)}
          onClose={() => setIsOpen(false)}
          loadingYes={infoDeleteQuery.isLoading}
          title="Delete Templates?"
        />
      )}
    </Styled.Wrapper>
  );
};

export default RPButtonsTable;
