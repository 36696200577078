import PaymentAPI from "../../../services/paymentAPI";
import { IResponseInitCrypto } from "../../../services/paymentAPI/types/response";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import { initDataSS, initDataTS } from "../data";
import PaymentSystem, { TLocationState } from "../shared";
import { requireFiles } from "../shared/validations";
import { getDataFromForm, initDataCommon, initDataFS } from "./data";
import FirstStepCrypto from "./firstStep";
import { IDataCommon, IDataFS } from "./types";

interface ILocationState extends TLocationState {
  nameCrypto: string;
  type: string;
}

const CryptoComponent = () => {
  return (
    <PaymentSystem<
      IDataFS,
      IDataCommon,
      IResponseInitCrypto,
      any,
      ILocationState
    >
      initDataFS={initDataFS}
      initDataSS={initDataSS}
      initDataTS={initDataTS}
      initDataCommon={initDataCommon}
      updateInput={(input): ILocationState => ({
        ...input,
        nameCrypto: "nameCrypto" in input ? (input.nameCrypto as string) : "",
        type: "type" in input ? (input.type as string) : "fiat-to-crypto",
      })}
      infoInitQueryProvider={(input) => {
        return useQueryAPI(PaymentAPI.crypto.getInit, {
          payload: {
            id: input.accountId || input.paymentId || "",
            mode: input.mode,
            type: input.type,
          },
          changeIsLoading: true,
        });
      }}
      firstStepComponet={(context, setStep) => {
        return <FirstStepCrypto input={context} setStep={setStep} />;
      }}
      onInitQueryLoaded={(
        response,
        input,
        onChangeDataFS,
        updateDataCommon
      ) => {
        if (!response.data) {
          return;
        }
        const {
          header,
          account,
          transfer,
          form = {},
          files = [],
          user,
        } = response.data.data;

        const dataForm = getDataFromForm(form, files);

        dataForm.map(([key, value]) => onChangeDataFS(key, value));

        updateDataCommon((prev) => ({
          ...prev,
          dataHeader: header,
          amountSymbol: account.currencySymbol,
          currencyCode: account.currencyCode,
          balance: account.balance.available,
          paymentID: transfer.paymentId,
          typeAuth: "google-authenticator",
          accountId: account.accountId,
          isRequireFiles: transfer.options.requireFilesUpload,
          isDisableSaveAsDraft: transfer.options.disableSaveAsDraft,
          nameCrypto: input.nameCrypto,
          companyName: account.accountName,
          confirmMessage: transfer.confirmMessage,
          isAbleAuth: user?.userType !== "simple-user",
        }));
      }}
      // this is not tested - crypto might not validate properly
      validateAll={(data, dataCommon) => {
        if (dataCommon.isRequireFiles) {
          requireFiles(data.Tfiles);
        }

        if (!data.DIsConfirm.value) {
          data.DIsConfirm.error = "Confirm required";
        }
      }}
    />
  );
};

export default CryptoComponent;
