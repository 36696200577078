import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import { CITY_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

const rulesCity: IRulesString = { isReq: true, min: 3, max: 50 };

type IDataFS = {
  BCity: IValueError<string>;
};

interface Input {
  dataFS: IDataFS;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const CityInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: Input;
}) => {
  return (
    <InputComponent
      {...dataFS.BCity}
      onChangeValue={(value) => onChangeDataFS("BCity", value)}
      title="City:"
      maxLength={rulesCity.max}
      fieldActions={prepareStringRulesField("BCity", rulesCity)}
      regex={CITY_REGEX}
      onLostFocusActions={[
        LostFocusActions.REPLACE_WHITESPACES,
        LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
      ]}
      id="BCity"
    />
  );
};
