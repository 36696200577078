import { InputRef } from "antd";
import { createContext, createRef, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import WrapperPayment from "../../component/Wrappers/wrapperPayment";
import { MassPaymentAPI } from "../../services/massPaymentAPI";
import { TTypeQueryMassIssio } from "../../services/massPaymentAPI/types";
import { changeOpenType, setIsQuestionLeave } from "../../store/user";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import {
  getAccountToDestination,
  getDataAccount,
  initContext,
  initData,
  initDataCommon,
} from "./data";
import MPRequest from "./request";
import FirstStepMassPayment from "./steps/firstStep";
import SecondStepMassPayment from "./steps/secondStep";
import ThirdStepPayment from "./steps/thirdStep";

interface IProps {
  typeQuery: TTypeQueryMassIssio;
}

export const ContextMassPayment = createContext(initContext);

const MassPayment: FC<IProps> = ({ typeQuery }) => {
  const [queryInit, infoInitQuery] = useMutationAPI(MassPaymentAPI.init, {
    changeIsLoading: true,
  });

  const refInputTS = createRef<InputRef>();

  const [isInit, setIsInit] = useState(true);
  const [data, setData] = useState(initData);
  const [dataCommon, setDataCommon] = useState(initDataCommon);
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsInit(true);
    setStep(1);
    dispatch(changeOpenType({ openType: typeQuery }));
    queryInit({ typeQuery });
  }, [typeQuery]);

  useEffect(() => {
    return () => {
      dispatch(setIsQuestionLeave(false));
    };
  }, []);

  useEffect(() => {
    if (!isInit) {
      dispatch(setIsQuestionLeave(true));
    }
  }, [data]);

  useEffect(() => {
    const response = infoInitQuery.data?.data;
    if (response) {
      const {
        remitters_list,
        transfer,
        documents,
        data,
        mass_payment_types_list,
      } = response;

      const dataAccount = getDataAccount(remitters_list);
      const dataDestination = getDataAccount(mass_payment_types_list);
      const accountToDestination = getAccountToDestination(remitters_list);

      setData({
        ...initData,
        importId: transfer?.importId || "",
        documents,
        dataInfo: data || [],
      });
      setDataCommon({ dataAccount, dataDestination, accountToDestination });
      setTimeout(() => setIsInit(false), 1000);
    }
  }, [infoInitQuery]);

  const setFocus = (refInputTS: InputRef) => {
    setTimeout(() => {
      if (refInputTS) refInputTS.focus();
    }, 1000);
  };

  useEffect(() => {
    if (step === 3 && refInputTS.current) {
      setFocus(refInputTS.current);
    }
  }, [step]);

  return (
    <ContextMassPayment.Provider
      value={{
        data,
        setData,
        dataCommon,
        setDataCommon,
        step,
        setStep,
        typeQuery,
      }}
    >
      {!!data.dataInfo.length ? (
        <MPRequest />
      ) : (
        <WrapperPayment {...infoInitQuery.data?.data.page?.header} step={step}>
          <FirstStepMassPayment />
          <SecondStepMassPayment />
          <ThirdStepPayment ref={refInputTS} />
        </WrapperPayment>
      )}
    </ContextMassPayment.Provider>
  );
};

export default MassPayment;
