import { TDataFS } from "../../pages/payments/types";
import { IPropsSTE } from "./types";

export const scrollToError = (errors: IPropsSTE[]) => {
  setTimeout(() => {
    if (!errors.length) return;
    const elementError = document.getElementById(errors[0].key);
    const elementWrapper = document.getElementById("main_wrapper");

    if (elementWrapper && elementError) {
      const { top } = elementError.getBoundingClientRect();
      elementWrapper.scrollTop += top - 40;
    }
  }, 100);
};

export const scrollToFirstError = (data: TDataFS<any>) => {
  const errors = findErrors(data);
  scrollToError(errors);
};

const findErrors = <T extends TDataFS<any>>(
  data: T
): {
  key: Extract<keyof T, string>;
  error: string;
}[] => {
  const keys = Object.keys(data) as Extract<keyof T, string>[];
  return keys
    .map((key) => {
      return {
        key: key,
        error: data[key].error,
      };
    })
    .filter((item) => item.error.length !== 0);
};
