import { CopyOutlined } from "@ant-design/icons";
import { message, Typography } from "antd";
import React, { FC } from "react";

interface IProps {
  text: string;
  messageText: string;
  style?: React.CSSProperties;
}

const TextCopyable: FC<IProps> = ({ text, messageText, style }) => {
  return (
    <Typography.Paragraph
      style={style}
      copyable={{
        icon: [
          <CopyOutlined style={{ color: "#5bdfa7" }} key="copy-icon" />,
          <CopyOutlined style={{ color: "#5bdfa7" }} key="copied-icon" />,
        ],
        tooltips: false,
        onCopy: () => {
          message.success(messageText + " copied");
        },
      }}
    >
      {text}
    </Typography.Paragraph>
  );
};

export default TextCopyable;
