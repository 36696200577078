import { IValueError } from "../../component/payment/types";
import { ISecondStepDataAPI } from "../../services/paymentAPI/types";
import { IDataTS } from "./types";

export function getValueError<T>(value: T): IValueError<T> {
  return { value, error: "" };
}

export const initDataSS: ISecondStepDataAPI[] = [];
export const initDataTS: IDataTS = {
  amount: "",
  name: "",
};
