import { Button, Result } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TextBold } from "../../commonStyle";
import { changeOpenType } from "../../store/user";
import * as Styled from "./style";

const PageNotFound = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onBackHome = () => {
    dispatch(changeOpenType({ openType: "dashboard" }));
    navigate("/login");
  };

  return (
    <Styled.Wrapper>
      <Result
        status={404}
        title={<TextBold fontSize={18}>404</TextBold>}
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Styled.WrapperButton>
            <Button shape="round" size="large" onClick={onBackHome}>
              Back Home
            </Button>
          </Styled.WrapperButton>
        }
      />
    </Styled.Wrapper>
  );
};

export default PageNotFound;
