import { Button } from "antd";
import { FC, useContext, useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CryptoSendContext } from "../..";
import CommonModalComponent from "../../../../../component/commonModal";
import { TGetLeaveButton } from "../../../../../component/Wrappers/wrapperHeaderNav/navBar/modalLeave";
import { CryptoAPI } from "../../../../../services/crypto";
import {
  IPayloadSendDraft,
  IPayloadSendValidate,
} from "../../../../../services/crypto/types/payload";
import { useInterval } from "../../../../../utils/useInterval";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { getPayload } from "./data";
import * as Styled from "./style";

interface IProps {
  queryContinue: (value: IPayloadSendValidate) => Promise<any>;
  isLoadingContinue: boolean;
  queryDraft: (value: IPayloadSendDraft) => Promise<any>;
  isLoadingDraft: boolean;
  validateData: () => Promise<boolean>;
}

const CSendButtons: FC<IProps> = ({
  queryContinue,
  isLoadingContinue,
  queryDraft,
  isLoadingDraft,
  validateData,
}) => {
  const idComponent = useId();
  const { dataFS, dataCommon, onErrorData } = useContext(CryptoSendContext);
  const { paymentId, accountId } = dataCommon;

  const [queryDiscard,infoDiscardQuery] = useMutationAPI(CryptoAPI.discard);
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);


  const onClickContinue = async () => {
    if (!dataFS.wallet.value.length) {
      onErrorData("wallet", "Required");
      return;
    }
    if (await validateData()) {
      queryContinue({
        paymentId,
        payload: getPayload(accountId, dataFS),
      });
    }
  };

  const onClickSaveForLater = (autoSave?: boolean) => {
    return queryDraft({
      paymentId,
      payload: {
        ...getPayload(accountId, dataFS),
        autoSave,
      },
    });
  };

  useInterval(() => {
    onClickSaveForLater(true);
  }, 14 * 60 * 1000);

  useEffect(() => {
    let getLeaveButtons: TGetLeaveButton = (onClickStay, onClickLeave) => {
      return [
        <Button
          key={idComponent + "leave"}
          shape="round"
          size="large"
          style={{ padding: "6.4px 24px" }}
          loading={isLoadingDraft}
          onClick={() => {
            onClickSaveForLater(false).then(() => {
              onClickLeave();
            });
          }}
        >
          Save as draft
        </Button>,
        <Button
          key={idComponent + "stay"}
          shape="round"
          size="large"
          style={{ padding: "6.4px 24px" }}
          loading={infoDiscardQuery.isLoading}
          onClick={() => {
            queryDiscard(paymentId).then(() => {
              onClickLeave();
            });
          }}
          danger
        >
          Discard
        </Button>,
      ];
    };

    (window as any).getLeaveButtons = getLeaveButtons;
  }, [accountId, dataFS]);

  return (
    <Styled.Wrapper>
      <Button
        shape="round"
        size="middle"
        onClick={() => {
          onClickSaveForLater(false).then(() => navigate("/crypto"));
        }}
        disabled={
          !!dataFS.files.value.filter(({ isLoading }) => isLoading).length
        }
      >
        Save as draft
      </Button>
      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onClickContinue}
        loading={isLoadingContinue}
        disabled={
          !!dataFS.files.value.filter(({ isLoading }) => isLoading).length
        }
      >
        Continue
      </Button>
      {isOpenModal && (
        <CommonModalComponent
          onClickYes={() => {
            onClickSaveForLater(false).then(() => navigate("/crypto"));
          }}
          onClickNo={() => setIsOpenModal(false)}
          onClose={() => setIsOpenModal(false)}
          loadingYes={isLoadingDraft}
          buttons={["Save as draft", "Cancel"]}
          title="Save as draft?"
          isDanger={[false, true]}
          message={
            <div>
              <div>
                Save as draft to continue filling out the form once ready.
              </div>
              <div>Cancel to go back to filling out details.</div>
            </div>
          }
          smallPadding
        />
      )}
    </Styled.Wrapper>
  );
};

export default CSendButtons;
