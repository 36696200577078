import InputComponent from "../../../../../component/Global/Input";
import { NUMERIC_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

const rulesSortCode: IRulesString = { isReq: true, max: 6 };

type IDataFS = {
  BSortCode: IValueError<string>;
};

interface Input {
  dataFS: IDataFS;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const SortCodeInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
  isReq,
}: {
  input: Input;
  isReq?: boolean;
}) => {
  return (
    <InputComponent
      {...dataFS.BSortCode}
      onChangeValue={(value) => onChangeDataFS("BSortCode", value)}
      title="Sort code:"
      fieldActions={prepareStringRulesField("BSortCode", {
        ...rulesSortCode,
        isReq: isReq ?? rulesSortCode.isReq,
      })}
      maxLength={rulesSortCode.max}
      regex={NUMERIC_REGEX}
      id="BSortCode"
    />
  );
};
