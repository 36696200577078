import { IItemPicker } from "../../component/Global/Picker";
import { ITable } from "../../component/table/types";
import { IPSettlementGetData } from "../../services/settlement/types/payload";
import { IPTransactionGetData } from "../../services/transactions/types/payload";
import { IDropdownAPI } from "../../services/types";
import { getDateWithTimezone } from "../../utils/getDateWithTimezone";
import { IContext, IData } from "./types";

const initialDate = new Date();
const initialDateEnd = getDateWithTimezone({
  year: initialDate.getFullYear(),
  month: initialDate.getMonth(),
  date: initialDate.getDate(),
});
const initialDateStart = getDateWithTimezone({
  year: initialDate.getFullYear(),
  month: initialDate.getMonth() - 1,
  date: initialDate.getDate(),
});

const initTotal = {
  data: [],
  item: [],
};

export const initData: IData = {
  dataTable: [],
  startDate: initialDateStart,
  endDate: initialDateEnd,
  site: { text: "", id: "" },
  siteData: [],
  interval: { text: "", id: "" },
  intervalData: [],
  siteType: { text: "", id: "" },
  siteTypeData: [],
  infoHeader: {
    report_company: [],
    report_dates: [],
    report_heading: [],
    report_site: [],
  },
  downloadData: [],
  view_page: {
    page_number: 1,
    page_size: 10,
    total_items: 10,
    total_pages: 1,
  },
  total: {
    totalDeductions: initTotal,
    totalNetSettlement: initTotal,
    totalVolume: initTotal,
  },
};

export const initContext: IContext = {
  data: initData,
  onChangeData: () => {},
};

export const getFilterData = (data: IDropdownAPI) =>
  data.dropdown_options.data.map(
    ({ text, value }): IItemPicker => ({ text, id: value })
  );

export const getTable = (
  dataRow: IData["dataTable"],
  size: number
): ITable[] => [
  // {
  //   title: "Settle Date",
  //   width: "150px",
  //   positionText: "left",
  //   dataColumn: dataRow.map(({ item }) => item.settledDate),
  // },
  // {
  //   title: "Processing Period",
  //   width: "15%",
  //   positionText: "left",
  //   dataColumn: dataRow.map(({ item }) => item.processingPeriod),
  // },
  // {
  //   title: "Total Volume",
  //   width: "15%",
  //   positionText: "left",
  //   dataColumn: dataRow.map(({ item }) => item.totalVolume),
  // },
  // {
  //   title: "Total Deductions",
  //   width: "15%",
  //   positionText: "left",
  //   dataColumn: dataRow.map(({ item }) => item.totalDeductions),
  // },
  // {
  //   title: "Total Net Settlement",
  //   width: "20%",
  //   positionText: "left",
  //   dataColumn: dataRow.map(({ item }) => item.totalNetSettlement),
  // },
  // {
  //   title: "Payment Type",
  //   width: "calc(100% - 75% - 150px)",
  //   positionText: "left",
  //   dataColumn: dataRow.map(({ item }) => item.paymentType),
  // },
  // {
  //   title: "Status",
  //   width: "10%",
  //   positionText: size > 1024 ? "right" : "left",
  //   dataColumn: dataRow.map(({ item }) => item.status),
  // },


  {
    title: "Date",
    width: "150px",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.settledDate),
  },
  {
    title: "Description",
    width: "calc(100% - 30% - 150px)",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.description),
  },
  {
    title: "Amount",
    width: "10%",
    positionText: size <= 1024 ? "left" : "right",
    dataColumn: dataRow.map(({ item }) => item.amount),
  },
];

export const getPayload = (
  data: IData,
  isInit: boolean
): IPSettlementGetData => {
  const { site, siteType, startDate, endDate, interval } = data;
  const response = {
    site: site.id,
    interval: interval.id,
    settlementStatus: siteType.id,
    pageNumber: data.view_page.page_number,
    pageSize: data.view_page.page_size,
  };
  if (isInit || !!interval.id.length) return response;
  return {
    ...response,
    paymentDateFrom_submit: startDate.toISOString().split("T")[0],
    paymentDateTo_submit: endDate.toISOString().split("T")[0],
  };
};
