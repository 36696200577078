import { FC, useId } from "react";
import SwitchComponent from "../../../../../component/Global/Switch";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import WrapperTitleError from "../../../../../component/Wrappers/wrapperTitleError";
import { IItemTableAPI } from "../../../../../services/types/table";
import { TypeToText } from "../../../../../utils/typeToText";
import * as Styled from "./style";

interface IProps {
  value: boolean;
  error: string;
  onChangeValue: (value: boolean) => void;
  dataConfirm: IItemTableAPI[][];
  disabled: boolean;
}

const CryptoConfirmRow: FC<IProps> = ({
  value,
  error,
  onChangeValue,
  dataConfirm,
  disabled,
}) => {
  const onClickSwitch = () => {
    onChangeValue(!value);
  };

  return (
    <WrapperTitleError error={error}>
      <SwitchComponent
        checked={value}
        onChange={onClickSwitch}
        text="Confirm Crypto Sell*"
        disabled={disabled}
        textChange
      />
      <TypeToTextMap
        data={dataConfirm}
        styleData={{
          fontSize: 14,
        }}
      />
    </WrapperTitleError>
  );
};

export default CryptoConfirmRow;
