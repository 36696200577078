import { FC, ReactNode } from "react";
import TypeToTextMap from "../../../component/Global/TypeToTextMap";
import { IItemTableAPI } from "../../../services/types/table";
import * as Styled from "./style";

export interface IPropsItemStatments {
  firstColumnData: IItemTableAPI[][];
  score: IItemTableAPI[][];
  buttons: ReactNode;
}

const ItemStatments: FC<IPropsItemStatments> = ({
  firstColumnData,
  score,
  buttons,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.FirstColumn>
        <TypeToTextMap data={firstColumnData} />
      </Styled.FirstColumn>

      <Styled.SecondColumn>
        <Styled.WrapperAmount>
          <TypeToTextMap data={score} />
        </Styled.WrapperAmount>

        <Styled.ColumnRow justifyContent="end">{buttons}</Styled.ColumnRow>
      </Styled.SecondColumn>
    </Styled.Wrapper>
  );
};

export default ItemStatments;
