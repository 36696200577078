import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import WrapperPayment from "../../component/Wrappers/wrapperPayment";
import { BulkTransferAPI } from "../../services/bulkTransfer";
import { setIsQuestionLeave } from "../../store/user";
import { getChangeError } from "../../utils/getChangeError";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import { getDataAccount, initContext, initData, initDataCommon } from "./data";
import FirstStepImportBP from "./steps/firstStep";
import SecondStepBulkImport from "./steps/secondStep";

export const ContextImportBulkTransfer = createContext(initContext);

const BulkImportComponent = () => {
  const infoInitQuery = useQueryAPI(BulkTransferAPI.initImport, {
    changeIsLoading: true,
  });
  const dispatch = useDispatch();

  const [isInit, setIsInit] = useState(true);
  const [step, setStep] = useState(1);
  const [dataFS, setDataFS] = useState(initData);
  const [dataCommon, setDataCommon] = useState(initDataCommon);
  const { onChangeData: onChangeDataFS, onErrorData: setErrorFS } =
    getChangeError(setDataFS);

  useEffect(() => {
    return () => {
      dispatch(setIsQuestionLeave(false));
    };
  }, []);

  useEffect(() => {
    if (!isInit) {
      dispatch(setIsQuestionLeave(true));
    }
  }, [dataFS]);

  useEffect(() => {
    const response = infoInitQuery.data;
    if (response) {
      const importId = response.data.transfer.importId;
      const dataAccount = getDataAccount(
        response.data.beneficiaries_list.dropdown_options.data
      );
      setDataCommon((prev) => ({ ...prev, importId, dataAccount }));
      setTimeout(() => setIsInit(false), 1000);
    }
  }, [infoInitQuery]);

  return (
    <ContextImportBulkTransfer.Provider
      value={{
        step,
        setStep,
        dataFS,
        setDataFS,
        dataCommon,
        setDataCommon,
        onChangeDataFS,
        setErrorFS,
      }}
    >
      <WrapperPayment
        title="IMPORT BULK TRANSFERS"
        step={step}
        viewStep={["Fill out", "Verify"]}
      >
        <FirstStepImportBP />
        <SecondStepBulkImport />
      </WrapperPayment>
    </ContextImportBulkTransfer.Provider>
  );
};

export default BulkImportComponent;
