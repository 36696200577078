import { Checkbox } from "antd";
import { createRef, FC, useEffect, useMemo, useState } from "react";
import { TextBold } from "../../../commonStyle";
import { IPagination, TPositionTextTable } from "../types";
import * as Styled from "./style";

interface IDataHeader {
  title: string;
  width: string;
  positionText: TPositionTextTable;
}

interface IProps {
  data: IDataHeader[];
  idRows?: string[];
  idSelectRows: string[];
  onSelectRow?: (idRows: string[]) => void;
  isDelete: boolean;
  pagination: IPagination;
}

const HeaderTable: FC<IProps> = ({
  data,
  idRows,
  idSelectRows,
  onSelectRow,
  isDelete,
  pagination,
}) => {
  const sliceIdRow = useMemo(() => {
    if (!idRows) return [];
    const { current, pageSize } = pagination;
    const start = (current - 1) * pageSize;
    const end = current * pageSize;
    return idRows.slice(start, end);
  }, [pagination, idRows]);

  const isSelectAll = useMemo(() => {
    if (!idRows) return false;
    return !sliceIdRow.filter((item) => !idSelectRows.includes(item)).length;
  }, [idSelectRows, sliceIdRow]);

  const onClickCheckbox = () => {
    if (!onSelectRow || !idRows) return;
    onSelectRow(isSelectAll ? [] : [...sliceIdRow]);
  };

  return (
    <Styled.Wrapper isSelect={!!idRows} onClick={idRows && onClickCheckbox}>
      <Styled.WrapperCheckbox>
        {idRows && <Checkbox onClick={onClickCheckbox} checked={isSelectAll} />}
      </Styled.WrapperCheckbox>

      <Styled.WrapperTextSelect>
        <TextBold fontSize={18}>Select All</TextBold>
      </Styled.WrapperTextSelect>

      {data.map(({ title, width, positionText }, index) => (
        <Styled.WrapperText
          key={"Table.Header." + title + "." + index}
          type={positionText}
          width={width}
        >
          <TextBold fontSize={18}>{title}</TextBold>
        </Styled.WrapperText>
      ))}
      {isDelete && <div style={{ width: "24px" }} />}
    </Styled.Wrapper>
  );
};

export default HeaderTable;
