import styled from "styled-components";

export const WrappperTotal = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 1024px) {
    margin-top: 20px;
  }
`;
