import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WrapperPayment from "../../component/Wrappers/wrapperPayment";
import { CardAPI } from "../../services/cards";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import {
  getAccountData,
  initContext,
  initDataCommon,
  initDataFS,
  initDataSS,
  initDataTS,
} from "./data";
import CTUFirstStep from "./steps/first";
import CTUSecondStep from "./steps/second";
import CTUThirdStep from "./steps/third";
import { TOnChangeDataFS, TSetErrorDataFS } from "./types";
import { useQuestionBeforeLeave } from "../../utils/questionBeforeLeave";
import { getValuesDataFS } from "../../utils/getValuesDataFS";

interface ILocation {
  state?: {
    cardId: string;
  };
}

export const CardTopUpContext = createContext(initContext);

const CardTUComponent = () => {
  const { state } = useLocation() as ILocation;
  const cardId = state?.cardId || "";

  const infoInitQuery = useQueryAPI(CardAPI.topUp.init, {
    payload: { cardId },
    changeIsLoading: true,
  });
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [dataFS, setDataFS] = useState(initDataFS);
  const [dataSS, setDataSS] = useState(initDataSS);
  const [dataTS, setDataTS] = useState(initDataTS);
  const [dataCommon, setDataCommon] = useState(initDataCommon);

  const onFinishLoading = useQuestionBeforeLeave({ deps: [dataFS] });

  const onChangeDataFS: TOnChangeDataFS = (key, value) => {
    setDataFS((prev) => ({ ...prev, [key]: { error: "", value } }));
  };

  const setErrorDataFS: TSetErrorDataFS = (key, error) => {
    setDataFS((prev) => ({ ...prev, [key]: { ...prev[key], error } }));
  };

  useEffect(() => {
    if (!cardId.length) {
      navigate("/cards");
    }
  }, []);

  useEffect(() => {
    const response = infoInitQuery.data;
    if (response) {
      const { remitters_list, account, transfer, header } = response.data;
      const { currencySymbol, accountId } = account;
      const { paymentDescription, paymentId } = transfer;

      const accountData = getAccountData(remitters_list.dropdown_options.data);
      const accountItem = accountData.find(
        ({ id }) => id === remitters_list.dropdown_options.value
      );

      if (accountItem) onChangeDataFS("account", accountItem);
      onChangeDataFS("description", paymentDescription);
      setDataCommon((prev) => ({
        ...prev,
        accountData,
        currencySymbol,
        subTitle: header,
        paymentId,
        accountId,
      }));

      setTimeout(() => onFinishLoading(false), 1000);
    }
  }, [infoInitQuery]);

  return (
    <CardTopUpContext.Provider
      value={{
        step,
        setStep,
        dataFS,
        setDataFS,
        dataSS,
        setDataSS,
        dataTS,
        setDataTS,
        dataCommon,
        setDataCommon,
        onChangeDataFS,
        setErrorDataFS,
      }}
    >
      <WrapperPayment title="CREATE TOP-UP payment" step={step}>
        <CTUFirstStep />
        <CTUSecondStep />
        <CTUThirdStep />
      </WrapperPayment>
    </CardTopUpContext.Provider>
  );
};

export default CardTUComponent;
