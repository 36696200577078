import { IItemPicker } from "../../component/Global/Picker";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import { IResponseTUInit } from "../../services/cards/types/response";
import { getValueError } from "../payments/data";
import { IContext, IDataCommon, IDataFS, IDataSS, IDataTS } from "./types";

export const initDataFS: IDataFS = {
  account: getValueError({ text: "", id: "" }),
  amount: getValueError("0.00"),
  description: getValueError(""),
};

export const initDataSS: IDataSS = [];

export const initDataTS: IDataTS = {
  name: "",
  amount: "",
};

export const initDataCommon: IDataCommon = {
  accountData: [],
  subTitle: [],
  currencySymbol: "",
  paymentId: "",
  accountId: "",
  checksum: "",
};

const initSet = () => console.log("INIT CARD TOP UP CONTEXT");

export const initContext: IContext = {
  step: 1,
  setStep: initSet,
  dataFS: initDataFS,
  setDataFS: initSet,
  dataSS: initDataSS,
  setDataSS: initSet,
  dataTS: initDataTS,
  setDataTS: initSet,
  dataCommon: initDataCommon,
  setDataCommon: initSet,
  onChangeDataFS: initSet,
  setErrorDataFS: initSet,
};

export const getAccountData = (
  data: IResponseTUInit["remitters_list"]["dropdown_options"]["data"]
): IItemPicker[] => {
  return data.map(({ data, value }) => ({
    text: data.map(({ text }) => text).join(" "),
    id: value,
    component: <TypeToTextMap data={[data]} />,
  }));
};
