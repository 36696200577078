import { IItemPicker } from "../../component/Global/Picker";
import { IDataFilter } from "./types";

export const initDataFilter: IDataFilter = {
  dataBank: [],
  dataCompany: [],
};

export const initCompany: IItemPicker = {
  text: "All Companies",
  id: "all-companies",
};

export const initBank: IItemPicker = {
  text: "All Banks",
  id: "all-banks",
};
