import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import ModalDeleteComponent from "../../../../../../component/commonModal";
import { BulkTransferAPI } from "../../../../../../services/bulkTransfer";
import useMutationAPI from "../../../../../../utils/usePromise/hookMutationAPI";
import useSizePage from "../../../../../../utils/hookSizePage";

interface IProps {
  transfers: string[];
  onReload: () => void;
}

const BTPButtonDelete: FC<IProps> = ({ transfers, onReload }) => {
  const [queryDelete, infoDeleteQuery] = useMutationAPI(
    BulkTransferAPI.pending.delete
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const { width } = useSizePage();

  useEffect(() => {
    const response = infoDeleteQuery.data;
    if (response && response.data.result) {
      setIsOpenModal(false);
      onReload();
    }
  }, [infoDeleteQuery]);

  return (
    <>
      <Button
        shape="round"
        size="large"
        disabled={!transfers.length}
        onClick={() => setIsOpenModal(true)}
        danger
      >
        {width > 1024 ? "Delete selected" : "Delete"}
      </Button>
      {isOpenModal && (
        <ModalDeleteComponent
          onClose={() => setIsOpenModal(false)}
          onClickNo={() => setIsOpenModal(false)}
          onClickYes={() => queryDelete({ transfers })}
          title="Delete transfers?"
          loadingYes={infoDeleteQuery.isLoading}
        />
      )}
    </>
  );
};

export default BTPButtonDelete;
