import { Button } from "antd";
import { useContext } from "react";
import { formatValue } from "react-currency-input-field";
import { ContextInvoiceBuilder } from "../../..";
import { Text } from "../../../../../commonStyle";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { initDataCommon } from "../../../data";
import { getSubtotal, getTax } from "./data";
import IBFLine from "./line";
import * as Styled from "./style";

const IBFSFooter = () => {
  const { dataFS, dataCommon, setDataCommon } = useContext(
    ContextInvoiceBuilder
  );
  const { GTypeTax } = dataFS;
  const { dataTable, currency } = dataCommon;
  const isTax = GTypeTax.value.id !== "none";

  const getValue = (value: number) => {
    return ` ${currency} ${formatValue({
      value: String(value),
      decimalScale: 2,
    })}`;
  };

  const setNewItem = () => {
    setDataCommon((prev) => {
      const dataTable: typeof prev.dataTable = [
        ...prev.dataTable,
        { ...initDataCommon.dataTable[0] },
      ];
      return { ...prev, dataTable };
    });
  };

  return (
    <Styled.Wrapper>
      <div>
        <Button shape="round" size="large" onClick={setNewItem}>
          Add New Invoice Item
        </Button>
      </div>

      <div>
        <WrapperWhiteBG title="Invoice Summary">
          <table>
            <tbody>
              <IBFLine
                prefix="Total:"
                value={getValue(getSubtotal(dataTable) + getTax(dataTable))}
                isTotal
              />
              {isTax && (
                <IBFLine
                  prefix={`Inc. ${GTypeTax.value.text}:`}
                  value={getValue(getTax(dataTable))}
                />
              )}
            </tbody>
          </table>
        </WrapperWhiteBG>
      </div>
    </Styled.Wrapper>
  );
};

export default IBFSFooter;
