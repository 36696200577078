import { useContext, useMemo, useState } from "react";
import { ContextSettlement } from "../..";
import TypeToTextMap from "../../../../component/Global/TypeToTextMap";
import useSizePage from "../../../../utils/hookSizePage";
import * as Styled from "./styled";

const SettlementInfoHeader = () => {
  const { report_company, report_dates, report_heading, report_site } =
    useContext(ContextSettlement).data.infoHeader;

  const { width } = useSizePage();

  const dataFirst: any = useMemo(() => {
    if (width > 1024) return [report_heading, report_dates];
    return [report_heading, report_company];
  }, [width, report_heading, report_company, report_dates]);

  const dataSecond: any = useMemo(() => {
    if (width > 1024) return [report_company, report_site];
    return [report_site, report_dates];
  }, [width, report_company, report_site]);

  const position = useMemo(() => (width > 1024 ? "end" : "start"), [width]);

  return (
    <Styled.Wrapper>
      <Styled.FirstColumn>
        <TypeToTextMap data={dataFirst} />
      </Styled.FirstColumn>

      <Styled.SecondColumn>
        <TypeToTextMap data={dataSecond} position={position} />
      </Styled.SecondColumn>
    </Styled.Wrapper>
  );
};

export default SettlementInfoHeader;
