import { ReactNode, useEffect } from "react";
import InputComponent, {
  LostFocusActions,
} from "../../../../../../component/Global/Input";
import { IDataHintsInput } from "../../../../../../component/Global/Input/hints";
import WrapperWhiteBG from "../../../../../../component/Wrappers/wrapperWhiteBG";
import { COMPANY_AND_CORRESPONDENT_BANK_NAME_REGEX } from "../../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../../utils/validate/validateString";
import { IValueError } from "../../../../../contact/form/types";
import { TOnChangeDataFS, TOnUpdateDataFS } from "../../../../types";
import * as Styled from "../style";
import { FormActions } from "../../form";

type IDataFS = {
  BCompany: IValueError<string>;
};

interface IDataCommon {
  companyHints: IDataHintsInput[];
}

interface Input<DataFS extends IDataFS> {
  dataFS: DataFS;
  dataCommon: IDataCommon;
  onChangeDataFS: TOnChangeDataFS<DataFS>;
  updateDataFS: TOnUpdateDataFS<DataFS>;
  formActions: FormActions<DataFS>;
}

const PaymentBeneficiaryCommon = <DataFS extends IDataFS>({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    updateDataFS,
    formActions: { prepareStringRulesField },
  },
  companyValidationRules,
  emptyFields,
  additionalUpdateOnSelectingHintCompany,
  children,
  title = "Beneficiary Details",
  companyNamePlaceholder = "Select Beneficiary or enter a new one",
}: {
  input: Input<DataFS>;
  companyValidationRules: IRulesString;
  emptyFields: Partial<DataFS>;
  additionalUpdateOnSelectingHintCompany?: (data: Partial<DataFS>) => void;
  children: ReactNode;
  title?: string;
  companyNamePlaceholder?: string;
}) => {
  const { BCompany } = dataFS;

  const onSelectHintCompany = (data: Partial<DataFS>) => {
    additionalUpdateOnSelectingHintCompany?.(data);
    updateDataFS(data);
  };

  useEffect(() => {
    if (!BCompany.value.length) {
      updateDataFS(emptyFields);
    }
  }, [BCompany.value]);

  return (
    <WrapperWhiteBG title={title}>
      <Styled.Wrapper>
        <Styled.Row>
          <InputComponent
            {...BCompany}
            onChangeValue={(value) => onChangeDataFS("BCompany", value)}
            title="Company / Name:"
            hints={{
              data: dataCommon.companyHints,
              onSelect: onSelectHintCompany,
            }}
            placeholder={companyNamePlaceholder}
            maxLength={companyValidationRules.max}
            regex={COMPANY_AND_CORRESPONDENT_BANK_NAME_REGEX}
            onLostFocusActions={[
              LostFocusActions.REPLACE_WHITESPACES,
              LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
            ]}
            id="BCompany"
            fieldActions={prepareStringRulesField(
              "BCompany",
              companyValidationRules
            )}
          />
        </Styled.Row>
        {children}
      </Styled.Wrapper>
    </WrapperWhiteBG>
  );
};

export default PaymentBeneficiaryCommon;
