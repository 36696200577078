import { createContext, useEffect, useState } from "react";
import {
  getAccountData,
  getBeneficiaryDetails,
  getCountryData,
  getCurrencyAccountData,
  getHintsData,
  getStateData,
  initContext,
  initDataFS,
  initDataCommon,
  initDataSS,
  getResFormToData,
  getDataTax,
  getDataPrefix,
} from "./data";
import IBFirstStep from "./steps/firstStep";
import IBSecondStep from "./steps/secondStep";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import { getChangeError } from "../../utils/getChangeError";
import { useDispatch } from "react-redux";
import { setIsQuestionLeave } from "../../store/user";
import { useLocation, useNavigate } from "react-router-dom";
import WrapperPayment from "../../component/Wrappers/wrapperPayment";
import { InvoiceTBAPI } from "../../services/invoiceTemplateBuilder";
import { useOpenBrowserModalAfterPageReload } from "../../utils/useOpenBrowserModalAfterPageReload/useOpenBrowserModalAfterPageReload";
import { useValidateOnBlur } from "./steps/firstStep/continue/validator";

interface ILocation {
  state?: {
    invoiceId?: string;
    isEdit?: boolean;
  };
}

export const ContextInvoiceBuilder = createContext(initContext);

const InvoiceTemplateBuilderComponent = () => {
  const { invoiceId: invoiceID, isEdit = false } =
    (useLocation() as ILocation).state || {};

  const infoInitQuery = useQueryAPI(InvoiceTBAPI.init, {
    payload: { invoiceID, mode: isEdit ? "edit" : "init" },
    changeIsLoading: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isInit, setIsInit] = useState(true);
  const [step, setStep] = useState(1);
  const [dataFS, setDataFS] = useState(initDataFS);
  const [dataSS, setDataSS] = useState(initDataSS);
  const [dataCommon, setDataCommon] = useState(initDataCommon);

  const { onChangeData, onErrorData } = getChangeError(setDataFS);
  const validateOnBlur = useValidateOnBlur(dataFS, dataCommon, onErrorData);

  useOpenBrowserModalAfterPageReload(!isInit, [dataFS, dataSS, dataCommon]);

  useEffect(() => {
    return () => {
      dispatch(setIsQuestionLeave(false));
    };
  }, []);

  useEffect(() => {
    if (!isInit) {
      dispatch(setIsQuestionLeave(true));
    }
  }, [dataFS, dataSS, dataCommon]);

  useEffect(() => {
    const response = infoInitQuery.data;
    const responseError = infoInitQuery.error;

    if (response) {
      const {
        beneficiaries_list,
        dictionaries,
        invoice,
        remitters,
        form,
        logo,
      } = response.data;
      const { countries, country_id_us, us_states, taxationTypes } =
        dictionaries;

      const beneficiary = getBeneficiaryDetails(
        beneficiaries_list.dropdown_options.data,
        "beneficiary_data"
      );

      const paymentDetails = getBeneficiaryDetails(
        beneficiaries_list.dropdown_options.data,
        "payment_data"
      );

      const dataGAccount = getAccountData(
        beneficiaries_list.dropdown_options.data
      );

      const dataCurrencyAccount = getCurrencyAccountData(
        beneficiaries_list.dropdown_options.data
      );

      const dataRemCountry = getCountryData(countries);
      const dataRemState = getStateData(us_states);

      const dataRemHints = getHintsData(remitters, dataRemCountry);

      const dataGTypeTax = getDataTax(taxationTypes);

      const dataPaymentPrefix = getDataPrefix(
        beneficiaries_list.dropdown_options.data
      );

      const { dataForm, dataTable } = getResFormToData(
        form,
        logo,
        dataGAccount,
        dataRemCountry,
        dataRemState,
        dataGTypeTax
      );

      dataForm.forEach(({ key, value }) => onChangeData(key, value));

      setDataSS((prev) => ({
        ...prev,
        templateName: { value: invoice.invoiceTemplateName || "", error: "" },
      }));

      setDataCommon((prev) => ({
        ...prev,
        beneficiary,
        paymentDetails,
        dataGAccount,
        dataGTypeTax,
        invoiceID: invoice.invoiceTemplateId,
        USID: country_id_us,
        dataCurrencyAccount,
        dataRemCountry,
        dataRemState,
        dataRemHints,
        dataTable,
        dataPaymentPrefix,
      }));
      setTimeout(() => {
        setIsInit(false);
      }, 1000);
    }

    if (responseError) {
      navigate("/invoices-templates");
    }
  }, [infoInitQuery]);

  return (
    <ContextInvoiceBuilder.Provider
      value={{
        step,
        setStep,
        dataFS,
        setDataFS,
        dataSS,
        setDataSS,
        dataCommon,
        setDataCommon,
        onChangeData,
        onErrorData,
        validateOnBlur,
      }}
    >
      <WrapperPayment
        {...infoInitQuery.data?.data.page?.header}
        step={step}
        viewStep={["Fill out", "Preview & save"]}
      >
        <IBFirstStep />
        <IBSecondStep />
      </WrapperPayment>
    </ContextInvoiceBuilder.Provider>
  );
};

export default InvoiceTemplateBuilderComponent;
