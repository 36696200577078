import { Button, Modal, Skeleton } from "antd";
import { FC, useEffect, useState } from "react";
import { Text, TextBold } from "../../../../../../../commonStyle";
import InputComponent from "../../../../../../../component/Global/Input";
import TypeToTextMap from "../../../../../../../component/Global/TypeToTextMap";
import { CardAPI } from "../../../../../../../services/cards";
import { IItemTableAPI } from "../../../../../../../services/types/table";
import useMutationAPI from "../../../../../../../utils/usePromise/hookMutationAPI";
import useQueryAPI from "../../../../../../../utils/usePromise/hookQueryAPI";
import { useAppSelector } from "../../../../../../../utils/useState";
import validateString from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

interface IProps {
  data: IItemTableAPI[][];
  cardId: string;
  onCancel: () => void;
  template: {
    title: string;
    data: IItemTableAPI[][];
  };
  refetchData: () => void;
}

const CTFCBlockCard: FC<IProps> = ({
  data,
  cardId,
  template,
  onCancel,
  refetchData,
}) => {
  const [queryBlock, infoBlockQuery] = useMutationAPI(CardAPI.table.blockCard);

  const [dataCode, setDataCode] = useState({ value: "", error: "" });

  const { userMultiFactorType } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    if (infoBlockQuery.data) {
      onCancel();
      refetchData();
    }
  }, [infoBlockQuery]);

  const onClickBlock = () => {
    const min = userMultiFactorType === "sms" ? 4 : 6;
    const error = validateString(dataCode.value, {
      isReq: true,
      min,
      max: min,
    });

    setDataCode((prev) => ({ ...prev, error }));

    if (error.length) return;

    queryBlock({ auth_code: dataCode.value, cardId });
  };

  return (
    <Modal open={true} onCancel={onCancel} width={478}>
      <Styled.Wrapper>
        <Text fontSize={20} margin="0 0 16px">
          {template.title}
        </Text>
        <Styled.WrapperItems>
          <TypeToTextMap
            data={template.data}
            styleData={{ textAling: "center" }}
            position="center"
          />
          <TypeToTextMap data={data} position="center" />

          <Text fontSize={16} textAling="center">
            {userMultiFactorType === "sms"
              ? "Please enter SMS code"
              : "Please enter Google Authenticator code"}
          </Text>

          <InputComponent
            {...dataCode}
            onChangeValue={(value) => setDataCode({ error: "", value })}
            onKeyEnter={onClickBlock}
            width="60%"
            maxLength={userMultiFactorType === "sms" ? 4 : 6}
          />

          <Button
            shape="round"
            size="large"
            disabled={!!dataCode.error.length || !dataCode.value.length}
            onClick={onClickBlock}
            danger
          >
            Authorize & Block
          </Button>
        </Styled.WrapperItems>
      </Styled.Wrapper>
    </Modal>
  );
};

export default CTFCBlockCard;
