import { Button } from "antd";
import { useContext, useEffect, useId } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ContextCryptoFiat } from "../..";
import { TextBold } from "../../../../commonStyle";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { setIsQuestionLeave } from "../../../../store/user";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import ItemSecondStepSwift from "./item";
import * as Styled from "./style";
import { CryptoFiatPayInApi } from "../../../../services/cryptoFiatPayIn";

const CryptoFiatPayInSecondStep = () => {
  const idCompoenent = useId();
  const valueContext = useContext(ContextCryptoFiat);
  if (!valueContext) return <div />;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, dataSS, step, setStep, dataCommon } = valueContext;
  const { requestId } = dataCommon;
  const { dataSecondStep } = dataSS;

  const [queryExecute, infoExecuteQuery] = useMutationAPI(
    CryptoFiatPayInApi.execute
  );

  useEffect(() => {
    const response = infoExecuteQuery.data;
    if (response) {
      dispatch(setIsQuestionLeave(false));
      navigate("/crypto-fiat-pay-in-request");
    }
  }, [infoExecuteQuery]);

  return (
    <Styled.Wrapper>
      <WrapperWhiteBG>
        <Styled.WrapperItem>
          {dataSecondStep.map(({ title, data }, index) => {
            return (
              <div key={idCompoenent + "." + index}>
                <TextBold fontSize={18}>{title}</TextBold>
                <ItemSecondStepSwift data={data} />
              </div>
            );
          })}
        </Styled.WrapperItem>
        <Styled.WrapperButtons>
          <Button
            shape="round"
            size="large"
            onClick={() => setStep(1)}
            disabled={infoExecuteQuery.isLoading || step !== 2}
          >
            Edit request details
          </Button>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => queryExecute({ requestId })}
            loading={infoExecuteQuery.isLoading}
            disabled={step !== 2}
          >
            Confirm
          </Button>
        </Styled.WrapperButtons>
      </WrapperWhiteBG>
    </Styled.Wrapper>
  );
};

export default CryptoFiatPayInSecondStep;
