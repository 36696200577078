import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const WrapperAlert = styled.div`
  width: calc(100% / 3);
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
