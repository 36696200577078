import { Button, Modal } from "antd";
import { FC, useId } from "react";
import { useDispatch } from "react-redux";
import { Text } from "../../commonStyle";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import { INotificationModalAPI } from "../../services/types";
import UserAPI from "../../services/userAPI";
import { setNotificationModal } from "../../store/user";
import useMutationAPI from "../usePromise/hookMutationAPI";
import * as Styled from "./style";

const NotifModalAPI: FC<INotificationModalAPI> = ({
  data,
  buttons,
  title,
  notificationId,
}) => {
  const idComponent = useId();
  const [queryAgree, infoAgreeQuery] = useMutationAPI(UserAPI.agreeModal);
  const [queryClose, infoCloseQuery] = useMutationAPI(UserAPI.closeModal);

  const dispatch = useDispatch();

  const closeModal = () => dispatch(setNotificationModal(null));

  const onAction = (isClose: boolean) => {
    const query = isClose ? queryClose : queryAgree;
    if (infoCloseQuery.isLoading || infoAgreeQuery.isLoading) return;
    query({ notificationId }).finally(() => closeModal());
  };

  return (
    <Modal open={true} onCancel={() => onAction(true)} width={478}>
      <Styled.Wrapper>
        <Text fontSize={20} margin="0 0 16px">
          {title}
        </Text>
        <Styled.WrapperItems>
          <TypeToTextMap
            data={[data]}
            styleData={{ textAling: "center" }}
            position="center"
          />
          <Styled.WrapperButtons>
            {buttons.map(({ text }, index) => (
              <Button
                shape="round"
                size="large"
                onClick={() => onAction(false)}
                loading={infoCloseQuery.isLoading || infoAgreeQuery.isLoading}
                type="primary"
                key={idComponent + index}
              >
                {text}
              </Button>
            ))}
          </Styled.WrapperButtons>
        </Styled.WrapperItems>
      </Styled.Wrapper>
    </Modal>
  );
};

export default NotifModalAPI;
