import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { createRef, FC } from "react";
import { checkDate, isDateEqueality } from "../../../utils/compareDate";
import WrapperTitleError from "../../Wrappers/wrapperTitleError";
import * as Styled from "./style";
import { ILimitationDate } from "./types";

interface IProps extends ILimitationDate {
  value?: Date | null;
  setDate?: (date: Date) => void;
  title?: string;
  error?: string;
  disabledError?: boolean;
  width?: string;
  id?: string;
  disableToday?: boolean;
  disableHeader?: boolean;
  dateRender?: (current: dayjs.Dayjs) => JSX.Element;
}

const DatePickerComponent: FC<IProps> = ({
  value,
  setDate,
  title,
  error,
  minDate = new Date(0),
  maxDate = new Date(10000, 0, 0),
  blockDayWeek = [],
  blockDate = [],
  disabledError,
  width,
  id,
  disableToday = false,
  disableHeader = false,
  dateRender,
}) => {
  const refDatePicker = createRef<HTMLDivElement>();

  const getDisableDate = (value: Dayjs) => {
    const date = [new Date(value.toISOString())];
    return checkDate({ date, blockDate, blockDayWeek, maxDate, minDate })[0];
  };

  moment.locale("en", {
    week: {
      dow: 1,
    },
  });

  return (
    <WrapperTitleError
      title={title}
      error={error}
      disabledError={disabledError}
      width={width}
      ref={refDatePicker}
      id={id}
    >
      {/* <DatePicker
        value={value ? moment(value) : undefined}
        size="large"
        getPopupContainer={(trigger) => trigger}
        disabledDate={getDisableDate}
        format="DD/MM/YYYY"
        onChange={(value) => {
          if (!value) return;
          setDate(new Date(value.toISOString()));
        }}
      /> */}
      <Styled.Wrapper disableHeader={disableHeader}>
        <DatePicker
          value={value ? dayjs(value) : value}
          size="large"
          getPopupContainer={(trigger) => trigger}
          disabledDate={getDisableDate}
          format="MM/DD/YYYY"
          dateRender={dateRender}
          showToday={!disableToday}
          onChange={(value) => {
            if (!value || !setDate) return;
            setDate(new Date(value.toISOString()));
          }}
        />
      </Styled.Wrapper>
    </WrapperTitleError>
  );
};

export default DatePickerComponent;
