import styled, { css } from "styled-components";
import { TextBold } from "../../../commonStyle";

interface IWrapper {
  isBorder: boolean;
}

const getBorder = () => {
  return css`
    & > div {
      border-bottom: 1px solid #bfbfbf;
    }
    & > div:last-child {
      border-bottom: unset;
    }
  `;
};

export const Wrapper = styled.div<IWrapper>`
  width: 100%;
  box-shadow: 0 0 20px rgba(27, 41, 68, 10%);
  border-radius: 20px;
  padding: 25px 32px;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "initial")};
  ${({ isBorder }) => isBorder && getBorder()};
  @media (max-width: 480px) {
    padding: 15px 24px;
  }
`;

export const Title = styled(TextBold)`
  display: flex;
  align-items: center;
`;
