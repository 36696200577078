import { FC } from "react";

import * as Styled from "./style";
import TypeToTextMap from "../../../../component/Global/TypeToTextMap";
import { IItemTableAPI } from "../../../../services/types/table";

export interface IPropsItemBalances {
  firstColumnData: IItemTableAPI[][];
  score: IItemTableAPI[][];
}

const ItemBalances: FC<IPropsItemBalances> = ({ firstColumnData, score }) => {
  return (
    <Styled.Wrapper>
      <Styled.FirstColumn>
        <TypeToTextMap data={firstColumnData} />
      </Styled.FirstColumn>

      <Styled.SecondColumn>
        <Styled.WrapperAmount>
          <TypeToTextMap data={score} />
        </Styled.WrapperAmount>
      </Styled.SecondColumn>
    </Styled.Wrapper>
  );
};

export default ItemBalances;
