import { AxiosError, AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useContext } from "react";
import { GoogleReCaptchaContext } from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";
import {
  INotificationAPI,
  INotificationModalAPI,
  IResponseError,
} from "../../../services/types";
import UserAPI from "../../../services/userAPI";
import {
  setAuthError,
  setDataUser,
  setIsAuth,
  setIsQuestionLeave,
  setNotificationModal,
} from "../../../store/user";
import { useCreateNotif } from "../../hookCraeteNotif";

const usePromiseData = <T, E = IResponseError>() => {
  const valueContextGRC = useContext(GoogleReCaptchaContext);
  const { executeRecaptcha } = valueContextGRC;

  const createNotif = useCreateNotif();
  const dispatch = useDispatch();

  const setNotification = (notification: INotificationAPI | null) => {
    if (notification) {
      const { title, data, type } = notification;
      createNotif(title, data, type);
    }
  };

  const setNotifModal = (modal_notification: INotificationModalAPI | null) => {
    dispatch(setNotificationModal(modal_notification));
  };

  const logOutUser = async (title: string, info: string) => {
    try {
      await UserAPI.logout();
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      localStorage.removeItem("token");
      dispatch(setIsAuth(false));
      dispatch(
        setAuthError({
          status: true,
          data: { title, info },
        })
      );
      dispatch(setIsQuestionLeave(false));
    }
  };

  const onError = (error: AxiosError<E, any>) => {
    if (!error.response) return;

    switch (error.response.status) {
      case 403:
        logOutUser(
          "Security alert",
          "You have been logged out due to security measures. Please login again"
        );
        break;
      case 500:
        logOutUser("Security alert", "Server error");
        break;
      default:
        setNotification((error.response.data as any).notification || null);
        setNotifModal((error.response.data as any).modal_notification || null);
        break;
    }
  };

  const onSucces = (infoData: any) => {
    if (infoData.user) {
      const {
        name: userName,
        nav,
        userPhone,
        userMultiFactorType,
        userId,
        userType,
      } = infoData.user;

      dispatch(
        setDataUser({
          userName,
          nav: nav ?? [],
          userPhone,
          userMultiFactorType,
          userId,
          userType,
        })
      );
    }
    if (
      infoData.version &&
      localStorage.getItem("version") &&
      infoData.version !== localStorage.getItem("version")
    ) {
      logOutUser(
        "App update",
        "We have updated the App and kindly ask you to login again."
      );
    }
    if (infoData.version) localStorage.setItem("version", infoData.version);

    dispatch(setIsAuth(true));

    setNotification(infoData.notification || null);
    setNotifModal(infoData.modal_notification || null);
  };

  const getReCatcha = async () => {
    try {
      if (!executeRecaptcha) return false;
      const token = await executeRecaptcha();
      const { data } = await UserAPI.reCaptcha({ token, type: "password" });
      const status = data.result === "ok";
      if (!status)
        setNotification({ title: "ReCaptcha failed", data: [], type: "error" });
      return status;
    } catch {
      return false;
    }
  };

  return { onError, onSucces, getReCatcha };
};

export default usePromiseData;
