import { IItemPicker } from "../../../../component/Global/Picker";
import { IValueError } from "../../../../component/payment/types";
import validateNumber from "../../../../utils/validate/validateNumber";
import validateString from "../../../../utils/validate/validateString";

type TKeysValidate =
  | "benefAccount"
  | "remitterName"
  | "paymentDescription"
  | "amount";

type TDataValidate = {
  [key in TKeysValidate]: IValueError<IItemPicker | string>;
};

export const validate = (data: TDataValidate, prefixAmount: string) => {
  const keys = Object.keys(data) as (keyof TDataValidate)[];

  const errors = keys.map((key) => {
    const value = data[key].value;

    if (typeof value === "string" && key === "amount") {
      return {
        key,
        error: validateNumber(
          parseFloat(value) || 0,
          {
            min: 0.01,
          },
          {
            symbol: prefixAmount,
          }
        ),
      };
    }

    if (typeof value === "string") {
      return {
        key,
        error: validateString(value, { isReq: true }),
      };
    }

    return {
      key,
      error: validateString(value.id, { isReq: true }),
    };
  });
  return errors;
};
