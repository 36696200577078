import styled from "styled-components";

export const WrapperRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  margin-top: 15px;
  & > div:first-child {
    flex: 2;
  }
  & > div {
    flex: 3;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    & > div {
      width: 100%;
      flex: unset;
    }
  }
`;
