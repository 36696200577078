import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeOpenType } from "../../store/user";
import MainPageCreditDoc from "./main";

const CreditDocComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeOpenType({ openType: "credit-documents" }));
  }, []);

  return <MainPageCreditDoc />;
};

export default CreditDocComponent;
