import { useContext, useEffect } from "react";
import { ContextInvoiceBuilder } from "../../../../..";
import PickerComponent from "../../../../../../../component/Global/Picker";
import * as Styled from "./style";

const IBGFirstRow = () => {
  const { dataFS, dataCommon, onChangeData, onErrorData, setDataCommon } =
    useContext(ContextInvoiceBuilder);
  const { GAccount, GTypeTax } = dataFS;
  const { dataGAccount, dataCurrencyAccount, dataPaymentPrefix, dataGTypeTax } =
    dataCommon;

  useEffect(() => {
    const paymentPrefix =
      dataPaymentPrefix.find(({ id }) => id === GAccount.value.id)?.text || "";

    const currency =
      dataCurrencyAccount.find(({ id }) => id === GAccount.value.id)?.text ||
      "";

    onErrorData("DReference", "");
    setDataCommon((prev) => ({ ...prev, paymentPrefix, currency }));
  }, [GAccount]);

  return (
    <Styled.Wrapper>
      <PickerComponent
        {...GAccount}
        data={dataGAccount}
        title="Receive funds to Account:"
        placeholder="Select Account"
        setValue={(value) => onChangeData("GAccount", value)}
        id="GAccount"
        width="75%"
      />
      <PickerComponent
        {...GTypeTax}
        title="Taxation wording:"
        data={dataGTypeTax}
        setValue={(value) => onChangeData("GTypeTax", value)}
        placeholder="Select Taxation"
        width="25%"
        id="GTypeTax"
      />
    </Styled.Wrapper>
  );
};

export default IBGFirstRow;
