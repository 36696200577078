import { IDataHintsInput } from "../../../component/Global/Input/hints";
import { IItemPicker } from "../../../component/Global/Picker";
import { IFileTransferDocument } from "../../../component/Global/transferDocument/types";
import {
  IBeneficiarySwiftAPI,
  IDictionariesSwift,
  IFilesPayment,
} from "../../../services/paymentAPI/types";
import { IPayloadValidateSwift } from "../../../services/paymentAPI/types/payload";
import { IDropdownAPI } from "../../../services/types";
import { getValueError } from "../data";
import { IDataCommon, IDataFS } from "./types";

export const initDataFS: IDataFS = {
  DIsInterTransfer: getValueError(false),
  DIsUrgent: getValueError(false),
  DIsCompanyName: getValueError(true),
  DAmount: getValueError("0.00"),
  DPaymentDescription: getValueError(""),
  DFee: getValueError({ text: "", id: "sha" }),

  BCompany: getValueError(""),
  BCountry: getValueError({ text: "", id: "" }),
  BState: getValueError({ text: "", id: "" }),
  BCaState: getValueError({ text: "", id: "" }),
  BCity: getValueError(""),
  BPostalCode: getValueError(""),
  BStreetAddress: getValueError(""),
  BHouseNumber: getValueError(""),
  BAccountNumber: getValueError(""),
  BBic: getValueError(""),
  BInstitutionNumber: getValueError(""),
  BTransitNumber: getValueError(""),
  BAba: getValueError(""),
  BCorrespAccount: getValueError(""),
  BCorrespBIC: getValueError(""),
  BCorrespBankName: getValueError(""),
  BIsOpenCorresp: getValueError(false),

  Tfiles: getValueError([]),
};

export const initDataCommon: IDataCommon = {
  amountSymbol: "",
  balance: 0,
  dataHeader: [],
  canadaID: "",
  usID: "",
  paymentID: "",
  checksum: "",
  typeAuth: "google-authenticator",
  dataCountry: [],
  dataState: [],
  dataCaState: [],
  companyHints: [],
  accountId: "",
  isRequireFiles: true,
  isDisableSaveAsDraft: false,
  useCompanyNameInDescriptionControl: true,
  companyName: "",
  dataFee: [],
  isLoadingFiles: false,
  prefixDescription: "Test Company",
  isAbleAuth: false,
  isUsingTemplate: false,
};

const getDataHints = (
  data: IBeneficiarySwiftAPI,
  dictionaries: IDictionariesSwift
): Partial<IDataFS> => {
  const countryName = dictionaries.countries.find(
    (item) => item.countryId === data.paymentNameCountryId
  )?.countryName;
  const stateName = dictionaries.us_states.find(
    (item) => item.stateCode === data.paymentNameAddressState
  )?.stateName;
  const canadaStateName = dictionaries.ca_states.find(
    (item) => item.stateCode === data.paymentNameAddressState
  )?.stateName;

  return {
    BBic: getValueError(data.paymentBankBic || ""),
    BCountry: getValueError({
      text: countryName || "",
      id: data.paymentNameCountryId || "",
    }),
    BCity: getValueError(data.paymentNameAddressCity || ""),
    BState: getValueError({
      text: stateName || "",
      id: data.paymentNameAddressState || "",
    }),
    BCaState: getValueError({
      text: canadaStateName || "",
      id: data.paymentNameAddressState || "",
    }),
    BPostalCode: getValueError(data.paymentNameAddressPostalCode || ""),
    BStreetAddress: getValueError(data.paymentNameAddressStreet || ""),
    BHouseNumber: getValueError(data.paymentNameAddressNumber || ""),
    BAba: getValueError(data.paymentBankAba || ""),
    BAccountNumber: getValueError(data.paymentAccountNumberTo || ""),
    BInstitutionNumber: getValueError(data.paymentBankInstitutionNumber || ""),
    BTransitNumber: getValueError(data.paymentBankTransitNumber || ""),
    BCorrespAccount: getValueError(data.paymentCorrespondentAccount || ""),
    BCorrespBIC: getValueError(data.paymentCorrespondentBankBic || ""),
    BCorrespBankName: getValueError(data.paymentCorrespondentBankName || ""),
  };
};

export const getCompanyHints = (
  beneficiaries: IBeneficiarySwiftAPI[],
  dictionaries: IDictionariesSwift
) => {
  const data = beneficiaries.map(
    (item): IDataHintsInput => ({
      title: { text: item.paymentName, type: "em" },
      subTitle: item.details,
      data: getDataHints(item, dictionaries),
    })
  );
  return data;
};

export const getDataFee = (dataProps?: IDropdownAPI) => {
  if (!dataProps) return [];

  const data = dataProps.dropdown_options.data.map(
    ({ text, value }): IItemPicker => ({
      text,
      id: value,
    })
  );
  return data;
};

export const getInitFee = (dataProps?: IDropdownAPI) => {
  if (!dataProps) return { text: "", id: "sha" };

  const data = getDataFee(dataProps).find(
    (item) => item.id === dataProps.dropdown_options.value
  );
  if (!data) return { text: "", id: "sha" };
  return data;
};

const resToData: {
  [key in keyof IPayloadValidateSwift["payload"]]: keyof IDataFS;
} = {
  accountId: "BAba",
  paymentAccountNumberTo: "BAccountNumber",
  paymentAmount: "DAmount",
  paymentBankBic: "BBic",
  paymentDescription: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
  paymentIsSameCompanyTransfer: "DIsInterTransfer",
  paymentName: "BCompany",
  paymentPriorityType: "DIsUrgent",
  paymentCorrespondentAccount: "BCorrespAccount",
  paymentCorrespondentBankBic: "BCorrespBIC",
  paymentCorrespondentBankName: "BCorrespBankName",
  paymentNameAddressCity: "BCity",
  paymentNameAddressNumber: "BHouseNumber",
  paymentNameAddressPostalCode: "BPostalCode",
  paymentNameAddressState: "BState",
  paymentNameAddressStreet: "BStreetAddress",
  paymentNameCountryId: "BCountry",
  paymentSwiftFeeType: "DFee",
  paymentBankTransitNumber: "BTransitNumber",
  paymentBankInstitutionNumber: "BInstitutionNumber",
  paymentBankAba: "BAba",
};

type TValue = boolean | string | IItemPicker | IFileTransferDocument[];

export const getDataFromForm = (
  form: Partial<IPayloadValidateSwift["payload"]>,
  files: IFilesPayment[],
  dataCountry: IItemPicker[],
  dataState: IItemPicker[],
  dataCaState: IItemPicker[],
  dataFee: IItemPicker[],
  paymentDescription: string
) => {
  let responseData: Partial<{
    [key in keyof IDataFS]: TValue;
  }> = {};

  const keysForm = Object.keys(form) as (keyof typeof form)[];

  keysForm.forEach((key) => {
    const value = form[key];
    const keyData = resToData[key];

    if (!keyData) return;

    if (typeof value === "number") {
      responseData[keyData] = value.toFixed(2);
      return;
    }

    if (keyData === "BCountry") {
      responseData[keyData] =
        dataCountry.find(({ id }) => id === form.paymentNameCountryId) ||
        initDataFS.BCountry.value;
      return;
    }

    if (keyData === "BState") {
      responseData["BCaState"] =
        dataCaState.find(({ id }) => id === form.paymentNameAddressState) ||
        initDataFS.BCaState.value;
      responseData["BState"] =
        dataState.find(({ id }) => id === form.paymentNameAddressState) ||
        initDataFS.BState.value;
      return;
    }

    if (keyData === "DFee") {
      responseData[keyData] =
        dataFee.find(({ id }) => id === form.paymentSwiftFeeType) ||
        initDataFS.DFee.value;
      return;
    }

    if (
      keyData === "DPaymentDescription" &&
      typeof value === "string" &&
      form.paymentDescriptionCompanyName
    ) {
      responseData[keyData] = value.slice(paymentDescription.length + 1);
      return;
    }

    responseData[keyData] = value;
  });

  responseData["Tfiles"] = files.map(({ documentId, name, url }) => ({
    id: documentId,
    name,
    url,
    isLoading: false,
  }));

  return Object.entries(responseData) as [keyof IDataFS, TValue][];
};
