import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import { POSTAL_CODE_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

const rulesPostalCode: IRulesString = { isReq: true, min: 4, max: 10 };

type IDataFS = {
  BPostalCode: IValueError<string>;
};

interface Input {
  dataFS: IDataFS;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const PostalCodeInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: Input;
}) => {
  return (
    <InputComponent
      {...dataFS.BPostalCode}
      onChangeValue={(value) => onChangeDataFS("BPostalCode", value)}
      title="Postal Code:"
      fieldActions={prepareStringRulesField("BPostalCode", rulesPostalCode)}
      regex={POSTAL_CODE_REGEX}
      onLostFocusActions={[
        LostFocusActions.REPLACE_WHITESPACES,
        LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
      ]}
      maxLength={rulesPostalCode.max}
      id="BPostalCode"
    />
  );
};
