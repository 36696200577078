import { Button } from "antd";
import { useCallback, useEffect, useId, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IFileTransferDocument } from "../../../../../component/Global/transferDocument/types";
import { TGetLeaveButton } from "../../../../../component/Wrappers/wrapperHeaderNav/navBar/modalLeave";
import CommonModalComponent from "../../../../../component/commonModal";
import PaymentAPI from "../../../../../services/paymentAPI";
import {
  scrollToError,
  scrollToFirstError,
} from "../../../../../utils/scrollToError";
import { useInterval } from "../../../../../utils/useInterval";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { IValueError } from "../../../../contact/form/types";
import { TDataFS } from "../../../types";
import * as Styled from "./style";
import { FormActions } from "../form";

type IDataFS = {
  Tfiles: IValueError<IFileTransferDocument[]>;
} & TDataFS<any>;

interface IDataCommon {
  paymentID: string;
  accountId: string;
  isDisableSaveAsDraft: boolean;
  isUsingTemplate?: boolean;
}

interface Input<DataFS extends IDataFS, DataCommon extends IDataCommon> {
  dataFS: DataFS;
  dataCommon: DataCommon;
  formActions: FormActions<DataFS>;
}

interface ValidationError<DataFS extends IDataFS> {
  key: Extract<keyof DataFS, string>;
  error: string;
}

interface IProps<DataFS extends IDataFS, DataCommon extends IDataCommon> {
  input: Input<DataFS, DataCommon>;
  queryValidate: (dataFS: DataFS, dataCommon: DataCommon) => Promise<any>;
  isLoadingValid: boolean;
  queryDraft: (
    dataFS: DataFS,
    dataCommon: DataCommon,
    autoSave?: boolean
  ) => Promise<any>;
  isLoadingDraft: boolean;
  buttonsCanBeDisabled?: boolean; // TODO maybe we can remove this and make all payments with disalable buttons
}

const CommonContinueButton = <
  DataFS extends IDataFS,
  DataCommon extends IDataCommon
>({
  input: { dataFS, dataCommon, formActions },
  queryValidate,
  isLoadingValid,
  isLoadingDraft,
  queryDraft,
  buttonsCanBeDisabled = true,
}: IProps<DataFS, DataCommon>) => {
  const idComponent = useId();
  const navigate = useNavigate();

  const [queryDiscard, infoDiscardQuery] = useMutationAPI(PaymentAPI.discard);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { paymentID, accountId, isDisableSaveAsDraft } = dataCommon;

  const disableContinue = useMemo(() => {
    return (
      buttonsCanBeDisabled &&
      !!dataFS.Tfiles.value.filter(({ isLoading }) => isLoading).length
    );
  }, [dataFS]);

  const onClickContinue = useCallback(() => {
    const dataWithErrors = formActions.validate();
    if (dataWithErrors == null) {
      queryValidate(dataFS, dataCommon);
    } else {
      scrollToFirstError(dataWithErrors);
    }
  }, [dataFS, dataCommon]);

  const onClickSaveForLater = useCallback(
    (autoSave?: boolean) => {
      return queryDraft(dataFS, dataCommon, autoSave);
    },
    [dataFS, dataCommon]
  );

  useInterval(() => {
    onClickSaveForLater(true);
  }, 14 * 60 * 1000);

  useEffect(() => {
    if (!isDisableSaveAsDraft) {
      let getLeaveButtons: TGetLeaveButton = (onClickStay, onClickLeave) => {
        return [
          <Button
            key={idComponent + "leave"}
            shape="round"
            size="large"
            style={{ padding: "6.4px 24px" }}
            onClick={() => {
              onClickSaveForLater().then(() => {
                onClickLeave();
              });
            }}
          >
            Save as draft
          </Button>,
          <Button
            key={idComponent + "stay"}
            shape="round"
            size="large"
            style={{ padding: "6.4px 24px" }}
            loading={infoDiscardQuery.isLoading}
            onClick={() => {
              if (dataCommon.isUsingTemplate) {
                onClickLeave();
              } else {
                queryDiscard(paymentID).then(() => {
                  onClickLeave();
                });
              }
            }}
            danger
          >
            Discard
          </Button>,
        ];
      };

      (window as any).getLeaveButtons = getLeaveButtons;
    } else {
      (window as any).getLeaveButtons = undefined;
    }
  }, [dataFS, onClickSaveForLater, accountId, paymentID]);

  return (
    <Styled.WrapperContinue>
      {!isDisableSaveAsDraft && (
        <Button
          shape="round"
          size="middle"
          onClick={() => setIsOpenModal(true)}
          disabled={disableContinue}
        >
          Save as draft
        </Button>
      )}

      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onClickContinue}
        loading={isLoadingValid}
        disabled={disableContinue}
      >
        Continue
      </Button>
      {isOpenModal && (
        <CommonModalComponent
          onClickYes={() => {
            onClickSaveForLater(false).then(() => navigate("/new-payment"));
          }}
          onClickNo={() => setIsOpenModal(false)}
          onClose={() => setIsOpenModal(false)}
          loadingYes={isLoadingDraft}
          buttons={["Save as draft", "Cancel"]}
          title="Save as draft?"
          isDanger={[false, true]}
          message={
            <div>
              <div>
                Save as draft to continue filling out the form once ready.
              </div>
              <div>Cancel to go back to filling out details.</div>
            </div>
          }
          smallPadding
        />
      )}
    </Styled.WrapperContinue>
  );
};

export default CommonContinueButton;
