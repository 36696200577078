import { Alert } from "antd";
import { FC } from "react";
import { TextBold } from "../../../commonStyle";
import TypeToTextMap from "../../../component/Global/TypeToTextMap";
import { INotifDashboard } from "../../../services/pagesAPI/types";
import * as Styled from "./style";

interface IProps {
  data: INotifDashboard[];
  deleteNotif: (id: string) => void;
}

const DashNotifications: FC<IProps> = ({ data, deleteNotif }) => {
  return (
    <div>
      <TextBold fontSize={18} fontWeight={500} margin="16px 0">Notifications</TextBold>
      <Styled.WrapperNotif>
        {data.map(({ title, type, data, notificationId }) => {
          return (
            <Alert
              key={notificationId}
              message={title}
              type={type}
              description={
                <TypeToTextMap data={data} styleData={{ fontSize: 14}} />
              }
              showIcon
              closable
              onClose={() => setTimeout(() => deleteNotif(notificationId), 300)}
            />
          );
        })}
      </Styled.WrapperNotif>
    </div>
  );
};

export default DashNotifications;
