import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px 24px;
  & > * {
    width: 50%;
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
