import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../utils/useState";

const PrivateRoute = () => {
  const { isAuth } = useAppSelector((state) => state.userReducer);
  const divReCaptcha = document.querySelector(".grecaptcha-badge");
  if (divReCaptcha && divReCaptcha.parentElement) {
    divReCaptcha.parentElement.style.display = "none";
  }
  if (!isAuth) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

export default PrivateRoute;
