import { useContext, useEffect } from "react";
import { ContextRecurringPayment } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import validateString, {
  IRulesString,
} from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

const rulesCorrespAccount: IRulesString = { isReq: true, min: 7, max: 34 };
const rulesCorrespBIC: IRulesString = { isReq: true, min: 8, max: 11 };
const rulesCorrespBankName: IRulesString = { isReq: true, min: 3, max: 50 };

const RPBFPayToCorrespRow = () => {
  const { dataFS, onChangeData, onErrorData } = useContext(
    ContextRecurringPayment
  );

  const { correspAccount, correspBIC, correspBankName } = dataFS;
  
  useEffect(() => {
    if (!correspAccount.value && !correspBankName.value && !correspBIC.value) {
      onErrorData("correspAccount", "");
      onErrorData("correspBankName", "");
      onErrorData("correspBIC", "");
    }
  }, [correspAccount.value, correspBIC.value, correspBankName.value]);

  const onBlurString = (
    value: string,
    rules: IRulesString,
    key: keyof typeof dataFS
  ) => {
    if (
      !!correspAccount.value ||
      !!correspBankName.value ||
      !!correspBIC.value
    ) {
      onErrorData(key, validateString(value, rules));
    }
  };
  return (
    <Styled.Row>
      <Styled.Item flex={2}>
        <InputComponent
          {...correspAccount}
          onChangeValue={(value) => onChangeData("correspAccount", value)}
          title="Correspondent Account#:"
          onBlur={() =>
            onBlurString(
              correspAccount.value,
              rulesCorrespAccount,
              "correspAccount"
            )
          }
          maxLength={34}
          id="correspAccount"
        />
      </Styled.Item>
      <Styled.Item flex={1}>
        <InputComponent
          {...correspBIC}
          onChangeValue={(value) => onChangeData("correspBIC", value)}
          title="Correspondent BIC:"
          onBlur={() =>
            onBlurString(correspBIC.value, rulesCorrespBIC, "correspBIC")
          }
          maxLength={11}
          id="correspBIC"
        />
      </Styled.Item>
      <Styled.Item flex={2}>
        <InputComponent
          {...correspBankName}
          onChangeValue={(value) => onChangeData("correspBankName", value)}
          title="Correspondent Bank Name:"
          onBlur={() =>
            onBlurString(
              correspBankName.value,
              rulesCorrespBankName,
              "correspBankName"
            )
          }
          maxLength={50}
          id="correspBankName"
        />
      </Styled.Item>
    </Styled.Row>
  );
};

export default RPBFPayToCorrespRow;
