import { LoadingOutlined, UpOutlined } from "@ant-design/icons";
import { AlertProps, Spin } from "antd";
import React, {
  createRef,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import WrapperTitleError from "../../Wrappers/wrapperTitleError";
import PickerOpen from "./Open";
import * as Styled from "./style";
import ValuePicker from "./Value";
import { MAIN_THEME } from "../../../assets";
import {
  PreparedFieldActions,
  usePreparedFiled,
} from "../../../pages/payments/shared/firstStep/form";

// export class CItemPicker {
//   text;
//   id;
//   component;

//   constructor(text: string, id: string, component?: ReactNode) {
//     this.text = text;
//     this.id = id;
//     this.component = component;
//   }
// }

export type IItemPickerValue = {
  text: string;
  id: string;
};

export interface IItemPicker {
  text: string;
  id: string;
  component?: ReactNode;
}

export interface IPropsPicker {
  value: IItemPickerValue;
  setValue: (value: IItemPickerValue) => void;
  data: IItemPicker[];
  borderColor?: string;
  width?: string;
  icon?: ReactNode;
  isButton?: boolean;
  placeholder?: string;
  title?: string;
  error?: string;
  disabledError?: boolean;
  info?: AlertProps[];
  isLoading?: boolean;
  id?: string;
  selectOneItem?: boolean;
  fieldActions?: PreparedFieldActions;
}

const PickerComponent: FC<IPropsPicker> = ({
  value,
  setValue,
  data,
  borderColor = MAIN_THEME.projectColor,
  isButton = false,
  icon,
  placeholder = "",
  title,
  error,
  disabledError,
  info,
  width = "100%",
  isLoading = false,
  id,
  selectOneItem = false,
  fieldActions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const refSelect = createRef<HTMLDivElement>();
  const refInput = createRef<HTMLInputElement>();
  const [searchValue, setSeachValue] = useState("");

  document.body.addEventListener("click", (e) => {
    if (refSelect.current && !e.composedPath().includes(refSelect.current)) {
      setIsOpen(false);
    }
  });

  if (fieldActions) {
    usePreparedFiled(fieldActions);
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeachValue(e.target.value);
  };

  const dataValue = useMemo(() => {
    const fullValue = data.find((it) => it.id == value.id);
    return {
      isOpen,
      isButton,
      inputData: {
        value: searchValue,
        onChange: onChangeSearch,
        placeholder: value.text?.toString() || "",
        ref: refInput,
      },
      value: value.text,
      valueComponent: fullValue?.component,
      placeholder,
      widthIcon: icon ? 21 : 0,
      isPlaceholder: !value.id.length,
    };
  }, [isOpen, isButton, value, refInput, searchValue, icon, placeholder]);

  const dataView = useMemo(() => {
    return data.filter((item) => {
      if (typeof item.text !== "string") return true;
      return item.text
        .toLocaleLowerCase()
        .includes(searchValue.toLocaleLowerCase());
    });
  }, [searchValue, data]);

  const onSelectItem = (value: IItemPicker) => {
    if (isLoading) return;
    setValue(value);
    setIsOpen(false);
    setTimeout(() => {
      fieldActions?.onBlur();
    });
  };

  useEffect(() => {
    setSeachValue("");
    if (refInput.current) {
      refInput.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (data.length === 1 && selectOneItem) {
      onSelectItem(data[0]);
    }
  }, [data]);

  return (
    <WrapperTitleError
      title={title}
      error={error}
      disabledError={disabledError}
      info={info}
      width={width}
      id={id}
    >
      <Styled.Wrapper ref={refSelect}>
        <Styled.WrapperValue
          borderColor={borderColor}
          onClick={() => {
            if (selectOneItem && data.length === 1) return;
            setIsOpen((prev) => !prev);
          }}
          isOpen={isOpen}
          isButton={isButton}
          isError={!!error}
        >
          <Styled.WrapperIcon>{icon}</Styled.WrapperIcon>
          <ValuePicker {...dataValue} />

          {!(selectOneItem && data.length === 1) && (
            <Styled.WrapperArrow isOpen={isOpen || isLoading}>
              {isLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined style={{ color: "#7a78a9" }} spin />
                  }
                />
              ) : (
                <UpOutlined
                  style={isButton ? { color: MAIN_THEME.projectColor } : {}}
                />
              )}
            </Styled.WrapperArrow>
          )}
        </Styled.WrapperValue>

        {isOpen && <PickerOpen data={dataView} onSelectItem={onSelectItem} />}
      </Styled.Wrapper>
    </WrapperTitleError>
  );
};

export default PickerComponent;
