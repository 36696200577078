import { Dispatch, SetStateAction } from "react";
import { IValueError } from "../../component/payment/types";

type IData<K extends string | number | symbol> = {
  [key in K]: IValueError<any>;
};

type TOnChangeData<K extends string | number | symbol> = <
  T extends keyof IData<K>,
  D extends IData<K>[T]["value"]
>(
  key: T,
  value: D
) => void;

type TOnErrrorData<K extends string | number | symbol> = (
  key: keyof IData<K>,
  error: string
) => void;

export const getChangeError = <D extends IData<keyof D>>(
  setData: Dispatch<SetStateAction<D>>
) => {
  const onChangeData: TOnChangeData<keyof D> = (key, value) => {
    setData((prev) => ({ ...prev, [key]: { error: "", value } }));
  };

  const onErrorData: TOnErrrorData<keyof D> = (key, error) => {
    setData((prev) => ({ ...prev, [key]: { ...prev[key], error } }));
  };

  return { onChangeData, onErrorData };
};
