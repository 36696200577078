import { IPayloadValidateUk } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { IDataFS } from "../types";

const keyResponse: {
  [key in keyof Required<IPayloadValidateUk["payload"]>]: keyof IDataFS;
} = {
  paymentBankUkAccountNumber: "BUkAccountNumber",
  paymentAmount: "DAmount",
  paymentBankBic: "BBic",
  paymentIsSameCompanyTransfer: "DIsInterTransfer",
  paymentName: "BCompany",
  paymentNameAddressCity: "BCity",
  paymentNameAddressNumber: "BHouseNumber",
  paymentNameAddressPostalCode: "BPostalCode",
  paymentNameAddressStreet: "BStreetAddress",
  paymentNameAddressState: "BState",
  paymentNameCountryId: "BCountry",
  accountId: "DAmount",
  paymentBankSortCode: "BSortCode",
  paymentReference: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
};

export const getErrorResponse = (
  errorProps: IErrorPayment<keyof IPayloadValidateUk["payload"]>[]
) =>
  errorProps.map(({ field, message }) => ({
    key: keyResponse[field],
    error: message,
  }));
