import PaymentAPI from "../../../../services/paymentAPI";
import { IPayloadValidateSepa } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { getValueError } from "../../data";
import FirstStepCommon from "../../shared/firstStep";
import PaymentBeneficiaryCommon from "../../shared/firstStep/beneficiary/companyInput";
import CommonContinueButton from "../../shared/firstStep/continueButton";
import TransferDocumentCommon from "../../shared/firstStep/transferDocument";
import { IDataCommon, IDataFS, InputSepa } from "../types";
import ThirdRowBeneficiarySepa from "./beneficiary/rows/ThirdRow";
import FirstRowBeneficiarySepa from "./beneficiary/rows/firstRow";
import SecondRowBeneficiarySepa from "./beneficiary/rows/secondRow";
import { getPayload } from "./continueButton/data";
import { getErrorResponse } from "./data";
import PaymentDetaisSepa from "./details";

type TErrorResponse = IErrorPayment<keyof IPayloadValidateSepa["payload"]>[];

const FirstStepSepa = ({
  input,
  setStep,
}: {
  input: InputSepa;
  setStep: (step: number) => void;
}) => {
  const { dataFS, dataCommon } = input;
  const { canadaID, usID } = dataCommon;
  const countryID = dataFS.BCountry.value.id;

  const emptyFields: Partial<IDataFS> = {
    BCountry: getValueError({ text: "", id: "" }),
    BCity: getValueError(""),
    BPostalCode: getValueError(""),
    BStreetAddress: getValueError(""),
    BHouseNumber: getValueError(""),
    BAccountNumber: getValueError(""),
    BBic: getValueError(""),
  };

  return (
    <FirstStepCommon
      input={input}
      setStep={setStep}
      validateApi={PaymentAPI.sepa.validate}
      draftApi={PaymentAPI.sepa.draft}
      getErrorResponse={(errors) => getErrorResponse(errors as TErrorResponse)}
      content={(
        queryValidate,
        infoValidateQuery,
        queryDraft,
        infoDraftQuery
      ) => {
        return (
          <>
            <PaymentDetaisSepa input={input} />
            <PaymentBeneficiaryCommon
              input={input}
              companyValidationRules={{
                isReq: true,
                min: 3,
                max: 100,
              }}
              emptyFields={emptyFields}
            >
              {dataCommon.isRequireAddress && (
                <>
                  <FirstRowBeneficiarySepa
                    input={input}
                    isUS={countryID === usID}
                    isCanada={countryID === canadaID}
                  />
                  <SecondRowBeneficiarySepa
                    input={input}
                    isUS={countryID === usID}
                    isCanada={countryID === canadaID}
                  />
                </>
              )}
              <ThirdRowBeneficiarySepa input={input} />
            </PaymentBeneficiaryCommon>
            <TransferDocumentCommon
              input={input}
              disabled={infoValidateQuery.isLoading}
              lookAtIsRequireFiles={false}
            />
            <CommonContinueButton<IDataFS, IDataCommon>
              input={input}
              queryValidate={(dataFS, dataCommon) => {
                const countryId = dataFS.BCountry.value.id;
                return queryValidate({
                  id: dataCommon.paymentID,
                  payload: getPayload(
                    dataFS,
                    dataCommon.isRequireAddress,
                    dataCommon.accountId,
                    dataCommon.canadaID == countryId,
                    dataCommon.usID == countryId,
                    dataCommon.useCompanyNameInDescriptionControl
                  ),
                });
              }}
              isLoadingValid={infoValidateQuery.isLoading}
              queryDraft={(dataFS, dataCommon, autoSave) => {
                const countryId = dataFS.BCountry.value.id;
                return queryDraft({
                  id: dataCommon.paymentID,
                  payload: {
                    ...getPayload(
                      dataFS,
                      dataCommon.isRequireAddress,
                      dataCommon.accountId,
                      dataCommon.canadaID == countryId,
                      dataCommon.usID == countryId,
                      dataCommon.useCompanyNameInDescriptionControl
                    ),
                    autoSave,
                  },
                });
              }}
              isLoadingDraft={infoDraftQuery.isLoading}
            />
          </>
        );
      }}
    />
  );
};

export default FirstStepSepa;
