import { Route } from "react-router-dom";
import StatementID from "../../pages/statementID";
import StatementsNewPayment from "../../pages/statementsNewPayment";
import PagesAPI from "../../services/pagesAPI";

const StatementRoutes = () => {
  return (
    <Route path="/statements">
      <Route
        path=""
        element={
          <StatementsNewPayment
            openType="statements"
            promise={PagesAPI.statements}
          />
        }
      />
      <Route path="view" element={<StatementID />} />
    </Route>
  );
};

export default StatementRoutes;
