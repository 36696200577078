import styled from "styled-components";
import { Text } from "../../commonStyle";

export const TextModalCred = styled(Text)`
  text-align: center;

  &:first-of-type {
    margin-bottom: 10px;
  }

  &:nth-of-type(2) {
    & > div {
      align-items: start;

      & > div:last-of-type {
        align-self: self-end;
      }
    }
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 20px;
  margin-top: 20px;
  & > button.ant-btn {
    padding: 6.4px 40px;
  }

  & button.ant-btn {
    width: 100%;
  }

  & > div > div > div:first-of-type {
    margin-bottom: 12px;
  }
`;
