import instance from "..";
import { IPayloadFileUpload } from "../pagesAPI/types/payload";
import {
  IResponseFileDelete,
  IResponseFileUpload,
} from "../pagesAPI/types/response";
import {
  IPayloadExecute,
  IPayloadFileDelete,
  IPayloadPendingAuth,
  IPayloadPendingExecute,
  IPayloadValidate,
} from "./types/payload";
import {
  IResponseAuth,
  IResponseExecute,
  IResponseInit,
  IResponseInitTable,
  IResponseValidate,
} from "./types/response";

export const BulkTransferAPI = {
  pending: {
    init: () => instance.get<IResponseInitTable>("/app/init-bulk-pending"),
    authorize: (payload: IPayloadPendingAuth) =>
      instance.post<IResponseAuth>("/transfers/saved/authorize", payload),
    execute: (payload: IPayloadPendingExecute) =>
      instance.post<IResponseExecute>("/transfers/saved/execute", payload),
    delete: (payload: IPayloadPendingAuth) =>
      instance.post("/transfers/saved/delete", payload),
  },
  initImport: () => instance.get<IResponseInit>("/transfers/init/bulk-import"),
  valadate: (payload: IPayloadValidate) =>
    instance.post<IResponseValidate>(
      "/transfers/validate/bulk-import/" + payload.importId,
      payload
    ),
  execute: ({ importId }: IPayloadExecute) =>
    instance.post("/transfers/bulk-import/execute/" + importId),
  file: {
    upload: ({ formData, id }: IPayloadFileUpload) =>
      instance.post<IResponseFileUpload>(
        "/transfers/bulk-import/documents/upload/" + id,
        formData
      ),
    delete: ({ importId, fileId }: IPayloadFileDelete) =>
      instance.get<IResponseFileDelete>(
        `/transfers/bulk-import/documents/delete/${importId}/${fileId}`
      ),
  },
};
