import { FC, ReactNode } from "react";
import { TextBold } from "../../../commonStyle";
import * as Styled from "./style";

interface IProps {
  title?: string;
  titleOut?: string;
  children: ReactNode;
  isBorder?: boolean;
  onClick?: () => void;
}

const WrapperWhiteBG: FC<IProps> = ({
  title,
  children,
  isBorder = false,
  titleOut,
  onClick,
}) => {
  return (
    <div style={{ width: "100%" }}>
      {titleOut && (
        <Styled.Title fontSize={18} margin="0 0 8px 0">
          {titleOut}
        </Styled.Title>
      )}
      <Styled.Wrapper onClick={onClick} isBorder={isBorder}>
        {title && (
          <Styled.Title fontSize={18} margin="0 0 8px 0">
            {title}
          </Styled.Title>
        )}
        {children}
      </Styled.Wrapper>
    </div>
  );
};

export default WrapperWhiteBG;
