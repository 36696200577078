import { Button, InputRef } from "antd";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentAPI from "../../../services/paymentAPI";
import { changeOpenType, setIsQuestionLeave } from "../../../store/user";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import AuthTransfer from "../../authTransfer";
import { IValueError } from "../types";

export interface IRedirectOnPaymentCompletedData {
  navigateTo: string;
  openType: string;
}

export interface IPropsExecutePayment {
  amount: string;
  name: string;
  paymentId: string;
  checksum: string;
  navOnError?: string;
  setStep: (step: number) => void;
  onSuccess?: () => void;
  onError?: () => void;
  redirectOnPaymentCompletedData?: IRedirectOnPaymentCompletedData;
}

const ExecutePayment = forwardRef<InputRef, IPropsExecutePayment>(
  (
    {
      amount,
      name,
      setStep,
      paymentId,
      checksum,
      navOnError,
      redirectOnPaymentCompletedData,
    },
    ref
  ) => {
    const [executeQuery, infoExecuteQuery] = useMutationAPI(PaymentAPI.execute);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
      if (infoExecuteQuery.error) {
        if (navOnError) {
          navigate(navOnError);
          dispatch(setIsQuestionLeave(false));
        }
      }
      if (infoExecuteQuery.data) {
        const { accountId } = infoExecuteQuery.data.data;
        navigate(
          redirectOnPaymentCompletedData?.navigateTo ?? "/statements/view",
          { state: { accountId } }
        );
        dispatch(
          changeOpenType({
            openType: redirectOnPaymentCompletedData?.openType ?? "statements",
          })
        );
        dispatch(setIsQuestionLeave(false));
      }
    }, [infoExecuteQuery]);

    const onClickExecute = (auth: IValueError<string>) => {
      if (!!auth.error.length) return;
      executeQuery({
        paymentId,
        checksum,
        auth_code: auth.value,
      });
    };

    return (
      <AuthTransfer
        amount={amount}
        name={name}
        onClick={onClickExecute}
        isLoadingAuth={infoExecuteQuery.isLoading}
        ref={ref}
      >
        <Button
          shape="round"
          size="large"
          onClick={() => setStep(1)}
          disabled={infoExecuteQuery.isLoading}
        >
          Edit payment details
        </Button>
      </AuthTransfer>
    );
  }
);

export default ExecutePayment;
