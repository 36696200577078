import { Button } from "antd";
import { FC, useContext, useEffect, useId } from "react";
import { ContextMassPayment } from "../../..";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { MassPaymentAPI } from "../../../../../services/massPaymentAPI";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { TypeToText } from "../../../../../utils/typeToText";
import * as Styled from "./../style";

interface IProps {
  setIsAble: (status: boolean) => void;
}

const SSMPSummary: FC<IProps> = ({ setIsAble }) => {
  const dataContext = useContext(ContextMassPayment);
  const idComponent = useId();
  if (!dataContext) return <div />;

  const [queryAuth, infoAuthQuery] = useMutationAPI(MassPaymentAPI.authorize);

  const { data, setStep, setData, typeQuery } = dataContext;
  const { secondStepSummary, hasErrors, importId } = data;

  useEffect(() => {
    setIsAble(!infoAuthQuery.isLoading);
    const response = infoAuthQuery.data;
    if (response && response.data.result) {
      const { type: typeAuth, payment } = response.data;
      setData((prev) => ({
        ...prev,
        typeAuth,
        dataThirdStep: {
          amount: payment.paymentAmountFormatted,
          name: payment.paymentName,
        },
      }));
      setStep(3);
    }
  }, [infoAuthQuery]);

  return (
    <WrapperWhiteBG title="SUMMARY">
      <TypeToTextMap data={secondStepSummary} />
      <Styled.WrapperButton justifyContent="end">
        {!hasErrors && (
          <Button
            type="primary"
            shape="round"
            size="large"
            loading={infoAuthQuery.isLoading}
            onClick={() => queryAuth({ importId, typeQuery })}
          >
            Authorize
          </Button>
        )}
      </Styled.WrapperButton>
    </WrapperWhiteBG>
  );
};

export default SSMPSummary;
