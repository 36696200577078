import styled from "styled-components";

export const WrapperTable = styled.div`
  margin-top: 50px;
`;

export const WrapperDowload = styled.div`
  display: flex;
  justify-content: end;
  margin: 32px 0 24px;
`;
