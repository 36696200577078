import { FAVICON_PATHS, STYLE_PATHS } from "../../assets";

export const createMainImports = () => {
  // Find <head>
  const head = document.head;

  // Add fonts to <head>
  const fontsLink = document.createElement("link");

  fontsLink.type = "text/css";
  fontsLink.rel = "stylesheet";
  fontsLink.href = STYLE_PATHS.fonts;

  head.appendChild(fontsLink);

  // Add antd to <head>
  // const antDesignLink = document.createElement("link");

  // antDesignLink.type = "text/css";
  // antDesignLink.rel = "stylesheet";
  //  antDesignLink.href = STYLE_PATHS.antd;

  head.appendChild(fontsLink);

  // Add favicons to <head>
  const favicon = document.createElement("link");

  favicon.type = "image/x-icon";
  favicon.rel = "shortcut icon";
  favicon.href = FAVICON_PATHS.favicon;

  head.appendChild(favicon);

  // Add title to <head>
  document.title = process.env.REACT_APP_PROJECT_TITLE || "Default title";
};
