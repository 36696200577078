import { NavigateFunction } from "react-router-dom";
import { IItemPicker } from "../../component/Global/Picker";
import { ITable } from "../../component/table/types";
import ActionsTableComponent from "../../component/tableActionsPicker";
import { IDataInit } from "../../services/pendingPayments/types";
import { TOnSelectAuth } from "./types";

const getUrlActions = (action: string) => {
  switch (action) {
    case "faster-payments": {
      return "/new-payment/uk";
    }
    case "crypto-to-crypto":
    case "crypto-to-fiat": {
      return `/crypto/${action}`;
    }
    case "fiat-to-crypto":
    case "fiat-to-crypto-fx": {
      return `/new-payment/crypto`;
    }
    default: {
      return `/new-payment/${action}`;
    }
  }
};

export const getDataTable = (
  dataRow: IDataInit[],
  width: number,
  onSelect: TOnSelectAuth
): ITable[] => [
  {
    title: "Date",
    width: "10%",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.date),
  },
  {
    title: "Account",
    width: "30%",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.account),
  },
  {
    title: "Beneficiary / Details",
    width: "20%",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => [
      ...item.counterparty,
      ...item.description,
    ]),
  },
  {
    title: "Amount",
    width: "15%",
    positionText: width > 1024 ? "right" : "left",
    dataColumn: dataRow.map(({ item }) => item.amount),
  },
  {
    title: "Actions",
    width: "25%",
    positionText: width > 1024 ? "right" : "left",
    dataColumn: dataRow.map(({ paymentTransferType, paymentId, item }) =>
      item.actions.map((line) =>
        line.map((item) => (
          <ActionsTableComponent
            {...item}
            setValue={({ id }) =>
              onSelect({
                id: paymentId,
                mode: id,
                text: getUrlActions(paymentTransferType),
                type: paymentTransferType,
              })
            }
          />
        ))
      )
    ),
  },
];

export const getDataTableWithoutActions = (
  dataRow: IDataInit[],
  width: number
): ITable[] => [
  {
    title: "Date",
    width: "10%",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.date),
  },
  {
    title: "Account",
    width: "30%",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.account),
  },
  {
    title: "Beneficiary",
    width: "25%",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.counterparty),
  },
  {
    title: "Payment",
    width: "15%",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.description),
  },
  {
    title: "Amount",
    width: "20%",
    positionText: width > 1024 ? "right" : "left",
    dataColumn: dataRow.map(({ item }) => item.amount),
  },
];
