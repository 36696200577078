import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 10px 0;
`;

export const WrapperButton = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
