import { NavigateFunction } from "react-router-dom";
import ButtonToAntd, { TOnClickButtonToAntd } from "../../component/Button";
import { IButtonAPI } from "../../services/types";
import { IRowTableStatements } from "../../services/types/table";

const getButton = (
  dataButton: IButtonAPI,
  navigate: NavigateFunction,
  key: string
) => {
  const onClick: TOnClickButtonToAntd = ({ action, data, ddChose }) => {
    switch (action) {
      case "fiat-to-crypto":
      case "fiat-to-crypto-fx": {
        navigate("/new-payment/crypto", {
          state: { ...data, nameCrypto: ddChose?.text, type: action },
        });
        return;
      }

      case "view-statement": {
        navigate("/statements/view", { state: { ...data } });
        return;
      }

      default: {
        navigate("/new-payment/" + action, { state: { ...data } });
      }
    }
  };

  return <ButtonToAntd {...dataButton} onClick={onClick} key={key} />;
};

export const responseToData = (
  responseTable: IRowTableStatements[],
  navigate: NavigateFunction,
  id: string
) => {
  return responseTable.map(({ item }, index) => {
    const key = id + "." + index;
    return {
      firstColumnData: item.description,
      score: item.balance,
      buttons: item.buttons.map((props, index) =>
        getButton(props, navigate, key + "." + index)
      ),
      params: item.params,
    };
  });
};
