import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import { PAYMENT_DESCRIPTION_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

const rulesPaymentDescription: IRulesString = { isReq: true, min: 3, max: 100 };
const rulesPaymentReference: IRulesString = { isReq: true, min: 3, max: 18 };

type IDataFS = {
  DPaymentDescription: IValueError<string>;
  DIsCompanyName: IValueError<boolean>;
};

type IDataCommon = {
  prefixDescription: string;
  useCompanyNameInDescriptionControl: boolean;
};

interface Input {
  dataFS: IDataFS;
  dataCommon: IDataCommon;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const PaymentDescriptionInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    dataCommon,
    formActions: { prepareStringRulesField },
  },
  isPaymentReference = false,
  useCompanyNameInDescriptionControl,
}: {
  input: Input;
  isPaymentReference?: boolean;
  useCompanyNameInDescriptionControl?: boolean;
}) => {
  const isuseCompanyNameInDescriptionControl =
    useCompanyNameInDescriptionControl ??
    dataCommon.useCompanyNameInDescriptionControl;

  return (
    <InputComponent
      {...dataFS.DPaymentDescription}
      onChangeValue={(value) => {
        onChangeDataFS("DPaymentDescription", value);
      }}
      title={`Payment ${isPaymentReference ? "reference" : "description"}:`}
      addonBefore={
        dataFS.DIsCompanyName.value && isuseCompanyNameInDescriptionControl
          ? dataCommon.prefixDescription
          : ""
      }
      fieldActions={prepareStringRulesField(
        "DPaymentDescription",
        isPaymentReference ? rulesPaymentReference : rulesPaymentDescription
      )}
      maxLength={
        isPaymentReference
          ? rulesPaymentReference.max
          : rulesPaymentDescription.max
      }
      regex={PAYMENT_DESCRIPTION_REGEX}
      onLostFocusActions={[
        LostFocusActions.REPLACE_WHITESPACES,
        LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
      ]}
      id="DPaymentDescription"
    />
  );
};
