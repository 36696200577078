import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IItemPicker } from "../../component/Global/Picker";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import { CardAPI } from "../../services/cards";
import { changeOpenType } from "../../store/user";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import { initDataTable } from "./data";
import CardRequest from "./request";
import CardsTable from "./table";

const CardComponent = () => {
  const [queryInit, infoInitQuery] = useMutationAPI(CardAPI.init, {
    changeIsLoading: true,
  });

  const [dataRequest, setDataRequest] = useState<IItemPicker[]>([]);
  const [dataTable, setDataTable] = useState(initDataTable);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeOpenType({ openType: "cards" }));
    queryInit({});
  }, []);

  useEffect(() => {
    const response = infoInitQuery.data;

    if (response) {
      const { companies_list_for_card_request, cards, templates } =
        response.data;
      const dataReq = companies_list_for_card_request.dropdown_options.data.map(
        ({ text, value }) => ({ text, id: value })
      );

      setDataRequest(dataReq);
      setDataTable({ cards, templates });
    }
  }, [infoInitQuery]);

  return (
    <WrapperBodyTitle {...infoInitQuery.data?.data.page?.header}>
      {!!dataRequest.length && <CardRequest dataPicker={dataRequest} />}
      <div style={{ marginTop: !!dataRequest.length ? "30px" : "0px" }}>
        <CardsTable {...dataTable} refetchData={() => queryInit({})} />
      </div>
    </WrapperBodyTitle>
  );
};

export default CardComponent;
