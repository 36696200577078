import styled from "styled-components";

interface IWrapper {
  gap: number;
}

export const Wrapper = styled.div<IWrapper>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => `${gap}px 0`};
`;

interface IWrapperDataLine {
  position: "start" | "center" | "end";
  gap: number;
}

export const WrapperDataLine = styled.div<IWrapperDataLine>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${({ position }) => position};
  gap: ${({ gap }) => `${gap}px`};
`;
