import styled from "styled-components";

interface IWrapperIcon {
  widthIcon: number;
}

export const WrapperIcon = styled.div<IWrapperIcon>`
  & svg {
    width: ${({ widthIcon }) => widthIcon + "px"};
    height: auto;
  }
`;

export const WrapperTextIcon = styled.div<IWrapperIcon>`
  display: flex;
  align-items: center;
  gap: 8px;
`;
