import { Modal, Skeleton } from "antd";
import { FC, useEffect, useId, useMemo, useState } from "react";
import TextCopyable from "../../../component/Global/TextCopyable";
import { CryptoAPI } from "../../../services/crypto";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import * as Styled from "./style";
import { IWallets } from "../../../services/crypto/types";
import SwitchComponent from "../../../component/Global/Switch";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import TypeToTextMap from "../../../component/Global/TypeToTextMap";

interface IProps {
  onCancel: () => void;
  accountId: string;
  realoadPage: () => void;
}

const GenerateModal: FC<IProps> = ({ onCancel, accountId, realoadPage }) => {
  const infoQuery = useQueryAPI(CryptoAPI.generateAdressAgreement, {
    payload: { accountId },
  });

  const [queryDelete, infoDeleteQuery] = useMutationAPI(CryptoAPI.deleteAdress);
  const [queryGenerate, infoGenerateQuery] = useMutationAPI(
    CryptoAPI.generateAdress
  );

  const [dataAdd, setDataAdd] = useState({
    coinbaseAddressId: "",
    text: "",
    qrcode: "",
  });
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    const response = infoGenerateQuery.data?.data;
    if (response) {
      const { coinbaseAddress: text, coinbaseAddressId, qrcode } = response;
      setDataAdd({ coinbaseAddressId, text, qrcode });
    }
  }, [infoGenerateQuery]);

  const response = useMemo(() => infoQuery.data?.data, [infoQuery]);

  const setConfirm = (value: boolean) => {
    setIsConfirm(value);
    if (value) {
      queryGenerate({ accountId });
    } else {
      queryDelete({
        coinbaseAddressId: dataAdd.coinbaseAddressId,
      });
    }
  };

  return (
    <Modal
      open={true}
      onCancel={() => {
        if (isConfirm) realoadPage();
        onCancel();
      }}
    >
      <Styled.Wrapper>
        {infoQuery.isLoading && <Skeleton paragraph={{ rows: 10 }} />}

        {response && (
          <>
            <Styled.Info fontSize={22}>{response.title}</Styled.Info>

            <Styled.WrapperData>
              <TypeToTextMap data={response.data} gapLines={8} />
            </Styled.WrapperData>

            <Styled.WrapperSwitch>
              <SwitchComponent
                checked={isConfirm}
                onChange={setConfirm}
                text={response.walletGenerationAgreement}
                disabled={
                  infoGenerateQuery.isLoading || infoDeleteQuery.isLoading
                }
                textChange
                iniline
              />
            </Styled.WrapperSwitch>
          </>
        )}
        {infoGenerateQuery.isLoading && (
          <Skeleton paragraph={{ rows: 5 }} style={{ marginTop: "12px" }} />
        )}
        {response && isConfirm && !infoGenerateQuery.isLoading && (
          <>
            <img
              style={{ width: "200px", marginTop: "16px" }}
              src={"data:image/png;base64," + dataAdd.qrcode}
              alt="QR Code"
            />
            <TextCopyable
              text={dataAdd.text}
              messageText={dataAdd.text}
              style={{ marginTop: "20px" }}
            />
          </>
        )}
      </Styled.Wrapper>
    </Modal>
  );
};

export default GenerateModal;
