import { FC } from "react";
import { Text } from "../../../commonStyle";
import * as Styled from "./style";

interface IProps {
  QRCode: string;
}

const LSSQRCode: FC<IProps> = ({ QRCode }) => {
  return (
    <Styled.Wrapper>
      <Styled.WrapperImg>
        <img src={`data:image/svg+xml;base64,` + QRCode} />
      </Styled.WrapperImg>
      <Text textAling="center">
        Please scan QR code with Google Authenticator mobile app and enter the
        result code below.
      </Text>
    </Styled.Wrapper>
  );
};

export default LSSQRCode;
