import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 16px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  gap: 0 15px;

  button {
    max-width: 150px;
    width: 100%;
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

export const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: #e5e5e5;
`;
