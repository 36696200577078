import { Route } from "react-router-dom";
import CryptoComponent from "../../pages/crypto";
import SellCrypto from "../../pages/crypto/sell";
import CryptoSend from "../../pages/crypto/send";


const CryptoRoutes = ()=>{

  return (
    <Route path="/crypto">
      <Route path="" element={<CryptoComponent />} />
      <Route path="crypto-to-fiat" element={<SellCrypto />} />
      <Route path="crypto-to-crypto" element={<CryptoSend />} />
    </Route>
  );
}

export default CryptoRoutes