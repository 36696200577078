import {
  CopyOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Checkbox } from "antd";
import { FC } from "react";
import TILastIcon from "../lastIcon";
import * as Styled from "./style";

interface IProps {
  id?: string;
  isOpen: boolean;
  onSelect: (id: string) => void;
  checked: boolean;
  isDetails: boolean;
  onDuplicate?: () => void;
}

const getIconInfo = (isOpen: boolean) => {
  return isOpen ? <MinusSquareOutlined /> : <PlusSquareOutlined />;
};

const TIFirstIcon: FC<IProps> = ({
  id,
  isOpen,
  isDetails,
  onSelect,
  checked,
  onDuplicate,
}) => {
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!id) return;
    onSelect(id);
  };

  return (
    <Styled.Wrapper>
      <span>{id && <Checkbox onClick={onClick} checked={checked} />}</span>
      <span>
        {isDetails && (
          <Styled.WrapperDetails>{getIconInfo(isOpen)}</Styled.WrapperDetails>
        )}
      </span>
      {onDuplicate && (
        <Styled.WrapperDuplicate>
          <CopyOutlined onClick={onDuplicate} />
        </Styled.WrapperDuplicate>
      )}
    </Styled.Wrapper>
  );
};

export default TIFirstIcon;
