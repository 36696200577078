import { IPayloadValidateCrypto } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { IDataFS } from "../types";

const keyResponse: {
  [key in keyof Required<IPayloadValidateCrypto["payload"]>]: keyof IDataFS;
} = {
  paymentAmount: "DAmount",
  accountId: "DAmount",
  cryptoCurrencyCode: "DWallet",
  paymentReference: "DWallet",
  flagWalletConsent: "DIsConfirm",
  paymentDescription: "DReason",
};

export const getErrorResponse = (
  errorProps: IErrorPayment<keyof IPayloadValidateCrypto["payload"]>[]
) =>
  errorProps.map(({ field, message }) => ({
    key: keyResponse[field],
    error: message,
  }));

