import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  & > div {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: center;
  }
`;

export const WrapperButton = styled.div`
  margin-top: 20px;
`;
