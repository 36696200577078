import { createContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WrapperPayment from "../../../component/Wrappers/wrapperPayment";
import { CryptoAPI } from "../../../services/crypto";

import { IItemTableAPI } from "../../../services/types/table";
import { getChangeError } from "../../../utils/getChangeError";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import useSizePage from "../../../utils/hookSizePage";
import {
  initDataCommon,
  initSecondStep,
  initFisrtStep,
  initThirdStep,
  initContext,
} from "./data";
import CryptoSellFS from "./firstStep";
import { getDecimal } from "./firstStep/data";
import CryptoSellSS from "./secondStep";
import CryptoSellTS from "./thirdStep";
import { useQuestionBeforeLeave } from "../../../utils/questionBeforeLeave";

interface ILocation {
  state: {
    accountId?: string;
    paymentId?: string;
  };
}

export const CryptoSellContext = createContext(initContext);

const SellCrypto = () => {
  const navigate = useNavigate();
  const { accountId, paymentId } = (useLocation() as ILocation).state || {};
  const { width } = useSizePage();

  const infoInitQuery = useQueryAPI(CryptoAPI.sell.init, {
    payload: { id: accountId || paymentId || "", isEdit: !!paymentId?.length },
    changeIsLoading: true,
  });

  const [step, setStep] = useState(1);
  const [subTitle, setSubTitle] = useState<IItemTableAPI[][]>([]);
  const [dataCommon, setDataCommon] = useState(initDataCommon);
  const [dataFirst, setDataFirst] = useState(initFisrtStep);
  const [dataSecond, setDataSecond] = useState(initSecondStep);
  const [dataThird, setDataThird] = useState(initThirdStep);

  const { onChangeData, onErrorData } = getChangeError(setDataFirst);

  const onFinishLoading = useQuestionBeforeLeave({ deps: [dataFirst] });

  useEffect(() => {
    if (accountId === undefined && paymentId === undefined)
      navigate("/new-payment");
  }, []);

  useEffect(() => {
    const response = infoInitQuery.data?.data;

    if (response) {
      const { header, transfer, account } = response;

      const {
        beneficiary: dataBenef,
        confirmCryptoSellNote: dataConfirm,
        paymentId,
        fiatAccountId,
        fiatCurrencyId,
      } = transfer;
      const { balance, currencySymbol, currencyCode, accountId } = account;

      setSubTitle(header);
      setDataCommon((prev) => ({
        ...prev,
        dataBenef,
        dataConfirm,
        balance: balance.available,
        currencySymbol,
        paymentId,
        currencyCode,
        accountId,
        fiatAccountId,
        fiatCurrencyId,
      }));

      onChangeData("amount", (0).toFixed(getDecimal(currencyCode)));
      setTimeout(() => onFinishLoading(false), 1000);
    }
  }, [infoInitQuery]);

  return (
    <CryptoSellContext.Provider
      value={{
        step,
        setStep,
        dataFirst,
        setDataFirst,
        dataSecond,
        setDataSecond,
        dataCommon,
        setDataCommon,
        dataThird,
        setDataThird,
        onChangeData,
        onErrorData,
      }}
    >
      <WrapperPayment
        {...infoInitQuery.data?.data.page?.header}
        step={step}
        viewStep={[
          width > 1024 ? "Fill out" : "Fill",
          width > 1024 ? "Authorize" : "Auth",
          "Review",
        ]}
      >
        <CryptoSellFS />
        <CryptoSellSS />
        <CryptoSellTS />
      </WrapperPayment>
    </CryptoSellContext.Provider>
  );
};

export default SellCrypto;
