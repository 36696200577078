import InputComponent from "../../../../../component/Global/Input";
import { NUMERIC_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

const rulesABA: IRulesString = { min: 9, max: 9, isReq: true };

type IDataFS = {
  BAba: IValueError<string>;
};

interface Input {
  dataFS: IDataFS;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const ABAInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: Input;
}) => {
  return (
    <InputComponent
      {...dataFS.BAba}
      onChangeValue={(value) => onChangeDataFS("BAba", value)}
      title="ABA:"
      fieldActions={prepareStringRulesField("BAba", rulesABA)}
      id="BAba"
      maxLength={rulesABA.max}
      regex={NUMERIC_REGEX}
    />
  );
};
