import TransferDocument from "../../../../../component/Global/transferDocument";
import {
  IFileToFormData,
  IFileTransferDocument,
} from "../../../../../component/Global/transferDocument/types";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { IValueError } from "../../../../../component/payment/types";
import PagesAPI from "../../../../../services/pagesAPI";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { TMyDispatch, TOnChangeDataFS, TSetErrorDataFS } from "../../../types";

type IDataFS = {
  Tfiles: IValueError<IFileTransferDocument[]>;
  DIsInterTransfer?: IValueError<boolean>;
};

interface IDataCommon {
  paymentID: string;
  isRequireFiles: boolean;
}

interface Input<DataFS extends IDataFS, DataCommon extends IDataCommon> {
  dataFS: DataFS;
  dataCommon: DataCommon;
  onChangeDataFS: TOnChangeDataFS<DataFS>;
  setErrorDataFS: TSetErrorDataFS<DataFS>;
  setDataCommon: TMyDispatch<DataCommon>;
}

const TransferDocumentCommon = <
  DataFS extends IDataFS,
  DataCommon extends IDataCommon
>({
  disabled,
  input,
  lookAtIsRequireFiles = true,
}: {
  input: Input<DataFS, DataCommon>;
  disabled: boolean;
  lookAtIsRequireFiles?: boolean;
}) => {
  const { dataFS, dataCommon, onChangeDataFS, setErrorDataFS, setDataCommon } =
    input;
  const { Tfiles } = dataFS;
  const { isRequireFiles, paymentID } = dataCommon;

  const [queryUpload] = useMutationAPI(PagesAPI.file.upload);
  const [queryDelete] = useMutationAPI(PagesAPI.file.delete);

  const onDeleteFiles = async (id: string) => {
    const response = await queryDelete({ id });

    if (response.data) return response.data.data.result;
    return false;
  };

  const onUploadFiles = async (data: IFileToFormData[]) => {
    const response = await Promise.all(
      data.map(({ formData }) => queryUpload({ formData, id: paymentID }))
    );

    return response.map(({ data: response, error: responseError }) => {
      if (response && response.data.status === "done") {
        const { name, documentId: id, url } = response.data;
        return { name, id, isLoading: false, url };
      }
      if (responseError && responseError.response) {
        return "";
      }
      if (response && response.data.status === "file_exists") {
        return "";
      }
      return "Unknow error";
    });
  };

  let isInterTransferBlocking: boolean;
  if (dataFS.DIsInterTransfer) {
    isInterTransferBlocking = !(dataFS.DIsInterTransfer.value as boolean);
  } else {
    isInterTransferBlocking = true;
  }

  return (
    <WrapperWhiteBG title="Payment Documents">
      <TransferDocument
        {...Tfiles}
        id="Tfiles"
        setValue={(value) => onChangeDataFS("Tfiles", value)}
        setError={(error) => setErrorDataFS("Tfiles", error)}
        onUpload={onUploadFiles}
        onDelete={onDeleteFiles}
        setIsLoading={(isLoadingFiles) =>
          setDataCommon((prev) => ({ ...prev, isLoadingFiles }))
        }
        isAble={!disabled}
        isRequired={
          (!lookAtIsRequireFiles || isRequireFiles) && isInterTransferBlocking
        }
        accept=".jpeg, .jpg, .png, .pdf, .doc, .docx, .xls, .xlsx"
        multiple
      />
    </WrapperWhiteBG>
  );
};

export default TransferDocumentCommon;
