import { Button, Modal, Skeleton } from "antd";
import { FC, useEffect, useState } from "react";
import { Text } from "../../../../../commonStyle";
import InputComponent from "../../../../../component/Global/Input";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import { CardAPI } from "../../../../../services/cards";
import { IItemTableAPI } from "../../../../../services/types/table";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { useAppSelector } from "../../../../../utils/useState";
import validateString from "../../../../../utils/validate/validateString";
import * as Styled from "./style";

interface IProps {
  data: IItemTableAPI[][];
  cardId: string;
  onCancel: () => void;
  template: {
    title: string;
    data: IItemTableAPI[][];
  };
  refetchData: () => void;
}

const CTSCActivateCard: FC<IProps> = ({
  data,
  cardId,
  template,
  onCancel,
  refetchData,
}) => {
  const [queryActivate, infoActivateQuery] = useMutationAPI(
    CardAPI.table.activateCard
  );

  useEffect(() => {
    if (infoActivateQuery.data) {
      onCancel();
      refetchData();
    }
  }, [infoActivateQuery]);

  const onClickBlock = () => {
    queryActivate({ cardId });
  };

  return (
    <Modal open={true} onCancel={onCancel} width={478}>
      <Styled.Wrapper>
        <Text fontSize={20} margin="0 0 16px">
          {template.title}
        </Text>
        <Styled.WrapperItems>
          <TypeToTextMap data={data} position="center" />

          <TypeToTextMap
            data={template.data}
            gapLines={15}
            styleData={{ textAling: "center" }}
            position="center"
          />

          <Button
            shape="round"
            size="large"
            loading={infoActivateQuery.isLoading}
            onClick={onClickBlock}
          >
            Agree and Accept
          </Button>
        </Styled.WrapperItems>
      </Styled.Wrapper>
    </Modal>
  );
};

export default CTSCActivateCard;
