import { Empty } from "antd";
import { FC, useId } from "react";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { IDataSS } from "../../../types";

interface IProps {
  data: IDataSS["remitter"][];
}

const RPBSSItem: FC<IProps> = ({ data }) => {
  const idComponent = useId();
  return (
    <>
      {data.map(({title,data}, index) => {
        return (
          <div key={idComponent + index}>
            <WrapperWhiteBG title={title}>
              {!data.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              <TypeToTextMap data={data} />
            </WrapperWhiteBG>
          </div>
        );
      })}
    </>
  );
};

export default RPBSSItem;
