import { AlertProps, Input, InputProps, InputRef } from "antd";
import React, {
  ClipboardEvent,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from "react";
import WrapperTitleError from "../../Wrappers/wrapperTitleError";
import HintsInputComponent, { IDataHintsInput } from "./hints";
import * as Styled from "./style";
import {
  DASH_REGEX,
  UPPERCASE_FIRST_LETTERS_REGEX,
  WHITESPACES_REGEX,
} from "../../../utils/regex/common";
import {
  PreparedFieldActions,
  usePreparedFiled,
} from "../../../pages/payments/shared/firstStep/form";

export enum LostFocusActions {
  REPLACE_WHITESPACES,
  REPLACE_DASH_LIKE_CHARACTERS,
  REMOVE_DASH_LIKE_CHARACTERS,
  UPPERCASE_FIRST_LETTERS,
  REMOVE_WHITESPACES,
  UPPERCASE,
}

export interface IHintsInput<T = any> {
  onSelect: (data: T) => void;
  data: IDataHintsInput<T>[];
}

export interface IPropsInputComponent {
  value: string;
  type?: "number" | "password" | "text";
  onChangeValue?: (text: string) => void;
  addonBefore?: string;
  addonAfter?: string;
  error?: string;
  title?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  prefix?: ReactNode;
  onKeyEnter?: () => void;
  hints?: IHintsInput;
  maxLength?: number;
  info?: AlertProps[];
  width?: string;
  disabledError?: boolean;
  id?: string;
  size?: InputProps["size"];
  onLostFocusActions?: LostFocusActions[];
  regex?: RegExp;
  fieldActions?: PreparedFieldActions;
}

const InputComponent = forwardRef<InputRef, IPropsInputComponent>(
  (
    {
      error = "",
      title,
      addonBefore,
      addonAfter,
      value,
      onChangeValue,
      onBlur,
      onFocus,
      placeholder,
      onKeyEnter,
      hints,
      maxLength,
      prefix = "",
      info,
      width,
      disabledError,
      type = "text",
      id,
      size = "large",
      onLostFocusActions,
      regex,
      fieldActions,
    },
    ref
  ) => {
    const [isOpenHints, setIsOpenHints] = useState(false);
    const [shouldApplyLostFocusActions, setShouldApplyLostFocusActions] =
      useState(false);

    useEffect(() => {
      if (onChangeValue && maxLength && value.length > maxLength) {
        onChangeValue(value.slice(0, maxLength));
      }
    }, [value]);

    if (fieldActions) {
      usePreparedFiled(fieldActions);
    }

    const exhaustiveSwitch = (n: never) => {
      throw new Error();
    };

    const applyLostFocusActions = (text?: string) => {
      if (!onLostFocusActions || !onChangeValue) {
        return;
      }
      if (!text) {
        text = value;
      }

      for (const onLostFocusAction of onLostFocusActions) {
        switch (onLostFocusAction) {
          case LostFocusActions.REPLACE_WHITESPACES:
            text = text.replaceAll(WHITESPACES_REGEX, " ");
            break;
          case LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS:
            text = text.replaceAll(DASH_REGEX, "-");
            break;
          case LostFocusActions.REMOVE_DASH_LIKE_CHARACTERS:
            text = text.replaceAll(DASH_REGEX, "");
            break;
          case LostFocusActions.UPPERCASE_FIRST_LETTERS:
            text = text.replace(UPPERCASE_FIRST_LETTERS_REGEX, (letter) =>
              letter.toUpperCase()
            );
            break;
          case LostFocusActions.REMOVE_WHITESPACES:
            text = text.replaceAll(WHITESPACES_REGEX, "");
            break;
          case LostFocusActions.UPPERCASE:
            text = text.toUpperCase();
            break;
          default:
            exhaustiveSwitch(onLostFocusAction);
            break;
        }
      }

      onChangeValue(text);
    };

    const onFocusInput = () => {
      setIsOpenHints(true);
      if (onFocus) onFocus();
    };

    const onBlurInput = () => {
      applyLostFocusActions();
      setIsOpenHints(false);
      if (onBlur) onBlur();
      setTimeout(() => {
        fieldActions?.onBlur?.();
      });
    };

    const onPasteInput = (e: ClipboardEvent<HTMLInputElement>) => {
      if (onLostFocusActions) {
        setShouldApplyLostFocusActions(true);
      }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChangeValue) {
        let result = "";

        if (regex) {
          for (let i = 0; i < e.target.value.length; i++) {
            let char = e.target.value[i];

            if (regex.test(char)) {
              result = result + char;
            }
          }
        } else {
          result = e.target.value;
        }

        const finalResult = result.slice(0, maxLength);

        if (shouldApplyLostFocusActions) {
          setShouldApplyLostFocusActions(false);
          applyLostFocusActions(finalResult);
        } else {
          onChangeValue(finalResult);
        }
      }
    };

    const onClickEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter" && onKeyEnter) {
        onKeyEnter();
      }
    };

    const onSelectHint = (title: string, data: any) => {
      if (!onChangeValue) return;
      onChangeValue(title);
      if (hints) hints.onSelect(data);
    };

    return (
      <WrapperTitleError
        title={title}
        error={error}
        info={info}
        width={width}
        disabledError={disabledError}
        id={id}
      >
        <Styled.Wrapper>
          <Input
            value={value}
            onFocus={onFocusInput}
            onPaste={onPasteInput}
            onBlur={onBlurInput}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            prefix={prefix}
            placeholder={placeholder}
            ref={ref}
            onChange={onChange}
            onKeyDown={onClickEnter}
            type={type}
            size={size}
            status={!error.length ? "" : "error"}
          />
          {isOpenHints && hints && (
            <HintsInputComponent
              data={hints.data}
              onSelect={onSelectHint}
              value={value}
            />
          )}
        </Styled.Wrapper>
      </WrapperTitleError>
    );
  }
);

export default InputComponent;
