import { IPayloadValidateFx } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { IDataFS } from "../types";

const keyResponse: {
  [key in keyof Required<IPayloadValidateFx["payload"]>]: keyof IDataFS;
} = {
  paymentAccountNumberTo: "BAccountNumber",
  paymentBankBic: "BBic",
  paymentDescription: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
  paymentIsSameCompanyTransfer: "DIsInterTransfer",
  paymentName: "BCompany",
  paymentNameAddressCity: "BCity",
  paymentNameAddressNumber: "BHouseNumber",
  paymentNameAddressPostalCode: "BPostalCode",
  paymentNameAddressStreet: "BStreetAddress",
  paymentNameCountryId: "BCountry",
  accountId: "DAmount",
  fxPaymentAmount: "DAmount",
  fxPaymentCurrencyId: "DCurrency",
  paymentBankAba: "BAba",
  paymentBankInstitutionNumber: "BInstitutionNumber",
  paymentBankTransitNumber: "BTransitNumber",
  paymentNameAddressState: "BState",
  paymentBankSortCode: "BSortCode",
  paymentBankUkAccountNumber: "BUkAccountNumber",
};

export const getErrorResponse = (
  errorProps: IErrorPayment<keyof IPayloadValidateFx["payload"]>[]
): { key: keyof IDataFS; error: string }[] =>
  errorProps.flatMap(({ field, message }) => {
    if (field === "paymentNameAddressState") {
      return [
        {
          key: "BState",
          error: message,
        },
        {
          key: "BCaState",
          error: message,
        },
      ];
    } else {
      return [
        {
          key: keyResponse[field],
          error: message,
        },
      ];
    }
  });
