import PickerComponent, { IItemPicker } from "../../component/Global/Picker";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import { IDetailsAPI } from "../../services/types/table";
import { TypeToText } from "../typeToText";

export const getTableDetailsPicker = (
  dataProp: IDetailsAPI,
  onSelect?: (value: IItemPicker) => void
) => {
  const onSelectDefault = ({ id }: IItemPicker) => {
    const a = document.createElement("a");
    a.href = `${id}?jwt=${localStorage.getItem("token")}`;
    a.target = "_blank";
    a.click();
  };

  if (!dataProp.data.length) {
    return {
      ...dataProp,
      data: [],
    };
  }
  if (dataProp.data.length === 1) {
    const props: IItemPicker = {
      id: dataProp.data[0].map(({ url }) => url).join(""),
      text: dataProp.data[0].map(({ text }) => text).join(" "),
    };

    const onClickSingle = () => {
      if (onSelect) {
        onSelect(props);
        return;
      }
      onSelectDefault(props);
    };

    return {
      ...dataProp,
      data: [
        [
          <div onClick={onClickSingle}>
            <TypeToTextMap
              data={[
                dataProp.data[0].map((item) => {
                  return {
                    ...item,
                    url: undefined,
                  };
                }),
              ]}
              styleData={{ color: "rgb(108, 100, 244)", isCursor: true }}
            />
          </div>,
        ],
      ],
    };
  }

  return {
    ...dataProp,
    data: [
      [
        <PickerComponent
          value={{ id: "init", text: dataProp.title }}
          data={dataProp.data.map((item) => ({
            id: item.map(({ url }) => url).join(""),
            text: item.map(({ text }) => text).join(" "),
            component: <TypeToTextMap data={[item]} />,
          }))}
          setValue={onSelect || onSelectDefault}
          disabledError
        />,
      ],
    ],
  };
};
