import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px 0;
  & > * {
    width: 100% !important;
  }
`;
