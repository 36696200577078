import PaymentAPI from "../../../../services/paymentAPI";
import { IPayloadValidateEft } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { getValueError } from "../../data";
import FirstStepCommon from "../../shared/firstStep";
import PaymentBeneficiaryCommon from "../../shared/firstStep/beneficiary/companyInput";
import CommonContinueButton from "../../shared/firstStep/continueButton";
import TransferDocumentCommon from "../../shared/firstStep/transferDocument";
import { IDataCommon, IDataFS, InputEft } from "../types";
import BeneficiaryRowsEft from "./beneficiary/rows";
import { getPayload } from "./continueButton/data";
import { getErrorResponse } from "./data";
import PaymentDetaisEft from "./details";

type TErrorResponse = IErrorPayment<keyof IPayloadValidateEft["payload"]>[];

const FirstStepEft = ({
  input,
  setStep,
}: {
  input: InputEft;
  setStep: (step: number) => void;
}) => {
  const emptyFields: Partial<IDataFS> = {
    paymentAccountNumberTo: getValueError(""),
    paymentBankInstitutionNumber: getValueError(""),
    paymentBankTransitNumber: getValueError(""),
    paymentEmail: getValueError(""),
    paymentFirstName: getValueError(""),
    paymentLastName: getValueError(""),
  };

  return (
    <FirstStepCommon
      input={input}
      setStep={setStep}
      validateApi={PaymentAPI.eft.validate}
      draftApi={PaymentAPI.eft.draft}
      getErrorResponse={(errors) => getErrorResponse(errors as TErrorResponse)}
      content={(
        queryValidate,
        infoValidateQuery,
        queryDraft,
        infoDraftQuery
      ) => {
        return (
          <>
            <PaymentDetaisEft input={input} />
            <PaymentBeneficiaryCommon
              input={input}
              companyValidationRules={{ isReq: true, min: 3 }}
              emptyFields={emptyFields}
            >
              <BeneficiaryRowsEft input={input} />
            </PaymentBeneficiaryCommon>
            <TransferDocumentCommon
              input={input}
              disabled={infoValidateQuery.isLoading || infoDraftQuery.isLoading}
            />
            <CommonContinueButton<IDataFS, IDataCommon>
              input={input}
              queryValidate={(dataFS, dataCommon) => {
                return queryValidate({
                  id: dataCommon.paymentID,
                  payload: getPayload(
                    dataFS,
                    dataCommon.accountId,
                    dataCommon.useCompanyNameInDescriptionControl
                  ),
                });
              }}
              isLoadingValid={infoValidateQuery.isLoading}
              queryDraft={(dataFS, dataCommon, autoSave) => {
                return queryDraft({
                  id: dataCommon.paymentID,
                  payload: {
                    ...getPayload(
                      dataFS,
                      dataCommon.accountId,
                      dataCommon.useCompanyNameInDescriptionControl
                    ),
                    autoSave,
                  },
                });
              }}
              isLoadingDraft={infoDraftQuery.isLoading}
            />
          </>
        );
      }}
    />
  );
};

export default FirstStepEft;
