import { EllipsisOutlined } from "@ant-design/icons";
import { createRef, FC, useEffect, useState } from "react";
import { TextBold } from "../../commonStyle";
import { IDropdownAPI } from "../../services/types";
import PickerComponent, { IItemPicker } from "../Global/Picker";
import { getIconDropDown } from "./data";
import * as Styled from "./style";

interface IProps extends IDropdownAPI {
  setValue: (item: IItemPicker) => void;
}

const ActionsTableComponent: FC<IProps> = ({ dropdown_options, text, setValue }) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!ref.current) return;
    const { width, height } = ref.current.getBoundingClientRect();
    setSize({ width, height });
  }, [dropdown_options, text]);

  const onClickDiv = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  return (
    <div onClick={onClickDiv}>
      <div style={{ height: size.height + "px", width: size.width + "px" }}>
        <div ref={ref} style={{ position: "absolute" }}>
          <PickerComponent
            value={{ text, id: "init" }}
            data={dropdown_options.data.map(({ text, value: id }) => ({
              text,
              id,
              component: (
                <Styled.Wrapper>
                  {getIconDropDown(id)}
                  <TextBold fontSize={16}>{text}</TextBold>
                </Styled.Wrapper>
              ),
            }))}
            width="150px"
            icon={
              <EllipsisOutlined
                style={{ transform: "rotateZ(90deg)", color: "#6C64F4" }}
              />
            }
            setValue={setValue}
            isButton={true}
            isLoading={false}
          />
        </div>
      </div>
    </div>
  );
};


export default ActionsTableComponent;