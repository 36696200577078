import { useContext } from "react";
import { ContextCardRequest } from "../../..";
import TransferDocument from "../../../../../component/Global/transferDocument";
import { IFileToFormData } from "../../../../../component/Global/transferDocument/types";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { CardAPI } from "../../../../../services/cards";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import * as Styled from "./style";

const CRDocuments = () => {
  const valueContext = useContext(ContextCardRequest);

  const [queryUpload] = useMutationAPI(CardAPI.request.files.upload);
  const [queryDelete] = useMutationAPI(CardAPI.request.files.delete);

  const { dataFS, dataCommon, setDataCommon, onChangeDataFS, setErrorDataFS } =
    valueContext;
  const { DOCBill, DOCCompany, DOCPassport } = dataFS;
  const { cardRecordId } = dataCommon;

  const onDeleteFiles = async (id: string) => {
    setDataCommon((prev) => ({ ...prev, isLoadingFile: true }));

    const response = await queryDelete({ id });

    setDataCommon((prev) => ({ ...prev, isLoadingFile: false }));

    if (response.data) return response.data.data.result;
    return false;
  };

  const onUploadFiles = async (
    data: IFileToFormData[],
    path: "id" | "proof-of-address" | "other"
  ) => {
    setDataCommon((prev) => ({ ...prev, isLoadingFile: true }));

    const response = await Promise.all(
      data.map(({ formData }) => queryUpload({ formData, cardRecordId, path }))
    );

    setDataCommon((prev) => ({ ...prev, isLoadingFile: false }));

    return response.map(({ data: response, error: responseError }) => {
      if (response && response.data.status === "done") {
        const { name, documentId: id } = response.data;
        return { name, id, isLoading: false };
      }
      if (responseError && responseError.response) {
        return "";
      }
      if (response && response.data.status === "file_exists") {
        return "";
      }
      return "Unknow error";
    });
  };

  return (
    <WrapperWhiteBG title="Documents (optional)">
      <Styled.Wrapper>
        <TransferDocument
          {...DOCPassport}
          id="DOCPassport"
          setValue={(files) => onChangeDataFS("DOCPassport", files)}
          setError={(error) => setErrorDataFS("DOCPassport", error)}
          onUpload={(value) => onUploadFiles(value, "id")}
          onDelete={onDeleteFiles}
          title="Verified Passport or ID Copy:"
          accept=".jpeg, .jpg, .png, .docx, .pdf"
          isAble
          multiple
        />
        <TransferDocument
          {...DOCBill}
          id="DOCBill"
          setValue={(files) => onChangeDataFS("DOCBill", files)}
          setError={(error) => setErrorDataFS("DOCBill", error)}
          onUpload={(value) => onUploadFiles(value, "proof-of-address")}
          onDelete={onDeleteFiles}
          title="Verified Utility bill:"
          accept=".jpeg, .jpg, .png, .docx, .pdf"
          isAble
          multiple
        />
        <TransferDocument
          {...DOCCompany}
          id="DOCCompany"
          setValue={(files) => onChangeDataFS("DOCCompany", files)}
          setError={(error) => setErrorDataFS("DOCCompany", error)}
          onUpload={(value) => onUploadFiles(value, "other")}
          onDelete={onDeleteFiles}
          title="Documents showing your connection to the Company:"
          accept=".jpeg, .jpg, .png, .docx, .pdf"
          isAble
          multiple
        />
      </Styled.Wrapper>
    </WrapperWhiteBG>
  );
};

export default CRDocuments;
