import { IItemPicker } from "../../../../../component/Global/Picker";
import { IDataTableInvoiceAPI } from "../../../../../services/invoiceTemplateBuilder/types";
import { IPayloadInvoiceValid } from "../../../../../services/invoiceTemplateBuilder/types/payload";
import { IErrorPayment } from "../../../../../services/types";
import { genIndexedKey } from "../../../../../utils/getIndexedKey";
import validateNumber from "../../../../../utils/validate/validateNumber";
import validateString from "../../../../../utils/validate/validateString";
import { IDataCommon, IDataFS, ITableInvoiceBuilder } from "../../../types";

export const addressFields: (keyof IDataFS)[] = [
  "RemHouseNumber",
  "RemCity",
  "RemCountry",
  "RemPostalCode",
  "RemStreetAddress",
];

const getError = (key: keyof IDataFS, error = "") => {
  return { key, error };
};

export const validateData = (data: IDataFS, isUS: boolean) => {
  const keys = Object.keys(data) as (keyof IDataFS)[];

  const addressIsBeingFilled =
    addressFields.find((key) => {
      if (key === "RemCountry") {
        return data[key].value.id !== "";
      } else {
        return data[key].value !== "";
      }
    }) !== undefined;

  return keys.map((key) => {
    const { value } = data[key];

    if (addressFields.includes(key)) {
      if (!addressIsBeingFilled) {
        return getError(key);
      } else {
        if (key === "RemCountry") {
          return getError(
            key,
            validateString((value as IItemPicker).id, { isReq: true })
          );
        } else {
          return getError(
            key,
            validateString(value as string, { isReq: true })
          );
        }
      }
    }

    if (
      value instanceof Date ||
      (!isUS && key === "RemState") ||
      key === "DReference" ||
      key === "GLogo" ||
      key === "RemState"
    ) {
      return getError(key);
    }

    if (key == "RemCompanyName") {
      return getError(
        key,
        validateString(value as string, {
          isReq: true,
          min: 3,
        })
      );
    }

    if (key == "RemCity") {
      return getError(
        key,
        validateString(value as string, {
          min: 3,
          max: 50,
        })
      );
    }

    if (
      key == "RemStreetAddress" ||
      key == "RemHouseNumber" ||
      key == "RemPostalCode"
    ) {
      return getError(
        key,
        validateString(value as string, {
          min: 3,
          max: key === "RemPostalCode" || key === "RemHouseNumber" ? 10 : 50,
        })
      );
    }

    if (key == "RemPhone" || key == "RemEmail") {
      return getError(
        key,
        validateString(value as string, {
          min: 3,
          max: key === "RemEmail" ? 255 : 20,
          isEmail: key === "RemEmail",
        })
      );
    }

    if (typeof value === "string") {
      return getError(key, validateString(value, { isReq: true }));
    }

    return getError(key, validateString(value.id, { isReq: true }));
  });
};

export const validateCommonData = (
  dataCommon: IDataCommon
): { key: string; error: string }[] => {
  return dataCommon.dataTable.flatMap((row, index) =>
    (["title", "rate", "tax", "qty"] as (keyof ITableInvoiceBuilder)[]).map(
      (key) => {
        if (key === "tax") {
          return {
            key: genIndexedKey(key, index),
            error: validateNumber(parseFloat(row[key].value) || 1, {
              min: 0.01,
              max: 60,
            }),
          };
        }

        if (key === "qty") {
          return {
            key: genIndexedKey(key, index),
            error: validateNumber(parseFloat(row[key].value) || 0, {
              min: 1,
            }),
          };
        }

        if (key === "rate") {
          return {
            key: genIndexedKey(key, index),
            error: validateNumber(parseFloat(row[key].value) || 0, {
              min: 0.01,
            }),
          };
        }

        return {
          key: genIndexedKey(key, index),
          error: validateString(row[key].value, { isReq: true }),
        };
      }
    )
  );
};

export const getPayloadAPI = (
  data: IDataFS,
  dataTable: ITableInvoiceBuilder[],
  prefix: string
): IPayloadInvoiceValid["payload"] => {
  return {
    accountId: data.GAccount.value.id,
    invoiceReference: prefix + " " + data.DReference.value,
    remitterCity: data.RemCity.value,
    remitterCountryId: data.RemCountry.value.id,
    remitterState: data.RemState.value.id,
    remitterEmail: data.RemEmail.value,
    remitterHouseNumber: data.RemHouseNumber.value,
    remitterName: data.RemCompanyName.value,
    remitterPhone: data.RemPhone.value,
    remitterPostalCode: data.RemPostalCode.value,
    remitterStreetAddress: data.RemStreetAddress.value,
    taxationType: data.GTypeTax.value.id,
    invoiceData: dataTable.map(({ qty, rate, tax, title }) => ({
      invoiceItemDescription: title.value,
      invoiceItemQty: parseFloat(qty.value),
      invoiceItemRate: parseFloat(rate.value),
      invoiceItemTax: parseFloat(tax.value),
    })),
  };
};

const resToData: {
  [key in keyof IPayloadInvoiceValid["payload"]]: keyof IDataFS;
} = {
  accountId: "GAccount",
  invoiceData: "GTypeTax",
  invoiceReference: "DReference",
  remitterCity: "RemCity",
  remitterCountryId: "RemCountry",
  remitterEmail: "RemEmail",
  remitterHouseNumber: "RemHouseNumber",
  remitterName: "RemCompanyName",
  remitterPhone: "RemPhone",
  remitterPostalCode: "RemPostalCode",
  remitterStreetAddress: "RemStreetAddress",
  taxationType: "GTypeTax",
  remitterState: "RemState",
};

const resTableToData: {
  [key in keyof IDataTableInvoiceAPI]: keyof ITableInvoiceBuilder;
} = {
  invoiceItemDescription: "title",
  invoiceItemQty: "qty",
  invoiceItemRate: "rate",
  invoiceItemTax: "tax",
};

const getErrorsResToData = (
  errorsProps: IErrorPayment<keyof IPayloadInvoiceValid["payload"]>[]
) => {
  return errorsProps.map(({ field, message }) => ({
    key: resToData[field],
    error: message,
  }));
};

const getErrorTable = (errorsProps: IErrorPayment[]) => {
  return errorsProps.map(({ field, message }) => {
    const [_, index, resKey] = field.split(".");

    return {
      key: resTableToData[resKey as keyof IDataTableInvoiceAPI],
      index: parseInt(index),
      error: message,
    };
  });
};

export const getErrorResponse = (
  errorsProps: IErrorPayment<keyof IPayloadInvoiceValid["payload"]>[]
) => {
  const resErrorTable: IErrorPayment[] = [];
  const resError = errorsProps.filter((item) => {
    if (!item.field.includes("invoiceData")) return true;
    resErrorTable.push(item);
    return false;
  });

  const errorTable = getErrorTable(resErrorTable);
  const errors = getErrorsResToData(resError);

  return { errorTable, errors };
};
