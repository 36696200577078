import { TableProps } from "antd";
import { FC, useId } from "react";
import { TItemAntdTable } from "..";
import AntdMTBlock from "./block";

const AntdMobileTable: FC<TableProps<TItemAntdTable>> = ({
  dataSource = [],
  columns = [],
}) => {
  const idComponent = useId();
  return (
    <div>
      {dataSource.map((item, index) => (
        <AntdMTBlock key={idComponent + index} item={item} columns={columns} />
      ))}
    </div>
  );
};

export default AntdMobileTable;
