import { createContext, useEffect, useState } from "react";
import WrapperPayment from "../../component/Wrappers/wrapperPayment";
import { CryptoFiatPayInApi } from "../../services/cryptoFiatPayIn";
import { getValuesDataFS } from "../../utils/getValuesDataFS";
import { useQuestionBeforeLeave } from "../../utils/questionBeforeLeave";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import { formActionsDefault, useForm } from "../payments/shared/firstStep/form";
import { TOnChangeDataFS, TSetErrorDataFS } from "../payments/types";
import { dataCommonDefault, dataContext, getDataBenefAccount } from "./data";
import CryptoFiatPayInFirstStep from "./steps/first";
import CryptoFiatPayInSecondStep from "./steps/second";
import {
  CryptoFiatInput,
  DataCommonCryptoFiat,
  IContext,
  IDataContext,
} from "./types";

const valueContext: IContext = {
  data: dataContext,
  dataCommon: dataCommonDefault,
  dataSS: { dataSecondStep: [] },
  setDataSS: () => {
    throw new Error("not initialized");
  },
  setData: (value) => console.log(value),
  step: 1,
  setStep: (value) => console.log(value),
  formActions: formActionsDefault,
  createInput: () => {
    throw new Error("not initialized");
  },
};

export const ContextCryptoFiat = createContext<IContext>(valueContext);

const CryptoFiatPayInNewRequest = () => {
  const infoInitQuery = useQueryAPI(CryptoFiatPayInApi.initNewRequest, {
    changeIsLoading: true,
  });
  const [data, setData] = useState(dataContext);

  const [dataSS, setDataSS] = useState(valueContext.dataSS);

  const [dataCommon, setDataCommon] = useState<DataCommonCryptoFiat>(
    valueContext.dataCommon
  );
  const [step, setStep] = useState(1);
  const onFinishLoading = useQuestionBeforeLeave({
    deps: [getValuesDataFS(data)],
  });

  const formActions = useForm(data, setData, () => {});

  const onChangeData: TOnChangeDataFS<IDataContext> = (key, value) => {
    setData((prev) => ({ ...prev, [key]: { error: "", value } }));
  };

  const onErrorData: TSetErrorDataFS<IDataContext> = (key, error) => {
    setData((prev) => ({ ...prev, [key]: { ...prev[key], error } }));
  };

  const createInput = (): CryptoFiatInput => ({
    dataFS: data,
    dataCommon: { ...dataCommon, companyHints: [] },
    onChangeDataFS: onChangeData,
    updateDataFS: (newData: Partial<IDataContext>) =>
      setData((currDataFS) => ({ ...currDataFS, ...newData })),
    formActions: formActions,
  });

  useEffect(() => {
    const response = infoInitQuery.data;
    if (response) {
      const dataBenefAccount = getDataBenefAccount(response.data.accounts);

      const dataCountry = response.data.dictionaries.countries.map(
        ({ countryId, countryName }) => ({
          text: countryName,
          id: countryId,
        })
      );

      const dataState = response.data.dictionaries.us_states.map(
        ({ stateCode, stateName }) => ({
          text: stateName,
          id: stateCode,
        })
      );

      const dataCaState = response.data.dictionaries.ca_states.map(
        ({ stateCode, stateName }) => ({
          text: stateName,
          id: stateCode,
        })
      );

      const { requestId } = response.data.transfer;
      setDataCommon({
        dataBenefAccount: dataBenefAccount,
        dictionaries: response.data.dictionaries,
        canadaID: response.data.dictionaries.country_id_ca,
        usID: response.data.dictionaries.country_id_us,
        dataCountry,
        dataState,
        dataCaState,
        requestId,
        currencyId: response.data.dictionaries.currencies[0].currencyId,
      });

      setTimeout(() => onFinishLoading(false), 1000);
    }
  }, [infoInitQuery]);
  return (
    <ContextCryptoFiat.Provider
      value={{
        data,
        setData,
        step,
        setStep,
        dataCommon,
        formActions,
        createInput,
        dataSS,
        setDataSS,
      }}
    >
      <WrapperPayment
        {...infoInitQuery.data?.data.page?.header}
        viewStep={["Fill out", "Verify"]}
        step={step}
      >
        <CryptoFiatPayInFirstStep />
        <CryptoFiatPayInSecondStep />
      </WrapperPayment>
    </ContextCryptoFiat.Provider>
  );
};

export default CryptoFiatPayInNewRequest;
