import { Button } from "antd";
import { useContext } from "react";
import { formatValue } from "react-currency-input-field";
import { ContextInvoiceUse } from "../../..";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { initDataCommon } from "../../../data";
import { getSubtotal, getTax } from "./data";
import IBFLine from "./line";
import * as Styled from "./style";

const IUFSFooter = () => {
  const { dataCommon, setDataCommon } = useContext(ContextInvoiceUse);
  const { dataTable, currency, taxType } = dataCommon;
  const isTax = taxType.id !== "none";

  const getValue = (value: number) => {
    return ` ${currency} ${formatValue({
      value: String(value),
      decimalScale: 2,
    })}`;
  };

  const setNewItem = () => {
    setDataCommon((prev) => {
      const dataTable: typeof prev.dataTable = [
        ...prev.dataTable,
        { ...initDataCommon.dataTable[0] },
      ];
      return { ...prev, dataTable };
    });
  };

  return (
    <Styled.Wrapper>
      <div>
        <Button shape="round" size="large" onClick={setNewItem}>
          Add New Invoice Item
        </Button>
      </div>

      <div>
        <WrapperWhiteBG title="Invoice Summary">
          <table>
            <tbody>
              <IBFLine
                prefix="Total:"
                value={getValue(getSubtotal(dataTable) + getTax(dataTable))}
                isTotal
              />
              {isTax && (
                <IBFLine
                  prefix={`Inc. ${taxType.text}:`}
                  value={getValue(getTax(dataTable))}
                />
              )}
            </tbody>
          </table>
        </WrapperWhiteBG>
      </div>
    </Styled.Wrapper>
  );
};

export default IUFSFooter;
