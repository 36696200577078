import { formatValue } from "react-currency-input-field";

export interface IRulesNumber {
  min?: number;
  max?: number;
}

interface IData {
  symbol?: string;
  decimalScale?: number;
}

const validateNumber = (value: number, rules: IRulesNumber, data?: IData) => {
  const { min, max } = rules;
  const { decimalScale = 2, symbol = "" } = data || {};

  if (isNaN(value)) return "Error: can't validate NaN";
  if (min && value < min)
    return `Minimum amount is ${symbol} ${formatValue({
      value: String(min),
      decimalScale,
    })}`;

  if (max && value > max)
    return `Exceeds account balance of ${symbol} ${formatValue({
      value: String(max),
      decimalScale,
    })}`;

  return "";
};

export default validateNumber;
