import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 1024px) {
    #titleOfTableItem {
      display: block;
      padding-bottom: 8px;
    }
  }
`;
