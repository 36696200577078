import { RightOutlined } from "@ant-design/icons";
import { Badge, Tooltip } from "antd";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsOpenNav, setIsQuestionLeave } from "../../../../store/user";
import { useAppSelector } from "../../../../utils/useState";
import IconNav from "./icon";
import NBModalLeave from "./modalLeave";
import * as Styled from "./style";

interface IProps {
  closeable?: boolean;
}

const NavBar: FC<IProps> = ({ closeable = false }) => {
  const [modalOpenType, setModalOpenType] = useState("");
  const { nav, openType, isQuestionLeave, isOpenNav } = useAppSelector(
    (state) => state.userReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const viewText = () => {
    dispatch(setIsOpenNav(!isOpenNav));
  };

  const onLeave = (type: string) => {
    navigate("/" + type);
    dispatch(setIsQuestionLeave(false));
    setModalOpenType("");
    if (window.innerWidth < 1024) viewText();
  };

  const selectPath = (openType: string) => {
    if (window.innerWidth < 1400) dispatch(setIsOpenNav(false));

    if (isQuestionLeave) {
      setModalOpenType(openType);
      return;
    }

    onLeave(openType);
  };

  return (
    <Styled.Wrapper>
      {isOpenNav && window.innerWidth < 1400 && (
        <Styled.Background onClick={() => dispatch(setIsOpenNav(false))} />
      )}
      {!!modalOpenType.length && (
        <NBModalLeave
          onClickStay={() => setModalOpenType("")}
          onClickLeave={() => onLeave(modalOpenType)}
        />
      )}
      <Styled.WrapperSecond>
        {nav.map(({ title, type, counter }, index) => (
          <Tooltip
            key={"nav" + index + ":" + title}
            placement="right"
            title={title}
            zIndex={isOpenNav ? -2 : 1}
          >
            <Styled.WrapperNav
              isOpen={isOpenNav}
              isSelect={openType === type}
              onClick={() => {
                selectPath(type);
              }}
            >
              <Badge
                count={counter}
                style={{
                  backgroundColor: "white",
                  color: "#5bdfa7",
                  borderColor: "#5bdfa7",
                }}
              >
                <IconNav type={type} />
              </Badge>
              <Styled.TextNav fontSize={18}>{title}</Styled.TextNav>
            </Styled.WrapperNav>
          </Tooltip>
        ))}
        {closeable && (
          <Styled.WrapperViewFull onClick={viewText} isOpen={isOpenNav}>
            <RightOutlined />
          </Styled.WrapperViewFull>
        )}
      </Styled.WrapperSecond>
    </Styled.Wrapper>
  );
};

export default NavBar;
