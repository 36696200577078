import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px 0;
`;

export const Info = styled.div`
  display: flex;
  justify-content: center;
  font-family: Poppins-light;
  & > span {
    text-align: center;
  }
  & a {
    color: #6c64f4;
    text-decoration: underline;
    margin: 0 4px;
  }
`;

export const Phone = styled.div`
  width: 100%;
  padding: 8px 15px;
  font-size: 14px;
  text-align: start;
  background-color: #e6f7ff;
  border-radius: 5px;
  animation: slideDownAlert 0.4s;
`;

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 4px;
`;
