import AmountComponent from "../../../../../../component/Global/Amount";
import validateNumber, {
  IRulesNumber,
} from "../../../../../../utils/validate/validateNumber";
import { InputBulk } from "../../../types";
import PaymentRateComponent from "./Rate";
import * as Styled from "./style";
import { formatValue } from "react-currency-input-field";
import Big from "big.js";

interface IDataRate {
  paymentAmount: number;
  paymentAmountFormatted: string;
}

const PaymentDetaisCurrencyFirst = ({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    formActions: { prepareMoney },
    getCurrentCompanyCurrency,
  },
}: {
  input: InputBulk;
}) => {
  const { DAmount } = dataFS;
  const {
    balance,
    dataRates,
    dataCurruncyDictionary,
    balanceCurrencyCode,
    balanceCurrencySymbol,
    balanceCurrencyId,
  } = dataCommon;

  const calculateDataRate = (): IDataRate => {
    if (dataRates) {
      const currentCompanyCurrency = getCurrentCompanyCurrency();
      let currency: { currencyCode: string } | undefined =
        dataCurruncyDictionary.find(
          (el) => el.currencyId == currentCompanyCurrency.currencyId
        );
      if (!currency) {
        currency =
          balanceCurrencyId == currentCompanyCurrency.currencyId ||
          currentCompanyCurrency.currencyId == ""
            ? { currencyCode: balanceCurrencyCode }
            : undefined;
      }
      if (!currency) {
        return {
          paymentAmount: 0,
          paymentAmountFormatted: "",
        };
      }

      let rate = dataRates[currency.currencyCode];
      if (currency.currencyCode === balanceCurrencyCode) {
        rate = 1;
      }
      if (!rate) {
        return {
          paymentAmount: 0,
          paymentAmountFormatted: "",
        };
      }

      const resultAmount = new Big(DAmount.value)
        .times(rate)
        .round(2, Big.roundHalfUp)
        .toNumber();

      return {
        paymentAmount: resultAmount,
        paymentAmountFormatted: `${balanceCurrencySymbol} ${formatValue({
          value: resultAmount.toString(),
          decimalSeparator: ".",
          groupSeparator: ",",
          decimalScale: 2,
        })}`,
      };
    } else {
      return {
        paymentAmount: 0,
        paymentAmountFormatted: "",
      };
    }
  };

  const currentCompanyCurrency = getCurrentCompanyCurrency();

  return (
    <Styled.WrapperRow>
      <AmountComponent
        value={DAmount.value}
        onChangeValue={(value) => onChangeDataFS("DAmount", value)}
        title="Amount:"
        placeholder={currentCompanyCurrency.currencySymbol + " 0.00"}
        prefix={currentCompanyCurrency.currencySymbol + " "}
        error={DAmount.error}
        charLimit={16}
        id="DAmount"
        fieldActions={prepareMoney("DAmount", (value, allValues) => {
          let errorRules: IRulesNumber;
          const dataRate = calculateDataRate();
          if (getCurrentCompanyCurrency().currencyId == "") {
            errorRules = { min: 0.01 };
          } else {
            errorRules =
              dataRate.paymentAmount > balance
                ? { min: 0.01, max: balance }
                : { min: 0.01 };
          }
          return validateNumber(dataRate.paymentAmount, errorRules, {
            symbol: balanceCurrencySymbol,
          });
        })}
      />

      <PaymentRateComponent
        isAble={
          currentCompanyCurrency.currencySymbol != "" && DAmount.value != ""
        }
        text={calculateDataRate().paymentAmountFormatted}
      />
    </Styled.WrapperRow>
  );
};

export default PaymentDetaisCurrencyFirst;
