import { createContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Table from "../../component/table";
import PaginationTable from "../../component/table/pagination";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import WrapperWhiteBG from "../../component/Wrappers/wrapperWhiteBG";
import { SettlementAPI } from "../../services/settlement";
import { changeOpenType } from "../../store/user";
import useSizePage from "../../utils/hookSizePage";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import {
  getFilterData,
  getPayload,
  getTable,
  initContext,
  initData,
} from "./data";
import TransactionDownload from "./download";
import SettlementHeader from "./header";
import SettlementInfoBalences from "./infoComponent/balances";
import SettlementInfoHeader from "./infoComponent/header";
import * as Styled from "./style";

export const ContextSettlement = createContext(initContext);

const SettlementPage = () => {
  const [queryData, infoDataQuery] = useMutationAPI(SettlementAPI.getData, {
    changeIsLoading: true,
  });

  const dispatch = useDispatch();

  const [isBlockQuery, setIsBlockQuery] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [data, setData] = useState(initData);
  const { width } = useSizePage();

  const onChangeData: typeof initContext["onChangeData"] = (key, data) =>
    setData((prev) => ({ ...prev, [key]: data }));

  useEffect(() => {
    dispatch(changeOpenType({ openType: "settlements-report" }));
  }, []);

  useEffect(() => {
    if (isBlockQuery) return;
    setIsBlockQuery(true);
    queryData(getPayload(data, isInit));
    setIsInit(false);
  }, [
    data.site,
    data.interval,
    data.siteType,
    data.startDate,
    data.endDate,
    data.view_page.page_number,
    data.view_page.page_size,
  ]);

  useEffect(() => {
    const response = infoDataQuery.data?.data;

    if (response) {
      const { data: dataTable, filters, header, view_page, total } = response;
      const {
        sites_filter,
        intervals_filter,
        status_filter,
        export_filter,
        dates_filter,
      } = filters;
      const { date_from, date_to } = dates_filter;

      const intervalData = getFilterData(intervals_filter);
      const siteTypeData = getFilterData(status_filter);
      const siteData = getFilterData(sites_filter);
      const downloadData = getFilterData(export_filter);


      const site = siteData.find(
        ({ id }) => id === sites_filter.dropdown_options.value
      ) || { id: "", text: "" };

      const interval = intervalData.find(
        ({ id }) => id === intervals_filter.dropdown_options.value
      ) || { id: "", text: "" };

      const siteType = siteTypeData.find(
        ({ id }) => id === status_filter.dropdown_options.value
      ) || { id: "", text: "" };

      setData((prev) => ({
        ...prev,
        dataTable,
        intervalData,
        infoHeader: header,
        siteTypeData,
        siteData,
        downloadData,
        total,
        view_page: { ...prev.view_page, total_items: view_page.total_items },
        site,
        startDate: new Date(date_from.value),
        endDate: new Date(date_to.value),
        interval,
        siteType,
      }));

      setTimeout(() => setIsBlockQuery(false), 1000);
    }
  }, [infoDataQuery]);

  return (
    <ContextSettlement.Provider value={{ data, onChangeData }}>
      <WrapperBodyTitle {...infoDataQuery.data?.data.page?.header}>
        <SettlementHeader />
        <WrapperWhiteBG isBorder>
          <SettlementInfoHeader />
          <SettlementInfoBalences />
        </WrapperWhiteBG>
        {!!data.dataTable.length && <TransactionDownload data={data} />}
        <Styled.WrapperTable>
          <Table
            table={getTable(data.dataTable, width)}
            // details_table={data.dataTable.map(
            //   ({ details_table }) => details_table
            // )}
            details={data.dataTable.map(({ details }) => details || [])}
            disablePagination
            pageSize={data.view_page.page_size}
          />
        </Styled.WrapperTable>
        {!!data.dataTable.length && (
          <PaginationTable
            current={data.view_page.page_number}
            pageSize={data.view_page.page_size}
            total={data.view_page.total_items}
            onChange={(page_number, page_size) => {
              onChangeData("view_page", {
                ...data.view_page,
                page_number,
                page_size,
              });
            }}
          />
        )}
      </WrapperBodyTitle>
    </ContextSettlement.Provider>
  );
};

export default SettlementPage;
