import PickerComponent, {
  IItemPicker,
  IItemPickerValue,
} from "../../../../../component/Global/Picker";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

type IDataFS = {
  BCountry: IValueError<IItemPickerValue>;
};

type IDataCommon = {
  dataCountry: IItemPicker[];
};

interface Input {
  dataFS: IDataFS;
  dataCommon: IDataCommon;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const CountryPicker = ({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    formActions: { preparePickerRequired },
  },
}: {
  input: Input;
}) => {
  return (
    <PickerComponent
      {...dataFS.BCountry}
      data={dataCommon.dataCountry}
      setValue={(value) => onChangeDataFS("BCountry", value)}
      title="Country:"
      placeholder="Select Country"
      id="BCountry"
      fieldActions={preparePickerRequired("BCountry")}
    />
  );
};
