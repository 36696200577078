import { Table, TableProps } from "antd";
import { FC, ReactNode, useMemo } from "react";
import useSizePage from "../../utils/hookSizePage";
import { ITableAntdOptions } from "../table/types";
import AntdMobileTable from "./mobileTable";
import * as Styled from "./style";

export type TItemAntdTable = {
  [key: string]: ReactNode;
};

interface IProsp extends TableProps<TItemAntdTable> {
  mobileView?: number;
  options?: ITableAntdOptions;
}

const AntdResponsiveTable: FC<IProsp> = ({ mobileView = 0, ...props }) => {
  const dataSource = useMemo(
    () =>
      props.dataSource?.map((item) => {
        const array = (Object.entries(item) as [string, ReactNode][]).map(
          ([key, value]) => {
            if (value === "#empty") {
              return [key, ""];
            }
            return [key, value];
          }
        );
        return Object.fromEntries(array);
      }),
    [props.dataSource]
  );

  return (
    <Styled.WrapperTable mobileView={mobileView}>
      <Table
        {...props}
        dataSource={dataSource}
        showHeader={props.options?.showHeader}
        size={props.options?.size}
        bordered
      />
      <AntdMobileTable {...props} />
    </Styled.WrapperTable>
  );
};

export default AntdResponsiveTable;
