import instance from "..";
import {
  IResponseDashboard,
  IResponseStatements,
  IResponseViewStatement,
  IResponseFileUpload,
  IResponseFileDelete,
} from "./types/response";
import {
  IPayloadCloseNotif,
  IPayloadDeleteDrafts,
  IPayloadFileDelete,
  IPayloadFileUpload,
  IPayloadViewStatement,
} from "./types/payload";
import { IFileAPI } from "../types";

const PagesAPI = {
  dashboard: () => instance.get<IResponseDashboard>("/app/init-dashboard"),
  draftsDelete: (payload: IPayloadDeleteDrafts) =>
    instance.post("transfers/saved-drafts/delete", payload),
  closeNotif: ({ id }: IPayloadCloseNotif) =>
    instance.get("/notifications/" + id),
  statements: () =>
    instance.get<IResponseStatements>("/app/init-statements-list"),
  getStatement: (payload: IPayloadViewStatement) =>
    instance.post<IResponseViewStatement>("/statements/list", payload),
  dowloadFile: (payload: IPayloadViewStatement) =>
    instance.post<IFileAPI>("/statements/list", payload),
  file: {
    upload: ({ formData, id }: IPayloadFileUpload) =>
      instance.post<IResponseFileUpload>(
        "/transfers/documents/upload/" + id,
        formData
      ),
    delete: ({ id }: IPayloadFileDelete) =>
      instance.get<IResponseFileDelete>("/transfers/documents/delete/" + id),
  },
};

export default PagesAPI;
