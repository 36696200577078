import { Button } from "antd";
import { FC, useState } from "react";
import { IItemTableAPI } from "../../../services/types/table";
import ARBAModal from "./modal";

interface IProps {
  text: string;
  riskCaseId: string;
  counterparty: IItemTableAPI[][];
  amount: IItemTableAPI[][];
  onSuccess: () => void;
}

const ARButtonAttach: FC<IProps> = ({
  text,
  riskCaseId,
  counterparty,
  amount,
  onSuccess,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onSuccessModal = () => {
    setIsOpenModal(false);
    onSuccess();
  };

  return (
    <>
      <Button
        shape="round"
        size="large"
        onClick={() => setIsOpenModal(true)}
        style={{ marginTop: "8px" }}
      >
        {text}
      </Button>
      {isOpenModal && (
        <ARBAModal
          onCancel={() => setIsOpenModal(false)}
          counterparty={counterparty}
          amount={amount}
          requestId={riskCaseId}
          onSuccess={onSuccessModal}
        />
      )}
    </>
  );
};

export default ARButtonAttach;
