import { Button } from "antd";
import { useContext, useEffect, useId } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ContextRecurringPayment } from "../..";
import { RecurringPaymentAPI } from "../../../../services/recurringPayment";
import { setIsQuestionLeave } from "../../../../store/user";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import RPBSSItem from "./item";
import * as Styled from "./style";

const RPBuilderSS = () => {
  const idComponent = useId();
  const { dataSS, setStep, dataCommon, dataFS } = useContext(
    ContextRecurringPayment
  );
  const { beneficiary, remitter } = dataSS;
  const { recurringPaymentId } = dataCommon;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [querySave, infoSaveQuery] = useMutationAPI(RecurringPaymentAPI.save);

  useEffect(() => {
    const response = infoSaveQuery.data?.data;

    if (response) {
      dispatch(setIsQuestionLeave(false));
      navigate("/recurring-payments");
    }
  }, [infoSaveQuery]);

  return (
    <div>
      {[[remitter, beneficiary]].map((row, index) => (
        <Styled.WrapperBlock key={idComponent + index}>
          <RPBSSItem data={row} />
        </Styled.WrapperBlock>
      ))}
      <Styled.WrapperButtons>
        <Button shape="round" size="large" onClick={() => setStep(1)}>
          Edit recurring
        </Button>
        <Button
          shape="round"
          size="large"
          type="primary"
          loading={infoSaveQuery.isLoading}
          onClick={() =>
            querySave({
              id: recurringPaymentId,
            })
          }
        >
          Save recurring
        </Button>
      </Styled.WrapperButtons>
    </div>
  );
};

export default RPBuilderSS;
