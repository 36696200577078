import { IPayloadTUValidate } from "../../../../../services/cards/types/payload";
import { IErrorPayment } from "../../../../../services/types";
import validateNumber from "../../../../../utils/validate/validateNumber";
import validateString from "../../../../../utils/validate/validateString";
import { IDataFS } from "../../../types";

export const validateData = (data: IDataFS, currencySymbol: string) => {
  const keys: (keyof IDataFS)[] = ["account", "amount", "description"];

  const errors = keys.map((key) => {
    switch (key) {
      case "account": {
        return {
          key,
          error: validateString(data[key].value.id, { isReq: true }),
        };
      }

      case "amount": {
        const value = parseFloat(data[key].value) || 0;
        return {
          key,
          error: validateNumber(
            value,
            { min: 0.01 },
            {
              symbol: currencySymbol,
            }
          ),
        };
      }

      case "description": {
        return {
          key,
          error: validateString(data[key].value, { isReq: true }),
        };
      }

      default: {
        return {
          key,
          error: "",
        };
      }
    }
  });

  return errors;
};

export const getPayload = (
  data: IDataFS,
  paymentId: string,
  accountId: string
): IPayloadTUValidate => {
  const { amount, account, description } = data;
  return {
    paymentId,
    payload: {
      accountId,
      accountIdFrom: account.value.id,
      paymentAmount: parseFloat(amount.value) || 0.01,
      paymentDescription: description.value,
    },
  };
};

const keyResponse: {
  [key in keyof IPayloadTUValidate["payload"]]: keyof IDataFS;
} = {
  paymentAmount: "amount",
  accountId: "account",
  accountIdFrom: "account",
  paymentDescription: "description",
};

export const getErrorResponse = (
  data: IErrorPayment<keyof IPayloadTUValidate["payload"]>[]
) => {
  return data.map(({ field, message }) => ({
    key: keyResponse[field],
    error: message,
  }));
};
