import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotificationModalAPI } from "../../services/types";
import {
  IChangeOpenTypePayload,
  IInitialState,
  ISetDataUserPayload,
  ISetIsAuthErrror,
} from "./types";

const initialState: IInitialState = {
  nav: [],
  userName: "",
  userId: "",
  openType: "dashboard",
  isAuthError: false,
  isAuth: false,
  authErrorData: {
    info: "",
    title: "Security alert",
  },
  isInitQuery: true,
  userMultiFactorType: "google-authenticator",
  userType: "user",
  userPhone: "",
  isQuestionLeave: false,
  isOpenNav: false,
  isLoading: false,
  couterQuery: 0,
  isQuestionBack: false,
  isAbleQuestionBack: false,
  notificationModal: null,
};

const userReducer = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setDataUser: (state, action: PayloadAction<ISetDataUserPayload>) => {
      state.userName = action.payload.userName;
      state.nav = action.payload.nav;
      state.userMultiFactorType = action.payload.userMultiFactorType;
      state.userPhone = action.payload.userPhone;
      state.userId = action.payload.userId;
      state.userType = action.payload.userType;
    },
    changeOpenType: (state, action: PayloadAction<IChangeOpenTypePayload>) => {
      state.openType = action.payload.openType;
    },
    setAuthError: (state, action: PayloadAction<ISetIsAuthErrror>) => {
      const { status, data } = action.payload;
      state.isAuthError = status;
      if (!data) return;
      state.authErrorData = { ...data };
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
      if (!state.isAuth) {
        sessionStorage.clear();
      }
    },
    setIsInitQuery: (state, action: PayloadAction<boolean>) => {
      state.isInitQuery = action.payload;
    },
    setIsQuestionLeave: (state, action: PayloadAction<boolean>) => {
      const status = action.payload;
      state.isQuestionLeave = status;
      if (!status) {
        (window as any).getLeaveButtons = null;
      }
    },
    setIsOpenNav: (state, action: PayloadAction<boolean>) => {
      state.isOpenNav = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCouterQuery: (state, action: PayloadAction<-1 | 1>) => {
      state.couterQuery = state.couterQuery + action.payload;
    },
    setIsQuestionBack: (state, action: PayloadAction<boolean>) => {
      state.isQuestionBack = action.payload;
    },
    setIsAbleQuestionBack: (state, action: PayloadAction<boolean>) => {
      state.isAbleQuestionBack = action.payload;
    },
    setNotificationModal: (
      state,
      action: PayloadAction<INotificationModalAPI | null>
    ) => {
      state.notificationModal = action.payload;
    },
  },
});

export const {
  setDataUser,
  changeOpenType,
  setAuthError,
  setIsAuth,
  setIsInitQuery,
  setIsQuestionLeave,
  setIsOpenNav,
  setIsLoading,
  setCouterQuery,
  setIsQuestionBack,
  setIsAbleQuestionBack,
  setNotificationModal,
} = userReducer.actions;

export default userReducer.reducer;
