import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { FC } from "react";
import * as Styled from "./style";

interface IAble {
  nextMonth: boolean;
  nextYear: boolean;
  backMonth: boolean;
  backYear: boolean;
}

interface IHandlersClick {
  nextMonthClick: () => void;
  nextYearClick: () => void;
  backMonthClick: () => void;
  backYearClick: () => void;
}

interface IProps {
  text: string;
  isAble?: IAble;
  handlersClick: IHandlersClick;
}

const initAble: IAble = {
  nextMonth: true,
  nextYear: true,
  backMonth: true,
  backYear: true,
};

const HeaderDateRange: FC<IProps> = ({
  text,
  isAble = initAble,
  handlersClick,
}) => {
  const { nextMonth, nextYear, backMonth, backYear } = isAble;
  const { nextMonthClick, nextYearClick, backMonthClick, backYearClick } =
    handlersClick;
  return (
    <Styled.Wrapper>
      <Styled.WrapperIcon>
        {backYear && <DoubleLeftOutlined onClick={backYearClick} />}
      </Styled.WrapperIcon>
      <Styled.WrapperIcon>
        {backMonth && <LeftOutlined onClick={backMonthClick} />}
      </Styled.WrapperIcon>
      <Styled.WrapperText>{text}</Styled.WrapperText>
      <Styled.WrapperIcon>
        {nextMonth && <RightOutlined onClick={nextMonthClick} />}
      </Styled.WrapperIcon>
      <Styled.WrapperIcon>
        {nextYear && <DoubleRightOutlined onClick={nextYearClick} />}
      </Styled.WrapperIcon>
    </Styled.Wrapper>
  );
};

export default HeaderDateRange;
