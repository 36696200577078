import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 10px 20px;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const WrapperAccount = styled.div`
  width: 500px;
  @media (max-width: 1400px) {
    width: calc(50% - 10px);
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const WrapperDate = styled.div`
  & > div:last-child {
    display: none;
  }
  @media (max-width: 1400px) {
    width: calc(50% - 10px);
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    & > div:first-child {
      display: none;
    }
    & > div:last-child {
      display: flex;
      gap: 0 20px;
    }
  }
`;

export const WrapperSelect = styled.div`
  display: flex;
  gap: 20px;
  width: 340px;
  @media (max-width: 1400px) {
    width: 100%;
  }
  @media (max-width: 1024px) {
    & > div {
      width: 50%;
    }
  }
`;
