import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../../component/table";
import { ITableDetails } from "../../component/table/types";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import { PendingPaymnetsAPI } from "../../services/pendingPayments";
import { IDataInit } from "../../services/pendingPayments/types";
import { changeOpenType } from "../../store/user";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import useSizePage from "../../utils/hookSizePage";
import PPButtons from "./buttons";
import { getDataTable, getDataTableWithoutActions } from "./data";
import { TOnSelectAuth } from "./types";
import ModalAuth from "../shared/components/ModalAuth";

const PendingPayments = () => {
  const [queryInit, infoInitQuery] = useMutationAPI(PendingPaymnetsAPI.init, {
    changeIsLoading: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useSizePage();

  const [queryAuth, infoAuthQuery] = useMutationAPI(
    PendingPaymnetsAPI.authorize
  );

  const [dataTable, setDataTable] = useState<IDataInit[]>([]);
  const [dataDetails, setDataDetails] = useState<ITableDetails[][]>([]);
  const [dataProcessingDetails, setDataProcessingDetails] = useState<
    ITableDetails[][]
  >([]);
  const [idRows, setIdRows] = useState<string[]>([]);
  const [selectRows, setSelectRows] = useState<string[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [paymentIdModal, setPaymentIdModal] = useState("");

  useEffect(() => {
    const response = infoAuthQuery.data;
    if (response) {
      setIsOpenModal(true);
    }
  }, [infoAuthQuery]);

  useEffect(() => {
    queryInit({});
    dispatch(changeOpenType({ openType: "saved-payments" }));
  }, []);

  useEffect(() => {
    const response = infoInitQuery.data;

    if (response && !response.data.data.length) {
      navigate("/dashboard");
      return;
    }

    if (response) {
      setSelectRows([]);
      setDataTable(response.data.data);
      setDataDetails(response.data.data.map(({ details }) => details));

      setDataProcessingDetails(
        response.data.processing_data?.map(({ details }) => details) ?? []
      );

      setIdRows(response.data.data.map(({ paymentId }) => paymentId));
    }
  }, [infoInitQuery]);

  const onSelectAuth: TOnSelectAuth = ({ id, mode, text, type }) => {
    if (mode === "execute") {
      setPaymentIdModal(id);
      queryAuth({ transfers: [id] });
      return;
    }

    navigate(text, {
      state: { paymentId: id, mode, type },
    });
  };

  const table = useMemo(
    () => getDataTable(dataTable, width, onSelectAuth),
    [dataTable, width]
  );

  const processingTable = useMemo(
    () => getDataTableWithoutActions(dataTable, width),
    [dataTable, width]
  );

  return (
    <WrapperBodyTitle {...infoInitQuery.data?.data.page?.header}>
      <Table
        table={table}
        details={dataDetails}
        idRows={idRows}
        idSeletRows={selectRows}
        onSelectRow={setSelectRows}
      />
      {!!table[0]?.dataColumn.length && (
        <PPButtons transfers={selectRows} onRealod={() => queryInit({})} />
      )}
      {/* <Table
        title="Processing Payments"
        table={processingTable}
        details={dataProcessingDetails}
      /> */}
      {isOpenModal && (
        <ModalAuth
          onCancel={() => {
            setIsOpenModal(false);
            setPaymentIdModal("");
          }}
          transfers={[paymentIdModal]}
          onResponse={() => queryInit({})}
          execute={PendingPaymnetsAPI.execute}
        />
      )}
    </WrapperBodyTitle>
  );
};

export default PendingPayments;
