import { UnorderedListOutlined } from "@ant-design/icons";
import { FC, useContext, useMemo } from "react";
import { ContextTransactions } from "..";
import DatePickerComponent from "../../../component/Global/DatePicker";
import DateRangePicker from "../../../component/Global/DateRangePicker";
import PickerComponent, { IItemPicker } from "../../../component/Global/Picker";
import { typeToDate } from "../../../utils/typeToDate";
import * as Styled from "./style";

interface IProps {}

const TransactionHeader: FC<IProps> = ({}) => {
  const { data, onChangeData } = useContext(ContextTransactions);
  const {
    site,
    siteData,
    interval,
    intervalData,
    siteType,
    siteTypeData,
    startDate,
    endDate,
    view_page,
  } = data;

  const setStartDate = (value: Date) => {
    onChangeData("view_page", { ...view_page, page_number: 1 });
    onChangeData("startDate", value);
    onChangeData("interval", {text:"",id:""});
  };
  const setEndDate = (value: Date) => {
    onChangeData("view_page", { ...view_page, page_number: 1 });
    onChangeData("endDate", value);
    onChangeData("interval", { text: "", id: "" });

  };

  const infoStartEndDate = useMemo(
    () => ({
      startDate,
      setStartDate,
      endDate,
      setEndDate,
    }),
    [startDate, endDate]
  );

  return (
    <Styled.Wrapper>
      {siteData.length > 1 && (
        <Styled.WrapperAccount>
          <PickerComponent
            value={site}
            setValue={(value) => onChangeData("site", value)}
            data={siteData}
            disabledError
            placeholder="Select Site"
          />
        </Styled.WrapperAccount>
      )}

      <Styled.WrapperDate>
        <DateRangePicker infoDateRange={infoStartEndDate} />
        <div>
          <DatePickerComponent
            value={startDate}
            setDate={setStartDate}
            disabledError
          />
          <DatePickerComponent
            value={endDate}
            setDate={setEndDate}
            disabledError
          />
        </div>
      </Styled.WrapperDate>
      <Styled.WrapperSelect>
        <PickerComponent
          value={interval}
          setValue={(value) => onChangeData("interval", value)}
          data={intervalData}
          icon={<UnorderedListOutlined style={{ color: "#041e42" }} />}
          placeholder="Select Date"
        />
        <PickerComponent
          value={siteType}
          setValue={(value) => onChangeData("siteType", value)}
          data={siteTypeData}
          icon={<UnorderedListOutlined style={{ color: "#041e42" }} />}
          placeholder="Select Payment"
        />
      </Styled.WrapperSelect>
    </Styled.Wrapper>
  );
};

export default TransactionHeader;
