import { Button, Modal } from "antd";
import { FC, ReactNode } from "react";
import * as Styled from "./style";

interface IProps {
  width?: number;
  onClose: () => void;
  onClickYes?: () => void;
  onClickNo?: () => void;
  loadingYes?: boolean;
  loadingNo?: boolean;
  title?: string;
  message?: ReactNode;
  buttons?: ReactNode[] | [string, string];
  isDanger?: [boolean, boolean];
  smallPadding?: string | boolean;
}

const getPadding = (value: string | boolean) => {
  if (typeof value === "boolean") {
    if (value) return { padding: "6.4px 24px" };
    return {};
  }
  return { padding: value };
};

const CommonModalComponent: FC<IProps> = ({
  width = 374,
  onClose,
  onClickNo,
  onClickYes,
  loadingYes,
  loadingNo,
  title = "Delete items?",
  message = "Please note that delete action is irreversible.",
  buttons = ["Delete", "Cancel"],
  isDanger = [true, false],
  smallPadding = false,
}) => {

  return (
    <Modal title="" open={true} width={width} onCancel={onClose}>
      <Styled.TextModalCred fontSize={22}>{title}</Styled.TextModalCred>
      <Styled.TextModalCred fontSize={16}>{message}</Styled.TextModalCred>
      <Styled.WrapperButton>
        {typeof buttons[0] === "string" ? (
          <>
            <Button
              onClick={onClickYes}
              shape="round"
              size="large"
              style={getPadding(smallPadding)}
              loading={loadingYes}
              danger={isDanger[0]}
            >
              {buttons[0]}
            </Button>

            <Button
              onClick={onClickNo}
              shape="round"
              size="large"
              style={getPadding(smallPadding)}
              loading={loadingNo}
              danger={isDanger[1]}
            >
              {buttons[1]}
            </Button>
          </>
        ) : (
          buttons
        )}
      </Styled.WrapperButton>
    </Modal>
  );
};

export default CommonModalComponent;
