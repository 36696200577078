import { useCallback, useEffect, useRef, useState } from "react";

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>(() => {});
  const [id, setId] = useState<NodeJS.Timer>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const idIterval = setInterval(tick, delay);
    setId(idIterval);

    return () => {
      clearInterval(idIterval);
      setId(undefined);
    };
  }, []);

  return () => {
    clearInterval(id);
    setId(undefined);
  };
};
