import { FeePicker } from "../../../../shared/firstStep/components/feePicker";
import { InputEft } from "../../../types";
import * as Styled from "./style";

const PaymentDetaisDateFee = ({ input }: { input: InputEft }) => {
  return (
    <Styled.WrapperRow>
      <FeePicker input={input} />
    </Styled.WrapperRow>
  );
};

export default PaymentDetaisDateFee;
