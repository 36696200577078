import { IItemPicker } from "../../component/Global/Picker";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import { IDataDropdonwInit } from "../../services/bulkTransfer/types";
import { getValueError } from "../payments/data";
import { IContext, IDataCommon, IDataFS } from "./types";

export const initData: IDataFS = {
  files: getValueError([]),
  account: getValueError({ text: "", id: "" }),
};

export const initDataCommon: IDataCommon = {
  dataAccount: [],
  importId: "",
  dataTable: [],
  hasErrors: false,
  secondStepHeader: [],
  secondStepSummary: [],
};

const initSet = () => console.log("Init Context Bulk Payment");

export const initContext: IContext = {
  step: 1,
  setStep: initSet,
  dataFS: initData,
  setDataFS: initSet,
  dataCommon: initDataCommon,
  setDataCommon: initSet,
  onChangeDataFS: initSet,
  setErrorFS: initSet,
};

export const getDataAccount = (data: IDataDropdonwInit[]): IItemPicker[] => {
  return data.map(({ data, value }) => ({
    text: data.map(({ text }) => text).join(""),
    id: value,
    component: <TypeToTextMap data={[data]} />,
  }));
};
