import { createRef, Dispatch, FC, SetStateAction, useState } from "react";
import TransferDocument from "../../../../../component/Global/transferDocument";
import {
  IFileToFormData,
  IFileTransferDocument,
} from "../../../../../component/Global/transferDocument/types";
import { IValueError } from "../../../../../component/payment/types";
import { ActionReqiredAPI } from "../../../../../services/actionReqired";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";

type TFiles = IValueError<IFileTransferDocument[]>;

interface IProps {
  requestId: string;
  files: TFiles;
  setFiles: Dispatch<SetStateAction<TFiles>>;
  isAble: boolean;
}

const ARBAMButtonUpload: FC<IProps> = ({
  requestId,
  files,
  setFiles,
  isAble,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [queryUpload] = useMutationAPI(ActionReqiredAPI.file.upload);

  const [queryDelete] = useMutationAPI(ActionReqiredAPI.file.delete);

  const refInput = createRef<HTMLInputElement>();

  const onUpload = async (data: IFileToFormData[]) => {
    const dataResponse = await Promise.all(
      data.map(({ formData }) => queryUpload({ requestId, formData }))
    );
    return dataResponse.map(({ data: response, error: responseError }) => {
      if (response && response.data.status === "done") {
        const { name, documentId: id } = response.data;
        return { name, id, isLoading: false };
      }
      if (responseError && responseError.response) {
        return "";
      }
      if (response && response.data.status === "file_exists") {
        return "";
      }
      return "Unknow error";
    });
  };

  const deleteFile = async (id: string) => {
    if (isDeleting) return false;
    setIsDeleting(true);

    const data = files.value.map((item) => {
      if (item.id !== id) return item;
      return {
        ...item,
        isLoading: true,
      };
    });

    setFiles({ value: data, error: "" });

    const { data: responseDelete } = await queryDelete({ id });
    const { result } = responseDelete?.data || {};

    if (!result) return false;

    const filtredValue = files.value.filter((item) => id !== item.id);
    setFiles({ value: filtredValue, error: "" });

    if (!filtredValue.length) {
      setFiles((prev) => ({ ...prev, error: "Field required" }));
    }
    setIsDeleting(false);

    return true;
  };

  return (
    <TransferDocument
      {...files}
      onDelete={deleteFile}
      onUpload={onUpload}
      setError={(error) => setFiles((prev) => ({ ...prev, error }))}
      setValue={(value) => setFiles({ value, error: "" })}
      isAble={isAble}
      multiple
    />
  );
};

export default ARBAMButtonUpload;
