import { IDataHintsInput } from "../../../component/Global/Input/hints";
import { IItemPicker } from "../../../component/Global/Picker";
import { IFileTransferDocument } from "../../../component/Global/transferDocument/types";
import {
  IBeneficiaryFxAPI,
  IDictionariesFx,
  IFilesPayment,
} from "../../../services/paymentAPI/types";
import { IPayloadValidateFx } from "../../../services/paymentAPI/types/payload";
import { getValueError } from "../data";
import { IDataCommon, IDataFS } from "./types";

export const initDataFS: IDataFS = {
  DIsInterTransfer: getValueError(false),
  DIsCompanyName: getValueError(true),
  DAmount: getValueError("0.00"),
  DPaymentDescription: getValueError(""),
  DCurrency: getValueError({ text: "", id: "" }),

  BCompany: getValueError(""),
  BCountry: getValueError({ text: "", id: "" }),
  BState: getValueError({ text: "", id: "" }),
  BCaState: getValueError({ text: "", id: "" }),
  BSortCode: getValueError(""),
  BUkAccountNumber: getValueError(""),
  BCity: getValueError(""),
  BPostalCode: getValueError(""),
  BStreetAddress: getValueError(""),
  BHouseNumber: getValueError(""),
  BAccountNumber: getValueError(""),
  BBic: getValueError(""),
  BInstitutionNumber: getValueError(""),
  BTransitNumber: getValueError(""),
  BAba: getValueError(""),

  Tfiles: getValueError([]),
};

export const initDataCommon: IDataCommon = {
  balance: 0,
  balanceCurrencySymbol: "",
  dataHeader: [],
  canadaID: "",
  usID: "",
  paymentID: "",
  ukID: "",
  checksum: "",
  typeAuth: "google-authenticator",
  dataCurrencyID: [],
  dataCurrency: [],
  dataRates: {},
  dataCurrencyDictionary: [],
  dataCountry: [],
  dataState: [],
  dataCaState: [],
  companyHints: [],
  accountId: "",
  isRequireFiles: true,
  isDisableSaveAsDraft: false,
  useCompanyNameInDescriptionControl: true,
  prefixDescription: "",
  isLoadingFiles: false,
  isAbleAuth: false,
  isUsingTemplate: false,
};

const getDataHints = (
  data: IBeneficiaryFxAPI,
  dictionaries: IDictionariesFx
): Partial<IDataFS> => {
  const countryName = dictionaries.countries.find(
    (item) => item.countryId === data.paymentNameCountryId
  )?.countryName;
  const stateName = dictionaries.us_states.find(
    (item) => item.stateCode === data.paymentNameAddressState
  )?.stateName;
  const canadaStateName = dictionaries.ca_states.find(
    (item) => item.stateCode === data.paymentNameAddressState
  )?.stateName;

  return {
    BAccountNumber: getValueError(data.paymentAccountNumberTo || ""),
    BBic: getValueError(data.paymentBankBic || ""),
    BCountry: getValueError({
      text: countryName || "",
      id: data.paymentNameCountryId || "",
    }),
    BCity: getValueError(data.paymentNameAddressCity || ""),
    BState: getValueError({
      text: stateName || "",
      id: data.paymentNameAddressState || "",
    }),
    BCaState: getValueError({
      text: canadaStateName || "",
      id: data.paymentNameAddressState || "",
    }),
    BPostalCode: getValueError(data.paymentNameAddressPostalCode || ""),
    BStreetAddress: getValueError(data.paymentNameAddressStreet || ""),
    BHouseNumber: getValueError(data.paymentNameAddressNumber || ""),
    BAba: getValueError(data.paymentBankAba || ""),
    BInstitutionNumber: getValueError(data.paymentBankInstitutionNumber || ""),
    BTransitNumber: getValueError(data.paymentBankTransitNumber || ""),
    BUkAccountNumber: getValueError(data.paymentBankUkAccountNumber || ""),
    BSortCode: getValueError(data.paymentBankSortCode || ""),
  };
};

export const getCompanyHints = (
  beneficiaries: IBeneficiaryFxAPI[],
  dictionaries: IDictionariesFx
) => {
  const data = beneficiaries.map(
    (item): IDataHintsInput => ({
      title: { text: item.paymentName, type: "em" },
      subTitle: item.details,
      data: getDataHints(item, dictionaries),
    })
  );
  return data;
};

export const getDataCurrency = (dictionaries: IDictionariesFx) => {
  const data = dictionaries.currencies.map(({ currencyId, currencyName }) => ({
    text: currencyName,
    id: currencyId,
  }));
  return data;
};

export const getDataCurrencyID = (dictionaries: IDictionariesFx) => {
  const data = dictionaries.currencies.map(
    ({ currencyId, currencySymbol }) => ({
      text: currencySymbol,
      id: currencyId,
    })
  );
  return data;
};

const resToData: {
  [key in keyof IPayloadValidateFx["payload"]]: keyof IDataFS;
} = {
  accountId: "DPaymentDescription",
  paymentAccountNumberTo: "BAccountNumber",
  paymentBankBic: "BBic",
  paymentDescription: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
  paymentIsSameCompanyTransfer: "DIsInterTransfer",
  paymentName: "BCompany",
  paymentNameAddressCity: "BCity",
  paymentNameAddressNumber: "BHouseNumber",
  paymentNameAddressPostalCode: "BPostalCode",
  paymentNameAddressState: "BState", //id
  paymentNameAddressStreet: "BStreetAddress",
  paymentNameCountryId: "BCountry", //id
  paymentBankTransitNumber: "BTransitNumber",
  paymentBankInstitutionNumber: "BInstitutionNumber",
  paymentBankAba: "BAba",
  fxPaymentAmount: "DAmount",
  fxPaymentCurrencyId: "DCurrency", //id
  paymentBankSortCode: "BSortCode",
  paymentBankUkAccountNumber: "BUkAccountNumber",
};

type TValue = string | boolean | IItemPicker | IFileTransferDocument[];

export const getDataFromForm = (
  form: Partial<IPayloadValidateFx["payload"]>,
  files: IFilesPayment[],
  dataCountry: IItemPicker[],
  dataState: IItemPicker[],
  dataCaState: IItemPicker[],
  dataCurrency: IItemPicker[],
  paymentDescription: string
) => {
  let responseData: Partial<{
    [key in keyof IDataFS]: TValue;
  }> = {};

  const keysForm = Object.keys(form) as (keyof typeof form)[];

  keysForm.forEach((key) => {
    const value = form[key];
    const keyData = resToData[key];

    if (!keyData) return;

    if (typeof value === "number") {
      responseData[keyData] = value.toFixed(2);
      return;
    }

    if (keyData === "BCountry") {
      responseData[keyData] =
        dataCountry.find(({ id }) => id === form.paymentNameCountryId) ||
        initDataFS.BCountry.value;
      return;
    }

    if (keyData === "BState") {
      responseData["BCaState"] =
        dataCaState.find(({ id }) => id === form.paymentNameAddressState) ||
        initDataFS.BCaState.value;
      responseData["BState"] =
        dataState.find(({ id }) => id === form.paymentNameAddressState) ||
        initDataFS.BState.value;
      return;
    }

    if (keyData === "DCurrency") {
      responseData[keyData] =
        dataCurrency.find(({ id }) => id === form.fxPaymentCurrencyId) ||
        initDataFS.DCurrency.value;
      return;
    }
    if (
      keyData === "DPaymentDescription" &&
      typeof value === "string" &&
      form.paymentDescriptionCompanyName
    ) {
      responseData[keyData] = value.slice(paymentDescription.length + 1);
      return;
    }
    responseData[keyData] = value;
  });

  responseData["Tfiles"] = files.map(({ documentId, name, url }) => ({
    id: documentId,
    name,
    url,
    isLoading: false,
  }));

  return Object.entries(responseData) as [keyof IDataFS, TValue][];
};
