import PaymentAPI from "../../../services/paymentAPI";
import {
  getCompanyHints,
  getDataCompanyID,
  getDataCurrencyID,
  getDataFromForm,
  initDataCommon,
  initDataFS,
} from "./data";
import FirstStepBulk from "./firstStep";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import { initDataSS, initDataTS } from "../data";
import PaymentSystem, { TLocationState } from "../shared";
import { IDataCommon, IDataFS } from "./types";
import { IResponseInitBulk } from "../../../services/paymentAPI/types/response";
import validateNumber from "../../../utils/validate/validateNumber";
import { requireFiles } from "../shared/validations";

const BulkComponent = () => {
  return (
    <PaymentSystem<IDataFS, IDataCommon, IResponseInitBulk, any, TLocationState>
      isSavePending={false}
      initDataFS={initDataFS}
      initDataSS={initDataSS}
      initDataTS={initDataTS}
      initDataCommon={initDataCommon}
      updateInput={(input) => input}
      redirectOnPaymentCompletedData={{
        navigateTo: "/bulk-payments",
        openType: "bulk-payments",
      }}
      infoInitQueryProvider={({ accountId, paymentId, mode }) => {
        return useQueryAPI(PaymentAPI.bulk.getInit, {
          payload: { id: accountId || paymentId || "", mode },
          changeIsLoading: true,
        });
      }}
      firstStepComponet={(context, setStep) => {
        return (
          <FirstStepBulk
            input={{
              ...context,
              getCurrentCompanyCurrency: (): {
                currencySymbol: string;
                currencyId: string;
              } => {
                const currencyId =
                  context.dataCommon.dataCompanyID.find(
                    ({ id }) => id === context.dataFS.BCompany.value.id
                  )?.text || "";

                let currencySymbol =
                  context.dataCommon.dataCurrencyID.find(
                    ({ id }) => id === currencyId
                  )?.text || "";
                if (!currencySymbol.length) {
                  currencySymbol =
                    context.dataCommon.balanceCurrencyId === currencyId
                      ? context.dataCommon.balanceCurrencySymbol
                      : "";
                }

                return { currencySymbol, currencyId: currencyId };
              },
            }}
            setStep={setStep}
          />
        );
      }}
      onInitQueryLoaded={(
        response,
        input,
        onChangeDataFS,
        updateDataCommon
      ) => {
        if (!response.data) {
          return;
        }

        const {
          header,
          beneficiaries_list,
          account,
          transfer,
          dictionaries,
          form = {},
          files = [],
          user,
        } = response.data.data;

        const dataCompanyID = getDataCompanyID(
          beneficiaries_list.dropdown_options
        );
        const dataCurrencyID = getDataCurrencyID(dictionaries);

        const dataForm = getDataFromForm(
          form,
          files,
          dataCompanyID,
          dataCurrencyID,
          account.accountName
        );

        dataForm.map(([key, value]) => onChangeDataFS(key, value));

        updateDataCommon((prev) => ({
          ...prev,
          dataHeader: header,
          amountSymbol: account.currencySymbol,
          balance: account.balance.available,
          balanceCurrencySymbol: account.currencySymbol,
          balanceCurrencyCode: account.currencyCode,
          balanceCurrencyId: account.currencyId,
          paymentID: transfer.paymentId,
          companyHints: getCompanyHints(beneficiaries_list.dropdown_options),
          dataCompanyID,
          accountIdTo: beneficiaries_list.dropdown_options.value,
          accountId: account.accountId,
          dataCurrencyID,
          dataRates: dictionaries.rates,
          dataCurruncyDictionary: dictionaries.currencies,
          companyName: account.accountName,
          prefixDescription: account.accountName,
          isRequireFiles: transfer.options.requireFilesUpload,
          isDisableSaveAsDraft: transfer.options.disableSaveAsDraft,
          useCompanyNameInDescriptionControl:
            transfer.options.useCompanyNameInDescriptionControl,
          isAbleAuth: user?.userType !== "simple-user",
          isUsingTemplate: input.mode === "edit",
        }));
      }}
      validateAll={(data, dataCommon) => {
        data.DAmount.error = validateNumber(
          parseFloat(data.DAmount.value),
          { min: 0.01 },
          {
            symbol: dataCommon.amountSymbol,
          }
        );

        if (dataCommon.isRequireFiles) {
          requireFiles(data.Tfiles);
        }
      }}
    />
  );
};

export default BulkComponent;
