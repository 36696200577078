import { Button, InputRef } from "antd";
import { createRef, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ContextCardRequest } from "../..";
import AuthTransfer from "../../../../component/authTransfer";
import { CardAPI } from "../../../../services/cards";
import { setIsQuestionLeave } from "../../../../store/user";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import { useAppSelector } from "../../../../utils/useState";

const CRThirdStep = () => {
  const valueContext = useContext(ContextCardRequest);
  const refInput = createRef<InputRef>();

  const { dataCommon, setStep, step } = valueContext;
  const { cardRecordId } = dataCommon;

  const navigate = useNavigate();

  const [executeQuery, infoExecuteQuery] = useMutationAPI(
    CardAPI.request.execute
  );

  useEffect(() => {
    if (infoExecuteQuery.data || infoExecuteQuery.error) {
      navigate("/cards");
    }
  }, [infoExecuteQuery]);

  const onClickCorrect = () => {
    setStep(1);
  };

  const onClickExecute = (auth_code: string) => {
    executeQuery({ cardRecordId, auth_code });
  };

  useEffect(() => {
    if (step === 3) {
      setTimeout(() => {
        if (refInput.current) refInput.current.focus();
      }, 1000);
    }
  }, [step]);

  return (
    <AuthTransfer
      isLoadingAuth={infoExecuteQuery.isLoading}
      onClick={({ value }) => onClickExecute(value)}
      title="Authorize card request"
      ref={refInput}
    >
      <Button
        shape="round"
        size="large"
        onClick={onClickCorrect}
        disabled={infoExecuteQuery.isLoading}
        style={{ marginTop: "20px" }}
      >
        Edit request details
      </Button>
    </AuthTransfer>
  );
};

export default CRThirdStep;
