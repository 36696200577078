import { ColumnType } from "antd/es/table";
import { FC, ReactNode, useId, useMemo } from "react";
import { TItemAntdTable } from "../..";
import * as Styled from "./style";

interface IProps {
  columns: ColumnType<TItemAntdTable>[];
  item: TItemAntdTable;
}

const getTitle = (data: ReactNode) => {
  if (data === "#empty") {
    return null;
  }
  return (
    <>
      <div>{data}</div>
      <div>:&nbsp;</div>
    </>
  );
};

const AntdMTBlock: FC<IProps> = ({ columns, item }) => {
  const idComponent = useId();

  const arrayItem = useMemo(
    () =>
      Object.entries(item).filter(
        ([key, value]) => key !== "key" && value !== "#empty"
      ),
    [item]
  );

  return (
    <Styled.Wrapper>
      {arrayItem.map(([key, value], index) => {
        const data = columns.find(({ dataIndex }) => String(dataIndex) === key);
        return (
          <Styled.WrapperItem key={idComponent + index}>
            {getTitle(data?.title as ReactNode)}
            <div>{value}</div>
          </Styled.WrapperItem>
        );
      })}
    </Styled.Wrapper>
  );
};

export default AntdMTBlock;
