import PaymentAPI from "../../../../services/paymentAPI";
import { IPayloadValidateSwift } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { getValueError } from "../../data";
import FirstStepCommon from "../../shared/firstStep";
import PaymentBeneficiaryCommon from "../../shared/firstStep/beneficiary/companyInput";
import CommonContinueButton from "../../shared/firstStep/continueButton";
import TransferDocumentCommon from "../../shared/firstStep/transferDocument";
import { IDataCommon, IDataFS, InputSwift } from "../types";
import BeneficiaryRowsSwift from "./beneficiary/rows";
import { getPayload } from "./continueButton/data";
import { getErrorResponse } from "./data";
import PaymentDetaisSwift from "./details";

type TErrorResponse = IErrorPayment<keyof IPayloadValidateSwift["payload"]>[];

const FirstStepSwift = ({
  input,
  setStep,
}: {
  input: InputSwift;
  setStep: (step: number) => void;
}) => {
  const { onChangeDataFS } = input;

  const emptyFields: Partial<IDataFS> = {
    BCountry: getValueError({ text: "", id: "" }),
    BState: getValueError({ text: "", id: "" }),
    BCaState: getValueError({ text: "", id: "" }),
    BCity: getValueError(""),
    BPostalCode: getValueError(""),
    BStreetAddress: getValueError(""),
    BHouseNumber: getValueError(""),
    BAccountNumber: getValueError(""),
    BBic: getValueError(""),
    BInstitutionNumber: getValueError(""),
    BTransitNumber: getValueError(""),
    BAba: getValueError(""),
    BCorrespAccount: getValueError(""),
    BCorrespBIC: getValueError(""),
    BCorrespBankName: getValueError(""),
    BIsOpenCorresp: getValueError(false),
  };

  return (
    <FirstStepCommon
      input={input}
      setStep={setStep}
      validateApi={PaymentAPI.swift.validate}
      draftApi={PaymentAPI.swift.draft}
      getErrorResponse={(errors) => getErrorResponse(errors as TErrorResponse)}
      content={(
        queryValidate,
        infoValidateQuery,
        queryDraft,
        infoDraftQuery
      ) => {
        return (
          <>
            <PaymentDetaisSwift input={input} />
            <PaymentBeneficiaryCommon<IDataFS>
              input={input}
              companyValidationRules={{ isReq: true, min: 3, max: 100 }}
              emptyFields={emptyFields}
              additionalUpdateOnSelectingHintCompany={(data) => {
                const { BCorrespBankName, BCorrespBIC, BCorrespAccount } = data;
                if (
                  !!BCorrespBankName?.value ||
                  !!BCorrespBIC?.value ||
                  !!BCorrespAccount?.value
                ) {
                  onChangeDataFS("BIsOpenCorresp", true);
                }
              }}
            >
              <BeneficiaryRowsSwift input={input} />
            </PaymentBeneficiaryCommon>
            <TransferDocumentCommon
              input={input}
              disabled={infoValidateQuery.isLoading || infoDraftQuery.isLoading}
              lookAtIsRequireFiles={false}
            />
            <CommonContinueButton<IDataFS, IDataCommon>
              input={input}
              queryValidate={(dataFS, dataCommon) => {
                const countryId = dataFS.BCountry.value.id;
                return queryValidate({
                  id: dataCommon.paymentID,
                  payload: getPayload(
                    dataFS,
                    dataCommon.accountId,
                    dataCommon.canadaID == countryId,
                    dataCommon.usID == countryId,
                    dataCommon.useCompanyNameInDescriptionControl
                  ),
                });
              }}
              isLoadingValid={infoValidateQuery.isLoading}
              queryDraft={(dataFS, dataCommon, autoSave) => {
                const countryId = dataFS.BCountry.value.id;
                return queryDraft({
                  id: dataCommon.paymentID,
                  payload: {
                    ...getPayload(
                      dataFS,
                      dataCommon.accountId,
                      dataCommon.canadaID == countryId,
                      dataCommon.usID == countryId,
                      dataCommon.useCompanyNameInDescriptionControl
                    ),
                    autoSave,
                  },
                });
              }}
              isLoadingDraft={infoDraftQuery.isLoading}
            />
          </>
        );
      }}
    />
  );
};

export default FirstStepSwift;
