import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  & > div {
    width: 33.3%;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
`;
