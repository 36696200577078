import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;
  width: 50%;
`;

export const WrapperIcon = styled.div`
  width: 10px;
  & svg {
    width: 10px;
    height: auto;
    cursor: pointer;
  }
`;

export const WrapperText = styled.div`
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
