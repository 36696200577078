import styled from "styled-components";

export const Wrapper = styled.div`
  @media (max-width: 1024px) {
    #titleOfTableItem {
      display: block;
      padding-bottom: 8px;
    }
  }
`;
