import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { FC } from "react";
import { IDataRowTable } from "../../types";
import * as Styled from "./style";

interface IProps {
  index: number;
  data: IDataRowTable[];
  onClick?: (index: number, data: IDataRowTable[]) => void;
}

const TILastIcon: FC<IProps> = ({ index, data, onClick }) => {
  if (!onClick) return <div />;
  return (
    <Styled.Wrapper>
      {onClick && (
        <DeleteOutlined
          onClick={(e) => {
            e.stopPropagation();
            onClick(index, data);
          }}
        />
      )}
    </Styled.Wrapper>
  );
};

export default TILastIcon;
