import { Radio } from "antd";
import { FC } from "react";
import { Text } from "../../../../../commonStyle";
import WrapperTitleError from "../../../../../component/Wrappers/wrapperTitleError";
import { TAuthCode } from "../../../../../services/types";
import * as Styled from "./style";

interface IProps {
  value: TAuthCode;
  error: string;
  setType: (value: TAuthCode) => void;
}

const EPChooseType: FC<IProps> = ({ value, error, setType }) => {
  return (
    <WrapperTitleError title="Authenticator:" error={error}>
      <Styled.WrapperRadio>
        <Radio checked={value === "sms"} onClick={() => setType("sms")}>
          SMS
        </Radio>
        <Radio
          checked={value === "google-authenticator"}
          onClick={() => setType("google-authenticator")}
        >
          Google Authenticator
        </Radio>
      </Styled.WrapperRadio>
    </WrapperTitleError>
  );
};

export default EPChooseType;
