import PaymentAPI from "../../../services/paymentAPI";
import { IResponseInitEft } from "../../../services/paymentAPI/types/response";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import { initDataSS, initDataTS } from "../data";
import PaymentSystem, { TLocationState } from "../shared";
import { requireFiles } from "../shared/validations";
import {
  getCompanyHints,
  getDataFee,
  getDataFromForm,
  getInitFee,
  initDataCommon,
  initDataFS,
} from "./data";
import FirstStepEft from "./firstStep";
import { IDataCommon, IDataFS } from "./types";

const EftComponent = () => {
  return (
    <PaymentSystem<IDataFS, IDataCommon, IResponseInitEft, any, TLocationState>
      isSavePending
      initDataFS={initDataFS}
      initDataSS={initDataSS}
      initDataTS={initDataTS}
      initDataCommon={initDataCommon}
      updateInput={(input) => input}
      infoInitQueryProvider={({ accountId, paymentId, mode }) => {
        return useQueryAPI(PaymentAPI.eft.getInit, {
          payload: { id: accountId || paymentId || "", mode },
          changeIsLoading: true,
        });
      }}
      firstStepComponet={(context, setStep) => {
        return <FirstStepEft input={context} setStep={setStep} />;
      }}
      onInitQueryLoaded={(
        response,
        input,
        onChangeDataFS,
        updateDataCommon
      ) => {
        if (!response.data) {
          return;
        }
        const {
          header,
          beneficiaries,
          account,
          transfer,
          eft_fee_types,
          form = {},
          files = [],
          user,
        } = response.data.data;

        const dataFee = getDataFee(eft_fee_types);

        onChangeDataFS("DFee", getInitFee(eft_fee_types));

        const dataForm = getDataFromForm(
          form,
          files,
          dataFee,
          account.accountName
        );

        dataForm.forEach(([key, value]) => {
          onChangeDataFS(key, value);
        });

        updateDataCommon((prev) => ({
          ...prev,
          dataHeader: header,
          amountSymbol: account.currencySymbol,
          balance: account.balance.available,
          paymentID: transfer.paymentId,
          companyHints: getCompanyHints(beneficiaries),
          accountId: account.accountId,
          isRequireFiles: transfer.options.requireFilesUpload,
          isDisableSaveAsDraft: transfer.options.disableSaveAsDraft,
          useCompanyNameInDescriptionControl:
            transfer.options.useCompanyNameInDescriptionControl,
          companyName: account.accountName,
          prefixDescription: account.accountName,
          dataFee,
          isAbleAuth: user?.userType !== "simple-user",
          isUsingTemplate: input.mode === "edit",
        }));
      }}
      validateAll={(data, dataCommon) => {
        const isRequireFiles =
          dataCommon.isRequireFiles && !data.DIsInterTransfer.value;

        if (isRequireFiles) {
          requireFiles(data.Tfiles);
        }
      }}
    />
  );
};

export default EftComponent;
