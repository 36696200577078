import { UnorderedListOutlined } from "@ant-design/icons";
import { FC, useContext, useMemo } from "react";
import { ContextCardsStatement } from "..";
import DatePickerComponent from "../../../component/Global/DatePicker";
import DateRangePicker from "../../../component/Global/DateRangePicker";
import PickerComponent, { IItemPicker } from "../../../component/Global/Picker";
import { typeToDate } from "../../../utils/typeToDate";
import * as Styled from "./style";

interface IProps {}

const HeaderStatementsID: FC<IProps> = ({}) => {
  const valueContext = useContext(ContextCardsStatement);

  const { data, onChangeData } = valueContext;
  const {
    accountId,
    accountIdData,
    interval,
    intervalData,
    paymentType,
    paymentTypeData,
    startDate,
    endDate,
  } = data;

  const setStartDate = (value: Date) => {
    onChangeData("startDate", value);
    onChangeData("interval", { text: "", id: "" });
  };

  const setEndDate = (value: Date) => {
    onChangeData("endDate", value);
    onChangeData("interval", { text: "", id: "" });
  };

  const infoStartEndDate = useMemo(
    () => ({
      startDate,
      setStartDate,
      endDate,
      setEndDate,
    }),
    [startDate, endDate]
  );

  return (
    <Styled.Wrapper>
      <Styled.WrapperAccount>
        <PickerComponent
          value={accountId}
          setValue={(value) => onChangeData("accountId", value)}
          data={accountIdData}
          disabledError
          placeholder="Select Account"
        />
      </Styled.WrapperAccount>

      <Styled.WrapperDate>
        <DateRangePicker infoDateRange={infoStartEndDate} />
        <div>
          <DatePickerComponent
            value={startDate}
            setDate={setStartDate}
            disabledError
          />
          <DatePickerComponent
            value={endDate}
            setDate={setEndDate}
            disabledError
          />
        </div>
      </Styled.WrapperDate>
      <Styled.WrapperSelect>
        <PickerComponent
          value={interval}
          setValue={(value) => onChangeData("interval", value)}
          data={intervalData}
          icon={<UnorderedListOutlined style={{ color: "#041e42" }} />}
          placeholder="Select Date"
        />
        <PickerComponent
          value={paymentType}
          setValue={(value) => onChangeData("paymentType", value)}
          data={paymentTypeData}
          icon={<UnorderedListOutlined style={{ color: "#041e42" }} />}
          placeholder="Select Payment"
        />
      </Styled.WrapperSelect>
    </Styled.Wrapper>
  );
};

export default HeaderStatementsID;
