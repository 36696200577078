import React, { FC, useId } from "react";
import TypeToTextMap from "../../../../component/Global/TypeToTextMap";
import { IWallets } from "../../../../services/crypto/types";
import { IItemTableAPI } from "../../../../services/types/table";
import { TypeToText } from "../../../../utils/typeToText";
import * as Styled from "./style";
import WalletWrapper from "./wallet";

interface IProps {
  titleData: IItemTableAPI[][];
  wallets: IWallets[];
  realoadPage: () => void;
}

const FSTCryptoTable: FC<IProps> = ({ titleData, wallets, realoadPage }) => {
  const idComponent = useId();

  return (
    <Styled.WrapperColunm>
      <TypeToTextMap data={titleData} />
      {wallets.map((item, index) => (
        <Styled.WrapperRow key={idComponent + ".wallet." + index}>
          <WalletWrapper {...item} realoadPage={realoadPage} />
        </Styled.WrapperRow>
      ))}
    </Styled.WrapperColunm>
  );
};

export default FSTCryptoTable;
