import instance from "..";
import { IFileAPI } from "../types";
import {
  IPayloadInitBuilder,
  IPayloadInvoiceEmailSend,
  IPayloadInvoiceList,
  IPayloadInvoiceListDelete,
  IPayloadInvoiceLogoDelete,
  IPayloadInvoiceLogoUpload,
  IPayloadInvoiceSave,
  IPayloadInvoiceValid,
} from "./types/payload";
import {
  IResInitBuilder,
  IResInvoiceEmail,
  IResInvoiceInitList,
  IResInvoiceLogoUpload,
  IResInvoiceSaveDownload,
  IResInvoiceValid,
} from "./types/response";

export const InvoiceBuilderAPI = {
  initList: (payload: IPayloadInvoiceList) =>
    instance.post<IResInvoiceInitList>("app/init-invoices-list", payload),
  listDelete: (payload: IPayloadInvoiceListDelete) =>
    instance.post("invoice-builder/delete", payload),
  initBuilder: ({ invoiceID }: IPayloadInitBuilder) =>
    instance.get<IResInitBuilder>(
      "/invoice-builder/init" + (invoiceID ? `/${invoiceID}` : "")
    ),
  validate: ({ invoiceID, payload }: IPayloadInvoiceValid) =>
    instance.post<IResInvoiceValid>(
      "invoice-builder/validate/" + invoiceID,
      payload
    ),
  buttonSS: {
    save: ({ invoiceID }: IPayloadInvoiceSave) =>
      instance.post("invoice-builder/store/" + invoiceID),
    email: ({ invoiceID }: IPayloadInvoiceSave) =>
      instance.get<IResInvoiceEmail>("invoice-builder/email/init/" + invoiceID),
    download: ({ invoiceID }: IPayloadInvoiceSave) =>
      instance.get<IFileAPI>("invoice-builder/export/pdf/" + invoiceID),
    saveDownload: ({ invoiceID }: IPayloadInvoiceSave) =>
      instance.post<IResInvoiceSaveDownload>(
        "invoice-builder/store-export/pdf/" + invoiceID
      ),
    emailSend: ({ invoiceID, payload }: IPayloadInvoiceEmailSend) =>
      instance.post("invoice-builder/email/send/" + invoiceID, payload),
  },
  logo: {
    upload: ({ invoiceID, formData }: IPayloadInvoiceLogoUpload) =>
      instance.post<IResInvoiceLogoUpload>(
        "invoice-builder/logo/upload/" + invoiceID,
        formData
      ),
    delete: ({ invoiceID }: IPayloadInvoiceLogoDelete) =>
      instance.post("invoice-builder/logo/delete/" + invoiceID),
  },
};
