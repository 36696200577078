import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  & svg {
    width: 20px;
    height: auto;
  }
`;
