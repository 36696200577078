import { Collapse } from "antd";
import styled from "styled-components";

export const CollapseWrapper = styled(Collapse)`
  .ant-collapse {
    &-item {
      margin: 16px 0 40px;
    }

    &-header {
      padding: 0 16px 0 16px !important;
      align-items: baseline !important;
      box-shadow: rgba(27, 41, 68, 0.1) 0px 0px 20px;
      background-color: rgb(244, 245, 246);

      &-text {
        font-size: 18px;
        font-weight: 500;
        margin: 16px 0px;
        text-transform: uppercase;
        font-family: Poppins-bold;
      }
    }

    &-content {
      position: relative;
      box-shadow: rgba(27, 41, 68, 0.1) 0px 9px 30px;

      &-box {
        padding: 0 !important;

        & > div {
          box-shadow: initial;

          & > div {
            box-shadow: rgba(27, 41, 68, 0.1) 0px 7px 20px -9px;
          }
        }
      }
    }
  }
`;
