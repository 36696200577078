import { useContext } from "react";
import { ContextCardRequest } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import validateString, {
  IRulesString,
} from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

const typeRules: IRulesString = {
  isReq: true,
  min: 3,
  max: 20,
};

const fullNameRules: IRulesString = {
  isReq: true,
  min: 3,
  max: 100,
};

const cardNameRules: IRulesString = {
  isReq: true,
  min: 3,
  max: 21,
};

const CRPDFirstRow = () => {
  const valueContext = useContext(ContextCardRequest);

  const { dataFS, dataCommon, onChangeDataFS, setErrorDataFS } = valueContext;

  const { PDCardType, PDFullName, PDNameCard } = dataFS;

  const onSelectCardType = (value: string) => {
    onChangeDataFS("PDCardType", value);
  };

  const onBlur = (
    key: "PDCardType" | "PDFullName" | "PDNameCard",
    rules: IRulesString
  ) => {
    const error = validateString(dataFS[key].value, rules);

    setErrorDataFS(key, error);
  };

  return (
    <Styled.Wrapper>
      <InputComponent
        {...PDCardType}
        title="Card Applicant Type:"
        placeholder="Select applicant type or describe other"
        onChangeValue={(value) => onChangeDataFS("PDCardType", value)}
        hints={{
          data: dataCommon.PDCardTypeHints,
          onSelect: onSelectCardType,
        }}
        onBlur={() => onBlur("PDCardType", typeRules)}
        maxLength={20}
        id="PDCardType"
      />
      <InputComponent
        {...PDFullName}
        title="Full Name:"
        onChangeValue={(value) => onChangeDataFS("PDFullName", value)}
        onBlur={() => onBlur("PDFullName", fullNameRules)}
        maxLength={100}
        id="PDFullName"
      />
      <InputComponent
        {...PDNameCard}
        title="Name on Card:"
        onChangeValue={(value) => onChangeDataFS("PDNameCard", value)}
        onBlur={() => onBlur("PDNameCard", cardNameRules)}
        maxLength={21}
        id="PDNameCard"
      />
    </Styled.Wrapper>
  );
};

export default CRPDFirstRow;
