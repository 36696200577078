import { IPayloadRequestValidate } from "../../../../../services/cards/types/payload";
import validateString from "../../../../../utils/validate/validateString";
import { IDataFS } from "../../../types";

export const getDisableContinue = (data: IDataFS) => {
  const keys = Object.keys(data) as (keyof IDataFS)[];

  const getError = (key: keyof IDataFS, error = "") => ({
    key,
    error,
  });

  const errors = keys.map((key) => {
    if (key === "DOCBill" || key === "DOCPassport" || key === "DOCCompany")
      return getError(key);

    if (key === "PIDIssueDate" || key === "PIDExpireDate" || key === "PDDate") {
      return {
        key,
        error: data[key]["value"] ? "" : "Date required",
      };
    }

    const value = data[key]["value"];

    if (typeof value === "boolean") {
      return getError(key);
    }

    if (
      data.SDSwitch.value &&
      (key === "SDAdditional" ||
        key === "SDCity" ||
        key === "SDHouseNumber" ||
        key === "SDPostalCode" ||
        key === "SDRegion" ||
        key === "SDCountry" ||
        key === "SDStreet")
    ) {
      return getError(key);
    }

    if (typeof value === "string") {
      return getError(key, validateString(value, { isReq: true }));
    }

    return getError(key, validateString(value.id, { isReq: true }));
  });

  return errors;
};

export const getPayload = (
  data: IDataFS
): IPayloadRequestValidate["payload"] => {
  return {
    cardAgreementConsent: data.TSSwitch.value,
    cardRecordAddressCity: data.RACity.value,
    cardRecordAddressCountryId: data.RACountry.value.id,
    cardRecordAddressNumber: data.RAHouseNumber.value,
    cardRecordAddressPostalCode: data.RAPostalCode.value,
    cardRecordAddressRefinement: data.RAAdditional.value,
    cardRecordAddressRegion: data.RARegion.value,
    cardRecordAddressStreet: data.RAStreet.value,
    cardRecordApplicantType: data.PDCardType.value,
    cardRecordBirthDate: data.PDDate.value.toISOString(),
    cardRecordBirthPlaceCountryId: data.PDPlace.value.id,
    cardRecordDocumentCountryId: data.PIDCountry.value.id,
    cardRecordDocumentExpireDate: data.PIDExpireDate.value?.toISOString() || "",
    cardRecordDocumentIssueDate: data.PIDIssueDate.value?.toISOString() || "",
    cardRecordDocumentNumber: data.PIDDocNumber.value,
    cardRecordEmail: data.PDEmail.value,
    cardRecordHolderName: data.PDNameCard.value,
    cardRecordName: data.PDFullName.value,
    cardRecordNationalityCountryId: data.PDNationality.value.id,
    cardRecordPhone: data.PDMobile.value,
    cardRecordShippingAddressCity: data.SDCity.value,
    cardRecordShippingAddressCountryId: data.SDCountry.value.id,
    cardRecordShippingAddressNumber: data.SDHouseNumber.value,
    cardRecordShippingAddressPostalCode: data.SDPostalCode.value,
    cardRecordShippingAddressRefinement: data.SDAdditional.value,
    cardRecordShippingAddressRegion: data.SDRegion.value,
    cardRecordShippingAddressStreet: data.SDStreet.value,
    cardRecordShippingName: data.SDFullName.value,
    feesAccountId: data.account.value.id,
    flagShipToRegistrationAddress: data.SDSwitch.value,
  };
};
