import { useContext } from "react";
import { ContextCardRequest } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import validateString from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

const CRPDThirdRow = () => {
  const valueContext = useContext(ContextCardRequest);

  const { dataFS, onChangeDataFS, setErrorDataFS } = valueContext;

  const { PDEmail, PDMobile } = dataFS;

  const onBlur = (key: "PDMobile" | "PDEmail") => {
    const error = validateString(dataFS[key].value, {
      isReq: true,
      isEmail: key === "PDEmail",
      min: 3,
      max: key === "PDEmail" ? 255 : 20,
    });

    setErrorDataFS(key, error);
  };

  return (
    <Styled.Wrapper>
      <InputComponent
        {...PDEmail}
        title="Email:"
        onChangeValue={(value) => onChangeDataFS("PDEmail", value)}
        onBlur={() => onBlur("PDEmail")}
        info={[{ message: "Will be used for card activity notifications" }]}
        disabledError={!PDEmail.error.length}
        maxLength={255}
        id="PDEmail"
      />
      <InputComponent
        {...PDMobile}
        title="Phone Number:"
        onChangeValue={(value) => onChangeDataFS("PDMobile", value)}
        onBlur={() => onBlur("PDMobile")}
        info={[{ message: "Will be used for receiving 3DS SMS codes" }]}
        disabledError={!PDMobile.error.length}
        maxLength={20}
        id="PDMobile"
      />
      <div style={{ width: "100%" }} />
    </Styled.Wrapper>
  );
};

export default CRPDThirdRow;
