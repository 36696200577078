import { useContext, useEffect } from "react";
import { ContextCardRequest } from "../../../../..";
import DatePickerComponent from "../../../../../../../component/Global/DatePicker";
import PickerComponent from "../../../../../../../component/Global/Picker";
import * as Styled from "./style";

const today = new Date();

const DateBlock = new Date(
  today.getFullYear() - 18,
  today.getMonth(),
  today.getDate()
);

const CRPDSecondRow = () => {
  const valueContext = useContext(ContextCardRequest);

  const { dataFS, dataCommon, onChangeDataFS } = valueContext;

  const { PDDate, PDPlace, PDNationality } = dataFS;
  const { PDPlaceData, PDNationalityData } = dataCommon;

  useEffect(() => {
    onChangeDataFS("PDDate", DateBlock);
  }, []);

  return (
    <Styled.Wrapper>
      <DatePickerComponent
        {...PDDate}
        title="Date of Birth:"
        setDate={(value) => onChangeDataFS("PDDate", value)}
        maxDate={DateBlock}
        id="PDDate"
      />

      <PickerComponent
        {...PDPlace}
        data={PDPlaceData}
        title="Place of Birth:"
        setValue={(value) => onChangeDataFS("PDPlace", value)}
        placeholder="Select Place of Birth"
        id="PDPlace"
      />

      <PickerComponent
        {...PDNationality}
        data={PDNationalityData}
        title="Nationality:"
        setValue={(value) => onChangeDataFS("PDNationality", value)}
        placeholder="Select Nationality"
        id="PDNationality"
      />
    </Styled.Wrapper>
  );
};

export default CRPDSecondRow;
