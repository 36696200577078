import { ChangeEvent, createRef, FC, useEffect } from "react";
import WrapperTitleError from "../../Wrappers/wrapperTitleError";
import * as Styled from "./style";

interface IStyle {
  width?: string;
  height?: string;
  fontSize?: number;
}
interface IProps {
  value: string;
  onChangeValue?: (value: string) => void;
  error?: string;
  title?: string;
  disabledError?: boolean;
  maxLenght?: number;
  placeholder?: string;
  onBlur?: () => void;
  resizeWhenTyping?: boolean;
  style?: IStyle;
  idKey?: string;
}

const TextareaComponent: FC<IProps> = ({
  value,
  onChangeValue,
  error,
  title,
  maxLenght,
  placeholder,
  disabledError = true,
  onBlur,
  resizeWhenTyping = false,
  style = {},
  idKey,
}) => {
  const refTextarea = createRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (!refTextarea.current || !resizeWhenTyping) return;
    refTextarea.current.style.height = style.height || "40px";
    refTextarea.current.style.height =
      refTextarea.current.scrollHeight + 4 + "px";
  }, [value]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, maxLenght);
    if (onChangeValue) onChangeValue(value);
  };

  return (
    <WrapperTitleError
      title={title}
      error={error}
      disabledError={disabledError}
    >
      <div style={{ lineHeight: 1, width: "100%", display: "flex" }}>
        <Styled.Textarea
          id={idKey}
          value={value}
          ref={refTextarea}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={onBlur}
          {...style}
          isError={!!error && !!error.length}
        />
      </div>
    </WrapperTitleError>
  );
};

export default TextareaComponent;
