import * as Styled from "../../../../shared/firstStep/beneficiary/style";
import { IBANAccountNumberInput } from "../../../../shared/firstStep/components/accountNumberInput";
import { BICInput } from "../../../../shared/firstStep/components/bicInput";
import { InputSepa } from "../../../types";

const ThirdRowBeneficiarySepa = ({ input }: { input: InputSepa }) => {
  return (
    <Styled.Row>
      <Styled.Item flex={2}>
        <IBANAccountNumberInput input={input} />
      </Styled.Item>

      <Styled.Item flex={1}>
        <BICInput input={input} />
      </Styled.Item>
    </Styled.Row>
  );
};

export default ThirdRowBeneficiarySepa;
