import { Button, Modal, Skeleton } from "antd";
import { FC, useEffect, useState } from "react";
import { Text } from "../../../../../../commonStyle";
import InputComponent from "../../../../../../component/Global/Input";
import TextareaComponent from "../../../../../../component/Global/Textarea";
import { InvoiceBuilderAPI } from "../../../../../../services/invoiceBuilder";
import { getChangeError } from "../../../../../../utils/getChangeError";
import useMutationAPI from "../../../../../../utils/usePromise/hookMutationAPI";
import useQueryAPI from "../../../../../../utils/usePromise/hookQueryAPI";
import validateString from "../../../../../../utils/validate/validateString";
import { initData, validateData } from "./data";
import * as Styled from "./style";

interface IProps {
  onCancel: () => void;
  invoiceID: string;
}

const IUModalEmail: FC<IProps> = ({ onCancel, invoiceID }) => {
  const infoInitQuery = useQueryAPI(InvoiceBuilderAPI.buttonSS.email, {
    payload: { invoiceID },
  });

  const [querySend, infoSendQuery] = useMutationAPI(
    InvoiceBuilderAPI.buttonSS.emailSend
  );

  const [data, setData] = useState(initData);
  const { onChangeData, onErrorData } = getChangeError(setData);

  const { email, description, info } = data;

  useEffect(() => {
    const response = infoInitQuery.data?.data;

    if (response) {
      const { emailAddress, emailSubject, emailText } = response.form;
      onChangeData("email", emailAddress);
      onChangeData("description", emailSubject);
      onChangeData("info", emailText);
    }
  }, [infoInitQuery]);

  useEffect(() => {
    const response = infoSendQuery.data?.data;
    if (response) {
      onCancel();
    }
  }, [infoSendQuery]);

  const validate = () => {
    const errorsArr = validateData(data);

    errorsArr.forEach(({ key, error }) => onErrorData(key, error));

    return !errorsArr.filter(({ error }) => !!error.length).length;
  };

  const onClickSend = () => {
    if (!validate()) return;

    querySend({
      invoiceID,
      payload: {
        emailAddress: email.value,
        emailSubject: description.value,
        emailText: info.value,
      },
    });
  };

  const onBlur = (type: keyof typeof initData) => {
    const error = validateString(email.value, {
      isReq: true,
      isEmail: type === "email",
    });
    onErrorData(type, error);
  };

  return (
    <Modal open onCancel={onCancel}>
      {infoInitQuery.isLoading ? (
        <Skeleton paragraph={{ rows: 15 }} active />
      ) : (
        <Styled.Wrapper>
          <Text fontSize={22} textAling="center">
            Email invoice
          </Text>
          <Text fontSize={16} textAling="center">
            Send invoice to remitter
          </Text>
          <InputComponent
            {...email}
            title="Email:"
            onChangeValue={(value) => onChangeData("email", value)}
            onBlur={() => onBlur("email")}
          />
          <InputComponent
            {...description}
            title="Description:"
            onChangeValue={(value) => onChangeData("description", value)}
            onBlur={() => onBlur("description")}
          />
          <TextareaComponent
            {...info}
            title="Info:"
            onChangeValue={(value) => onChangeData("info", value)}
            onBlur={() => onBlur("info")}
          />
          <div>
            <Button
              shape="round"
              size="large"
              type="primary"
              onClick={onClickSend}
            >
              Send Email
            </Button>
          </div>
        </Styled.Wrapper>
      )}
    </Modal>
  );
};

export default IUModalEmail;
