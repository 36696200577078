import { IPayloadValidateInternal } from "../../../../../services/paymentAPI/types/payload";
import { IDataFS } from "../../types";

export const getPayload = (
  data: IDataFS,
  accountId: string,
  useCompanyName: boolean
): IPayloadValidateInternal["payload"] => {
  let payload: IPayloadValidateInternal["payload"] = {
    accountId,
    paymentAmount: parseFloat(data.DAmount.value),
    paymentDescription: data.DPaymentDescription.value,
    paymentDescriptionCompanyName: useCompanyName && data.DIsCompanyName.value,
    accountIdTo: data.BCompany.value.id,
  };

  return payload;
};
