import styled from "styled-components";

export const WrapperCompanyAt = styled.div`
  margin-top: 24px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  gap: 32px;
`;
