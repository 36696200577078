import styled from "styled-components";

export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px 0;
  @media (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  gap: 0 10px;
`;
