import { FC } from "react";
import { CryptoFiatInput, IContext } from "../../../../types";
import * as Styled from "../../../../../payments/shared/firstStep/beneficiary/style";
import { CountryPicker } from "../../../../../payments/shared/firstStep/components/countryPicker";
import { StatePicker } from "../../../../../payments/shared/firstStep/components/statePicker";
import { CanadaStatePicker } from "../../../../../payments/shared/firstStep/components/canadaStatePicker";
import { CityInput } from "../../../../../payments/shared/firstStep/components/cityInput";
import { PostalCodeInput } from "../../../../../payments/shared/firstStep/components/postalCodeInput";

interface IProps {
  isUS: boolean;
  isCanada: boolean;
  input: CryptoFiatInput;
}

const FirstRowCounterpartyCryptoFiat: FC<IProps> = ({
  input,
  isUS,
  isCanada,
}) => {
  return (
    <Styled.Row>
      <Styled.Item flex={1}>
        <CountryPicker input={input} />
      </Styled.Item>

      {isUS && (
        <Styled.Item flex={1}>
          <StatePicker input={input} />
        </Styled.Item>
      )}

      {isCanada && (
        <Styled.Item flex={1}>
          <CanadaStatePicker input={input} />
        </Styled.Item>
      )}

      <Styled.Item flex={1}>
        <CityInput input={input} />
      </Styled.Item>

      {!isUS && !isCanada && (
        <Styled.Item flex={1}>
          <PostalCodeInput input={input} />
        </Styled.Item>
      )}
    </Styled.Row>
  );
};

export default FirstRowCounterpartyCryptoFiat;
