import { Button } from "antd";
import { useContext, useEffect, useMemo } from "react";
import { ContextInvoiceUse } from "../../..";
import { InvoiceBuilderAPI } from "../../../../../services/invoiceBuilder";
import { IPayloadInvoiceValid } from "../../../../../services/invoiceBuilder/types/payload";
import { IErrorPayment } from "../../../../../services/types";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { scrollToError } from "../../../../../utils/scrollToError";

import {
  getErrorResponse,
  getPayloadAPI,
  validateCommonData,
  validateData,
} from "./data";
import * as Styled from "./style";
import { splitKey } from "../../../../../utils/getIndexedKey";

type TErrorsResponse =
  | IErrorPayment<keyof IPayloadInvoiceValid["payload"]>[]
  | undefined;

const IUFSContinue = () => {
  const { dataFS, dataCommon, setStep, onErrorData, setDataSS, setDataCommon } =
    useContext(ContextInvoiceUse);
  const { invoiceID, dataTable, prefixRef } = dataCommon;

  const [queryValid, infoValidQuery] = useMutationAPI(
    InvoiceBuilderAPI.validate
  );

  useEffect(() => {
    const response = infoValidQuery.data?.data;
    const responseError = infoValidQuery.error?.response?.data;

    if (response) {
      const {
        header,
        beneficiary,
        remitter,
        payment_details,
        invoice_items,
        footer,
      } = response;

      setDataSS((prev) => ({
        ...prev,
        headerData: header.data,
        beneficiary: beneficiary,
        remmiter: remitter,
        details: payment_details,
        table: invoice_items.data,
        footer: footer.data,
        logo: header.headerLogoContents,
      }));
      setStep(2);
    }

    if (responseError) {
      const resError = responseError.errors as TErrorsResponse;

      if (!resError) return;

      const { errors, errorTable } = getErrorResponse(resError);
      errors.forEach(({ key, error }) => onErrorData(key, error));

      setDataCommon((prev) => {
        const dataTable = [...prev.dataTable];

        errorTable.forEach(({ key, index, error }) => {
          if (isNaN(index)) return;

          dataTable[index][key].error = error;
        });

        return { ...prev, dataTable };
      });
    }
  }, [infoValidQuery]);

  const validate = () => {
    const errorsFS = validateData(dataFS, !!prefixRef.length).filter(
      ({ error }) => !!error.length
    );
    errorsFS.forEach(({ key, error }) => onErrorData(key, error));

    const commonErrors = validateCommonData(dataCommon);

    let newDataCommon = dataCommon;
    commonErrors.forEach(({ key: errorKeyId, error: errorMessage }) => {
      const { key: errorKey, index: errorKeyIndex } = splitKey(errorKeyId);
      newDataCommon = {
        ...newDataCommon,
        dataTable: newDataCommon.dataTable.map((row, index) => {
          if (index === errorKeyIndex) {
            return {
              ...row,
              [errorKey]: {
                ...row[errorKey as keyof typeof row],
                error: errorMessage,
              },
            };
          } else {
            return row;
          }
        }),
      };
    });
    setDataCommon(newDataCommon);

    const errors = [...errorsFS, ...commonErrors];

    scrollToError(errors);

    return !errors.filter(({ error }) => !!error.length).length;
  };

  const onClickContinue = () => {
    if (validate()) {
      queryValid({
        invoiceID,
        payload: getPayloadAPI(dataFS, dataTable, prefixRef),
      });
    }
  };

  return (
    <Styled.Wrapper>
      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onClickContinue}
        loading={infoValidQuery.isLoading}
      >
        Continue to invoice preview
      </Button>
    </Styled.Wrapper>
  );
};

export default IUFSContinue;
