import { FC, useEffect, useState } from "react";
import UserAPI from "../../../services/userAPI";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import EPChangePassword from "./changePassword";
import EPDetails from "./details";
import EPFooter from "./footer";
import { IData, TOnChangeData, TSetErrorData } from "../types";
import { IItemPicker } from "../../../component/Global/Picker";
import { useNavigate } from "react-router-dom";
import * as Styled from "./style";
import { validateNewOldPasswords } from "./changePassword/utils";
import { validateUsernames } from "./details/nameLogin/utils";

interface IProps {
  onChangeData: TOnChangeData;
  setErrorData: TSetErrorData;
  initialized: boolean;
  data: IData;
  dataTimezone: IItemPicker[];
}

const calculateIdentity = (data: IData): String => {
  return JSON.stringify(
    Object.keys(data)
      .map((it) => it as keyof typeof data)
      .map((key) => [key, data[key].value])
  );
};

const EPSetting: FC<IProps> = ({
  onChangeData,
  data,
  initialized,
  setErrorData,
  dataTimezone,
}) => {
  const [querySave, infoSaveQuery] = useMutationAPI(UserAPI.edit.saveEdit);
  const [initialData, setInitalData] = useState(data);

  useEffect(() => {
    if (initialized) {
      setInitalData(data);
    }
  }, [initialized]);

  const passValidation = validateNewOldPasswords(
    data.oldPassword.value,
    data.newPassword.value
  );

  const isButtonDisabled =
    calculateIdentity(initialData) === calculateIdentity(data) ||
    (data.newPassword.value !== "" &&
      data.oldPassword.value !== "" &&
      (passValidation[0] !== "" || passValidation[1] !== "")) ||
    (initialData.login.value !== data.login.value &&
      validateUsernames(data.login.value) !== "") ||
    (initialData.name.value !== data.name.value &&
      validateUsernames(data.name.value) !== "");

  const navigate = useNavigate();

  useEffect(() => {
    const error = infoSaveQuery.error?.response?.data;

    if (error) {
      const oldPasswordMessage = error.errors?.find(
        (it) => it.field === "userPassword"
      )?.message;
      if (oldPasswordMessage) {
        setErrorData("oldPassword", oldPasswordMessage);
      }

      const newPasswordMessage = error.errors?.find(
        (it) => it.field === "userPasswordNew"
      )?.message;
      if (newPasswordMessage) {
        setErrorData("newPassword", newPasswordMessage);
      }

      const userLoginMessage = error.errors?.find(
        (it) => it.field === "userLogin"
      )?.message;
      if (userLoginMessage) {
        setErrorData("login", userLoginMessage);
      }

      const userNameMessage = error.errors?.find(
        (it) => it.field === "userName"
      )?.message;
      if (userNameMessage) {
        setErrorData("name", userNameMessage);
      }

      return;
    }

    const response = infoSaveQuery.data;

    if (response) {
      localStorage.removeItem("token");
      navigate("/login");
    }
  }, [infoSaveQuery]);

  const onCkickUpdate = () => {
    const { login, authType, name, newPassword, oldPassword, timezone } = data;
    querySave({
      userLogin: login.value,
      userMultiFactorType: authType.value,
      userName: name.value,
      userPassword: oldPassword.value,
      userPasswordNew: newPassword.value,
      userTimezone: timezone.value.text,
    });
  };

  return (
    <Styled.Wrappper>
      <EPDetails
        onChangeData={onChangeData}
        data={data}
        dataTimezone={dataTimezone}
        setErrorData={setErrorData}
      />
      <EPChangePassword
        oldPassword={data.oldPassword}
        newPassword={data.newPassword}
        onChangeData={onChangeData}
        setErrorData={setErrorData}
      />
      <EPFooter
        onCkick={onCkickUpdate}
        isLoading={infoSaveQuery.isLoading}
        isDisabled={isButtonDisabled}
      />
    </Styled.Wrappper>
  );
};

export default EPSetting;
