import { IPayloadValidateBulk } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { IDataFS } from "../types";

const keyResponse: {
  [key in keyof Required<IPayloadValidateBulk["payload"]>]: keyof IDataFS;
} = {
  fxPaymentAmount: "DAmount",
  accountId: "DAmount",
  accountIdTo: "BCompany",
  fxPaymentCurrencyId: "DAmount",
  paymentDescription: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
};

export const getErrorResponse = (
  errorProps: IErrorPayment<keyof IPayloadValidateBulk["payload"]>[]
) =>
  errorProps.map(({ field, message }) => ({
    key: keyResponse[field],
    error: message,
  }));
