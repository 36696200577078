import { CloseOutlined, ExportOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TextBold } from "../../../../../../commonStyle";
import {
  setIsAuth,
  setIsOpenNav,
  setIsQuestionLeave,
} from "../../../../../../store/user";
import { useAppSelector } from "../../../../../../utils/useState";
import NavBar from "../../../navBar";
import * as Styled from "./style";
import NBModalLeave from "../../../navBar/modalLeave";

interface IProps {
  onClose: () => void;
}

const HBMenu: FC<IProps> = ({ onClose }) => {
  const { userName, isOpenNav, isQuestionLeave } = useAppSelector(
    (state) => state.userReducer
  );
  const [willEditProfile, setWillEditProfile] = useState<boolean>(false);
  const [willLogOut, setWillLogOut] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    localStorage.removeItem("token");
    dispatch(setIsOpenNav(!isOpenNav));
    dispatch(setIsAuth(false));
    navigate("/login");
  };

  const onEditProfile = () => {
    if (isQuestionLeave) {
      setWillEditProfile(true);
    } else {
      onClose();
      navigate("/edit-profile");
    }
  };

  const onLogOut = () => {
    if (isQuestionLeave) {
      setWillLogOut(true);
    } else {
      logOut();
    }
  };

  return (
    <Styled.Wrapper isOpen={isOpenNav}>
      {willEditProfile && (
        <NBModalLeave
          onClickStay={() => setWillEditProfile(false)}
          onClickLeave={() => {
            onClose();
            navigate("/edit-profile");
            dispatch(setIsQuestionLeave(false));
          }}
        />
      )}

      {willLogOut && (
        <NBModalLeave
          onClickStay={() => setWillLogOut(false)}
          onClickLeave={() => {
            logOut();
            dispatch(setIsQuestionLeave(false));
          }}
        />
      )}

      {isOpenNav && <Styled.Background onClick={onClose} />}
      <Styled.WrapperBody isOpen={isOpenNav}>
        <Styled.WrapperHeader>
          <Styled.WrapperName>
            <TextBold fontSize={16} id="HBUserName">
              {userName}
            </TextBold>
            <TextBold
              fontSize={16}
              color="#6c64f4"
              onClick={onEditProfile}
              isCursor
            >
              Edit Profile
            </TextBold>
          </Styled.WrapperName>
          <CloseOutlined onClick={onClose} />
        </Styled.WrapperHeader>
        <NavBar />
        <Styled.WrapperFooter onClick={onLogOut}>
          <ExportOutlined />
          <TextBold>Log Out</TextBold>
        </Styled.WrapperFooter>
      </Styled.WrapperBody>
    </Styled.Wrapper>
  );
};

export default HBMenu;
