import { FC } from "react";
import * as Styled from "./style";
import {
  AuditOutlined,
  BellOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  ClockCircleOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  DeploymentUnitOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FolderOutlined,
  MailOutlined,
  ProfileOutlined,
  SendOutlined,
  UsbOutlined,
  UserSwitchOutlined,
  WarningOutlined,
  CloudDownloadOutlined,
  DeliveredProcedureOutlined,
} from "@ant-design/icons";
import { BtcIcon } from "../../../../assets";

interface IProps {
  type: string;
}

const IconNav: FC<IProps> = ({ type }) => {
  switch (type) {
    case "dashboard": {
      return <BellOutlined />;
    }
    case "new-payment": {
      return <SendOutlined />;
    }
    case "statements": {
      return <FileDoneOutlined />;
    }
    case "cards": {
      return <CreditCardOutlined />;
    }
    case "mass-payments": {
      return <ProfileOutlined />;
    }
    case "izzio-payouts": {
      return <UserSwitchOutlined />;
    }
    case "accounts-details": {
      return <FileTextOutlined />;
    }
    case "contact": {
      return <MailOutlined />;
    }
    case "bulk-payments": {
      return <FolderOutlined />;
    }
    case "crypto": {
      return (
        <Styled.WrapperIcon>
          <BtcIcon />
        </Styled.WrapperIcon>
      );
    }
    case "action-required": {
      return <WarningOutlined />;
    }
    case "saved-payments": {
      return <ClockCircleOutlined />;
    }
    case "credit-documents": {
      return <AuditOutlined />;
    }
    case "invoices-templates": {
      return <ContainerOutlined />;
    }
    case "recurring-payments": {
      return <CalendarOutlined />;
    }
    case "transactions-report": {
      return <DeploymentUnitOutlined />;
    }
    case "settlements-report": {
      return <CarryOutOutlined />;
    }
    case "docs": {
      return <CloudDownloadOutlined />;
    }
    case "crypto-fiat-pay-in-request": {
      return <DeliveredProcedureOutlined />;
    }
    default: {
      return <UsbOutlined />;
    }
  }
};

export default IconNav;
