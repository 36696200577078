import CRSDFirstRow from "./firstRow";
import CRSDSecondRow from "./secondRow";

const CRSDRows = () => {
  return (
    <div>
      <CRSDFirstRow />
      <CRSDSecondRow />
    </div>
  );
};

export default CRSDRows;
