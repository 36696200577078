import { InputRef } from "antd";
import { createRef, useContext, useEffect } from "react";
import { CardTopUpContext } from "../..";
import ExecutePayment from "../../../../component/payment/execute";

export interface IDataThirdStepPayment {
  amount: string;
  name: string;
}

const CTUThirdStep = () => {
  const valueContext = useContext(CardTopUpContext);
  const refInput = createRef<InputRef>();

  const { dataCommon, dataTS, setStep, step } = valueContext;
  const { paymentId, checksum } = dataCommon;

  useEffect(() => {
    if (step === 3) {
      setTimeout(() => {
        if (refInput.current) refInput.current.focus();
      }, 1000);
    }
  }, [step]);

  return (
    <ExecutePayment
      {...dataTS}
      navOnError="/cards"
      setStep={setStep}
      checksum={checksum}
      paymentId={paymentId}
      ref={refInput}
    />
  );
};

export default CTUThirdStep;
