import * as Styled from "./style";
import { FC } from "react";
import { logoImg } from "../../../assets";
import { Outlet } from "react-router-dom";

const WrapperAuth: FC = () => {
  return (
    <Styled.Wrapper>
      <Styled.ImgWrapper>
        <img src={logoImg} />
      </Styled.ImgWrapper>

      <div style={{ width: "100%" }}>
        <Outlet />
      </div>
    </Styled.Wrapper>
  );
};

export default WrapperAuth;
