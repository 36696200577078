import { LockOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Text } from "../../commonStyle";
import InputComponent from "../../component/Global/Input";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import { IItemTableAPI } from "../../services/types/table";
import UserAPI from "../../services/userAPI";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import * as Styled from "./style";
import OptionalErrorAlert from "../../component/OptionalErrorAlert";

interface ILocation {
  state?: {
    userId: string;
    data: IItemTableAPI[][];
    title: IItemTableAPI[][];
  };
}

const ResetUPComponent = () => {
  const { state } = useLocation() as ILocation;
  const { userId = "", data = [], title = [] } = state || {};
  const navigate = useNavigate();

  const [queryUpload, infoUploadQuery] = useMutationAPI(
    UserAPI.reset.updatePassword
  );

  const [password, setPassword] = useState({ value: "", error: "" });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    error: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (!userId.length) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (infoUploadQuery.data) {
      localStorage.removeItem("token");
      navigate("/login");
    }
  }, [infoUploadQuery]);

  const validatePassword = () => {
    const reg = /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,128}$/;

    if (password.value !== confirmPassword.value) {
      setError("Password mismatch");
      return false;
    }

    if (!reg.test(password.value)) {
      setError("Password is not strong enough");
      return false;
    }

    return true;
  };

  const onClickUpdate = () => {
    if (validatePassword()) {
      setError("");
      queryUpload({
        userId,
        userPasswordNew: password.value,
        userPasswordNewConfirm: confirmPassword.value,
      });
    }
  };

  return (
    <>
      <OptionalErrorAlert message={error} />
      <Styled.Wrapper>
        <Text fontSize={18}>Password Change Required</Text>
        <TypeToTextMap
          data={title}
          styleData={({ type }) => (type === "em" ? {} : { fontSize: 14 })}
        />
        <InputComponent
          {...password}
          onChangeValue={(value) => setPassword({ error: "", value })}
          prefix={<LockOutlined />}
          placeholder="New Password"
          onKeyEnter={onClickUpdate}
          type="password"
          disabledError
        />
        <InputComponent
          {...confirmPassword}
          onChangeValue={(value) => setConfirmPassword({ error: "", value })}
          prefix={<LockOutlined />}
          placeholder="Confirm Password"
          onKeyEnter={onClickUpdate}
          type="password"
        />
        <Button
          shape="round"
          size="large"
          onClick={onClickUpdate}
          loading={infoUploadQuery.isLoading}
          block
        >
          Update Password
        </Button>
        <TypeToTextMap
          data={data}
          styleData={({ type }) => (type === "em" ? {} : { fontSize: 14 })}
        />
      </Styled.Wrapper>
    </>
  );
};

export default ResetUPComponent;
