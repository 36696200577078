import { IPayloadValidateSepa } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { IDataFS } from "../types";

const keyResponse: {
  [key in keyof Required<IPayloadValidateSepa["payload"]>]: keyof IDataFS;
} = {
  paymentAccountNumberTo: "BAccountNumber",
  paymentAmount: "DAmount",
  paymentBankBic: "BBic",
  paymentDescription: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
  paymentIsSameCompanyTransfer: "DIsInterTransfer",
  paymentName: "BCompany",
  paymentPriorityType: "DIsUrgent",
  paymentNameAddressCity: "BCity",
  paymentNameAddressNumber: "BHouseNumber",
  paymentNameAddressPostalCode: "BPostalCode",
  paymentNameAddressStreet: "BStreetAddress",
  paymentNameAddressState: "BState",
  paymentNameCountryId: "BCountry",
  accountId: "DAmount",
};

export const getErrorResponse = (
  errorProps: IErrorPayment<keyof IPayloadValidateSepa["payload"]>[]
) =>
  errorProps.map(({ field, message }) => ({
    key: keyResponse[field],
    error: message,
  }));
