import { FC } from "react";
import { useTranslation } from "react-i18next";
import InputComponent from "../../../component/Global/Input";
import PickerComponent from "../../../component/Global/Picker";
import { credItem } from "../data";
import { ICredState } from "../types";

interface IProps {
  data: ICredState;
  errors: ICredState;
  onChangeValue: (name: keyof ICredState, value: string) => void;
  onBlurInput: (name: keyof ICredState) => void;
  onLogin: () => void;
}

const LFSItem: FC<IProps> = ({
  data,
  errors,
  onBlurInput,
  onChangeValue,
  onLogin,
}) => {
  return (
    <>
      {credItem.map(({ name, img, placeholder }) => {
        return (
          <InputComponent
            key={"LoginPage." + name}
            value={data[name]}
            prefix={img}
            placeholder={placeholder}
            onChangeValue={(value: string) => {
              onChangeValue(name, value);
            }}
            onBlur={() => {
              onBlurInput(name);
            }}
            onKeyEnter={onLogin}
            error={errors[name]}
            type={name === "password" ? name : "text"}
          />
        );
      })}
    </>
  );
};

export default LFSItem;
