import { Navigate, Outlet } from "react-router-dom";

const ReCaptchaRoute = () => {
  const divReCaptcha = document.querySelector(".grecaptcha-badge");
  if (divReCaptcha && divReCaptcha.parentElement) {
    divReCaptcha.parentElement.style.display = "block";
  }

  // localStorage.setItem("token", "");

  return <Outlet />;
};

export default ReCaptchaRoute;
