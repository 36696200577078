import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 1024px) {
    flex-direction: column;
    & > * {
      width: 100%;
    }
  }
`;
