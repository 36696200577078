import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 20px 0;
`;

export const WrapperButton = styled.div`
  margin-top: 20px;
  @media (max-width: 1024px) {
    position: sticky;
    bottom: 20px;
  }
`;
