import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 20px 0;
  gap: 16px 48px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
