import { Button } from "antd";
import { useContext, useEffect, useMemo } from "react";
import { ContextCredDoc } from "../..";
import AmountComponent from "../../../../component/Global/Amount";
import InputComponent from "../../../../component/Global/Input";
import PickerComponent from "../../../../component/Global/Picker";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { CreditDocApi } from "../../../../services/creditDoc";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import useSizePage from "../../../../utils/hookSizePage";
import { scrollToError } from "../../../../utils/scrollToError";
import validateNumber from "../../../../utils/validate/validateNumber";
import validateString from "../../../../utils/validate/validateString";
import { validate } from "./data";
import * as Styled from "./style";
import CDUTransferDocument from "./transferDocument";

type TKeySetData =
  | "benefAccount"
  | "remitterName"
  | "paymentDescription"
  | "amount"
  | "files";

const CredDocFirstStep = () => {
  const valueContext = useContext(ContextCredDoc);
  const { width } = useSizePage();

  const [queryValidate, infoQueryValidate] = useMutationAPI(
    CreditDocApi.validate
  );

  const { data, setData, setStep } = valueContext;
  const { dataBenefAccount, requestId } = data;
  const {
    benefAccount,
    remitterName,
    paymentDescription,
    amount,
    files,
    dataBenefCurrency,
  } = data;

  const onErrorData = (error: string, key: TKeySetData) => {
    setData((prev) => ({ ...prev, [key]: { ...prev[key], error } }));
  };

  useEffect(() => {
    const response = infoQueryValidate.data;
    if (response) {
      setData((prev) => ({ ...prev, dataSecondStep: response.data.data }));
      setStep(2);
    }

    const error = infoQueryValidate.error?.response?.data;

    if (error) {
      const amountMessage = error.errors?.find(
        (it) => it.field === "paymentAmount"
      )?.message;
      if (amountMessage) {
        onErrorData(amountMessage, "amount");
      }
    }
  }, [infoQueryValidate]);

  const isDisableContinue = useMemo(() => {
    const dataCheck = [
      benefAccount,
      remitterName,
      paymentDescription,
      amount,
      files,
    ];
    const dataError = dataCheck.filter(({ error }) => !!error.length);
    const isLoadingFiles = files.value
      .map(({ isLoading }) => isLoading)
      .filter((item) => item);

    return !!isLoadingFiles.length;
  }, [data]);

  const onChangeData = <
    T extends (typeof data)[D]["value"],
    D extends TKeySetData
  >(
    value: T,
    key: D
  ) => {
    setData((prev) => ({ ...prev, [key]: { error: "", value } }));
  };

  const prefixAmount = useMemo(
    () =>
      dataBenefCurrency.find(({ id }) => id === benefAccount.value.id)?.text ||
      "",
    [benefAccount]
  );

  const validateData = () => {
    const errorData = validate(
      {
        benefAccount,
        remitterName,
        paymentDescription,
        amount,
      },
      " " + prefixAmount
    ).filter(({ error }) => !!error.length);

    scrollToError(errorData);

    errorData.map(({ key, error }) => onErrorData(error, key));

    if (!files.value.length) {
      onErrorData("Document(s) required", "files");
      if (!errorData.length)
        scrollToError([{ key: "files", error: "Document(s) required" }]);
      return false;
    }

    return !errorData.filter(({ error }) => !!error.length).length;
  };

  const onClickContinue = () => {
    if (validateData()) {
      queryValidate({
        requestId,
        payload: {
          accountIdTo: benefAccount.value.id,
          paymentAmount: parseFloat(amount.value),
          paymentDescription: paymentDescription.value,
          paymentName: remitterName.value,
        },
      });
    }
  };

  const onBlurAmount = () => {
    const error = validateNumber(
      parseFloat(amount.value) || 0,

      {
        min: 0.01,
      },
      {
        symbol: prefixAmount,
      }
    );
    onErrorData(error, "amount");
  };

  const onBlurInput = (type: "remitterName" | "paymentDescription") => {
    const error = validateString(data[type].value, {
      isReq: true,
      min: 3,
      max: type === "remitterName" ? 50 : 100,
    });
    onErrorData(error, type);
  };

  useEffect(() => {
    if (amount.error) {
      onBlurAmount();
    }
  }, [benefAccount.value]);

  return (
    <div>
      <WrapperWhiteBG title="Payment Details">
        <PickerComponent
          {...benefAccount}
          title="Beneficiary's account:"
          setValue={(value) => onChangeData(value, "benefAccount")}
          data={dataBenefAccount}
          placeholder="Select Account"
          id="benefAccount"
        />
        <AmountComponent
          {...amount}
          onChangeValue={(value) => onChangeData(value, "amount")}
          title="Amount:"
          prefix={prefixAmount + " "}
          placeholder={prefixAmount + " 0.00"}
          width={width > 1024 ? "33%" : "100%"}
          id="amount"
          charLimit={10}
          onBlur={onBlurAmount}
        />

        <InputComponent
          {...remitterName}
          onChangeValue={(value) => onChangeData(value, "remitterName")}
          title="Remitter's name:"
          id="remitterName"
          maxLength={50}
          onBlur={() => onBlurInput("remitterName")}
        />
        <InputComponent
          {...paymentDescription}
          onChangeValue={(value) => onChangeData(value, "paymentDescription")}
          title="Payment description:"
          id="paymentDescription"
          maxLength={100}
          onBlur={() => onBlurInput("paymentDescription")}
        />
      </WrapperWhiteBG>

      <CDUTransferDocument
        setValue={(value) => onChangeData(value, "files")}
        setError={(error) => onErrorData(error, "files")}
      />

      <Styled.WrapperButton>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={onClickContinue}
          disabled={isDisableContinue}
          loading={infoQueryValidate.isLoading}
        >
          Continue
        </Button>
      </Styled.WrapperButton>
    </div>
  );
};

export default CredDocFirstStep;
