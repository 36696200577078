import styled from "styled-components";
import { TPositionTextTable } from "../../types";

interface IWrapperText {
  type: TPositionTextTable;
}

const typeToAlingItem = (type: TPositionTextTable) => {
  if (type === "left") return "flex-start";
  if (type === "right") return "flex-end";
  return "center";
};

export const WrapperText = styled.div<IWrapperText>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ type }) => typeToAlingItem(type)};
  gap: 0 5px;
`;

interface IWrapperItem {
  type: TPositionTextTable;
}

export const WrapperItem = styled.div<IWrapperItem>`
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: ${({ type }) => typeToAlingItem(type)};
  width: 100%;
`;

interface IWrapperBody {
  width: string;
}

export const WrapperBody = styled.div<IWrapperBody>`
  width: ${({ width }) => width};

  & > div:first-child {
    display: none;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
