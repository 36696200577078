import PaymentAPI from "../../../services/paymentAPI";
import {
  getCompanyHints,
  getDataFee,
  getDataFromForm,
  getInitFee,
  initDataCommon,
  initDataFS,
} from "./data";
import FirstStepSwift from "./firstStep";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import { initDataSS, initDataTS } from "../data";
import PaymentSystem, { TLocationState } from "../shared";
import { IDataCommon, IDataFS } from "./types";
import { IResponseInitSwift } from "../../../services/paymentAPI/types/response";
import { requireAllFieldsOrNone, requireFiles } from "../shared/validations";

const SwiftComponent = () => {
  return (
    <PaymentSystem<
      IDataFS,
      IDataCommon,
      IResponseInitSwift,
      any,
      TLocationState
    >
      isSavePending
      initDataFS={initDataFS}
      initDataSS={initDataSS}
      initDataTS={initDataTS}
      initDataCommon={initDataCommon}
      updateInput={(input) => input}
      infoInitQueryProvider={({ accountId, paymentId, mode }) => {
        return useQueryAPI(PaymentAPI.swift.getInit, {
          payload: { id: accountId || paymentId || "", mode },
          changeIsLoading: true,
        });
      }}
      firstStepComponet={(context, setStep) => {
        return <FirstStepSwift input={context} setStep={setStep} />;
      }}
      onInitQueryLoaded={(
        response,
        input,
        onChangeDataFS,
        updateDataCommon
      ) => {
        if (!response.data) {
          return;
        }

        const {
          header,
          beneficiaries,
          account,
          dictionaries,
          transfer,
          swift_fee_types,
          form = {},
          files = [],
          user,
        } = response.data.data;

        const dataCountry = dictionaries.countries.map(
          ({ countryId, countryName }) => ({
            text: countryName,
            id: countryId,
          })
        );
        const dataState = dictionaries.us_states.map(
          ({ stateCode, stateName }) => ({
            text: stateName,
            id: stateCode,
          })
        );

        const dataCaState = dictionaries.ca_states.map(
          ({ stateCode, stateName }) => ({
            text: stateName,
            id: stateCode,
          })
        );

        const dataFee = getDataFee(swift_fee_types);

        onChangeDataFS("DFee", getInitFee(swift_fee_types));

        const dataForm = getDataFromForm(
          form,
          files,
          dataCountry,
          dataState,
          dataCaState,
          dataFee,
          account.accountName
        );

        dataForm.forEach(([key, value]) => {
          if (
            key === "BCorrespAccount" ||
            key === "BCorrespBIC" ||
            key === "BCorrespBankName"
          ) {
            onChangeDataFS("BIsOpenCorresp", true);
          }
          onChangeDataFS(key, value);
        });

        updateDataCommon((prev) => ({
          ...prev,
          dataHeader: header,
          amountSymbol: account.currencySymbol,
          balance: account.balance.available,
          canadaID: dictionaries.country_id_ca,
          usID: dictionaries.country_id_us,
          paymentID: transfer.paymentId,
          dataCountry,
          dataState,
          dataCaState,
          companyHints: getCompanyHints(beneficiaries, dictionaries),
          accountId: account.accountId,
          isRequireFiles: transfer.options.requireFilesUpload,
          isDisableSaveAsDraft: transfer.options.disableSaveAsDraft,
          useCompanyNameInDescriptionControl:
            transfer.options.useCompanyNameInDescriptionControl,
          companyName: account.accountName,
          prefixDescription: account.accountName,
          dataFee,
          isAbleAuth: user?.userType !== "simple-user",
          isUsingTemplate: input.mode === "edit",
        }));
      }}
      validateAll={(data, dataCommon) => {
        requireAllFieldsOrNone(data, [
          "BCorrespAccount",
          "BCorrespBIC",
          "BCorrespBankName",
        ]);

        const isRequireFiles =
          dataCommon.isRequireFiles && !data.DIsInterTransfer.value;

        if (isRequireFiles) {
          requireFiles(data.Tfiles);
        }
      }}
      formConfig={{
        fieldGroups: [["BCorrespAccount", "BCorrespBIC", "BCorrespBankName"]],
      }}
    />
  );
};

export default SwiftComponent;
