import { Button } from "antd";
import { useContext, useEffect, useMemo } from "react";
import { ContextCardRequest } from "../../..";
import { CardAPI } from "../../../../../services/cards";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { scrollToError } from "../../../../../utils/scrollToError";
import { IDataFS } from "../../../types";
import { getDisableContinue, getPayload } from "./data";
import * as Styled from "./style";

const CRFSContinue = () => {
  const valueContext = useContext(ContextCardRequest);

  const { dataFS, dataCommon, setErrorDataFS, setDataSS, setStep } =
    valueContext;
  const { isLoadingFile, cardRecordId } = dataCommon;

  const [queryValid, infoValidQuery] = useMutationAPI(CardAPI.request.validate);

  const isDisable = useMemo(() => {
    let keysError = Object.keys(dataFS) as (keyof IDataFS)[];

    if (dataFS.SDSwitch.value) {
      keysError = keysError.filter(
        (key) =>
          key !== "SDAdditional" &&
          key !== "SDCity" &&
          key !== "SDHouseNumber" &&
          key !== "SDPostalCode" &&
          key !== "SDRegion" &&
          key !== "SDCountry" &&
          key !== "SDStreet"
      );
    }

    const isError = keysError.filter((key) => !!dataFS[key].error.length);

    return isLoadingFile || !dataFS.TSSwitch.value;
  }, [dataFS, isLoadingFile]);

  const validateData = () => {
    const errors = getDisableContinue(dataFS).filter(
      ({ error }) => !!error.length
    );

    scrollToError(errors);

    errors.forEach(({ key, error }) => setErrorDataFS(key, error));
    return !errors.filter(({ error }) => !!error.length).length;
  };

  const onClickContinue = () => {
    if (validateData()) {
      queryValid({ cardRecordId, payload: getPayload(dataFS) });
    }
  };

  useEffect(() => {
    const response = infoValidQuery.data;

    if (response) {
      const { messages } = response.data;
      setDataSS(messages);
      setStep(2);
    }
  }, [infoValidQuery]);

  return (
    <Styled.Wrapper>
      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onClickContinue}
        disabled={isDisable}
        loading={infoValidQuery.isLoading}
      >
        Continue
      </Button>
    </Styled.Wrapper>
  );
};

export default CRFSContinue;
