import CurrencyInput from "react-currency-input-field";
import styled from "styled-components";
import { Text } from "../../../commonStyle";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

interface IWrapperValue {
  borderColor: string;
  isError: boolean;
}

export const WrapperValue = styled.div<IWrapperValue>`
  display: flex;
  align-items: center;
  gap: 0 5px;
  padding: 4px 8px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ borderColor }) => borderColor};
  &:hover {
    border-color: ${({ isError }) => (isError ? "#eb5757" : "#6c64f4")};
  }

  div:first-of-type {
    text-overflow: initial;
    overflow: initial;
  }
`;

export const ImgWrapper = styled.div`
  height: 18px;
  & > img {
    height: 100%;
    width: auto;
  }
`;

export const InputNumber = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Input = styled(CurrencyInput)`
  border: unset;
  outline: unset;
  font-size: 16px;
  flex: auto;
  font-family: Poppins;
  overflow: hidden;
  padding-right: 20px;
`;

export const ArrowUp = styled.span`
  position: absolute;
  cursor: pointer;
  right: 3px;
  transform: scaleY(0.6);
  top: -5px;
`;

export const ArrowDown = styled(ArrowUp)`
  transform: rotate(180deg) scaleY(0.6);
  top: auto;
  bottom: -5px;
`;

export const Error = styled(Text)`
  min-height: 15px;
  text-align: start;
  color: #eb5757;
`;

export const Title = styled(Text)`
  text-align: start;
`;

export const Postfix = styled(Text)`
  overflow: initial;
  padding-right: 2px;
`;
