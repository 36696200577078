
import IBSSButtons from "./buttons";
import IBSSButtonsGreen from "./buttonsGreen";
import IBSSFooter from "./footer";
import IBSSHeader from "./header";
import IBSSMainInfo from "./mainInfo";
import IBSSTable from "./table";

const InvoiceUseSS= () => {
  return (
    <div>
      <IBSSHeader />
      <IBSSMainInfo />
      <IBSSTable />
      <IBSSFooter />
      <IBSSButtonsGreen />
      <IBSSButtons />
    </div>
  );
};

export default InvoiceUseSS;
