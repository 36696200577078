import { useContext, useId } from "react";
import { ContextMassPayment } from "../../..";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import * as Styled from "./style";

const DocumentMassPay = () => {
  const idComponent = useId();
  const { documents } = useContext(ContextMassPayment).data;

  if (!documents) return null;

  return (
    <WrapperWhiteBG title={documents.title}>
      {documents.data.map(({ data }, index) => {
        return (
          <Styled.WrapperLink key={idComponent + index}>
            <TypeToTextMap data={[data]} styleData={{ color: "#355EA0" }} />
          </Styled.WrapperLink>
        );
      })}
    </WrapperWhiteBG>
  );
};

export default DocumentMassPay;
