import { Alert, AlertProps } from "antd";
import { FC, forwardRef, ReactNode, useId } from "react";
import * as Styled from "./style";

export interface IPropsInputComponent {
  error?: string;
  title?: string;
  disabledError?: boolean;
  width?: string;
  info?: AlertProps[];
  children: ReactNode;
  id?: string;
}

const WrapperTitleError = forwardRef<HTMLDivElement, IPropsInputComponent>(
  (
    { error, title, children, width = "100%", disabledError = false, info, id },
    ref
  ) => {
    const idComponent = useId();

    return (
      <Styled.Wrapper width={width}>
        {title && <Styled.Title margin="0 0 8px">{title}</Styled.Title>}
        <div ref={ref} id={id} >
          {children}
        </div>

        {!disabledError && <Styled.Error>{error}</Styled.Error>}
        {info && (
          <Styled.WrapperAllert>
            {info.map((item, index) => (
              <Alert
                key={idComponent + ".Alert." + index}
                {...item}
                style={{ width: "fit-content" }}
              />
            ))}
          </Styled.WrapperAllert>
        )}
      </Styled.Wrapper>
    );
  }
);

export default WrapperTitleError;
