import instance from "..";
import { IDataContact } from "../../pages/contact/form/types";
import {
  IPayloadCheckToken,
  IPayloadConfirmCode,
  IPayloadProfileInvite,
  IPayloadReCaptcha,
  IPayloadResend,
  IPayloadReset,
  IPayloadSaveEdit,
  IPayloadStep1,
  IPayloadStep2,
  IPayloadUpdatePassword,
  IPNotifModal,
} from "./types/payload";
import {
  IResponseCheckToken,
  IResponseConfirm,
  IResponseContact,
  IResponseProfileInit,
  IResponseReCaptcha,
  IResponseReset,
  IResponseStep1,
  IResponseStep2,
} from "./types/response";

const UserAPI = {
  loginStep1: async (payload: IPayloadStep1) => {
    return await instance.post<IResponseStep1>("login/step1", { ...payload });
  },
  loginStep2: async (payload: IPayloadStep2) => {
    return await instance.post<IResponseStep2>("login/step2", payload);
  },
  logout: () => instance.post("/profile/logout"),
  reCaptcha: (payload: IPayloadReCaptcha) =>
    instance.post<IResponseReCaptcha>("/login/check-captcha", payload),
  resendCode: async ({ type }: IPayloadResend) => {
    return await instance.get<IPayloadStep1>("/login/resend-sms/" + type);
  },
  getContact: () => instance.get<IResponseContact>("/app/init-contacts"),
  sendContactMessage: (payload: any) =>
    instance.post<IResponseContact>("/app/send-contacts-message", payload),
  edit: {
    init: () => instance.get<IResponseProfileInit>("/profile/init"),
    saveEdit: (payload: IPayloadSaveEdit) =>
      instance.post("/profile/store", payload),
    invite: (payload: IPayloadProfileInvite) =>
      instance.post("/profile/invite", payload),
  },
  reset: {
    sendEmail: (payload: IPayloadReset) =>
      instance.post<IResponseReset>("login/reset-email", {
        ...payload,
      }),
    checkToken: (payload: IPayloadCheckToken) =>
      instance.post<IResponseCheckToken>("/login/check-reset-token", payload),
    confirmCode: (payload: IPayloadConfirmCode) =>
      instance.post<IResponseConfirm>("/login/confirm-reset-code", payload),
    updatePassword: (payload: IPayloadUpdatePassword) =>
      instance.post("/profile/update-password", payload),
  },
  closeModal: ({ notificationId }: IPNotifModal) =>
    instance.get(`notifications-postpone/${notificationId}`),
  agreeModal: ({ notificationId }: IPNotifModal) =>
    instance.get(`notifications/${notificationId}`),
};

export default UserAPI;
