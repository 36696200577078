import { Pagination } from "antd";
import { FC, useRef } from "react";
import * as Styled from "./style";

interface IProps {
  current: number;
  pageSize: number;
  total: number;
  onChange: (current: number, pageSize: number) => void;
}

const PaginationTable: FC<IProps> = (props) => {
  const initialPageSizeRef = useRef(props.pageSize);

  if (
    props.total <= props.pageSize &&
    initialPageSizeRef.current == props.pageSize
  )
    return null;

  return (
    <Styled.WrapperPagination>
      <Pagination
        {...props}
        showSizeChanger
        pageSizeOptions={[5, 10, 20, 50, 100]}
      />
    </Styled.WrapperPagination>
  );
};

export default PaginationTable;
