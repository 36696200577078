import CRTermsService from "./termsService";
import CRAccount from "./account";
import CRDocuments from "./documents";
import CRPassportID from "./passportID";
import CRPersonalDetails from "./personalDetails";
import CRResidentialAddress from "./residentialAddress";
import CRShippingDetails from "./shippingDetails";
import CRFSContinue from "./continueButton";

const CRFirstStep = () => {
  return (
    <div>
      <CRAccount />
      <CRPersonalDetails />
      <CRPassportID />
      <CRResidentialAddress />
      <CRShippingDetails />
      <CRDocuments />
      <CRTermsService />
      <CRFSContinue />
    </div>
  );
};

export default CRFirstStep;
