export type TKey =
 | "start"
 | "end"
 | "left"
 | "right"
 | "center"
 | "justify"
 | "match-parent";

export type TValue = "start" | "end" | "center" | undefined;

export const alignToPosition: { [key in TKey]: TValue } = {
 start: "start",
 end: "end",
 left: "start",
 right: "end",
 center: "center",
 justify: undefined,
 "match-parent": undefined,
};
