import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  & > div {
    width: 50%;
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    justify-content: start;
    & > div {
      width: 100%;
    }
  }
`;

export const Logo = styled.img`
  width: 320px;
  height: 120px;
  object-fit: none;
  position: relative;
  left: 0px;
`;
