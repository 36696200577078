import PaymentDetailsCommon from "../../../shared/firstStep/payment";
import { InputBulk } from "../../types";
import PaymentDetaisCurrencyFirst from "./firstPart";
import PaymentDetaisDateSecond from "./secondPart";

const PaymentDetaisBulk = ({ input }: { input: InputBulk }) => {
  return (
    <PaymentDetailsCommon>
      <PaymentDetaisCurrencyFirst input={input} />
      <PaymentDetaisDateSecond input={input} />
    </PaymentDetailsCommon>
  );
};

export default PaymentDetaisBulk;
