import { ArrowLeftOutlined, LockOutlined } from "@ant-design/icons";
import { Button, InputRef } from "antd";
import { createRef, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, TextBold } from "../../commonStyle";
import InputComponent from "../../component/Global/Input";
import UserAPI from "../../services/userAPI";
import { setIsInitQuery } from "../../store/user";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import LSSQRCode from "./qrcode";
import * as Styled from "./style";

interface ILocation {
  state?: {
    userPhone: string;
    QRCode: string;
    type?: string;
    isFromApp?: boolean;
  };
}

const LoginCodeComponent = () => {
  const {
    userPhone = "",
    QRCode = "",
    type,
    isFromApp,
  } = (useLocation() as ILocation).state || {};

  const [queryStep2, infoQuery] = useMutationAPI(UserAPI.loginStep2, {
    isLogin: true,
  });
  const [queryResend, infoResendQuery] = useMutationAPI(UserAPI.resendCode, {
    isLogin: true,
  });
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState("");
  const [seconds, setSeconds] = useState(30);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refInput = createRef<InputRef>();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (!isFromApp) {
      navigate("/login");
    }

    if (refInput.current) refInput.current.focus();
    dispatch(setIsInitQuery(true));
  }, []);

  useEffect(() => {
    const response = infoQuery.data;
    const responseError = infoQuery.error;

    if (response && response.data.updatePassword) {
      const { userId = "", data = [], title = [] } = response.data;
      navigate("/reset/password/update", {
        state: { userId, data, title },
      });
      return;
    }
    if (response) {
      navigate("/dashboard");
    }
    if (responseError && responseError.response) {
      setErrors(
        responseError.response.data.error ||
          process.env.REACT_APP_UNKNOWN_ERROR ||
          "Incorrect code"
      );
    }
  }, [infoQuery]);

  const onChangeCode = (code: string) => {
    if (/^[0-9]*$/.test(code)) {
      setCode(code);
      setErrors("");
    }
  };

  const onBlur = () => {
    if (!code.length) {
      setErrors("Please enter code");
    }
  };

  const onClickConfirm = async () => {
    if (!code.length) {
      onBlur();
      return;
    }

    if (type === "phone-verification") {
      queryStep2({ code, type });
    } else {
      queryStep2({ code });
    }
  };

  const onClickReset = () => {
    if (userPhone.length) {
      queryResend({ type: "auth" });
      return;
    }
    navigate("/reset/g2fa");
  };

  const onClickBack = () => {
    localStorage.removeItem("token");
    navigate(-1);
  };

  return (
    <>
      <Styled.Wrapper>
        {!!QRCode.length && <LSSQRCode QRCode={QRCode} />}
        {!!userPhone.length && !infoResendQuery.isLoading && (
          <Styled.Phone>SMS was send to +...{userPhone.slice(-3)}</Styled.Phone>
        )}
        <InputComponent
          value={code}
          title={
            !userPhone.length
              ? "Please enter Code from the Google Authenticator App:"
              : "Please enter Code received in the SMS:"
          }
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Code"
          onChangeValue={onChangeCode}
          onBlur={onBlur}
          error={errors}
          onKeyEnter={onClickConfirm}
          maxLength={!userPhone.length ? 6 : 4}
          ref={refInput}
        />
        <Button
          shape="round"
          size="large"
          onClick={onClickConfirm}
          loading={infoQuery.isLoading}
        >
          Confirm
        </Button>
        {!userPhone.length && (
          <Styled.Info>
            <span>
              Get Google Authenticator App for
              <a target="_blank" href={process.env.REACT_APP_LINK_IPHONE}>
                iPhone
              </a>
              or
              <a target="_blank" href={process.env.REACT_APP_LINK_ANDROID}>
                Android
              </a>
              Phone
            </span>
          </Styled.Info>
        )}

        {!userPhone.length ? (
          <Button
            shape="round"
            size="large"
            onClick={onClickReset}
            disabled={infoQuery.isLoading}
            type="link"
            style={{ padding: "6.4px 0" }}
          >
            Reset Google Authenticator?
          </Button>
        ) : (
          <Button
            shape="round"
            size="large"
            onClick={() => {
              onClickReset();
              setSeconds(30);
            }}
            disabled={infoQuery.isLoading || seconds > 0}
            type="link"
            style={{ padding: "6.4px 0" }}
          >
            Resend code
            {seconds > 0 && (
              <span>
                &nbsp;in {seconds < 10 ? `0${seconds}` : seconds} seconds
              </span>
            )}
          </Button>
        )}

        <Styled.WrapperButton>
          <ArrowLeftOutlined style={{ color: "#6c64f4" }} />
          <TextBold
            fontSize={16}
            color="#6c64f4"
            onClick={onClickBack}
            isCursor
          >
            Back
          </TextBold>
        </Styled.WrapperButton>
      </Styled.Wrapper>
    </>
  );
};

export default LoginCodeComponent;
