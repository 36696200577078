import { PaperClipOutlined } from "@ant-design/icons";
import { FC } from "react";
import { IItemTableAPI } from "../../../../../services/types/table";
import { TypeToText } from "../../../../../utils/typeToText";
import * as Styled from "./style";

interface IData {
  data: IItemTableAPI[];
  title: string;
}

interface IProps {
  data: IData[];
}

const ItemSecondStepSwift: FC<IProps> = ({ data }) => {
  return (
    <Styled.Wrapper>
      {data.map(({ title, data }, index) => {
        return (
          <Styled.WrapperItem key={"Payment.View." + title + index}>
            {!!title.length && (
              <Styled.Title fontSize={16}>{title}</Styled.Title>
            )}
            {data.map((item) => (
              <TypeToText {...item} />
            ))}
          </Styled.WrapperItem>
        );
      })}
    </Styled.Wrapper>
  );
};

export default ItemSecondStepSwift;
