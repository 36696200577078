import { IItemPicker } from "../../component/Global/Picker";
import { IDataAccountList } from "../../services/cards/types";
import { getValueError } from "../payments/data";
import { IContext, IDataFS, IDataCommon, TDataSS } from "./types";
import { ICountryAPI, IDataDropdown, IDropdownAPI } from "../../services/types";
import { IDataHintsInput } from "../../component/Global/Input/hints";
import { IResponseRequestInit } from "../../services/cards/types/response";
import TypeToTextMap from "../../component/Global/TypeToTextMap";

export const initDataFS: IDataFS = {
  account: getValueError({ text: "", id: "" }),

  PDNationality: getValueError({ text: "", id: "" }),
  PDPlace: getValueError({ text: "", id: "" }),
  PDCardType: getValueError(""),
  PDDate: getValueError(new Date()),
  PDEmail: getValueError(""),
  PDFullName: getValueError(""),
  PDMobile: getValueError(""),
  PDNameCard: getValueError(""),

  PIDCountry: getValueError({ text: "", id: "" }),
  PIDDocNumber: getValueError(""),
  PIDExpireDate: getValueError(null),
  PIDIssueDate: getValueError(null),

  RACountry: getValueError({ text: "", id: "" }),
  RAAdditional: getValueError(""),
  RACity: getValueError(""),
  RAHouseNumber: getValueError(""),
  RAPostalCode: getValueError(""),
  RAStreet: getValueError(""),
  RARegion: getValueError(""),

  SDFullName: getValueError(""),
  SDCountry: getValueError({ text: "", id: "" }),
  SDAdditional: getValueError(""),
  SDCity: getValueError(""),
  SDHouseNumber: getValueError(""),
  SDPostalCode: getValueError(""),
  SDStreet: getValueError(""),
  SDRegion: getValueError(""),
  SDSwitch: getValueError(false),

  DOCBill: getValueError([]),
  DOCCompany: getValueError([]),
  DOCPassport: getValueError([]),

  TSSwitch: getValueError(false),
};

export const initDataSS: TDataSS = [];

export const initDataCommon: IDataCommon = {
  accountData: [],
  PDCardTypeHints: [],
  PDNationalityData: [],
  PDPlaceData: [],
  PIDCountryData: [],
  RACountry: [],
  cardRecordId: "",
  dataTerms: [],
  subTitle: [],
  isLoadingFile: false,
};

const initSet = () => console.log("Init Context Card Request");

export const initContext: IContext = {
  dataFS: initDataFS,
  setDataFS: initSet,
  dataSS: initDataSS,
  setDataSS: initSet,
  dataCommon: initDataCommon,
  setDataCommon: initSet,
  onChangeDataFS: initSet,
  setErrorDataFS: initSet,
  step: 1,
  setStep: initSet,
};

export const getAccountData = (data: IDataAccountList[], id: string) => {
  return data.map(({ data, value }): IItemPicker => {
    return {
      text: data.map(({ text }) => text).join(" "),
      id: value,
      component: <TypeToTextMap data={[data]} />,
    };
  });
};

export const getCardTypeData = (data: IDataDropdown[]) => {
  return data.map(({ text }): IDataHintsInput<string> => {
    return {
      title: { text, type: "em" },
      subTitle: [],
      data: text,
    };
  });
};

export const getPlaceData = (data: ICountryAPI[]) => {
  return data.map(({ countryId, countryName }) => ({
    text: countryName,
    id: countryId,
  }));
};

export const getDatafromResponse = (
  response: IResponseRequestInit,
  idComponent: string
) => {
  const { accounts_list, card_application_types_list, request, dictionaries } =
    response;

  const accountData = getAccountData(
    accounts_list.dropdown_options.data,
    idComponent
  );

  const PDCardTypeHints = getCardTypeData(
    card_application_types_list.dropdown_options.data
  );

  const PDPlaceData = getPlaceData(dictionaries.countries);

  return { accountData, PDCardTypeHints, PDPlaceData };
};
