import { EllipsisOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FC } from "react";
import { TextBold } from "../../commonStyle";
import { IButtonAPI } from "../../services/types";
import PickerComponent, { IItemPicker } from "../Global/Picker";
import { MAIN_THEME } from "../../assets";

export type TOnClickButtonToAntd<
  DB = { [key: string]: string },
  AB = string
> = (props: { action: AB; data?: DB; ddChose?: IItemPicker }) => void;

interface IProps<DB, AB> extends IButtonAPI<DB, AB> {
  onClick?: TOnClickButtonToAntd<DB, AB>;
}

const getPropsButton = (type: IButtonAPI["type"]) => {
  if (type === "dangerous" || type === "disabled") return "default";
  return type;
};

const ButtonToAntd = <DB, AB>({
  text,
  action,
  type,
  data,
  size = "large",
  dropdown_options,
  onClick,
}: IProps<DB, AB>) => {
  if (dropdown_options) {
    return (
      <PickerComponent
        width="fit-content"
        isButton={true}
        value={{ text, id: text }}
        setValue={(ddChose) => {
          if (onClick) onClick({ action, data, ddChose });
        }}
        icon={
          <EllipsisOutlined
            style={{
              transform: "rotateZ(90deg)",
              color: MAIN_THEME.projectColor,
            }}
          />
        }
        data={dropdown_options.data.map(({ text, value }) => ({
          text,
          id: value,
          component: (
            <TextBold
              color={MAIN_THEME.projectColor}
              fontSize={16}
              padding="5px 0"
            >
              {text}
            </TextBold>
          ),
        }))}
        disabledError
      />
    );
  }

  return (
    <Button
      shape="round"
      size={size}
      onClick={() => {
        if (onClick) onClick({ action, data });
      }}
      disabled={type === "disabled"}
      danger={type === "dangerous"}
      type={getPropsButton(type)}
    >
      {text}
    </Button>
  );
};

export default ButtonToAntd;
