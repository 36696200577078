import { useContext, useMemo, useState } from "react";
import { ContextCardsStatement } from "../..";
import TypeToTextMap from "../../../../component/Global/TypeToTextMap";
import useSizePage from "../../../../utils/hookSizePage";
import * as Styled from "./styled";

const InfoHeaderViewStatement = () => {
  const valueContext = useContext(ContextCardsStatement);
  const info = valueContext.data.infoHeader;

  const { width } = useSizePage();

  const dataFirst = useMemo(() => {
    if (width > 1024) return [info.statement_heading, info.statement_dates];
    return [info.statement_heading, info.statement_title];
  }, [width, info]);

  const dataSecond = useMemo(() => {
    if (width > 1024) return [info.statement_title, info.statement_subtitle];
    return [info.statement_subtitle, info.statement_dates];
  }, [width, info]);

  const position = useMemo(() => (width > 1024 ? "end" : "start"), [width]);

  return (
    <Styled.Wrapper>
      <Styled.FirstColumn>
        <TypeToTextMap data={dataFirst} />
      </Styled.FirstColumn>

      <Styled.SecondColumn>
        <TypeToTextMap data={dataSecond} position={position} />
      </Styled.SecondColumn>
    </Styled.Wrapper>
  );
};

export default InfoHeaderViewStatement;
