import { Button } from "antd";
import { useContext, useEffect } from "react";
import { ContextCardRequest } from "../..";
import VerifyPayments from "../../../../component/payment/verify";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { CardAPI } from "../../../../services/cards";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import * as Styled from "./style";

const CRSecondStep = () => {
  const valueContext = useContext(ContextCardRequest);

  const { dataSS, setStep, dataCommon } = valueContext;
  const { cardRecordId } = dataCommon;

  const [queryAuth, infoAuthQuery] = useMutationAPI(CardAPI.request.authorize);

  useEffect(() => {
    const response = infoAuthQuery.data;
    if (response && response.data.result) {
      setStep(3);
    }
  }, [infoAuthQuery]);

  return (
    <div>
      <WrapperWhiteBG>
        <Styled.Wrapper>
          <VerifyPayments data={dataSS} />
          <Styled.WrapperButton>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => queryAuth({ cardRecordId })}
              loading={infoAuthQuery.isLoading}
            >
              Authorize
            </Button>
          </Styled.WrapperButton>
        </Styled.Wrapper>
      </WrapperWhiteBG>
      <Styled.WrapperButton>
        <Button
          shape="round"
          size="large"
          disabled={infoAuthQuery.isLoading}
          onClick={() => setStep(1)}
        >
          Edit request details
        </Button>
      </Styled.WrapperButton>
    </div>
  );
};

export default CRSecondStep;
