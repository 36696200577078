import styled from "styled-components";

export const WrapperRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  margin-top: 15px;
  & > * {
    flex: 1;
  }
`;

export const WrapperUrgent = styled.div`
  margin-top: 15px;
`;
