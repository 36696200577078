import { IItemPicker } from "../../component/Global/Picker";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import { IDataDropdonwInitNewRequest } from "../../services/cryptoFiatPayIn/types";
import { getValueError } from "../payments/data";
import { DataCommonCryptoFiat, IDataContext } from "./types";

export const dataContext: IDataContext = {
  benefAccount: getValueError({ text: "", id: "" }),
  paymentDescription: getValueError(""),
  amount: getValueError("0.00"),
  BCompany: getValueError(""),
  BCountry: getValueError({ text: "", id: "" }),
  BState: getValueError({ text: "", id: "" }),
  BCaState: getValueError({ text: "", id: "" }),
  BCity: getValueError(""),
  BPostalCode: getValueError(""),
  BStreetAddress: getValueError(""),
  BHouseNumber: getValueError(""),
};

export const dataCommonDefault: DataCommonCryptoFiat = {
  dataBenefAccount: [],
  requestId: "",
  currencyId: "",
  dictionaries: {
    countries: [],
    us_states: [],
    ca_states: [],
    currencies: [
      {
        currencyCode: "",
        currencyId: "",
        currencyName: "",
        currencySymbol: "",
      },
    ],
    country_id_ca: "",
    country_id_us: "",
  },
  usID: "",
  canadaID: "",
  dataCountry: [],
  dataState: [],
  dataCaState: [],
};

export const getDataBenefAccount = (
  data: IDataDropdonwInitNewRequest[]
): IItemPicker[] => {
  return data.map(({ data, value }) => {
    return {
      text: data.map(({ text }) => text).join(""),
      id: value,
      component: <TypeToTextMap data={[data]} />,
    };
  });
};
