import { IPayloadValidateEft } from "../../../../../services/paymentAPI/types/payload";
import { IDataFS } from "../../types";

export const getPayload = (
  data: IDataFS,
  accountId: string,
  useCompanyName: boolean
): IPayloadValidateEft["payload"] => {
  let payload: IPayloadValidateEft["payload"] = {
    accountId,
    paymentAccountNumberTo: data.paymentAccountNumberTo.value,
    paymentAmount: parseFloat(data.DAmount.value),
    paymentDescription: data.DPaymentDescription.value,
    paymentDescriptionCompanyName: useCompanyName && data.DIsCompanyName.value,
    paymentIsSameCompanyTransfer: data.DIsInterTransfer.value,
    paymentName: data.BCompany.value,
    paymentPriorityType: data.DIsUrgent.value,
    paymentEftFeeType: data.DFee.value.id,
    paymentBankTransitNumber: data.paymentBankTransitNumber.value,
    paymentBankInstitutionNumber: data.paymentBankInstitutionNumber.value,
    paymentEmail: data.paymentEmail.value,
    paymentFirstName: data.paymentFirstName.value,
    paymentLastName: data.paymentLastName.value,
  };

  return payload;
};
