import {
  DeleteOutlined,
  LoadingOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import { FC } from "react";
import { Text } from "../../../../commonStyle";
import { TypeToText } from "../../../../utils/typeToText";
import { IFileTransferDocument } from "../types";
import * as Styled from "./style";

interface IProps {
  files: IFileTransferDocument[];
  onDelete: (id: string) => void;
}

const ItemFileTransfer: FC<IProps> = ({ files, onDelete }) => {
  return (
    <Styled.Container>
      {files.map(({ name, id, isLoading, url }, index) => (
        <Styled.Wrapper key={name + "." + id + ":" + index}>
          <Styled.WrapperFile>
            <div>
              {isLoading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ color: "#6c64f455" }} />}
                />
              ) : (
                <PaperClipOutlined />
              )}

              <TypeToText
                text={name}
                type="normal"
                url={url}
                styleData={{ padding: "0 8px 0", breakWord: true }}
              />
            </div>
            {!isLoading && <DeleteOutlined onClick={() => onDelete(id)} />}
          </Styled.WrapperFile>
          {isLoading && <Styled.ProgressLine />}
        </Styled.Wrapper>
      ))}
    </Styled.Container>
  );
};

export default ItemFileTransfer;
