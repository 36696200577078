import { FC } from "react";
import * as Styled from "../../../../../payments/shared/firstStep/beneficiary/style";
import { CryptoFiatInput } from "../../../../types";
import { PostalCodeInput } from "../../../../../payments/shared/firstStep/components/postalCodeInput";
import { StreetAddressInput } from "../../../../../payments/shared/firstStep/components/streetAddressInput";
import { HouseNumberInput } from "../../../../../payments/shared/firstStep/components/houseNumberInput";

interface IProps {
  input: CryptoFiatInput;
  isUS: boolean;
  isCanada: boolean;
}

const SecondRowCounterpartyCryptoFiat: FC<IProps> = ({
  input,
  isUS,
  isCanada,
}) => {
  return (
    <Styled.Row>
      {(isUS || isCanada) && (
        <Styled.Item flex={1}>
          <PostalCodeInput input={input} />
        </Styled.Item>
      )}

      <Styled.Item flex={isUS ? 1 : 2 || isCanada ? 1 : 2}>
        <StreetAddressInput input={input} />
      </Styled.Item>

      <Styled.Item flex={1}>
        <HouseNumberInput input={input} />
      </Styled.Item>
    </Styled.Row>
  );
};

export default SecondRowCounterpartyCryptoFiat;
