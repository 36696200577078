import styled from "styled-components";
import { Text } from "../../../commonStyle";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Info = styled(Text)`
  margin-bottom: 16px;
`;

export const WrapperData = styled.div`
  margin-bottom: 16px;
`;

export const WrapperSwitch = styled.div`
  display: flex;
  width:100%
`;
