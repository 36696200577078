import styled from "styled-components";
import { TextBold } from "../../../commonStyle";

export const Title = styled(TextBold)`
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const WrapperText = styled(TextBold)`
  margin-bottom: 20px;
`;

export const WrapperButtons = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 32px;
`;
