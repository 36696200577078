import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import {
  IBAN_ACCOUNT_NUMBER_FOR_SWIFT_AND_CORRESPONDENT_ACCOUNT_REGEX,
  IBAN_ACCOUNT_NUMBER_REGEX,
  NUMERIC_REGEX,
} from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions, PreparedFieldActions } from "../form";

const rulesIbanAccountNumber: IRulesString = { isReq: true, min: 7, max: 34 };
const rulesAccountNumber: IRulesString = { isReq: true, max: 8 };
const rulesPaymentAccountNumber: IRulesString = {
  isReq: true,
  min: 7,
  max: 12,
};

type IAccountNumberDataFS = {
  BAccountNumber: IValueError<string>;
};

type IUkAccountNumberDataFS = {
  BUkAccountNumber: IValueError<string>;
};

interface AccountNumberInput {
  dataFS: IAccountNumberDataFS;
  onChangeDataFS: TOnChangeDataFS<IAccountNumberDataFS>;
  formActions: FormActions<IAccountNumberDataFS>;
}

interface UkAccountNumberInput {
  dataFS: IUkAccountNumberDataFS;
  onChangeDataFS: TOnChangeDataFS<IUkAccountNumberDataFS>;
  formActions: FormActions<IUkAccountNumberDataFS>;
}

type IPaymentAccountNumberDataFS = {
  paymentAccountNumberTo: IValueError<string>;
};

interface PaymentAccountNumberInput {
  dataFS: IPaymentAccountNumberDataFS;
  onChangeDataFS: TOnChangeDataFS<IPaymentAccountNumberDataFS>;
  formActions: FormActions<IPaymentAccountNumberDataFS>;
}

interface IAccountNumberInputTemplate {
  data: IValueError<string>;
  title: string;
  onChangeValue: (value: string) => void;
  regex: RegExp;
  onLostFocusActions?: LostFocusActions[];
  maxLength?: number;
  id: string;
  fieldActions: PreparedFieldActions;
}

const AccountNumberInputTemplate = ({
  data,
  title,
  onChangeValue,
  regex,
  onLostFocusActions,
  maxLength,
  id,
  fieldActions,
}: IAccountNumberInputTemplate) => {
  return (
    <InputComponent
      {...data}
      onChangeValue={onChangeValue}
      title={title}
      regex={regex}
      onLostFocusActions={onLostFocusActions}
      maxLength={maxLength}
      id={id}
      fieldActions={fieldActions}
    />
  );
};

export const IBANAccountNumberInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
  isExtendedRegex = false,
  isReq,
}: {
  input: AccountNumberInput;
  isExtendedRegex?: boolean;
  isReq?: boolean;
}) => {
  return (
    <AccountNumberInputTemplate
      data={dataFS.BAccountNumber}
      title="IBAN / Account number:"
      onChangeValue={(value) => onChangeDataFS("BAccountNumber", value)}
      regex={
        isExtendedRegex
          ? IBAN_ACCOUNT_NUMBER_FOR_SWIFT_AND_CORRESPONDENT_ACCOUNT_REGEX
          : IBAN_ACCOUNT_NUMBER_REGEX
      }
      onLostFocusActions={[
        LostFocusActions.REMOVE_WHITESPACES,
        LostFocusActions.REMOVE_DASH_LIKE_CHARACTERS,
        LostFocusActions.UPPERCASE,
      ]}
      fieldActions={prepareStringRulesField("BAccountNumber", {
        ...rulesIbanAccountNumber,
        isReq: isReq ?? rulesIbanAccountNumber.isReq,
      })}
      maxLength={rulesIbanAccountNumber.max}
      id="BAccountNumber"
    />
  );
};

export const AccountNumberInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
  isReq,
}: {
  input: UkAccountNumberInput;
  isReq?: boolean;
}) => {
  return (
    <AccountNumberInputTemplate
      data={dataFS.BUkAccountNumber}
      title="Account number:"
      onChangeValue={(value) => onChangeDataFS("BUkAccountNumber", value)}
      regex={NUMERIC_REGEX}
      fieldActions={prepareStringRulesField("BUkAccountNumber", {
        ...rulesAccountNumber,
        isReq: isReq ?? rulesAccountNumber.isReq,
      })}
      maxLength={rulesAccountNumber.max}
      id="BUkAccountNumber"
    />
  );
};

export const PaymentAccountNumberInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: PaymentAccountNumberInput;
}) => {
  return (
    <AccountNumberInputTemplate
      data={dataFS.paymentAccountNumberTo}
      title="Account Number:"
      onChangeValue={(value) => onChangeDataFS("paymentAccountNumberTo", value)}
      regex={NUMERIC_REGEX}
      fieldActions={prepareStringRulesField(
        "paymentAccountNumberTo",
        rulesPaymentAccountNumber
      )}
      maxLength={rulesPaymentAccountNumber.max}
      id="paymentAccountNumberTo"
    />
  );
};
