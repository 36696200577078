import { ITable } from "../../../../../component/table/types";
import { IResInvoiceValid } from "../../../../../services/invoiceBuilder/types/response";

export const getDataTable = (
  data: IResInvoiceValid["invoice_items"]["data"]
): ITable[] => {
  const isTax = !!data.filter(({ item }) => item.tax).length;

  const widthQTY = isTax ? "15%" : "20%";
  const widthSubtotal = isTax ? "20%" : "25%";
  const widthRate = isTax ? "15%" : "20%";

  const table: ITable[] = [
    {
      title: "Description",
      width: "35%",
      positionText: "left",
      dataColumn: data.map(({ item }) => item.description),
    },
    {
      title: "QTY",
      width: widthQTY,
      positionText: "left",
      dataColumn: data.map(({ item }) => item.quantity),
    },
    {
      title: "Rate",
      width: widthRate,
      positionText: "left",
      dataColumn: data.map(({ item }) => item.rate),
    },
    {
      title: "Subtotal",
      width: widthSubtotal,
      positionText: "left",
      dataColumn: data.map(({ item }) => item.subtotal),
    },
  ];

  if (isTax) {
    const taxColumn: ITable = {
      title: "Tax(%)",
      width: "15%",
      positionText: "left",
      dataColumn: data.map(({ item }) => item.tax || []),
    };

    table.splice(3, 0, taxColumn);
  }

  return table;
};
