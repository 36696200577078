import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import IUFSGeneralFR from "./row/firstRow";
import IUFSGeneralSR from "./row/secondRow";

const IUFSGeneral = () => {
  return (
    <WrapperWhiteBG title="General">
      <IUFSGeneralFR />
      <IUFSGeneralSR />
    </WrapperWhiteBG>
  );
};

export default IUFSGeneral;
