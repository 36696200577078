import { IDataCryptoSellFS } from "../types";

export const getIsErrorOnData = (data: IDataCryptoSellFS) => {
  const keysData: (keyof IDataCryptoSellFS)[] = [
    "amount",
    "description",
    "isConfirm",
  ];
  const errors = keysData
    .map((item) => data[item].error)
    .filter((item) => !!item.length);
  return !!errors.length;
};



export const getMin = (code: string) => {
  switch (code) {
    case "BTC": {
      return 0.000015;
    }
    default: {
      return 0.01;
    }
  }
};

export const getDecimal = (code: string) => {
  switch (code) {
    case "BTC": {
      return 8;
    }
    default: {
      return 2;
    }
  }
};
