import { Button } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ContextInvoiceUse } from "../../..";
import * as Styled from "./style";

const IBSSButtons = () => {
  const { setStep } = useContext(ContextInvoiceUse);
  const navigate = useNavigate();

  return (
    <Styled.Wrapper>
      <Button shape="round" size="large" onClick={() => setStep(1)}>
        Edit invoice
      </Button>
      <Button
        shape="round"
        size="large"
        onClick={() => navigate("/invoices-templates")}
      >
        Close Template
      </Button>
    </Styled.Wrapper>
  );
};

export default IBSSButtons;
