import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import { FIRST_AND_LAST_NAME_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions, PreparedFieldActions } from "../form";

const rulesFullName: IRulesString = { isReq: true, min: 1, max: 100 };

type IFirstNameDataFS = {
  paymentFirstName: IValueError<string>;
};

type ILastNameDataFS = {
  paymentLastName: IValueError<string>;
};

interface FirstNameInput {
  dataFS: IFirstNameDataFS;
  onChangeDataFS: TOnChangeDataFS<IFirstNameDataFS>;
  formActions: FormActions<IFirstNameDataFS>;
}

interface LastNameInput {
  dataFS: ILastNameDataFS;
  onChangeDataFS: TOnChangeDataFS<ILastNameDataFS>;
  formActions: FormActions<ILastNameDataFS>;
}

interface IFullNameInputTemplate {
  data: IValueError<string>;
  title: string;
  onChangeValue: (value: string) => void;
  id: string;
  fieldActions: PreparedFieldActions;
}

const FullNameInputTemplate = ({
  data,
  title,
  onChangeValue,
  id,
  fieldActions,
}: IFullNameInputTemplate) => {
  return (
    <InputComponent
      {...data}
      onChangeValue={onChangeValue}
      title={title}
      maxLength={rulesFullName.max}
      regex={FIRST_AND_LAST_NAME_REGEX}
      onLostFocusActions={[
        LostFocusActions.REPLACE_WHITESPACES,
        LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
        LostFocusActions.UPPERCASE_FIRST_LETTERS,
      ]}
      id={id}
      fieldActions={fieldActions}
    />
  );
};

export const FirstNameInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: FirstNameInput;
}) => {
  return (
    <FullNameInputTemplate
      data={dataFS.paymentFirstName}
      title="First name:"
      onChangeValue={(value) => onChangeDataFS("paymentFirstName", value)}
      fieldActions={prepareStringRulesField("paymentFirstName", rulesFullName)}
      id="paymentFirstName"
    />
  );
};

export const LastNameInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: LastNameInput;
}) => {
  return (
    <FullNameInputTemplate
      data={dataFS.paymentLastName}
      title="Last name:"
      onChangeValue={(value) => onChangeDataFS("paymentLastName", value)}
      fieldActions={prepareStringRulesField("paymentLastName", rulesFullName)}
      id="paymentLastName"
    />
  );
};
