import instance from "..";
import { IPayloadInvoiceListDelete } from "./types/payload";
import { IResInitInvoiceTL } from "./types/response";

export const InvoiceTLAPI = {
  init: () =>
    instance.get<IResInitInvoiceTL>("app/init-invoices-templates-list"),
  listDelete: (payload: IPayloadInvoiceListDelete) =>
    instance.post("invoice-templates/delete", payload),
};
