import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px 0;
`;

export const WrapperAuthButton = styled(Wrapper)`
  gap: 15px 0;
`;

export const WrapperInput = styled.div`
  width: 20%;
  min-width: 300px;
`;
