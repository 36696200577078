import { InputEft } from "../../../types";
import FirstRowBeneficiaryEft from "./firstRow";
import SecondRowBeneficiaryEft from "./secondRow";

const BeneficiaryRowsEft = ({ input }: { input: InputEft }) => {
  return (
    <>
      <FirstRowBeneficiaryEft input={input} />
      <SecondRowBeneficiaryEft input={input} />
    </>
  );
};

export default BeneficiaryRowsEft;
