import { useContext } from "react";
import { ContextInvoiceUse } from "../../../../..";
import DatePickerComponent from "../../../../../../../component/Global/DatePicker";
import InputComponent from "../../../../../../../component/Global/Input";
import * as Styled from "./style";
import validateString from "../../../../../../../utils/validate/validateString";

const IUFSGeneralFR = () => {
  const { dataFS, onChangeData, onErrorData } = useContext(ContextInvoiceUse);
  const { invoiceNumber, invoiceDate, dueDate } = dataFS;

  const onChangeDate = (type: "invoiceDate" | "dueDate", value: Date) => {
    if (type === "invoiceDate" && dueDate.value && value > dueDate.value) {
      onChangeData(type, value);
      onChangeData("dueDate", value);
      return;
    }
    if (type === "dueDate" && invoiceDate.value && value < invoiceDate.value) {
      onChangeData(type, value);
      onChangeData("invoiceDate", value);
      return;
    }
    onChangeData(type, value);
  };

  const onBlurInvoiceNumber = () => {
    const error = validateString(invoiceNumber.value, {
      isReq: true,
    });
    onErrorData("invoiceNumber", error);
  };

  return (
    <Styled.Wrapper>
      <InputComponent
        {...invoiceNumber}
        title="Invoice Number:"
        onChangeValue={(value) => onChangeData("invoiceNumber", value)}
        onBlur={onBlurInvoiceNumber}
        maxLength={100}
        id="invoiceNumber"
      />
      <DatePickerComponent
        {...invoiceDate}
        title="Invoice Date:"
        setDate={(value) => onChangeDate("invoiceDate", value)}
        id="invoiceDate"
      />
      <DatePickerComponent
        {...dueDate}
        title="Due Date:"
        setDate={(value) => onChangeDate("dueDate", value)}
        id="dueDate"
        minDate={new Date()}
      />
    </Styled.Wrapper>
  );
};

export default IUFSGeneralFR;
