import React, { FC, useId } from "react";
import { ITextCommon } from "../../../../commonStyle";
import { IItemTableAPI } from "../../../../services/types/table";
import { TypeToText } from "../../../../utils/typeToText";
import { TItemTable, TPositionTextTable } from "../../types";
import * as Styled from "./style";

interface IProps {
  data: TItemTable[][];
  positionText?: TPositionTextTable;
}

const getTextAling: { [key: string]: ITextCommon["textAling"] } = {
  left: "start",
  center: "center",
  right: "end",
  unset: "unset",
};

const TIDataToComponent: FC<IProps> = ({ data, positionText = "unset" }) => {
  const idComponent = useId();
 
  return (
    <Styled.Wrapper>
      {data.map((line, index) => {
  
        const key = idComponent + "." + index;
        return (
          <Styled.WrapperText key={key} type={positionText}>
            {line.map((item, index) => {
              if (React.isValidElement(item)) {
                return (
                  <React.Fragment key={key + "." + index}>
                    {item}
                  </React.Fragment>
                );
              }

              const propsText = item as IItemTableAPI;

              return (
                <TypeToText
                  {...propsText}
                  key={key + "." + index}
                  styleData={{
                    textAling: getTextAling[positionText],
                    fontSize: 16,
                  }}
                />
              );
            })}
          </Styled.WrapperText>
        );
      })}
    </Styled.Wrapper>
  );
};

export default TIDataToComponent;
