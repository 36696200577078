import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  & > div {
    width: 50%;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 16px;
      width: 100%;
    }
  }
`;
