import { useContext, useEffect } from "react";
import { ContextInvoiceUse } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import validateString from "../../../../../../../utils/validate/validateString";

const formatDate = (date: Date) =>
  date.toISOString().split("T")[0].split("-").reverse().join(".");

const IUFSGeneralSR = () => {
  const { dataFS, dataCommon, onChangeData, setDataCommon, onErrorData } =
    useContext(ContextInvoiceUse);
  const { description, invoiceNumber, invoiceDate, dueDate } = dataFS;
  const { referencePrefix, descriptionMode } = dataCommon;

  useEffect(() => {
    if (descriptionMode) {
      const numId = !invoiceNumber.value.length
        ? ""
        : " #" + invoiceNumber.value;

      let message = `Invoice${numId} ${formatDate(invoiceDate.value)}`;

      onChangeData("description", message);
    }
  }, [descriptionMode, invoiceNumber.value, invoiceDate.value]);

  const onBlurDescription = () => {
    const error = validateString(description.value, {
      isReq: true,
    });
    onErrorData("description", error);
  };

  return (
    <InputComponent
      {...description}
      title="Payment description:"
      addonBefore={referencePrefix}
      onChangeValue={(value) => {
        setDataCommon((prev) => ({ ...prev, descriptionMode: false }));
        onChangeData("description", value);
      }}
      onBlur={onBlurDescription}
      maxLength={100}
      width="100%"
      id="description"
    />
  );
};

export default IUFSGeneralSR;
