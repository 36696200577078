import { Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "../../../commonStyle";
import ModalDeleteComponent from "../../../component/commonModal";
import WrapperBodyTitle from "../../../component/Wrappers/WrapperBodyTitle";
import { CreditDocApi } from "../../../services/creditDoc";
import { IDataInitCredit } from "../../../services/creditDoc/types";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import * as Styled from "./style";
import CDTable from "./table";

const MainPageCreditDoc = () => {
  const infoInitQuery = useQueryAPI(CreditDocApi.init, {
    changeIsLoading: true,
  });
  const [queryDelete, infoQueryDelete] = useMutationAPI(CreditDocApi.delete);
  const navigate = useNavigate();

  const [tableResp, setTableResp] = useState<IDataInitCredit[]>([]);
  const [selectIdTable, setSelectIdTable] = useState<string[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const response = infoInitQuery.data;
    if (response) {
      setTableResp(response.data.data);
    }
  }, [infoInitQuery]);

  useEffect(() => {
    const response = infoQueryDelete.data;
    if (response && response.data.result) {
      setIsOpenModal(false);
      setTableResp((prev) =>
        prev.filter(({ requestId }) => !selectIdTable.includes(requestId))
      );
      setSelectIdTable([]);
    }
  }, [infoQueryDelete]);

  const onClickNo = () => setIsOpenModal(false);

  const onClickYes = () => {
    queryDelete({ transfers: selectIdTable });
    onClickNo;
  };

  return (
    <WrapperBodyTitle
      {...infoInitQuery.data?.data.page?.header}
      onClickButton={() => navigate("/credit-documents/upload")}
    >
      <CDTable
        tableResp={tableResp}
        selectIdTable={selectIdTable}
        setIsOpenModal={setIsOpenModal}
        setSelectIdTable={setSelectIdTable}
      />
      {isOpenModal && (
        <ModalDeleteComponent
          onClose={onClickNo}
          onClickYes={onClickYes}
          onClickNo={onClickNo}
          loadingYes={infoQueryDelete.isLoading}
          title="Delete documents?"
        />
      )}
    </WrapperBodyTitle>
  );
};

export default MainPageCreditDoc;
