import { FC, useContext, useEffect, useState } from "react";

import SSMPHeader from "./header";
import * as Styled from "./style";
import { ITable } from "../../../../component/table/types";
import { ContextImportBulkTransfer } from "../..";
import Table from "../../../../component/table";
import { IItemTableBulkImport } from "../../../../services/bulkTransfer/types";
import { Button } from "antd";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import { BulkTransferAPI } from "../../../../services/bulkTransfer";
import { useNavigate } from "react-router-dom";

const getDataTable = (dataRow: IItemTableBulkImport[]): ITable[] => [
  {
    title: "Date",
    width: "10%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.date.data),
  },
  {
    title: "Remitter",
    width: "35%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.remitter.data),
  },
  {
    title: "Payment Details",
    width: "35%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.description.data),
  },
  {
    title: "Amount",
    width: "10%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.amount.data),
  },
  {
    title: "Status",
    width: "10%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.status.data),
  },
];

const SecondStepBulkImport = () => {
  const dataContext = useContext(ContextImportBulkTransfer);

  const [queryAuth, infoAuthQuery] = useMutationAPI(BulkTransferAPI.execute);
  const navigate = useNavigate();

  const { dataCommon, setStep, onChangeDataFS } = dataContext;
  const { dataTable, importId, hasErrors } = dataCommon;

  useEffect(() => {
    if (infoAuthQuery.data) {
      navigate("/dashboard");
    }
  }, [infoAuthQuery]);

  const onClickRestart = () => {
    onChangeDataFS("files", []);
    setStep(1);
  };

  return (
    <div>
      <SSMPHeader />
      <Table table={getDataTable(dataTable)} />
      <Styled.WrapperButton>
        <Button
          shape="round"
          size="middle"
          onClick={onClickRestart}
          disabled={infoAuthQuery.isLoading}
        >
          Restart import
        </Button>
        <Button
          type="primary"
          shape="round"
          size="large"
          loading={infoAuthQuery.isLoading}
          onClick={() => queryAuth({ importId })}
          disabled={hasErrors}
        >
          Confirm
        </Button>
      </Styled.WrapperButton>
    </div>
  );
};

export default SecondStepBulkImport;
