import { Spin } from "antd";
import * as Styled from "./style";

const Loader = () => {
  return (
    <Styled.Wrapper>
      <Spin size="large" style={{ color: "#5536ef" }} />
    </Styled.Wrapper>
  );
};

export default Loader;
