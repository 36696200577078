import { getValueError } from "../../payments/data";
import {
  IContext,
  IDataCommon,
  IDataCryptoSellFS,
  IDataCryptoSellSS,
  IDataCryptoSellTS,
} from "./types";

export const initDataCommon: IDataCommon = {
  dataBenef: [],
  dataConfirm: [],
  balance: 0,
  currencySymbol: "",
  paymentId: "",
  rate: "",
  rateNumber: 0,
  currencyCode: "",
  accountId: "",
  fiatAccountId: "",
  fiatCurrencyId: "",
  isLoadingRate: false,
};

export const initFisrtStep: IDataCryptoSellFS = {
  amount: getValueError("0.00000000"),
  description: getValueError(""),
  isConfirm: getValueError(false),
};

export const initSecondStep: IDataCryptoSellSS = {
  checksum: "",
  name: "",
  amount: "",
  type: "google-authenticator",
};

export const initThirdStep: IDataCryptoSellTS = {
  table: [],
  accountId: "",
};

const initSetData = () => console.log("init Crypto Context");

export const initContext: IContext = {
  step: 1,
  setStep: initSetData,
  dataFirst: initFisrtStep,
  setDataFirst: initSetData,
  dataSecond: initSecondStep,
  setDataSecond: initSetData,
  dataCommon: initDataCommon,
  setDataCommon: initSetData,
  dataThird: initThirdStep,
  setDataThird: initSetData,
  onChangeData: initSetData,
  onErrorData: initSetData,
};
