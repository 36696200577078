export const PAYMENT_DESCRIPTION_REGEX =
  /^[A-Za-z0-9\s.,:;()\u2012\u2013\u2014\u2015\-\/&@#%*!$^_+=<>?\[\]{}|\\~`€£¥₹¢₩₪₫₭₮₦₱₲₴₵₸]*$/;

export const COMPANY_AND_CORRESPONDENT_BANK_NAME_REGEX =
  /^[\p{L}\p{M}\d\s&@£$€¥.,:;?!'"(){}\[\]~_^`|\\\/\*\+<=>«»#%\u2012\u2013\u2014\u2015\-’‘“”]*$/u;

export const CITY_REGEX =
  /^[\p{L}\p{M}\d\s\/\u2012\u2013\u2014\u2015\-'.’‘]*$/u;

export const POSTAL_CODE_REGEX = /^[A-Za-z0-9\s\u2012\u2013\u2014\u2015\-]*$/;

export const STREET_ADDRESS_REGEX =
  /^[\p{L}\p{M}\d\s\u2012\u2013\u2014\u2015\-'\.,\/’‘]*$/u;

export const HOUSE_NUMBER_REGEX =
  /^[A-Za-z0-9\/\s\u2012\u2013\u2014\u2015\-]*$/;

export const IBAN_ACCOUNT_NUMBER_FOR_SWIFT_AND_CORRESPONDENT_ACCOUNT_REGEX =
  /^[A-Za-z0-9\s\-\/]*$/;

export const IBAN_ACCOUNT_NUMBER_REGEX = /^[A-Za-z0-9\s\-]*$/;

export const BIC_REGEX = /^[A-Za-z0-9]*$/;

export const NUMERIC_REGEX = /^[\d]*$/;

export const EMAIL_REGEX = /^[A-Za-z0-9@._\u2012\u2013\u2014\u2015\-]*$/;

export const FIRST_AND_LAST_NAME_REGEX =
  /^[\p{L}\p{M}'\u2012\u2013\u2014\u2015\-\s\.\d’‘`]*$/u;
