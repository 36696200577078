import { useId } from "react";
import { Text } from "../../../../../../commonStyle";
import SwitchComponent from "../../../../../../component/Global/Switch";
import WrapperTitleError from "../../../../../../component/Wrappers/wrapperTitleError";
import { InputCrypto } from "../../../types";
import * as Styled from "./style";

const ConfirmRowCrypto = ({
  input: { dataFS, dataCommon, onChangeDataFS },
  isValidWallet,
}: {
  input: InputCrypto;
  isValidWallet: boolean;
}) => {
  const idComponent = useId();
  if (!isValidWallet) return <div />;

  const { DWallet, DIsConfirm } = dataFS;

  return (
    <WrapperTitleError error={DIsConfirm.error}>
      <SwitchComponent
        checked={DIsConfirm.value}
        onChange={() => {
          onChangeDataFS("DIsConfirm", !DIsConfirm.value);
        }}
        text={`Confirm ${dataCommon.nameCrypto} Wallet Address*`}
        id="DIsConfirm"
        textChange
      />
      <Styled.WrapperText>
        {dataCommon.confirmMessage.split("<br>").map((line, index) => {
          const lineReplace = line.replace(
            '"%s"',
            `<bold>${DWallet.value}<bold>`
          );
          const key = idComponent + "." + index;
          return (
            <Text key={key}>
              {lineReplace.split("<bold>").map((text, index) => {
                if (index === 1)
                  return <strong key={key + "." + index}>{text}</strong>;
                return text;
              })}
            </Text>
          );
        })}
      </Styled.WrapperText>
    </WrapperTitleError>
  );
};

export default ConfirmRowCrypto;
