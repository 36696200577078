import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer, { changeOpenType } from "./user";

const userPersistConfig = {
  key: "user",
  storage,
  keyPrefix: "redux-",
  whitelist: ["isAuth"],
};

const rootReducer = combineReducers({
  userReducer: persistReducer(userPersistConfig, userReducer),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

const getOpenTypeFromLocalStorage = () => {
  try {
    const persistedState = localStorage.getItem("openType");
    if (persistedState) return persistedState;
  } catch (e) {
    console.log(e);
  }
};

const savedOpenType = getOpenTypeFromLocalStorage();
if (savedOpenType) {
  store.dispatch(changeOpenType({ openType: savedOpenType }));
}

store.subscribe(() => {
  localStorage.setItem("openType", store.getState().userReducer.openType);
});

export const persistor = persistStore(store);

export type TDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
