import { useEffect } from "react";

export const useOpenBrowserModalAfterPageReload = (
  whenOpenModal = true,
  dependencies: any[]
) => {
  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent
      event.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;
    };

    if (whenOpenModal) {
      window.addEventListener("beforeunload", listener);
    }

    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, dependencies);
};
