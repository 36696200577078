import { useEffect } from "react";
import PaymentAPI from "../../../../services/paymentAPI";
import { IPayloadValidateBulk } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import FirstStepCommon from "../../shared/firstStep";
import PaymentBeneficiaryCompanyPickerCommon from "../../shared/firstStep/beneficiary/companyPicker";
import CommonContinueButton from "../../shared/firstStep/continueButton";
import TransferDocumentCommon from "../../shared/firstStep/transferDocument";
import { IDataCommon, IDataFS, InputBulk } from "../types";
import { getPayload } from "./continueButton/data";
import { getErrorResponse } from "./data";
import PaymentDetaisBulk from "./details";

type TErrorResponse = IErrorPayment<keyof IPayloadValidateBulk["payload"]>[];

const FirstStepBulk = ({
  input,
  setStep,
}: {
  input: InputBulk;
  setStep: (step: number) => void;
}) => {
  const { getCurrentCompanyCurrency } = input;

  return (
    <FirstStepCommon
      input={input}
      setStep={setStep}
      validateApi={PaymentAPI.bulk.validate}
      draftApi={PaymentAPI.bulk.draft}
      getErrorResponse={(errors) => getErrorResponse(errors as TErrorResponse)}
      content={(
        queryValidate,
        infoValidateQuery,
        queryDraft,
        infoDraftQuery
      ) => {
        return (
          <>
            <PaymentDetaisBulk input={input} />
            <PaymentBeneficiaryCompanyPickerCommon<IDataFS>
              input={input}
              options={{ deps: ["DAmount"] }}
            />
            <TransferDocumentCommon
              input={input}
              disabled={infoValidateQuery.isLoading}
            />
            <CommonContinueButton<IDataFS, IDataCommon>
              input={input}
              queryValidate={(dataFS, dataCommon) => {
                return queryValidate({
                  id: dataCommon.paymentID,
                  payload: getPayload(
                    dataFS,
                    dataCommon.accountId,
                    dataCommon.useCompanyNameInDescriptionControl,
                    getCurrentCompanyCurrency().currencyId
                  ),
                });
              }}
              isLoadingValid={infoValidateQuery.isLoading}
              queryDraft={(dataFS, dataCommon, autoSave) => {
                return queryDraft({
                  id: dataCommon.paymentID,
                  payload: {
                    ...getPayload(
                      dataFS,
                      dataCommon.accountId,
                      dataCommon.useCompanyNameInDescriptionControl,
                      getCurrentCompanyCurrency().currencyId
                    ),
                    autoSave,
                  },
                });
              }}
              isLoadingDraft={infoDraftQuery.isLoading}
            />
          </>
        );
      }}
    />
  );
};

export default FirstStepBulk;
