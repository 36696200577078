import { Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { CryptoSendContext } from "..";
import TransferDocument from "../../../../component/Global/transferDocument";
import { IFileToFormData } from "../../../../component/Global/transferDocument/types";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { CryptoAPI } from "../../../../services/crypto";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import CryptoSendBenef from "./benef";
import CSendButtons from "./buttons";
import { validate } from "./data";
import CryptoSendDetails from "./details";

const CryptoSendFS = () => {
  const valueContext = useContext(CryptoSendContext);

  const [queryUpload] = useMutationAPI(CryptoAPI.send.files.upload);
  const [queryDelete] = useMutationAPI(CryptoAPI.send.files.delete);
  const [queryValidate, infoValidateQuery] = useMutationAPI(
    CryptoAPI.send.validate
  );
  const [queryDraft, infoDraftQuery] = useMutationAPI(CryptoAPI.send.draft);
  const [queryValidWallet, infoValidWalletQuery] = useMutationAPI(
    CryptoAPI.validateAddress
  );

  const {
    dataFS,
    dataCommon,
    setDataSS,
    setStep,
    setDataCommon,
    onErrorData,
    onChangeData,
  } = valueContext;
  const { files, wallet } = dataFS;
  const { paymentId, currencySymbol, currencyCode } = dataCommon;

  useEffect(() => {
    const response = infoValidateQuery.data;
    if (response) {
      const {
        paymentChecksum: checksum,
        paymentName: name,
        paymentAmountFormatted: amount,
      } = response.data.payment;
      setDataSS(response.data.data);
      setStep(2);
      setDataCommon((prev) => ({ ...prev, checksum, name, amount }));
    }
  }, [infoValidateQuery]);

  const onUploadFiles = async (data: IFileToFormData[]) => {
    const dataResponse = await Promise.all(
      data.map(({ formData }) => queryUpload({ paymentId, formData }))
    );

    const dataFiles = dataResponse.map(
      ({ data: response, error: responseError }) => {
        if (response && response.data.status === "done") {
          const { name, documentId: id } = response.data;
          return { name, id, isLoading: false };
        }
        if (responseError && responseError.response) {
          return "";
        }
        if (response && response.data.status === "file_exists") {
          return "";
        }
        return "Unknow error";
      }
    );

    return dataFiles;
  };

  const onDeleteFile = async (id: string) => {
    const dataResponse = await queryDelete({ id });
    return dataResponse.data?.data.result || false;
  };

  const validateData = async () => {
    const { data } = await queryValidWallet({
      wallet: wallet.value,
      currencyCode,
    });

    const error = validate(dataFS, currencySymbol, currencyCode, !!data);

    error.forEach(({ key, error }) => onErrorData(key, error));

    return !error.filter(({ error }) => !!error.length).length;
  };

  return (
    <div>
      <CryptoSendDetails onChangeValue={onChangeData} setError={onErrorData} />
      <CryptoSendBenef onChangeValue={onChangeData} setError={onErrorData} />
      <WrapperWhiteBG title="Payment Documents">
        <TransferDocument
          {...files}
          setValue={(value) => onChangeData("files", value)}
          setError={(error) => onErrorData("files", error)}
          onUpload={onUploadFiles}
          onDelete={onDeleteFile}
          isAble={!infoValidWalletQuery.isLoading}
          multiple
          isRequired
        />
      </WrapperWhiteBG>
      <CSendButtons
        queryContinue={queryValidate}
        isLoadingContinue={infoValidateQuery.isLoading}
        validateData={validateData}
        queryDraft={queryDraft}
        isLoadingDraft={infoDraftQuery.isLoading}
      />
    </div>
  );
};

export default CryptoSendFS;
