import { createGlobalStyle } from "styled-components";
import { MAIN_THEME } from "..";

export const MainANTDWrapper = createGlobalStyle`
  /* Radio */

  .ant-radio-inner {
    background-color: transparent !important;
  }

  /* Switch */
  .ant-switch-checked {
    background: ${MAIN_THEME.mainColor} !important;
  }

  /* DatePicker */

  @media (max-width: 768px) {
    .ant-picker-large .ant-picker-input > input {
      font-size: 14px !important;
    }
  }

  .ant-picker {
    width: 100% !important;
  }

  .ant-picker-cell-selected div {
    color: white !important;
    background-color: ${MAIN_THEME.secondColor} !important;
  }

  .ant-picker-cell-today,
  .ant-picker-today-btn,
  .ant-picker-today-btn:hover {
    color: ${MAIN_THEME.mainColor} !important;
  }

  .ant-picker-focused,
  .ant-picker:hover {
    border-color: ${MAIN_THEME.secondColor} !important;
    box-shadow: 0 0 0 2px rgba(85, 54, 239, 0.2) !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: none !important;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
      .ant-picker-cell-range-start
    ):not(.ant-picker-cell-range-end):not(
      .ant-picker-cell-range-hover-start
    ):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: #c5c5c7 !important;
  }

  /* Steps */

  @media (max-width: 768px) {
    .ant-steps-item-title::after {
      display: none !important;
    }
    .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
      margin-right: 8px !important;
    }
  }

  .ant-steps-item-tail::after {
    display: none !important;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${MAIN_THEME.mainColor} !important;
    border-color: ${MAIN_THEME.mainColor} !important;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background: ${MAIN_THEME.mainColor} !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${MAIN_THEME.mainColor} !important;
    background-color: transparent !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${MAIN_THEME.mainColor} !important;
  }

  /* Spin */

  .ant-spin-dot-item {
    background-color: ${MAIN_THEME.secondColor} !important;
  }

  /* ButtonDefault */

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px !important;
  }

  @media (max-width: 720px) {
    .ant-btn-round.ant-btn-lg {
      padding: 4px 16px !important;
    }
  }

  .ant-btn-default:not(:disabled):focus {
    color: ${MAIN_THEME.secondColor} !important;
    border-color: ${MAIN_THEME.secondColor} !important;
    background-color: transparent !important;
  }

  .ant-btn-default:not(:disabled):hover,
  .ant-btn-default:not(:disabled):active {
    color: white !important;
    background-color: ${MAIN_THEME.secondColor} !important;
    border-color: ${MAIN_THEME.secondColor} !important;
  }

  .ant-btn-default:not(:disabled) {
    color: ${MAIN_THEME.secondColor} !important;
    border-color: ${MAIN_THEME.secondColor} !important;
    border-width: 2px !important;
  }

  .ant-btn > span {
    font-family: Poppins-bold !important;
    line-height: 1 !important;
  }

  /* ButtonPrimary */

  .ant-btn-primary:not(:disabled) {
    background-color: transparent !important;
  }

  .ant-btn-primary:not(:disabled):focus {
    color: ${MAIN_THEME.mainColor} !important;
    border-color: ${MAIN_THEME.mainColor} !important;
    background-color: transparent !important;
  }

  .ant-btn-primary:not(:disabled):hover,
  .ant-btn-primary:not(:disabled):active {
    color: white !important;
    background-color: ${MAIN_THEME.mainColor} !important;
    border-color: ${MAIN_THEME.mainColor} !important;
  }

  .ant-btn-primary:not(:disabled) {
    color: ${MAIN_THEME.mainColor} !important;
    border-color: ${MAIN_THEME.mainColor} !important;
    border-width: 2px !important;
  }

  /* ButtonLink */
  .ant-btn-link:not(:disabled):hover,
  .ant-btn-link:not(:disabled):focus,
  .ant-btn-link:not(:disabled):active {
    color: #1890ff !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .ant-btn-link:not(:disabled) {
    color: #1890ff !important;
    border-color: transparent !important;
  }

  /* ButtonDangerous */

  .ant-btn-dangerous:not(:disabled):focus {
    color: #ff4d4f !important;
    border-color: #ff4d4f !important;
    background-color: transparent !important;
  }

  .ant-btn-dangerous:not(:disabled):hover,
  .ant-btn-dangerous:not(:disabled):active {
    color: white !important;
    background-color: #ff4d4f !important;
    border-color: #ff4d4f !important;
  }

  .ant-btn-dangerous:not(:disabled) {
    color: #ff4d4f !important;
    border-color: #ff4d4f !important;
    border-width: 2px !important;
  }

  .ant-btn-loading::before {
    top: -2px !important;
    right: -2px !important;
    bottom: -2px !important;
    left: -2px !important;
  }

  /* Checkbox */

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${MAIN_THEME.mainColor} !important;
    border-color: ${MAIN_THEME.mainColor} !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: ${MAIN_THEME.mainColor} !important;
  }

  /* Modal Window */

  .ant-modal-body {
    padding: 15px !important;
  }

  .ant-modal-footer {
    display: none !important;
  }

  .ant-modal-close svg {
    width: 20px !important;
    height: auto !important;
    color: black !important;
  }

  .ant-modal-content {
    border-radius: 20px !important;
  }

  /* Radio Input */

  .ant-radio-checked .ant-radio-inner {
    border-color: #6c64f4 !important;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #6c64f4 !important;
    box-shadow: 0 0 0 3px #efeeff !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #6c64f4 !important;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #6c64f4 !important;
  }
`;
