import { useContext } from "react";
import { ContextInvoiceUse } from "../../..";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import * as Styled from "./style";

const IUFSStaticInfo = () => {
  const { beneficiary, remitter, logo, details } =
    useContext(ContextInvoiceUse).staticDataFS;

  return (
    <div>
      <Styled.Wrapper>
        <div>
          <WrapperWhiteBG title={beneficiary.title}>
            <TypeToTextMap data={beneficiary.data} />
          </WrapperWhiteBG>
        </div>

        <div>
          {!!logo.length && (
            <Styled.Logo src={"data:image/png;base64, " + logo} />
          )}
        </div>
      </Styled.Wrapper>
      <Styled.Wrapper>
        <WrapperWhiteBG title={remitter.title}>
          <TypeToTextMap data={remitter.data} />
        </WrapperWhiteBG>
        <WrapperWhiteBG title={details.title}>
          <TypeToTextMap data={details.data} />
        </WrapperWhiteBG>
      </Styled.Wrapper>
    </div>
  );
};

export default IUFSStaticInfo;
