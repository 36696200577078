import SwitchComponent from "../../../../../component/Global/Switch";
import PaymentDetailsCommon from "../../../shared/firstStep/payment";
import { InputSepa } from "../../types";
import PaymentDetaisDateFirst from "./firstPart";
import PaymentDetaisDateSecond from "./secondPart";

const PaymentDetaisSepa = ({ input }: { input: InputSepa }) => {
  const { dataFS, setErrorDataFS, onChangeDataFS } = input;
  const { DIsInterTransfer } = dataFS;

  return (
    <PaymentDetailsCommon>
      <SwitchComponent
        checked={DIsInterTransfer.value}
        text="Intercompany payment"
        onChange={(value) => {
          setErrorDataFS("Tfiles", "");
          onChangeDataFS("DIsInterTransfer", value);
        }}
        id="DIsInterTransfer"
        textChange
      />
      <PaymentDetaisDateFirst input={input} />
      <PaymentDetaisDateSecond input={input} />
    </PaymentDetailsCommon>
  );
};

export default PaymentDetaisSepa;
