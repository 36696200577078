import { FC, useId } from "react";
import WrapperWhiteBG from "../../../component/Wrappers/wrapperWhiteBG";
import { IDataInit } from "../../../services/cards/types";
import { IDataTable } from "../types";
import CTFirstColumn from "./firstColumn";
import CTSecondColumn from "./secondColumn";
import * as Styled from "./style";

interface IProps extends IDataTable {
  refetchData: () => void;
}

const CardsTable: FC<IProps> = ({ cards, templates, refetchData }) => {
  const idComponent = useId();

  return (
    <div>
      <WrapperWhiteBG titleOut="CARDS" isBorder>
        {cards.map(
          ({ title, data, operations_list, buttons, balance }, index) => {
            return (
              <Styled.WrapperItem key={idComponent + ".CardsTable." + index}>
                <CTFirstColumn
                  title={title}
                  data={data}
                  operations={operations_list}
                  templates={templates}
                  refetchData={refetchData}
                />
                <CTSecondColumn
                  buttons={buttons}
                  balance={balance}
                  data={data}
                  refetchData={refetchData}
                  activateTemp={templates.activate_card_modal}
                />
              </Styled.WrapperItem>
            );
          }
        )}
      </WrapperWhiteBG>
    </div>
  );
};

export default CardsTable;
