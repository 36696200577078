import { IPayloadValidateUk } from "../../../../../services/paymentAPI/types/payload";
import { IDataFS } from "../../types";

export const getPayload = (
  data: IDataFS,
  accountId: string,
  isCanada: boolean,
  isUs: boolean,
  useCompanyName: boolean
): IPayloadValidateUk["payload"] => {
  let payload: IPayloadValidateUk["payload"] = {
    accountId,
    paymentBankUkAccountNumber: data.BUkAccountNumber.value,
    paymentAmount: parseFloat(data.DAmount.value),
    paymentBankBic: data.BBic.value,
    paymentIsSameCompanyTransfer: data.DIsInterTransfer.value,
    paymentName: data.BCompany.value,
    paymentNameAddressCity: data.BCity.value,
    paymentNameAddressNumber: data.BHouseNumber.value,
    paymentNameAddressPostalCode: data.BPostalCode.value,
    paymentNameAddressState: isUs
      ? data.BState.value.id
      : isCanada
      ? data.BCaState.value.id
      : "",
    paymentNameAddressStreet: data.BStreetAddress.value,
    paymentNameCountryId: data.BCountry.value.id,
    paymentReference: data.DPaymentDescription.value,
    paymentDescriptionCompanyName: useCompanyName && data.DIsCompanyName.value,
    paymentBankSortCode: data.BSortCode.value,
  };

  return payload;
};
