import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  margin-top: 10px;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const WrapperFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div:first-child {
    display: flex;
    align-items: center;
    gap: 0 10px;
  }
`;

const progressAnimate = keyframes`
  from {
    width:0%;
    background-color:#3C97F1;
  }
  to {
    width:100%;
    background-color:#5BF118;
  }
`;

export const ProgressLine = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 3px;
  background-color: #5bf118;
  animation: ${progressAnimate};
  animation-duration: 3s;
`;
