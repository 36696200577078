import SwitchComponent from "../../../../../component/Global/Switch";
import PaymentDetailsCommon from "../../../shared/firstStep/payment";
import { InputEft } from "../../types";
import PaymentDetaisDateFee from "./FeeRow";
import PaymentDetaisDateFirst from "./firstPart";
import PaymentDetaisDateSecond from "./secondPart";

const PaymentDetaisEft = ({ input }: { input: InputEft }) => {
  const { dataFS, dataCommon, onChangeDataFS, setErrorDataFS } = input;
  const { DIsInterTransfer } = dataFS;
  const { dataFee } = dataCommon;

  return (
    <PaymentDetailsCommon>
      <SwitchComponent
        checked={DIsInterTransfer.value}
        text="Intercompany payment"
        onChange={(value) => {
          setErrorDataFS("Tfiles", "");
          onChangeDataFS("DIsInterTransfer", value);
        }}
        id="DIsInterTransfer"
        textChange
      />
      <PaymentDetaisDateFirst input={input} />

      <PaymentDetaisDateSecond input={input} />
      {!!dataFee.length && <PaymentDetaisDateFee input={input} />}
    </PaymentDetailsCommon>
  );
};

export default PaymentDetaisEft;
