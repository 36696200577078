import styled, { css } from "styled-components";
import { TextBold } from "../../../../commonStyle";
import { MAIN_THEME } from "../../../../assets";

export const Wrapper = styled.div`
  position: relative;
  margin-right: 15px;
  min-width: 88px;
  @media (max-width: 1400px) {
    width: 88px;
  }
  @media (max-width: 1024px) {
    width: unset;
  }
`;

export const WrapperSecond = styled.div`
  min-width: 88px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 40px;
  background-color: white;
  border-radius: 20px;

  width: fit-content;
  z-index: 10;

  box-shadow: rgba(27, 41, 68, 0.12) 0px 0px 20px;
`;

export const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const WrapperScrollNav = styled.div``;

interface IWrapperNav {
  isOpen: boolean;
  isSelect: boolean;
}

const selectColor = css`
  & > * {
    color: ${MAIN_THEME.projectColor};
  }
`;

export const WrapperNav = styled.div<IWrapperNav>`
  height: 56px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  padding: 24px;
  border-left: 10px solid;
  border-color: ${({ isSelect }) =>
    isSelect ? MAIN_THEME.projectColor : "#ffffff"};
  cursor: pointer;
  ${({ isSelect }) => isSelect && selectColor};
  & > *:first-child svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    & > * {
      color: ${MAIN_THEME.projectColor};
    }
  }
  & > *:nth-child(2) {
    width: ${({ isOpen }) => (isOpen ? "200px" : "0px")};
    height: ${({ isOpen }) => (isOpen ? "fit-content" : "0px")};
    transition: width 0.3s;
  }
`;

interface IWrapperViewFull {
  isOpen: boolean;
}

export const WrapperViewFull = styled.div<IWrapperViewFull>`
  width: 100%;
  margin-top: auto;
  position: sticky;
  bottom: 0px;
  background-color: white;
  border-radius: 0 0 20px 20px;
  cursor: pointer;
  & > * {
    transform: ${({ isOpen }) => (isOpen ? "rotateZ(180deg)" : "unset")};
    transition: 0.3s;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover svg {
      fill: rgb(108, 100, 244);
    }
  }
`;

export const TextNav = styled(TextBold)`
  white-space: nowrap;
`;

export const WrapperIcon = styled.span`
  width: 20px;
  & > img {
    width: 100%;
    height: auto;
    transform: rotateZ(15deg);
  }
`;
