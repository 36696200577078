import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px 30px;
  & > div:nth-child(2) {
    margin-top: 5px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    & > div:nth-child(2) {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
`;

export const WrapperAmount = styled.div`
  width: 33%;
  @media (max-width: 1024px) {
    width: 75%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
