import PaymentAPI from "../../../../services/paymentAPI";
import { IPayloadValidateCrypto } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import FirstStepCommon from "../../shared/firstStep";
import CommonContinueButton from "../../shared/firstStep/continueButton";
import TransferDocumentCommon from "../../shared/firstStep/transferDocument";
import { IDataCommon, IDataFS, InputCrypto } from "../types";
import { getPayload } from "./continueButton/data";
import { getErrorResponse } from "./data";
import PaymentDetaisCrypto from "./details";

type TErrorResponse = IErrorPayment<keyof IPayloadValidateCrypto["payload"]>[];

const FirstStepCrypto = ({
  input,
  setStep,
}: {
  input: InputCrypto;
  setStep: (step: number) => void;
}) => {
  return (
    <FirstStepCommon
      input={input}
      setStep={setStep}
      validateApi={PaymentAPI.crypto.validate}
      getErrorResponse={(errors) => getErrorResponse(errors as TErrorResponse)}
      content={(queryValidate, infoValidateQuery) => {
        return (
          <>
            <PaymentDetaisCrypto input={input} />
            <TransferDocumentCommon
              input={input}
              disabled={infoValidateQuery.isLoading}
            />
            <CommonContinueButton<IDataFS, IDataCommon>
              input={input}
              queryValidate={(dataFS, dataCommon) => {
                return queryValidate({
                  id: dataCommon.paymentID,
                  payload: getPayload(
                    dataFS,
                    dataCommon.accountId,
                    dataCommon.nameCrypto
                  ),
                  type: dataCommon.type,
                });
              }}
              isLoadingValid={infoValidateQuery.isLoading}
              queryDraft={() => Promise.resolve()}
              isLoadingDraft={false}
            />
          </>
        );
      }}
    />
  );
};

export default FirstStepCrypto;
