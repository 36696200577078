import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import { BulkTransferAPI } from "../../../../../../services/bulkTransfer";
import useMutationAPI from "../../../../../../utils/usePromise/hookMutationAPI";
import ModalAuth from "../../../../../shared/components/ModalAuth";

interface IProps {
  transfers: string[];
  onReload: () => void;
}

const BTPButtonAuth: FC<IProps> = ({ transfers, onReload }) => {
  const [queryAuth, infoAuthQuery] = useMutationAPI(
    BulkTransferAPI.pending.authorize
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [transfersResponse, setTransfers] = useState<string[]>([]);

  useEffect(() => {
    const response = infoAuthQuery.data;
    if (response) {
      setTransfers(response.data.transfers);
      setIsOpenModal(true);
    }
  }, [infoAuthQuery]);

  return (
    <>
      <Button
        shape="round"
        size="large"
        disabled={!transfers.length}
        onClick={() => queryAuth({ transfers })}
        loading={infoAuthQuery.isLoading}
      >
        {!transfers.length
          ? "Authorize & Execute"
          : `Authorize & Execute (${transfers.length})`}
      </Button>
      {isOpenModal && (
        <ModalAuth
          onCancel={() => setIsOpenModal(false)}
          onResponse={onReload}
          transfers={transfersResponse}
          execute={BulkTransferAPI.pending.execute}
        />
      )}
    </>
  );
};

export default BTPButtonAuth;
