import { useContext } from "react";
import { ContextInvoiceBuilder } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import { getValueError } from "../../../../../../payments/data";

const IBRemFirstRow = () => {
  const { dataFS, dataCommon, onChangeData, setDataFS, validateOnBlur } =
    useContext(ContextInvoiceBuilder);

  return (
    <InputComponent
      {...dataFS.RemCompanyName}
      title="Company / Name:"
      placeholder="Select Remitter or enter a new one"
      onChangeValue={(value) => {
        if (!value.length) {
          setDataFS((prev) => ({
            ...prev,
            RemState: getValueError({ text: "", id: "" }),
            RemCity: getValueError(""),
            RemCompanyName: getValueError(""),
            RemCountry: getValueError({ text: "", id: "" }),
            RemEmail: getValueError(""),
            RemHouseNumber: getValueError(""),
            RemPhone: getValueError(""),
            RemPostalCode: getValueError(""),
            RemStreetAddress: getValueError(""),
          }));
        }
        onChangeData("RemCompanyName", value);
      }}
      hints={{
        data: dataCommon.dataRemHints,
        onSelect: (data) => setDataFS((prev) => ({ ...prev, ...data })),
      }}
      onBlur={() => validateOnBlur("RemCompanyName")}
      maxLength={100}
      id="RemCompanyName"
    />
  );
};

export default IBRemFirstRow;
