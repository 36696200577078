import { Button } from "antd";
import { useContext, useId } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CryptoSellContext } from "..";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { setIsQuestionLeave } from "../../../../store/user";
import { TypeToText } from "../../../../utils/typeToText";
import * as Styled from "./style";

const CryptoSellTS = () => {
  const idComponent = useId();
  const valueContext = useContext(CryptoSellContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dataThird } = valueContext;
  const { table } = dataThird;

  const onClickContinue = () => {
    dispatch(setIsQuestionLeave(false));
    navigate("/crypto");
  };

  return (
    <div>
      <WrapperWhiteBG title="ORDER DETAILS">
        {table.map(({ title, data }, index) => {
          const key = idComponent + "." + index;
          return (
            <Styled.WrapperRow key={key}>
              {!!title.length && (
                <Styled.Title fontSize={16} breakWord>
                  {title}
                </Styled.Title>
              )}
              <div>
                {data.map((item, index) => (
                  <TypeToText
                    {...item}
                    key={key + "." + index}
                    styleData={{ fontSize: 14 }}
                  />
                ))}
              </div>
            </Styled.WrapperRow>
          );
        })}
        <Styled.WrapperButton>
          <Button shape="round" size="large" onClick={onClickContinue}>
            Continue
          </Button>
        </Styled.WrapperButton>
      </WrapperWhiteBG>
    </div>
  );
};

export default CryptoSellTS;
