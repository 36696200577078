import { useContext, useMemo } from "react";
import { ContextRecurringPayment } from "../../..";
import AmountComponent from "../../../../../component/Global/Amount";
import InputComponent from "../../../../../component/Global/Input";
import PickerComponent from "../../../../../component/Global/Picker";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import validateString, {
  IRulesString,
} from "../../../../../utils/validate/validateString";
import RPBFSGSchedule from "./schedule";
import * as Styled from "./style";

const ruleTempName: IRulesString = { isReq: true, min: 3, max: 50 };
const ruleDescp: IRulesString = { isReq: true, min: 3, max: 100 };
const ruleRef: IRulesString = {
  isReq: true,
  min: 3,
  max: 18,
  ownRule: {
    rule: /^([a-zA-Z0-9\/?:()-.+#=!%&*<>;{@ ]){1,18}$/,
    error: "Сontains invalid characters",
  },
};

const RPBFSGeneral = () => {
  const { dataCommon, dataFS, onChangeData, onErrorData } = useContext(
    ContextRecurringPayment
  );
  const { account, amount, description, templateName } = dataFS;
  const { dataAccount, dataSymbol, type } = dataCommon;

  const onBlur = (key: "description" | "templateName", rule: IRulesString) => {
    const error = validateString(dataFS[key].value, rule);
    onErrorData(key, error);
  };

  const amountSymbol = useMemo(
    () => dataSymbol.find(({ id }) => account.value.id === id)?.text || "",
    [dataSymbol, account]
  );

  return (
    <WrapperWhiteBG title="General">
      <PickerComponent
        {...account}
        setValue={(value) => onChangeData("account", value)}
        id="account"
        data={dataAccount}
        title="Send funds from Account:"
        placeholder="Select Account"
      />
      <RPBFSGSchedule />
      <Styled.WrapperAmount>
        <InputComponent
          {...templateName}
          id="templateName"
          title="Payment note:"
          onChangeValue={(value) => onChangeData("templateName", value)}
          onBlur={() => onBlur("templateName", ruleTempName)}
          placeholder="Note for me"
          maxLength={50}
          width="30%"
        />
        <AmountComponent
          {...amount}
          title="Amount:"
          id="amount"
          onChangeValue={(value) => onChangeData("amount", value)}
          width="20%"
          prefix={amountSymbol}
        />
        <InputComponent
          {...description}
          id="description"
          title={`Payment ${type === "uk" ? "reference" : "description"}:`}
          onChangeValue={(value) => onChangeData("description", value)}
          onBlur={() =>
            onBlur("description", type === "uk" ? ruleRef : ruleDescp)
          }
          maxLength={100}
          width="50%"
        />
      </Styled.WrapperAmount>
    </WrapperWhiteBG>
  );
};

export default RPBFSGeneral;
