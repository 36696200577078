import { DownloadOutlined } from "@ant-design/icons";
import { FC, useEffect } from "react";
import PickerComponent from "../../../component/Global/Picker";
import { TransactionsAPI } from "../../../services/transactions";
import { dowloadBase64 } from "../../../utils/dowloadBase64";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import { getPayload } from "../data";
import { IData } from "../types";
import * as Styled from "./style";

interface IProps {
  data: IData;
}

const TransactionDownload: FC<IProps> = ({ data }) => {
  const [queryDownload, infoDownloadQuery] = useMutationAPI(
    TransactionsAPI.downloadFile
  );

  useEffect(() => {
    const response = infoDownloadQuery.data?.data;
    if (response) {
      dowloadBase64(response.fileContents, response.fileName);
    }
  }, [infoDownloadQuery]);

  const onSelect = (exportType: string) => {
    queryDownload({ ...getPayload(data, false), exportType });
  };

  return (
    <Styled.Wrapper>
      <PickerComponent
        value={{ text: "", id: "" }}
        data={data.downloadData}
        setValue={({ id }) => onSelect(id)}
        width={"250px"}
        icon={<DownloadOutlined />}
        isLoading={infoDownloadQuery.isLoading}
        placeholder="Download Report"
        disabledError
      />
    </Styled.Wrapper>
  );
};

export default TransactionDownload;
