import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import ModalDeleteComponent from "../../../../component/commonModal";
import { PendingPaymnetsAPI } from "../../../../services/pendingPayments";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import useSizePage from "../../../../utils/hookSizePage";

interface IProps {
  transfers: string[];
  onRealod: () => void;
}

const PPButtonDelete: FC<IProps> = ({ transfers, onRealod }) => {
  const [queryDelete, infoDeleteQuery] = useMutationAPI(
    PendingPaymnetsAPI.delete
  );

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { width } = useSizePage();

  useEffect(() => {
    const response = infoDeleteQuery.data;
    if (response && response.data.result) {
      onRealod();
      setIsOpenModal(false);
    }
  }, [infoDeleteQuery]);

  return (
    <>
      <Button
        shape="round"
        size="large"
        onClick={() => setIsOpenModal(true)}
        disabled={!transfers.length}
        danger
      >
        {width > 1024 ? "Delete selected" : "Delete"}
      </Button>
      {isOpenModal && (
        <ModalDeleteComponent
          onClose={() => setIsOpenModal(false)}
          onClickYes={() => queryDelete({ transfers })}
          onClickNo={() => setIsOpenModal(false)}
          loadingYes={infoDeleteQuery.isLoading}
          title="Delete transfers?"
        />
      )}
    </>
  );
};

export default PPButtonDelete;
