import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 460px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 70px 100px 26px;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;

  @media (max-width: 1024px) {
    width: 300px;
    padding: 40px 30px 26px;
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 70%;
`;

export const Error = styled.div`
  width: 100%;
  animation: slideDownAlert 0.4s;
`;
