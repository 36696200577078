import { FC, useEffect, useId, useState } from "react";
import { IDataRowTable, ITableDetails, ITableDetailsTable } from "../types";
import BodyItemTable from "./body";
import DetailsDashboard from "./details";
import TIFirstIcon from "./firstIcon";
import TILastIcon from "./lastIcon";
import TIShowDetails from "./showDetails";
import * as Styled from "./style";

interface IProps {
  data: IDataRowTable[];
  details: ITableDetails[];
  details_table: ITableDetailsTable[];
  isSelected: boolean;
  id?: string;
  index: number;
  onSelect: (id: string) => void;
  onClickDelete?: (index: number) => void;
  onClickEdit?: (data: IDataRowTable[], index: number) => void;
  onClickDuplicate?: (index: number, data: IDataRowTable[]) => void;
  paddingDetails: string;
}

const ItemTable: FC<IProps> = ({
  data,
  details,
  id,
  isSelected,
  onSelect,
  index,
  onClickDelete,
  onClickEdit,
  onClickDuplicate,
  paddingDetails,
  details_table,
}) => {
  const idComponent = useId();
  const [isOpen, setIsOpen] = useState(false);

  const openDetails = (isWrapper: boolean) => {
    if (
      (!details.length && !details_table.length) ||
      (isWrapper && window.innerWidth < 1024)
    )
      return;
    setIsOpen((prev) => !prev);
  };

  return (
    <Styled.Wrapper>
      <Styled.WrapperMainInfo
        onClick={() => openDetails(true)}
        isCurson={!!details.length || !!details_table.length}
      >
        <TIFirstIcon
          id={id}
          isDetails={!!details.length || !!details_table.length}
          isOpen={isOpen}
          onSelect={onSelect}
          checked={isSelected}
          onDuplicate={
            onClickDuplicate ? () => onClickDuplicate(index, data) : undefined
          }
        />

        <Styled.WrapperBody
          // isFirst={!!id || !!details.length || !!onClickDuplicate}
          isFirst
          isLast={!!onClickDelete}
        >
          {data.map((props, index) => {
            return <BodyItemTable data={props} key={idComponent + index} />;
          })}
        </Styled.WrapperBody>

        <TILastIcon index={index} data={data} onClick={onClickDelete} />
      </Styled.WrapperMainInfo>

      {(!!details.length || !!details_table.length) && (
        <TIShowDetails
          isOpen={isOpen}
          changeStatus={() => openDetails(false)}
        />
      )}
      <DetailsDashboard
        details={details}
        details_table={details_table}
        isOpen={isOpen}
        paddingLeft={paddingDetails}
      />
    </Styled.Wrapper>
  );
};

export default ItemTable;
