import { useContext } from "react";
import { ContextInvoiceBuilder } from "../../..";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import * as Styled from "./style";

const IBSSHeader = () => {
  const { beneficiary, logo } = useContext(ContextInvoiceBuilder).dataSS;

  return (
    <Styled.Wrapper>
      <div>
        <WrapperWhiteBG title={beneficiary.title}>
          <TypeToTextMap data={beneficiary.data} />
        </WrapperWhiteBG>
      </div>

      <div>
        {!!logo.length && (
          <Styled.Logo src={"data:image/png;base64, " + logo} alt="logo" />
        )}
      </div>
    </Styled.Wrapper>
  );
};

export default IBSSHeader;
