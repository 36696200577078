import { Button, InputRef } from "antd";
import { FC, forwardRef, ReactNode, useState } from "react";
import { Text } from "../../commonStyle";
import { TAuthCode } from "../../services/types";
import validateString from "../../utils/validate/validateString";
import InputComponent from "../Global/Input";
import WrapperWhiteBG from "../Wrappers/wrapperWhiteBG";
import { IValueError } from "../payment/types";
import HeaderThirdStepPayment from "./header";
import * as Styled from "./style";
import { useAppSelector } from "../../utils/useState";

interface IProps {
  amount?: string;
  name?: string;
  title?: string;
  onClick: (value: IValueError<string>) => void;
  isLoadingAuth: boolean;
  children: ReactNode;
}

const textType = (type: TAuthCode) => {
  if (type === "google-authenticator") return "Google Authenticator code";
  return "SMS code";
};

const AuthTransfer = forwardRef<InputRef, IProps>(
  ({ name, amount, onClick, isLoadingAuth, children, title }, ref) => {
    const [auth, setAuth] = useState({ value: "", error: "" });

    const type = useAppSelector(
      (store) => store.userReducer.userMultiFactorType
    );

    const countNumber = type === "sms" ? 4 : 6;

    const onBlurCode = () => {
      const error = validateString(auth.value, {
        min: countNumber,
        max: countNumber,
      });
      setAuth((prev) => ({ ...prev, error }));
    };

    const onChangeCode = (value: string) => {
      if (/^[0-9]*$/.test(value)) {
        setAuth({ error: "", value });
      }
    };

    const onClickAuth = () => {
      onClick(auth);
    };

    return (
      <Styled.Wrapper>
        <WrapperWhiteBG>
          <Styled.Wrapper>
            <HeaderThirdStepPayment name={name} amount={amount} title={title} />

            <Styled.WrapperInput>
              <InputComponent
                {...auth}
                title={textType(type)}
                onChangeValue={onChangeCode}
                onKeyEnter={onClickAuth}
                onBlur={onBlurCode}
                maxLength={countNumber}
                ref={ref}
              />
            </Styled.WrapperInput>
          </Styled.Wrapper>
          <Styled.WrapperAuthButton>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={onClickAuth}
              disabled={!auth.value.length}
              loading={isLoadingAuth}
            >
              Authorize
            </Button>
          </Styled.WrapperAuthButton>
        </WrapperWhiteBG>
        {children}
      </Styled.Wrapper>
    );
  }
);

export default AuthTransfer;
