import { FC, useContext, useEffect } from "react";
import { CryptoSellContext } from "..";
import InputComponent from "../../../../component/Global/Input";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { CryptoAPI } from "../../../../services/crypto";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import validateNumber from "../../../../utils/validate/validateNumber";
import validateString from "../../../../utils/validate/validateString";
import CryptoSellAmount from "./amount";
import CryptoBenef from "./benef";
import CSellButtons from "./buttons";
import CryptoConfirmRow from "./confirm";
import { getDecimal, getMin } from "./data";

const CryptoSellFS = () => {
  const {
    dataFirst,
    dataCommon,
    setDataSecond,
    setStep,
    onChangeData,
    onErrorData,
  } = useContext(CryptoSellContext);

  const [queryValidate, infoValidateQuery] = useMutationAPI(
    CryptoAPI.sell.validate
  );

  const { description, isConfirm } = dataFirst;
  const { dataBenef, dataConfirm } = dataCommon;

  useEffect(() => {
    const response = infoValidateQuery.data;
    if (response) {
      const {
        paymentAmountFormatted: amount,
        paymentChecksum: checksum,
        paymentName: name,
      } = response.data.payment;
      setDataSecond((prev) => ({
        ...prev,
        name,
        amount,
        checksum,
        type: response.data.multiFactorType,
      }));
      setStep(2);
    }
  }, [infoValidateQuery]);

  const onBlurNote = () => {
    const error = validateString(description.value, {
      min: 3,
      max: 100,
    });

    onErrorData("description", error);
  };

  return (
    <div>
      <WrapperWhiteBG title="Payment Details">
        <CryptoBenef data={dataBenef} />

        <CryptoSellAmount />

        <InputComponent
          {...description}
          onChangeValue={(value) => onChangeData("description", value)}
          title="Note (optional):"
          maxLength={100}
          onBlur={onBlurNote}
        />
        <CryptoConfirmRow
          {...isConfirm}
          onChangeValue={(value) => onChangeData("isConfirm", value)}
          dataConfirm={dataConfirm}
          disabled={infoValidateQuery.isLoading}
        />
      </WrapperWhiteBG>

      <CSellButtons
        queryAuth={queryValidate}
        isLoadingAuth={infoValidateQuery.isLoading}
      />
    </div>
  );
};

export default CryptoSellFS;
