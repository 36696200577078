import { InputRef } from "antd";
import { ReactNode, createRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WrapperPayment from "../../../component/Wrappers/wrapperPayment";
import { ISecondStepDataAPI } from "../../../services/paymentAPI/types";
import { IResponseCommon } from "../../../services/types";
import { getValuesDataFS } from "../../../utils/getValuesDataFS";
import { useQuestionBeforeLeave } from "../../../utils/questionBeforeLeave";
import { IInfoQuery } from "../../../utils/usePromise/hookQueryAPI";
import SecondStepPayment from "../steps/secondStep";
import ThirdStepPayment from "../steps/thirdStep";
import {
  IDataCommon,
  IDataTS,
  TDataFS,
  TOnChangeDataFS,
  TSetErrorDataFS,
} from "../types";
import { IContext } from "./types";
import { useForm, UseFormConfig } from "./firstStep/form";
import { IRedirectOnPaymentCompletedData } from "../../../component/payment/execute";

export interface TLocationState {
  accountId?: string;
  paymentId?: string;
  mode: string;
}

interface ILocation<State extends TLocationState> {
  state?: Partial<State>;
}

interface Props<
  DataFS extends TDataFS<any>,
  DataCommon extends IDataCommon,
  IInitResponseInit extends IResponseCommon,
  IInitResponseInitError,
  LocationState extends TLocationState
> {
  initDataFS: DataFS;
  initDataSS: ISecondStepDataAPI[];
  initDataTS: IDataTS;
  initDataCommon: DataCommon;
  validateAll?: (data: DataFS, dataCommon: DataCommon) => void; // TODO make required
  updateInput: (input: TLocationState) => LocationState;
  infoInitQueryProvider: (
    input: LocationState
  ) => IInfoQuery<IInitResponseInit, IInitResponseInitError>;
  firstStepComponet: (
    context: IContext<DataFS, DataCommon>,
    setStep: (step: number) => void
  ) => ReactNode;
  onInitQueryLoaded: (
    response: IInfoQuery<IInitResponseInit, IInitResponseInitError>,
    input: LocationState,
    onChangeDataFS: TOnChangeDataFS<DataFS>,
    updateDataCommon: (update: (data: DataCommon) => DataCommon) => void
  ) => void;
  isSavePending?: boolean;
  formConfig?: UseFormConfig<DataFS>;
  redirectOnPaymentCompletedData?: IRedirectOnPaymentCompletedData;
}

const PaymentSystem = <
  DataFS extends TDataFS<any>,
  DataCommon extends IDataCommon,
  IInitResponseInit extends IResponseCommon,
  IInitResponseInitError,
  LocationState extends TLocationState
>({
  initDataFS,
  initDataSS,
  initDataTS,
  initDataCommon,
  validateAll = () => {},
  updateInput,
  infoInitQueryProvider,
  firstStepComponet,
  onInitQueryLoaded,
  isSavePending,
  formConfig: config,
  redirectOnPaymentCompletedData,
}: Props<
  DataFS,
  DataCommon,
  IInitResponseInit,
  IInitResponseInitError,
  LocationState
>) => {
  const navigate = useNavigate();
  let { state: stateOpt } = useLocation() as ILocation<TLocationState>;
  if (!stateOpt) {
    stateOpt = {};
  }
  let newState: TLocationState;
  if (!stateOpt?.mode) {
    newState = {
      ...stateOpt,
      mode: "init",
    };
  } else {
    newState = {
      ...stateOpt,
      mode: stateOpt.mode,
    };
  }
  const input = updateInput(newState);
  const { accountId, paymentId } = input;

  const infoInitQuery = infoInitQueryProvider(input);

  const refInputTS = createRef<InputRef>();

  const [step, setStep] = useState(1);
  const [dataFS, setDataFS] = useState(initDataFS);
  const [dataSS, setDataSS] = useState(initDataSS);
  const [dataTS, setDataTS] = useState(initDataTS);
  const [dataCommon, setDataCommon] = useState(initDataCommon);

  const onChangeDataFS: TOnChangeDataFS<DataFS> = (key, value) => {
    setDataFS((prev) => ({ ...prev, [key]: { error: "", value } }));
  };

  const setErrorDataFS: TSetErrorDataFS<DataFS> = (key, error) => {
    setDataFS((prev) => ({ ...prev, [key]: { ...prev[key], error } }));
  };

  const dataFSValues = getValuesDataFS(dataFS);
  const onFinishLoading = useQuestionBeforeLeave({ deps: [dataFSValues] });

  const formActions = useForm(
    dataFS,
    setDataFS,
    (dataFS) => validateAll(dataFS, dataCommon),
    config
  );

  useEffect(() => {
    if (accountId === undefined && paymentId === undefined)
      navigate("/new-payment");
  }, []);

  useEffect(() => {
    onInitQueryLoaded(infoInitQuery, input, onChangeDataFS, setDataCommon);
    if (infoInitQuery.data) {
      setTimeout(() => {
        onFinishLoading(false);
      }, 1000);
    }
  }, [infoInitQuery]);

  useEffect(() => {
    if (step === 3) {
      setTimeout(() => {
        if (refInputTS.current) refInputTS.current.focus();
      }, 1000);
    }
  }, [step]);

  const context: IContext<DataFS, DataCommon> = {
    step,
    setStep,
    dataFS,
    setDataFS,
    updateDataFS: (newData) => {
      setDataFS((currDataFS) => ({ ...currDataFS, ...newData }));
    },
    dataSS,
    setDataSS,
    dataTS,
    setDataTS,
    dataCommon,
    setDataCommon,
    onChangeDataFS,
    setErrorDataFS,
    formActions,
  };

  return (
    <WrapperPayment {...infoInitQuery.data?.data.page?.header} step={step}>
      {firstStepComponet(context, setStep)}
      <SecondStepPayment
        data={dataSS}
        setStep={setStep}
        step={step}
        paymentID={dataCommon.paymentID}
        checksum={dataCommon.checksum}
        isAbleAuth={dataCommon.isAbleAuth}
        isSavePending={isSavePending}
      />
      <ThirdStepPayment
        data={dataTS}
        setStep={setStep}
        paymentId={dataCommon.paymentID}
        checksum={dataCommon.checksum}
        ref={refInputTS}
        redirectOnPaymentCompletedData={redirectOnPaymentCompletedData}
      />
    </WrapperPayment>
  );
};

export default PaymentSystem;
