import { useContext } from "react";
import { ContextInvoiceBuilder } from "../../..";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";

const IBFSBeneficiary = () => {
  const { dataFS, dataCommon } = useContext(ContextInvoiceBuilder);
  const { beneficiary } = dataCommon;
  const DAccountID = dataFS.GAccount.value.id;

  return (
    <WrapperWhiteBG title="Pay to">
      {!beneficiary.length || !DAccountID.length ? (
        <div> Please select the Account to Receive funds to above </div>
      ) : (
        <TypeToTextMap
          data={
            beneficiary.find(({ id }) => id === DAccountID)?.data || [
              [{ text: "This account does not exist", type: "normal" }],
            ]
          }
        />
      )}
    </WrapperWhiteBG>
  );
};

export default IBFSBeneficiary;
