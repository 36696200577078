import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 24px;
  padding: 8px;
  & > span {
    display: none;
  }
  & > span > svg {
    width: 16px;
    height: auto;
  }
  &:hover {
    & > span > svg {
      color: red;
    }
  }
`;
