import { FC, useContext } from "react";
import { ContextRecurringPayment } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import validateString, {
  IRulesString,
} from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

interface IProps {
  isUS: boolean;
  isCA: boolean;
}

const rulesAccountNumber: IRulesString = { isReq: true, min: 7, max: 34 };
const rulesBIC: IRulesString = { isReq: true, min: 8, max: 11 };
const rulesABA: IRulesString = { isReq: true, min: 9 };
const rulesTransit: IRulesString = { isReq: true, min: 5 };
const rulesInstitution: IRulesString = { isReq: true, min: 3 };

const RPBFPayToFR: FC<IProps> = ({ isCA, isUS }) => {
  const { dataFS, dataCommon, onChangeData, onErrorData } = useContext(
    ContextRecurringPayment
  );

  const { iban, bic, aba, institutionNumber, transitNumber, sortCode } = dataFS;
  const { type } = dataCommon;

  const onBlurString = (
    value: string,
    rules: IRulesString,
    key: keyof typeof dataFS
  ) => {
    onErrorData(key, validateString(value, rules));
  };

  return (
    <Styled.Row>
      {type !== "fx" && (
        <Styled.Item flex={2}>
          <InputComponent
            {...iban}
            onChangeValue={(value) => onChangeData("iban", value)}
            title={`${type !== "uk" ? "IBAN / " : ""}Account number:`}
            onBlur={() => onBlurString(iban.value, rulesAccountNumber, "iban")}
            maxLength={34}
            id="iban"
          />
        </Styled.Item>
      )}
      {type !== "uk" && type !== "fx" && (
        <Styled.Item flex={2}>
          <InputComponent
            {...bic}
            onChangeValue={(value) => onChangeData("bic", value)}
            title="BIC:"
            onBlur={() => onBlurString(bic.value, rulesBIC, "bic")}
            maxLength={11}
            id="bic"
          />
        </Styled.Item>
      )}
      {type === "uk" && (
        <Styled.Item flex={2}>
          <InputComponent
            {...sortCode}
            onChangeValue={(value) => onChangeData("sortCode", value)}
            title="Sort Code:"
            onBlur={() => onBlurString(sortCode.value, rulesABA, "sortCode")}
            id="sortCode"
            maxLength={9}
          />
        </Styled.Item>
      )}
      {isUS && type === "swift" && (
        <Styled.Item flex={2}>
          <InputComponent
            {...aba}
            onChangeValue={(value) => onChangeData("aba", value)}
            title="ABA:"
            onBlur={() => onBlurString(aba.value, rulesABA, "aba")}
            id="aba"
            maxLength={9}
          />
        </Styled.Item>
      )}
      {isCA && type === "swift" && (
        <>
          <Styled.Item flex={1}>
            <InputComponent
              {...institutionNumber}
              onChangeValue={(value) =>
                onChangeData("institutionNumber", value)
              }
              title="Institution Number:"
              id="institutionNumber"
              maxLength={3}
              onBlur={() =>
                onBlurString(
                  institutionNumber.value,
                  rulesInstitution,
                  "institutionNumber"
                )
              }
            />
          </Styled.Item>
          <Styled.Item flex={1}>
            <InputComponent
              {...transitNumber}
              onChangeValue={(value) => onChangeData("transitNumber", value)}
              title="Transit Number:"
              id="transitNumber"
              maxLength={5}
              onBlur={() =>
                onBlurString(transitNumber.value, rulesTransit, "transitNumber")
              }
            />
          </Styled.Item>
        </>
      )}
    </Styled.Row>
  );
};

export default RPBFPayToFR;
