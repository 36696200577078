import { FC, useEffect, useId, useMemo, useState } from "react";
import { IDate } from "..";
import { ILimitationDate } from "../../../DatePicker/types";
import { checkIsActive, getDataTable, getIsActiveRange } from "./data";
import * as Styled from "./style";

export interface IDataTable {
  date: Date;
  status: boolean;
}

interface IProps {
  start: Date;
  end: Date;
  month: number;
  year: number;
  setDate: (date: Date) => void;
  hoverDate: Date;
  setHoverDate: (date: Date) => void;
  limitationDate: ILimitationDate;
}

const dataDay = ["Mo", "Th", "We", "Th", "Fr", "Sa", "Su"];

const TableDateRange: FC<IProps> = ({
  start,
  end,
  month,
  year,
  setDate,
  hoverDate,
  setHoverDate,
  limitationDate,
}) => {
  const idComponent = useId();
  const [dataTable, setDataTable] = useState<IDataTable[][]>([]);

  useEffect(() => {
    setDataTable(getDataTable(month, year, limitationDate));
  }, [month, year]);

  const getAttribute = (date: Date, status: boolean) => ({
    isActive: checkIsActive(date, start, end, hoverDate) && status,
    color: status ? "#041e42" : "#878787",
    isActiveRange: getIsActiveRange(date, start, hoverDate) && status,
    status,
  });

  return (
    <Styled.WrapperTable>
      <Styled.WrapperColumn>
        {dataDay.map((item, index) => {
          return (
            <Styled.WrapperName key={`${idComponent}.dataDay.${index}`}>
              <Styled.Item>{item}</Styled.Item>
            </Styled.WrapperName>
          );
        })}
      </Styled.WrapperColumn>
      {dataTable.map((item, index) => {
        return (
          <Styled.WrapperColumn key={`${idComponent}.dataTable.${index}`}>
            {item.map(({ date, status }, index) => (
              <Styled.WrapperItem
                key={`${idComponent}.dataTableDate.${index}`}
                {...getAttribute(date, status)}
                onMouseOver={() => {
                  if (status) setHoverDate(date);
                }}
                onClick={() => {
                  if (status) setDate(date);
                }}
              >
                <Styled.Item>{date.getDate()}</Styled.Item>
              </Styled.WrapperItem>
            ))}
          </Styled.WrapperColumn>
        );
      })}
    </Styled.WrapperTable>
  );
};

export default TableDateRange;
