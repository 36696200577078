import { formatValue } from "react-currency-input-field";
import AmountComponent from "../../../../../component/Global/Amount";
import InputComponent from "../../../../../component/Global/Input";
import { IItemPicker } from "../../../../../component/Global/Picker";
import TextareaComponent from "../../../../../component/Global/Textarea";
import { ITable } from "../../../../../component/table/types";
import validateNumber from "../../../../../utils/validate/validateNumber";
import validateString from "../../../../../utils/validate/validateString";
import { ITableInvoiceBuilder } from "../../../types";
import { TOnChangeDataTable, TOnErrorDataTable } from "./types";
import { genIndexedKey } from "../../../../../utils/getIndexedKey";

const multipleNumberString = (...arg: string[]) => {
  return arg.reduce((prev, current) => prev * parseFloat(current) || 0, 1);
};

export const getDataTable = (
  data: ITableInvoiceBuilder[],
  currency: string,
  taxType: IItemPicker,
  onChangeData: TOnChangeDataTable,
  onErrorTable: TOnErrorDataTable,
  width: number
): ITable[] => {
  const isTax = !!taxType.id.length && taxType.id !== "none";

  const widthQTY = isTax ? "15%" : "20%";
  const widthSubtotal = isTax ? "20%" : "25%";
  const widthRate = isTax ? "15%" : "20%";

  const table: ITable[] = [
    {
      title: "Description",
      width: width > 1024 ? "35%" : "100%",
      positionText: "left",
      dataColumn: data.map(({ title }, index) => [
        [
          <TextareaComponent
            {...title}
            placeholder="Description"
            style={{
              height: "36px",
              fontSize: 16,
            }}
            maxLenght={300}
            onBlur={() =>
              onErrorTable(
                "title",
                index,
                validateString(title.value, { isReq: true })
              )
            }
            idKey={genIndexedKey("title", index)}
            onChangeValue={(value) => onChangeData("title", index, value)}
            disabledError={width < 1024 ? false : true}
            resizeWhenTyping
          />,
        ],
      ]),
    },
    {
      title: "QTY",
      width: width > 1024 ? widthQTY : "100%",
      positionText: "left",
      dataColumn: data.map(({ qty }, index) => [
        [
          <AmountComponent
            {...qty}
            width={width > 1024 ? "95px" : "100%"}
            placeholder="QTY"
            onBlur={() =>
              onErrorTable(
                "qty",
                index,
                validateNumber(
                  parseFloat(qty.value) || 0,
                  { min: 1 },
                  { decimalScale: 0 }
                )
              )
            }
            onChangeValue={(value) => {
              onChangeData("qty", index, value);
            }}
            decimalsLimit={0}
            idKey={genIndexedKey("qty", index)}
            disabledError={width < 1024 ? false : true}
          />,
        ],
      ]),
    },
    {
      title: "Rate",
      width: width > 1024 ? widthRate : "100%",
      positionText: "left",
      dataColumn: data.map(({ rate }, index) => [
        [
          <AmountComponent
            {...rate}
            width={width > 1024 ? "100px" : "100%"}
            placeholder="Rate"
            onBlur={() =>
              onErrorTable(
                "rate",
                index,
                validateNumber(parseFloat(rate.value) || 0, { min: 0.01 })
              )
            }
            onChangeValue={(value) => onChangeData("rate", index, value)}
            idKey={genIndexedKey("rate", index)}
            disabledError={width < 1024 ? false : true}
          />,
        ],
      ]),
    },

    {
      title: "Subtotal",
      width: width > 1024 ? widthSubtotal : "100%",
      positionText: "left",
      dataColumn: data.map(({ qty, rate, tax }) => {
        const value = multipleNumberString(qty.value, rate.value);
        return [
          [
            {
              text:
                currency +
                " " +
                formatValue({
                  value: String(
                    value + (value * (parseFloat(tax.value) || 0)) / 100
                  ),
                  decimalScale: 2,
                }),
              type: "normal",
            },
          ],
        ];
      }),
    },
  ];

  const taxColumn: ITable = {
    title: taxType.text + "(%)",
    width: width > 1024 ? "15%" : "100%",
    positionText: "left",
    dataColumn: data.map(({ tax }, index) => [
      [
        <AmountComponent
          {...tax}
          width={width > 1024 ? "100px" : "100%"}
          onBlur={() => {
            onErrorTable(
              "tax",
              index,
              validateNumber(parseFloat(tax.value) || 1, { min: 0.01, max: 60 })
            );
          }}
          onChangeValue={(value) => onChangeData("tax", index, value)}
          idKey={genIndexedKey("tax", index)}
          charLimit={5}
          disabledError={width < 1024 ? false : true}
          postfix="%"
        />,
      ],
    ]),
  };

  if (isTax) {
    table.splice(3, 0, taxColumn);
  }

  return table;
};
