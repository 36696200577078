import { InputRef } from "antd";
import { FC, forwardRef } from "react";
import ExecutePayment, {
  IRedirectOnPaymentCompletedData,
} from "../../../../component/payment/execute";

export interface IDataThirdStepPayment {
  amount: string;
  name: string;
}

interface IProps {
  data: IDataThirdStepPayment;
  setStep: (step: number) => void;
  paymentId: string;
  checksum: string;
  redirectOnPaymentCompletedData?: IRedirectOnPaymentCompletedData;
}

const ThirdStepPayment = forwardRef<InputRef, IProps>(
  ({ data, ...props }, ref) => {
    return <ExecutePayment {...data} {...props} ref={ref} />;
  }
);

export default ThirdStepPayment;
