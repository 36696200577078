import IUFSContinue from "./continue";
import IUFSFooter from "./footer";
import IUFSGeneral from "./general";
import IUFSStaticInfo from "./staticInfo";
import IUFSTable from "./table";
import * as Styled from "./style";

const InvoiceUseFS = () => {
  return (
    <Styled.Wrapper>
      <IUFSStaticInfo />
      <IUFSGeneral />
      <IUFSTable />
      <IUFSFooter />
      <IUFSContinue />
    </Styled.Wrapper>
  );
};

export default InvoiceUseFS;
