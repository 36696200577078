import { Button } from "antd";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import VerifyPayments from "../../../../component/payment/verify";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import PaymentAPI from "../../../../services/paymentAPI";
import { ISecondStepDataAPI } from "../../../../services/paymentAPI/types";
import { setIsQuestionLeave } from "../../../../store/user";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import * as Styled from "./style";

interface IProps {
  paymentID: string;
  checksum: string;
  step: number;
  setStep: (step: number) => void;
  data: ISecondStepDataAPI[];
  isAbleAuth?: boolean;
  isSavePending?: boolean;
}

const SecondStepPayment: FC<IProps> = ({
  paymentID,
  checksum,
  setStep,
  data,
  step,
  isAbleAuth = false,
  isSavePending = false,
}) => {
  const [authorizeQuery, infoAuthorizeQuery] = useMutationAPI(
    PaymentAPI.authorise
  );
  const [addToPendQuery, infoAddToPendQuery] = useMutationAPI(
    PaymentAPI.addToPending
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (infoAuthorizeQuery.data) {
      setStep(3);
    }
  }, [infoAuthorizeQuery]);

  useEffect(() => {
    if (infoAddToPendQuery.data) {
      dispatch(setIsQuestionLeave(false));
      navigate("/saved-payments");
    }
  }, [infoAddToPendQuery]);

  return (
    <div>
      <Styled.Wrapper>
        <WrapperWhiteBG>
          <VerifyPayments data={data} />
          <Styled.WrapperButtons>
            {isSavePending ? (
              <Button
                shape="round"
                size="large"
                onClick={() => addToPendQuery({ paymentID, checksum })}
                disabled={infoAuthorizeQuery.isLoading || step !== 2}
                loading={infoAddToPendQuery.isLoading}
              >
                Save as Pending
              </Button>
            ) : (
              <div />
            )}

            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => authorizeQuery({ paymentID })}
              loading={infoAuthorizeQuery.isLoading}
              disabled={
                !(!(infoAddToPendQuery.isLoading || step !== 2) && isAbleAuth)
              }
            >
              Authorize
            </Button>
          </Styled.WrapperButtons>
        </WrapperWhiteBG>
        <Button
          shape="round"
          size="large"
          onClick={() => setStep(1)}
          style={{ marginTop: "20px" }}
          disabled={
            infoAuthorizeQuery.isLoading || infoAddToPendQuery.isLoading
          }
        >
          Edit payment details
        </Button>
      </Styled.Wrapper>
    </div>
  );
};

export default SecondStepPayment;
