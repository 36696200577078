import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import CRPDFirstRow from "./rows/firstRow";
import CRPDSecondRow from "./rows/secondRow";
import CRPDThirdRow from "./rows/thirdRow";

const CRPersonalDetails = () => {
  return (
    <WrapperWhiteBG title="Personal details">
      <CRPDFirstRow />
      <CRPDSecondRow />
      <CRPDThirdRow />
    </WrapperWhiteBG>
  );
};

export default CRPersonalDetails;
