import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { TextBold } from "../../../../../../commonStyle";
import { InputSwift } from "../../../types";
import CorrespRowBeneficiarySwift from "./correspRow";
import FirstRowBeneficiarySwift from "./firstRow";
import SecondRowBeneficiarySwift from "./secondRow";
import * as Styled from "./style";
import ThirdRowBeneficiarySwift from "./thirdRow";

const BeneficiaryRowsSwift = ({ input }: { input: InputSwift }) => {
  const { dataFS, dataCommon, onChangeDataFS } = input;
  const { canadaID, usID } = dataCommon;
  const countryID = dataFS.BCountry.value.id;
  const isOpenCorresp = dataFS.BIsOpenCorresp.value;

  const onClickDetails = () => {
    onChangeDataFS("BCorrespAccount", "");
    onChangeDataFS("BCorrespBIC", "");
    onChangeDataFS("BCorrespBankName", "");
    onChangeDataFS("BIsOpenCorresp", !isOpenCorresp);
  };

  return (
    <>
      <FirstRowBeneficiarySwift
        input={input}
        isUS={countryID === usID}
        isCanada={countryID === canadaID}
      />
      <SecondRowBeneficiarySwift
        input={input}
        isUS={countryID === usID}
        isCanada={countryID === canadaID}
      />
      <ThirdRowBeneficiarySwift
        input={input}
        isUS={countryID === usID}
        isCanada={countryID === canadaID}
      />
      <Styled.TitleCorrespDetails onClick={onClickDetails}>
        <Styled.TitleImg>
          {isOpenCorresp ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
        </Styled.TitleImg>
        <TextBold fontSize={18}>Correspondents Details</TextBold>
      </Styled.TitleCorrespDetails>
      {isOpenCorresp && <CorrespRowBeneficiarySwift input={input} />}
    </>
  );
};

export default BeneficiaryRowsSwift;
