import { useEffect, useState } from "react";
import AmountComponent from "../../../../../../component/Global/Amount";
import PaymentAPI from "../../../../../../services/paymentAPI";
import useMutationAPI from "../../../../../../utils/usePromise/hookMutationAPI";
import validateNumber from "../../../../../../utils/validate/validateNumber";
import { InputCrypto } from "../../../types";
import PaymentRateComponent from "./Rate";
import * as Styled from "./style";

const PaymentDetaisCurrencyFirst = ({
  input: {
    dataFS,
    dataCommon,
    setDataCommon,
    onChangeDataFS,
    formActions: { prepareMoney },
  },
}: {
  input: InputCrypto;
}) => {
  const [queryRate, infoRateQuery] = useMutationAPI(PaymentAPI.crypto.rate);

  const [valueAmount, setValueAmount] = useState(0);

  const { DAmount } = dataFS;
  const { balance, rate, nameCrypto, amountSymbol, currencyCode } = dataCommon;

  useEffect(() => {
    if (!!nameCrypto.length) queryRate({ nameCrypto, currencyCode });
  }, [nameCrypto, DAmount.value]);

  useEffect(() => {
    if (infoRateQuery.data) {
      setDataCommon((prev) => ({
        ...prev,
        rate: infoRateQuery.data?.data.rate || 0,
      }));
    }
  }, [infoRateQuery]);

  return (
    <Styled.WrapperRow>
      <AmountComponent
        {...DAmount}
        onChangeValue={(value) => {
          setValueAmount(parseFloat(value));
          onChangeDataFS("DAmount", value);
        }}
        title="Amount:"
        placeholder={amountSymbol + " 0.00"}
        prefix={amountSymbol + " "}
        charLimit={16}
        id="DAmount"
        fieldActions={prepareMoney("DAmount", (value) =>
          validateNumber(
            value,
            { min: 500, max: balance },
            {
              symbol: amountSymbol,
            }
          )
        )}
      />
      <PaymentRateComponent
        isAble={!!DAmount.value.length && !!rate}
        isError={valueAmount > balance}
        text={`${nameCrypto} ${parseFloat((valueAmount / rate).toFixed(4))}`}
      />
    </Styled.WrapperRow>
  );
};

export default PaymentDetaisCurrencyFirst;
