import styled from "styled-components";

export const WrapperButton = styled.div`
  margin: 20px 0;
`;

export const WrapperTables = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px 0;
`;
