import { IPayloadValidateCrypto } from "../../../../../services/paymentAPI/types/payload";
import { IDataFS } from "../../types";

export const getPayload = (
  data: IDataFS,
  accountId: string,
  nameCrypto: string
): IPayloadValidateCrypto["payload"] => {
  let payload: IPayloadValidateCrypto["payload"] = {
    accountId,
    paymentAmount: parseFloat(data.DAmount.value) || 0,
    paymentDescription: data.DReason.value,
    paymentReference: data.DWallet.value,
    flagWalletConsent: data.DIsConfirm.value,
    cryptoCurrencyCode: nameCrypto,
  };

  return payload;
};
