import { IPayloadSellValidate } from "../../../../../services/crypto/types/payload";
import validateNumber from "../../../../../utils/validate/validateNumber";
import validateString from "../../../../../utils/validate/validateString";
import { IDataCryptoSellFS } from "../../types";
import { getMin } from "../data";

const getError = (key: keyof IDataCryptoSellFS, error = "") => {
  return {
    key,
    error,
  };
};

export const validate = (
  data: IDataCryptoSellFS,
  amountSymbol: string,
  code: string
) => {
  const keys = Object.keys(data) as (keyof IDataCryptoSellFS)[];

  const errors = keys.map((key) => {
    const value = data[key].value;

    if (key === "amount") {
      const error = validateNumber(
        parseFloat(data[key].value),
        {
          min: getMin(code),
        },
        {
          symbol: amountSymbol,
        }
      );
      return getError(key, error);
    }

    if (key === "description") {
      return getError(key);
    }

    if (typeof value === "boolean") {
      return getError(key);
    }

    return getError(key, validateString(value, { isReq: true }));
  });

  if (!data.isConfirm.value)
    errors.push({ key: "isConfirm", error: "Please confirm the sell" });

  return errors;
};

export const getPayload = (
  accountId: string,
  dataFS: IDataCryptoSellFS,
  fiatAccountId: string,
  fiatCurrencyId: string,
  rateProps: number
): IPayloadSellValidate["payload"] => {
  const fxPaymentAmount = parseFloat(dataFS.amount.value) || 0;

  const rate = rateProps / fxPaymentAmount || 0;
  return {
    accountId,
    fiatAccountId,
    fiatCurrencyId,
    flagConsent: dataFS.isConfirm.value,
    fxPaymentAmount,
    paymentDescription: dataFS.description.value,
    rate,
  };
};
