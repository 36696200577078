import styled from "styled-components";

export const Line = styled.div`
  display: flex;
  gap: 0 5px;
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
