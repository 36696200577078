import { PaperClipOutlined } from "@ant-design/icons";
import { FC, useId } from "react";
import { IDataVerifyPayment } from "..";
import { IItemTableAPI } from "../../../../services/types/table";
import { TypeToText } from "../../../../utils/typeToText";
import * as Styled from "./style";

interface IProps {
  data: IDataVerifyPayment<IItemTableAPI>[];
}

const VPItem: FC<IProps> = ({ data }) => {
  const idComponent = useId();
  if (!(data instanceof Array)) return <div></div>;

  return (
    <Styled.Wrapper>
      {data.map(({ title, data }, index) => {
        const key = idComponent + index;
        return (
          <Styled.WrapperItem key={key}>
            {!!title.length && (
              <Styled.Title fontSize={16}>{title}</Styled.Title>
            )}
            {data.map((item,index) => (
              <TypeToText
                key={key + ".Body." + index}
                {...item}
                styleData={{ width: "fit-content", breakWord: true }}
              />
            ))}
          </Styled.WrapperItem>
        );
      })}
    </Styled.Wrapper>
  );
};

export default VPItem;
