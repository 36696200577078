import { useEffect, useState } from "react";

const useSizePage = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const changeSize = () => {
    const { innerHeight: height, innerWidth: width } = window;
    setSize({ width, height });
  };

  useEffect(() => {
    window.addEventListener("resize", changeSize);
    changeSize();
  }, []);

  return size;
};

export default useSizePage;
