import { IItemPicker } from "../../component/Global/Picker";
import TypeToTextMap from "../../component/Global/TypeToTextMap";
import { IResponseInit } from "../../services/massPaymentAPI/types";
import { getValueError } from "../payments/data";
import { IContext, IDataCommon, IDataContext } from "./types";

export const initData: IDataContext = {
  account: getValueError({ text: "", id: "" }),
  paymentDestination: getValueError({ text: "", id: "" }),
  files: getValueError([]),

  importId: "",
  dataTable: [],
  secondStepHeader: [],
  secondStepSummary: [],
  hasErrors: false,
  documents: {
    title: "",
    data: [],
  },
  typeAuth: "google-authenticator",
  dataThirdStep: {
    amount: "",
    name: "",
  },
  isLoadingFile: false,
  dataInfo: [],
};

export const initDataCommon: IDataCommon = {
  dataAccount: [],
  dataDestination: [],
  accountToDestination: [],
};

export const initContext: IContext = {
  data: initData,
  setData: () => {},
  dataCommon: initDataCommon,
  setDataCommon: () => {},
  setStep: () => {},
  step: 1,
  typeQuery: "mass-payments",
};

export const getDataAccount = (
  dataProps: IResponseInit["mass_payment_types_list"]
) => {
  const data = dataProps?.dropdown_options.data || [];
  return data.map(
    ({ data, value }): IItemPicker => ({
      text: data.map(({ text }) => text).join(""),
      id: value,
      component: <TypeToTextMap data={[data]} />,
    })
  );
};

export const getAccountToDestination = (
  dataProps: IResponseInit["remitters_list"]
): IDataCommon["accountToDestination"] => {
  const data = dataProps?.dropdown_options.data || [];
  return data.map(({ options: dataId, value }) => ({
    value,
    dataId,
  }));
};
