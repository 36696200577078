import { IPayloadValidateSwift } from "../../../../../services/paymentAPI/types/payload";
import { IDataFS } from "../../types";

export const getPayload = (
  data: IDataFS,
  accountId: string,
  isCanada: boolean,
  isUs: boolean,
  useCompanyName: boolean
): IPayloadValidateSwift["payload"] => {
  let payload: IPayloadValidateSwift["payload"] = {
    accountId,
    paymentAccountNumberTo: data.BAccountNumber.value,
    paymentAmount: parseFloat(data.DAmount.value),
    paymentBankBic: data.BBic.value,
    paymentDescription: data.DPaymentDescription.value,
    paymentDescriptionCompanyName: useCompanyName && data.DIsCompanyName.value,
    paymentIsSameCompanyTransfer: data.DIsInterTransfer.value,
    paymentName: data.BCompany.value,
    paymentPriorityType: data.DIsUrgent.value,
    paymentCorrespondentAccount: data.BCorrespAccount.value,
    paymentCorrespondentBankBic: data.BCorrespBIC.value,
    paymentCorrespondentBankName: data.BCorrespBankName.value,
    paymentNameAddressCity: data.BCity.value,
    paymentNameAddressNumber: data.BHouseNumber.value,
    paymentNameAddressPostalCode: data.BPostalCode.value,
    paymentNameAddressState: isUs
      ? data.BState.value.id
      : isCanada
      ? data.BCaState.value.id
      : "",
    paymentNameAddressStreet: data.BStreetAddress.value,
    paymentNameCountryId: data.BCountry.value.id,
    paymentSwiftFeeType: data.DFee.value.id,
    paymentBankTransitNumber: data.BTransitNumber.value,
    paymentBankInstitutionNumber: data.BInstitutionNumber.value,
    paymentBankAba: data.BAba.value,
  };

  return payload;
};
