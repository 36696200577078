import * as Styled from "../../../../shared/firstStep/beneficiary/style";
import { BICInput } from "../../../../shared/firstStep/components/bicInput";
import { HouseNumberInput } from "../../../../shared/firstStep/components/houseNumberInput";

import { InputUk } from "../../../types";

const ThirdRowBeneficiaryUk = ({ input }: { input: InputUk }) => {
  return (
    <Styled.Row>
      <Styled.Item flex={2}>
        <HouseNumberInput input={input} />
      </Styled.Item>

      <Styled.Item flex={2}>
        <BICInput input={input} />
      </Styled.Item>
    </Styled.Row>
  );
};

export default ThirdRowBeneficiaryUk;
