import styled from "styled-components";
import { Text, TextBold } from "../../commonStyle";

export const Wrapper = styled.div`
  width: 100%;
  box-shadow: 0 0 20px rgb(27, 41, 68, 10%);
  background-color: #f4f5f6;
`;

export const WrapperRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

export const Title = styled(TextBold)`
  font-size: 18px;
  font-weight: 500;
  margin: 16px 0;
  text-transform: uppercase;
`;
