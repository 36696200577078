import { Button } from "antd";
import { FC, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonToAntd from "../../../../component/Button";
import TypeToTextMap from "../../../../component/Global/TypeToTextMap";
import { IDataInit } from "../../../../services/cards/types";
import { IResponseCardInit } from "../../../../services/cards/types/response";
import useSizePage from "../../../../utils/hookSizePage";
import CTSCActivateCard from "./activeModal";
import * as Styled from "./style";
import { changeOpenType } from "../../../../store/user";
import { useDispatch } from "react-redux";

interface IProps {
  balance: IDataInit["balance"];
  buttons: IDataInit["buttons"];
  data: IDataInit["data"];
  refetchData: () => void;
  activateTemp: IResponseCardInit["templates"]["activate_card_modal"];
}

const CTSecondColumn: FC<IProps> = ({
  balance = [],
  buttons = [],
  activateTemp,
  data,
  refetchData,
}) => {
  const idComponent = useId();

  const [isOpen, setOpen] = useState(false);
  const [cardId, setCardId] = useState("");
  const { width } = useSizePage();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClick = (type: string, data: any) => {
    const { cardId, accountId } = data;
    switch (type) {
      case "card-activate": {
        setCardId(cardId || "");
        setOpen(true);
        return;
      }

      case "card-statement": {
        navigate("/statements/view", { state: { accountId } });
        dispatch(changeOpenType({ openType: "statements" }));
        return;
      }

      case "card-top-up": {
        navigate("/cards/top-up", { state: { cardId: accountId } });
        return;
      }

      default: {
        return;
      }
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Styled.Wrapper>
      <div>
        {balance.map((data, index) => (
          <TypeToTextMap
            data={data}
            position={width > 768 ? "end" : "start"}
            key={idComponent + ".CTSecondColumn1." + index}
          />
        ))}
      </div>

      {isOpen && (
        <CTSCActivateCard
          cardId={cardId}
          data={data}
          onCancel={onCancel}
          template={activateTemp}
          refetchData={refetchData}
        />
      )}

      <Styled.WrapperButton>
        {buttons.map((item, index) => {
          return (
            <ButtonToAntd
              {...item}
              key={idComponent + ".CTSecondColumn2." + index}
              onClick={({ action, data }) => onClick(action, data)}
            />
          );
        })}
      </Styled.WrapperButton>
    </Styled.Wrapper>
  );
};

export default CTSecondColumn;
