import styled from "styled-components";

export const Wrapper = styled.div`
  width: 24px;
  padding-right: 8px;
  & span svg {
    width: 16px;
    height: auto;
  }
`;

export const WrapperDetails = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const WrapperDuplicate = styled.span`
  display: none;
  &:hover {
    color: rgb(91, 223, 167);
  }
`;
