import { useEffect, useState } from "react";
import AmountComponent from "../../../../../../component/Global/Amount";
import validateNumber from "../../../../../../utils/validate/validateNumber";
import { InputEft } from "../../../types";
import * as Styled from "./style";

const getIsAbleUrgent = () => {
  const date = new Date();
  const hours = date.getUTCHours();
  const day = date.getUTCDay();

  if (hours < 8 || hours > 12) return false;
  if (day === 0 || day === 6) return false;
  return true;
};

const PaymentDetaisDateFirst = ({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    setErrorDataFS,
    formActions: { prepareMoney },
  },
}: {
  input: InputEft;
}) => {
  const { DAmount } = dataFS;
  const { amountSymbol, balance } = dataCommon;

  const [isAbleUrgent, setIsAbleUrgent] = useState(false);

  useEffect(() => {
    setIsAbleUrgent(getIsAbleUrgent());
    const interval = setInterval(() => {
      setIsAbleUrgent(getIsAbleUrgent());
    }, 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!isAbleUrgent) {
      onChangeDataFS("DIsUrgent", false);
    }
  }, [isAbleUrgent]);

  const onChangeAmount = (value: string) => {
    onChangeDataFS("DAmount", value);
    setErrorDataFS("DAmount", "");
  };

  return (
    <Styled.WrapperRow>
      <AmountComponent
        {...DAmount}
        onChangeValue={onChangeAmount}
        title="Amount:"
        prefix={amountSymbol + " "}
        placeholder={amountSymbol + " 0.00"}
        charLimit={16}
        id="DAmount"
        fieldActions={prepareMoney("DAmount", (amount) =>
          validateNumber(
            amount,
            { min: 0.01, max: balance },
            { symbol: amountSymbol }
          )
        )}
      />
    </Styled.WrapperRow>
  );
};

export default PaymentDetaisDateFirst;
