import { Button } from "antd";
import { NavigateFunction } from "react-router-dom";
import { ITable } from "../../../component/table/types";
import { ITableDraftDashboard } from "../../../services/pagesAPI/types";

const getUrlDraft = (action: string) => {
  switch (action) {
    case "crypto-to-crypto":
    case "crypto-to-fiat": {
      return `/crypto/${action}`;
    }
    default: {
      return `/new-payment/${action}`;
    }
  }
};

export const getDataTableDraft = (
  dataRow: ITableDraftDashboard[],
  width: number,
  navigate: NavigateFunction
): ITable[] => [
  {
    title: "Created",
    width: "150px",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.date),
  },
  {
    title: "Account",
    width: "calc((100% - 450px)/2)",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.account),
  },
  {
    title: "Beneficiary",
    width: "calc((100% - 450px)/2)",
    positionText: "left",
    dataColumn: dataRow.map(({ item }) => item.counterparty),
  },
  {
    title: "Amount",
    width: "150px",
    positionText: width > 1024 ? "right" : "left",
    dataColumn: dataRow.map(({ item }) => item.amount),
  },
  {
    title: "Action",
    width: "150px",
    positionText: width > 1024 ? "right" : "left",
    dataColumn: dataRow.map(({ paymentTransferType, paymentId }) => [
      [
        <Button
          shape="round"
          size="large"
          onClick={() =>
            navigate(getUrlDraft(paymentTransferType), {
              state: { paymentId, mode: "edit" },
            })
          }
        >
          Use
        </Button>,
      ],
    ]),
  },
];
