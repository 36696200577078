import { FC, useId } from "react";
import WrapperTitleError from "../../../../../component/Wrappers/wrapperTitleError";
import { IItemTableAPI } from "../../../../../services/types/table";
import { TypeToText } from "../../../../../utils/typeToText";
import * as Styled from "./style";

interface IProps {
  data: IItemTableAPI[];
}

const CryptoBenef: FC<IProps> = ({ data }) => {
  const idComponent = useId();
  return (
    <WrapperTitleError title="Beneficiary's Account:">
      <Styled.Wrapper>
        {data.map((item, index) => {
          return (
            <TypeToText
              {...item}
              key={idComponent + index}
              styleData={{ fontSize: 14 }}
            />
          );
        })}
      </Styled.Wrapper>
    </WrapperTitleError>
  );
};

export default CryptoBenef;
