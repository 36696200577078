import { Alert, AlertProps } from "antd";
import { useContext } from "react";
import { CardTopUpContext } from "../../..";
import AmountComponent from "../../../../../component/Global/Amount";
import InputComponent from "../../../../../component/Global/Input";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import useSizePage from "../../../../../utils/hookSizePage";
import validateNumber from "../../../../../utils/validate/validateNumber";
import validateString from "../../../../../utils/validate/validateString";
import * as Styled from "./style";

const infoAmount: AlertProps[] = [
  {
    message:
      "Please kindly note it might take up to one business day for the funds to be available on the card.",
  },
];

const CTUFSTopUpDetails = () => {
  const valueContext = useContext(CardTopUpContext);

  const { dataFS, dataCommon, onChangeDataFS, setErrorDataFS } = valueContext;
  const { amount, description } = dataFS;
  const { currencySymbol } = dataCommon;

  const { width } = useSizePage();

  const onBlurAmount = () => {
    const value = parseFloat(amount.value) || 0;
    const error = validateNumber(
      value,
      {
        min: 0.01,
      },
      {
        symbol: currencySymbol ,
      }
    );

    setErrorDataFS("amount", error);
  };

  const onBlurDescription = () => {
    const error = validateString(description.value, {
      isReq: true,
      min: 3,
      max: 50,
    });

    setErrorDataFS("description", error);
  };

  return (
    <WrapperWhiteBG title="Top-Up Details">
      <Styled.Wrapper>
        <AmountComponent
          {...amount}
          title="Amount:"
          onChangeValue={(value) => onChangeDataFS("amount", value)}
          width={width > 1024 ? "calc(100% / 3)" : "100%"}
          prefix={currencySymbol + " "}
          onBlur={onBlurAmount}
          placeholder={currencySymbol + " 0.00"}
        />
        <InputComponent
          {...description}
          title="Payment description:"
          onChangeValue={(value) => onChangeDataFS("description", value)}
          width={width > 1024 ? "calc(100% / 1.5)" : "100%"}
          onBlur={onBlurDescription}
          maxLength={50}
        />
      </Styled.Wrapper>
      <Styled.WrapperAlert>
        <Alert message="Please kindly note it might take up to one business day for the funds to be available on the card." />
      </Styled.WrapperAlert>
    </WrapperWhiteBG>
  );
};

export default CTUFSTopUpDetails;
