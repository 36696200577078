import { useEffect, useState } from "react";
import { IItemPicker } from "../../component/Global/Picker";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import UserAPI from "../../services/userAPI";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import { initData } from "./data";
import EPInvite from "./invite";
import EPSetting from "./setting";
import { IData } from "./types";

const EditProfile = () => {
  const infoInitQuery = useQueryAPI(UserAPI.edit.init, {
    changeIsLoading: true,
  });

  const [dataSetting, setDataSetting] = useState(initData);
  const [initialized, setInitialized] = useState(false);
  const [dataTimezone, setDataTimezone] = useState<IItemPicker[]>([]);
  const [isAbleInvite, setIsAbleInvite] = useState(false);

  const onChangeDataSetting = <
    T extends keyof IData,
    D extends IData[T]["value"]
  >(
    key: T,
    value: D
  ) => {
    setDataSetting((prev) => ({ ...prev, [key]: { error: "", value } }));
  };

  const setErrorDataSetting = (key: keyof IData, error: string) => {
    setDataSetting((prev) => ({ ...prev, [key]: { ...prev[key], error } }));
  };

  useEffect(() => {
    const response = infoInitQuery.data;
    if (response) {
      const { timezones, profile } = response.data;
      const {
        userTimezone,
        userLogin,
        userName,
        userMultiFactorType,
        inviteUserFlag,
      } = profile;

      const timeData = timezones.dropdown_options.data.map(
        ({ text, value }) => ({
          text,
          id: value,
        })
      );

      const timezoneValue = timeData.find(
        ({ text }) => text === userTimezone
      ) || { text: "", id: "" };

      onChangeDataSetting("timezone", timezoneValue);
      onChangeDataSetting("name", userName);
      onChangeDataSetting("login", userLogin);
      onChangeDataSetting("authType", userMultiFactorType);

      setIsAbleInvite(inviteUserFlag);

      setDataTimezone(timeData);

      setInitialized(true);
    }
  }, [infoInitQuery]);

  return (
    <WrapperBodyTitle>
      {isAbleInvite && <EPInvite />}
      <EPSetting
        onChangeData={onChangeDataSetting}
        setErrorData={setErrorDataSetting}
        dataTimezone={dataTimezone}
        data={dataSetting}
        initialized={initialized}
      />
    </WrapperBodyTitle>
  );
};

export default EditProfile;
