import styled from "styled-components";

interface ITextarea {
  width?: string;
  height?: string;
  fontSize?: number;
  isError: boolean;
}

export const Textarea = styled.textarea<ITextarea>`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "128px")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize + "px" : "16px")};
  padding: 10px;
  border-radius: 4px;
  resize: none;
  outline: none;
  background-color: transparent;
  border: 1px solid;
  border-color: ${({ isError }) =>
    isError ? "#eb5757 !important" : "rgb(217, 217, 217)"};

  &:hover {
    border-color: ${({ isError }) => (isError ? "#eb5757" : "#6c64f4")};
  }
  &:focus {
    border-color: ${({ isError }) => (isError ? "#eb5757" : "#6c64f4")};
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7a78a9;
    border-radius: 5px;
  }
`;
