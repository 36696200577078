import { IPayloadValidateSepa } from "../../../../../services/paymentAPI/types/payload";
import { IDataFS } from "../../types";

export const getPayload = (
  data: IDataFS,
  isRequireAddress: boolean,
  accountId: string,
  isCanada: boolean,
  isUs: boolean,
  useCompanyName: boolean
): IPayloadValidateSepa["payload"] => {
  let payload: IPayloadValidateSepa["payload"] = {
    accountId,
    paymentAccountNumberTo: data.BAccountNumber.value,
    paymentAmount: parseFloat(data.DAmount.value),
    paymentBankBic: data.BBic.value,
    paymentDescription: data.DPaymentDescription.value,
    paymentDescriptionCompanyName: useCompanyName && data.DIsCompanyName.value,
    paymentIsSameCompanyTransfer: data.DIsInterTransfer.value,
    paymentName: data.BCompany.value,
    paymentPriorityType: data.DIsUrgent.value,
  };

  if (isRequireAddress) {
    payload = {
      ...payload,
      paymentNameAddressCity: data.BCity.value,
      paymentNameAddressNumber: data.BHouseNumber.value,
      paymentNameAddressPostalCode: data.BPostalCode.value,
      paymentNameAddressState: isUs
        ? data.BState.value.id
        : isCanada
        ? data.BCaState.value.id
        : "",
      paymentNameAddressStreet: data.BStreetAddress.value,
      paymentNameCountryId: data.BCountry.value.id,
    };
  }

  return payload;
};
