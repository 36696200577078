import { useContext, useId } from "react";
import { ContextInvoiceUse } from "../../..";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { TypeToText } from "../../../../../utils/typeToText";
import * as Styled from "./style";

const IBSSFooter = () => {
  const idComponent = useId();
  const { footer } = useContext(ContextInvoiceUse).dataSS;

  return (
    <Styled.Wrapper>
      <div>
        <WrapperWhiteBG title="Invoice Summary">
          <table>
            <tbody>
              {footer.map((line, index) => {
                const key = idComponent + "." + index;

                return (
                  <tr key={"key" + index}>
                    {line.map((item, index) => (
                      <td key={key + "." + index}>
                        <TypeToText
                          {...item}
                          styleData={
                            index === 0
                              ? { textAling: "end" }
                              : { padding: "0 0 0 16px" }
                          }
                        />
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </WrapperWhiteBG>
      </div>
    </Styled.Wrapper>
  );
};

export default IBSSFooter;
