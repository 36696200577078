import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 0 10px;
  flex-wrap: wrap;
`;

interface IItem {
  flex: number;
}

export const Item = styled.div<IItem>`
  flex: ${({ flex }) => flex};
  @media (max-width: 1024px) {
    flex: unset;
    width: 100%;
  }
`;
