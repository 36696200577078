import { FC } from "react";
import { useAppSelector } from "../../../utils/useState";
import PPButtonAuth from "./Auth";
import PPButtonDelete from "./Delete";
import * as Styled from "./style";

interface IProps {
  transfers: string[];
  onRealod: () => void;
}

const PPButtons: FC<IProps> = (props) => {
  const { userType } = useAppSelector((store) => store.userReducer);

  return (
    <Styled.WrapperButton>
      {userType !== "simple-user" && <PPButtonAuth {...props} />}
      <PPButtonDelete {...props} />
    </Styled.WrapperButton>
  );
};

export default PPButtons;
