import { ISecondStepDataAPI } from "../../../services/paymentAPI/types";
import { getValueError } from "../../payments/data";
import { IDataCommon, IDataFS, IDataTS } from "./types";

export const initDataCommon: IDataCommon = {
  accountId: "",
  currencySymbol: "",
  paymentId: "",
  balance: 0,
  currencyCode: "",
  confirmMessage: "",
  checksum: "",
  typeAuth: "google-authenticator",
};

export const initDataFS: IDataFS = {
  amount: getValueError("0.00000000"),
  company: getValueError(""),
  description: getValueError(""),
  wallet: getValueError(""),
  files: getValueError([]),
  isConfirm: getValueError(false),
};

export const initDataSS: ISecondStepDataAPI[] = [];

export const initDataTS: IDataTS = {
  amount: "",
  name: "",
};
