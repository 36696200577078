import { IFileTransferDocument } from "../../../component/Global/transferDocument/types";
import { IFilesPayment } from "../../../services/paymentAPI/types";
import { IPayloadValidateCrypto } from "../../../services/paymentAPI/types/payload";
import { getValueError } from "../data";
import { IDataCommon, IDataFS } from "./types";

export const initDataFS: IDataFS = {
  DIsConfirm: getValueError(false),
  DAmount: getValueError("0.00"),
  DWallet: getValueError(""),
  DReason: getValueError(""),

  Tfiles: getValueError([]),
};

export const initDataCommon: IDataCommon = {
  amountSymbol: "",
  balance: 0,
  dataHeader: [],
  paymentID: "",
  checksum: "",
  typeAuth: "google-authenticator",
  accountId: "",
  currencyCode: "",
  isRequireFiles: true,
  isDisableSaveAsDraft: false,
  rate: 0,
  nameCrypto: "",
  companyName: "",
  confirmMessage: "",
  isLoadingFiles: false,
  isAbleAuth: false,
  type: "fiat-to-crypto",
};

const resToData: {
  [key in keyof IPayloadValidateCrypto["payload"]]: keyof IDataFS;
} = {
  accountId: "DReason",
  paymentAmount: "DAmount",
  paymentDescription: "DReason",
  paymentReference: "DWallet",
  flagWalletConsent: "DIsConfirm",
  cryptoCurrencyCode: "DReason",
};

type TValue = string | boolean | IFileTransferDocument[];

export const getDataFromForm = (
  form: Partial<IPayloadValidateCrypto["payload"]>,
  files: IFilesPayment[]
) => {
  let responseData: Partial<{
    [key in keyof IDataFS]: TValue;
  }> = {};

  const keysForm = Object.keys(form) as (keyof typeof form)[];

  keysForm.forEach((key) => {
    const value = form[key];
    const keyData = resToData[key];

    if (!keyData) return;

    if (typeof value === "number") {
      responseData[keyData] = value.toFixed(2);
      return;
    }

    responseData[keyData] = value;
  });

  responseData["Tfiles"] = files.map(({ documentId, name, url }) => ({
    id: documentId,
    name,
    url,
    isLoading: false,
  }));

  return Object.entries(responseData) as [keyof IDataFS, TValue][];
};
