import { FC, useId } from "react";
import * as Styled from "./style";
import WrapperWhiteBG from "../../../component/Wrappers/wrapperWhiteBG";
import { IDataInit } from "../../../services/accountDetails/types";
import { TypeToText } from "../../../utils/typeToText";
import { Empty } from "antd";

interface IProps {
  dataTable: IDataInit[];
}

const AccountDetailsTable: FC<IProps> = ({ dataTable }) => {
  const idComponent = useId();

  return (
    <WrapperWhiteBG isBorder>
      {!dataTable.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {dataTable.map(({ title, data }, index) => {
        const key = idComponent + index;
        return (
          <Styled.WrapperItem key={key}>
            <Styled.Title>{title}</Styled.Title>
            {data.map((item, index) => (
              <TypeToText
                {...item}
                key={key + index}
                styleData={{ fontSize: 15 }}
              />
            ))}
          </Styled.WrapperItem>
        );
      })}
    </WrapperWhiteBG>
  );
};

export default AccountDetailsTable;
