import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CreditCardOutlined,
  PaperClipOutlined,
  UsbOutlined,
} from "@ant-design/icons";
import { FC, useMemo } from "react";

interface IProps {
  icon: string;
  type: string;
  color?: string;
}

const getColor = (type: string, color?: string) => {
  if (color) return color;
  switch (type) {
    case "alert": {
      return "#eb5757";
    }
    case "success": {
      return "#54b9c8";
    }
    case "link": {
      return "#6c64f4";
    }
    default: {
      return undefined;
    }
  }
};

const TextIcon: FC<IProps> = ({ icon, type, color }) => {
  switch (icon) {
    case "ClockCircleOutlined": {
      return <ClockCircleOutlined style={{ color: getColor(type, color) }} />;
    }
    case "PaperClipOutlined": {
      return <PaperClipOutlined style={{ color: getColor(type, color) }} />;
    }
    case "CreditCardOutlined": {
      return <CreditCardOutlined style={{ color: getColor(type, color) }} />;
    }
    case "CheckCircleOutlined": {
      return <CheckCircleOutlined style={{ color: getColor(type, color) }} />;
    }
    default: {
      return <UsbOutlined style={{ color: getColor(type, color) }} />;
    }
  }
};

export default TextIcon;
