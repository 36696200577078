import instance from "..";
import {
  IPayloadFileDelete,
  IPayloadFileResult,
  IPayloadFileUpload,
} from "./types/payload";
import {
  IResponseFileDelete,
  IResponseFileUpload,
  IResponseInit,
} from "./types/response";

export const ActionReqiredAPI = {
  init: () => instance.get<IResponseInit>("/app/init-requested"),
  resolve: ({ requestId }: IPayloadFileResult) =>
    instance.post<IResponseFileDelete>(
      "/statements/requested/documents/resolve/" + requestId
    ),
  file: {
    upload: ({ requestId, formData }: IPayloadFileUpload) =>
      instance.post<IResponseFileUpload>(
        "/statements/requested/documents/store/" + requestId,
        formData
      ),
    delete: ({ id }: IPayloadFileDelete) =>
      instance.get<IResponseFileDelete>(
        "/statements/requested/documents/delete/" + id
      ),
  },
};
