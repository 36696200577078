import { Button } from "antd";
import { useContext, useEffect, useMemo } from "react";
import { ContextRecurringPayment } from "../../..";
import { RecurringPaymentAPI } from "../../../../../services/recurringPayment";
import { IPRecurringValidate } from "../../../../../services/recurringPayment/types/payload";
import { IErrorPayment } from "../../../../../services/types";
import { scrollToError } from "../../../../../utils/scrollToError";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { getError, getErrorFromResponse, getPayload } from "./data";
import * as Styled from "./style";

const RPBFSFooter = () => {
  const { dataCommon, dataFS, onErrorData, setDataSS, setStep } = useContext(
    ContextRecurringPayment
  );

  const { recurringPaymentId, usId, caId, type, isOpenCorresp } = dataCommon;

  const [queryValidate, infoValidateQuery] = useMutationAPI(
    RecurringPaymentAPI.validate
  );

  const { isCA, isUS } = useMemo(
    () => ({
      isUS: dataFS.country.value.id === usId,
      isCA: dataFS.country.value.id === caId,
    }),
    [dataFS.country, usId, caId]
  );

  useEffect(() => {
    const response = infoValidateQuery.data?.data;
    const error = infoValidateQuery.error?.response?.data;
    if (response) {
      setDataSS((prev) => ({ ...prev, ...response }));
      setStep(2);
    }
    if (error) {
      const errors = getErrorFromResponse(
        error.errors as IErrorPayment<keyof IPRecurringValidate["payload"]>[]
      );
      errors.forEach(({ key, error }) => onErrorData(key || "city", error));
    }
  }, [infoValidateQuery]);

  const validate = () => {
    const errors = getError(
      dataFS,
      type as any,
      isUS,
      isCA,
      isOpenCorresp
    ).filter(({ error }) => !!error.length);

    errors.forEach(({ key, error }) => onErrorData(key, error));

    scrollToError(errors);

    return !errors.length;
  };

  const onClickContinue = () => {
    if (!validate()) return;
    queryValidate({
      id: recurringPaymentId,
      payload: getPayload(dataFS),
    });
  };

  return (
    <Styled.Wrapper>
      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onClickContinue}
        loading={infoValidateQuery.isLoading}
      >
        Continue
      </Button>
    </Styled.Wrapper>
  );
};

export default RPBFSFooter;
