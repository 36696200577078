import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IItemPicker } from "../../component/Global/Picker";
import Table from "../../component/table";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import { InvoiceTLAPI } from "../../services/invoiceTemplateList";
import { IDataInitInvoiceTL } from "../../services/invoiceTemplateList/types";
import { changeOpenType } from "../../store/user";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import useSizePage from "../../utils/hookSizePage";
import ILButtonsTable from "./buttonsTable";
import { getDataPicker, getDataTable, getDetailsTable, initData } from "./data";
import * as Styled from "./style";
import CompanyAtBank from "../../component/companyAtBank";

const initPicker: IItemPicker = {
  text: "",
  id: "",
};

const InvoiceTemplateListComponent = () => {
  const [queryInit, infoInitQuery] = useMutationAPI(InvoiceTLAPI.init, {
    changeIsLoading: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useSizePage();

  const [data, setData] = useState(initData);
  const [idSeletRows, setIdSeletRow] = useState<string[]>([]);

  const [valueCompany, setValueCompany] = useState<IItemPicker>({
    ...initPicker,
  });

  const [valueAccount, setValueAccount] = useState<IItemPicker>({
    ...initPicker,
  });

  useEffect(() => {
    dispatch(changeOpenType({ openType: "invoices-templates" }));
    queryInit({});
  }, []);

  useEffect(() => {
    const response = infoInitQuery.data?.data;

    if (response) {
      const dataAccount = getDataPicker(response.filters.accounts_filter);
      const dataCompany = getDataPicker(response.filters.companies_filter);

      const valueAccount = dataAccount.find(
        ({ id }) => id === "all-accounts"
      ) || { ...initPicker };

      const valueCompany = dataCompany.find(
        ({ id }) => id === "all-companies"
      ) || { ...initPicker };

      setData({
        table: response.data,
        dataAccount,
        dataCompany,
      });

      setValueAccount(valueAccount);
      setValueCompany(valueCompany);
    }
  }, [infoInitQuery]);

  const onSelectDropDownTable = ({ text, id }: IItemPicker) => {
    switch (text) {
      case "clone": {
        navigate("/invoices-templates/builder", { state: { invoiceId: id } });
        break;
      }
      case "use": {
        navigate("/invoices-templates/use", { state: { invoiceId: id } });
        break;
      }
      case "edit": {
        navigate("/invoices-templates/builder", {
          state: { invoiceId: id, isEdit: true },
        });
        break;
      }
    }
  };

  const getIsValidItem = ({ accountId, companyId }: IDataInitInvoiceTL) => {
    const isAccount =
      accountId === valueAccount.id || valueAccount.id === "all-accounts";
    const isCompany =
      companyId === valueCompany.id || valueCompany.id === "all-companies";
    return { isAccount, isCompany };
  };

  const tableFiltred = useMemo(() => {
    const response = data.table.filter((item) => {
      const { isCompany, isAccount } = getIsValidItem(item);
      return isCompany && isAccount;
    });

    setIdSeletRow(
      idSeletRows.filter(
        (id) =>
          !!response.find(({ invoiceTemplateId }) => invoiceTemplateId === id)
      )
    );

    return response;
  }, [valueAccount, valueCompany, data]);

  return (
    // Translate_l10n
    <WrapperBodyTitle
      {...infoInitQuery.data?.data.page?.header}
      onClickButton={() => navigate("/invoices-templates/builder")}
    >
      <Styled.WrapperCompanyAt>
        <CompanyAtBank
          pickers={[
            {
              data: data.dataCompany.filter(({ id }) => {
                const item = data.table
                  .filter((itemF) => getIsValidItem(itemF).isAccount)
                  .find(({ companyId }) => companyId === id);
                if (id === "all-companies") return true;
                return !!item;
              }),
              value: valueCompany,
              setValue: setValueCompany,
              disabledError: true,
            },
            {
              data: data.dataAccount.filter(({ id }) => {
                const item = data.table
                  .filter((itemF) => getIsValidItem(itemF).isCompany)
                  .find(({ accountId }) => accountId === id);
                if (id === "all-accounts") return true;
                return !!item;
              }),
              value: valueAccount,
              setValue: setValueAccount,
              disabledError: true,
            },
          ]}
        />
      </Styled.WrapperCompanyAt>

      <Table
        table={getDataTable(tableFiltred, onSelectDropDownTable, width)}
        idRows={tableFiltred.map(({ invoiceTemplateId }) => invoiceTemplateId)}
        details={getDetailsTable(tableFiltred)}
        idSeletRows={idSeletRows}
        onSelectRow={setIdSeletRow}
      />
      {!!tableFiltred.length && (
        <ILButtonsTable
          invoices={idSeletRows}
          reloadTable={() => queryInit({})}
        />
      )}
    </WrapperBodyTitle>
  );
};

export default InvoiceTemplateListComponent;
