import { IPRecurringValidate } from "../../../../../services/recurringPayment/types/payload";
import { IErrorPayment, IResponseError } from "../../../../../services/types";
import validateNumber from "../../../../../utils/validate/validateNumber";
import validateString from "../../../../../utils/validate/validateString";
import { IDataFS } from "../../../types";
import { TDataKeyValidation } from "./types";

export const getPayload = (data: IDataFS): IPRecurringValidate["payload"] => ({
  accountId: data.account.value.id,
  beneficiaryName: data.company.value,
  beneficiaryCountryId: data.country.value.id,
  beneficiaryState: data.state.value.id,
  beneficiaryCity: data.city.value,
  beneficiaryPostalCode: data.postalCode.value,
  beneficiaryStreetAddress: data.street.value,
  beneficiaryHouseNumber: data.house.value,
  recurringPaymentDescription: data.description.value,
  recurringPaymentAmount: data.amount.value,
  recurringPaymentName: data.templateName.value,
  recurringPaymentScheduleType: data.schedulePicker.value.id,
  recurringPaymentScheduleData: data.scheduleOptions.value,
  beneficiaryAccountNumber: data.iban.value,
  beneficiaryBankBic: data.bic.value,
  beneficiaryBankAba: data.aba.value,
  beneficiaryBankInstitutionNumber: data.institutionNumber.value,
  beneficiaryBankSortCode: data.sortCode.value,
  beneficiaryBankTransitNumber: data.transitNumber.value,
  beneficiaryCorrespondentAccount: data.correspAccount.value,
  beneficiaryCorrespondentBankBic: data.correspBIC.value,
  beneficiaryCorrespondentBankName: data.correspBankName.value,
});

const getErrorKey = (key: keyof IDataFS, error = "") => ({ key, error });

const dataKeyValidMain: (keyof IDataFS)[] = [
  "account",
  "amount",
  "description",
  "scheduleOptions",
  "schedulePicker",
  "templateName",
  "company",
];
const dataKeyValidSepa: (keyof IDataFS)[] = [
  ...dataKeyValidMain,
  "country",
  "city",
  "state",
  "postalCode",
  "street",
  "house",
  "bic",
  "iban",
];

const dataKeyValid: TDataKeyValidation = {
  sepa: dataKeyValidSepa,
  swift: [
    ...dataKeyValidSepa,
    "aba",
    "institutionNumber",
    "transitNumber",
    "correspAccount",
    "correspBIC",
    "correspBankName",
  ],
  uk: [...dataKeyValidMain, "iban", "sortCode"],
  fx: dataKeyValidMain,
};

export const getError = (
  data: IDataFS,
  type: "swift" | "uk" | "sepa" | "fx",
  isUS: boolean,
  isCA: boolean,
  isOpenCorresp: boolean
) => {
  const arrayKeys = Object.keys(data) as (keyof IDataFS)[];

  const isValueCorep =
    !!data.correspAccount.value ||
    !!data.correspBIC.value ||
    !!data.correspBankName.value;

  return arrayKeys.map((key) => {
    const { value } = data[key];

    const isReqKeyCorep =
      !isValueCorep &&
      (key === "correspAccount" ||
        key === "correspBIC" ||
        key === "correspBankName");

    if (!dataKeyValid[type].includes(key)) {
      return getErrorKey(key);
    }

    if (value instanceof Array) {
      return getErrorKey(
        key,
        !value.length ? "Please set schedule options" : ""
      );
    }

    if (key === "amount" && typeof value === "string") {
      const error = validateNumber(parseFloat(value), { min: 0.01 });
      return getErrorKey(key, error);
    }

    if (key === "aba" && !isUS) {
      return getErrorKey(key);
    }
    if ((key === "institutionNumber" || key === "transitNumber") && !isCA) {
      return getErrorKey(key);
    }

    if (isReqKeyCorep) {
      return getErrorKey(key);
    }

    if (typeof value === "string") {
      const error = validateString(value, { isReq: true });
      return getErrorKey(key, error);
    }

    if (key === "state" && !isUS) {
      return getErrorKey(key);
    }
    const error = validateString(value.id, { isReq: true });
    return getErrorKey(key, error);
  });
};

const responseToData: {
  [key in keyof IPRecurringValidate["payload"]]: keyof IDataFS;
} = {
  accountId: "account",
  beneficiaryCity: "city",
  beneficiaryCountryId: "country",
  beneficiaryHouseNumber: "house",
  beneficiaryName: "company",
  beneficiaryPostalCode: "postalCode",
  beneficiaryState: "state",
  beneficiaryStreetAddress: "street",
  recurringPaymentAmount: "amount",
  recurringPaymentDescription: "description",
  recurringPaymentScheduleData: "scheduleOptions",
  recurringPaymentScheduleType: "schedulePicker",
  recurringPaymentName: "templateName",
  beneficiaryAccountNumber: "iban",
  beneficiaryBankAba: "aba",
  beneficiaryBankBic: "bic",
  beneficiaryBankInstitutionNumber: "institutionNumber",
  beneficiaryBankSortCode: "sortCode",
  beneficiaryBankTransitNumber: "transitNumber",
  beneficiaryCorrespondentAccount: "correspAccount",
  beneficiaryCorrespondentBankBic: "correspBIC",
  beneficiaryCorrespondentBankName: "correspBankName",
};

export const getErrorFromResponse = (
  errorsProps: IErrorPayment<keyof typeof responseToData>[]
) =>
  errorsProps.map(({ field, message }) => ({
    key: responseToData[field],
    error: message,
  }));
