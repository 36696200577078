import styled from "styled-components";

export const Wrapper = styled.div``;

export const WrapperItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
