import { FC, useContext } from "react";
import { ContextRecurringPayment } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import PickerComponent from "../../../../../../../component/Global/Picker";
import validateString from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

interface IProps {
  isUS: boolean;
}

const RPBFPayToSR: FC<IProps> = ({ isUS }) => {
  const { dataFS, onChangeData, dataCommon, onErrorData } = useContext(
    ContextRecurringPayment
  );
  const { country, city, state } = dataFS;
  const { dataCountry, dataState } = dataCommon;

  const onBlurCity = () => {
    const error = validateString(city.value, { isReq: true, min: 3, max: 50 });
    onErrorData("city", error);
  };

  return (
    <Styled.Wrapper>
      <PickerComponent
        {...country}
        id="country"
        data={dataCountry}
        title="Country:"
        setValue={(value) => onChangeData("country", value)}
        placeholder="Select Country"
      />
      {isUS && (
        <PickerComponent
          {...state}
          id="state"
          data={dataState}
          title="State:"
          setValue={(value) => onChangeData("state", value)}
        />
      )}
      <InputComponent
        {...city}
        id="city"
        title="City:"
        onChangeValue={(value) => onChangeData("city", value)}
        onBlur={onBlurCity}
        maxLength={50}
      />
    </Styled.Wrapper>
  );
};

export default RPBFPayToSR;
