import { Button } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IItemPicker } from "../../component/Global/Picker";
import Table from "../../component/table";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import { RecurringPaymentAPI } from "../../services/recurringPayment";
import { IDataInitInvoiceRP } from "../../services/recurringPayment/types";
import { changeOpenType } from "../../store/user";
import useSizePage from "../../utils/hookSizePage";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import RPButtonsTable from "./buttonsTable";
import {
  getDataFilter,
  getDataTable,
  getDetailsTable,
  initData,
  initPicker,
} from "./data";
import RPLHeader from "./header";
import { IPropsOnSelectTable } from "./types";

const RecurringPaymentComponent = () => {
  const [queryInit, infoInitQuery] = useMutationAPI(RecurringPaymentAPI.init, {
    changeIsLoading: true,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useSizePage();

  const [data, setData] = useState(initData);
  const [idSeletRows, setIdSeletRow] = useState<string[]>([]);
  const [valueCompany, setValueCompany] = useState<IItemPicker>({
    id: "all-companies",
    text: "All Companies",
  });
  const [valueAccount, setValueAccount] = useState<IItemPicker>({
    id: "all-accounts",
    text: "All Accounts",
  });

  useEffect(() => {
    dispatch(changeOpenType({ openType: "recurring-payments" }));
    queryInit({});
  }, []);

  useEffect(() => {
    const response = infoInitQuery.data?.data;

    if (response) {
      const { data, filters } = response;
      setData((prev) => ({
        ...prev,
        table: data,
        dataAccount: getDataFilter(filters.accounts_filter),
        dataCompany: getDataFilter(filters.companies_filter),
      }));
    }
  }, [infoInitQuery]);

  const getIsValidItem = ({ accountId, companyId }: IDataInitInvoiceRP) => {
    const isAccount =
      accountId === valueAccount.id || valueAccount.id === "all-accounts";
    const isCompany =
      companyId === valueCompany.id || valueCompany.id === "all-companies";
    return { isAccount, isCompany };
  };

  const onSelectDropDownTable = ({ action, id, type }: IPropsOnSelectTable) => {
    switch (action) {
      case "clone": {
        navigate("/recurring-payments/builder", {
          state: { recurringId: id, type },
        });
        break;
      }
      case "edit": {
        navigate("/recurring-payments/builder", {
          state: { recurringId: id, isEdit: true, type },
        });
        break;
      }
    }
  };

  const tableFiltred = useMemo(() => {
    const response = data.table.filter((item) => {
      const { isCompany, isAccount } = getIsValidItem(item);
      return isCompany && isAccount;
    });

    setIdSeletRow(
      idSeletRows.filter(
        (id) =>
          !!response.find(({ recurringPaymentId }) => recurringPaymentId === id)
      )
    );

    return response;
  }, [valueAccount, valueCompany, data]);

  const headerPickers = useMemo(
    () => [
      {
        data: data.dataCompany.filter(({ id }) => {
          const item = data.table
            .filter((itemF) => getIsValidItem(itemF).isAccount)
            .find(({ companyId }) => companyId === id);
          if (id === "all-companies") return true;
          return !!item;
        }),
        value: valueCompany,
        setValue: setValueCompany,
        disabledError: true,
      },
      {
        data: data.dataAccount.filter(({ id }) => {
          const item = data.table
            .filter((itemF) => getIsValidItem(itemF).isCompany)
            .find(({ accountId }) => accountId === id);
          if (id === "all-accounts") return true;
          return !!item;
        }),
        value: valueAccount,
        setValue: setValueAccount,
        disabledError: true,
        width: "300px",
      },
    ],
    [data]
  );

  return (
    <WrapperBodyTitle
      {...infoInitQuery.data?.data.page?.header}
      onClickButton={({ action }) => {
        navigate("/recurring-payments/builder", {
          state: { type: action },
        });
      }}
    >
      <RPLHeader pickers={headerPickers} />
      <Table
        table={getDataTable(tableFiltred, onSelectDropDownTable, width)}
        details={getDetailsTable(tableFiltred)}
        onSelectRow={setIdSeletRow}
        idSeletRows={idSeletRows}
        idRows={tableFiltred.map(
          ({ recurringPaymentId }) => recurringPaymentId
        )}
      />
      {!!getDetailsTable(tableFiltred).length && (
        <RPButtonsTable
          invoices={idSeletRows}
          reloadTable={() => {
            setIdSeletRow([]);
            queryInit({});
          }}
        />
      )}
    </WrapperBodyTitle>
  );
};

export default RecurringPaymentComponent;
