import styled from "styled-components";

export const WrapperPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  z-index: 1;
  position: relative;

  & li,
  .ant-pagination-item-link {
    border-radius: 5px;
  }

  & li:hover,
  .ant-pagination-item-link:hover,
  .ant-pagination-item-active {
    border-color: #5536ef;
    color: #5536ef;
  }

  & .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }

  & a:hover,
  .ant-pagination-item-active a {
    color: #5536ef;
  }
`;
