import { useContext } from "react";
import { ContextRecurringPayment } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import PickerComponent from "../../../../../../../component/Global/Picker";
import validateString from "../../../../../../../utils/validate/validateString";
import { IDataFS } from "../../../../../types";

const RPBFSPayToFR = () => {
  const {
    dataFS,
    onChangeData,
    dataCommon,
    setDataFS,
    onErrorData,
    setDataCommon,
  } = useContext(ContextRecurringPayment);
  const { company } = dataFS;
  const { dataCompany,type } = dataCommon;

  const onSelectHint = (value: Partial<IDataFS>) => {
    const { correspAccount, correspBIC, correspBankName } = value;
    if (
      !!correspAccount?.value ||
      !!correspBIC?.value ||
      !!correspBankName?.value
    ) {
      setDataCommon((prev) => ({ ...prev, isOpenCorresp: true }));
    }
    setDataFS((prev) => ({ ...prev, ...value }));
  };

  const onBlur = () => {
    const error = validateString(company.value, {
      isReq: true,
      min: 3,
      max: 100,
    });
    onErrorData("company", error);
  };

  return (
    <div>
      <InputComponent
        {...company}
        id="company"
        title={type==="uk"?"Account holder name":"Company/Name:"}
        onChangeValue={(value) => onChangeData("company", value)}
        hints={{
          data: dataCompany,
          onSelect: onSelectHint,
        }}
        onBlur={onBlur}
        maxLength={100}
        placeholder="Select Beneficiary or enter a new one"
      />
    </div>
  );
};

export default RPBFSPayToFR;
