import instance from "..";
import { IPayloadAuthrize, IPayloadExecute } from "./types/payload";
import { IResponseAuth, IResponseExecute, IResponseInit } from "./types/reponse";

export const PendingPaymnetsAPI = {
  init: () => instance.get<IResponseInit>("/app/init-saved"),
  authorize: ({ transfers }: IPayloadAuthrize) =>
    instance.post<IResponseAuth>("/transfers/saved/authorize", { transfers }),
  execute: ({ transfers, auth_code }: IPayloadExecute) =>
    instance.post<IResponseExecute>("/transfers/saved/execute", {
      transfers,
      auth_code,
    }),
  delete: ({ transfers }: IPayloadAuthrize) =>
    instance.post<IResponseExecute>("/transfers/saved/delete", { transfers }),
};
