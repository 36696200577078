import { MenuOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setIsOpenNav } from "../../../../../store/user";
import HBMenu from "./menu";
import * as Styled from "./style";

const HeaderBurger = () => {
  const dispatch = useDispatch();

  return (
    <Styled.Wrapper>
      <Styled.WrapperIcon>
        <MenuOutlined onClick={() => dispatch(setIsOpenNav(true))} />
      </Styled.WrapperIcon>
      <HBMenu onClose={() => dispatch(setIsOpenNav(false))} />
    </Styled.Wrapper>
  );
};

export default HeaderBurger;
