import { forwardRef } from "react";
import { FC, ReactNode } from "react";
import { IButtonAPI } from "../../../services/types";
import { IItemTableAPI } from "../../../services/types/table";
import ButtonToAntd, { TOnClickButtonToAntd } from "../../Button";
import TypeToTextMap from "../../Global/TypeToTextMap";
import * as Styled from "./style";

interface IProps<DB, AB> {
  title?: string;
  text?: IItemTableAPI[][];
  buttons?: IButtonAPI<DB, AB>[];
  onClickButton?: TOnClickButtonToAntd<DB, AB>;
  children: ReactNode;
  ref?: React.RefObject<HTMLDivElement>;
}

const WrapperBodyTitle = <DB, AB>({
  title,
  children,
  text,
  buttons,
  onClickButton,
}: IProps<DB, AB>) => {
  return (
    <>
      {title && <Styled.Title fontSize={18}>{title}</Styled.Title>}
      {!!text?.length && (
        <Styled.WrapperText>
          <TypeToTextMap data={text} />
        </Styled.WrapperText>
      )}
      {!!buttons?.length && (
        <Styled.WrapperButtons>
          {buttons.map((item, index) => (
            <ButtonToAntd key={index} {...item} onClick={onClickButton} />
          ))}
        </Styled.WrapperButtons>
      )}

      <div>{children}</div>
    </>
  );
};

export default WrapperBodyTitle;
