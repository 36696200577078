import { IDataTableInvoiceAPI } from "../../../../../services/invoiceBuilder/types";
import { IPayloadInvoiceValid } from "../../../../../services/invoiceBuilder/types/payload";
import { IErrorPayment } from "../../../../../services/types";
import { genIndexedKey } from "../../../../../utils/getIndexedKey";
import validateNumber from "../../../../../utils/validate/validateNumber";
import validateString from "../../../../../utils/validate/validateString";
import { ITableInvoiceBuilder } from "../../../../invoiceTemplate/types";
import { IDataCommon, IDataFS } from "../../../types";

const getError = (key: keyof IDataFS, error = "") => {
  return { key, error };
};

export const validateData = (data: IDataFS, isPrefix: boolean) => {
  const keys = Object.keys(data) as (keyof IDataFS)[];

  return keys.map((key) => {
    const { value } = data[key];

    if (
      value instanceof Date ||
      (key === "description" && isPrefix) ||
      (key === "invoiceNumber" && isPrefix)
    ) {
      return getError(key);
    }

    if (key === "description" && typeof value === "string") {
      return getError(key, validateString(value, { isReq: true }));
    }

    if (key === "invoiceNumber" && typeof value === "string") {
      return getError(key, validateString(value, { isReq: true }));
    }

    return getError(key);
  });
};

export const validateCommonData = (
  dataCommon: IDataCommon
): { key: string; error: string }[] => {
  return dataCommon.dataTable.flatMap((row, index) =>
    (["title", "rate", "tax", "qty"] as (keyof ITableInvoiceBuilder)[]).map(
      (key) => {
        if (key === "tax") {
          return {
            key: genIndexedKey(key, index),
            error: validateNumber(parseFloat(row[key].value) || 1, {
              min: 0.01,
              max: 60,
            }),
          };
        }

        if (key === "qty") {
          return {
            key: genIndexedKey(key, index),
            error: validateNumber(parseFloat(row[key].value) || 0, {
              min: 1,
            }),
          };
        }

        if (key === "rate") {
          return {
            key: genIndexedKey(key, index),
            error: validateNumber(parseFloat(row[key].value) || 0, {
              min: 0.01,
            }),
          };
        }

        return {
          key: genIndexedKey(key, index),
          error: validateString(row[key].value, { isReq: true }),
        };
      }
    )
  );
};

export const getPayloadAPI = (
  data: IDataFS,
  dataTable: ITableInvoiceBuilder[],
  prefix: string
): IPayloadInvoiceValid["payload"] => {
  return {
    invoiceDate: data.invoiceDate.value.toISOString(),
    invoiceDueDate: data.dueDate.value ? data.dueDate.value.toISOString() : "",
    invoiceNumber: data.invoiceNumber.value,
    invoiceReference: prefix + " " + data.description.value,
    invoiceData: dataTable.map(({ qty, rate, tax, title }) => ({
      invoiceItemDescription: title.value,
      invoiceItemQty: parseFloat(qty.value),
      invoiceItemRate: parseFloat(rate.value),
      invoiceItemTax: parseFloat(tax.value),
    })),
  };
};

const resToData: {
  [key in keyof IPayloadInvoiceValid["payload"]]: keyof IDataFS;
} = {
  invoiceData: "description",
  invoiceDate: "invoiceDate",
  invoiceDueDate: "dueDate",
  invoiceNumber: "invoiceNumber",
  invoiceReference: "description",
};

const resTableToData: {
  [key in keyof IDataTableInvoiceAPI]: keyof ITableInvoiceBuilder;
} = {
  invoiceItemDescription: "title",
  invoiceItemQty: "qty",
  invoiceItemRate: "rate",
  invoiceItemTax: "tax",
};

const getErrorsResToData = (
  errorsProps: IErrorPayment<keyof IPayloadInvoiceValid["payload"]>[]
) => {
  return errorsProps.map(({ field, message }) => ({
    key: resToData[field],
    error: message,
  }));
};

const getErrorTable = (errorsProps: IErrorPayment[]) => {
  return errorsProps.map(({ field, message }) => {
    const [_, index, resKey] = field.split(".");

    return {
      key: resTableToData[resKey as keyof IDataTableInvoiceAPI],
      index: parseInt(index),
      error: message,
    };
  });
};

export const getErrorResponse = (
  errorsProps: IErrorPayment<keyof IPayloadInvoiceValid["payload"]>[]
) => {
  const resErrorTable: IErrorPayment[] = [];
  const resError = errorsProps.filter((item) => {
    if (!item.field.includes("invoiceData")) return true;
    resErrorTable.push(item);
    return false;
  });

  const errorTable = getErrorTable(resErrorTable);
  const errors = getErrorsResToData(resError);

  return { errorTable, errors };
};
