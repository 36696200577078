import { FC, useContext, useState } from "react";
import { ContextMassPayment } from "../..";
import Table from "../../../../component/table";
import { ITable } from "../../../../component/table/types";
import { IItemTableMassPayment } from "../../../../services/massPaymentAPI/types";
import SSMPHeader from "./header";
import SSMPSummary from "./summary";
import * as Styled from "./style";
import { Button } from "antd";
import useSizePage from "../../../../utils/hookSizePage";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import { MassPaymentAPI } from "../../../../services/massPaymentAPI";

const getDataTable = (dataRow: IItemTableMassPayment[]): ITable[] => [
  {
    title: "Beneficiary",
    width: "70%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.beneficiary.data),
  },
  {
    title: "Amount",
    width: "30%",
    positionText: "left",
    dataColumn: dataRow.map((item) => item.amount.data),
  },
];

const SecondStepMassPayment = () => {
  const { data, setData, setStep, typeQuery } = useContext(ContextMassPayment);

  const [queryDelete] = useMutationAPI(MassPaymentAPI.file.delete);

  const [isAbleRestart, setIsAbleRestart] = useState(true);
  const { width } = useSizePage();

  const { dataTable, files, importId } = data;

  const onClickRestart = () => {
    files.value.forEach(({ id: fileId }) => {
      queryDelete({ typeQuery, importId, fileId });
    });
    setData((prev) => ({ ...prev, files: { value: [], error: "" } }));
    setStep(1);
  };

  return (
    <div>
      <SSMPHeader />
      <Table table={getDataTable(dataTable.map(({ item }) => item))} />
      <SSMPSummary setIsAble={setIsAbleRestart} />
      <Styled.WrapperButton justifyContent="center">
        <Button
          shape="round"
          size="large"
          onClick={onClickRestart}
          disabled={!isAbleRestart}
          block={width < 1024}
        >
          Restart import
        </Button>
      </Styled.WrapperButton>
    </div>
  );
};

export default SecondStepMassPayment;
