import { FC, ReactNode } from "react";
import * as Styled from "./style";

interface IProps {
  children?: ReactNode;
}

const WrapperBG: FC<IProps> = ({ children }) => {
  return (
    <div>
      <Styled.WrapperBG />
      <Styled.WrapperBody id="main_wrapper">{children}</Styled.WrapperBody>
    </div>
  );
};

export default WrapperBG;
