import { FC } from "react";
import InputComponent from "../../../../component/Global/Input";
import { IValueError } from "../../../../component/payment/types";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { TOnChangeData, TSetErrorData } from "../../types";
import * as Styled from "./style";
import { validateNewOldPasswords } from "./utils";

interface IProps {
  oldPassword: IValueError<string>;
  newPassword: IValueError<string>;
  onChangeData: TOnChangeData;
  setErrorData: TSetErrorData;
}

const EPChangePassword: FC<IProps> = ({
  oldPassword,
  newPassword,
  onChangeData,
  setErrorData,
}) => {
  const onBlur = (data: IValueError<string>) => {
    const [oldPasswordError, newPasswordError] = validateNewOldPasswords(
      oldPassword.value,
      newPassword.value
    );

    setErrorData("oldPassword", oldPasswordError);
    setErrorData("newPassword", newPasswordError);
  };

  return (
    <WrapperWhiteBG title="Change password">
      <Styled.Wrapper>
        <InputComponent
          {...oldPassword}
          title="Old Password:"
          onBlur={() => onBlur(oldPassword)}
          onChangeValue={(value) => onChangeData("oldPassword", value)}
          maxLength={100}
          type="password"
        />
        <InputComponent
          {...newPassword}
          title="New Password:"
          onBlur={() => onBlur(newPassword)}
          onChangeValue={(value) => onChangeData("newPassword", value)}
          maxLength={100}
          type="password"
        />
      </Styled.Wrapper>
    </WrapperWhiteBG>
  );
};

export default EPChangePassword;
