import { MutableRefObject, useEffect, useRef, useState } from "react";
import { TDataFS } from "../../../types";
import validateString, {
  IRulesString,
} from "../../../../../utils/validate/validateString";
import { IItemPickerValue } from "../../../../../component/Global/Picker";
import { deepCopyData } from "../../utils";

export type FormActions<DataFS extends TDataFS<any>> = {
  prepareField: TOnPrepareInput<DataFS>;
  prepareStringRulesField: TOnPrepareStringRules<DataFS>;
  preparePicker: TOnPreparePicker<DataFS>;
  preparePickerRequired: TOnPreparePickerRequiredness<DataFS>;
  preparePickerOptional: TOnPreparePickerRequiredness<DataFS>;
  prepareMoney: TOnPrepareMoney<DataFS>;
  validate: () => DataFS | null;
};

/**
 * @param deps: List of fields that will be validated together with current filed
 * @param revalidateDeps: List of fields that will be validated together with current filed if they were previously validated. If field was untouched, it won't be revalidated
 */
export type PrepareOptions<T extends TDataFS<any>> = Partial<{
  deps: (keyof T)[];
  revalidateDeps: (keyof T)[];
}>;

type TOnPrepareStringRules<T extends TDataFS<any>> = <K extends keyof T>(
  key: K,
  rulesString: IRulesString,
  prepareOptions?: PrepareOptions<T>
) => PreparedFieldActions;

export type TOnPrepareInput<T extends TDataFS<any>> = <K extends keyof T>(
  key: K,
  validate: (value: string, allValues: T) => string,
  prepareOptions?: PrepareOptions<T>
) => PreparedFieldActions;

export type TOnPreparePicker<T extends TDataFS<any>> = <K extends keyof T>(
  key: K,
  validate: (value: IItemPickerValue, allValues: T) => string,
  prepareOptions?: PrepareOptions<T>
) => PreparedFieldActions;

export type TOnPreparePickerRequiredness<T extends TDataFS<any>> = <
  K extends keyof T
>(
  key: K,
  prepareOptions?: PrepareOptions<T>
) => PreparedFieldActions;

export type TOnPrepareMoney<T extends TDataFS<any>> = <K extends keyof T>(
  key: K,
  validate: (value: number, allValues: T) => string,
  prepareOptions?: PrepareOptions<T>
) => PreparedFieldActions;

export type UseFormConfig<DataFS extends TDataFS<any>> = {
  fieldGroups?: (keyof DataFS)[][];
};

export const useForm = <DataFS extends TDataFS<any>>(
  newData: DataFS,
  updateData: (data: DataFS) => void,
  validateAll: (data: DataFS) => void,
  config?: UseFormConfig<DataFS>
): FormActions<DataFS> => {
  const data = useRef(newData);
  useEffect(() => {
    data.current = newData;
  }, [newData]);
  const validators = useRef<
    Map<
      keyof DataFS,
      (value: DataFS[keyof DataFS]["value"], allValues: DataFS) => string
    >
  >(new Map());

  const validatedFields = useRef<Set<keyof DataFS>>(new Set());

  const addValidator = (
    field: keyof DataFS,
    validator: (
      value: DataFS[keyof DataFS]["value"],
      allValues: DataFS
    ) => string
  ) => {
    validators.current = new Map(validators.current).set(field, validator);
  };
  const removeValidator = (field: keyof DataFS) => {
    const newValidatorsMap = new Map(validators.current);
    newValidatorsMap.delete(field);
    validators.current = newValidatorsMap;
  };

  const prepareField = (
    key: keyof DataFS,
    validate: (
      value: DataFS[keyof DataFS]["value"],
      allValues: DataFS
    ) => string,
    prepareOptions?: PrepareOptions<DataFS>
  ) => ({
    registerValidator: () => {
      addValidator(key, validate);
    },
    unregisterValidator: () => {
      removeValidator(key);
    },
    onBlur: () => {
      const keyGroup =
        (config?.fieldGroups ?? []).find((group) => group.includes(key)) ?? [];
      const fieldsToRevalidate = intersect(
        validatedFields.current,
        prepareOptions?.revalidateDeps ?? []
      );
      const keysToProcess = Array.from(
        new Set([
          ...(prepareOptions?.deps ?? []),
          ...keyGroup,
          ...fieldsToRevalidate,
          key,
        ])
      );
      validateKeys(
        keysToProcess,
        data.current,
        updateData,
        validators.current,
        validateAll,
        validatedFields
      );
    },
  });

  return {
    prepareField: (key, validate, prepareOptions) => {
      return prepareField(key, validate, prepareOptions);
    },
    prepareStringRulesField: (key, stringRules, prepareOptions) => {
      return prepareField(
        key,
        (value) => validateString(value, stringRules),
        prepareOptions
      );
    },
    preparePicker: (key, validate, prepareOptions) => {
      return prepareField(
        key,
        (value, allValues) => validate(value as IItemPickerValue, allValues),
        prepareOptions
      );
    },
    preparePickerRequired: (key, prepareOptions) => {
      return prepareField(
        key,
        (value) =>
          validateString((value as IItemPickerValue).id, { isReq: true }),
        prepareOptions
      );
    },
    preparePickerOptional: (key, prepareOptions) => {
      return prepareField(key, (value) => "", prepareOptions);
    },
    prepareMoney: (key, validate, prepareOptions) => {
      return prepareField(
        key,
        (value, allValues) => validate(parseFloat(value), allValues),
        prepareOptions
      );
    },
    validate: () => {
      const keysToProcess = [...Object.keys(data.current)];
      return validateKeys(
        keysToProcess,
        data.current,
        updateData,
        validators.current,
        validateAll,
        validatedFields
      );
    },
  };
};

const validateKeys = <DataFS extends TDataFS<any>>(
  keysToProcess: (keyof DataFS)[],
  data: DataFS,
  updateData: (data: DataFS) => void,
  validators: Map<
    keyof DataFS,
    (value: DataFS[keyof DataFS]["value"], allValues: DataFS) => string
  >,
  validateAll: (data: DataFS) => void,
  validatedFields: MutableRefObject<Set<keyof DataFS>>
): DataFS | null => {
  const validateAllCopyWithoutErrors = deepCopyData(data, {
    errorOverride: "",
  });
  validateAll(validateAllCopyWithoutErrors);

  const dataCopy = { ...data };
  let hasError = false;
  for (const key of keysToProcess) {
    let error: string = "";
    const validator = validators.get(key)!;
    if (validator) {
      error = validator(dataCopy[key].value, dataCopy);
    }
    if (error.length == 0) {
      error = validateAllCopyWithoutErrors[key].error;
    }
    if (error.length != 0) {
      hasError = true;
    }
    dataCopy[key] = { ...dataCopy[key], error };
  }
  updateData(dataCopy);
  keysToProcess.forEach((key) => validatedFields.current.add(key));
  if (hasError) {
    return dataCopy;
  } else {
    return null;
  }
};

const intersect = <T,>(a1: Set<T>, a2: T[]): T[] => {
  return a2.filter((it) => a1.has(it));
};

export type PreparedFieldActions = {
  registerValidator: () => void;
  unregisterValidator: () => void;
  onBlur: () => void;
};

export const usePreparedFiled = (actions: PreparedFieldActions) => {
  useEffect(() => {
    actions.registerValidator();
    return () => {
      actions.unregisterValidator();
    };
  }, [actions]);
};

export const formActionsDefault: FormActions<any> = {
  prepareField: () => {
    throw Error("form actions not initialized");
  },
  prepareStringRulesField: () => {
    throw Error("form actions not initialized");
  },
  preparePicker: () => {
    throw Error("form actions not initialized");
  },
  preparePickerRequired: () => {
    throw Error("form actions not initialized");
  },
  preparePickerOptional: () => {
    throw Error("form actions not initialized");
  },
  prepareMoney: () => {
    throw Error("form actions not initialized");
  },
  validate: () => {
    throw Error("form actions not initialized");
  },
};
