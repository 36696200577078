import { InputInternal } from "../../../types";
import { PaymentDescriptionInput } from "../../../../shared/firstStep/components/paymentDescriptionInput";

const PaymentDetaisDateSecond = ({ input }: { input: InputInternal }) => {
  return (
    <PaymentDescriptionInput
      input={input}
      useCompanyNameInDescriptionControl={false}
    />
  );
};

export default PaymentDetaisDateSecond;
