import "./App.css";
import WrapperBG from "./component/Wrappers/wrapperBackground";
import Page403 from "./pages/page403";
import RoutesApp from "./routes";
import { useAppSelector } from "./utils/useState";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useEffect } from "react";
import useSizePage from "./utils/hookSizePage";
import { useDispatch } from "react-redux";
import {
  setIsAbleQuestionBack,
  setIsOpenNav,
  setIsQuestionBack,
} from "./store/user";
import { useNavigate } from "react-router-dom";
import NBModalLeave from "./component/Wrappers/wrapperHeaderNav/navBar/modalLeave";
import NotifModalAPI from "./utils/notifModal";
import { MainANTDWrapper } from "./assets/theme/MainAntdWrapper";

function App() {
  const { isAuthError, authErrorData, isQuestionBack, notificationModal } =
    useAppSelector((state) => state.userReducer);

  const { width } = useSizePage();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (width > 1600 && !!width) {
      dispatch(setIsOpenNav(true));
    }
  }, [width]);

  const closeModalBack = () => {
    dispatch(setIsQuestionBack(false));
    dispatch(setIsAbleQuestionBack(true));
  };

  const onClickModalLeave = () => {
    if (history.state.isQuestion) navigate(-1);
    navigate(-1);
    dispatch(setIsAbleQuestionBack(false));
    dispatch(setIsQuestionBack(false));
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
    >
      {/* <Table columns={[{ key: "1", name: <TypeToTextMap data={[]} /> }]} /> */}
      <WrapperBG>
        {isAuthError ? <Page403 {...authErrorData} /> : <RoutesApp />}
      </WrapperBG>
      {isQuestionBack && (
        <NBModalLeave
          onClickLeave={onClickModalLeave}
          onClickStay={closeModalBack}
        />
      )}
      {notificationModal && <NotifModalAPI {...notificationModal} />}
    </GoogleReCaptchaProvider>
  );
}

export default App;
