import { IPayloadValidate } from "../../../../services/cryptoFiatPayIn/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { DataCommonCryptoFiat, IDataContext } from "../../types";

export const createPayload = (
  data: IDataContext,
  dataCommon: DataCommonCryptoFiat
): IPayloadValidate => {
  const isUs = dataCommon.usID == data.BCountry.value.id;
  const isCanada = dataCommon.canadaID == data.BCountry.value.id;

  return {
    requestId: dataCommon.requestId,
    payload: {
      accountId: data.benefAccount.value.id,
      requestFiatAmount: parseFloat(data.amount.value),
      requestFiatCurrencyId: dataCommon.currencyId,
      requestDescription: data.paymentDescription.value,
      requestCounterpartyName: data.BCompany.value,
      requestCounterpartyCountryId: data.BCountry.value.id,
      requestCounterpartyAddressCity: data.BCity.value,
      requestCounterpartyAddressState: isUs
        ? data.BState.value.id
        : isCanada
        ? data.BCaState.value.id
        : "",
      requestCounterpartyAddressPostalCode: data.BPostalCode.value,
      requestCounterpartyAddressStreet: data.BStreetAddress.value,
      requestCounterpartyAddressNumber: data.BHouseNumber.value,
    },
  };
};

const keyResponse: {
  [key in keyof Required<IPayloadValidate["payload"]>]:
    | keyof IDataContext
    | null;
} = {
  accountId: "benefAccount",
  requestFiatAmount: "amount",
  requestFiatCurrencyId: null,
  requestDescription: "paymentDescription",
  requestCounterpartyName: "BCompany",
  requestCounterpartyCountryId: "BCountry",
  requestCounterpartyAddressCity: "BCity",
  requestCounterpartyAddressState: null,
  requestCounterpartyAddressPostalCode: "BPostalCode",
  requestCounterpartyAddressStreet: "BStreetAddress",
  requestCounterpartyAddressNumber: "BHouseNumber",
};

export const getErrorResponse = (
  errorProps: IErrorPayment<keyof IPayloadValidate["payload"]>[],
  isUS: boolean,
  isCanada: boolean
): { key: keyof IDataContext; error: string }[] =>
  errorProps.flatMap(({ field, message }) => {
    if (field == "requestCounterpartyAddressState") {
      if (isUS) {
        return {
          key: "BState",
          error: message,
        };
      } else if (isCanada) {
        return {
          key: "BCaState",
          error: message,
        };
      }
    }
    const key = keyResponse[field];
    if (key == null) {
      return [];
    }
    return {
      key: key,
      error: message,
    };
  });
