import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { createRef, FC, useEffect, useState } from "react";
import WrapperTitleError from "../../Wrappers/wrapperTitleError";
import { onChangeInputFile } from "./data";
import ItemFileTransfer from "./item";
import { IFileTransferDocument, TOnUploadFile } from "./types";

interface IProps {
  isAble?: boolean;
  onDelete: (id: string) => Promise<boolean>;
  onUpload: TOnUploadFile;
  setValue: (value: IFileTransferDocument[]) => void;
  setError: (value: string) => void;
  value: IFileTransferDocument[];
  error: string;
  multiple?: boolean;
  accept?: string;
  title?: string;
  isRequired?: boolean;
  setIsLoading?: (value: boolean) => void;
  id?: string;
}

const TransferDocument: FC<IProps> = ({
  isAble = true,
  onDelete,
  setValue,
  setError,
  value,
  onUpload,
  error,
  multiple,
  accept,
  title = "Please provide supporting document(s) for this payment:",
  isRequired = false,
  setIsLoading = () => {},
  id,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const refInput = createRef<HTMLInputElement>();
  const [isUpload, setIsUpload] = useState(false);

  useEffect(() => {
    setIsLoading(isUpload);
  }, [isUpload]);

  const onClickUpload = () => {
    if (!refInput.current) return;
    refInput.current.click();
  };

  const onChangeInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setIsUpload(true);

    const dataFiles = Array.from(e.target.files);

    const dataInfo = {
      value,
      setValue,
      setError,
    };

    await onChangeInputFile(dataFiles, dataInfo, onUpload);

    setIsUpload(false);
    e.target.value = "";
  };

  const deleteFile = async (id: string) => {
    if (isDeleting) return;
    setIsDeleting(true);
    setIsUpload(true);

    const data = value.map((item) => {
      if (item.id !== id) return item;
      return {
        ...item,
        isLoading: true,
      };
    });
    setValue(data);

    const result = await onDelete(id);

    if (!result) return;

    const filtredValue = value.filter((item) => id !== item.id);

    setValue(filtredValue);
    if (!filtredValue.length && isRequired) {
      setError("Document(s) required");
    }

    setIsUpload(false);
    setIsDeleting(false);
  };

  return (
    <WrapperTitleError title={title} error={error} id={id}>
      <input
        ref={refInput}
        type="file"
        style={{ display: "none" }}
        onChange={onChangeInput}
        accept={accept}
        multiple={multiple}
      />
      <Button
        shape="round"
        size="large"
        onClick={onClickUpload}
        disabled={!isAble || isUpload}
        icon={<UploadOutlined />}
      >
        Browse files
      </Button>
      <ItemFileTransfer files={value} onDelete={deleteFile} />
    </WrapperTitleError>
  );
};

export default TransferDocument;
