import instance from "..";
import {
  IPayloadCryptoValidateAddress,
  IPayloadDA,
  IPayloadDeleteWallet,
  IPayloadExecute,
  IPayloadFileDelete,
  IPayloadFileUpload,
  IPayloadGA,
  IPayloadInitStatement,
  IPayloadRate,
  IPayloadSellDraft,
  IPayloadSellInit,
  IPayloadSellValidate,
  IPayloadSendAuthorize,
  IPayloadSendDraft,
  IPayloadSendExecute,
  IPayloadSendInit,
  IPayloadSendValidate,
} from "./types/payload";
import {
  IResponseExecute,
  IResponseGenAddress,
  IResponseInit,
  IResponseRate,
  IResponseRates,
  IResponseSellInit,
  IResponseSellValidate,
  IResponseSendAuthorize,
  IResponseSendExecute,
  IResponseSendFileDelete,
  IResponseSendFileUpload,
  IResponseSendInit,
  IResponseSendValidate,
  IResponseStateInit,
  IRGenAddressAgreement,
} from "./types/response";

export const CryptoAPI = {
  init: () => instance.get<IResponseInit>("app/init-crypto-payments-list"),
  getRates: () => instance.get<IResponseRates>("crypto/rates"),
  generateAdressAgreement: ({ accountId }: IPayloadGA) =>
    instance.get<IRGenAddressAgreement>(
      "/crypto/generate-address-agreement/" + accountId
    ),
  generateAdress: ({ accountId }: IPayloadGA) =>
    instance.get<IResponseGenAddress>("/crypto/generate-address/" + accountId),
  deleteAdress: ({ coinbaseAddressId }: IPayloadDA) =>
    instance.get(`crypto/delete-address/${coinbaseAddressId}`),
  deleteWallet: ({ walletId }: IPayloadDeleteWallet) =>
    instance.get("/crypto/delete-address/" + walletId),
  validateAddress: ({ currencyCode, wallet }: IPayloadCryptoValidateAddress) =>
    instance.get(`crypto/validate-address/${currencyCode}/${wallet}`),
  discard: (paymentId: string) =>
    instance.get(`transfers/discard/${paymentId}`),
  sell: {
    init: ({ id, isEdit }: IPayloadSellInit) =>
      instance.get<IResponseSellInit>(
        `transfers/${isEdit ? "edit" : "init"}/crypto-to-fiat/${id}`
      ),
    validate: ({ paymentId, payload }: IPayloadSellValidate) =>
      instance.post<IResponseSellValidate>(
        "/transfers/validate/crypto-to-fiat/" + paymentId,
        payload
      ),
    execute: ({ checksum, paymentId, auth_code }: IPayloadExecute) =>
      instance.post<IResponseExecute>(
        `/transfers/execute/crypto-to-fiat/${paymentId}/${checksum}`,
        { auth_code }
      ),
    rate: (payload: IPayloadRate) =>
      instance.post<IResponseRate>("transfers/fx-rate", payload),
    draft: ({ paymentId, payload }: IPayloadSellDraft) =>
      instance.post(
        "transfers/save-draft/crypto-to-fiat/" + paymentId,
        payload
      ),
  },
  send: {
    init: ({ id, isEdit }: IPayloadSendInit) =>
      instance.get<IResponseSendInit>(
        `transfers/${isEdit ? "edit" : "init"}/crypto-to-crypto/${id}`
      ),
    validate: ({ payload, paymentId }: IPayloadSendValidate) =>
      instance.post<IResponseSendValidate>(
        "/transfers/validate/crypto-to-crypto/" + paymentId,
        payload
      ),
    authorize: ({ paymentId }: IPayloadSendAuthorize) =>
      instance.get<IResponseSendAuthorize>("/transfers/authorize/" + paymentId),
    execute: ({ paymentId, checksum, auth_code }: IPayloadSendExecute) =>
      instance.post<IResponseSendExecute>(
        `/transfers/execute/${paymentId}/${checksum}`,
        {
          auth_code,
        }
      ),
    files: {
      upload: ({ paymentId, formData }: IPayloadFileUpload) =>
        instance.post<IResponseSendFileUpload>(
          "/transfers/documents/upload/" + paymentId,
          formData
        ),
      delete: ({ id }: IPayloadFileDelete) =>
        instance.get<IResponseSendFileDelete>(
          "/transfers/documents/delete/" + id
        ),
    },
    draft: ({ paymentId, payload }: IPayloadSendDraft) =>
      instance.post(
        "transfers/save-draft/crypto-to-crypto/" + paymentId,
        payload
      ),
  },
};
