import AmountComponent from "../../../../../component/Global/Amount";
import validateNumber from "../../../../../utils/validate/validateNumber";
import PaymentDetailsCommon from "../../../shared/firstStep/payment";
import { InputInternal } from "../../types";
import PaymentDetaisDateSecond from "./secondPart";
import * as Styled from "./style";

const PaymentDetaisInternal = ({ input }: { input: InputInternal }) => {
  const {
    dataFS,
    dataCommon,
    onChangeDataFS,
    formActions: { prepareMoney },
  } = input;
  const { DAmount } = dataFS;
  const { amountSymbol, balance } = dataCommon;

  return (
    <PaymentDetailsCommon>
      <Styled.AmountWrapper>
        <AmountComponent
          {...DAmount}
          onChangeValue={(value) => onChangeDataFS("DAmount", value)}
          title="Amount:"
          placeholder={amountSymbol + " 0.00"}
          prefix={amountSymbol + " "}
          charLimit={16}
          id="DAmount"
          fieldActions={prepareMoney("DAmount", (value) =>
            validateNumber(
              value,
              { min: 0.01, max: balance },
              {
                symbol: amountSymbol,
              }
            )
          )}
        />
      </Styled.AmountWrapper>

      <PaymentDetaisDateSecond input={input} />
    </PaymentDetailsCommon>
  );
};

export default PaymentDetaisInternal;
