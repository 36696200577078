import styled from "styled-components";
import { Text } from "../../../commonStyle";

export const WrapperItem = styled.div`
  padding: 15px 0;
`;

export const Title = styled(Text)`
  margin-bottom: 15px;
  font-size: 18px;
`;
