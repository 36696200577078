import { IPayloadValidateEft } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import { IDataFS } from "../types";

const keyResponse: {
  [key in keyof Required<IPayloadValidateEft["payload"]>]: keyof IDataFS;
} = {
  paymentAccountNumberTo: "paymentAccountNumberTo",
  paymentAmount: "DAmount",
  paymentDescription: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
  paymentIsSameCompanyTransfer: "DIsInterTransfer",
  paymentName: "BCompany",
  paymentPriorityType: "DIsUrgent",
  paymentEftFeeType: "DFee",
  paymentBankInstitutionNumber: "paymentBankInstitutionNumber",
  paymentBankTransitNumber: "paymentBankTransitNumber",
  accountId: "DAmount",
  paymentEmail: "paymentEmail",
  paymentFirstName: "paymentFirstName",
  paymentLastName: "paymentLastName",
};

export const getErrorResponse = (
  errorProps: IErrorPayment<keyof IPayloadValidateEft["payload"]>[]
) =>
  errorProps.map(({ field, message }) => ({
    key: keyResponse[field],
    error: message,
  }));
