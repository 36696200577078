import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import { COMPANY_AND_CORRESPONDENT_BANK_NAME_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

const rulesCorrespBankName: IRulesString = { min: 3, max: 50 };

type IDataFS = {
  BCorrespBankName: IValueError<string>;
};

interface Input {
  dataFS: IDataFS;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const CorrespondentBankNameInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: Input;
}) => {
  return (
    <InputComponent
      {...dataFS.BCorrespBankName}
      onChangeValue={(value) => onChangeDataFS("BCorrespBankName", value)}
      title="Correspondent Bank Name:"
      fieldActions={prepareStringRulesField(
        "BCorrespBankName",
        rulesCorrespBankName
      )}
      maxLength={rulesCorrespBankName.max}
      regex={COMPANY_AND_CORRESPONDENT_BANK_NAME_REGEX}
      onLostFocusActions={[
        LostFocusActions.REPLACE_WHITESPACES,
        LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
      ]}
      id="BCorrespBankName"
    />
  );
};
