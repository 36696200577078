import styled from "styled-components";

interface IWrapperTable {
  mobileView: number;
}

export const WrapperTable = styled.div<IWrapperTable>`
  box-shadow: 0 0 20px #1b29441c;
  & [data-row-key^="footer"] {
    background-color: #fafafa;
  }
  & > div:last-child {
    display: none;
  }
  @media (max-width: ${({ mobileView }) => `${mobileView}px`}) {
    & > div:first-child {
      display: none;
    }
    & > div:last-child {
      display: block;
    }
    box-shadow: unset;
  }
`;
