import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 10px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 0;
  }
`;

export const FirstColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface IColumnRow {
  justifyContent: "start" | "end";
}

export const ColumnRow = styled.div<IColumnRow>`
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: start;
  }
`;

export const SecondColumn = styled(FirstColumn)`
  @media (min-width: 769px) {
    align-items: flex-end;
  }
`;

export const WrapperAmount = styled.div`
  margin-top: 10px;
`;
