import { useContext } from "react";
import { ContextRecurringPayment } from "../../..";
import TransferDocument from "../../../../../component/Global/transferDocument";
import { TOnUploadFile } from "../../../../../component/Global/transferDocument/types";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { RecurringPaymentAPI } from "../../../../../services/recurringPayment";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";

const RPBFSDocuments = () => {
  const { dataFS, onChangeData, onErrorData, dataCommon } = useContext(
    ContextRecurringPayment
  );
  const { files } = dataFS;
  const { recurringPaymentId } = dataCommon;

  const [queryUpload] = useMutationAPI(RecurringPaymentAPI.file.upload);
  const [queryDelete] = useMutationAPI(RecurringPaymentAPI.file.delete);

  const onDelete = async (id: string) => {
    const response = await queryDelete({ id });

    if (response.data) return response.data.data.result;
    return false;
  };

  const onUpload: TOnUploadFile = async (data) => {
    const response = await Promise.all(
      data.map(({ formData }) => queryUpload({ formData, recurringPaymentId }))
    );

    return response.map(({ data: response, error: responseError }) => {
      if (response && response.data.status === "done") {
        const { name, documentId: id, url } = response.data;
        return { name, id, isLoading: false, url };
      }
      if (responseError && responseError.response) {
        return "";
      }
      if (response && response.data.status === "file_exists") {
        return "";
      }
      return "Unknow error";
    });
  };

  return (
    <WrapperWhiteBG title="Payment Documents">
      <TransferDocument
        {...files}
        setValue={(value) => onChangeData("files", value)}
        setError={(error) => onErrorData("files", error)}
        onDelete={onDelete}
        onUpload={onUpload}
      />
    </WrapperWhiteBG>
  );
};

export default RPBFSDocuments;
