import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

interface ICircle {
  color: string;
}

export const Circle = styled.div<ICircle>`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const rotate = keyframes`
  from{
    transform:rotateZ(0deg)
  }
  to{
    transform:rotateZ(360deg)
  }
`;

export const WrapperCircles = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  animation: ${rotate} 1.5s linear infinite;
  & > div:first-child {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }
  & > div:nth-child(2) {
    top: 0;
    left: 100%;
    transform: translate(-50%, -50%);
  }
  & > div:nth-child(3) {
    top: 100%;
    left: 0;
    transform: translate(-50%, -50%);
  }
  & > div:last-child {
    top: 100%;
    left: 100%;
    transform: translate(-50%, -50%);
  }
`;
