import instance from "..";
import { IFileAPI } from "../types";
import { IPSettlementGetData } from "./types/payload";
import { IRSettlementInit } from "./types/response";

export const SettlementAPI = {
  init: () => instance.get<IRSettlementInit>("processing/settlements-list"),
  getData: (payload: IPSettlementGetData) =>
    instance.post<IRSettlementInit>("processing/settlements-list", payload),
  downloadFile: (payload: IPSettlementGetData) =>
    instance.post<IFileAPI>("processing/settlements-list", payload),
};
