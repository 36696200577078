import { FC, useEffect, useMemo, useState } from "react";
import { Text } from "../../commonStyle";
import { IPropsPicker } from "../Global/Picker";
import SelectAt from "./SelectAt";

interface IProps {
  pickers: IPropsPicker[];
}

const CompanyAtBank: FC<IProps> = ({ pickers: pickersProps }) => {
  const pickers = useMemo(
    () => pickersProps.filter((item) => item.data.length > 1),
    [pickersProps]
  );

  if (!pickers.length) {
    return null;
  }
  return (
    <div>
      <SelectAt pickers={pickers} />
      <Text fontSize={18} margin="32px 0 16px ">
        {pickers.map((item) => item.value.text).join(" at ")}
      </Text>
    </div>
  );
};

export default CompanyAtBank;
