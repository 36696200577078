import styled from "styled-components";

export const Line = styled.div`
  display: flex;
  gap: 0 5px;
`;

interface IWrapperButton {
  justifyContent: "center" | "start" | "end";
}

export const WrapperButton = styled.div<IWrapperButton>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-top: 20px;
`;
