import { useContext, useId, useMemo } from "react";
import { TextBold } from "../../../../commonStyle";
import useSizePage from "../../../../utils/hookSizePage";
import { TypeToText } from "../../../../utils/typeToText";
import * as Styled from "./style";
import { ContextSettlement } from "../..";
import TypeToTextMap from "../../../../component/Global/TypeToTextMap";

const SettlementInfoBalences = () => {
  const idComponent = useId();
  const { total } = useContext(ContextSettlement).data;

  const { width } = useSizePage();

  const data = useMemo(
    () => [total.totalVolume, total.totalDeductions, total.totalNetSettlement],
    [total]
  );

  return (
    <Styled.Wrapper>
      {data.map(({ data, item }, index) => {
        return (
          <Styled.WrapperItem key={idComponent + "." + index}>
            {/* <TextBold fontSize={width > 1024 ? 18 : 14}>{title}</TextBold> */}
            <TypeToTextMap
              data={[...item, ...data]}
              styleData={{ fontSize: width > 1024 ? 16 : 14 }}
              position={width > 1024 ? "end" : "start"}
            />
          </Styled.WrapperItem>
        );
      })}
    </Styled.Wrapper>
  );
};

export default SettlementInfoBalences;
