import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL,
  withCredentials: true
});

// Commenting out the request interceptor logic to attach JWT token to headers
// This is omitted because JWT token is transmitted via cookies instead of headers
// instance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers = { ...config.headers, Authorization: "Bearer " + token }
//   }
//   return config;
// });

// Commenting out the response interceptor logic for handling JWT token in response
// This is omitted because the logic for handling JWT token in the response is not required
// when the token is transmitted through cookies
// instance.interceptors.response.use((response) => {
//   if (response.data.token) {
//     localStorage.setItem("token", response.data.token);
//   } else {
//     console.error("None_token " + response.request.responseURL);
//   }
//   return response;
// });

export default instance;
