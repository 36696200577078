import styled from "styled-components";
import { Text } from "../../../../commonStyle";

export const Title = styled(Text)`
  font-size: 20px;
  margin-bottom: 20px;
`;

export const WrapperButton = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1024px) {
    position: sticky;
    bottom: 20px;
  }
`;
