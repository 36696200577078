import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setIsAbleQuestionBack,
  setIsQuestionBack,
  setIsQuestionLeave,
} from "../../store/user";
import { useAppSelector } from "../useState";
import { useNavigate } from "react-router-dom";

interface IProps {
  deps: any[];
  isMenu?: boolean;
  isBrowser?: boolean;
}

export const useQuestionBeforeLeave = ({
  deps,
  isMenu = true,
  isBrowser = true,
}: IProps) => {
  const initalDepsJSONString = useRef<string>();

  const navigate = useNavigate();

  useEffect(() => {
    if (isInit) {
      initalDepsJSONString.current = JSON.stringify(deps);
    }
  }, [deps]);

  const { isAbleQuestionBack } = useAppSelector((store) => store.userReducer);
  const dispatch = useDispatch();
  const isAbleQuestionBackRef = useRef(isAbleQuestionBack);
  useEffect(() => {
    isAbleQuestionBackRef.current = isAbleQuestionBack;
  }, [isAbleQuestionBack]);

  const [isInit, setIsInit] = useState(true);

  useEffect(() => {
    if (isBrowser) {
      dispatch(setIsAbleQuestionBack(true));
      if (history.state.isQuestion) {
        history.back();
      }
    }

    if (isMenu) {
      return () => {
        dispatch(setIsQuestionLeave(false));
      };
    }
  }, []);

  useEffect(() => {}, [...deps]);

  useEffect(() => {
    if (!isInit && isBrowser) {
      if (!history.state.isQuestion) {
        history.pushState(
          { ...history.state, isQuestion: true },
          "questionBeforeLeave"
        );
      }

      window.onbeforeunload = (e) => {
        return "";
      };

      window.onpopstate = (e) => {
        if (isAbleQuestionBackRef.current) {
          dispatch(setIsQuestionBack(true));
          navigate(1);
        }
        dispatch(setIsAbleQuestionBack(false));
        return "";
      };
    }

    if (!isInit && isMenu) {
      if (JSON.stringify(deps) === initalDepsJSONString.current) {
        dispatch(setIsQuestionLeave(false));
      } else {
        dispatch(setIsQuestionLeave(true));
      }
    }

    if (isBrowser) {
      return () => {
        window.onbeforeunload = null;
        window.onpopstate = null;
      };
    }
  }, [...deps]);

  return setIsInit;
};
