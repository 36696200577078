import { Alert } from "antd";
import SwitchComponent from "../../../../../../component/Global/Switch";
import { InputSepa } from "../../../types";
import * as Styled from "./style";
import { PaymentDescriptionInput } from "../../../../shared/firstStep/components/paymentDescriptionInput";

const PaymentDetaisDateSecond = ({ input }: { input: InputSepa }) => {
  const { dataFS, dataCommon, onChangeDataFS } = input;
  const { DIsCompanyName } = dataFS;
  const { useCompanyNameInDescriptionControl: showCompanyName } = dataCommon;

  return (
    <>
      <PaymentDescriptionInput input={input} />

      {showCompanyName ? (
        <>
          <Styled.WrapperCompanyName>
            <SwitchComponent
              checked={DIsCompanyName.value}
              text="Company name in description*"
              onChange={(value) => {
                onChangeDataFS("DIsCompanyName", value);
              }}
              id="DIsCompanyName"
              textChange
            />
          </Styled.WrapperCompanyName>

          <Styled.WrapperAlert>
            <Alert
              message=" *By removing your company name the beneficiary will not have any
        reference to it."
            />
          </Styled.WrapperAlert>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaymentDetaisDateSecond;
