import { useContext } from "react";
import { ContextCardRequest } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import PickerComponent from "../../../../../../../component/Global/Picker";
import validateString from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

const CRRAFirstRow = () => {
  const valueContext = useContext(ContextCardRequest);

  const { dataFS, dataCommon, onChangeDataFS, setErrorDataFS } = valueContext;

  const { RACountry, RACity, RARegion } = dataFS;

  const onBlur = (key: "RACity" | "RARegion") => {
    const error = validateString(dataFS[key].value, {
      isReq: true,
      min: 3,
      max: 50,
    });

    setErrorDataFS(key, error);
  };

  return (
    <Styled.Wrapper>
      <PickerComponent
        {...RACountry}
        title="Country:"
        data={dataCommon.RACountry}
        setValue={(value) => onChangeDataFS("RACountry", value)}
        placeholder="Select Country"
        id="RACountry"
      />
      <InputComponent
        {...RACity}
        title="City:"
        onChangeValue={(value) => onChangeDataFS("RACity", value)}
        onBlur={() => onBlur("RACity")}
        maxLength={50}
        id="RACity"
      />
      <InputComponent
        {...RARegion}
        title="Region/District:"
        onChangeValue={(value) => onChangeDataFS("RARegion", value)}
        onBlur={() => onBlur("RARegion")}
        id="RARegion"
      />
    </Styled.Wrapper>
  );
};

export default CRRAFirstRow;
