import React, { FC, ReactNode } from "react";
import * as Styled from "./style";

interface IInput {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  ref: React.RefObject<HTMLInputElement>;
}

interface IProps {
  isOpen: boolean;
  isButton: boolean;
  inputData: IInput;
  value: string;
  valueComponent?: ReactNode;
  widthIcon: number;
  placeholder: string;
  isPlaceholder: boolean;
}

const ValuePicker: FC<IProps> = ({
  isOpen,
  isButton,
  inputData,
  value,
  widthIcon,
  placeholder,
  isPlaceholder,
  valueComponent,
}) => {
  if (isOpen && !isButton) {
    return <Styled.ValueOpen {...inputData} widthIcon={widthIcon} />;
  }

  const getValue = () => {
    if (isPlaceholder) return placeholder;
    if (valueComponent) return valueComponent;
    return value;
  };

  return (
    <Styled.Value
      isButton={isButton}
      widthIcon={widthIcon}
      isPlaceholder={isPlaceholder}
    >
      {getValue()}
    </Styled.Value>
  );
};

export default ValuePicker;
