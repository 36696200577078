import styled from "styled-components";

export const WrapperColunm = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WrapperRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0 10px;
`;
