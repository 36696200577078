import { createContext, useEffect, useId, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WrapperPayment from "../../component/Wrappers/wrapperPayment";
import { CardAPI } from "../../services/cards";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import {
  getDatafromResponse,
  initContext,
  initDataFS,
  initDataCommon,
  initDataSS,
} from "./data";
import { IContext } from "./types";
import CRFirstStep from "./steps/firstStep";
import CRSecondStep from "./steps/secondStep";
import CRThirdStep from "./steps/thirdStep";
import { getChangeError } from "../../utils/getChangeError";
import { useDispatch } from "react-redux";
import { setIsQuestionLeave } from "../../store/user";

interface ILocation {
  state?: {
    id: string;
  };
}

export const ContextCardRequest = createContext<IContext>(initContext);

const CardRequestComponent = () => {
  const idComponent = useId();
  const idRequest = (useLocation() as ILocation).state?.id || "";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const infoInitQuery = useQueryAPI(CardAPI.request.init, {
    payload: { idRequest },
    changeIsLoading: true,
  });

  const [isInit, setIsInit] = useState(true);
  const [step, setStep] = useState(1);
  const [dataFS, setDataFS] = useState(initDataFS);
  const [dataSS, setDataSS] = useState(initDataSS);
  const [dataCommon, setDataCommon] = useState(initDataCommon);

  const { onChangeData: onChangeDataFS, onErrorData: setErrorDataFS } =
    getChangeError(setDataFS);

  useEffect(() => {
    if (!idRequest.length) {
      navigate("/cards");
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setIsQuestionLeave(false));
    };
  }, []);

  useEffect(() => {
    if (!isInit) {
      dispatch(setIsQuestionLeave(true));
    }
  }, [dataFS]);

  useEffect(() => {
    const response = infoInitQuery.data;

    if (response) {
      const { terms_and_conditions, request, header } = response.data;
      const { cardRecordHolderName, cardRecordId } = request;

      const { accountData, PDCardTypeHints, PDPlaceData } = getDatafromResponse(
        response.data,
        idComponent
      );
      setDataCommon((prev) => ({
        ...prev,
        accountData,
        PDCardTypeHints,
        PDPlaceData,
        PDNationalityData: PDPlaceData,
        PIDCountryData: PDPlaceData,
        RACountry: PDPlaceData,
        cardRecordId,
        dataTerms: terms_and_conditions.data,
        subTitle: header,
      }));
      onChangeDataFS("PDNameCard", cardRecordHolderName.slice(0, 21));

      setTimeout(() => setIsInit(false), 1000);
    }
  }, [infoInitQuery]);

  return (
    <ContextCardRequest.Provider
      value={{
        dataFS,
        setDataFS,
        dataSS,
        setDataSS,
        dataCommon,
        setDataCommon,
        onChangeDataFS,
        setErrorDataFS,
        step,
        setStep,
      }}
    >
      <WrapperPayment
        title="CARD REQUEST"
        step={step}
      >
        <CRFirstStep />
        <CRSecondStep />
        <CRThirdStep />
      </WrapperPayment>
    </ContextCardRequest.Provider>
  );
};

export default CardRequestComponent;
