import instance from "..";
import {
  IPayloadInitBuilder,
  IPayloadInvoiceEmailSend,
  IPayloadInvoiceLogoDelete,
  IPayloadInvoiceLogoUpload,
  IPayloadInvoiceSave,
  IPayloadInvoiceValid,
} from "./types/payload";
import {
  IResInitBuilder,
  IResInvoiceLogoUpload,
  IResInvoiceSave,
  IResInvoiceValid,
} from "./types/response";

export const InvoiceTBAPI = {
  init: ({ invoiceID, mode }: IPayloadInitBuilder) =>
    instance.get<IResInitBuilder>(
      `/invoice-templates/${mode}${invoiceID ? `/${invoiceID}` : ""}`
    ),
  validate: ({ invoiceID, payload }: IPayloadInvoiceValid) =>
    instance.post<IResInvoiceValid>(
      "/invoice-templates/validate/" + invoiceID,
      payload
    ),
  buttonSS: {
    save: ({ invoiceID, payload }: IPayloadInvoiceSave) =>
      instance.post<IResInvoiceSave>(
        "/invoice-templates/store/" + invoiceID,
        payload
      ),
  },
  logo: {
    upload: ({ invoiceID, formData }: IPayloadInvoiceLogoUpload) =>
      instance.post<IResInvoiceLogoUpload>(
        "/invoice-templates/logo/upload/" + invoiceID,
        formData
      ),
    delete: ({ invoiceID }: IPayloadInvoiceLogoDelete) =>
      instance.post("/invoice-templates/logo/delete/" + invoiceID),
  },
};
