import { CryptoFiatInput } from "../../../../types";
import FirstRowCounterpartyCryptoFiat from "./firstRow";
import SecondRowCounterpartyCryptoFiat from "./secondRow";

const CounterpartyRowsCryptoFiat = ({ input }: { input: CryptoFiatInput }) => {
  const { dataFS, dataCommon } = input;
  const countryId = dataFS.BCountry.value.id;
  const { canadaID, usID } = dataCommon;

  return (
    <>
      <FirstRowCounterpartyCryptoFiat
        input={input}
        isUS={countryId === usID}
        isCanada={countryId === canadaID}
      />
      <SecondRowCounterpartyCryptoFiat
        input={input}
        isUS={countryId === usID}
        isCanada={countryId === canadaID}
      />
    </>
  );
};

export default CounterpartyRowsCryptoFiat;
