import { Route } from "react-router-dom";
import BulkComponent from "../../pages/payments/bulk";
import CryptoComponent from "../../pages/payments/crypto";
import FxComponent from "../../pages/payments/fx";
import InternalComponent from "../../pages/payments/internal";
import SepaComponent from "../../pages/payments/sepa";
import SwiftComponent from "../../pages/payments/swift";
import UkComponent from "../../pages/payments/uk";
import EftComponent from "../../pages/payments/eft";
import NewPayment from "../../pages/statementsNewPayment";
import PaymentAPI from "../../services/paymentAPI";

const NewPaymentRoutes = () => {
  return (
    <Route path="/new-payment">
      <Route
        path=""
        element={
          <NewPayment openType="new-payment" promise={PaymentAPI.getInitList} />
        }
      />
      <Route path="sepa" element={<SepaComponent />} />
      <Route path="swift" element={<SwiftComponent />} />
      <Route path="eft" element={<EftComponent />} />
      <Route path="fx" element={<FxComponent />} />
      <Route path="bulk-fx" element={<BulkComponent />} />
      <Route path="internal" element={<InternalComponent />} />
      <Route path="uk" element={<UkComponent />} />
      <Route path="crypto" element={<CryptoComponent />} />
    </Route>
  );
};

export default NewPaymentRoutes;
