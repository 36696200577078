import { FC, useContext } from "react";
import { ContextCardRequest } from "../../..";
import PickerComponent from "../../../../../component/Global/Picker";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";

const CRAccount = () => {
  const valueContext = useContext(ContextCardRequest);

  const { dataFS, dataCommon, onChangeDataFS } = valueContext;

  return (
    <WrapperWhiteBG title="Account to charge setup and shipping fee">
      <PickerComponent
        {...dataFS.account}
        data={dataCommon.accountData}
        setValue={(value) => onChangeDataFS("account", value)}
        placeholder="Select Account"
        info={[
          {
            message: "Issue and Shipping Fees will be charged to this Account",
          },
        ]}
        disabledError={!dataFS.account.error.length}
        id="account"
      />
    </WrapperWhiteBG>
  );
};

export default CRAccount;
