import { Steps } from "antd";
import { createRef, FC, useEffect, useId } from "react";

interface IProps {
  viewSteps: string[];
  step: number;
}

const { Step } = Steps;

const ViewSteps: FC<IProps> = ({ viewSteps, step }) => {
  const idComponent = useId();

  return (
    <Steps
      current={step - 1}
      style={{ display: "flex ", flexDirection: "row" }}
      items={viewSteps.map((title) => ({ title }))}
    />
  );
};

export default ViewSteps;
