import { Button } from "antd";
import { useState } from "react";
import { TextBold } from "../../../commonStyle";
import InputComponent from "../../../component/Global/Input";
import WrapperWhiteBG from "../../../component/Wrappers/wrapperWhiteBG";
import UserAPI from "../../../services/userAPI";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import validateString from "../../../utils/validate/validateString";
import { initData } from "./data";
import * as Styled from "./style";
import { IData } from "./types";

const EPInvite = () => {
  const [queryInvite, infoInviteQuery] = useMutationAPI(UserAPI.edit.invite);

  const [data, setData] = useState(initData);

  const onChangeData = (key: keyof IData, value: string) => {
    setData((prev) => ({ ...prev, [key]: { error: "", value } }));
  };

  const setError = (key: keyof IData, error: string) => {
    setData((prev) => ({ ...prev, [key]: { ...prev[key], error } }));
  };

  const onBlurName = () => {
    const error = validateString(data.name.value, { isReq: true, min: 2 });

    setError("name", error);
    return !error.length;
  };

  const onBlurMobile = () => {
    const error = validateString(data.mobile.value, { isReq: true, min: 2 });

    setError("mobile", error);
    return !error.length;
  };

  const onBlurEmail = () => {
    const error = validateString(data.email.value, {
      isReq: true,
      isEmail: true,
      max: 255,
    });

    setError("email", error);
    return !error.length;
  };

  const validateData = () => {
    const isErrorEmail = onBlurEmail();
    const isErrorName = onBlurName();
    const isErrorMobile = onBlurMobile();

    return isErrorEmail && isErrorName && isErrorMobile;
  };

  const onClickRequest = () => {
    if (validateData()) {
      const { email, name, mobile } = data;
      queryInvite({
        userEmail: email.value,
        userName: name.value,
        userPhone: `+${mobile.value}`,
      });
    }
  };

  return (
    <Styled.Wrapper>
      <WrapperWhiteBG title="User details" titleOut="INVITE USER">
        <Styled.WrapperInputRow>
          <InputComponent
            {...data.name}
            title="Full Name:"
            onChangeValue={(value) => onChangeData("name", value)}
            onBlur={onBlurName}
          />
          <InputComponent
            {...data.mobile}
            title="Mobile number to receive SMS:"
            onChangeValue={(value) => onChangeData("mobile", value)}
            onBlur={onBlurMobile}
            prefix="+"
            type="number"
          />
        </Styled.WrapperInputRow>
        <Styled.WrapperInputRow>
          <InputComponent
            {...data.email}
            title="Email:"
            onChangeValue={(value) => onChangeData("email", value)}
            onBlur={onBlurEmail}
            maxLength={255}
          />
        </Styled.WrapperInputRow>
      </WrapperWhiteBG>
      <Styled.WrapperButton>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={onClickRequest}
        >
          SEND REQUEST
        </Button>
      </Styled.WrapperButton>
    </Styled.Wrapper>
  );
};

export default EPInvite;
