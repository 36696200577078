const idSeparator = "_";

export const genIndexedKey = (key: string, index: number): string =>
  `${key}${idSeparator}${index}`;

export const splitKey = (id: string): { key: string; index: number } => {
  const [key, index] = id.split(idSeparator);
  return {
    key: key,
    index: parseInt(index),
  };
};
