import PickerComponent, {
  IItemPicker,
  IItemPickerValue,
} from "../../../../../component/Global/Picker";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

type IDataFS = {
  BState: IValueError<IItemPickerValue>;
};

type IDataCommon = {
  dataState: IItemPicker[];
};

interface Input {
  dataFS: IDataFS;
  dataCommon: IDataCommon;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const StatePicker = ({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    formActions: { preparePickerRequired },
  },
}: {
  input: Input;
}) => {
  return (
    <PickerComponent
      {...dataFS.BState}
      data={dataCommon.dataState}
      setValue={(value) => onChangeDataFS("BState", value)}
      title="State:"
      placeholder="Select State"
      id="BState"
      fieldActions={preparePickerRequired("BState")}
    />
  );
};
