import { FC } from "react";
import * as Styled from "../../../../shared/firstStep/beneficiary/style";
import { InputFx } from "../../../types";
import {
  AccountNumberInput,
  IBANAccountNumberInput,
} from "../../../../shared/firstStep/components/accountNumberInput";
import { BICInput } from "../../../../shared/firstStep/components/bicInput";
import { TransitNumberInput } from "../../../../shared/firstStep/components/transitNumberInput";
import { InstitutionNumberInput } from "../../../../shared/firstStep/components/institutionNumberInput";
import { ABAInput } from "../../../../shared/firstStep/components/abaInput";
import { SortCodeInput } from "../../../../shared/firstStep/components/sortCodeInput";

interface IProps {
  input: InputFx;
  isUS: boolean;
  isCanada: boolean;
  isUk: boolean;
}

const ThirdRowBeneficiarySwift: FC<IProps> = ({
  input,
  isCanada,
  isUS,
  isUk,
}) => {
  return (
    <Styled.Row>
      <Styled.Item flex={2}>
        <IBANAccountNumberInput input={input} isReq={false} />
      </Styled.Item>

      <Styled.Item flex={2}>
        <BICInput input={input} isReq={false} />
      </Styled.Item>

      {isUk && (
        <>
          <Styled.Item flex={2}>
            <SortCodeInput input={input} isReq={false} />
          </Styled.Item>

          <Styled.Item flex={2}>
            <AccountNumberInput input={input} isReq={false} />
          </Styled.Item>
        </>
      )}

      {isUS && (
        <Styled.Item flex={2}>
          <ABAInput input={input} />
        </Styled.Item>
      )}

      {isCanada && (
        <>
          <Styled.Item flex={1}>
            <InstitutionNumberInput input={input} />
          </Styled.Item>

          <Styled.Item flex={1}>
            <TransitNumberInput input={input} />
          </Styled.Item>
        </>
      )}
    </Styled.Row>
  );
};

export default ThirdRowBeneficiarySwift;
