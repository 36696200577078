import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "../component/privateRoute";
import ReCaptchaRoute from "../component/reCaptchaRoute";
import AccountDetails from "../pages/accountDetails";
import ActionReqired from "../pages/actionRequired";
import BulkImportComponent from "../pages/bulkImport";
import BulkTransferPayment from "../pages/bulkTransfer";
import ContactComponent from "../pages/contact";
import Dashboard from "../pages/dashboard";
import EditProfile from "../pages/editProfile";
import LoginComponent from "../pages/login";
import LoginCodeComponent from "../pages/loginCode";
import MassPayment from "../pages/massPayment";
import PageNotFound from "../pages/pageNotFound";
import PendingPayments from "../pages/pendingPayments";
import SettlementPage from "../pages/settlement";
import TransactionsPage from "../pages/transactions";
import { CardsRoutes } from "./cards";
import CreditDocRoutes from "./creditDoc";
import CryptoRoutes from "./crypto";
import InvoiceTemplateRoutes from "./invoiceTemplate";
import NewPaymentRoutes from "./newPayment";
import RecurringRoute from "./recurring";
import ResetRoutes from "./reset";
import StatementRoutes from "./statements";
import DownloadsComponent from "../pages/downloads";
import WrapperAuth from "../component/Wrappers/wrapperAuth";
import WrapperHeaderNav from "../component/Wrappers/wrapperHeaderNav";
import CryptoFiatPayInRoutes from "./cryptoFiatPayIn";

const RoutesApp = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />

      <Route element={<WrapperAuth />}>
        <Route element={<ReCaptchaRoute />}>
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/login/code" element={<LoginCodeComponent />} />
          {ResetRoutes()}
        </Route>
      </Route>

      <Route element={<PrivateRoute />}>
        <Route element={<WrapperHeaderNav />}>
          {StatementRoutes()}
          {NewPaymentRoutes()}
          {CryptoRoutes()}
          {CardsRoutes()}
          {CreditDocRoutes()}
          {CryptoFiatPayInRoutes()}
          {InvoiceTemplateRoutes()}
          {RecurringRoute()}
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route
            path="/mass-payments"
            element={<MassPayment typeQuery="mass-payments" />}
          />
          <Route
            path="/izzio-payouts"
            element={<MassPayment typeQuery="izzio-payouts" />}
          />

          <Route path="/bulk-payments" element={<BulkTransferPayment />} />
          <Route
            path="/bulk-payments/import"
            element={<BulkImportComponent />}
          />

          <Route path="/accounts-details" element={<AccountDetails />} />
          <Route path="/saved-payments" element={<PendingPayments />} />
          <Route path="/action-required" element={<ActionReqired />} />
          <Route path="/contact" element={<ContactComponent />} />
          <Route path="/docs" element={<DownloadsComponent />} />
          <Route path="/transactions-report" element={<TransactionsPage />} />
          <Route path="/settlements-report" element={<SettlementPage />} />
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RoutesApp;
