import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import CompanyAtBank from "../../component/companyAtBank";
import { IItemPickerValue } from "../../component/Global/Picker";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import AccountDetailsAPI from "../../services/accountDetails";
import { IDataFilterAPI, IDataInit } from "../../services/accountDetails/types";
import { changeOpenType } from "../../store/user";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import { initBank, initCompany, initDataFilter } from "./data";
import AccountDetailsTable from "./table";

const getFilterFromResponse = (filters?: IDataFilterAPI) => {
  return (
    filters?.dropdown_options?.data?.map(({ text, value }) => ({
      text,
      id: value,
    })) ?? []
  );
};

const AccountDetails = () => {
  const infoInitQuery = useQueryAPI(AccountDetailsAPI.init, {
    changeIsLoading: true,
  });
  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState<IDataInit[]>([]);
  const [company, setCompany] = useState<IItemPickerValue>(initCompany);
  const [bank, setBabk] = useState<IItemPickerValue>(initBank);

  const [dataFilter, setDataFilter] = useState(initDataFilter);

  useEffect(() => {
    dispatch(changeOpenType({ openType: "accounts-details" }));
  }, []);

  useEffect(() => {
    const response = infoInitQuery.data;
    if (response) {
      const { data, filters } = response.data;
      setDataTable(data);
      setDataFilter({
        dataBank: getFilterFromResponse(filters.banks_filter),
        dataCompany: getFilterFromResponse(filters.companies_filter),
      });
    }
  }, [infoInitQuery]);

  const viewTable = useMemo(
    () =>
      dataTable.filter(
        ({ bankId, companyId }) =>
          (bankId === bank.id || bank.id === "all-banks") &&
          (companyId === company.id || company.id === "all-companies")
      ),
    [bank, company, dataTable]
  );

  return (
    <WrapperBodyTitle {...infoInitQuery.data?.data.page?.header}>
      <CompanyAtBank
        pickers={[
          {
            value: company,
            setValue: setCompany,
            data: dataFilter.dataCompany,
            disabledError: true,
          },
          {
            value: bank,
            setValue: setBabk,
            data: dataFilter.dataBank,
            disabledError: true,
          },
        ]}
      />
      <AccountDetailsTable dataTable={viewTable} />
    </WrapperBodyTitle>
  );
};

export default AccountDetails;
