import { Button, Modal } from "antd";
import { FC, useEffect, useId, useState } from "react";
import CommonModalComponent from "../../../../component/commonModal";
import { IFileTransferDocument } from "../../../../component/Global/transferDocument/types";
import TypeToTextMap from "../../../../component/Global/TypeToTextMap";
import { IValueError } from "../../../../component/payment/types";
import { ActionReqiredAPI } from "../../../../services/actionReqired";
import { IItemTableAPI } from "../../../../services/types/table";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import ARBAMItem from "./item";
import * as Styled from "./style";
import ARBAMButtonUpload from "./upload";

interface IProps {
  onCancel: () => void;
  counterparty: IItemTableAPI[][];
  amount: IItemTableAPI[][];
  requestId: string;
  onSuccess: () => void;
}

const ARBAModal: FC<IProps> = ({
  onCancel,
  counterparty,
  amount,
  requestId,
  onSuccess,
}) => {
  const idComponent = useId();
  const [querySend, infoSendQuery] = useMutationAPI(ActionReqiredAPI.resolve);
  const [files, setFiles] = useState<IValueError<IFileTransferDocument[]>>({
    value: [],
    error: "", });

  useEffect(() => {
    const response = infoSendQuery.data;
    if (response && response.data.result) {
      onSuccess();
    }
  }, [infoSendQuery]);

  return (
    <CommonModalComponent
      width={478}
      onClose={onCancel}
      title="Upload documents"
      message={
        <>
          <TypeToTextMap
            data={[...counterparty, ...amount]}
            styleData={{ textAling: "center" }}
            position="center"
          />
        </>
      }
      buttons={[
        <div key={idComponent + ".Modal_Button"}>
          <ARBAMButtonUpload
            files={files}
            setFiles={setFiles}
            requestId={requestId}
            isAble={!infoSendQuery.isLoading}
          />
          <Styled.WrapperSend>
            <Button
              shape="round"
              size="large"
              type="primary"
              onClick={() => querySend({ requestId })}
              loading={infoSendQuery.isLoading}
              disabled={
                !files.value.length ||
                !!files.value.find(({ isLoading }) => isLoading)
              }
            >
              Send
            </Button>
          </Styled.WrapperSend>
        </div>,
      ]}
    />
  );
};

export default ARBAModal;
