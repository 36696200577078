import styled from "styled-components";

export const WrapperItem = styled.div`
  display: flex;
  padding: 20px 0;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
