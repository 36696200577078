import styled from "styled-components";

export const AmountWrapper = styled.div`
  width: 33%;

  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 20px;
  }
`;
