import { Button, InputRef } from "antd";
import { createRef, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CryptoSendContext } from "..";
import AuthTransfer from "../../../../component/authTransfer";
import { IValueError } from "../../../../component/payment/types";
import { CryptoAPI } from "../../../../services/crypto";
import { TAuthCode } from "../../../../services/types";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";

export interface IDataThirdStepPayment {
  amount: string;
  name: string;
}

const textType = (type: TAuthCode) => {
  if (type === "google-authenticator")
    return "Please enter Google Authenticator code";
  return "Please enter SMS code";
};

const CryptoSendTS = () => {
  const valueContext = useContext(CryptoSendContext);
  const refInput = createRef<InputRef>();

  const { dataTS, dataCommon, setStep, step } = valueContext;
  const { paymentId, checksum, typeAuth } = dataCommon;

  const [executeQuery, infoExecuteQuery] = useMutationAPI(
    CryptoAPI.send.execute
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (infoExecuteQuery.error) {
      navigate("/new-payment");
    }
    if (infoExecuteQuery.data) {
      const { accountId } = infoExecuteQuery.data.data;
      navigate("/crypto/statement", { state: { accountId } });
    }
  }, [infoExecuteQuery]);

  const onClickExecute = ({ value, error }: IValueError<string>) => {
    if (!!error.length) return;
    executeQuery({ paymentId, checksum, auth_code: value });
  };

  useEffect(() => {
    if (step === 3) {
      setTimeout(() => {
        if (refInput.current) refInput.current.focus();
      }, 1000);
    }
  }, [step]);

  return (
    <AuthTransfer
      {...dataTS}
      onClick={onClickExecute}
      isLoadingAuth={infoExecuteQuery.isLoading}
      ref={refInput}
    >
      <Button
        shape="round"
        size="large"
        onClick={() => setStep(1)}
        disabled={infoExecuteQuery.isLoading}
      >
        Edit payment details
      </Button>
    </AuthTransfer>
  );
};

export default CryptoSendTS;
