import { IPayloadInvoiceValid } from "../../services/invoiceBuilder/types/payload";
import { IResInitBuilder } from "../../services/invoiceBuilder/types/response";
import { getValueError } from "../payments/data";
import {
  IContext,
  IDataCommon,
  IDataFS,
  IDataSS,
  IStaticDataFS,
} from "./types";

export const initStaticDataFS: IStaticDataFS = {
  beneficiary: { title: "", data: [] },
  remitter: { title: "", data: [] },
  details: { title: "", data: [] },
  logo: "",
};

export const initDataFS: IDataFS = {
  description: getValueError(""),
  dueDate: getValueError(null),
  invoiceDate: getValueError(new Date()),
  invoiceNumber: getValueError(""),
};

export const initDataSS: IDataSS = {
  logo: "",
  beneficiary: {
    title: "",
    data: [],
  },
  remmiter: {
    title: "",
    data: [],
  },
  details: {
    title: "",
    data: [],
  },
  table: [],
  footer: [],
};

export const initDataCommon: IDataCommon = {
  dataTable: [
    {
      title: getValueError(""),
      qty: getValueError("1"),
      rate: getValueError(""),
      tax: getValueError("0"),
    },
  ],
  invoiceID: "",
  currency: "",
  templateName: "",
  prefixRef: "",
  taxType: { text: "", id: "" },
  descriptionMode: false,
  referencePrefix: "",
};

const initSet = () => console.log("Init Context Invoice Use");

export const initContext: IContext = {
  step: 1,
  setStep: initSet,
  staticDataFS: initStaticDataFS,
  setStaticDataFS: initSet,
  dataFS: initDataFS,
  setDataFS: initSet,
  dataSS: initDataSS,
  setDataSS: initSet,
  dataCommon: initDataCommon,
  setDataCommon: initSet,
  onChangeData: initSet,
  onErrorData: initSet,
};

const resToData: {
  [key in keyof IPayloadInvoiceValid["payload"]]: keyof IDataFS;
} = {
  invoiceData: "description",
  invoiceDate: "invoiceDate",
  invoiceDueDate: "dueDate",
  invoiceNumber: "invoiceNumber",
  invoiceReference: "description",
};

export const getResFormToData = (form: IResInitBuilder["form"]) => {
  let responseData: Partial<{ [key in keyof IDataFS]: string | Date }> = {};
  const dataTable: IDataCommon["dataTable"] = [];
  if (!form) return { dataForm: [], dataTable: initDataCommon.dataTable };

  const keysForm = Object.keys(form) as (keyof typeof form)[];

  keysForm.forEach((key) => {
    if (key === "invoiceData") {
      const data = form[key];
      if (!data) return;

      data.forEach((item) => {
        dataTable.push({
          title: getValueError(item.invoiceItemDescription),
          qty: getValueError(item.invoiceItemQty.toString()),
          rate: getValueError(item.invoiceItemRate.toString()),
          tax: getValueError(item.invoiceItemTax.toString()),
        });
      });
      return;
    }

    responseData[resToData[key]] = form[key];
  });

  const response = Object.entries(responseData) as [
    keyof IDataFS,
    string | Date
  ][];

  return {
    dataForm: response.map(([key, value]) => ({ key, value })),
    dataTable: !dataTable.length ? initDataCommon.dataTable : dataTable,
  };
};
