import instance from "..";
import { IResponseStatements } from "../pagesAPI/types/response";
import {
  IPayloadAddToPending,
  IPayloadAuthorize,
  IPayloadCryptoRate,
  IPayloadDraftBulk,
  IPayloadDraftEft,
  IPayloadDraftFx,
  IPayloadDraftInternal,
  IPayloadDraftSepa,
  IPayloadDraftSwift,
  IPayloadDraftUk,
  IPayloadExecute,
  IPayloadGetInitPayment,
  IPayloadValidateBulk,
  IPayloadValidateCrypto,
  IPayloadValidateEft,
  IPayloadValidateFx,
  IPayloadValidateInternal,
  IPayloadValidateSepa,
  IPayloadValidateSwift,
  IPayloadValidateUk,
} from "./types/payload";
import {
  IResponseAuthorize,
  IResponseCryptoRate,
  IResponseExecute,
  IResponseInitBulk,
  IResponseInitCrypto,
  IResponseInitEft,
  IResponseInitFx,
  IResponseInitIternal,
  IResponseInitSepa,
  IResponseInitSwift,
  IResponseInitUk,
  IResponsePaymentValidate,
} from "./types/response";

const PaymentAPI = {
  getInitList: () =>
    instance.get<IResponseStatements>("/app/init-payments-list"),
  authorise: ({ paymentID }: IPayloadAuthorize) =>
    instance.get<IResponseAuthorize>("transfers/authorize/" + paymentID),
  execute: ({ paymentId, checksum, auth_code }: IPayloadExecute) =>
    instance.post<IResponseExecute>(
      `transfers/execute/${paymentId}/${checksum}`,
      {
        auth_code,
      }
    ),
  addToPending: ({ paymentID, checksum }: IPayloadAddToPending) =>
    instance.get<IResponseAuthorize>(
      `/transfers/save/${paymentID}/${checksum}`
    ),
  discard: (paymentId: string) =>
    instance.get(`transfers/discard/${paymentId}`),
  sepa: {
    getInit: ({ id, mode }: IPayloadGetInitPayment) =>
      instance.get<IResponseInitSepa>(`/transfers/${mode}/sepa/${id}`),
    validate: ({ id, payload }: IPayloadValidateSepa) =>
      instance.post<IResponsePaymentValidate>(
        "transfers/validate/sepa/" + id,
        payload
      ),
    draft: ({ id, payload }: IPayloadDraftSepa) =>
      instance.post("transfers/save-draft/sepa/" + id, payload),
  },
  swift: {
    getInit: ({ id, mode }: IPayloadGetInitPayment) =>
      instance.get<IResponseInitSwift>(`/transfers/${mode}/swift/${id}`),
    validate: ({ id, payload }: IPayloadValidateSwift) =>
      instance.post<IResponsePaymentValidate>(
        "transfers/validate/swift/" + id,
        payload
      ),
    draft: ({ id, payload }: IPayloadDraftSwift) =>
      instance.post("transfers/save-draft/swift/" + id, payload),
  },
  eft: {
    getInit: ({ id, mode }: IPayloadGetInitPayment) =>
      instance.get<IResponseInitEft>(`/transfers/${mode}/eft/${id}`),
    validate: ({ id, payload }: IPayloadValidateEft) =>
      instance.post<IResponsePaymentValidate>(
        "transfers/validate/eft/" + id,
        payload
      ),
    draft: ({ id, payload }: IPayloadDraftEft) =>
      instance.post("transfers/save-draft/eft/" + id, payload),
  },
  fx: {
    getInit: ({ id, mode }: IPayloadGetInitPayment) =>
      instance.get<IResponseInitFx>(`/transfers/${mode}/fx/${id}`),
    validate: ({ id, payload }: IPayloadValidateFx) =>
      instance.post<IResponsePaymentValidate>(
        "transfers/validate/fx/" + id,
        payload
      ),
    draft: ({ id, payload }: IPayloadDraftFx) =>
      instance.post("transfers/save-draft/fx/" + id, payload),
  },
  bulk: {
    getInit: ({ id, mode }: IPayloadGetInitPayment) =>
      instance.get<IResponseInitBulk>(`/transfers/${mode}/bulk-fx/${id}`),
    validate: ({ id, payload }: IPayloadValidateBulk) =>
      instance.post<IResponsePaymentValidate>(
        "transfers/validate/bulk-fx/" + id,
        payload
      ),
    draft: ({ id, payload }: IPayloadDraftBulk) =>
      instance.post("transfers/save-draft/bulk-fx/" + id, payload),
  },
  internal: {
    getInit: ({ id, mode }: IPayloadGetInitPayment) =>
      instance.get<IResponseInitIternal>(`/transfers/${mode}/internal/${id}`),
    validate: ({ id, payload }: IPayloadValidateInternal) =>
      instance.post<IResponsePaymentValidate>(
        "transfers/validate/internal/" + id,
        payload
      ),
    draft: ({ id, payload }: IPayloadDraftInternal) =>
      instance.post("transfers/save-draft/internal/" + id, payload),
  },
  uk: {
    getInit: ({ id, mode }: IPayloadGetInitPayment) =>
      instance.get<IResponseInitUk>(`/transfers/${mode}/uk/${id}`),
    validate: ({ id, payload }: IPayloadValidateUk) =>
      instance.post<IResponsePaymentValidate>(
        "transfers/validate/uk/" + id,
        payload
      ),
    draft: ({ id, payload }: IPayloadDraftUk) =>
      instance.post("transfers/save-draft/uk/" + id, payload),
  },
  crypto: {
    getInit: ({ id, mode, type }: IPayloadGetInitPayment) =>
      instance.get<IResponseInitCrypto>(`/transfers/${mode}/${type}/${id}`),
    validate: ({ id, payload, type }: IPayloadValidateCrypto) =>
      instance.post<IResponsePaymentValidate>(
        `transfers/validate/${type}/${id}`,
        payload
      ),
    rate: ({ nameCrypto, currencyCode }: IPayloadCryptoRate) =>
      instance.get<IResponseCryptoRate>(
        `/crypto/buy-rate/${nameCrypto}/${currencyCode}`
      ),
  },
};

export default PaymentAPI;
