import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: end;

  & > div {
    min-width: 200px;
    width: 100%;
  }
`;
