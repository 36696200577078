import styled from "styled-components";
import { Text } from "../../../commonStyle";

interface IWrapper {
  width: string;
}

export const Wrapper = styled.div<IWrapper>`
  width: ${({ width }) => width};
`;

export const Error = styled(Text)`
  min-height: 22px;
  text-align: start;
  color: #eb5757;
`;

export const Title = styled(Text)`
  text-align: start;
`;

export const WrapperAllert = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;
