import { FC } from "react";
import { formatValue } from "react-currency-input-field";
import { Text, TextBold } from "../../../../../../commonStyle";
import * as Styled from "./style";

interface IProps {
  prefix: string;
  value: string;
  isTotal?: boolean;
}

const IBFLine: FC<IProps> = ({ prefix, value, isTotal = false }) => {
  return (
    <tr>
      <td>
        {!isTotal && (
          <Text fontSize={16} textAling="end">
            {prefix}
          </Text>
        )}
        {isTotal && (
          <TextBold fontSize={16} textAling="end">
            {prefix}
          </TextBold>
        )}
      </td>
      <Styled.ColumnAmount>
        {!isTotal && <Text fontSize={16}>{value}</Text>}
        {isTotal && <TextBold fontSize={16}>{value}</TextBold>}
      </Styled.ColumnAmount>
    </tr>
  );
};

export default IBFLine;
