import PaymentAPI from "../../../../services/paymentAPI";
import { IPayloadValidateInternal } from "../../../../services/paymentAPI/types/payload";
import { IErrorPayment } from "../../../../services/types";
import FirstStepCommon from "../../shared/firstStep";
import PaymentBeneficiaryCompanyPickerCommon from "../../shared/firstStep/beneficiary/companyPicker";
import CommonContinueButton from "../../shared/firstStep/continueButton";
import TransferDocumentCommon from "../../shared/firstStep/transferDocument";
import { IDataCommon, IDataFS, InputInternal } from "../types";
import { getPayload } from "./continueButton/data";
import { getErrorResponse } from "./data";
import PaymentDetaisInternal from "./details";

type TErrorResponse = IErrorPayment<
  keyof IPayloadValidateInternal["payload"]
>[];

const FirstStepInternal = ({
  input,
  setStep,
}: {
  input: InputInternal;
  setStep: (step: number) => void;
}) => {
  return (
    <FirstStepCommon
      input={input}
      setStep={setStep}
      validateApi={PaymentAPI.internal.validate}
      draftApi={PaymentAPI.internal.draft}
      getErrorResponse={(errors) => getErrorResponse(errors as TErrorResponse)}
      content={(
        queryValidate,
        infoValidateQuery,
        queryDraft,
        infoDraftQuery
      ) => {
        return (
          <>
            <PaymentDetaisInternal input={input} />
            <PaymentBeneficiaryCompanyPickerCommon input={input} />
            <TransferDocumentCommon
              input={input}
              disabled={infoValidateQuery.isLoading}
              lookAtIsRequireFiles={false}
            />
            <CommonContinueButton<IDataFS, IDataCommon>
              input={input}
              queryValidate={(dataFS, dataCommon) => {
                return queryValidate({
                  id: dataCommon.paymentID,
                  payload: getPayload(
                    dataFS,
                    dataCommon.accountId,
                    dataCommon.useCompanyNameInDescriptionControl
                  ),
                });
              }}
              isLoadingValid={infoValidateQuery.isLoading}
              queryDraft={(dataFS, dataCommon, autoSave) => {
                return queryDraft({
                  id: dataCommon.paymentID,
                  payload: {
                    ...getPayload(
                      dataFS,
                      dataCommon.accountId,
                      dataCommon.useCompanyNameInDescriptionControl
                    ),
                    autoSave,
                  },
                });
              }}
              isLoadingDraft={infoDraftQuery.isLoading}
              buttonsCanBeDisabled={false}
            />
          </>
        );
      }}
    />
  );
};

export default FirstStepInternal;
