import styled, { css } from "styled-components";
import { Text } from "../../../../../commonStyle";

export const WrapperTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px 0;
  padding: 5px;
  & > div:first-child {
    margin-bottom: 5px;
  }
`;

export const WrapperName = styled.div`
  width: 32px;
  height: 24px;
  margin: 0 auto;
`;

interface IWrapperItem {
  isActiveRange: boolean;
  color: string;
  isActive: boolean;
  status: boolean;
}

export const WrapperItem = styled(WrapperName)<IWrapperItem>`
  background-color: ${({ isActiveRange }) =>
    isActiveRange ? "#E6F7FF" : "transparent"};
  & > div {
    color: ${({ color, isActive }) => (isActive ? "white" : color)};
    border: 1px solid;
    border-color: ${({ isActive }) => (isActive ? "#6c64f4" : "transparent")};
    background-color: ${({ isActive }) =>
      isActive ? "#6c64f4" : "transparent"};
    border-radius: 2px;
    cursor: ${({ status }) => (status ? " pointer" : "not-allowed ")};
    text-overflow: unset;
  }
  &:hover {
    & > div {
      ${({ status }) =>
        status &&
        css`
          border-color: #6c64f4;
          background-color: #6c64f4;
          color: white;
        `}
    }
  }
`;

export const Item = styled(Text)`
  text-align: center;
  width: 24px;
  height: 24px;
  color: #041e42;
`;

export const WrapperColumn = styled.div`
  display: flex;
`;
