import PickerComponent, {
  IItemPicker,
  IItemPickerValue,
} from "../../../../../component/Global/Picker";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

type IDataFS = {
  BCaState: IValueError<IItemPickerValue>;
};

type IDataCommon = {
  dataCaState: IItemPicker[];
};

interface Input {
  dataFS: IDataFS;
  dataCommon: IDataCommon;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const CanadaStatePicker = ({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    formActions: { preparePickerRequired },
  },
}: {
  input: Input;
}) => {
  return (
    <PickerComponent
      {...dataFS.BCaState}
      data={dataCommon.dataCaState}
      setValue={(value) => onChangeDataFS("BCaState", value)}
      title="State:"
      placeholder="Select State"
      id="BCaState"
      fieldActions={preparePickerRequired("BCaState")}
    />
  );
};
