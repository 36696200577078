import { useContext } from "react";
import { ContextRecurringPayment } from "../../../../..";
import WrapperTitleError from "../../../../../../../component/Wrappers/wrapperTitleError";
import ScheduleDailyPicker from "./dailyPicker";
import ScheduleMounthlyPicker from "./monthly";
import * as Styled from "./style";
import ScheduleWeeklyPicker from "./weekly";

const ScheduleOptions = () => {
  const { dataFS } = useContext(ContextRecurringPayment);
  const { schedulePicker, scheduleOptions } = dataFS;

  if (!schedulePicker.value.id) {
    return null;
  }

  return (
    <WrapperTitleError error={scheduleOptions.error}>
      <Styled.Wrapper id="scheduleOptions">
        <div>{`${schedulePicker.value.text} schedule options`}</div>
        {schedulePicker.value.id === "daily" && <ScheduleDailyPicker />}
        {schedulePicker.value.id === "weekly" && <ScheduleWeeklyPicker />}
        {schedulePicker.value.id === "monthly" && <ScheduleMounthlyPicker />}
        {schedulePicker.value.id === "quarterly" && (
          <ScheduleMounthlyPicker singleSelected />
        )}
      </Styled.Wrapper>
    </WrapperTitleError>
  );
};

export default ScheduleOptions;
