import styled from "styled-components";

export const WrapperRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  margin-top: 15px;
  & > * {
    flex: 1;
  }
  @media (max-width: 1024px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;

export const WrapperUrgent = styled.div`
  margin-top: 15px;
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
`;

export const WrapperDate = styled.div`
  width: calc(100% / 3);

  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 20px;
  }
`;

export const WrapperTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  transform: translateY(-16px);
  & > div {
    width: 100%;
  }
  @media (max-width: 1400px) {
    transform: translateY(-8px);
  }
  @media (max-width: 1024px) {
    justify-content: start;
    & > div {
      width: 100%;
    }
  }
`;
