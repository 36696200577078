import * as Styled from "./style";

const SuccessResReset = () => {
  return (
    <>
      <Styled.Info>Reset instructions were sent to your email.</Styled.Info>
      <Styled.Info>Please check your mailbox.</Styled.Info>
    </>
  );
};

export default SuccessResReset;
