import { Dispatch, SetStateAction } from "react";
import { IItemPicker } from "../../../component/Global/Picker";
import { IFileTransferDocument } from "../../../component/Global/transferDocument/types";
import TypeToTextMap from "../../../component/Global/TypeToTextMap";

import {
  IDictionariesFx,
  IDropdownOptions,
  IFilesPayment,
} from "../../../services/paymentAPI/types";
import { IPayloadValidateBulk } from "../../../services/paymentAPI/types/payload";
import { getValueError } from "../data";
import { IDataCommon, IDataFS } from "./types";

export const initDataFS: IDataFS = {
  DAmount: getValueError("0.00"),
  DPaymentDescription: getValueError(""),
  DIsCompanyName: getValueError(true),

  BCompany: getValueError({ text: "", id: "" }),

  Tfiles: getValueError([]),
};

export const initDataCommon: IDataCommon = {
  balance: 0,
  balanceCurrencySymbol: "",
  balanceCurrencyCode: "",
  balanceCurrencyId: "",
  accountIdTo: "",
  dataHeader: [],
  paymentID: "",
  checksum: "",
  typeAuth: "google-authenticator",
  amountSymbol: "",
  companyHints: [],
  dataCompanyID: [],
  dataCurrencyID: [],
  dataRates: {},
  dataCurruncyDictionary: [],
  accountId: "",
  isRequireFiles: true,
  isDisableSaveAsDraft: false,
  useCompanyNameInDescriptionControl: true,
  companyName: "",
  isLoadingFiles: false,
  prefixDescription: "Test Company",
  isAbleAuth: false,
  isUsingTemplate: false,
};

export const getCompanyHints = (beneficiaries: IDropdownOptions) => {
  const dataOptions = beneficiaries.data;
  const data = dataOptions.map(
    ({ value, data }): IItemPicker => ({
      text: data.map(({ text }) => text).join(" "),
      id: value,
      component: <TypeToTextMap data={[data]} />,
    })
  );
  return data;
};

export const getDataCompanyID = (beneficiaries: IDropdownOptions) => {
  const dataOptions = beneficiaries.data;
  const data = dataOptions.map(
    ({ value, currencyId }): IItemPicker => ({
      text: currencyId,
      id: value,
    })
  );
  return data;
};

export const getDataCurrency = (dictionaries: IDictionariesFx) => {
  const data = dictionaries.currencies.map(({ currencyId, currencyName }) => ({
    text: currencyName,
    id: currencyId,
  }));
  return data;
};

export const getDataCurrencyID = (dictionaries: IDictionariesFx) => {
  const data = dictionaries.currencies.map(
    ({ currencyId, currencySymbol }) => ({
      text: currencySymbol,
      id: currencyId,
    })
  );
  return data;
};

const resToData: {
  [key in keyof IPayloadValidateBulk["payload"]]: keyof IDataFS;
} = {
  accountId: "DAmount",
  accountIdTo: "BCompany",
  fxPaymentAmount: "DAmount",
  paymentDescription: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
  fxPaymentCurrencyId: "DAmount",
};

type TValue = string | boolean | IItemPicker | IFileTransferDocument[];

export const getDataFromForm = (
  form: Partial<IPayloadValidateBulk["payload"]>,
  files: IFilesPayment[],
  dataCompany: IItemPicker[],
  dataCurrency: IItemPicker[],
  paymentDescription: string
) => {
  let responseData: Partial<{
    [key in keyof IDataFS]: TValue;
  }> = {};

  const keysForm = Object.keys(form) as (keyof typeof form)[];

  keysForm.forEach((key) => {
    const value = form[key];
    const keyData = resToData[key];

    if (!keyData) return;

    if (typeof value === "number") {
      responseData[keyData] = value.toFixed(2);
      return;
    }

    if (keyData === "BCompany") {
      responseData[keyData] =
        dataCompany.find(({ id }) => id === form.accountIdTo) ||
        initDataFS.BCompany.value;
      return;
    }

    if (
      keyData === "DPaymentDescription" &&
      typeof value === "string" &&
      form.paymentDescriptionCompanyName
    ) {
      responseData[keyData] = value.slice(paymentDescription.length + 1);
      return;
    }

    responseData[keyData] = value;
  });

  responseData["Tfiles"] = files.map(({ documentId, name, url }) => ({
    id: documentId,
    name,
    url,
    isLoading: false,
  }));

  return Object.entries(responseData) as [keyof IDataFS, TValue][];
};
