import { Button } from "antd";
import { FC } from "react";
import AmountComponent from "../../../../../../../../../component/Global/Amount";
import SwitchComponent from "../../../../../../../../../component/Global/Switch";
import { IData } from "../../types";
import * as Styled from "./style";

interface IProp {
  data: IData;
  setData: <K extends keyof IData, D extends IData[K]>(key: K, data: D) => void;
  onClickButton: () => void;
}

const CTFCAutoTopUpFS: FC<IProp> = ({ data, setData, onClickButton }) => {
  return (
    <Styled.Wrapper>
      <Styled.WrapperSwitch>
        <SwitchComponent
          checked={data.isEnabled}
          onChange={(value) => setData("isEnabled", value)}
          text="Enabled"
        />
      </Styled.WrapperSwitch>

      <Styled.Title>When balance goes below</Styled.Title>
      <AmountComponent
        value={data.amountBelow}
        onChangeValue={(value) => setData("amountBelow", value)}
        placeholder="0.00"
        width="60%"
        prefix="€"
      />

      <Styled.Title>Top up card with amount of</Styled.Title>
      <AmountComponent
        value={data.amountTopUp}
        onChangeValue={(value) => setData("amountTopUp", value)}
        placeholder="0.00"
        width="60%"
        prefix="€"
      />

      <Button
        shape="round"
        size="large"
        style={{ marginTop: "15px" }}
        onClick={onClickButton}
      >
        Authorize & Save
      </Button>
    </Styled.Wrapper>
  );
};

export default CTFCAutoTopUpFS;
