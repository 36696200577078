import styled from "styled-components";

interface IWrapper {
  isOpen: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  position: fixed;
  top: 0;
  left: 0;
  height: ${({ isOpen }) => (isOpen ? "100vh" : "0px")};
  width: 100vw;
  z-index: 1000;
`;

export const Background = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
`;

interface IWrapperBody {
  isOpen: boolean;
}

export const WrapperBody = styled.div<IWrapperBody>`
  position: relative;
  margin-top: 1%;
  left: ${({ isOpen }) => (isOpen ? "calc(99% - 270px)" : "100%")};
  z-index: 2;
  width: 270px;
  height: 98vh;
  background-color: white;
  border-radius: 10px;
  transition: left 0.5s;
  & > div:nth-child(2) {
    height: 75%;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: unset;
    margin-right: unset;
  }
  & > div:nth-child(2) > div {
    padding-top: unset;
  }
`;

export const WrapperHeader = styled.div`
  height: 16%;
  padding: 20px 10px 0;
  margin: 0 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0 50px;
  border-bottom: 1px solid #e5e5e5;
`;

export const WrapperName = styled.div`
  & svg {
    width: 20px;
    height: 20px;
  }
  & > div:first-child {
    max-height: 50px;
  }
`;

export const WrapperFooter = styled.div`
  height: 10%;
  padding: 20px;
  margin: 0 20px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
  cursor: pointer;
  & svg {
    width: 20px;
    height: 20px;
  }
`;
