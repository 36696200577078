import InputComponent from "../../../../../component/Global/Input";
import { NUMERIC_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions, PreparedFieldActions } from "../form";

const rulesInstitution: IRulesString = { min: 3, max: 3, isReq: true };

type IInstitutionNumberDataFS = {
  BInstitutionNumber: IValueError<string>;
};

type IpaymentBankInstitutionNumberDataFS = {
  paymentBankInstitutionNumber: IValueError<string>;
};

interface InstitutionNumberInput {
  dataFS: IInstitutionNumberDataFS;
  onChangeDataFS: TOnChangeDataFS<IInstitutionNumberDataFS>;
  formActions: FormActions<IInstitutionNumberDataFS>;
}

interface PaymentBankInstitutionNumberInput {
  dataFS: IpaymentBankInstitutionNumberDataFS;
  onChangeDataFS: TOnChangeDataFS<IpaymentBankInstitutionNumberDataFS>;
  formActions: FormActions<IpaymentBankInstitutionNumberDataFS>;
}

interface IInstitutionNumberInputTemplate {
  data: IValueError<string>;
  onChangeValue: (value: string) => void;
  id: string;
  fieldActions: PreparedFieldActions;
}

const InstitutionNumberTemplate = ({
  data,
  onChangeValue,
  id,
  fieldActions,
}: IInstitutionNumberInputTemplate) => {
  return (
    <InputComponent
      {...data}
      onChangeValue={onChangeValue}
      title="Institution Number:"
      maxLength={rulesInstitution.max}
      regex={NUMERIC_REGEX}
      id={id}
      fieldActions={fieldActions}
    />
  );
};

export const InstitutionNumberInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: InstitutionNumberInput;
}) => {
  return (
    <InstitutionNumberTemplate
      data={dataFS.BInstitutionNumber}
      onChangeValue={(value) => onChangeDataFS("BInstitutionNumber", value)}
      fieldActions={prepareStringRulesField(
        "BInstitutionNumber",
        rulesInstitution
      )}
      id="BInstitutionNumber"
    />
  );
};

export const PaymentBankInstitutionNumberInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: PaymentBankInstitutionNumberInput;
}) => {
  return (
    <InstitutionNumberTemplate
      data={dataFS.paymentBankInstitutionNumber}
      onChangeValue={(value) =>
        onChangeDataFS("paymentBankInstitutionNumber", value)
      }
      fieldActions={prepareStringRulesField(
        "paymentBankInstitutionNumber",
        rulesInstitution
      )}
      id="paymentBankInstitutionNumber"
    />
  );
};
