import { FC } from "react";
import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import { IValueError } from "../../../../../component/payment/types";
import { TOnChangeData, TSetErrorData } from "../../../types";
import * as Styled from "./style";
import { validateUsernames } from "./utils";
import {
  LOGIN_REGEX,
  NAME_REGEX,
} from "../../../../../utils/regex/editProfile";

interface IProps {
  name: IValueError<string>;
  login: IValueError<string>;
  onChangeData: TOnChangeData;
  setErrorData: TSetErrorData;
}

const EPNameLogin: FC<IProps> = ({
  name,
  login,
  onChangeData,
  setErrorData,
}) => {
  const onBlur = (type: "name" | "login", data: IValueError<string>) => {
    const error = validateUsernames(data.value);
    setErrorData(type, error);
  };

  return (
    <Styled.Wrapper>
      <InputComponent
        {...name}
        title="Name:"
        onChangeValue={(value) => onChangeData("name", value)}
        onBlur={() => onBlur("name", name)}
        maxLength={100}
        regex={NAME_REGEX}
        onLostFocusActions={[
          LostFocusActions.REPLACE_WHITESPACES,
          LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
          LostFocusActions.UPPERCASE_FIRST_LETTERS,
        ]}
      />
      <InputComponent
        {...login}
        title="Login:"
        onChangeValue={(value) => onChangeData("login", value)}
        onBlur={() => onBlur("login", login)}
        maxLength={100}
        regex={LOGIN_REGEX}
        onLostFocusActions={[
          LostFocusActions.REMOVE_WHITESPACES,
          LostFocusActions.REPLACE_DASH_LIKE_CHARACTERS,
        ]}
      />
    </Styled.Wrapper>
  );
};

export default EPNameLogin;
