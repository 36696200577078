import styled from "styled-components";
import { Text } from "../../commonStyle";

export const WrapperModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Text)`
  margin-bottom: 16px;
`;

export const Info = styled(Text)`
  margin-bottom: 20px;
  text-align: center;
`;

export const WrapperButton = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
`;
