import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { createMainImports } from "./utils/createMainImports";
import { MainANTDWrapper } from "./assets/theme/MainAntdWrapper";

// Add css links
createMainImports();

// i18n
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .use(HttpApi)
//   .init({
//     supportedLngs: ["en", "ua"],
//     debug: false,
//     detection: {
//       order: ["cookie", "localStorage"],
//       caches: ["cookie"],
//     },
//     backend:{
//       loadPath:"/language/{{lng}}/translate.json"
//     }
//   });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MainANTDWrapper />
      <App />
    </Provider>
  </BrowserRouter>
);
