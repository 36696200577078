import SwitchComponent from "../../../../../component/Global/Switch";
import PaymentDetailsCommon from "../../../shared/firstStep/payment";
import { InputFx } from "../../types";
import PaymentDetaisCurrencyFirst from "./firstPart";
import PaymentDetaisCurrencySecond from "./secondPart";

const PaymentDetaisFx = ({ input }: { input: InputFx }) => {
  const { dataFS, onChangeDataFS, setErrorDataFS } = input;

  return (
    <PaymentDetailsCommon>
      <SwitchComponent
        checked={dataFS.DIsInterTransfer.value}
        text="Intercompany payment"
        onChange={(value) => {
          setErrorDataFS("Tfiles", "");
          onChangeDataFS("DIsInterTransfer", value);
        }}
        id="DIsInterTransfer"
        textChange
      />
      <PaymentDetaisCurrencyFirst input={input} />
      <PaymentDetaisCurrencySecond input={input} />
    </PaymentDetailsCommon>
  );
};

export default PaymentDetaisFx;
