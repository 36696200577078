import { getValueError } from "../payments/data";
import { IData } from "./types";

export const initData: IData = {
  authType: getValueError("sms"),
  timezone: getValueError({ text: "", id: "" }),
  name: getValueError(""),
  login: getValueError(""),
  oldPassword: getValueError(""),
  newPassword: getValueError(""),
};
