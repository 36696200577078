import styled from "styled-components";

export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WrapperDataLine = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;
`;
