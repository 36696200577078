import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  max-width: 1840px;
  padding: 15px 20px;
  margin: 0 auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media (max-width: 1024px) {
    padding: 5px;
  }
`;

export const WrapperBodyNav = styled.div`
  min-height: calc(100vh - 310px);
  display: flex;

  @media (max-width: 1024px) {
    min-height: calc(100% - 160px - 90px);
    & > div:first-child {
      display: none;
    }
  }
`;

interface IWrapperBody {
  widthNav: number;
  isLoading: boolean;
}

export const WrapperBody = styled.div<IWrapperBody>`
  width: ${({ widthNav }) => `calc(100% - ${widthNav + 15}px)`};
  padding: 50px 40px;
  background-color: white;
  border-radius: 20px;
  transition: width 0.3s;
  overflow: hidden;

  ${({ isLoading }) =>
    isLoading &&
    css`
      & > div:first-child {
        opacity: 1;
        height: fit-content;
      }
      & > div {
        opacity: 0;
        height: 0;
      }
    `}

  @media (max-width: 1400px) {
    width: calc(100% - 100px);
  }
  @media (max-width: 1024px) {
    width: 100%;
    padding: 30px 20px;
    min-height: calc(100% - 250px);
  }
`;
