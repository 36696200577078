import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeOpenType } from "../../store/user";
import MainPageCryptoFiatPayIn from "./main";

const CryptoFiatPayInComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeOpenType({ openType: "crypto-fiat-pay-in-request" }));
  }, []);

  return <MainPageCryptoFiatPayIn />;
};

export default CryptoFiatPayInComponent;
