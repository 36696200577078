import styled from "styled-components";
import { TextBold } from "../../../../commonStyle";

export const WrapperRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0px 15px;
  margin-bottom: 5px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled(TextBold)`
  width: 300px;
`;

export const WrapperButton = styled.div`
  margin-top: 30px;
`;
