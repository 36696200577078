import { useContext, useEffect, useMemo } from "react";
import PickerComponent from "../../../../component/Global/Picker";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { ContextCryptoFiat } from "../..";
import { IDataContext } from "../../types";
import { TOnChangeDataFS, TSetErrorDataFS } from "../../../payments/types";
import AmountComponent from "../../../../component/Global/Amount";
import useSizePage from "../../../../utils/hookSizePage";
import validateNumber from "../../../../utils/validate/validateNumber";
import InputComponent from "../../../../component/Global/Input";
import PaymentBeneficiaryCommon from "../../../payments/shared/firstStep/beneficiary/companyInput";
import CounterpartyRowsCryptoFiat from "./counterparty/rows";
import { getValueError } from "../../../payments/data";
import * as Styled from "./style";
import { Button } from "antd";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import { CryptoFiatPayInApi } from "../../../../services/cryptoFiatPayIn";
import { createPayload, getErrorResponse } from "./data";
import { IErrorPayment } from "../../../../services/types";
import { IPayloadValidate } from "../../../../services/cryptoFiatPayIn/types/payload";
import { scrollToFirstError } from "../../../../utils/scrollToError";
import { deepCopyData } from "../../../payments/shared/utils";

const CryptoFiatPayInFirstStep = () => {
  const valueContext = useContext(ContextCryptoFiat);
  const { width } = useSizePage();

  const {
    data,
    setData,
    setDataSS,
    setStep,
    dataCommon,
    formActions,
    createInput,
  } = valueContext;
  const { benefAccount, paymentDescription, amount } = data;
  const {
    preparePickerRequired,
    prepareMoney,
    prepareStringRulesField,
    validate,
  } = formActions;
  const { dataBenefAccount, dictionaries } = dataCommon;

  const [queryValidate, infoQueryValidate] = useMutationAPI(
    CryptoFiatPayInApi.validate
  );

  const currencySymbol = dictionaries.currencies[0].currencySymbol;

  const emptyFields: Partial<IDataContext> = {
    BCountry: getValueError({ text: "", id: "" }),
    BState: getValueError({ text: "", id: "" }),
    BCaState: getValueError({ text: "", id: "" }),
    BCity: getValueError(""),
    BPostalCode: getValueError(""),
    BStreetAddress: getValueError(""),
    BHouseNumber: getValueError(""),
  };

  const onChangeData: TOnChangeDataFS<IDataContext> = (key, value) => {
    setData((prev) => ({ ...prev, [key]: { error: "", value } }));
  };

  const onErrorData: TSetErrorDataFS<IDataContext> = (key, error) => {
    setData((prev) => ({ ...prev, [key]: { ...prev[key], error } }));
  };

  const input = createInput();

  const onClickContinue = () => {
    const validatedData = validate();
    if (validatedData === null) {
      queryValidate(createPayload(data, dataCommon));
    } else {
      scrollToFirstError(validatedData);
    }
  };

  useEffect(() => {
    const errors = infoQueryValidate.error?.response?.data?.errors;
    if (errors) {
      const errorsData = getErrorResponse(
        errors as IErrorPayment<keyof IPayloadValidate["payload"]>[],
        dataCommon.usID == data.BCountry.value.id,
        dataCommon.canadaID == data.BCountry.value.id
      );
      const newData = deepCopyData(data);
      errorsData.forEach(({ key, error }) => {
        newData[key].error = error;
      });
      setData(newData);
      scrollToFirstError(newData);
    }

    const response = infoQueryValidate.data;
    if (response) {
      setDataSS((prev) => ({ ...prev, dataSecondStep: response.data.data }));
      setStep(2);
    }
  }, [infoQueryValidate]);

  return (
    <div>
      <WrapperWhiteBG title="Payment Details">
        <PickerComponent
          {...benefAccount}
          title="Company & crypto assets to invoice in"
          setValue={(value) => onChangeData("benefAccount", value)}
          data={dataBenefAccount}
          placeholder="Select Company and Asset"
          id="benefAccount"
          fieldActions={preparePickerRequired("benefAccount")}
        />

        <AmountComponent
          {...amount}
          onChangeValue={(value) => onChangeData("amount", value)}
          title="Fiat Amount to receive:"
          prefix={currencySymbol + " "}
          placeholder={currencySymbol + " 0.00"}
          width={width > 1024 ? "33%" : "100%"}
          id="amount"
          charLimit={10}
          fieldActions={prepareMoney("amount", (value) =>
            validateNumber(value, { min: 0.01 }, { symbol: currencySymbol })
          )}
        />

        <InputComponent
          {...paymentDescription}
          onChangeValue={(value) => onChangeData("paymentDescription", value)}
          title="Invoice payment description:"
          id="paymentDescription"
          maxLength={100}
          fieldActions={prepareStringRulesField("paymentDescription", {
            isReq: true,
            min: 3,
            max: 100,
          })}
        />
      </WrapperWhiteBG>

      <PaymentBeneficiaryCommon
        input={input}
        companyValidationRules={{
          isReq: true,
          min: 3,
          max: 100,
        }}
        emptyFields={emptyFields}
        title="Counterparty Details"
        companyNamePlaceholder=""
      >
        <CounterpartyRowsCryptoFiat input={input} />
      </PaymentBeneficiaryCommon>

      <Styled.WrapperButton>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={onClickContinue}
          loading={infoQueryValidate.isLoading}
        >
          Continue
        </Button>
      </Styled.WrapperButton>
    </div>
  );
};

export default CryptoFiatPayInFirstStep;
