import { FC, useContext, useEffect } from "react";
import { ContextMassPayment } from "../../..";
import TransferDocument from "../../../../../component/Global/transferDocument";
import { IFileToFormData } from "../../../../../component/Global/transferDocument/types";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { MassPaymentAPI } from "../../../../../services/massPaymentAPI";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";

interface IProps {
  isAble: boolean;
}

const MPTransferDocument: FC<IProps> = ({ isAble }) => {
  const dataContext = useContext(ContextMassPayment);
  if (!dataContext) return <div />;

  const { data, setData, typeQuery } = dataContext;
  const { files, importId } = data;

  const [queryUpload] = useMutationAPI(MassPaymentAPI.file.upload);
  const [queryDelete] = useMutationAPI(MassPaymentAPI.file.delete);

  const onDeleteFiles = async (id: string) => {
    setData((prev) => ({ ...prev, isLoadingFile: true }));

    const response = await queryDelete({ fileId: id, importId, typeQuery });

    setData((prev) => ({ ...prev, isLoadingFile: false }));

    if (response.data) return response.data.data.result;
    return false;
  };

  const onUploadFiles = async (data: IFileToFormData[]) => {
    setData((prev) => ({ ...prev, isLoadingFile: true }));

    const response = await Promise.all(
      data.map(({ formData }) => queryUpload({ formData, typeQuery, importId }))
    );

    setData((prev) => ({ ...prev, isLoadingFile: false }));

    return response.map(({ data: response, error: responseError }) => {
      if (response && response.data.status === "done") {
        const { name, documentId: id } = response.data;
        return { name, id, isLoading: false };
      }
      if (responseError && responseError.response) {
        return "";
      }
      if (response && response.data.status === "file_exists") {
        return "";
      }
      return "Unknow error";
    });
  };

  const setValue = (value: typeof data.files.value) => {
    setData((prev) => {
      return { ...prev, files: { ...prev.files, value } };
    });
  };

  const setError = (error: string) => {
    setData((prev) => {
      return { ...prev, files: { ...prev.files, error } };
    });
  };

  return (
    <WrapperWhiteBG title="Import File">
      <TransferDocument
        {...files}
        title="Please provide Excel file in format according to the Payment Destination type."
        setValue={setValue}
        setError={setError}
        onUpload={onUploadFiles}
        onDelete={onDeleteFiles}
        accept=".xlsx"
        isAble={isAble}
        isRequired
      />
    </WrapperWhiteBG>
  );
};

export default MPTransferDocument;
