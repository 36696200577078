import { InputFx } from "../../../types";
import FirstRowBeneficiarySwift from "./firstRow";
import SecondRowBeneficiarySwift from "./secondRow";
import ThirdRowBeneficiarySwift from "./thirdRow";

const BeneficiaryRowsFx = ({ input }: { input: InputFx }) => {
  const { dataFS, dataCommon } = input;
  const countryId = dataFS.BCountry.value.id;
  const { canadaID, usID, ukID } = dataCommon;

  return (
    <>
      <FirstRowBeneficiarySwift
        input={input}
        isUS={countryId === usID}
        isCanada={countryId === canadaID}
      />
      <SecondRowBeneficiarySwift
        input={input}
        isUS={countryId === usID}
        isCanada={countryId === canadaID}
      />
      <ThirdRowBeneficiarySwift
        input={input}
        isUS={countryId === usID}
        isCanada={countryId === canadaID}
        isUk={countryId === ukID}
      />
    </>
  );
};

export default BeneficiaryRowsFx;
