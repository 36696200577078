import styled from "styled-components";

export const WrapperContinue = styled.div`
  display: flex;
  justify-content: space-between;

  button.ant-btn-primary {
    margin-left: auto;
  }
`;
