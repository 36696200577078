import { FC } from "react";
import { Text } from "../../../../../../../commonStyle";
import * as Styled from "./style";

interface IProps {
  isAble: boolean;
  isError: boolean;
  text: string;
}

const PaymentRateComponent: FC<IProps> = ({ isAble, text, isError }) => {
  return (
    <Styled.Wrapper>
      {isAble && (
        <Text fontSize={16} color={isError ? "#FF0018" : undefined}>
          &#8780;{" " + text}
        </Text>
      )}
    </Styled.Wrapper>
  );
};

export default PaymentRateComponent;
