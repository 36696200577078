import { IValueError } from "../../../../../../component/payment/types";
import validateString from "../../../../../../utils/validate/validateString";
import { getValueError } from "../../../../../payments/data";
import { IData } from "./types";

export const initData: IData = {
  description: getValueError(""),
  email: getValueError(""),
  info: getValueError(""),
};

export const validateData = (data: IData) => {
  const dataArr = Object.entries(data) as [keyof IData, IValueError<string>][];

  return dataArr.map(([key, { value }]) => {
    const error = validateString(value, {
      isReq: true,
      isEmail: key === "email",
    });

    return {
      key,
      error,
    };
  });
};
