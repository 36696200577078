import { LockOutlined } from "@ant-design/icons";
import { Button, InputRef, Skeleton } from "antd";
import { createRef, FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Text } from "../../commonStyle";
import InputComponent from "../../component/Global/Input";
import UserAPI from "../../services/userAPI";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import useQueryAPI from "../../utils/usePromise/hookQueryAPI";
import * as Styled from "./style";

interface IProps {
  type: "password" | "google2fa";
}

const ResetTokenComponent: FC<IProps> = ({ type }) => {
  const { token = "" } = useParams();
  const navigate = useNavigate();

  const infoCheckQuery = useQueryAPI(UserAPI.reset.checkToken, {
    payload: {
      type,
      token,
    },
  });
  const [querySend] = useMutationAPI(UserAPI.resendCode);
  const [queryConfirm, infoConfirmQuery] = useMutationAPI(
    UserAPI.reset.confirmCode
  );

  const [code, setCode] = useState({ value: "", error: "" });
  const [seconds, setSeconds] = useState(30);
  const [phoneHind, setPhoneHind] = useState("+...xx");

  const refInput = createRef<InputRef>();

  const titleInput = useMemo(() => {
    const common = "Enter SMS code to reset ";
    if (type === "password") return common + type;
    return common + " Google Authenticator";
  }, [type]);

  const onChangeCode = (code: string) => {
    if (/^[0-9]*$/.test(code)) {
      setCode({ value: code, error: "" });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (refInput.current) {
      refInput.current.focus();
    }
  }, [infoCheckQuery.isLoading]);

  useEffect(() => {
    const response = infoCheckQuery.data;
    const responseError = infoCheckQuery.error;
    if (response) {
      setPhoneHind("+..." + response.data.userPhone.slice(-2));
    }
    if (responseError) {
      navigate("/login");
    }
  }, [infoCheckQuery]);

  useEffect(() => {
    const response = infoConfirmQuery.data;
    if (
      response &&
      (response.data.resetPassword || response.data.updatePassword)
    ) {
      const { userId, data, title } = response.data;
      navigate("/reset/password/update", {
        state: { userId, data, title },
      });
      return;
    }
    if (response) {
      localStorage.removeItem("token");
      navigate("/login");
    }
  }, [infoConfirmQuery]);

  return (
    <>
      {infoCheckQuery.isLoading ? (
        <Skeleton paragraph={{ rows: 7 }} active />
      ) : (
        <Styled.Wrapper>
          <Text fontSize={18}>{`SMS was sent to ${phoneHind}`}</Text>
          <InputComponent
            {...code}
            onChangeValue={onChangeCode}
            title={titleInput}
            prefix={<LockOutlined />}
            placeholder="SMS Code"
            maxLength={4}
            onKeyEnter={() => queryConfirm({ type, token, code: code.value })}
            ref={refInput}
            disabledError
          />
          <Button
            shape="round"
            size="large"
            onClick={() => queryConfirm({ type, token, code: code.value })}
            loading={infoConfirmQuery.isLoading}
            disabled={code.value.length !== 4}
            block
          >
            Confirm
          </Button>
          <Button
            type="link"
            shape="round"
            size="large"
            onClick={() => {
              querySend({ type });
              setSeconds(30);
            }}
            disabled={infoConfirmQuery.isLoading || seconds > 0}
            block
          >
            Resend SMS code
            {seconds > 0 && (
              <span>
                &nbsp;in {seconds < 10 ? `0${seconds}` : seconds} seconds
              </span>
            )}
          </Button>
        </Styled.Wrapper>
      )}
    </>
  );
};

export default ResetTokenComponent;
