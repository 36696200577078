import { IDataTable } from "./types";

export const initDataTable: IDataTable = {
  cards: [],
  templates: {
    activate_card_modal: { title: "", data: [] },
    block_card_modal: { title: "", data: [] },
    show_card_pin_modal: { title: "", data: [] },
    terminate_card_modal: { title: "", data: [] },
    unblock_card_modal: { title: "", data: [] },
  },
};
