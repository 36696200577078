import * as Styled from "./style";

import { useNavigate } from "react-router-dom";
import { Text, TextBold } from "../../../../commonStyle";
import { useAppSelector } from "../../../../utils/useState";
import { Button } from "antd";
import HeaderBurger from "./burger";
import { useDispatch } from "react-redux";
import {
  changeOpenType,
  setIsAuth,
  setIsQuestionLeave,
} from "../../../../store/user";
import { createRef, useEffect, useState } from "react";
import { logoImg } from "../../../../assets";
import UserAPI from "../../../../services/userAPI";
import NBModalLeave from "../navBar/modalLeave";
import React from "react";

const Image = ({ logoImg }: { logoImg: string | undefined }) => {
  return (
    <Styled.WrapperHeaderImg>
      <img src={logoImg} />
    </Styled.WrapperHeaderImg>
  );
};

const ImageMemoized = React.memo(Image, () => true);

const HeaderWrapper = () => {
  const { userName, openType, isQuestionLeave } = useAppSelector(
    (state) => state.userReducer
  );
  const [willEditProfile, setWillEditProfile] = useState<boolean>(false);
  const [willLogOut, setWillLogOut] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refHeader = createRef<HTMLDivElement>();

  const [isLogOut, setIsLogOut] = useState(false);

  const logOut = () => {
    localStorage.removeItem("token");
    setIsLogOut(true);

    UserAPI.logout();

    setTimeout(() => {
      navigate("/login");
      dispatch(setIsAuth(false));
      dispatch(setIsQuestionLeave(false));
      setIsLogOut(false);
    }, 1000);
  };

  useEffect(() => {
    if (refHeader.current) {
      refHeader.current.scrollIntoView(false);
    }
  }, [openType]);

  const onEditProfile = () => {
    if (isQuestionLeave) {
      setWillEditProfile(true);
    } else {
      dispatch(changeOpenType({ openType: "" }));
      navigate("/edit-profile");
    }
  };

  const onLogOut = () => {
    if (isQuestionLeave) {
      setWillLogOut(true);
    } else {
      logOut();
    }
  };

  return (
    <Styled.WrapperHeader ref={refHeader}>
      {willEditProfile && (
        <NBModalLeave
          onClickStay={() => setWillEditProfile(false)}
          onClickLeave={() => {
            navigate("/edit-profile");
            dispatch(setIsQuestionLeave(false));
            dispatch(changeOpenType({ openType: "" }));
            setWillEditProfile(false);
          }}
        />
      )}

      {willLogOut && (
        <NBModalLeave
          onClickStay={() => setWillLogOut(false)}
          onClickLeave={() => {
            logOut();
            dispatch(setIsQuestionLeave(false));
            setWillLogOut(false);
          }}
        />
      )}

      <ImageMemoized logoImg={logoImg} />
      <Styled.WrapperHeaderContent>
        <div>
          <TextBold fontSize={18} id="UserName">
            {userName}
          </TextBold>
          <Text onClick={() => onEditProfile()} isCursor>
            Edit profile
          </Text>
        </div>
        <Styled.WrapperButton>
          <Button
            shape="round"
            size="large"
            onClick={onLogOut}
            loading={isLogOut}
          >
            Log Out
          </Button>
        </Styled.WrapperButton>
      </Styled.WrapperHeaderContent>
      <HeaderBurger />
    </Styled.WrapperHeader>
  );
};

export default HeaderWrapper;
