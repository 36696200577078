import axios, { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IResponseError } from "../../../services/types";
import {
  setCouterQuery,
  setIsInitQuery,
  setIsLoading,
} from "../../../store/user";
import { useAppSelector } from "../../useState";
import usePromiseData from "../hookPromiseData";

export interface IInfoQuery<T, E> {
  data: AxiosResponse<T> | null;
  isLoading: boolean;
  error: AxiosError<E> | null;
}

interface IProps<D> {
  isLogin?: boolean;
  payload?: D;
  reCaptcha?: boolean;
  changeIsLoading?: boolean;
}

const payloadDefault: any = {};
const initInfo = {
  data: null,
  isLoading: false,
  error: null,
};

function useQueryAPI<T, D, E = IResponseError>(
  promise: (data: D) => Promise<AxiosResponse<T>>,
  dataProps?: IProps<D>
) {
  const [info, setInfo] = useState<IInfoQuery<T, E>>(initInfo);
  const { isInitQuery, couterQuery } = useAppSelector(
    (state) => state.userReducer
  );
  const { onError, onSucces, getReCatcha } = usePromiseData<T, E>();
  const dispatch = useDispatch();

  const {
    changeIsLoading = false,
    isLogin = false,
    reCaptcha = false,
    payload = payloadDefault,
  } = dataProps || {};

  useEffect(() => {
    makeRequest();
  }, [promise]);

  const makeRequest = async () => {
    try {
      setInfo({ data: null, isLoading: true, error: null });
      if (changeIsLoading) dispatch(setCouterQuery(1));

      if (reCaptcha) {
        const status = await getReCatcha();
        if (!status) throw new Error("ReCaptcha failed");
      }

      const data = await promise(payload);
      onSucces(data.data);

      setInfo({ ...initInfo, data });
    } catch (e) {
      if (!axios.isAxiosError(e)) {
        setInfo(initInfo);
      }

      const error = e as AxiosError<E>;

      onError(error);

      setInfo({ ...initInfo, error });
    } finally {
      if (isInitQuery && !isLogin) dispatch(setIsInitQuery(false));
      if (changeIsLoading) dispatch(setCouterQuery(-1));
    }
  };

  return info;
}

export default useQueryAPI;
