import PickerComponent, {
  IItemPicker,
  IItemPickerValue,
} from "../../../../../component/Global/Picker";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

type IDataFS = {
  DFee: IValueError<IItemPickerValue>;
};

type IDataCommon = {
  dataFee: IItemPicker[];
};

interface Input {
  dataFS: IDataFS;
  dataCommon: IDataCommon;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const FeePicker = ({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    formActions: { preparePickerRequired },
  },
}: {
  input: Input;
}) => {
  return (
    <PickerComponent
      {...dataFS.DFee}
      setValue={(value) => onChangeDataFS("DFee", value)}
      data={dataCommon.dataFee}
      width="66%"
      id="DFee"
      placeholder="Select Fee"
      fieldActions={preparePickerRequired("DFee")}
    />
  );
};
