import { createRef, FC, ReactNode, useEffect } from "react";
import * as Styled from "./style";

interface IProps {
  step: number;
  children: ReactNode;
  gapChilden?: string;
  speedHide?: number;
  speedSlide?: number;
}

const WrapperSteps: FC<IProps> = ({
  step,
  children,
  gapChilden = "16px 0",
  speedHide = 1,
  speedSlide = 1,
}) => {
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        if (ref.current) ref.current.scrollIntoView(true);
      }, 500);
    }
  }, [step]);

  return (
    <div style={{ marginTop: "16px" }}>
      <div ref={ref} />
      <Styled.Wrapper>
        <Styled.WrapperChildren
          step={step}
          gap={gapChilden}
          speedHide={speedHide}
          speedSlide={speedSlide}
        >
          {children}
        </Styled.WrapperChildren>
      </Styled.Wrapper>
    </div>
  );
};

export default WrapperSteps;
