import { Button } from "antd";
import { useContext } from "react";
import { ContextInvoiceBuilder } from "../../..";
import * as Styled from "./style";

const IBSSButtons = () => {
  const { setStep } = useContext(ContextInvoiceBuilder);

  return (
    <Styled.Wrapper>
      <Button shape="round" size="large" onClick={() => setStep(1)}>
        Back to Fill out step
      </Button>
    </Styled.Wrapper>
  );
};

export default IBSSButtons;
