export type AppTheme = {
  projectColor: string;
  mainColor: string;
  secondColor: string;
};

export const CassiopayTheme: AppTheme = {
  projectColor: "#445ef2",
  mainColor: "#6b7ff2",
  secondColor: "#445ef2",
};

export const paylogiqTheme: AppTheme = {
  projectColor: "#6c64f4",
  mainColor: "#5bdfa7",
  secondColor: "#5536ef",
};

export const smroTheme: AppTheme = {
  projectColor: "#00d690",
  mainColor: "#5bdfa7",
  secondColor: "#00d690",
};

export const smcaTheme: AppTheme = {
  projectColor: "#00d690",
  mainColor: "#5bdfa7",
  secondColor: "#00d690",
};
