import * as Styled from "../../../../shared/firstStep/beneficiary/style";
import { AccountNumberInput } from "../../../../shared/firstStep/components/accountNumberInput";
import { SortCodeInput } from "../../../../shared/firstStep/components/sortCodeInput";
import { InputUk } from "../../../types";

const SortRowBeneficiaryUk = ({ input }: { input: InputUk }) => {
  return (
    <Styled.Row>
      <Styled.Item flex={1}>
        <SortCodeInput input={input} />
      </Styled.Item>

      <Styled.Item flex={1}>
        <AccountNumberInput input={input} />
      </Styled.Item>
    </Styled.Row>
  );
};

export default SortRowBeneficiaryUk;
