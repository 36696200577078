import styled from "styled-components";

export interface ITextCommon {
  fontSize?: number;
  height?: number;
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  margin?: string;
  padding?: string;
  color?: string;
  isCursor?: boolean;
  textAling?: "start" | "center" | "end" | "unset";
  breakWord?: boolean;
  noWrap?: boolean;
  width?: string;
}

export const Text = styled.div<ITextCommon>`
  width: ${({ width }) => (width ? width : "unset")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize + "px" : "14px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
  margin: ${({ margin }) => (margin ? margin : "unset")};
  padding: ${({ padding }) => (padding ? padding : "unset")};
  color: ${({ color }) => (color ? color : "#041e42")};
  text-align: ${({ textAling }) => (textAling ? textAling : "unset")};
  cursor: ${({ isCursor }) => (isCursor ? "pointer" : "inherit")};
  text-overflow: ellipsis;
  overflow: hidden;
  height: ${({ height }) => (height ? height + "px" : "unset")};
  word-break: ${({ breakWord }) => (breakWord ? "break-all" : "normal")};
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "normal")};
`;

export const TextLight = styled(Text)`
  font-family: Poppins-light;
`;

export const TextBold = styled(Text)`
  font-family: Poppins-bold;
`;

export const Link = styled.a`
  width: fit-content;
  text-decoration: none;
`;
