import InputComponent, {
  LostFocusActions,
} from "../../../../../component/Global/Input";
import { IBAN_ACCOUNT_NUMBER_FOR_SWIFT_AND_CORRESPONDENT_ACCOUNT_REGEX } from "../../../../../utils/regex/payments";
import { IRulesString } from "../../../../../utils/validate/validateString";
import { IValueError } from "../../../../contact/form/types";
import { TOnChangeDataFS } from "../../../types";
import { FormActions } from "../form";

const rulesCorrespAccount: IRulesString = { min: 7, max: 34 };

type IDataFS = {
  BCorrespAccount: IValueError<string>;
};

interface Input {
  dataFS: IDataFS;
  onChangeDataFS: TOnChangeDataFS<IDataFS>;
  formActions: FormActions<IDataFS>;
}

export const CorrespondentAccountInput = ({
  input: {
    dataFS,
    onChangeDataFS,
    formActions: { prepareStringRulesField },
  },
}: {
  input: Input;
}) => {
  return (
    <InputComponent
      {...dataFS.BCorrespAccount}
      onChangeValue={(value) => onChangeDataFS("BCorrespAccount", value)}
      title="Correspondent Account#:"
      fieldActions={prepareStringRulesField(
        "BCorrespAccount",
        rulesCorrespAccount
      )}
      maxLength={rulesCorrespAccount.max}
      regex={IBAN_ACCOUNT_NUMBER_FOR_SWIFT_AND_CORRESPONDENT_ACCOUNT_REGEX}
      onLostFocusActions={[
        LostFocusActions.REMOVE_WHITESPACES,
        LostFocusActions.REMOVE_DASH_LIKE_CHARACTERS,
        LostFocusActions.UPPERCASE,
      ]}
      id="BCorrespAccount"
    />
  );
};
