import { Button, InputRef, Modal } from "antd";
import { createRef, FC, useEffect, useState } from "react";
import { Text } from "../../../../commonStyle";
import InputComponent from "../../../../component/Global/Input";
import { TAuthCode } from "../../../../services/types";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import { useAppSelector } from "../../../../utils/useState";
import validateString from "../../../../utils/validate/validateString";
import * as Styled from "./style";
import { AxiosResponse } from "axios";

interface IExecute {
  auth_code: string;
  transfers: string[];
}

interface IResponseExecute {
  result: boolean;
}

interface IProps {
  onCancel: () => void;
  transfers: string[];
  onResponse: () => void;
  execute: (input: IExecute) => Promise<AxiosResponse<IResponseExecute, any>>;
}

const textType = (type: TAuthCode) => {
  if (type === "google-authenticator")
    return "Please enter Google Authenticator code";
  return "Please enter SMS code";
};

const ModalAuth: FC<IProps> = ({
  onCancel,
  transfers,
  onResponse,
  execute,
}) => {
  const { userMultiFactorType } = useAppSelector((store) => store.userReducer);
  const [queryExecute, infoExecuteQuery] = useMutationAPI(execute);

  const refInput = createRef<InputRef>();

  const [auth, setAuth] = useState({ value: "", error: "" });

  const countNumber = userMultiFactorType === "sms" ? 4 : 6;

  useEffect(() => {
    if (refInput.current) {
      refInput.current.focus();
    }
  }, [refInput.current]);

  useEffect(() => {
    const response = infoExecuteQuery.data;
    if (response && response.data.result) {
      onResponse();
      onCancel();
    }
  }, [infoExecuteQuery]);

  const onBlurCode = () => {
    const error = validateString(auth.value, {
      isReq: true,
      min: countNumber,
      max: countNumber,
    });
    setAuth((prev) => ({ ...prev, error }));
  };

  const onClickAuth = () => {
    if (auth.value.length === countNumber) {
      queryExecute({ auth_code: auth.value, transfers });
    }
  };

  const onChangeCode = (value: string) => {
    if (/^[0-9]*$/.test(value)) {
      setAuth({ error: "", value });
    }
  };

  return (
    <Modal width={478} open={true} onCancel={onCancel}>
      <Styled.Wrapper>
        <Styled.Title fontSize={20}>Authorize transfers</Styled.Title>
        <Text fontSize={16} color="rgba(0,0,0,0.65)" textAling="center">
          {textType(userMultiFactorType)}
        </Text>
        <Styled.WrapperInput>
          <InputComponent
            {...auth}
            onChangeValue={onChangeCode}
            onKeyEnter={onClickAuth}
            onBlur={onBlurCode}
            maxLength={countNumber}
            placeholder="Code"
            ref={refInput}
          />
        </Styled.WrapperInput>

        <Styled.WrapperButton>
          <Button
            shape="round"
            size="large"
            type="primary"
            onClick={onClickAuth}
            style={{ padding: "6.4px 40px" }}
            disabled={!auth.value.length}
            loading={infoExecuteQuery.isLoading}
          >
            Authorize
          </Button>
        </Styled.WrapperButton>
      </Styled.Wrapper>
    </Modal>
  );
};

export default ModalAuth;
