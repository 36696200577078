import { Button } from "antd";
import { useContext, useEffect } from "react";
import { CardTopUpContext } from "../..";
import VerifyPayments from "../../../../component/payment/verify";
import WrapperWhiteBG from "../../../../component/Wrappers/wrapperWhiteBG";
import { CardAPI } from "../../../../services/cards";
import useMutationAPI from "../../../../utils/usePromise/hookMutationAPI";
import * as Styled from "./style";

const CTUSecondStep = () => {
  const valueContext = useContext(CardTopUpContext);

  const { dataSS, dataCommon, setStep } = valueContext;
  const { paymentId } = dataCommon;

  const [queryAuth, infoAutQuery] = useMutationAPI(CardAPI.topUp.authorize);

  useEffect(() => {
    const response = infoAutQuery.data;

    if (response && response.data.result) {
      setStep(3);
    }
  }, [infoAutQuery]);

  return (
    <div>
      <WrapperWhiteBG>
        <Styled.Wrapper>
          <VerifyPayments data={dataSS} />
          <Styled.WrapperButton>
            <Button
              type="primary"
              shape="round"
              size="large"
              loading={infoAutQuery.isLoading}
              onClick={() => queryAuth({ paymentId })}
            >
              Authorize
            </Button>
          </Styled.WrapperButton>
        </Styled.Wrapper>
      </WrapperWhiteBG>
      <Styled.WrapperButton>
        <Button
          shape="round"
          size="large"
          disabled={infoAutQuery.isLoading}
          onClick={() => setStep(1)}
        >
          Edit payment details
        </Button>
      </Styled.WrapperButton>
    </div>
  );
};

export default CTUSecondStep;
