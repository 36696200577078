import { useContext } from "react";
import { ContextInvoiceBuilder } from "../../..";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import * as Styled from "./style";

const IBSSMainInfo = () => {
  const { remmiter,details } = useContext(ContextInvoiceBuilder).dataSS;

  return (
    <Styled.Wrapper>
      <WrapperWhiteBG title={details.title}>
        <TypeToTextMap data={details.data} />
      </WrapperWhiteBG>
      <WrapperWhiteBG title={remmiter.title}>
        <TypeToTextMap data={remmiter.data} />
      </WrapperWhiteBG>
    </Styled.Wrapper>
  );
};

export default IBSSMainInfo;
