import { Button } from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import PickerComponent, { IItemPicker } from "../../../component/Global/Picker";
import WrapperWhiteBG from "../../../component/Wrappers/wrapperWhiteBG";
import useSizePage from "../../../utils/hookSizePage";
import * as Styled from "./style";

interface IProps {
  dataPicker: IItemPicker[];
}

const CardRequest: FC<IProps> = ({ dataPicker }) => {
  const [value, setValue] = useState<IItemPicker>({ text: "", id: "" });
  const navigate = useNavigate();
  const { width } = useSizePage();

  const onClickPicker = () => {
    navigate("/cards/request", { state: { id: value.id } });
  };

  return (
    <WrapperWhiteBG titleOut="REQUEST CARD">
      <Styled.Wrapper>
        <PickerComponent
          value={value}
          data={dataPicker.slice(0, 1)}
          setValue={setValue}
          placeholder="Select Company"
          width={width > 768 ? "300px" : "100%"}
          disabledError
          selectOneItem
        />
        <Button
          shape="round"
          size="large"
          onClick={onClickPicker}
          disabled={!value.id.length}
        >
          REQUEST
        </Button>
      </Styled.Wrapper>
    </WrapperWhiteBG>
  );
};

export default CardRequest;
