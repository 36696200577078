import { FC, useContext } from "react";
import { ContextImportBulkTransfer } from "../../..";
import TransferDocument from "../../../../../component/Global/transferDocument";
import { IFileToFormData } from "../../../../../component/Global/transferDocument/types";
import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import { BulkTransferAPI } from "../../../../../services/bulkTransfer";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";

interface IProps {
  disabled: boolean;
}

const BTFSTransferDocument: FC<IProps> = ({ disabled }) => {
  const valueContext = useContext(ContextImportBulkTransfer);

  const { dataFS, dataCommon, onChangeDataFS, setErrorFS, setDataCommon } =
    valueContext;
  const { files } = dataFS;
  const { importId } = dataCommon;

  const [queryUpload] = useMutationAPI(BulkTransferAPI.file.upload);
  const [queryDelete] = useMutationAPI(BulkTransferAPI.file.delete);

  const onDeleteFiles = async (id: string) => {
    const response = await queryDelete({ fileId: id, importId });

    if (response.data) return response.data.data.result;
    return false;
  };

  const onUploadFiles = async (data: IFileToFormData[]) => {
    const response = await Promise.all(
      data.map(({ formData }) => queryUpload({ formData, id: importId }))
    );

    return response.map(({ data: response, error: responseError }) => {
      if (response && response.data.status === "done") {
        const { name, documentId: id } = response.data;
        return { name, id, isLoading: false };
      }
      if (responseError && responseError.response) {
        return "";
      }
      if (response && response.data.status === "file_exists") {
        return "";
      }
      return "Unknow error";
    });
  };

  return (
    <WrapperWhiteBG title="Payment Documents">
      <TransferDocument
        {...files}
        setValue={(value) => onChangeDataFS("files", value)}
        setError={(error) => setErrorFS("files", error)}
        onUpload={onUploadFiles}
        onDelete={onDeleteFiles}
        setIsLoading={(isLoadingFiles) =>
          setDataCommon((prev) => ({ ...prev, isLoadingFiles }))
        }
        isAble={!disabled}
        accept=".xlsx, .pdf"
        isRequired
        multiple
      />
    </WrapperWhiteBG>
  );
};

export default BTFSTransferDocument;
