import WrapperWhiteBG from "../../../../../component/Wrappers/wrapperWhiteBG";
import IBRemFirstRow from "./rows/firstRow";
import IBRemFourthRow from "./rows/fourthRow";
import IBRemSecondRow from "./rows/secondRow";
import IBRemThirdRow from "./rows/thirdRow";

const IBFSRemitter = () => {
  return (
    <WrapperWhiteBG title="Bill to">
      <IBRemFirstRow />
      <IBRemSecondRow />
      <IBRemThirdRow />
      <IBRemFourthRow />
    </WrapperWhiteBG>
  );
};

export default IBFSRemitter;
