import { ReactNode } from "react";
import { Route } from "react-router-dom";
import Reset from "../../pages/reset";
import ResetTokenComponent from "../../pages/resetToken";
import ResetUPComponent from "../../pages/resetUpdatePassword";
import UserAPI from "../../services/userAPI";
import { TReset } from "../../services/userAPI/types";

const onClickReset = (emailAddress: string, type: TReset) => {
  return UserAPI.reset.sendEmail({ emailAddress, type });
};

const ResetRoutes = () => {
  return (
    <Route path="/reset">
      <Route
        path="password"
        element={
          <Reset
            title="Forgot your password?"
            textButton="Reset password"
            promiseReset={(email: string) => {
              return onClickReset(email, "password");
            }}
          />
        }
      />
      <Route
        path="g2fa"
        element={
          <Reset
            title="Google Authenticator Reset"
            textButton="Reset Authenticator"
            promiseReset={(email: string) => {
              return onClickReset(email, "google2fa");
            }}
          />
        }
      />
      <Route path="password">
        <Route path="update" element={<ResetUPComponent />} />
        <Route
          path=":token"
          element={<ResetTokenComponent type="password" />}
        />
      </Route>

      <Route
        path="g2fa/:token"
        element={<ResetTokenComponent type="google2fa" />}
      />
    </Route>
  );
};

export default ResetRoutes;
