import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeOpenType } from "../../store/user";
import PendingBulkTransfer from "./pending";

const BulkTransferPayment = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeOpenType({ openType: "bulk-payments" }));
  }, []);

  return <PendingBulkTransfer />;
};

export default BulkTransferPayment;
