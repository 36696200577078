import * as Styled from "./style";
import { FC, useEffect, useState } from "react";
import UserAPI from "../../services/userAPI";
import { useNavigate } from "react-router-dom";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import validateString from "../../utils/validate/validateString";
import LFSButtons from "./buttons";
import { initDataCred } from "./data";
import { ICredState } from "./types";
import LFSItem from "./item";
import { useDispatch } from "react-redux";
import { setIsInitQuery } from "../../store/user";
import OptionalErrorAlert from "../../component/OptionalErrorAlert";

const divReCaptcha = document.querySelector(".grecaptcha-badge-disabled");

const LoginComponent = () => {
  const [queryStep1, infoQuery] = useMutationAPI(UserAPI.loginStep1, {
    isLogin: true,
    reCaptcha: true,
  });
  const [dataCred, setDataCred] = useState({ ...initDataCred });
  const [errors, setErrors] = useState({ ...initDataCred });
  const [globalError, setGlobalErrors] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onChangeValue = (name: keyof ICredState, value: string) => {
    setDataCred((prev) => ({ ...prev, [name]: value }));
    setGlobalErrors("");
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const onBlurInput = (name: keyof ICredState) => {
    const error = validateString(dataCred[name], {
      isReq: true,
      min: 3,
      max: 40,
    });
    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const validateData = (data: ICredState) => {
    if (!data.login.length || !data.password.length) {
      setErrors((prev) => ({
        login: data.login.length ? prev.login : "Field is required",
        password: data.password.length ? prev.password : "Field is required",
      }));
      return false;
    }
    if (errors.login.length || errors.password.length) return false;
    return true;
  };

  const onLogin = () => {
    const isValidData = validateData(dataCred);
    if (!isValidData) return;
    queryStep1({ ...dataCred });
  };

  const onClickReset = () => {
    navigate("/reset/password");
  };

  useEffect(() => {
    if (divReCaptcha) divReCaptcha.className = "grecaptcha-badge";
    dispatch(setIsInitQuery(true));
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
  }, []);

  useEffect(() => {
    const response = infoQuery.data?.data;

    if (infoQuery.error) {
      setGlobalErrors("Incorrect credentials");
    }
    if (response) {
      const {
        verificationType,
        multiFactorData = "",
        userPhone = "",
      } = response;
      const QRCode = multiFactorData === "exists" ? "" : multiFactorData;
      navigate("/login/code", {
        state: { userPhone, QRCode, type: verificationType, isFromApp: true },
      });
    }
  }, [infoQuery]);

  return (
    <>
      <OptionalErrorAlert message={globalError} />
      <Styled.WrapperInput>
        <LFSItem
          data={dataCred}
          errors={errors}
          onBlurInput={onBlurInput}
          onChangeValue={onChangeValue}
          onLogin={onLogin}
        />
        <LFSButtons
          login={{ loading: infoQuery.isLoading, onClick: onLogin }}
          forgot={{
            onClick: onClickReset,
            disabled: infoQuery.isLoading,
          }}
        />
      </Styled.WrapperInput>
    </>
  );
};

export default LoginComponent;
