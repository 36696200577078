import instance from "..";
import { IFileAPI } from "../types";
import { IPTransactionGetData } from "./types/payload";
import { IRTransactionInit } from "./types/response";

export const TransactionsAPI = {
  init: () => instance.get<IRTransactionInit>("processing/transactions-list"),
  getData: (payload: IPTransactionGetData) =>
    instance.post<IRTransactionInit>("processing/transactions-list", payload),
  downloadFile: (payload: IPTransactionGetData) =>
    instance.post<IFileAPI>("processing/transactions-list", payload),
};
