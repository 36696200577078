import { FC, useEffect } from "react";
import { IWallets } from "../../../../../services/crypto/types";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { message, Typography } from "antd";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { CryptoAPI } from "../../../../../services/crypto";
import TextCopyable from "../../../../../component/Global/TextCopyable";

const { Paragraph } = Typography;

interface IProps extends IWallets {
  realoadPage: () => void;
}

const WalletWrapper: FC<IProps> = ({
  coinbaseAddressId,
  text,
  realoadPage,
}) => {
  const [queryDelete, infoDeleteQuery] = useMutationAPI(CryptoAPI.deleteWallet);

  const onClickDelete = () => {
    queryDelete({ walletId: coinbaseAddressId });
  };

  useEffect(() => {
    if (infoDeleteQuery.data) {
      realoadPage();
    }
  }, [infoDeleteQuery]);

  return (
    <>
      <DeleteOutlined style={{ color: "red" }} onClick={onClickDelete} />
      <TextCopyable
        text={text}
        messageText={text}
        style={{ marginBottom: "unset", wordBreak: "break-all" }}
      />
    </>
  );
};

export default WalletWrapper;
