import styled from "styled-components";
import { Text, TextBold } from "../../commonStyle";


export const Wrapper = styled.div`
  height: fit-content;
  max-height: calc(100% - 60px);
  overflow-y: auto;
  position: fixed;
  right: 30px;
  top: 30px;
  & > div {
    margin-top: 15px;
  }
  & > div:first-child {
    margin-top: unset;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const WrapperItem = styled.div`
  width: 350px;
  display: flex;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
  padding-top: 10px;
`;

export const ItemIcon = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  & * {
    width: 25px;
    height: auto;
  }
`;

export const TitleItem = styled(TextBold)`
  word-break: break-all;
`;
export const TextItem = styled(Text)`
  word-break: break-all;
`;

export const WrapperBody = styled.div`
  width: calc(100% - 75px);
`;
