import { FC, useId } from "react";
import { useNavigate } from "react-router-dom";
import CompanyAtBank from "../../../component/companyAtBank";
import { IPropsPicker } from "../../../component/Global/Picker";
import * as Styled from "./style";

interface IProps {
  pickers: IPropsPicker[];
}

const RPLHeader: FC<IProps> = ({ pickers }) => {
  const idComponent = useId();
  const navigate = useNavigate();

  return (
    <div>
      {/* <TypeToTextMap data={[header.text]} styleData={{ fontSize: 16 }} />
      <Styled.WrapperButtons>
        {header.buttons.map(({ action, text }, index) => (
          <Button
            key={idComponent + index}
            shape="round"
            size="large"
            style={{ marginTop: "16px" }}
            onClick={() =>
              navigate("/recurring-payments/builder", {
                state: { type: action },
              })
            }
          >
            {text}
          </Button>
        ))}
      </Styled.WrapperButtons> */}
      <Styled.WrapperCompanyAt>
        <CompanyAtBank pickers={pickers} />
      </Styled.WrapperCompanyAt>
    </div>
  );
};

export default RPLHeader;
