import { DownloadOutlined } from "@ant-design/icons";
import { createContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PickerComponent, { IItemPicker } from "../../component/Global/Picker";
import Table from "../../component/table";
import WrapperBodyTitle from "../../component/Wrappers/WrapperBodyTitle";
import WrapperWhiteBG from "../../component/Wrappers/wrapperWhiteBG";
import PagesAPI from "../../services/pagesAPI";
import {
  IDetailsAPI,
  IItemTableStatement,
  IRowTable,
} from "../../services/types/table";
import { dowloadBase64 } from "../../utils/dowloadBase64";
import useMutationAPI from "../../utils/usePromise/hookMutationAPI";
import useSizePage from "../../utils/hookSizePage";
import {
  getDataTable,
  getPayload,
  getValueToID,
  initContext,
  initData,
} from "./data";

import HeaderStatementsID from "./header";
import InfoBalencesViewStatement from "./infoComponent/balances";
import InfoHeaderViewStatement from "./infoComponent/header";
import * as Styled from "./style";
import { TOnChangeData } from "./types";

interface ILocation {
  state?: {
    accountId: string;
  };
}

export const ContextCardsStatement = createContext(initContext);

const StatementID = () => {
  const { state } = useLocation() as ILocation;
  const accountIdProps = state?.accountId || "";

  const { width } = useSizePage();

  const [isBlockQuery, setIsBlockQuery] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [data, setData] = useState(initData);
  const [dataTable, setDataTable] = useState<IRowTable<IItemTableStatement>[]>(
    []
  );
  const [detailsTable, setDetailsTable] = useState<IDetailsAPI[][]>([]);
  const [query, infoQuery] = useMutationAPI(PagesAPI.getStatement, {
    changeIsLoading: true,
  });

  const [queryDowload, infoDownloadQuery] = useMutationAPI(
    PagesAPI.dowloadFile
  );

  const navigate = useNavigate();

  const onChangeData: TOnChangeData = (key, value) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    if (!accountIdProps) {
      navigate("/cards");
    } else {
      onChangeData("accountId", { text: "initial", id: accountIdProps });
    }
  }, [accountIdProps]);

  useEffect(() => {
    if (isBlockQuery || !data.accountId.id.length) return;
    setIsBlockQuery(true);
    query(getPayload(data, isInit));
    setIsInit(false);
  }, [
    data.accountId.id,
    data.paymentType,
    data.startDate,
    data.endDate,
    data.interval,
  ]);

  useEffect(() => {
    const response = infoQuery.data?.data;
    if (response) {
      const { filters, header, balances, data } = response;
      const {
        accounts_filter,
        intervals_filter,
        payments_filter,
        export_filter,
        dates_filter,
      } = filters;
      const { date_from, date_to } = dates_filter;

      const accountIdData = getValueToID(accounts_filter.dropdown_options.data);
      const intervalData = getValueToID(intervals_filter.dropdown_options.data);
      const paymentTypeData = getValueToID(
        payments_filter.dropdown_options.data
      );
      const downloadData = getValueToID(export_filter.dropdown_options.data);

      const accountId = accountIdData.find(
        ({ id }) => id === accounts_filter.dropdown_options.value
      ) || { id: "", text: "" };

      const interval = intervalData.find(
        ({ id }) => id === intervals_filter.dropdown_options.value
      ) || { id: "", text: "" };

      const paymentType = paymentTypeData.find(
        ({ id }) => id === payments_filter.dropdown_options.value
      ) || { id: "", text: "" };

      setData((prev) => ({
        ...prev,
        accountId,
        interval,
        paymentType,
        accountIdData,
        intervalData,
        startDate: new Date(date_from.value),
        endDate: new Date(date_to.value),
        paymentTypeData,
        infoHeader: header,
        infoBalances: balances,
        downloadData,
      }));

      setDataTable(data);
      setDetailsTable(data.map(({ details }) => details || []));

      setTimeout(() => setIsBlockQuery(false), 1000);
    }
  }, [infoQuery.data]);

  useEffect(() => {
    const response = infoDownloadQuery.data?.data;
    if (response) {
      const { fileContents, fileName } = response;
      dowloadBase64(fileContents, fileName);
    }
  }, [infoDownloadQuery]);

  const table = useMemo(
    () =>
      getDataTable(
        dataTable.map(({ item }) => item),
        width
      ),
    [dataTable, width]
  );

  return (
    <ContextCardsStatement.Provider value={{ data, setData, onChangeData }}>
      <WrapperBodyTitle title="STATEMENTS">
        <HeaderStatementsID />
        <WrapperWhiteBG isBorder>
          <InfoHeaderViewStatement />
          <InfoBalencesViewStatement />
        </WrapperWhiteBG>
        {!!table[0]?.dataColumn.length && (
          <Styled.WrapperDowload>
            <PickerComponent
              value={{ text: "", id: "" }}
              setValue={(value) =>
                queryDowload({
                  ...getPayload(data, isInit),
                  exportType: value.id,
                })
              }
              data={data.downloadData}
              icon={<DownloadOutlined />}
              isLoading={infoDownloadQuery.isLoading}
              width="250px"
              placeholder="Download Statement"
              disabledError
            />
          </Styled.WrapperDowload>
        )}

        <Table table={table} details={detailsTable} />
      </WrapperBodyTitle>
    </ContextCardsStatement.Provider>
  );
};

export default StatementID;
