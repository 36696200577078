import * as Styled from "../../../../shared/firstStep/beneficiary/style";
import { EmailInput } from "../../../../shared/firstStep/components/emailInput";
import {
  FirstNameInput,
  LastNameInput,
} from "../../../../shared/firstStep/components/fullNameInput";
import { InputEft } from "../../../types";

const FirstRowBeneficiaryEft = ({ input }: { input: InputEft }) => {
  return (
    <Styled.Row>
      <Styled.Item flex={1}>
        <EmailInput input={input} />
      </Styled.Item>

      <Styled.Item flex={1}>
        <FirstNameInput input={input} />
      </Styled.Item>

      <Styled.Item flex={1}>
        <LastNameInput input={input} />
      </Styled.Item>
    </Styled.Row>
  );
};

export default FirstRowBeneficiaryEft;
