import { Skeleton } from "antd";
import {
  createRef,
  FC,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useAppSelector } from "../../../utils/useState";
import Loader from "../../Global/Loader";
import Footer from "./footer";
import HeaderWrapper from "./header";
import NavBar from "./navBar";
import * as Styled from "./style";
import { Outlet } from "react-router-dom";

const WrapperHeaderNav: FC = () => {
  const { isInitQuery, isOpenNav, couterQuery } = useAppSelector(
    (state) => state.userReducer
  );

  return (
    <Styled.Wrapper id="main_wrapper_header_nav">
      <HeaderWrapper />
      <Styled.WrapperBodyNav>
        <NavBar closeable />
        <Styled.WrapperBody
          widthNav={isOpenNav ? 288 : 88}
          isLoading={couterQuery !== 0}
        >
          {couterQuery !== 0 && <Skeleton paragraph={{ rows: 15 }} active />}
          <div style={couterQuery !== 0 ? { display: "none" } : {}}>
            <Outlet />
          </div>
        </Styled.WrapperBody>
        {couterQuery !== 0 && isInitQuery && <Loader />}
      </Styled.WrapperBodyNav>
      {/* <Footer /> */}
    </Styled.Wrapper>
  );
};

export default WrapperHeaderNav;
