import { Button } from "antd";
import { FC, useContext, useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CryptoSellContext } from "../..";
import CommonModalComponent from "../../../../../component/commonModal";
import { TGetLeaveButton } from "../../../../../component/Wrappers/wrapperHeaderNav/navBar/modalLeave";
import { CryptoAPI } from "../../../../../services/crypto";
import {
  IPayloadSellDraft,
  IPayloadSellValidate,
} from "../../../../../services/crypto/types/payload";
import { useInterval } from "../../../../../utils/useInterval";
import useMutationAPI from "../../../../../utils/usePromise/hookMutationAPI";
import { getPayload, validate } from "./data";
import * as Styled from "./style";

interface IProps {
  queryAuth: (payload: IPayloadSellValidate) => Promise<any>;
  isLoadingAuth: boolean;
}

const CSellButtons: FC<IProps> = ({ queryAuth, isLoadingAuth }) => {
  const idComponent = useId();
  const { dataFirst, dataCommon, onErrorData } = useContext(CryptoSellContext);
  const {
    currencyCode,
    currencySymbol,
    paymentId,
    accountId,
    fiatAccountId,
    fiatCurrencyId,
    rateNumber,
    isLoadingRate,
  } = dataCommon;

  const [queryDraft, infoDraftQuery] = useMutationAPI(CryptoAPI.sell.draft);
  const [queryDiscard] = useMutationAPI(CryptoAPI.discard);
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const validateData = () => {
    const errors = validate(dataFirst, currencySymbol, currencyCode);
    errors.forEach(({ key, error }) => onErrorData(key, error));
    return !errors.filter(({ error }) => !!error.length).length;
  };

  const onClickAuth = () => {
    if (validateData())
      queryAuth({
        paymentId,
        payload: getPayload(
          accountId,
          dataFirst,
          fiatAccountId,
          fiatCurrencyId,
          rateNumber
        ),
      });
  };

  // const onClickSaveForLater = (autoSave?: boolean) => {
  //   return queryDraft({
  //     paymentId,
  //     payload: {
  //       ...getPayload(
  //         accountId,
  //         dataFirst,
  //         fiatAccountId,
  //         fiatCurrencyId,
  //         rateNumber
  //       ),
  //       autoSave,
  //     },
  //   });
  // };

  // useInterval(() => {
  //   onClickSaveForLater(true);
  // }, 14 * 60 * 1000);

  // useEffect(() => {
  //   let getLeaveButtons: TGetLeaveButton = (onClickStay, onClickLeave) => {
  //     return [
  //       <Button
  //         key={idComponent + "leave"}
  //         shape="round"
  //         size="large"
  //         style={{ padding: "6.4px 24px" }}
  //         onClick={() => {
  //           onClickSaveForLater(false).then(() => {
  //             onClickLeave();
  //           });
  //         }}
  //       >
  //         Save as draft
  //       </Button>,
  //       <Button
  //         key={idComponent + "stay"}
  //         shape="round"
  //         size="large"
  //         style={{ padding: "6.4px 24px" }}
  //         onClick={() => {
  //           queryDiscard(paymentId).then(() => {
  //             onClickLeave();
  //           });
  //         }}
  //         danger
  //       >
  //         Discard
  //       </Button>,
  //     ];
  //   };

  //   (window as any).getLeaveButtons = getLeaveButtons;
  // }, [accountId, dataFirst, fiatAccountId, fiatCurrencyId]);

  return (
    <Styled.Wrapper>
      <Button
        shape="round"
        size="large"
        type="primary"
        onClick={onClickAuth}
        loading={isLoadingAuth}
        disabled={isLoadingRate}
      >
        Authorize & Sell
      </Button>
      {/* {isOpenModal && (
        <CommonModalComponent
          onClickYes={() => {
            onClickSaveForLater(false).then(() => navigate("/crypto"));
          }}
          onClickNo={() => setIsOpenModal(false)}
          onClose={() => setIsOpenModal(false)}
          loadingYes={infoDraftQuery.isLoading}
          buttons={["Save as draft", "Cancel"]}
          title="Save as draft?"
          isDanger={[false,true]}
          message={
            <div>
              <div>
                Save as draft to continue filling out the form once ready.
              </div>
              <div>Cancel to go back to filling out details.</div>
            </div>
          }
          smallPadding
        />
      )} */}
    </Styled.Wrapper>
  );
};

export default CSellButtons;
