import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const WrapperDate = styled.div`
  width: calc(100% / 3);
  height: 40px;
  & > div {
    width: 100%;
  }
`;

export const WrapperInput = styled.div`
  width: calc(100% / 3);
`;
