import { FC } from "react";
import { Text } from "../../../../../../commonStyle";
import SwitchComponent from "../../../../../../component/Global/Switch";
import WrapperTitleError from "../../../../../../component/Wrappers/wrapperTitleError";

interface IProps {
  value: boolean;
  error: string;
  onChangeValue: (value: boolean) => void;
  text: string;
  wallet: string;
}

const CryptoSendConfirm: FC<IProps> = ({
  value,
  error,
  onChangeValue,
  text,
  wallet,
}) => {
  return (
    <WrapperTitleError error={error}>
      <SwitchComponent
        checked={value}
        onChange={(value) => onChangeValue(value)}
        text="Confirm Wallet Address*"
        textChange
      />
      {text.split("<br>").map((line, index) => {
        const lineReplace = line.replace('"%s"', `<bold>${wallet}<bold>`);
        const keyLine = "CryptoPayment.Details.Confirm.Line." + index;
        return (
          <Text key={keyLine}>
            {lineReplace.split("<bold>").map((text, index) => {
              if (index === 1) return <strong>{text}</strong>;
              return text;
            })}
          </Text>
        );
      })}
    </WrapperTitleError>
  );
};

export default CryptoSendConfirm;
