import PaymentAPI from "../../../services/paymentAPI";
import { IResponseInitFx } from "../../../services/paymentAPI/types/response";
import useQueryAPI from "../../../utils/usePromise/hookQueryAPI";
import { initDataSS, initDataTS } from "../data";
import PaymentSystem, { TLocationState } from "../shared";
import { requireFiles, requireValue } from "../shared/validations";
import {
  getCompanyHints,
  getDataCurrency,
  getDataCurrencyID,
  getDataFromForm,
  initDataCommon,
  initDataFS,
} from "./data";
import FirstStepFx from "./firstStep";
import { IDataCommon, IDataFS } from "./types";

const FxComponent = () => {
  return (
    <PaymentSystem<IDataFS, IDataCommon, IResponseInitFx, any, TLocationState>
      isSavePending
      initDataFS={initDataFS}
      initDataSS={initDataSS}
      initDataTS={initDataTS}
      initDataCommon={initDataCommon}
      updateInput={(input) => input}
      infoInitQueryProvider={({ accountId, paymentId, mode }) => {
        return useQueryAPI(PaymentAPI.fx.getInit, {
          payload: { id: accountId || paymentId || "", mode },
          changeIsLoading: true,
        });
      }}
      firstStepComponet={(context, setStep) => {
        return (
          <FirstStepFx
            input={{
              ...context,
              amountSymbol: () => {
                return (
                  context.dataCommon.dataCurrencyID.find(
                    (item) => item.id === context.dataFS.DCurrency.value.id
                  )?.text || ""
                );
              },
            }}
            setStep={setStep}
          />
        );
      }}
      onInitQueryLoaded={(
        response,
        input,
        onChangeDataFS,
        updateDataCommon
      ) => {
        if (!response.data) {
          return;
        }
        const {
          header,
          beneficiaries,
          account,
          dictionaries,
          transfer,
          form = {},
          files = [],
          user,
        } = response.data.data;

        const dataCountry = dictionaries.countries.map(
          ({ countryId, countryName }) => ({
            text: countryName,
            id: countryId,
          })
        );

        const dataState = dictionaries.us_states.map(
          ({ stateCode, stateName }) => ({
            text: stateName,
            id: stateCode,
          })
        );

        const dataCaState = dictionaries.ca_states.map(
          ({ stateCode, stateName }) => ({
            text: stateName,
            id: stateCode,
          })
        );

        const dataCurrency = getDataCurrency(dictionaries);

        const dataForm = getDataFromForm(
          form,
          files,
          dataCountry,
          dataState,
          dataCaState,
          dataCurrency,
          account.accountName
        );

        dataForm.map(([key, value]) => onChangeDataFS(key, value));

        updateDataCommon((prev) => ({
          ...prev,
          dataHeader: header,
          balance: account.balance.available,
          balanceCurrencySymbol: account.currencySymbol,
          canadaID: dictionaries.country_id_ca,
          usID: dictionaries.country_id_us,
          ukID: dictionaries.country_id_uk,
          paymentID: transfer.paymentId,
          dataCurrencyID: getDataCurrencyID(dictionaries),
          dataCountry,
          dataState,
          dataCaState,
          dataCurrency,
          dataRates: dictionaries.rates,
          dataCurrencyDictionary: dictionaries.currencies,
          companyHints: getCompanyHints(beneficiaries, dictionaries),
          accountId: account.accountId,
          isRequireFiles: transfer.options.requireFilesUpload,
          isDisableSaveAsDraft: transfer.options.disableSaveAsDraft,
          useCompanyNameInDescriptionControl:
            transfer.options.useCompanyNameInDescriptionControl,
          companyName: account.accountName,
          prefixDescription: account.accountName,
          isAbleAuth: user?.userType !== "simple-user",
          isUsingTemplate: input.mode === "edit",
        }));
      }}
      formConfig={{
        fieldGroups: [
          ["BAccountNumber", "BBic", "BSortCode", "BUkAccountNumber"],
        ],
      }}
      validateAll={(data, dataCommon) => {
        const isRequireFiles =
          dataCommon.isRequireFiles && !data.DIsInterTransfer.value;

        if (isRequireFiles) {
          requireFiles(data.Tfiles);
        }

        if (data.BSortCode.value != "" || data.BUkAccountNumber.value != "") {
          requireValue(data.BSortCode);
          requireValue(data.BUkAccountNumber);
        } else {
          requireValue(data.BAccountNumber);
          requireValue(data.BBic);
        }
      }}
    />
  );
};

export default FxComponent;
