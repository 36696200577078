import { useContext } from "react";
import { ContextCardRequest } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import PickerComponent from "../../../../../../../component/Global/Picker";
import useSizePage from "../../../../../../../utils/hookSizePage";
import validateString from "../../../../../../../utils/validate/validateString";
import * as Styled from "./style";

const CRSDSecondRow = () => {
  const valueContext = useContext(ContextCardRequest);

  const { width } = useSizePage();

  const { dataFS, onChangeDataFS, setErrorDataFS } = valueContext;

  const { SDPostalCode, SDStreet, SDHouseNumber, SDAdditional } = dataFS;

  const onBlur = (
    key: "SDPostalCode" | "SDStreet" | "SDHouseNumber" | "SDAdditional"
  ) => {
    const error = validateString(dataFS[key].value, {
      isReq: true,
      min: 3,
      max: key === "SDPostalCode" || key === "SDHouseNumber" ? 10 : 50,
    });

    setErrorDataFS(key, error);
  };

  return (
    <Styled.Wrapper>
      <InputComponent
        {...SDPostalCode}
        title="Postal Code:"
        onChangeValue={(value) => onChangeDataFS("SDPostalCode", value)}
        width={width > 1024 ? "12.5%" : "100%"}
        maxLength={10}
        onBlur={() => onBlur("SDPostalCode")}
      />
      <InputComponent
        {...SDStreet}
        title="Street address:"
        onChangeValue={(value) => onChangeDataFS("SDStreet", value)}
        width={width > 1024 ? "50%" : "100%"}
        onBlur={() => onBlur("SDStreet")}
        maxLength={50}
      />
      <InputComponent
        {...SDHouseNumber}
        title="House number:"
        onChangeValue={(value) => onChangeDataFS("SDHouseNumber", value)}
        width={width > 1024 ? "12.5%" : "100%"}
        onBlur={() => onBlur("SDHouseNumber")}
        maxLength={10}
      />
      <InputComponent
        {...SDAdditional}
        title="Additional:"
        onChangeValue={(value) => onChangeDataFS("SDAdditional", value)}
        width={width > 1024 ? "25%" : "100%"}
        onBlur={() => onBlur("SDAdditional")}
      />
    </Styled.Wrapper>
  );
};

export default CRSDSecondRow;
