import { Button } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalDeleteComponent from "../../../component/commonModal";
import Table from "../../../component/table";
import PagesAPI from "../../../services/pagesAPI";
import { IResponseDashboard } from "../../../services/pagesAPI/types/response";
import useMutationAPI from "../../../utils/usePromise/hookMutationAPI";
import useSizePage from "../../../utils/hookSizePage";
import { getDataTableDraft } from "./data";
import * as Styled from "./style";

interface IProps {
  data: IResponseDashboard["draft_data"];
  reloadDash: () => void;
}

const DashboardDrafts: FC<IProps> = ({ data, reloadDash }) => {
  const [queryDelete, infoDeleteQuery] = useMutationAPI(PagesAPI.draftsDelete);
  const { width } = useSizePage();
  const navigate = useNavigate();

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [idSelected, setIdSelected] = useState<string[]>([]);

  useEffect(() => {
    const response = infoDeleteQuery.data?.data;
    if (response) {
      setIsOpenDelete(false);
      reloadDash();
    }
  }, [infoDeleteQuery]);

  const dataTable = useMemo(
    () => getDataTableDraft(data, width, navigate),
    [data, width, navigate]
  );

  if (!data.length) return null;

  return (
    <>
      <Styled.WrapperDrafts>
        <Table
          table={dataTable}
          idRows={data.map(({ paymentId }) => paymentId)}
          idSeletRows={idSelected}
          onSelectRow={setIdSelected}
          pageSize={5}
        />
        <Button
          shape="round"
          size="large"
          onClick={() => setIsOpenDelete(true)}
          disabled={!idSelected.length}
          danger
        >
          Delete selected
        </Button>
      </Styled.WrapperDrafts>

      {isOpenDelete && (
        <ModalDeleteComponent
          onClickNo={() => setIsOpenDelete(false)}
          onClickYes={() => queryDelete({ transfers: idSelected })}
          onClose={() => setIsOpenDelete(false)}
          loadingYes={infoDeleteQuery.isLoading}
        />
      )}
    </>
  );
};

export default DashboardDrafts;
