import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 4px;
  display: flex;
  gap: 0 8px;
  align-items: center;
  & * {
    color: #6c64f4;
  }
`;
