import { IDataTable } from ".";
import { IDate } from "..";
import { checkDate } from "../../../../../utils/compareDate";
import { getDateWithTimezone } from "../../../../../utils/getDateWithTimezone";
import { ILimitationDate } from "../../../DatePicker/types";

const getDate = (
  date: number,
  month: number,
  year: number,
  status: boolean,
  limitationDate: ILimitationDate
): IDataTable => {
  const data = getDateWithTimezone({ year, month, date });
  return {
    date: data,
    status: status && !checkDate({ date: [data], ...limitationDate })[0],
  };
};

export const getDataTable = (
  month: number,
  year: number,
  limitationDate: ILimitationDate
) => {
  let firstDay = new Date(year, month, 1).getDay();
  firstDay = !firstDay ? 7 : firstDay;

  const countDate = new Date(year, month + 1, 0).getDate();
  const data: IDataTable[][] = [];
  let counter = 1;

  for (let i = 0; i < 6; i++) {
    const row = [];
    for (let j = 0; j < 7; j++) {
      if (!i && firstDay - 1 > j) {
        row.push(getDate(j - firstDay + 2, month, year, false, limitationDate));
      } else {
        row.push(
          getDate(counter, month, year, counter < countDate + 1, limitationDate)
        );
        counter++;
      }
    }
    data.push(row);
  }
  return data;
};

export const checkIsActive = (
  date: Date,
  startDate: Date,
  endDate: Date,
  hoverDate: Date
) => {
  const isStart = startDate.toDateString() == date.toDateString();
  const isEnd = endDate.toDateString() === date.toDateString();
  const isHover = hoverDate.toDateString() === date.toDateString();
  return isStart || isEnd || isHover;
};

export const getIsActiveRange = (
  date: Date,
  startDate: Date,
  hoverDate: Date
) => {
  const status1 = hoverDate > date && date > startDate;
  const status2 = hoverDate < date && date < startDate;
  return (status1 || status2) && !!startDate.getTime();
};
