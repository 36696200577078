import { Table } from "antd";
import { createRef, FC, useEffect, useId, useState } from "react";
import useSizePage from "../../../../utils/hookSizePage";
import AntdResponsiveTable from "../../../antdTable";
import { ITableDetails, ITableDetailsTable } from "../../types";
import TIDataToComponent from "../utils";
import * as Styled from "./style";

interface IProps {
  details: ITableDetails[];
  details_table: ITableDetailsTable[];
  isOpen: boolean;
  paddingLeft: string;
}

const DetailsDashboard: FC<IProps> = ({
  details,
  details_table,
  isOpen,
  paddingLeft,
}) => {
  const idComponent = useId();

  const refBody = createRef<HTMLDivElement>();

  const [heightBody, setHeightBody] = useState(0);

  useEffect(() => {
    if (!refBody.current) {
      setHeightBody(0);
      return;
    }
    const height = refBody.current.offsetHeight;
    setHeightBody(window.innerWidth <= 1024 ? height + 20 : height);
  }, [isOpen, refBody.current]);

  return (
    <Styled.WrapperAnimation isOpen={isOpen} height={heightBody}>
      <Styled.Wrapper ref={refBody} isOpen={isOpen}>
        {/* {!!details_table.dataSource.length && isOpen && (
            <Table {...details_table} pagination={false} scroll={{ x: 1024 }} />
          )} */}
        <div>
          <Styled.WrapperTable>
            {!!details_table.length &&
              isOpen &&
              details_table.map((item,index) => (
                <AntdResponsiveTable
                  {...item}
                  key={idComponent + ".Table." + index}
                  pagination={false}
                  mobileView={1100}
                />
              ))}
          </Styled.WrapperTable>
        </div>

        <Styled.WrapperDetails isOpen={isOpen} paddingLeft={paddingLeft}>
          {details.map((info, index) => (
            <Styled.WrapperInfo key={idComponent + ".Text." + index}>
              <Styled.Title breakWord>{info.title}</Styled.Title>
              <div style={{ width: "calc(100% - 180px)", minWidth: "270px" }}>
                <TIDataToComponent data={info.data} />
              </div>
            </Styled.WrapperInfo>
          ))}
        </Styled.WrapperDetails>
      </Styled.Wrapper>
    </Styled.WrapperAnimation>
  );
};

export default DetailsDashboard;
