import { useContext, useId } from "react";
import { ContextMassPayment } from "../../..";
import { TextBold } from "../../../../../commonStyle";
import TypeToTextMap from "../../../../../component/Global/TypeToTextMap";
import { TypeToText } from "../../../../../utils/typeToText";
import * as Styled from "../style";

const SSMPHeader = () => {
  const dataContext = useContext(ContextMassPayment);
  if (!dataContext) return <div />;

  const { secondStepHeader } = dataContext.data;

  return (
    <div>
      <TextBold fontSize={18}>REMITTER</TextBold>
      <TypeToTextMap data={secondStepHeader} />
    </div>
  );
};

export default SSMPHeader;
