import styled from "styled-components";

export const TitleCorrespDetails = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0 8px;
  cursor: pointer;
`;

export const TitleImg = styled.div`
  & * {
    display: flex;
    align-items: center;
    width: 20px;
    height: auto;
  }
`;
