import { useContext, useEffect, useState } from "react";
import { ContextInvoiceBuilder } from "../../../../..";
import InputComponent from "../../../../../../../component/Global/Input";
import PickerComponent from "../../../../../../../component/Global/Picker";
import * as Styled from "./style";
import { addressFields } from "../../../continue/data";

const IBRemSecondRow = () => {
  const { dataFS, dataCommon, setDataCommon, onChangeData, validateOnBlur } =
    useContext(ContextInvoiceBuilder);
  const { RemCountry, RemCity, RemState } = dataFS;
  const { dataRemCountry, dataRemState, isUS } = dataCommon;
  const [updateCountryError, setUpdateCountryError] = useState(false);

  useEffect(() => {
    const isUS = dataFS.RemCountry.value.id === dataCommon.USID;

    setTimeout(() => onChangeData("RemState", { text: "", id: "" }), 500);
    setDataCommon((prev) => ({ ...prev, isUS }));
  }, [dataFS.RemCountry.value, dataCommon.USID]);

  useEffect(() => {
    if (updateCountryError) {
      validateOnBlur("RemCountry", addressFields);
    }
    setUpdateCountryError(false);
  }, [updateCountryError, setUpdateCountryError]);

  return (
    <Styled.Wrapper>
      <PickerComponent
        {...RemCountry}
        title="Country:"
        data={[
          { text: "", id: "", component: "Select Country" },
          ...dataRemCountry,
        ]}
        placeholder="Select Country"
        setValue={(value) => {
          onChangeData("RemCountry", value);
          setUpdateCountryError(true);
        }}
        width={isUS ? "40%" : "50%"}
        id="RemCountry"
      />
      <InputComponent
        {...RemCity}
        title="City:"
        maxLength={50}
        onChangeValue={(value) => onChangeData("RemCity", value)}
        onBlur={() => validateOnBlur("RemCity", addressFields)}
        width={isUS ? "30%" : "50%"}
        id="RemCity"
      />
      {isUS && (
        <PickerComponent
          {...RemState}
          title="State:"
          data={dataRemState}
          placeholder="Select State"
          setValue={(value) => onChangeData("RemState", value)}
          width="30%"
          id="RemState"
        />
      )}
    </Styled.Wrapper>
  );
};

export default IBRemSecondRow;
