import styled from "styled-components";
import { Text, TextLight } from "../../../commonStyle";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

interface IWrapperValue {
  borderColor: string;
  isError: boolean;
  disabled: boolean;
}

const getBorderColor = (isError: boolean, disabled: boolean) => {
  if (isError) return "#eb5757";
  if (disabled) return "rgb(58, 58, 60,0.1)";
  return "#6c64f4";
};

export const WrapperValue = styled.div<IWrapperValue>`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 0 4px;
  padding: 4px 8px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ borderColor }) => borderColor};
  background-color: ${({ disabled }) =>
    disabled ? "rgb(58, 58, 60,0.1)" : "transperent"};
  &:hover {
    border-color: ${({ isError, disabled }) =>
      getBorderColor(isError, disabled)};
  }
`;

export const ImgWrapper = styled.div`
  height: 18px;
  & > img {
    height: 100%;
    width: auto;
  }
`;



export const Input = styled.input`
  border: unset;
  outline: unset;
  font-size: 16px;
  flex: auto;
  font-family: Poppins;
`;

export const Error = styled(Text)`
  min-height: 15px;
  text-align: start;
  color: #eb5757;
`;

export const Title = styled(Text)`
  text-align: start;
`;
