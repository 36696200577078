import AmountComponent from "../../../../../../component/Global/Amount";
import useSizePage from "../../../../../../utils/hookSizePage";
import validateNumber from "../../../../../../utils/validate/validateNumber";
import { InputUk } from "../../../types";

const PaymentDetaisDateFirst = ({
  input: {
    dataFS,
    dataCommon,
    onChangeDataFS,
    formActions: { prepareMoney },
  },
}: {
  input: InputUk;
}) => {
  const { width } = useSizePage();

  const { DAmount } = dataFS;
  const { amountSymbol, balance } = dataCommon;

  return (
    <AmountComponent
      {...DAmount}
      onChangeValue={(value) => onChangeDataFS("DAmount", value)}
      title="Amount:"
      prefix={amountSymbol + " "}
      placeholder={amountSymbol + " 0.00"}
      id="DAmount"
      width={width > 1024 ? "33.3%" : "100%"}
      charLimit={16}
      fieldActions={prepareMoney("DAmount", (value) =>
        validateNumber(
          value,
          {
            min: 0.01,
            max: balance,
          },
          {
            symbol: amountSymbol,
          }
        )
      )}
    />
  );
};

export default PaymentDetaisDateFirst;
