import styled from "styled-components";

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin:20px 0
`;


