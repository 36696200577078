import * as Styled from "../../../../shared/firstStep/beneficiary/style";
import { InputSwift } from "../../../types";
import { BICCorrespondentInput } from "../../../../shared/firstStep/components/bicInput";
import { CorrespondentBankNameInput } from "../../../../shared/firstStep/components/correspondentBankNameInput";
import { CorrespondentAccountInput } from "../../../../shared/firstStep/components/correspondentAccountInput";

const CorrespRowBeneficiarySwift = ({ input }: { input: InputSwift }) => {
  return (
    <Styled.Row>
      <Styled.Item flex={2}>
        <CorrespondentAccountInput input={input} />
      </Styled.Item>

      <Styled.Item flex={1}>
        <BICCorrespondentInput input={input} />
      </Styled.Item>

      <Styled.Item flex={2}>
        <CorrespondentBankNameInput input={input} />
      </Styled.Item>
    </Styled.Row>
  );
};

export default CorrespRowBeneficiarySwift;
