import axios, { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { IResponseError } from "../../../services/types";
import {
  setCouterQuery,
  setIsInitQuery,
  setIsLoading,
} from "../../../store/user";

import { useAppSelector } from "../../useState";
import usePromiseData from "../hookPromiseData";

export interface IInfoMutaion<E, T> {
  data: AxiosResponse<T> | null;
  isLoading: boolean;
  error: AxiosError<E> | null;
}

export interface IPropsMutaionAPI {
  isLogin?: boolean;
  reCaptcha?: boolean;
  changeIsLoading?: boolean;
}

const initInfo = {
  data: null,
  isLoading: false,
  error: null,
};

function useMutationAPI<D, T, E = IResponseError>(
  promise: (props: D) => Promise<AxiosResponse<T>>,
  dataProps?: IPropsMutaionAPI
) {
  const [info, setInfo] = useState<IInfoMutaion<E, T>>(initInfo);
  const { isInitQuery, couterQuery } = useAppSelector(
    (state) => state.userReducer
  );
  const { onError, onSucces, getReCatcha } = usePromiseData<T, E>();
  const dispatch = useDispatch();

  const {
    changeIsLoading = false,
    isLogin = false,
    reCaptcha = false,
  } = dataProps || {};

  const query = async (props: D) => {
    try {
      setInfo({ ...initInfo, isLoading: true });
      if (changeIsLoading) dispatch(setCouterQuery(1));

      if (reCaptcha) {
        const status = await getReCatcha();
        if (!status) throw new Error("ReCaptcha failed");
      }

      const data = await promise(props);
      onSucces(data.data);

      const response = { ...initInfo, data };

      setInfo(response);
      
      if (changeIsLoading) dispatch(setCouterQuery(-1));

      return response;
    } catch (e) {
      if (!axios.isAxiosError(e)) {
        setInfo(initInfo);
        return initInfo;
      }

      const error = e as AxiosError<E>;

      onError(error);

      if (changeIsLoading) dispatch(setCouterQuery(-1));

      const response = { ...initInfo, error };
      setInfo(response);
      return response;
    } finally {
      if (isInitQuery && !isLogin) dispatch(setIsInitQuery(false));
    }
  };

  return [query, info] as [
    (props: D) => Promise<IInfoMutaion<E, T>>,
    IInfoMutaion<E, T>
  ];
}

export default useMutationAPI;
