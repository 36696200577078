import { IItemPicker } from "../../../component/Global/Picker";
import TypeToTextMap from "../../../component/Global/TypeToTextMap";
import { IDropdownOptions } from "../../../services/paymentAPI/types";
import { IPayloadValidateInternal } from "../../../services/paymentAPI/types/payload";
import { getValueError } from "../data";
import { IDataCommon, IDataFS } from "./types";

export const initDataFS: IDataFS = {
  DIsInterTransfer: getValueError(false),
  DAmount: getValueError("0.00"),
  DPaymentDescription: getValueError(""),
  DIsCompanyName: getValueError(true),
  BCompany: getValueError({ text: "", id: "" }),

  Tfiles: getValueError([]),
};

export const initDataCommon: IDataCommon = {
  balance: 0,
  dataHeader: [],
  paymentID: "",
  checksum: "",
  typeAuth: "google-authenticator",
  amountSymbol: "",
  isRequireAddress: false,
  isDisableSaveAsDraft: false,
  useCompanyNameInDescriptionControl: true,
  dataCompany: [],
  accountId: "",
  isRequireFiles: true,
  companyName: "",
  isLoadingFiles: false,
  prefixDescription: "Test Company",
  isAbleAuth: false,
  isUsingTemplate: false,
};

export const getDataCompany = (beneficiaries: IDropdownOptions) => {
  const dataOptions = beneficiaries.data;
  const data = dataOptions.map(
    ({ data, value }): IItemPicker => ({
      text: data.map(({ text }) => text).join(" "),
      id: value,
      component: <TypeToTextMap data={[data]} />,
    })
  );
  return data;
};

const resToData: {
  [key in keyof IPayloadValidateInternal["payload"]]: keyof IDataFS;
} = {
  accountId: "DPaymentDescription",
  paymentAmount: "DAmount",
  paymentDescription: "DPaymentDescription",
  paymentDescriptionCompanyName: "DIsCompanyName",
  accountIdTo: "BCompany",
};

type TValue = string | boolean | IItemPicker;

export const getDataFromForm = (
  form: Partial<IPayloadValidateInternal["payload"]>,
  dataCompany: IItemPicker[],
  paymentDescription: string
) => {
  let responseData: Partial<{
    [key in keyof IDataFS]: TValue;
  }> = {};

  const keysForm = Object.keys(form) as (keyof typeof form)[];

  keysForm.forEach((key) => {
    const value = form[key];
    const keyData = resToData[key];

    if (!keyData) return;

    if (typeof value === "number") {
      responseData[keyData] = value.toFixed(2);
      return;
    }

    if (keyData === "BCompany") {
      responseData[keyData] =
        dataCompany.find(({ id }) => id === form.accountIdTo) ||
        initDataFS.BCompany.value;
      return;
    }

    if (
      keyData === "DPaymentDescription" &&
      typeof value === "string" &&
      form.paymentDescriptionCompanyName
    ) {
      responseData[keyData] = value.slice(paymentDescription.length + 1);
      return;
    }

    responseData[keyData] = value;
  });

  return Object.entries(responseData) as [keyof IDataFS, TValue][];
};
