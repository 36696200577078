import styled from "styled-components";

export const Wrapper = styled.form`
  max-width: 800px;

  textarea {
    border-radius: 8px;
    border-color: #d9d9d9;
    font-size: 16px;
    line-height: 1.5;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-weight: 400;
    }

    &:hover {
      border-color: #1677ff !important;
    }

    &:focus {
      border-color: #1677ff !important;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
      outline: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 16px;
`;
