import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  & > button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;
